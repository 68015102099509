import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import NotesIcon from '@mui/icons-material/Notes';
import BlockIcon from '@mui/icons-material/Block';
import t from '../../helpers/languages';
import useStyles from './styles';

const CancelOrder = ({id, getAction}) => {
    const classes = useStyles();

    const handleCancel = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const data = {
            action: 'cancel',
            id: id
        }
      getAction(data);
      
    }

   return <>
       <Tooltip aria-label={t('CANCEL_ORDER')} title={t('CANCEL_ORDER')} >
           <IconButton
               aria-label={`${t('CANCEL_ORDER_WITH_ID')} ${id}`}
               className={classes.actionButton}
               onClick={(event) => { return handleCancel(event, id) }}
               size="large">
               <CancelIcon className={classes.actionIcon} />
           </IconButton>
       </Tooltip>
   </>;
}

const ConfirmOrder = ({id, getAction}) => {

    const handleConfirm = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const action = {
            action: 'confirm',
            id: id
        }
        getAction(action);
    }

    const classes = useStyles();
    return <>
        <Tooltip aria-label={t('CONFIRM_ORDER')} title={t('CONFIRM_ORDER')} >
            <IconButton
                aria-label={`${t('CONFIRM_ORDER_WITH_ID')} ${id}`}
                className={classes.actionButton}
                onClick={(event) => {
                    return handleConfirm(event, id)
                }}
                size="large">
                <CheckCircleIcon className={classes.actionIcon} />
            </IconButton>
        </Tooltip>
    </>;
}

const AddSupplier = ({id, getAction}) => {

    const handleAddSupplier = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const action = {
            action: 'addSupplier',
            id: id
        }
        getAction(action);
    }

    const classes = useStyles();
    return (
        <>
            <Tooltip aria-label={t('ADD_SUPPLIER')} title={t('ADD_SUPPLIER')} >
                <span>
                    <Button
                        variant="contained"
                        aria-label={`${t('ADD_SUPPLIER_WITH_ID')} ${id}`}
                        className={classes.actionButton}
                        color='secondary'
                        disabled={!id}
                        size="large"
                        startIcon={<PlaylistAddIcon />}
                        onClick={(event) => {
                            return handleAddSupplier(event, id)
                        }}
                    >
                        {t('ADD')}
                    </Button>
                </span>
            </Tooltip>
        </>);
}

const AddSupplierNotes = ({getAction, label}) => {

    const handleAddSupplierNotes = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const action = {
            action: 'addSupplierNotes',
        }
        getAction(action);
    }
    const classes = useStyles();
    return (
        <>
            <Tooltip aria-label={t(label)} title={t(label)} >
                <span>
                    <Button
                        variant="contained"
                        aria-label={t(label)}
                        color='success'
                        size="large"
                        fullWidth
                        startIcon={<NotesIcon />}
                        className={classes.actionButton}
                        onClick={(event) => {
                            return handleAddSupplierNotes(event)
                        }}
                    >
                        {t(label)}
                    </Button>
                </span>
            </Tooltip>
        </>);
}

const Decline = ({getAction, label}) => {

    const handleDecline = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const action = {
            action: 'decline',
        }
        getAction(action);
    }
    const classes = useStyles();
    return (
        <>
            <Tooltip aria-label={t(label)} title={t(label)} >
                <span>
                    <Button
                        variant="contained"
                        aria-label={t(label)}
                        color='primary'
                        size="large"
                        fullWidth
                        style={{ backgroundColor: 'red', color: '#ffffff' }}
                        startIcon={<BlockIcon />}
                        className={classes.actionButton+' '+'rfq-decline'}
                        onClick={(event) => {
                            return handleDecline(event)
                        }}
                    >
                        {t(label)}
                    </Button>
                </span>
            </Tooltip>
        </>);
}

export { CancelOrder, ConfirmOrder, AddSupplier, AddSupplierNotes, Decline };