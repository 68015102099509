import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import './style.scss';

const DoughnutChart = (props) => {

    useEffect(() => {

        const data = [props.completed, props.pending, props.canceled];
        const label = ['Completed', 'Pending', 'Canceled'];

        const con = document.getElementById('doughnutChart');
        let c = new Chart(con, {
            type: 'doughnut',
            data: {
                labels: label,
                datasets: [{
                    data: data,
                    label: "Rfq's",
                    backgroundColor: [
                        'rgba(188, 233, 129, 1)',
                        'rgba(102, 255, 181, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    // borderColor: [
                    //     'rgba(188, 233, 129, 1)',
                    //     'rgba(102, 255, 181, 1)',
                    //     'rgba(255, 99, 132, 1)',
                    // ],
                    borderWidth: .5,
                    lineWidth: .3
                }]
            },
            options: {
                cutout: '70%',
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            usePointStyle: true
                        }
                    },
                },
                responsive: false,
            }
        });
        return () => { c.destroy(); }
    }, [props.completed, props.pending, props.canceled]);

    const themes = {
        red: {
            fontColor: '#ff4861',
            progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
        },
        springGreen: {
            fontColor: '#00ff7f',
            progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
        },
        green: {
            fontColor: '#b8e986',
            progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
        },
        blue: {
            fontColor: '#48b5ff',
            progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
        },
        black: {
            fontColor: '#000000',
            progressBarColor: 'linear-gradient(270deg,#36454F,#000000)'
        },
    };

    return (
        <section
            className='doughnut-presenter'
        >

            <div className='column-doughnut'>
                <div
                    className='doughnut-presenter__total-amount'
                    style={{ color: themes[props.theme].fontColor }}
                >
                    <span>{props.completed + props.pending + props.canceled}</span>

                </div>
                <div className='doughnut-presenter__total-amount-label'>{props.label}</div>
            </div>
            <div className='column-doughnut'>
                <canvas
                    id="doughnutChart"
                    width={250}
                    height={'100%'}
                >
                </canvas>

            </div>

        </section>
    )
};

export default DoughnutChart;