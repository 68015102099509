import httpService from './httpService';


const getAllPortDictionariesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.GetPortDicts(criteria);
};

const deletePortDictionary = (id) => {
  return httpService.DeletePortDict(id);
};

const editPortDictionary = (id, data) => {
   return httpService.UpdatePortDict({}, data);
};

const addPortDictionary = (data) => {
  return httpService.CreatePortDict({},data);
};

export { getAllPortDictionariesInfo, deletePortDictionary, addPortDictionary, editPortDictionary };
