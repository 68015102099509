import React, {useCallback} from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCatalogues } from '../../services/cataloguesService';
import useStyles from './style';

export default function InlineEditCataloguesMultiple({ catalogue: initialCatalogue, getSelectedCatalogue, label, saved, errorMessage }) {
  const classes = useStyles();

  const [catalogue, setCatalogue] = React.useState(initialCatalogue && initialCatalogue.id !== null ? initialCatalogue : []);
  const [catalogues, setCatalogues] = React.useState(initialCatalogue && initialCatalogue.id !== null ? [initialCatalogue] : []);
  const [openCatalogue, setOpenCatalogue] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const handleSpanClick = useCallback(
    () => setOpenCatalogue(true),
    [setOpenCatalogue]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "catalogue" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debouncedGetCatalogues(value);
    };
  };

  const debouncedGetCatalogues = debounce(val => {
    catalogueSearch({
      search: val
    })
  },
    800);

  // Join Without Dupes.
  const joinWithoutDupes = (A, B) => {
    const a = new Set(A.map(x => x.item))
    const b = new Set(B.map(x => x.item))
    return [...A.filter(x => !b.has(x.item)), ...B.filter(x => !a.has(x.item))]
  }

  const onCataloguesSelectionChange = (event, values) => {
    setCatalogue(values ? values : '');
    const selectedCatalogue = {
      catalogue: values ? values : '',
    };
    getSelectedCatalogue(selectedCatalogue);
  };
  const catalogueSearch = (criteria) => {

    return getAllCatalogues(criteria).then((res) => {
      setCatalogues(joinWithoutDupes(res.data, catalogues));
    }).catch((error) => {
    })
  }

  return (
    <span className="inline-text">
      {openCatalogue || !saved ? (
        <span>
          <Autocomplete
            multiple
            id={"find-catalogue " + Math.random()}
            fullWidth
            open={openCatalogue}
            onOpen={() => {
              setOpenCatalogue(true);
            }}
            onClose={() => {
              setOpenCatalogue(false);
            }}
            openOnFocus={true}
            onInputChange={e => handleInputChange(e, 'catalogue')}
            onChange={onCataloguesSelectionChange}
            value={catalogues !== null ? catalogue : []}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name)}
            options={catalogues !== null && catalogues.length !== 0 ? catalogues : []}

            // value={vessels.find(v => v.id === vessel.id) || null}
            // getOptionSelected={(option, value) => (option.id === value.id)}
            // getOptionLabel={(option) => (option.name!==null?option.name:'')}
            // options={vessels !== null ? vessels : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                label={t(label)}
                variant="outlined"
                InputProps={{
                  ...params.InputProps
                }}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openCatalogue ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            label={t(label)}
            defaultValue={catalogue && catalogue.map((cat, index) => {return cat.name}) }
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </span>)}
    </span >
  );
};