import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { getTransactionById, addTransactionSupplier, editTransactionAgent } from '../../services/transactionsService';
import useStyles from './styles';
import TransactionInfo from "../../components/transactionInfo";
import RfqInfoTable from '../../components/rfqInfoTable';
import QuoteInfoTable from '../../components/quoteInfoTable';
import OrderInfoTable from '../../components/orderInfoTable';
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import { cancel } from '../../services/ordersService';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import FilterSuppliers from "../../components/filterSuppliers";
import FormControl from '@mui/material/FormControl';
import _ from 'lodash';
import t from '../../helpers/languages';
import DeliveriesInfoTable from '../../components/deliveriesInfoTable';
import { AddSupplier } from '../../components/actions';
import OnlineAssistant from '../../components/onlineAssistant';

const TrasactionItem = (props) => {

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //   };
  // });
  
  const [transactionState, setTransactionState] = useState({
    id: null,
    status: null,
    cancelled: false,
    isLoading: true,
    buyer: {
      id: null,
      name: null,
      supplier: null,
      admin: null,
    },
    vessel: {
      id: null,
      name: null,
      companyId: null,
    },
    port: {
      id: null,
      name: null,
      countryId: null,
      countryName: null,
    },
    notes: null,
    rfqs: [],
    agent: [],
    quotes: [],
    orders: [],
    deliveries: [],
    actions: [],
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
    isSaved: true,
  });

  const [supplier, setSupplier] = useState();
  const [suppliers, setSuppliers] = useState('');
  const pathName = window.location.pathname;
  const transactionPatern = '/transactions/';
  const trsId = pathName.replace(transactionPatern, '');

  const selectedSupplier = (data) => {

    const res = data.supplier;

    setSupplier(res);

    res && setSuppliers([
      ...suppliers,
      res
    ]);

  }

  const trsCriteria = { id: trsId };

  useEffect(() => {

    setTransactionState({
      ...transactionState,
      isLoading: true,
    });

    getTransactionById(trsCriteria).then(res => {

      setTransactionState({
        ...transactionState,
        isLoading: false,
        id: res.data.id,
        status: res.data.status,
        date: res.data.date,
        category: res.data.category,
        cancelled: res.data.canceled,
        vessel: res.data.vessel,
        port: res.data.port,
        buyer: res.data.buyer,
        rfqs: res.data.rfqs,
        agent: res.data.agent,
        quotes: res.data.quotes,
        orders: res.data.orders,
        deliveries: res.data.deliveries,
        actions: res.data.actions,
        notes: res.data.notes,
        error: res.data.error,
      });

    }).catch((error) => {
      setTransactionState({
        ...transactionState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);



  const handleCancelTransaction = (e) => {

    const order = {
      id: transactionState.id
    };

    setTransactionState({
      ...transactionState,
      confirmDialog: {
        message: 'Are you sure you want to cancel this transaction?',
        title: 'Cancel Transaction',
        onCancel: () => {
          setTransactionState({
            ...transactionState,
            confirmDialog: {
              ...transactionState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          cancel(order).then(res => {
            window.location.assign('/transactions/');

          }).catch((error) => {

            setTransactionState({
              ...transactionState,
              isLoading: false,
            });
          });
        },
        isLoading: false,
        isOpen: true
      }
    });
  }

  const handleCompareTransaction = (e) => {
    window.location.assign('/transactions/' + transactionState.id + '/comparison');
  }

  const saveAgent = (data) => {

    editTransactionAgent({ id: transactionState.id }, data)
      .then(res => {
        setTransactionState({
          ...transactionState,
          agent: res.data,
          isLoading: false,
          isAgentTelephoneNoSaved: true
        });
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setTransactionState({
          ...transactionState,
          isLoading: false,
          isAgentTelephoneNoSaved: false
        });
      });

  };

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        revise: {
          enabled: false,
        },
        delete: {
          enabled: false,
        },
        sync: {
          enabled: false,
        },
        submit: {
          enabled: false,
        },
        confirm: {
          enabled: false,
        },
        cancel: {
          enabled: transactionState && transactionState.actions && transactionState.actions.includes("CANCEL_TRANSACTION"),
          callback: (e) => handleCancelTransaction(e)
        },
        quote: {
          enabled: false,
        },
        cοmpare: {
          enabled:  transactionState && transactionState.actions && transactionState.actions.includes("COMPARE"),
          callback: (e) => handleCompareTransaction(e)
        },
      }}

    />;
  };

  const trasactionTab = () => {
    return (
      transactionState.date !== 0 &&!transactionState.isLoading&& <TransactionInfo
        infoTabTitle={t('TRANSACTION')}
        agentName={transactionState.agent?.name ? transactionState.agent.name : ""}
        agentTelephoneNo={transactionState.agent?.telephoneNo ? transactionState.agent.telephoneNo : ""}
        agentEmail={transactionState.agent?.email ? transactionState.agent.email : ""}
        agentFaxNo={transactionState.agent?.faxNo ? transactionState.agent.faxNo : ""}
        agentAddress={transactionState.agent?.address ? transactionState.agent.address : ""}
        savedAgentName={transactionState.isAgentNameSaved}
        savedAgentTelephoneNo={transactionState.isAgentTelephoneNoSaved}
        savedAgentEmail={transactionState.isAgentEmailSaved}
        savedAgentFaxNo={transactionState.isAgentFaxNoSaved}
        savedAgentAddress={transactionState.isAgentAddressSaved}
        agentData={saveAgent}
        vessel={transactionState.vessel || "-"}
        buyer={transactionState.buyer || "-"}
        port={transactionState.port || "-"}
        category={transactionState.category || "-"}
        date={transactionState.date ? transactionState.date.slice(0, 10) : "-"}
        status={transactionState.status !== 'RFQ' ? _.capitalize(transactionState.status) : transactionState.status || "-"}
        edit={transactionState.actions && transactionState.actions.includes("UPDATE_AGENT")}
      />
    )
  };

  const rfqTable = () => {
    return (
      transactionState.rfqs.length !== 0 && <RfqInfoTable
        toolbarTitle={t('RFQS')}
        transactionId={trsId}
        role={props.userState.user.userRole || "-"}
        rfqs={transactionState.rfqs || "-"}
      />
    )
  };

  const quoteTable = () => {
    return (
      transactionState.quotes.length !== 0 && <QuoteInfoTable
        toolbarTitle={t('QUOTES')}
        transactionId={trsId}
        role={props.userState.user.userRole || "-"}
        quotes={transactionState.quotes || "-"}
      />
    )
  };


  const orderTable = () => {
    return (
      transactionState.orders.length !== 0 && <OrderInfoTable
        toolbarTitle={t('ORDERS')}
        transactionId={trsId}
        role={props.userState.user.userRole || "-"}
        orders={transactionState.orders || "-"}
      />
    )
  };

  const deliveriesTable = () => {
    return (
      transactionState.deliveries.length !== 0 && <DeliveriesInfoTable
        toolbarTitle={t('DELIVERIES')}
        transactionId={trsId}
        role={props.userState.user.userRole || "-"}
        deliveries={transactionState.deliveries || "-"}
      />
    )
  };

  const confirmDialog = () => {
    return (<ConfirmationDialog
      ariaLabelledBy={''}
      confirmationMessage={transactionState.confirmDialog.message}
      dialogTitle={transactionState.confirmDialog.title}
      handleCancel={transactionState.confirmDialog.onCancel}
      handleOk={transactionState.confirmDialog.onConfirm}
      open={transactionState.confirmDialog.isOpen}
      confirm="YES"
      cancel="NO"
    />)
  };
  const addSupplier = () => {
    return <form>
      <FormControl component={Paper} variant="outlined" className={classes.formControl} fullWidth>
        <FilterSuppliers
          supplier={supplier}
          getSelectedSupplier={selectedSupplier}
          errorMessage={''}
        />
      </FormControl>
    </form>
  };

  const handleAddSupplier = (data) => {

    const dfs = {
      id: trsId,
      supplierId: data.id
    }


    addTransactionSupplier(dfs).then(res => {
      setTransactionState({
        ...transactionState,
      });

      getTransactionById(trsCriteria).then(res => {

        setTransactionState({
          ...transactionState,
          id: res.data.id,
          status: res.data.status,
          date: res.data.date,
          category: res.data.category,
          cancelled: res.data.canceled,
          vessel: res.data.vessel,
          port: res.data.port,
          buyer: res.data.buyer,
          rfqs: res.data.rfqs,
          quotes: res.data.quotes,
          orders: res.data.orders,
          deliveries: res.data.deliveries,
          actions: res.data.actions,
          notes: res.data.notes,
          error: res.data.error,
        });

        setSupplier(null);
        setSuppliers([null]);

      }).catch((error) => {

        setTransactionState({
          ...transactionState,
        });
      });

    }).catch((error) => {
      setTransactionState({
        ...transactionState,
      });

    });

  };

  return (
    <div className={classes.transactionWrapper}>
      <div className={classes.root}>
        <OnlineAssistant
          path={'transaction'}
          user={props.userState.user.userRole}
        />
        {breadcrump()}
        <Paper
          className={classes.paper}
          elevation={4}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={3}>
            <Grid item xs={12}>
              {trasactionTab()}
            </Grid>
            {transactionState.actions.includes("ADD_SUPPLIER") && <>


              <Grid item xs={10}>
                {addSupplier()}
              </Grid>
              <Grid item xs={2}>
                <AddSupplier id={supplier?.id} getAction={handleAddSupplier} />
              </Grid>

              <Divider />
            </>}
            <Grid item xs={12}>
              {rfqTable()}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {quoteTable()}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {orderTable()}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {deliveriesTable()}
            </Grid>
            {confirmDialog()}
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default TrasactionItem;
