import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { debounce, Grid, TextField } from "@mui/material";
import Switch from '@mui/material/Switch';
import './_countryTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const CountryTableFilter = ({ search: initialSearch, isUnresolved: initialIsUnresolved, getFilterData }) => {
  const classes = useStyles();

  const [search, setSearch] = useState(initialSearch || '');
  const [isUnresolved, setIsUnresolved] = useState(initialIsUnresolved || false);



  const handleChange = (event, property) => {
    if (property === " isUnresolved") {
      setIsUnresolved(event.target.checked);
      const filterData = {
        isUnresolved: event.target.checked,
        search: search
      };
      debounceFn(filterData);

    } else {
      setSearch(event.target.value);
      const filterData = {
        isUnresolved: isUnresolved,
        search: event.target.value
      };

      debounceFn(filterData);
    }
  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              variant="outlined"
              control={
                <Switch
                  checked={isUnresolved}
                  onChange={e => handleChange(e, ' isUnresolved')}
                  name=" isUnresolved"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              }
              label="IsUnresolved"
            /></FormControl>
        </Grid>
      </Grid>
    </form>
  );
}