import httpService from './httpService';

const getAllDataAudits = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchDataAuditList(criteria);
};

const getDataAuditById = (criteria = {}) => {
  return httpService.FetchDataAudit(criteria);
};

const getAllComAudits = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
  return httpService.FetchComAuditList(criteria);
};

export { getAllDataAudits, getDataAuditById, getAllComAudits };
