import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableActions from '../../components/TableActions/TableActions';
import clsx from "clsx";
import Table from "@mui/material/Table";
import React from "react";

const CountryMatchTable = ({ countries, onRowClick, selectedCountries, countriesCategory, handleChangePage, handleChangeRowsPerPage, handleDeleteCountry }) => {
    const ccat=countriesCategory;
    return (<>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Code</TableCell>
					{countriesCategory === 'resolvedCountry' ? <TableCell align="left">Category</TableCell> : <TableCell align="left">Action</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {countries.countries.map((country, index) => {
                    return (
                        <TableRow key={country.id}
                            onClick={() => onRowClick(country, countriesCategory, index)}
                            className={clsx({
                                'country-item': true,
                                'country-item--selected': selectedCountries[countriesCategory]?.id === country.id
                            })}
                        >
                            <TableCell>{country.name}</TableCell>
                            <TableCell>{country.code}</TableCell>
							{countriesCategory === 'resolvedCountry' ?  <TableCell>{country?.category?.name}</TableCell> : <TableCell><
                                    TableActions config={{
                                        edit: {
                                            enabled: false
                                        },
                                        delete: {
                                            enabled: true,
                                            callback: (e) => handleDeleteCountry(e, index)
                                        },
                                        close: {
                                            enabled: false
                                        },
                                        save: {
                                            enabled: false
                                        }
                                    }} /></TableCell>}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
        <TablePagination
            component='div'
            count={countries.page && countries.page.total}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, ccat)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, ccat)}
            page={countries.page && countries.page.page - 1}
            rowsPerPage={countries.page && countries.page.per_page}
            rowsPerPageOptions={[5, 10, 50]}
        />
    </>
    )
}

export default CountryMatchTable