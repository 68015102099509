import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/tableHeader';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { getOrderItems } from '../../services/orderItemsService';
import { getItemsEvaluation, addItemEvaluation, deleteItemEvaluation, editItemEvaluation } from '../../services/orderItemsEvaluationService';
import { tableHeadersEvaluate } from '../../helpers/orderItemsConfig';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import { TableFilterItems } from '../../components/tableFilterItems';
import InlineEditNotes from '../../components/inlineEditNotes';
import InlineRating from '../../components/inlineRating';

import t from '../../helpers/languages';

const OrderItemsEvaluation = (props) => {

  const orderItemInitObject = () => {
    return {
      notes: '',
      id: '',
      product: { code: '', id: '', name: '' },
      notes: '',
      add: true
    }
  };

  const [orderItemsState, setOrderItemsState] = useState({
    orderItems: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    orderItemEditIndex: -1,
    orderItemEditObject: orderItemInitObject(),
    orderItemCreateObject: null,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
    isNotesSaved: {},
    isStarsSaved: {},
  });


  const pathName = window.location.pathname;
  const regexTransaction = /(\d+)/gm;
  let m;

  m = regexTransaction.exec(pathName);


  const transactionId = m[0];
  const regexSupplier = /\d+$/;
  m = regexSupplier.exec(pathName);

  m = regexSupplier.exec(pathName);
  const supplierId = m[0];

  const orderCriteria = {
    id: transactionId,
    supplierId: supplierId
  };


  const orderItemCriteria = urlParams.getUrlParams();
  !orderItemCriteria.per_page && (orderItemCriteria.per_page = 10)
  !orderItemCriteria.page && (orderItemCriteria.page = 1)
  orderItemCriteria.id = transactionId;
  orderItemCriteria.supplierId = supplierId;
  urlParams.setUrlParams(orderItemCriteria);
  orderItemCriteria.order_type = orderItemsState.page.order_type;
  orderItemCriteria.order_by = orderItemsState.page.order_type;

  const loadOrderItems = () => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });
    getItemsEvaluation(orderItemCriteria).then(res => {

      const { results } = res.data;

      //initialize saved items
      let savedArray=[];
      for (let i = 0; i < results.length; i++) {
        savedArray[i] = true;
      }

      setOrderItemsState({
        ...orderItemsState,
        orderItems: results,
        isStarsSaved: savedArray,
        isNotesSaved: savedArray,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  }


  
  useEffect(() => {
    loadOrderItems();
    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();
    const isAsc = property === orderItemsState.page.order_by && orderItemsState.page.order_type === 'desc' ? 'asc' : 'desc';
    let filtersData = {
      ...orderItemsState.filters,
      per_page: orderItemsState.page.per_page,
      page: orderItemsState.page.page,
      order_type: isAsc,
      order_by: property,
      id: transactionId,
      supplierId: supplierId
    };
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    getItemsEvaluation(filtersData).then(res => {

      setOrderItemsState({
        ...orderItemsState,
        orderItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
    });

    const newCriteria = {
      ...orderItemsState.filters,
      per_page: orderItemsState.page.per_page,
      page: newPage + 1,
      id: transactionId,
      supplierId: supplierId
    };

    getItemsEvaluation(newCriteria).then(res => {
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
        orderItemsEditIndex: -1,
        orderItemsCreateObject: null,
        orderItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...orderItemsState.filters,
      page: 1,
      per_page: perPage,
      id: transactionId,
      supplierId: supplierId
    };



    getItemsEvaluation(newCriteria).then(res => {
     
      const { results } = res.data;
     
      //initialize saved items
      let savedArray = [];
      for (let i = 0; i < results.length; i++) {
        savedArray[i] = true;
      }

      setOrderItemsState({
        ...orderItemsState,
        orderItems: results,
        orderItemsEditIndex: -1,
        isLoading: false,
        isStarsSaved: savedArray,
        isNotesSaved: savedArray,
        orderItemsCreateObject: null,
        page: res.data.page,
        filters: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };


  const handleSearchItems = (data) => {

    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });


    let filtersData = {
      search: data.search,
      per_page: orderItemCriteria.per_page,
      page: 1,
      id: transactionId,
      supplierId: supplierId
    };
    getItemsEvaluation(filtersData).then(res => {
      const { results } = res.data;

      //initialize saved items
      let savedArray = [];
      for (let i = 0; i < results.length; i++) {
        savedArray[i] = true;
      }

      setOrderItemsState({
        ...orderItemsState,
        orderItems: results,
        orderItemsEditIndex: -1,
        orderItemsCreateObject: null,
        isLoading: false,
        isStarsSaved: savedArray,
        isNotesSaved: savedArray,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const classes = useStyles();


  const tableFilterComponent = () => {
    return <TableFilterItems
      config={{
        search: {
          enabled: true,
          callback: (e) => handleSearchItems(e)
        },
      }}
    />;
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeadersEvaluate}
      onSelectAllClick={() => { }}
      order={orderItemsState.page.order_type}
      orderBy={orderItemsState.page.order_by}
      rowCount={orderItemsState.page.length} />;
  };


  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={false}

    />;
  };

  const evalNotes = (data,pos) => {
    if (orderItemsState.orderItems[pos].id!==null) {
      const evalEditObject = Object.assign({}, orderItemsState.orderItems[pos]);
    
      const newEditEvaluation = {};
      newEditEvaluation.id = evalEditObject.id;
      newEditEvaluation.notes = data.notes;
      newEditEvaluation.product = evalEditObject.product;
      newEditEvaluation.rating = evalEditObject.rating;
      newEditEvaluation.supplier = evalEditObject.supplier;
      newEditEvaluation.transaction = evalEditObject.transaction;

      editItemEvaluation( orderItemsState.orderItems[pos].id, newEditEvaluation,)
      .then(res => {
        const editedRating = res.data;
        setOrderItemsState(
          oldOrderItemsState => {
            return {
              ...oldOrderItemsState,
              orderItems: oldOrderItemsState.orderItems.map((orderItem,index) => {
                return orderItem.id === editedRating.id
                  ? res.data : orderItem
              }),
              isLoading: false,
              isNotesSaved: {
                ...oldOrderItemsState.isNotesSaved,
                [pos]: true
              },
            }
          }
        );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrderItemsState({
          ...orderItemsState,
          isLoading: false,
          isNotesSaved: {
            ...orderItemsState.isNotesSaved,
            [pos]: false
          },
        });
      });

    } else {
      const evalCreateObject = Object.assign({}, orderItemsState.orderItems[pos]);

      const newEvaluation = {};
      newEvaluation.id = evalCreateObject.id;
      newEvaluation.notes = data.notes;
      newEvaluation.product = evalCreateObject.product;
      newEvaluation.rating = 0;
      newEvaluation.supplier = evalCreateObject.supplier;
      newEvaluation.transaction = evalCreateObject.transaction;


    addItemEvaluation(newEvaluation)
      .then(res => {
        const addedRating = res.data;
        setOrderItemsState(
          oldOrderItemsState => {
            return {
              ...oldOrderItemsState,
              orderItems: oldOrderItemsState.orderItems.map((orderItem,index) => {
                return pos === index
                  ? res.data : orderItem
              }),
              isLoading: false,
              isStarsSaved: {
                ...oldOrderItemsState.isStarsSaved,
                [pos]: true
              },
            }
          }
        );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrderItemsState({
          ...orderItemsState,
          isLoading: false,
          isStarsSaved: {
            ...orderItemsState.isStarsSaved,
            [pos]: false
          },
        });
      });
  }
  };
  const evalStars = (data, pos) => {

    if (orderItemsState.orderItems[pos].id!==null) {
      const evalEditObject = Object.assign({}, orderItemsState.orderItems[pos]);
    
      const newEditEvaluation = {};
      newEditEvaluation.id = evalEditObject.id;
      newEditEvaluation.notes = evalEditObject.notes;
      newEditEvaluation.product = evalEditObject.product;
      newEditEvaluation.rating = data.stars;
      newEditEvaluation.supplier = evalEditObject.supplier;
      newEditEvaluation.transaction = evalEditObject.transaction;

      editItemEvaluation( orderItemsState.orderItems[pos].id, newEditEvaluation,)
      .then(res => {
        const editedRating = res.data;
        setOrderItemsState(
          oldOrderItemsState => {
            return {
              ...oldOrderItemsState,
              orderItems: oldOrderItemsState.orderItems.map((orderItem,index) => {
                return orderItem.id === editedRating.id
                  ? res.data : orderItem
              }),
              isLoading: false,
              isStarsSaved: {
                ...oldOrderItemsState.isStarsSaved,
                [pos]: true
              },
            }
          }
        );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrderItemsState({
          ...orderItemsState,
          isLoading: false,
          isStarsSaved: {
            ...orderItemsState.isStarsSaved,
            [pos]: false
          },
        });
      });

    } else {
      const evalCreateObject = Object.assign({}, orderItemsState.orderItems[pos]);

      const newEvaluation = {};
      newEvaluation.id = evalCreateObject.id;
      newEvaluation.notes = evalCreateObject.notes;
      newEvaluation.product = evalCreateObject.product;
      newEvaluation.rating = data.stars;
      newEvaluation.supplier = evalCreateObject.supplier;
      newEvaluation.transaction = evalCreateObject.transaction;


    addItemEvaluation(newEvaluation)
      .then(res => {
        const addedRating = res.data;
        setOrderItemsState(
          oldOrderItemsState => {
            return {
              ...oldOrderItemsState,
              orderItems: oldOrderItemsState.orderItems.map((orderItem,index) => {
                return pos === index
                  ? res.data : orderItem
              }),
              isLoading: false,
              isStarsSaved: {
                ...oldOrderItemsState.isStarsSaved,
                [pos]: true
              },
            }
          }
        );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrderItemsState({
          ...orderItemsState,
          isLoading: false,
          isStarsSaved: {
            ...orderItemsState.isStarsSaved,
            [pos]: false
          },
        });
      });
  }
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {!orderItemsState.isLoading && Array.isArray(orderItemsState.orderItems)  && orderItemsState.orderItems.map((orderItem, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>{orderItem.product.code}</TableCell>
              <TableCell>{orderItem.product.name}</TableCell>
              <TableCell>
                <InlineRating
                  stars={orderItem.rating}
                  saved={orderItemsState.isStarsSaved[index]} 
                  error={orderItemsState.error}
                  getStars={(data) => evalStars(data, index)}
                />
              </TableCell>
              <TableCell>
                <InlineEditNotes
                  notes={orderItem.notes}
                  saved={orderItemsState.isStarsSaved[index]}
                  error={orderItemsState.error}
                  getNotes={(data) => evalNotes(data, index)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('ORDER_ITEMS_EVALUATION')}
        infoTabTitle={t('ORDER')}
        pagination={{
          total: orderItemsState.page.total || 0,
          page: orderItemsState.page.page - 1 || 0,
          perPage: orderItemsState.page.per_page || 10,
          count: orderItemsState.page.count || 0,
          disabled: false,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={false}
        newRowBtnEnabled={false}
        confirmDialog={orderItemsState.confirmDialog}
        breadcrump={breadcrump}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        isLoading={orderItemsState.isLoading}
        itemsTableTitle={t('ITEMS')}
      />
    </>
  );
};
export default OrderItemsEvaluation;
