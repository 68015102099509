import httpService from './httpService';

const getAllCatalogues = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCatalogueList(criteria);
  
};

export { getAllCatalogues };
  