import httpService from './httpService';

const getSystemVersion = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchSystemVersion(criteria);
};


export { getSystemVersion };