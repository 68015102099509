import React from 'react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from "@mui/icons-material/Check";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import StarRateIcon from '@mui/icons-material/StarRate';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import CompareIcon from '@mui/icons-material/Compare';
import useStyles from './styles';
import Button from '@mui/material/Button';


const BreadcrumbsActions = (props) => {

    const classes = useStyles();
    return (
        <>
            {props.config.submit.enabled && <Button
                variant="contained"
                style={!props.config.submit.disabled ? { backgroundColor: '#339966', color: '#ffffff' } : {}}
                className={classes.button +" "+"rfq-submit"}
                disabled={props.config.submit.disabled}
                onClick={props.config.submit.callback}
                startIcon={<CheckIcon />}
            >
                Submit
            </Button>}
            {props.config.accept.enabled && <Button
                variant="contained"
                style={{ backgroundColor: '#339966', color: '#ffffff' }}
                onClick={props.config.accept.callback}
                className={classes.button}
                startIcon={<SpellcheckIcon />}
            >
                Accept Changes
            </Button>}
            {props.config.sync.enabled && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={props.config.sync.callback}
                startIcon={<SyncIcon />}
            >
                Update
            </Button>}
            {props.config.delete.enabled && <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: '#ffffff' }}
                className={classes.button+" "+"rfq-delete"}
                onClick={props.config.delete.callback}
                startIcon={<DeleteIcon />}
            >
                Delete
            </Button>}
            {props.config.revise.enabled && <Button
                variant="contained"
                color="primary"
                onClick={props.config.revise.callback}
                className={classes.button}
                startIcon={<EditIcon />}
            >
                Revise
            </Button>}
            {props.config.decline.enabled && <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: '#ffffff' }}
                className={classes.button}
                onClick={props.config.decline.callback}
                startIcon={<BlockIcon />} >
                Decline
            </Button>}
            {props.config.quote.enabled && <Button
                variant="contained"
                color="secondary"
                onClick={props.config.quote.callback}
                className={classes.actionButton+' '+'quote-create'}
                startIcon={<PlaylistAddIcon />}
            >
                Quote
            </Button>}
            {props.config.order.enabled && <Button
                variant="contained"
                color="secondary"
                onClick={props.config.order.callback}
                className={classes.button}
                startIcon={<PlaylistAddIcon />}
            >
                Order
            </Button>}
            {props.config.compare.enabled && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={props.config.compare.callback}
                startIcon={<CompareIcon />} >
                Compare
            </Button>}
            {props.config.confirm.enabled && <Button
                variant="contained"
                style={{ backgroundColor: '#339966', color: '#ffffff' }}
                onClick={props.config.confirm.callback}
                className={classes.button}
                startIcon={<CheckIcon />}
            >
                Confirm
            </Button>}
            {props.config.cancel.enabled && <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: '#ffffff' }}
                onClick={props.config.cancel.callback}
                className={classes.button}
                startIcon={<CancelIcon />}
            >
                Cancel
            </Button>}
            {props.config.evaluateItems.enabled && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={props.config.evaluateItems.callback}
                startIcon={<StarRateIcon />} >
                Evaluate Items
            </Button>}
            {props.config.evaluateSupplier.enabled && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={props.config.evaluateSupplier.callback}
                startIcon={<StarRateIcon />} >
                Evaluate Supplier
            </Button>}

        </>
    );
}

export default BreadcrumbsActions;