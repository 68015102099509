import httpService from './httpService';

const getAllCountries = (criteria = {}) => {
  return httpService.FetchCountryList(criteria);
};

const getAllCountriesInfo = (criteria = {}) => {
  return httpService.FetchCountryInfoList(criteria);
};

const deleteCountry = (id) => {
  return httpService.DeleteCountry(id);
};

const editCountry = (id, data) => {
  return httpService.UpdateCountry({}, data);
};

const addCountry = (data) => {
  return httpService.CreateCountry({}, data);
};

const matchCountries = (data) => {
  return httpService.MatchCountry(data);
}

const clearCountryCache = (criteria = {}) => {
  return httpService.ClearCountryCache({},criteria);
};

export { getAllCountries, getAllCountriesInfo, editCountry, deleteCountry, addCountry, matchCountries, clearCountryCache };
