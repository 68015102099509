import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PercentagePresenter from "../../components/statistics/percentagePresenter";
import Paper from "@mui/material/Paper";
import ChartWithNoAxesAndNumber from "../../components/statistics/chartWithNoAxesAndNumber";
import DoughnutChart from "../../components/statistics/doughnutChart";
import DoughnutChartWithPercentage from "../../components/statistics/doughnutChartWithPercentage";
import PortStatsTable from '../../components/portStatsTable';
import VesselStatsTable from '../../components/vesselStatsTable';
import { getRFQStats, getQuoteStats, getOrderStats, getDeliveryStats, getTransactionStats, getPortStats, getVesselStats, getCatalogueStats } from '../../services/reportsService';
import t from '../../helpers/languages';
import './_dashboard.scss';


const Dashboard = (props) => {

  const [rfqsStatsState, setRfqsStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfRfqs: null,
    stats: [],
    toDate: null,
  });

  const [quotesStatsState, setQuotesStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfRfqs: null,
    numberOfQuotes: null,
    toDate: null,
  });

  const [ordersStatsState, setOrdersStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfQuotes: null,
    numberOfOrders: null,
    toDate: null,
  });

  const [deliveryStatsState, setDeliveryStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfDeliveryItems: null,
    numberOfQuoteItems: null,
    toDate: null,
  });

  const [transactionStatsState, setTransactionStatsState] = useState({
    isLoading: false,
    cancelled: null,
    companyId: null,
    completed: null,
    fromDate: null,
    pending: null,
    toDate: null,
    transactions: null,
  });

  const [portsStatsState, setPortsStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    stats: [],
    toDate: null,
  });

  const [vesselsStatsState, setVesselsStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    stats: [],
    toDate: null,
  });

  const [catalogueStatsState, setCatalogueStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    stats: [],
    toDate: null,
  });

  useEffect(() => {
    setRfqsStatsState({
      ...rfqsStatsState,
      isLoading: true,
    });

    setQuotesStatsState({
      ...quotesStatsState,
      isLoading: true,
    });

    setOrdersStatsState({
      ...ordersStatsState,
      isLoading: true,
    });

    setDeliveryStatsState({
      ...deliveryStatsState,
      isLoading: true,
    });

    setTransactionStatsState({
      ...transactionStatsState,
      isLoading: true,
    });

    setPortsStatsState({
      ...portsStatsState,
      isLoading: true,
    });

    setVesselsStatsState({
      ...vesselsStatsState,
      isLoading: true,
    });

    setCatalogueStatsState({
      ...catalogueStatsState,
      isLoading: true,
    });

    getRFQStats().then(res => {
      const results = res.data;
      setRfqsStatsState({
        ...rfqsStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfRfqs: results.numberOfRfqs,
        stats: results.stats,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setRfqsStatsState({
        ...rfqsStatsState,
        isLoading: false,
      });
    });
    getQuoteStats().then(res => {
      const results = res.data;
      setQuotesStatsState({
        ...quotesStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfRfqs: results.numberOfRfqs,
        numberOfQuotes: results.numberOfQuotes,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setQuotesStatsState({
        ...quotesStatsState,
        isLoading: false,
      });
    });
    getOrderStats().then(res => {
      const results = res.data;
      setOrdersStatsState({
        ...ordersStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfOrders: results.numberOfOrders,
        numberOfQuotes: results.numberOfQuotes,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setOrdersStatsState({
        ...ordersStatsState,
        isLoading: false,
      });
    });
    getDeliveryStats().then(res => {
      const results = res.data;
      setDeliveryStatsState({
        ...deliveryStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfDeliveryItems: results.numberOfDeliveryItems,
        numberOfQuoteItems: results.numberOfQuoteItems,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setDeliveryStatsState({
        ...deliveryStatsState,
        isLoading: false,
      });
    });
    getTransactionStats().then(res => {
      const results = res.data;
      setTransactionStatsState({
        ...transactionStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        cancelled: results.cancelled,
        completed: results.completed,
        pending: results.pending,
        transactions: results.transactions,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setTransactionStatsState({
        ...transactionStatsState,
        isLoading: false,
      });
    });
    getPortStats().then(res => {
      const results = res.data;
      setPortsStatsState({
        ...portsStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        stats: results.stats,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setPortsStatsState({
        ...portsStatsState,
        isLoading: false,
      });
    });
    getVesselStats().then(res => {
      const results = res.data;
      setVesselsStatsState({
        ...vesselsStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        stats: results.stats,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setVesselsStatsState({
        ...vesselsStatsState,
        isLoading: false,
      });
    })
    props.userState.user.userRole!=='SUPPLIER'&&getCatalogueStats().then(res => {
      const results = res.data;
      setCatalogueStatsState({
        ...catalogueStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        stats: results.stats,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setCatalogueStatsState({
        ...catalogueStatsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);


  const calculatePercentage = (a, b) => {

    return b > 0 ? Math.round((a / b) * 100) : 0;

  };

  const portStatsTable = () => {
    return (
      portsStatsState.stats.length !== 0 && <PortStatsTable
        toolbarTitle={t('PORTS')}
        subtitleToolbarTitle={t('TOP_10_SPENDING_PORTS')}
        stats={portsStatsState.stats}
      />
    )
  };

  const vesselStatsTable = () => {
    return (
      vesselsStatsState.stats.length !== 0 && <VesselStatsTable
        toolbarTitle={t('VESSELS')}
        subtitleToolbarTitle={t('TOP_10_SPENDING_VESSELS')}
        stats={vesselsStatsState.stats}
      />
    )
  };

  return (
    <main className='dashboard'>
      <div className='dashboard__total-amount-container'>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={3}
        >
          {!rfqsStatsState.isLoading && rfqsStatsState.stats && rfqsStatsState.stats.length !== 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                elevation={4}
              >
                <ChartWithNoAxesAndNumber theme={'red'} stats={rfqsStatsState.stats} amount={rfqsStatsState.numberOfRfqs} label={'Total Rfq\'s'} />
              </Paper>
            </Grid>
          )}
          {!transactionStatsState.isLoading && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                elevation={4}
              >
                <DoughnutChart theme={'black'} completed={transactionStatsState.completed} pending={transactionStatsState.pending} canceled={transactionStatsState.cancelled} label={'Requests'} />
              </Paper>
            </Grid>
          )}
          {!catalogueStatsState.isLoading && catalogueStatsState.stats && catalogueStatsState.stats.length !== 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                elevation={4}
              >
                <DoughnutChartWithPercentage theme={'green'} stats={catalogueStatsState.stats} label={'OpEx'} subtitleToolbarTitle={'Top 5 consumption categories'} />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
            >
              <PercentagePresenter theme={'green'} percentage={calculatePercentage(quotesStatsState.numberOfQuotes, quotesStatsState.numberOfRfqs)} amount={quotesStatsState.numberOfQuotes} label={'Total Quotes'} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
            >
              <PercentagePresenter theme={'blue'} percentage={calculatePercentage(ordersStatsState.numberOfOrders, ordersStatsState.numberOfQuotes)} amount={ordersStatsState.numberOfOrders} label={'Total Orders'} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
            >
              <PercentagePresenter theme={'springGreen'} percentage={calculatePercentage(deliveryStatsState.numberOfDeliveryItems, deliveryStatsState.numberOfQuoteItems)} amount={deliveryStatsState.numberOfDeliveryItems} label={'Total Deliveries'} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={4}
            >
              {portStatsTable()}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={4}
            >
              {vesselStatsTable()}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default Dashboard;
