import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    previewChipExisting: {
      minWidth: 160,
      maxWidth: 210,
      marginRight: 8
    },
    dropZone:{
      minHeight:30,
      border: 'none',
    },
    dropZoneText:{
      maxHeight:10,
      fontSize:'1rem',
      marginTop: 5,
      marginBottom: 12,
      textAlign: 'left',
    },
    
  }));

  export default useStyles;