import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeaders, newPricelist } from '../../helpers/pricelistsConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import dateToString from '../../helpers/dateToString';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {  editVessel, addVessel } from '../../services/vesselsService';
import { getAllInfoContracts, deleteContract, } from '../../services/contractsService';
import { PricelistTableFilter } from '../../components/pricelistTableFilter';
import moment from 'moment';
import './_pricelists.scss';
import t from '../../helpers/languages';

const Pricelists = (props) => {

  const pricelistInitObject = () => {
    return {
      id: '?',
      name: '',
      company: { name: '', id: '' },
      dateFrom: null,
      dateTo: null
    }
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });


  const filters = localStoreGetObj('pricelistFilter');

  const [pricelistsState, setPricelistsState] = useState({
    pricelists: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    pricelistEditIndex: -1,
    pricelistEditObject: pricelistInitObject(),
    pricelistCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let companyId = null;

  const pathName = window.location.pathname;
  const regex = /(\d+)/gm;
  let m;
  m = regex.exec(pathName);
  companyId = m[0];

  const filtersData = {
    ...pricelistsState.filters,
    supplier: companyId,
    searchDate: filters && filters.searchDate && filters.searchDate,
    per_page: criteria.per_page,
    page: criteria.page,
    order_type: pricelistsState.page.order_type,
    order_by: pricelistsState.page.order_by,
  };


  useEffect(function () {
    getAllInfoContracts(filtersData).then(res => {
      const { results } = res.data;
      setPricelistsState({
        ...pricelistsState,
        pricelists: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === pricelistsState.page.order_by && pricelistsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setPricelistsState({
      ...pricelistsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllInfoContracts().then(res => {
      const { results } = res.data;
      setPricelistsState({
        ...pricelistsState,
        pricelists: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setPricelistsState({
      ...pricelistsState,
      isLoading: true
    });
    const newCriteria = {
      ...pricelistsState.filters,
      per_page: pricelistsState.page.per_page,
      page: newPage + 1
    };
    getAllInfoContracts(newCriteria).then(res => {
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
        pricelists: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setPricelistsState({
      ...pricelistsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...pricelistsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllInfoContracts(newCriteria).then(res => {
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
        pricelists: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
      });
    });
  };

  const handleEditPricelist = (event, pricelistIndex) => {

    event.stopPropagation();

    const pricelistEditObject = Object.assign({}, pricelistsState.pricelists[pricelistIndex]);


    setPricelistsState({
      ...pricelistsState,
      pricelistEditIndex: pricelistIndex,
      pricelistEditObject: pricelistEditObject
    });

  };

  const onEditablePricelistChange = (event, property) => {

    const newValue = event.target.value;

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistEditObject);
    newEditPricelistItem[property] = newValue;

    setPricelistsState({
      ...pricelistsState,
      pricelistEditObject: newEditPricelistItem
    });
  };
  const onEditableDateFromChange = (date) => {

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistEditObject);

    if (moment(date).isValid() && !isNaN(date)) {
      newEditPricelistItem['dateFrom'] = dateToString(date) + 'T00:00:00Z';
    } else if (date === null) {
      newEditPricelistItem['dateFrom'] = date;
    }
    setPricelistsState({
      ...pricelistsState,
      pricelistEditObject: newEditPricelistItem
    });

  };

  const onEditableDateToChange = (date) => {

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistEditObject);

    if (moment(date).isValid() && !isNaN(date)) {
      newEditPricelistItem['dateFrom'] = dateToString(date) + 'T00:00:00Z';
    } else if (date === null) {
      newEditPricelistItem['dateFrom'] = date;
    }
    setPricelistsState({
      ...pricelistsState,
      pricelistEditObject: newEditPricelistItem
    });

  };

  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSavePricelist = (event, props) => {
    event.preventDefault();
    setPricelistsState({
      ...pricelistsState,
      isLoading: true
    });
    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistEditObject);

    if (validator.current.allValid()) {
      editVessel(newEditPricelistItem.id, newEditPricelistItem)
        .then(re => {
          const editedPricelist = re.data;
          setPricelistsState(oldPricelists => {
            return {
              ...oldPricelists,
              pricelists: oldPricelists.pricelists.map(pricelist => {
                return pricelist.id === editedPricelist.id
                  ? re.data : pricelist
              }),
              pricelistEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPricelistsState({
            ...pricelistsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditPricelist = (e, index) => {
    setPricelistsState({
      ...pricelistsState,
      pricelistEditIndex: -1
    })
  };

  const handleDeletePricelist = (event, index) => {

    const pricelistForDeletion = pricelistsState.pricelists[index];
    setPricelistsState({
      ...pricelistsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?\n'+
        '"Delete takes some time - please be patient after you select OK."',
        title: 'Delete Pricelist',
        onCancel: () => {
          setPricelistsState({
            ...pricelistsState,
            confirmDialog: {
              ...pricelistsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteContract(pricelistForDeletion.id).then(() => {
            const newPricelists = [...pricelistsState.pricelists];
            newPricelists.splice(index, 1);
            setPricelistsState({
              ...pricelistsState,
              pricelists: newPricelists,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewPricelist = (e) => {
    e.preventDefault();

    setPricelistsState({
      ...pricelistsState,
      pricelistCreateObject: newPricelist()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewPricelist = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newPricelist = {};
    newPricelist.name = pricelistsState.pricelistCreateObject.name;
    newPricelist.dateFrom = pricelistsState.pricelistCreateObject.dateFrom;
    newPricelist.dateTo = pricelistsState.pricelistCreateObject.dateTo;


    if (validator.current.allValid()) {
      addVessel(newPricelist)
        .then(res => {
          setPricelistsState({
            ...pricelistsState,
            pricelists: [...pricelistsState.pricelists, res.data],
            pricelistCreateObject: null,
            pricelistEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPricelistsState({
            ...pricelistsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewPricelistObject = (e, property) => {
    const newValue = e.target.value;

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistCreateObject);
    newEditPricelistItem[property] = newValue;

    setPricelistsState({
      ...pricelistsState,
      pricelistCreateObject: newEditPricelistItem
    });
  };

  const onEditNewDateFromChange = (date) => {

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistCreateObject);

    if (moment(date).isValid() && !isNaN(date)) {
      newEditPricelistItem['dateFrom'] = dateToString(date) + 'T00:00:00Z';
    } else if (date === null) {
      newEditPricelistItem['dateFrom'] = date;
    }
    setPricelistsState({
      ...pricelistsState,
      pricelistCreateObject: newEditPricelistItem
    });

  };

  const onEditNewDateToChange = (date) => {

    const newEditPricelistItem = Object.assign({}, pricelistsState.pricelistCreateObject);

    if (moment(date).isValid() && !isNaN(date)) {
      newEditPricelistItem['dateFrom'] = dateToString(date) + 'T00:00:00Z';
    } else if (date === null) {
      newEditPricelistItem['dateFrom'] = date;
    }
    setPricelistsState({
      ...pricelistsState,
      pricelistCreateObject: newEditPricelistItem
    });

  };


  const filterData = (data) => {
    setPricelistsState({
      ...pricelistsState,
      isLoading: true
    });

    const filtersData = {
        ...pricelistsState.filters,
        searchDate: data && data.searchDate,
        supplier: companyId
      };
    

    getAllInfoContracts(filtersData).then(res => {
      const { results } = res.data;

      setPricelistsState({
        ...pricelistsState,
        pricelists: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('pricelistFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPricelistsState({
        ...pricelistsState,
        isLoading: false,
      });
    });

  };


  const tableFilterComponent = () => {
    return <PricelistTableFilter
      searchDate={(filters && filters.searchDate) ? filters.searchDate : pricelistsState.filters.searchDate}
      autonomousPage={props.autonomousPage}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(pricelistsState.pricelists) && pricelistsState.pricelists.map((pricelist, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (pricelistsState.pricelistEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{pricelist.description}</TableCell>
                    <TableCell>{pricelist.productCategory?.name}</TableCell>
                    <TableCell>{pricelist.dateFrom}</TableCell>
                    <TableCell>{pricelist.dateTo}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={pricelistsState.pricelistEditObject.name || ''}
                          onChange={e => onEditablePricelistChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', pricelistsState.pricelistEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <DatePicker
                        minDate={new Date(2015, 1, 1)}
                        maxDate={new Date(2030, 1, 1)}
                        label={t('FROM')}
                        value={pricelistsState.pricelistEditObject.dateFrom}
                        inputFormat="yyyy-MM-DD"
                        inputVariant="outlined"
                        onChange={onEditableDateFromChange}
                        initialFocusedDate={pricelistsState.pricelistEditObject.dateFrom}
                        clearable
                        renderInput={(params) => <TextField {...params} />}
                      /></TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <DatePicker
                        minDate={new Date(2015, 1, 1)}
                        maxDate={new Date(2030, 1, 1)}
                        label={t('TO')}
                        inputFormat="yyyy-MM-DD"
                        value={pricelistsState.pricelistEditObject.dateTo}
                        inputVariant="outlined"
                        onChange={onEditableDateToChange}
                        clearable
                        renderInput={(params) => <TextField {...params} />}
                      /></TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: false,
                    callback: (e) => handleEditPricelist(e, index)
                  },
                  delete: {
                    enabled: pricelistsState.pricelistEditIndex !== index,
                    callback: (e) => handleDeletePricelist(e, index)
                  },
                  close: {
                    enabled: false,
                    callback: (e) => handleCloseEditPricelist(e, index)
                  },
                  save: {
                    enabled: false,
                    callback: (e) => handleSavePricelist(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          pricelistsState.pricelistCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={pricelistsState.pricelistCreateObject.name || ''}
                  onChange={e => onEditNewPricelistObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', pricelistsState.pricelistCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <DatePicker
                  minDate={new Date(2015, 1, 1)}
                  maxDate={new Date(2030, 1, 1)}
                  label={t('FROM')}
                  value={pricelistsState.pricelistCreateObject.dateFrom}
                  inputFormat="yyyy-MM-DD"
                  inputVariant="outlined"
                  onChange={onEditNewDateFromChange}
                  initialFocusedDate={pricelistsState.pricelistCreateObject.dateFrom}
                  clearable
                  renderInput={(params) => <TextField {...params} />}
                /></TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <DatePicker
                  minDate={new Date(2015, 1, 1)}
                  maxDate={new Date(2030, 1, 1)}
                  label={t('TO')}
                  inputFormat="yyyy-MM-DD"
                  value={pricelistsState.pricelistEditObject.dateTo}
                  inputVariant="outlined"
                  onChange={onEditNewDateToChange}
                  clearable
                  renderInput={(params) => <TextField {...params} />}
                />
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setPricelistsState({ ...pricelistsState, pricelistCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewPricelist()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PRICELIST')}
        pagination={{
          total: pricelistsState.page.total || 0,
          page: pricelistsState.page.page - 1 || 0,
          perPage: pricelistsState.page.per_page || 10,
          count: pricelistsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewPricelist}
        newRowBtnEnabled={false}
        confirmDialog={pricelistsState.confirmDialog.message.length > 0 ? pricelistsState.confirmDialog : pricelistsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={pricelistsState.isLoading}
      />
    </>
  );
};


export default Pricelists;
