import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { debounce, Grid, TextField } from "@mui/material";
import FilterCompanies from "../filterCompanies";
import './_dictionaryTableFilter.scss';
import t from '../../helpers/languages';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const DictionaryTableFilter = ({ company: initialCompany, searchText: initialSearchText, autonomousPage, getFilterData, filterLabel }) => {
  const classes = useStyles();

  const [company, setCompany] = useState(initialCompany);
  // const [product, setProduct] = useState(initialProduct);
  const [searchText, setSearchText] = useState(initialSearchText||'');


  const selectedCompany = (data) => {
    setCompany(data.company);
    const filterData = {
      searchText: searchText,
      company: data.company
    };

    getFilterData(filterData);
  }

  const handleChange = (event) => {
    const value = event && event.target && event.target.value;
    setSearchText(event.target.value);
    if (value && value.length >= 3 && searchText !== value) {
      const filterData = {
        searchText: event.target.value,
        company: company
      };
      debounceFn(filterData);
    }
    else { // when you clear the search field force backend to send all data.
      const filterData = {
        searchText: '',
        company: company
      };
      debounceFn(filterData);
    }
  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);


  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const filterData = {
  //     searchText: searchText ,
  //     company: company
  //   };
  //   getFilterData(filterData);
  // };

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
       {autonomousPage && <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCompanies
              company={company}
              companySearchFor={'productsCompany'} // in case the buyer is called company in the table. This sorts by buyer but the label says company.
              getSelectedCompany={selectedCompany}
              errorMessage={''}
            />
          </FormControl>
        </Grid>}
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={searchText}
              label={filterLabel}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        {/* <FormControl className={classes.formControl}>
          <Button
            type={'submit'}
            variant="contained"
            size="medium"
            style={{ width: 'auto', padding: '16px' }}
            startIcon={<SearchRoundedIcon></SearchRoundedIcon>}
            color="primary"
            onClick={e => handleSubmit(e)}>
            Search
          </Button>
        </FormControl> */}
      </Grid>
    </form>
  );
}