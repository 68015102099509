import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersPortRegions, newPortRegion } from '../../helpers/portsRegionConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { editPort} from '../../services/portsService';
import {getAllRegionPorts,  addOriginalRegionPort, deleteOriginalRegionData } from '../../services/originalRegionService';
import { FilterByText } from '../../components/filterByText';
import FilterPorts from "../../components/filterPorts";
import './_portsRegion.scss';
import t from '../../helpers/languages';

const PortsRegion = (props) => {

  const portInitObject = () => {
    return  {} ;
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('portsRegionFilter');

  const [portsState, setPortsRegionState] = useState({
    ports: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    portEditIndex: -1,
    portEditObject: portInitObject(),
    portCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });


  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};

  let regionId = null;

  if (!props.autonomousPage) {
    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;
    m = regex.exec(pathName);
    regionId = m[0];
  }



  filtersData = {
    ...portsState.filters,
    search: filters && filters.searchText,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllRegionPorts(regionId,filtersData).then(res => {
      const { results } = res.data;

      setPortsRegionState({
        ...portsState,
        ports: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsRegionState({
        ...portsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setPortsRegionState({
      ...portsState,
      isLoading: true
    });
    const newCriteria = {
      ...portsState.filters,
      per_page: portsState.page.per_page,
      page: newPage + 1
    };
    getAllRegionPorts(regionId, newCriteria).then(res => {
      setPortsRegionState({
        ...portsState,
        isLoading: false,
        ports: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setPortsRegionState({
      ...portsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...portsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllRegionPorts(regionId, newCriteria).then(res => {
      setPortsRegionState({
        ...portsState,
        isLoading: false,
        ports: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsRegionState({
        ...portsState,
        isLoading: false,
      });
    });
  };

  const handleEditPort = (event, portIndex) => {

    event.stopPropagation();

    const portEditObject = Object.assign({}, portsState.ports[portIndex]);


    setPortsRegionState({
      ...portsState,
      portEditIndex: portIndex,
      portEditObject: portEditObject
    });

  };

  const onEditablePortChange = (event, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }


    const newEditPortItem = Object.assign({}, portsState.portEditObject);
    newEditPortItem[property] = newValue;

    setPortsRegionState({
      ...portsState,
      portEditObject: newEditPortItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSavePort = (event, props) => {
    event.preventDefault();
    setPortsRegionState({
      ...portsState,
      isLoading: true
    });
    const newEditPortItem = Object.assign({}, portsState.portEditObject);

    if (validator.current.allValid()) {
      editPort(newEditPortItem.id, newEditPortItem)
        .then(re => {
          const editedPort = re.data;
          setPortsRegionState(oldPortsRegion => {
            return {
              ...oldPortsRegion,
              ports: oldPortsRegion.ports.map(port => {
                return port.id === editedPort.id
                  ? re.data : port
              }),
              portEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPortsRegionState({
            ...portsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setPortsRegionState({
        ...portsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditPort = (e, index) => {
    setPortsRegionState({
      ...portsState,
      portEditIndex: -1
    })
  };

  const handleDeletePort = (event, index) => {

    const portForDeletion =  {};
    
    portForDeletion.ports=[{
      portId: portsState.ports[index].portId,
      name: portsState.ports[index].name,
    }];

    setPortsRegionState({
      ...portsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Port',
        onCancel: () => {
          setPortsRegionState({
            ...portsState,
            confirmDialog: {
              ...portsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOriginalRegionData(regionId,portForDeletion ).then(() => {
            const newPortRegionsRegion = [...portsState.ports];
            newPortRegionsRegion.splice(index, 1);
            setPortsRegionState({
              ...portsState,
              ports: newPortRegionsRegion,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewPort = (e) => {
    e.preventDefault();

    setPortsRegionState({
      ...portsState,
      portCreateObject: newPortRegion()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewPort = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newPortRegion = {};
    newPortRegion.ports = [{
      portId: portsState.portCreateObject.port.id,
      name: portsState.portCreateObject.port.name,
    }];

   

    if (validator.current.allValid()) {
      addOriginalRegionPort(regionId, newPortRegion)
        .then(res => {
          setPortsRegionState({
            ...portsState,
            ports: [...portsState.ports, res.data],
            portCreateObject: null,
            portEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPortsRegionState({
            ...portsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const selectedPort = (data, action) => {
    const newEditPortItem  = Object.assign(
      {},
      action === "edit"
        ? portsState.portEditObject
        : portsState.portCreateObject
    );

    
    newEditPortItem.port = data.port;

    setPortsRegionState({
      ...portsState,
      [action === "edit" ? "portEditObject" : "portCreateObject"]:
      newEditPortItem ,
    });
  };

  const onEditNewPortObject = (event, property) => {
    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditPortItem = Object.assign({}, portsState.portCreateObject);
    newEditPortItem[property] = newValue;

    setPortsRegionState({
      ...portsState,
      portCreateObject: newEditPortItem
    });
  };

  const filterData = (data) => {
    setPortsRegionState({
      ...portsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...portsState.filters,
      search: data && data.searchText,
    };
    

    getAllRegionPorts(regionId, filtersData).then(res => {
      const { results } = res.data;

      setPortsRegionState({
        ...portsState,
        ports: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('portsRegionFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsRegionState({
        ...portsState,
        isLoading: false,
      });
    });

  };



  const tableFilterComponent = () => {
    return <FilterByText
      searchText={(filters && filters.searchText) ? filters.searchText : portsState.filters.search}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersPortRegions}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(portsState.ports) && portsState.ports.map((port, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (portsState.portEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{port.name}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterPorts
                          port={portsState.portEditObject.port || null}
                          getSelectedPort={(data) => selectedPort(data, "edit")}
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: false,
                    callback: () => null
                  },
                  delete: {
                    enabled: portsState.portEditIndex !== index,
                    callback: (e) => handleDeletePort(e, index)
                  },
                  close: {
                    enabled: portsState.portEditIndex === index,
                    callback: (e) => handleCloseEditPort(e, index)
                  },
                  save: {
                    enabled: portsState.portEditIndex === index,
                    callback: (e) => handleSavePort(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          portsState.portCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterPorts
                    port={portsState.portCreateObject.port || null}
                    getSelectedPort={(data) => selectedPort(data, "create")}
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setPortsRegionState({ ...portsState, portCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewPort()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PORT_REGIONS')}
        pagination={{
          total: portsState.page.total || 0,
          page: portsState.page.page - 1 || 0,
          perPage: portsState.page.per_page || 10,
          count: portsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewPort}
        newRowBtnEnabled={!portsState.portCreateObject}
        confirmDialog={portsState.confirmDialog.message.length > 0 ? portsState.confirmDialog : portsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={portsState.isLoading}
      />
    </>
  );
};


export default PortsRegion;
