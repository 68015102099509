const tableHeadersBuyer = [
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'vesselType', disablePadding: false, label: 'VesselType' },
  { id: 'age', disablePadding: false, label: 'Age'},
  { id: 'dwt', disablePadding: false, label: 'DWT'},
  { id: 'supplier', disablePadding: false, label: 'Supplier'},
  { id: 'usd', disablePadding: false, label: 'USD' },
];

const tableHeadersSupplier = [
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'vesselType', disablePadding: false, label: 'VesselType' },
  { id: 'age', disablePadding: false, label: 'Age'},
  { id: 'dwt', disablePadding: false, label: 'DWT'},
  { id: 'buyer', disablePadding: false, label: 'Buyer'},
  { id: 'usd', disablePadding: false, label: 'USD' },
];

export { tableHeadersBuyer, tableHeadersSupplier };