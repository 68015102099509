import LocalizedStrings from 'react-localization';

import languages from '../languages';
import { flattenObject } from './chisels';

// Get localized strings in the application language.
const strings = new LocalizedStrings(Object.keys(languages).reduce((acc, key) => {
  return { ...acc, [key]: flattenObject(languages[key]) };
}, {}));

// Select language from redux store
strings.setLanguage('en');

// Translates the given string with the given values for any placeholders.
const t = (str, ...values) => {
  const translated = strings[str];
  if (!translated) {
    return str;
  }
  if (!values || !values.length) {
    return translated;
  }
  // NOTE: Something is wrong with JSX elements passed as strings.
  const formatted = strings.formatString(translated, ...values);
  return 'string' === typeof formatted ? formatted : translated;
};

export default t;
