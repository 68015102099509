import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from "./styles";
import { PropaneSharp } from '@mui/icons-material';

const DoughnutChartWithGrid = (props) => {
    const classes = useStyles();

    useEffect(() => {

        const data = props.data;
        const label = props.labels;
        let randomBackgroundColor = [];
        let usedColors = new Set();

        let dynamicColors = function () {
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            let color = "rgb(" + r + "," + g + "," + b + ")";

            if (!usedColors.has(color)) {
                usedColors.add(color);
                return color;
            } else {
                return dynamicColors();
            }
        };

        for (let i in data) {
            randomBackgroundColor.push(dynamicColors());
        }
        const con = document.getElementById(props.id);
        let c = new Chart(con, {
            type: 'doughnut',
            plugins: [ChartDataLabels],
            data: {
                labels: label,
                datasets: [{
                    data: data,
                    // label: "Rfq's",
                    backgroundColor: randomBackgroundColor,

                    // [
                    //     'rgba(102, 255, 181, 1)',
                    //     'rgba(124, 188, 255, 1)',
                    //     'rgba(243, 217, 102, 1)',
                    //     'rgba(245, 70, 95, 1)',
                    //     'rgba(255, 206, 86, 1)',
                    // ],
                    // borderColor: [
                    //     'rgba(102, 255, 181, 1)',
                    //     'rgba(124, 188, 255, 1)',
                    //     'rgba(243, 217, 102, 1)',
                    //     'rgba(245, 70, 95, 1)',
                    //     'rgba(255, 206, 86, 1)',
                    // ],
                    borderWidth: .5,
                    lineWidth: .3,
                    datalabels: {
                        // anchor: 'end',
                        display: true,
                      }
                }]
            },
            options: {
                cutout: '70%',
                layout: {
                    padding: {
                        left: 30
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            usePointStyle: true
                        }
                    },
                    datalabels: {
                        
                        formatter: (value, ctx) => {
                            // let sum = 0;
                            // let dataArr = ctx.chart.data.datasets[0].data;
                            // dataArr.map(data => {
                            //     sum += data;
                            // });
                            // let percentage = (value * 100 / sum).toFixed(2) + "%";
                            return value + "%";
                        },
                        // anchor: 'end',
                   //     display: true,
                        // align: 'end',
                        // labels: {
                        //     value: {
                        //         color: 'black'
                        //     }
                        // },
                        backgroundColor: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        borderColor: 'white',
                        borderRadius: 25,
                        borderWidth: 2,
                        color: 'white',
                        display: function (context) {
                            var dataset = context.dataset;
                            var count = dataset.data.length;
                            var value = dataset.data[context.dataIndex];
                            return value > count * 1.5;
                        },
                        font: {
                            weight: 'bold'
                        },
                        padding: 6,
                    },
                    tooltip: {
                        callbacks: {
                          label: function (context) {
                            return context.formattedValue + "%";
                          }
                        }
                      },
                },
                responsive: true,
            },
            // Core options
            aspectRatio: 4 / 3,
            cutoutPercentage: 32,
            layout: {
                padding: 32
            },
            elements: {
                line: {
                    fill: false
                },
                point: {
                    hoverRadius: 7,
                    radius: 5
                }
            },
        });
        return () => { c.destroy(); }
    }, [props.stats]);

    // const themes = {
    //     red: {
    //         fontColor: '#ff4861',
    //         progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
    //     },
    //     springGreen: {
    //         fontColor: '#00ff7f',
    //         progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
    //     },
    //     green: {
    //         fontColor: '#b8e986',
    //         progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
    //     },
    //     blue: {
    //         fontColor: '#48b5ff',
    //         progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
    //     },
    // };

    return (


        <section
            className={classes.chartRoot}
        >
            <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <canvas
                    id={props.id}
                    width={props.doughnutWidth}
                // height={350}
                // height={'100%'}
                >
                </canvas>
            </Grid>
        </section>


    )
};

export default DoughnutChartWithGrid;