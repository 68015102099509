import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/tableHeader';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import FilterProducts from '../../components/filterProducts';
import FilterUnits from '../../components/filterUnits';
import TableActions from '../../components/TableActions/TableActions';
import {
  getOrderById,
  deleteOrder,
  editOrder,
  importOrder,
  updateOrderStatus,
  reviseOrder,
  submitOrder,
  syncOrder,
  confirm,
  accept,
  cancel,
  getOrderAttachment,
  addOrderAttachment,
  deleteOrderAttachment,
  printOrder,
  importDeliveryInvoices
} from '../../services/ordersService';
import {
  getOrderItems,
  edit,
  add,
  deleteOrderItem,
  bulkEditOrderItems
} from '../../services/orderItemsService';
import DropzoneComponent from '../../components/dropzoneComponent/DropzoneComponent';
import { tableHeaders, newOrderItem } from '../../helpers/orderItemsConfig';
import SimpleReactValidator from 'simple-react-validator';
import OrderInfo from '../../components/orderInfo';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import FileUploader from '../../components/fileUploader';
import { TableFilterItems } from '../../components/tableFilterItems';
import PrintPdf from '../../components/printPdf';
import MembersChat from '../../components/membersChat';
import { useKeyDown } from '../../hooks/useKeyDown';
import Sha256 from 'crypto-js/sha256';
import { ValidateField } from './Validator';
import './orderItemsStyles.css'

import t from '../../helpers/languages';

const OrderItems = (props) => {
  const orderItemEditObjectRef = useRef([]);
  const [disabled, setDisabled] = React.useState(true);
  const [, setDate] = React.useState('');
  const [newFiles, setNewFiles] = useState([]);
  const [pageCost, setPageCost] = useState(null);
  const [orderItemsCost, setOrderItemsCost] = useState(null)
  const [dropkey, setDropKey] = useState(1);
  const [, setOrderFiles] = React.useState('');
  const [, setORDERNotes] = React.useState('');
  const [, setOrderItemEditObject] = useState({});
  const [bulkEdit, setBulkEdit] = React.useState(false);
  const activeIndex = useRef(-1); // Track which cell to highlight
  const inputRefs = React.useRef([]);
  const userState = props.userState && props.userState.user;
  const totalCostAmountOnEditState = React.useRef(0);

  const orderInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      status: '',
      category: { name: '', id: '' },
      agent: { id: '', name: '', telephone: '', faxNo: '', email: '', address: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      discount: '',
      due: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      orderd_items: '',
      isCompleted: ''
    }
  };

  const [ordersState, setOrdersState] = useState({
    orders: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {},
    isLoading: true,
    orderEditIndex: -1,
    orderEditObject: orderInitObject(),
    orderCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
    error: null,
    isOrderSaved: false,
    isNotesSaved: true,
    isDueSaved: true,
    isAgentTelephoneNoSaved: true,
    isAgentNameSaved: true,
    isAgentAddressSaved: true,
    isAgentEmailSaved: true,
    isAgentFaxNoSaved: true,
    isReferenceNumberSaved: true,
    isSupplierReferenceSaved: true,
    isSupplierNotesSaved: true,
  });

  const orderItemInitObject = () => {
    return {
      category: { id: '', name: '', code: '' },
      discount: '',
      leadTimeDays: '',
      notes: '',
      id: '',
      product: { code: '', id: '', name: '' },
      productDescription: '',
      totalCost: '',
      quantity: '',
      orderId: '',
      remote_id: '',
      orderitem_id: '',
      unit: { id: '', name: '', categoryId: '', categoryName: '' },
      supplierUnit:{ id: '', name: '' },
      unitCost: '',
      net_price: '',
      unitDescription: '',
      add: true
    }
  };


  const [orderItemsState, setOrderItemsState] = useState({
    orderItems: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    orderItemEditIndex: -1,
    orderItemEditObject: orderItemInitObject(),
    orderItemCreateObject: null,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });


  const pathName = window.location.pathname;
  const regex = /\d+$/;
  let m;

  m = regex.exec(pathName);

  const orderId = m[0];

  const orderCriteria = { id: orderId };

  const orderItemCriteria = urlParams.getUrlParams();
  !orderItemCriteria.per_page && (orderItemCriteria.per_page = 10)
  !orderItemCriteria.page && (orderItemCriteria.page = 1)
  orderItemCriteria.id = orderId;
  urlParams.setUrlParams(orderItemCriteria);
  orderItemCriteria.order_type = orderItemsState.page.order_type;
  orderItemCriteria.order_by = orderItemsState.page.order_type;

  const loadOrderItems = () => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });
    getOrderItems(orderItemCriteria).then(res => {
      const { results } = res.data;
  
      setOrderItemsState({
        ...orderItemsState,
        orderItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      let totalItems = 0;
      results.map((item) => {
        totalItems = totalItems + item.totalCost;
      });
      setPageCost(totalItems);
      setOrderItemsCost(results);
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  }

  useEffect(() => {
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    loadOrderItems();
    getOrderById(orderCriteria).then(res => {
      setOrdersState({
        ...ordersState,
        orders: res.data,
        isLoading: false
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();


    const isAsc = property === orderItemsState.page.order_by && orderItemsState.page.order_type === 'desc' ? 'asc' : 'desc';
    let filtersData = {
      ...orderItemsState.filters,
      per_page: orderItemsState.page.per_page,
      page: orderItemsState.page.page,
      order_type: isAsc,
      order_by: property,
      id: orderId
    };
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });


    getOrderItems(filtersData).then(res => {

      setOrderItemsState({
        ...orderItemsState,
        orderItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
    });

    const newCriteria = {
      ...orderItemsState.filters,
      per_page: orderItemsState.page.per_page,
      page: newPage + 1,
      id: orderId
    };

    getOrderItems(newCriteria).then(res => {
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
        orderItemsEditIndex: -1,
        orderItemsCreateObject: null,
        orderItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...orderItemsState.filters,
      page: 1,
      per_page: perPage,
      id: orderId
    };

    getOrderItems(newCriteria).then(res => {
      setOrderItemsState({
        ...orderItemsState,
        orderItems: res.data.results,
        orderItemsEditIndex: -1,
        isLoading: false,
        orderItemsCreateObject: null,
        page: res.data.page,
        filters: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleDeleteOrderItem = (event, index) => {

    const orderItemForDeletion = orderItemsState.orderItems[index];


    setOrderItemsState({
      ...orderItemsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Order Item',
        onCancel: () => {
          setOrderItemsState({
            ...orderItemsState,
            confirmDialog: {
              ...orderItemsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOrderItem(orderItemForDeletion.id).then((res) => {
            const newOrderItems = [...orderItemsState.orderItems];
            newOrderItems.splice(index, 1);
            setOrderItemsState({
              ...orderItemsState,
              orderItems: newOrderItems,
            });

            setOrdersState({
              ...ordersState,
              orders: res.data,
              isLoading: false
            });
          })

            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };


  const handleEditOrderItem = (event, orderItemIndex) => {
    event.stopPropagation();
    setBulkEdit(true);
    const orderItemEditObject = JSON.parse(JSON.stringify(orderItemsState.orderItems));
    totalCostAmountOnEditState.current = ordersState.orders.amount;
    orderItemEditObjectRef.current = orderItemEditObject;
  };


  const shouldSetStateToDisabled = (orderItemsState.orderItems.length > 0
    && ordersState.orders.reference_number
    && orderItemsState.orderItemEditIndex === -1
    && orderItemsState.orderItemCreateObject === null
  );

  useEffect(() => {
    if (shouldSetStateToDisabled) {
      setDisabled(
        false

      );
    } else {
      setDisabled(
        true
      )
    }
  }, [shouldSetStateToDisabled]);

  useEffect(() => {
    //create a div wrapper in order  to display new calculated total cost
    if (bulkEdit && userState.userRole !== 'BUYER') {
      const newTotalCostEl = document.createElement("div");
      newTotalCostEl.classList.add('order-items-total-cost-preview');
      document.body.appendChild(newTotalCostEl);
    } else {
      const totalCostEls = document.querySelectorAll('.order-items-total-cost-preview');
      totalCostEls &&
        totalCostEls.forEach(el => el.remove());
    }
  }, [bulkEdit])

  const onEditableOrderItemChange = (event, property, index) => {
    event.preventDefault();
    const newValue = event.target.value;
    orderItemEditObjectRef.current[index][property] = newValue;
    const targetElement = event.target;
    targetElement.value = newValue;

    if (['supplierQuantity', 'supplierNetPrice'].includes(property)) {
      const rowDomEl = targetElement.closest('tr.MuiTableRow-root');
      updateRowsTotalCost(index, rowDomEl);
    }
  };

  const updateRowsTotalCost = (rowIndex, rowDomElement) => {
    /**
    *  Cost calculation formula
    *  ( For line Cost calculation ....)
    *    
    *  Let Quantity : X
    *  Let UnitCost: Y
    *  Let Discount: (Z)%
    *
    *  THEN
    *  Cost = (X * Y) * ( (100 - Z)/100 )
    *
    *
    *  ( For total Cost calculation  ....)
    *  Let totalAmount: X
    *  Let totalDiscount: Z (%)
    *  Let finalAmount: Y
    *
     *  THEN
     *   Y = X-(X*Z)/100 ===>
     *   X = (100*Y)/(100-Z)
     *
    **/
    const currentOrderItem = orderItemEditObjectRef.current[rowIndex];
    const orderCostEl = rowDomElement.querySelector('.total-cost__field');
    const quantity = currentOrderItem['supplierQuantity'];
    const unitCost = currentOrderItem['supplierNetPrice'];
    const discount = currentOrderItem['discount'] || 0;
    const totalCostPreviewEl = document.querySelector('.order-items-total-cost-preview');

    const errors = {
      ...ValidateField('quantity', quantity),
      ...ValidateField('unitCost', unitCost),
      ...isNaN(totalCostAmountOnEditState.current) && {
        'no-valid-number': true
      }
    }


    const areFieldsValid = Object.keys(errors).length === 0;


    if (areFieldsValid) {

      orderCostEl.classList.remove('total-item-cost--error');
      const calculatedCost = (parseFloat(quantity) * parseFloat(unitCost) * (parseFloat(100 - discount) / 100));


      const newOrderItemCost = Object.assign([], orderItemsCost);
      newOrderItemCost[rowIndex].totalCost = calculatedCost;


      setOrderItemsCost(
        newOrderItemCost,
      );
      
      let newPageCost = null;
      orderItemsCost.map((item, index) => {
    
        if (index !== rowIndex) {
          newPageCost = newPageCost + item.totalCost;
        } else {
          newPageCost = newPageCost + calculatedCost;
        }
      });
   
      orderCostEl.innerHTML = `${calculatedCost.toString()}`;

      const newTotalCostValue = ordersState.orders.amount - pageCost + newPageCost * (100 - ordersState.orders.discount) / 100;

      if (!isNaN(newTotalCostValue)) {
        totalCostAmountOnEditState.current = newTotalCostValue;
        totalCostPreviewEl.innerHTML = `New calculated Total  Cost: ${totalCostAmountOnEditState.current.toFixed(2)}`

      } else {
        totalCostAmountOnEditState.current = ordersState.orders.amount;
        totalCostPreviewEl.innerHTML = `New calculated Total  Cost: ${totalCostAmountOnEditState.current.toFixed(2)}`
      }

    } else {
      totalCostAmountOnEditState.current = ordersState.orders.amount.toFixed(2);
      orderCostEl.classList.add('total-item-cost--error');
      orderCostEl.innerHTML = 'Error';
    }
  }

  const handleSaveOrderItem = (event, property) => {
    event.preventDefault();
    let errors = {};
    let saveOrderItems = true;
    orderItemEditObjectRef.current.forEach((order, index) => {

      errors[index] = {
        quantity: ValidateField('quantity', order.quantity),
      }
    });


    Object.keys(errors).forEach(index => {
      const tableRow = document.querySelector(`tr[data-table-row-index="${index}"]`);
      const quanityErrorEl = tableRow.querySelector(`div[data-attribute="quantity"]`).nextSibling;
      quanityErrorEl.innerHTML = errors[index].quantity?.quantity || '';
      if (errors[index].quantity?.quantity) { saveOrderItems = false }
    });


    if (saveOrderItems) {
      const unChangedOrderItems = orderItemsState.orderItems;
      const originalOrderItemsHashes = [...unChangedOrderItems.map(item => Sha256(JSON.stringify(item)).toString())];
      const updatedOrderItemsHashes = [...orderItemEditObjectRef.current.map(item => Sha256(JSON.stringify(item)).toString())];
      const updatedOrderItems = [];

      updatedOrderItemsHashes.forEach((hash, index) => {
        if (originalOrderItemsHashes[index] !== updatedOrderItemsHashes[index]) {
          updatedOrderItems.push(orderItemEditObjectRef.current[index])
        }
      })

      setOrdersState({
        ...ordersState,
        isLoading: true,
      });
      bulkEditOrderItems(orderId, updatedOrderItems)
        .then(re => {
          const results = re.data;
          loadOrderItems();

          getOrderById(orderCriteria).then(res => {
            setOrdersState({
              ...ordersState,
              orders: res.data,
              isLoading: false
            });

            setNewFiles(() => { return [] });
            setDropKey(dropkey + 1);
            setBulkEdit(false);

          }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' '+error,
            // }));
            setOrdersState({
              ...ordersState,
              isLoading: false,
            });
          });

        }).catch((error) => {
          setBulkEdit(true);
          setOrderItemsState({
            ...orderItemsState,
            isLoading: false,
          });
        });
    } else {
      console.log('show errors');
    }
  };

  const handleCloseEditOrderItem = (e, index) => {
    setBulkEdit(false);
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
    });

    setOrderItemsState({
      ...orderItemsState,
      isLoading: false,
      orderItemEditIndex: -1
    })
  };

  const handleAddNewOrderItem = (e) => {
    e.preventDefault();
    setOrderItemsState({
      ...orderItemsState,
      orderItemCreateObject: newOrderItem()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };
  const simpleValidatorCreate = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const onSaveNewOrderItem = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newOrderItem = {};
    newOrderItem.product = orderItemsState.orderItemCreateObject.product;
    newOrderItem.unit = orderItemsState.orderItemCreateObject.unit;
    newOrderItem.quantity = orderItemsState.orderItemCreateObject.quantity;
    newOrderItem.notes = orderItemsState.orderItemCreateObject.notes;
    newOrderItem.orderId = ordersState.orders.id;
    newOrderItem.unitCost = 0;
    const formValid = simpleValidatorCreate.current.allValid()
    if (formValid) {
      setOrderItemsState({
        ...orderItemsState,
        isLoading: true,
      });
      add(newOrderItem)
        .then(res => {
          setOrderItemsState({
            ...orderItemsState,
            orderItems: [...orderItemsState.orderItems, res.data],
            orderItemCreateObject: null,
            orderItemEditIndex: -1,
            isLoading: false,
          })

          getOrderById(orderCriteria).then(res => {

            setOrdersState({
              ...ordersState,
              orders: res.data,
              isLoading: false
            });
          }).catch((error) => {

            setOrdersState({
              ...ordersState,
              isLoading: false,
            });
          });

        })
        .catch(err => {
          setOrderItemsState({
            ...orderItemsState,
            isLoading: false,
          });
          alert('error');
        });
    }
    else {
      console.log('error');
    }
  };

  const onNewOrderItemChange = (e, property) => {

    let newValue = '';
    newValue = e.target.value;

    const newCreateOrderItem = Object.assign({}, orderItemsState.orderItemCreateObject);
    newCreateOrderItem[property] = newValue;

    setOrderItemsState({
      ...orderItemsState,
      orderItemCreateObject: newCreateOrderItem
    });
  };

  const handleDeleteOrder = (event) => {

    const orderForDeletion = orderId;
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    setOrdersState({
      ...ordersState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Order',
        onCancel: () => {
          setOrdersState({
            ...ordersState,
            confirmDialog: {
              ...ordersState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOrder(orderForDeletion).then(() => {
            window.location.replace('/transactions/' + ordersState.orders.transaction.id);
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };


  const handleReviseOrder = (e) => {

    const order = {
      id: ordersState.orders.id
    };
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    reviseOrder(order)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isOrderSaved: true
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isOrderSaved: false
        });
      });
  };

  const handleSyncOrder = (e) => {

    const order = {
      id: ordersState.orders.id
    };
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });

    setOrdersState({
      ...ordersState,
      isLoading: true
    });

    syncOrder(order)
      .then(res => {
        getOrderItems(orderItemCriteria).then(res => {
          const { results } = res.data;
          setOrderItemsState({
            ...orderItemsState,
            orderItems: results,
            isLoading: false,
            page: res.data.page,
            filters: res.data.filters
          });
          urlParams.setUrlParams({
            page: res.data.page.page,
            per_page: res.data.page.per_page
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' '+error,
          // }));
          setOrderItemsState({
            ...orderItemsState,
            isLoading: false,
          });
        });

        getOrderById(orderCriteria).then(res => {

          setOrdersState({
            ...ordersState,
            orders: res.data,
            isLoading: false
          });

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' '+error,
          // }));
          setOrdersState({
            ...ordersState,
            isLoading: false,
          });
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isOrderSaved: false
        });
      });
  };
  const handleSubmitOrder = (e) => {


    const order = {
      id: ordersState.orders.id
    };
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    submitOrder(order)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isOrderSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isOrderSaved: false
        });
      });
  };

  const selectedProduct = (data, action) => {

    const newEditOrderItem = Object.assign({},
      action === 'edit'
        ? orderItemsState.orderItemEditObject
        : orderItemsState.orderItemCreateObject
    );

    if (data.product.id !== undefined) {
      newEditOrderItem.product.id = data.product.id;
      newEditOrderItem.product.name = data.product.name;
      newEditOrderItem.product.code = data.product.code;
      //todo what is product description
      newEditOrderItem.productDescription = data.product.name;

    } else {
      newEditOrderItem.product.id = '';
      newEditOrderItem.product.name = '';
      newEditOrderItem.product.code = '';
      newEditOrderItem.productDescription = '';
    }

    setOrderItemsState({
      ...orderItemsState,
      [
        action === 'edit'
          ? 'orderItemEditObject'
          : 'orderItemCreateObject'
      ]: newEditOrderItem
    });

  };

  const selectedDate = (data) => {
    setDate(data);

    const orderEditObject = Object.assign({}, ordersState.orders);
    if (data.due !== undefined && data.due !== 'empty') {
      const due = new Date(data.due);
      orderEditObject.due = due.toISOString();
    } else {
      orderEditObject.due = null;
    }
    setOrderItemEditObject(orderEditObject);

    editOrder({ id: ordersState.orders.id }, orderEditObject)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isDueSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isDueSaved: false
        });
      });
  };

  const orderNotes = (data) => {
    setORDERNotes(data);

    const orderEditObject = Object.assign({}, ordersState.orders);
    if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
      orderEditObject.notes = data.notes;
    } else {
      orderEditObject.notes = null;
    }

    setOrderItemEditObject(orderEditObject);

    editOrder({ id: ordersState.orders.id }, orderEditObject)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isNotesSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isNotesSaved: false
        });
      });
  };

  const orderSupplierNotes = (data) => {

    const orderEditObject = Object.assign({}, ordersState.orders);
    if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
      orderEditObject.supplierNotes = data.notes;
    } else {
      orderEditObject.supplierNotes = null;
    }

    setOrderItemEditObject(orderEditObject);

    setOrdersState({
      ...ordersState,
      orders: orderEditObject,
      isLoading: false,
      isSupplierNotesSaved: true
    });
  };

  const handleSupplierNotes = (e) => {

    let updateOrder = {};

    if (bulkEdit) {

      let errors = {};
      let saveOrderItems = true;
      orderItemEditObjectRef.current.forEach((order, index) => {
        errors[index] = {
          quantity: ValidateField('quantity', order.quantity),
          unitCost: ValidateField('unitCost', order.unitCost),
        }
      });


      Object.keys(errors).forEach(index => {
        const tableRow = document.querySelector(`tr[data-table-row-index="${index}"]`);
        const quanityErrorEl = tableRow.querySelector(`div[data-attribute="quantity"]`).nextSibling;
        const unitCostErrorEl = tableRow.querySelector(`div[data-attribute="unitCost"]`).nextSibling;
        quanityErrorEl.innerHTML = errors[index].quantity?.quantity || '';
        unitCostErrorEl.innerHTML = errors[index].unitCost?.unitCost || '';
        if (errors[index].quantity?.quantity || errors[index].unitCost?.unitCost) { saveOrderItems = false }
      });


      if (saveOrderItems) {
        const unChangedOrderItems = orderItemsState.orderItems;
        const originalOrderItemsHashes = [...unChangedOrderItems.map(item => Sha256(JSON.stringify(item)).toString())];
        const updatedOrderItemsHashes = [...orderItemEditObjectRef.current.map(item => Sha256(JSON.stringify(item)).toString())];
        const updatedOrderItems = [];

        updatedOrderItemsHashes.forEach((hash, index) => {
          if (originalOrderItemsHashes[index] !== updatedOrderItemsHashes[index]) {
            updatedOrderItems.push(orderItemEditObjectRef.current[index])
          }
        })

        setOrdersState({
          ...ordersState,
          isLoading: true,
        });

        updateOrder = {
          id: ordersState.orders.id,
          supplierReference: ordersState.orders.supplierReference,
          supplierNotes: ordersState.orders.supplierNotes,
          items: updatedOrderItems
        }

        updateOrderStatus({ id: ordersState.orders.id }, updateOrder)
          .then(re => {
            const results = re.data;
            loadOrderItems();
            // setOrderItemsState({
            //   ...orderItemsState,
            //   orderItems: results,
            //   isLoading: false,
            //   page: res.data.page,
            //   filters: res.data.filters
            // });

            getOrderById(orderCriteria).then(res => {
              setOrdersState({
                ...ordersState,
                orders: res.data,
                isLoading: false
              });

            }).catch((error) => {
              // dispatch(messagesActions.openSnackbar({
              //   variant: 'error ',
              //   message: ' '+error,
              // }));
              setOrdersState({
                ...ordersState,
                isLoading: false,
              });
            });
            setBulkEdit(false);
            setNewFiles(() => { return [] });
            setDropKey(dropkey + 1);

          }).catch((error) => {
            setBulkEdit(true);
            setOrderItemsState({
              ...orderItemsState,
              isLoading: false,
            });
          });
      } else {
        console.log('show errors');
      }
    }
    else {

      updateOrder = {
        id: ordersState.orders.id,
        supplierReference: ordersState.orders.supplierReference,
        supplierNotes: ordersState.orders.supplierNotes,
      };

      updateOrderStatus({ id: ordersState.orders.id }, updateOrder)
        .then(res => {
          setOrdersState({
            ...ordersState,
            orders: res.data,
            isLoading: false,
            isSupplierNotesSaved: true
          });
          setNewFiles(() => { return [] });
          setDropKey(dropkey + 1);
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setOrdersState({
            ...ordersState,
            isLoading: false,
            isSupplierNotesSaved: false
          });
        });
    }
  };

  const selectedUnit = (data, action, index) => {

    const newEditOrderItem = Object.assign({},
      action === 'edit'
        ? orderItemsState.orderItemEditObject
        : orderItemsState.orderItemCreateObject
    );

    if (data.unit?.id !== undefined && action !== 'edit') {
      newEditOrderItem.unit.id = data.unit.id;
      newEditOrderItem.unit.name = data.unit.name;
    } else if (data.unit?.id !== undefined && action === 'edit') {

      newEditOrderItem.supplierUnit.id = data.unit.id;
      newEditOrderItem.supplierUnit.name = data.unit.name;
    }
    else if (action !== 'edit') {
      newEditOrderItem.unit.id = '';
      newEditOrderItem.unit.name = '';
      newEditOrderItem.unit.categoryId = '';
      newEditOrderItem.unit.categoryName = '';
      newEditOrderItem.unitDescription = '';
    } else {
      newEditOrderItem.supplierUnit.id = '';
      newEditOrderItem.supplierUnit.name = '';
    }
    if (action === 'edit') {
    
      const newValue = newEditOrderItem.supplierUnit;
      orderItemEditObjectRef.current[index]['supplierUnit'] = newValue;
      // const targetElement = event.target;
      // targetElement.value = newValue;
    }
    if (action !== 'edit') {
      setOrderItemsState({
        ...orderItemsState,
        [
          action === 'edit'
            ? 'orderItemEditObject'
            : 'orderItemCreateObject'
        ]: newEditOrderItem
      });
    }
  };

  const handleSearchItems = (data) => {

    setOrderItemsState({
      ...orderItemsState,
      isLoading: true
    });


    let filtersData = {
      search: data.search,
      per_page: orderItemCriteria.per_page,
      page: 1,
      id: orderId
    };
    getOrderItems(filtersData).then(res => {
      const { results } = res.data;

      setOrderItemsState({
        ...orderItemsState,
        orderItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setOrderItemsState({
        ...orderItemsState,
        isLoading: false,
      });
    });
  };

  const handleConfirmOrder = (e) => {
    const order = {
      id: ordersState.orders.id
    };

    confirm(order).then(res => {
      getOrderById(orderCriteria).then(res => {

        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false
        });
      }).catch((error) => {

        setOrdersState({
          ...ordersState,
          isLoading: false,
        });
      });

    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  }

  const handleAcceptOrder = (e) => {
    const order = {
      id: ordersState.orders.id
    };
    accept(order).then(res => {
      setOrdersState({
        ...ordersState,
        orders: res.data,
        isLoading: false
      });
    }).catch((error) => {
      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  }

  const handleCancelOrder = (e) => {
    const order = {
      id: ordersState.orders.transaction.id
    };

    cancel(order).then(res => {
      getOrderById(orderCriteria).then(res => {

        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false
        });
      }).catch((error) => {

        setOrdersState({
          ...ordersState,
          isLoading: false,
        });
      });

    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  }

  const handleEvaluateSupplier = (e) => {

    window.location.assign('/transactions/' + ordersState.orders.transaction.id + '/suppliers/' + ordersState.orders.supplier.id);

  };

  const handleEvaluateItems = (e) => {

    window.location.assign('/transactions/' + ordersState.orders.transaction.id + '/rated-products/' + ordersState.orders.supplier.id);

  };

  const classes = useStyles();

  const orderSupplierReference = (data) => {

    const orderEditObject = Object.assign({}, ordersState.orders);

    if (data !== undefined && data !== '-' && data !== null) {
      orderEditObject.supplierReference = data.notes;
    } else {
      orderEditObject.supplierReference = '';
    }

    setOrderItemEditObject(orderEditObject);

    updateOrderStatus({ id: ordersState.orders.id }, orderEditObject)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isSupplierReferenceSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isSupplierReferenceSaved: false
        });
      });
  };

  const orderReferenceNumber = (data) => {

    const orderEditObject = Object.assign({}, ordersState.orders);

    if (data !== undefined && data !== '-' && data !== null) {
      orderEditObject.reference_number = data.notes;
    } else {
      orderEditObject.reference_number = '';
    }

    setOrderItemEditObject(orderEditObject);

    editOrder({ id: ordersState.orders.id }, orderEditObject)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false,
          isReferenceNumberSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setOrdersState({
          ...ordersState,
          isLoading: false,
          isReferenceNumberSaved: false
        });
      });
  };

  const orderFiles = (data) => {
    setOrderFiles(data);

    addOrderAttachment(ordersState.orders.id, data).then((response) => {
      const newFile = response.data;
      setNewFiles([...newFiles, newFile]);
      setOrdersState({
        ...ordersState,
        orders: {
          ...ordersState.orders,
          attachments: [
            ...ordersState.orders.attachments,
            newFile
          ]
        }
      })
    }).catch((error) => {
      console.log(error);
    });
  };

  const orderFileClicked = (data) => {
    const fileInfo = {
      id: orderId,
      fileId: data.id
    }

    getOrderAttachment(fileInfo).then((response) => {

      const binaryString = window.atob(response.data.blob);

      const binaryLen = binaryString.length;

      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }


      const url = window.URL.createObjectURL(new Blob([bytes], { type: response.data.type }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.name);


      document.body.appendChild(link);
      link.click();
    });
  };

  const orderFileDeleted = (data) => {
    const fileInfo = {
      id: orderId,
      fileStorageid: data.filestorageId
    }

    const atts = ordersState.orders.attachments.filter(attachment => attachment.id !== data.id);
    deleteOrderAttachment(fileInfo).then((response) => {
      if (response) {
        setOrdersState({
          ...ordersState,
          orders: {
            ...ordersState.orders,
            attachments: atts
          }
        })
      }
    });
  };

  const handleImport = (data) => {
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    setOrderItemsState({
      ...orderItemsState,
      isLoading: true,
    });
    importOrder(ordersState.orders.id, data)
      .then(res => {
        setOrdersState({
          ...ordersState,
          orders: res.data,
          isLoading: false
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        console.log(error);
        setOrdersState({
          ...ordersState,
          isLoading: false
        });
      });
  };

  const handleUpload = (data) => {

    importDeliveryInvoices(ordersState.orders.id, data)
      .then(res => {
        setOrdersState({
          ...ordersState,
          isLoading: false
        });

      }).catch((error) => {
        console.log(error);
        setOrdersState({
          ...ordersState,
          isLoading: false
        });
   
      });
  };

  const handlePrint = (e) => {

    const order = {
      id: ordersState.orders.id
    };

    printOrder(order)
      .then(response => {
        const binaryString = window.atob(response.data.blob);

        const binaryLen = binaryString.length;

        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }


        const url = window.URL.createObjectURL(new Blob([bytes], { type: response.data.type }));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
      });
  };

  const attachments = () => {
    return (<DropzoneComponent
      initialFiles={ordersState.orders.attachments}
      title={'Attachments'}
      key={dropkey}
      enabled={ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE")}
      newFiles={newFiles}
      getFiles={orderFiles}
      getFileClicked={orderFileClicked}
      getFileDeleted={orderFileDeleted}
    />)
  };


  const onKeyDown = useCallback(() => {

    if (activeIndex.current + 4 < orderItemsState.page.count * 4) {

      inputRefs.current[activeIndex.current + 4].focus();
    }
  }, [activeIndex.current, orderItemsState.page.count]);

  useKeyDown(() => {
    onKeyDown()
  }, ["Enter"]);

  const triggerBlurValidator = (event) => {
    const element = event.target;
    const rootEl = element.closest('[data-attribute]');

    const value = element.value;
    const attributeName = rootEl.dataset.attribute;
    const validationError = ValidateField(attributeName, value);
    const errorEl = rootEl.nextSibling;

    errorEl.innerHTML = (!!validationError && validationError[attributeName]) || '';
  }

  const tableFilterComponent = () => {
    return <TableFilterItems
      config={{
        search: {
          enabled: true,
          callback: (e) => handleSearchItems(e)
        },
        edit: {
          enabled: (ordersState.orders.actions?.includes("UPDATE_ORDER_STATUS") || (ordersState.orders.actions?.includes("UPDATE"))) && !bulkEdit && !orderItemsState.orderItemCreateObject,
          callback: (e) => handleEditOrderItem(e)
        },
        close: {
          enabled: bulkEdit,
          callback: (e) => handleCloseEditOrderItem(e)
        },
        save: {
          enabled: ordersState.orders.actions?.includes("UPDATE") && bulkEdit,
          callback: (e) => handleSaveOrderItem(e)
        }
      }}

    />;
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      order={orderItemsState.page.order_type}
      orderBy={orderItemsState.page.order_by}
      rowCount={orderItemsState.page.length} />;
  };

  const tablePrint = () => {
    return <PrintPdf title={t('PRINT')} handlePrint={handlePrint} />
  };

  const tableImport = () => {
    if (ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE_ORDER_STATUS"))
      return <FileUploader
        title={t('IMPORT')}
        accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/vnd.ms-excel'}
        handleImport={handleImport}
      />
    else {
      return undefined;
    }
  };

  const tableUpload = () => {
    return <FileUploader
      title={t('UPLOAD_INVOICE_DELIVERY_NOTE')}
      accept={'application/pdf'}
      multiple={true}
      handleImport={handleUpload} />
  };


  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        revise: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("REVISE"),
          callback: (e) => handleReviseOrder(e)
        },
        delete: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("DELETE"),
          callback: (e) => handleDeleteOrder(e)
        },
        sync: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("SYNCHRONIZE"),
          callback: (e) => handleSyncOrder(e)
        },
        submit: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("SUBMIT"),
          disabled: disabled,
          callback: (e) => handleSubmitOrder(e)
        },
        confirm: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("CONFIRM"),
          callback: (e) => handleConfirmOrder(e)
        },
        accept: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("ACCEPT_STATUS_UPDATE"),
          callback: (e) => handleAcceptOrder(e)
        },
        cancel: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("CANCEL_TRANSACTION"),
          callback: (e) => handleCancelOrder(e)
        },
        quote: {
          enabled: false,
        },
        evaluateItems: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("EVALUATE_ITEMS"),
          callback: (e) => handleEvaluateItems(e)
        },
        evaluateSupplier: {
          enabled: ordersState.orders && ordersState.orders.actions && ordersState.orders.actions.includes("EVALUATE_SUPPLIER"),
          callback: (e) => handleEvaluateSupplier(e)
        },
      }}

    />;
  };

  const infoTab = () => {
    return (
      ordersState.orders.length !== 0 &&
        <OrderInfo
          vessel={ordersState.orders.vessel ? ordersState.orders.vessel.name : "-"}
          supplier={ordersState.orders.supplier ? ordersState.orders.supplier.name : "-"}
          buyer={ordersState.orders.buyer ? ordersState.orders.buyer.name : "-"}
          userRole={props.userState.user.userRole}
          catalogue={ordersState.orders.category ? ordersState.orders.category.name : "-"}
          port={ordersState.orders.port ? ordersState.orders.port.name : "-"}
          periodDue={ordersState.orders.periodDue ? ordersState.orders.periodDue : "-"}
          due={ordersState.orders.due}
          submitted={ordersState.orders.submitted ? ordersState.orders.submitted.slice(0, 10) : "-"}
          currency={ordersState.orders.currency ? ordersState.orders.currency.name : "-"}
          status={ordersState.orders.status || "-"}
          saved={ordersState.isOrderSaved}
          referenceNumber={ordersState.orders.reference_number}
          supplierReference={ordersState.orders.supplierReference}
          supplierNotes={ordersState.orders.supplierNotes}
          amount={ordersState.orders.amountWithCurrency || "-"}
          alert={ordersState.orders.alert}
          disabled={false}
          discount={ordersState.orders.discount}
          savedNotes={ordersState.isNotesSaved}
          savedSupplierNotes={ordersState.isSupplierNotesSaved}
          savedReferenceNumber={ordersState.isReferenceNumberSaved}
          savedSupplierReference={ordersState.isSupplierReferenceSaved}
          savedDue={ordersState.isDueSaved}
          getSelectedDate={selectedDate}
          getNotes={orderNotes}
          getSupplierNotes={orderSupplierNotes}
          getReferenceNumber={orderReferenceNumber}
          getSupplierReference={orderSupplierReference}
          notes={ordersState.orders.notes}
          check={ordersState.orders.isCompleted}
          edit={ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE")}
          editSupplier={ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE_ORDER_STATUS")}
          role={props.userState.user.userRole || "-"}
          submitSupplierNotes={handleSupplierNotes}
        />

    )
  };

  const tableBodyComponent = () => {
    return (
      <TableBody className={'order-items-body'}>
        {ordersState.orders &&
          !ordersState.isLoading &&
          orderItemsState.orderItems.map((orderItem, index) => (
            <TableRow
              style={{ display: !bulkEdit ? 'table-row' : 'none' }}
              key={index}
              className={classes.tableRow}
            >
              <TableCell>{orderItem.product.code}</TableCell>
              <TableCell>{orderItem.product.name}</TableCell>
              <TableCell>{orderItem.quantity + "/" + orderItem.supplierQuantity}</TableCell>
              <TableCell>{orderItem.unit.name + "/" + orderItem.supplierUnit.name}</TableCell>
              <TableCell>{orderItem.unitCost + "/" + orderItem.supplierNetPrice}</TableCell>
              <TableCell>{orderItem.discount ? (orderItem.discount).toString() + '%' : '0%'}</TableCell>
              <TableCell>{orderItem.totalCost}</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {orderItem.notes && 'buyer: ' + orderItem.notes}
                {orderItem.notes && orderItem.supplierNotes && '\n'}
                {orderItem.supplierNotes && 'supplier: ' + orderItem.supplierNotes}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))
        }
        {
          !!orderItemEditObjectRef.current &&
          !!orderItemEditObjectRef.current.length &&
          !!bulkEdit &&
          orderItemEditObjectRef.current.map((orderItem, index) => (
            <TableRow
              style={{ display: 'table-row' }}
              key={index}
              className={classes.tableRow}
              data-table-row-index={index}
            >
              <TableCell onClick={e => e.stopPropagation()}>
                {orderItemsState.orderItems[index].product.code}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                {orderItemsState.orderItems[index].product.name}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  {userState.userRole === 'SUPPLIER' ? <TextField
                    variant="outlined"
                    size='small'
                    inputRef={(el) => (inputRefs.current[index * 4] = el)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {orderItemsState.orderItems[index].quantity + ' /'}
                        </InputAdornment>
                      ),
                    }}
                    // onKeyDown={e =>onKeyDown(e, index*5+3)}
                    onFocus={
                      (event) => {
                        activeIndex.current = (index * 4)
                        event.target.select();
                      }
                    }
                    defaultValue={orderItemsState.orderItems[index].supplierQuantity}
                    data-attribute={'quantity'}
                    onChange={e => onEditableOrderItemChange(e, 'supplierQuantity', index)}
                    onBlur={e => triggerBlurValidator(e)}
                  /> : <TextField
                    variant="outlined"
                    size='small'
                    inputRef={(el) => (inputRefs.current[index * 4] = el)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {'/' + orderItemsState.orderItems[index].supplierQuantity}
                        </InputAdornment>
                      ),
                    }}
                    // onKeyDown={e =>onKeyDown(e, index*5+3)}
                    onFocus={
                      (event) => {
                        activeIndex.current = (index * 4)
                        event.target.select();
                      }
                    }
                    defaultValue={orderItemsState.orderItems[index].quantity}
                    data-attribute={'quantity'}
                    onChange={e => onEditableOrderItemChange(e, 'quantity', index)}
                    onBlur={e => triggerBlurValidator(e)}
                  />}
                  <span className={'error'}></span>
                </FormControl>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                {userState.userRole === 'SUPPLIER' ? <FormControl variant="outlined" fullWidth>
                  <FilterUnits
                    unit={orderItemsState.orderItems[index].supplierUnit}
                    supplier={orderItemsState.orderItems[index].supplier?.id}
                    onFocusa={
                      (event) => {
                        activeIndex.current = (index * 4 + 1)
                        event.target.select();
                      }
                    }
                    port={orderItemsState.orderItems[index].port?.id}
                    inputAdornment={orderItemsState.orderItems[index].unit.name}
                    product={orderItemsState.orderItems[index].product?.id}
                    inputRefa={(el) => (inputRefs.current[index * 4 + 1] = el)}
                    getSelectedUnit={(data) => selectedUnit(data, 'edit', index)}
                  />
                </FormControl> : orderItemsState.orderItems[index].unit.name}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                {userState.userRole === 'SUPPLIER' ? <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    // type="number"     
                    // inputProps={{
                    //   step: '0.01',
                    // }}
                    size='small'
                    inputRef={(el) => (inputRefs.current[index * 4 + 2] = el)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {orderItemsState.orderItems[index].unitCost + ' /'}
                        </InputAdornment>
                      ),
                    }}
                    onFocus={
                      (event) => {
                        activeIndex.current = (index * 4) + 2;
                        event.target.select();
                      }
                    }
                    defaultValue={orderItemsState.orderItems[index].supplierNetPrice}
                    onChange={e => onEditableOrderItemChange(e, 'supplierNetPrice', index)}
                    data-attribute={'unitCost'}
                    onBlur={e => triggerBlurValidator(e)}
                    fullWidth
                  />
                  <span className={'error'}></span>
                </FormControl> : orderItemsState.orderItems[index].unitCost}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                {orderItemsState.orderItems[index].discount ? (orderItemsState.orderItems[index].discount).toString() + '%' : '0%'}
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()} >
                <div
                  className={'total-cost__field'}
                  data-attr={'totalCost'}
                  // ref={inputRefs.current[index * 4 + 4]}
                >
                  {orderItemsState.orderItems[index].totalCost}
                </div>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  {userState.userRole === 'SUPPLIER' ? <TextField
                    variant="outlined"
                    size='small'
                    inputRef={(el) => (inputRefs.current[(index * 4) + 3] = el)}
                    onFocus={
                      (event) => {
                        activeIndex.current = (index * 4) + 3;
                        event.target.select();
                      }
                    }
                    data-attribute={'notes'}
                    defaultValue={orderItemsState.orderItems[index].supplierNotes}
                    onChange={e => onEditableOrderItemChange(e, 'supplierNotes', index)}
                    fullWidth
                    multiline
                    maxRows={10}
                  /> : <TextField
                    variant="outlined"
                    size='small'
                    inputRef={(el) => (inputRefs.current[(index * 4) + 1] = el)}
                    onFocus={
                      (event) => {
                        activeIndex.current = (index * 4) + 1;
                        event.target.select();
                      }
                    }
                    data-attribute={'notes'}
                    defaultValue={orderItemsState.orderItems[index].notes}
                    onChange={e => onEditableOrderItemChange(e, 'notes', index)}
                    fullWidth
                    multiline
                    maxRows={10} />}
                </FormControl>
              </TableCell>
            </TableRow>
          ))
        }
        {
          orderItemsState.orderItemCreateObject &&
          <TableRow className={classes.tableRow}>
            <TableCell onClick={e => e.stopPropagation()}>
              {orderItemsState.orderItemCreateObject.product.code}
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProducts
                  product={orderItemsState.orderItemCreateObject.product}
                  catalogue={ordersState.orders.category.id || ""}
                  supplier={ordersState.orders.supplier?.id}
                  port={ordersState.orders.port?.id}
                  getSelectedProduct={(data) => selectedProduct(data, 'create')}
                  onBlur={simpleValidatorCreate.current.showMessageFor('product')} />
                {simpleValidatorCreate.current.message('product', orderItemsState.orderItemCreateObject.product.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  defaultValue={orderItemsState.orderItemCreateObject.quantity}
                  onChange={e => onNewOrderItemChange(e, 'quantity')}
                  onBlur={simpleValidatorCreate.current.showMessageFor('quantity')} />
                {simpleValidatorCreate.current.message('quantity', orderItemsState.orderItemCreateObject.quantity, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnits
                  unit={orderItemsState.orderItemCreateObject.unit}
                  supplier={ordersState.orders.supplier?.id}
                  port={ordersState.orders.port?.id}
                  product={orderItemsState.orderItemCreateObject.product?.id}
                  getSelectedUnit={(data) => selectedUnit(data, 'create')}
                  onBlur={simpleValidatorCreate.current.showMessageFor('unit')} />
                {simpleValidatorCreate.current.message('unit', orderItemsState.orderItemCreateObject.unit.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              {orderItemsState.orderItemCreateObject.unitCost}</TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              {'0%'}
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              {orderItemsState.orderItemCreateObject.totalCost}
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  defaultValue={orderItemsState.orderItemCreateObject.notes}
                  onChange={e => onNewOrderItemChange(e, 'notes')}
                  fullWidth
                  multiline
                  maxRows={10}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setOrderItemsState({ ...orderItemsState, orderItemCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewOrderItem()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  };





  return (
    <>{ordersState.orders && ordersState.orders.transaction && <MembersChat
      entityId={ordersState.orders.id}
      entityType={'order'}
      relatedId={ordersState.orders.transaction.id}
      ownerId={props.userState.user.userId}
      recipient={props.userState.user.userRole === "BUYER" ? ordersState.orders.supplier : ordersState.orders.buyer}
      supplierId={ordersState.orders.supplier.id}
      user={props.userState}
    />}
      <ProcureDataTable
        toolbarTitle={''}
        infoTabTitle={t('ORDER')}
        pagination={{
          total: orderItemsState.page.total || 0,
          page: orderItemsState.page.page - 1 || 0,
          perPage: orderItemsState.page.per_page || 10,
          count: orderItemsState.page.count || 0,
          disabled: bulkEdit,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={handleAddNewOrderItem}
        newRowActionAvailable={ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE") && !bulkEdit}
        importAction={ordersState.orders.actions && ordersState.orders.actions.includes("UPDATE_ORDER_STATUS")}
        newRowBtnEnabled={!orderItemsState.orderItemCreateObject}
        confirmDialog={orderItemsState.confirmDialog.message.length > 0 ? orderItemsState.confirmDialog : ordersState.confirmDialog}
        infoTab={infoTab}
        breadcrump={breadcrump}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        tablePrint={tablePrint}
        tableImport={tableImport}
        uploadAction={props.userState.user.userRole === 'BUYER'}
        tableUpload={tableUpload}
        attachments={attachments}
        isLoading={orderItemsState.isLoading}
        itemsTableTitle={t('ITEMS')}
      />
    </>
  );
};

export default OrderItems;
