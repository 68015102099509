import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
    return {
        disableBlackColor: {
            "& .MuiInputBase-input": {
                "&.Mui-disabled": {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87);',
                    color: 'rgba(0, 0, 0, 0.87);'
                },

            }
        }
    }
});

export default useStyles;
