import React, { useCallback } from "react";
import useStyles from './style';
// import MomentUtils from '@date-io/moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { Grid } from "@mui/material";
import t from '../../helpers/languages';
import dateToString from "../../helpers/dateToString";

//import ClearIcon from "@mui/icons-material/Clear";

export default function InlineEditDate({ due: initialDate, getSelectedDate, saved, error }) {

    const classes = useStyles();
    const emptyDate = { due: 'empty' };
    const [openDate, setOpenDate] = React.useState(false);
    const [due, setDue] = React.useState(initialDate?moment.utc(initialDate):null);

    const handleSpanClick = useCallback(() => setOpenDate(true), [
        setOpenDate
    ]);

    const onDateSelectionChange = (event, values) => {


        if (moment(event, "yyyy-MM-DD").isValid()) {
            
            const selectedDate = {
                due: event ? dateToString(event)+'T00:00:00Z' : emptyDate,
            };
            getSelectedDate(selectedDate);
            setOpenDate(false);
            setDue(event);
        } else if (!event) {
            getSelectedDate(emptyDate);
            setOpenDate(false);
            setDue(event);
        }
    };

    const handleBlur = (e) => {

        if (moment(e.target.value, "yyyy-MM-DD").isValid() && !openDate) {
            
            const selectedDate = {
                due: e.target.value ? dateToString(e.target.value)+'T00:00:00Z' : emptyDate,
            };
            getSelectedDate(selectedDate);
            setDue(e.target.value);
        } else if (!e.target.value && !openDate) {
            getSelectedDate(emptyDate);
            setDue(e.target.value);
        }

    };


    return (
        <span className="inline-text">
            {openDate || !saved ? (
                <span>
                    <LocalizationProvider  dateAdapter={AdapterMoment}>
                        <Grid container item md={12} wrap='nowrap' justifyContent="flex-start" alignItems="flex-end">
                            <DatePicker
                                clearable
                                inputVariant="outlined"
                                fullWidth
                                autoFocus
                                minDate={new Date(2015, 1, 1)}
                                maxDate={new Date(2030, 1, 1)}
                                // onAccept={setDue}
                                label={t('DUE')}
                                inputFormat="yyyy-MM-DD"
                                value={due}
                                onChange={date => onDateSelectionChange(date)}
                                onOpen={() => {
                                    setOpenDate(true);
                                }}
                                onClose={() => {
                                    setOpenDate(false);
                                }}
                                onBlur={handleBlur}
                                autoOk={true}
                                error={error && !saved}
                                renderInput={(params) => <TextField {...params} />}
                                helperText={error && !saved && "Value not saved."}
                            />

                            {/* <IconButton
                                edge="end"
                                size="small"
                                disabled={!due}
                                onClick={() => handleDateTimeChange(null)}
                            >
                                <ClearIcon />
                            </IconButton> */}
                        </Grid>
                    </LocalizationProvider>
                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openDate ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <TextField
                        fullWidth
                        id="outlined-disabled-due"
                        label={t('DUE')}
                        value={dateToString(due)}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </span>)}
        </span >
    );
};