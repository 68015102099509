import React, { useState, useEffect } from 'react';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';
import {
    homePageSteps,
    transactionBuyerPageSteps,
    transactionSupplierPageSteps,
    rfqBuyerPageSteps,
    rfqSupplierPageSteps,
    quoteSupplierPageSteps,
    pricelistBuyerPageSteps
} from '../../helpers/assistantConfig';
import Message from "../messages";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';

export default function OnlineAssistant({ path = '', user = 'none', submit }) {

    const [run, setRun] = useState(false);
    const [messages, setMessages] = useState([{
        id: 10,
        message: '                                                   Welcome to Sea-Beyond!' +
            '\n To onboard smoothly, please click the "red circle" on each page to take a quick guided tour.'
    }]);
    const [stepIndex, setStepIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const [steps, setSteps] = useState([]);
    const [enableTour, setEnableTour] = useState(false);

    useEffect(() => {
        setRun(true);
        setLoading(false);
        if (user === 'SUPPLIER' || user === 'BUYER') {
            if (path === 'transaction') {
                setStepIndex(0);
                if (user === 'BUYER') {
                    setSteps(transactionBuyerPageSteps);
                    if (!localStoreGetObj('transactionTourBuyer')) { setEnableTour(true) };
                } else {
                    setSteps(transactionSupplierPageSteps);
                    if (!localStoreGetObj('transactionTourSupplier')) { setEnableTour(true) };
                }
            }
            else if (path === 'rfq') {
                setStepIndex(0);

                if (user === 'BUYER') {
                    if (!localStoreGetObj('rfqTourBuyer') && submit) { setEnableTour(true) };
                    setSteps(rfqBuyerPageSteps);
                }
                else {
                    setSteps(rfqSupplierPageSteps);
                    if (!localStoreGetObj('rfqTourSupplier') && submit) { setEnableTour(true) };
                }
            }
            else if (path === 'quote') {
                setStepIndex(0);
                if (user === 'SUPPLIER') {
                    if (!localStoreGetObj('quoteTourSupplier') && submit) { setEnableTour(true) };
                    setSteps(quoteSupplierPageSteps);
                }
            }
            else if (path === 'pricelists') {
                setStepIndex(0);
                if (user === 'BUYER') {
                    if (!localStoreGetObj('pricelistTourBuyer') ) { setEnableTour(true) };
                    setSteps(pricelistBuyerPageSteps);
                }
            }
            else {
                setSteps(homePageSteps);
                if (!localStoreGetObj('homePageTour')) { setEnableTour(true) };
                setStepIndex(0);
            }
        }
        // eslint-disable-next-line  
    }, []);


    // console.log(enableTour);

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const nextStatuses = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

        if (finishedStatuses.includes(status) && run) {
            // if (finishedStatuses.includes(status) ) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
            switch (path) {
                case 'transaction':
                    if (user === 'BUYER') {
                        localStoreSetObj('transactionTourBuyer', true);
                    } else {
                        localStoreSetObj('transactionTourSupplier', true);
                    }
                    break;
                case 'rfq':
                    if (user === 'BUYER') {
                        localStoreSetObj('rfqTourBuyer', true);
                    }
                    else {
                        localStoreSetObj('rfqTourSupplier', true);
                    }
                    break;
                case 'quote':
                    localStoreSetObj('quoteTourSupplier', true);
                    break;
                case 'pricelists':
                    localStoreSetObj('pricelistTourBuyer', true);
                    break;
                default:
                    localStoreSetObj('homePageTour', true);
            }

        } else if (type === EVENTS.STEP_AFTER && index === 0) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            setRun(true);
            setStepIndex(nextStepIndex);
        }
        else if (nextStatuses.includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            setRun(true);
            setStepIndex(nextStepIndex);
        }
    };
    const onMessageClose = uuid => {
        setMessages(oldMessages => oldMessages.filter(message => message.id !== uuid));
    }
    return (
        <>{enableTour && !loading && <>
            {!localStoreGetObj('homePageTour')&&<Message
                messages={messages}
                onMessageClose={onMessageClose}
                severity={'success'}
                horizontal={'center'}
            />}
            <Joyride
                disableScrolling={true}
                callback={handleJoyrideCallback}
                continuous
                // hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                stepIndex={stepIndex}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            /></>}
        </>
    );
}
