////////////// check file inputs and types on upload
const checkFileInputs = (files, accept) => {

	const acceptInputs = accept.split(", ")  //all types in accepts must be seperated by comma with a space after

	const mapAccept = {
		"application/vnd.ms-excel": "xls",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
		"application/pdf": "pdf",
		"application/zip": "zip",
		"application/x-zip": "zip",
		"application/x-zip-compressed": "zip",
		"text/tab-separated-values": "tsv"
	}

	const acceptedExtensions = [];
	for (let input of acceptInputs) {
		if (!acceptedExtensions.includes(mapAccept[input].toLowerCase()))
			acceptedExtensions.push(mapAccept[input]);
	}

	let rejectedFiles = [];
	let acceptedFiles = [];
	for (let file of files) {
		const fileName = file.name;
		const fileExtension = fileName.split(".").pop();
		if (acceptedExtensions.includes(fileExtension.toLowerCase())) {
			acceptedFiles.push(file)
		} else {
			rejectedFiles.push(fileName);
		}
	}

	const checkedFiles = {rejectedFiles, acceptedFiles}
	return checkedFiles;

}

export default checkFileInputs;
