
const tableHeaders = [
  { id: 'product_code', disablePadding: false, label: 'Code',width:'5%', align: 'center' },
  { id: 'product_name', disablePadding: false, label: 'Product',width:'35%' },
  { id: 'quantity', disablePadding: false, label: 'Quantity',width:'5%' },
  { id: 'unit_name', disablePadding: false, label: 'Unit' ,width:'10%'},
  { id: 'notes', disablePadding: false, label: 'Notes' ,width:'35%'},
  { id: 'row-actions', disablePadding: false, width:'10%' },
];

// Empty rfqitem
const newRfqItem = () => {
  return {
    category: { id: '', name: '' },
    notes: '',
    id: '',
    product: { code: '', id: '', name: '' },
    quantity: '',
    remote_id: '',
    rfqitem_id: '',
    unit: { id: '', name: '' },
    add: true
  }
};


export { tableHeaders,newRfqItem };
