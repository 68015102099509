import httpService from './httpService';


const getSupplierEvaluation = (criteria) => {
    return httpService.FetchSupplierEvaluation(criteria);
};

const addSupplierEvaluation = (data) => {
    return httpService.CreateSupplierEvaluation({}, data);
};

const deleteSupplierEvaluation = (id) => {
    return httpService.DeleteSupplierEvaluation(id);
};

const editSupplierEvaluation = (id, data) => {
    return httpService.UpdateSupplierEvaluation({}, data);
};

const submitSupplierEvaluation = (id) => {
    return httpService.FinalizeSupplierEvaluation(id);
};

const editEvaluationFactor = (id, data) => {
    return httpService.UpdateEvaluationFactor(
        { id: id.id }
        , data);
};

export { getSupplierEvaluation, addSupplierEvaluation, deleteSupplierEvaluation, editSupplierEvaluation, submitSupplierEvaluation, editEvaluationFactor };
