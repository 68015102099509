
const tableHeaders = [

  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty pricelist
const newContract = () => {
  return {}
};

export { tableHeaders, newContract };
