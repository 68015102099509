const urlParams = {
    urlFiltersWhitelist : [
        'page','per_page','order_type','order_by'
    ],
    getUrlParams : () => {
        let urlParamsObject = {};
        const urlParams = new URLSearchParams(window.location.search);
        for (const [key, value] of urlParams) {
            urlParamsObject = {
                ...urlParamsObject,
                [decodeURI(key)]: decodeURI(value)
            }
        }

        return urlParamsObject;
    },

    setUrlParams: (criteria) => {
        const whiteListCriteria = {};
        Object.keys(criteria).map((key, index) => {
          if(urlParams.urlFiltersWhitelist.includes(key) && !!criteria[key]) {
              whiteListCriteria[key] = criteria[key];
          }
          return '';
        });
        let queryString = '?';
        Object.keys(whiteListCriteria).map((key, index) => {
            queryString += `${encodeURI(key)}=${encodeURI(criteria[key])}`;
            if(index < Object.keys(whiteListCriteria).length - 1) {
                queryString += '&'
            }
            return '';
        });

        window.history.pushState(null, null, queryString);
    }
}

export  { urlParams };