import React from 'react';
import './style.scss';
import TrendingUp from '@mui/icons-material/TrendingUp';
import TrendingDown from '@mui/icons-material/TrendingDown';

const PercentagePresenter = (props) => {
    
    const themes = {
        red : {
            fontColor: '#ff6384',
            progressBarColor : 'linear-gradient(270deg,#ff7e9a,#ff6384)'
        },
        springGreen: {
            fontColor: '#00ff7f',
            progressBarColor : 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
        },
        green : {
            fontColor: '#b8e986',
            progressBarColor : 'linear-gradient(270deg,#d8efc1,#b8e986)'
        },
        blue : {
            fontColor: '#48b5ff',
            progressBarColor : 'linear-gradient(270deg,#7edbff,#48b5ff)'
        },
    };

    return (
        <section
            className='percentage-presenter'
        >
            <div className='row'>
                <div
                    className='percentage-presenter__total-amount'
                    style={{color: themes[props.theme].fontColor}}
                >
                    <span>{props.amount}</span>
                    {props.percentage > 50 ?
                        <TrendingUp /> : <TrendingDown />}
                </div>
                <div className='percentage-presenter__total-amount-label'>{props.label}</div>
            </div>
            <div className='row percentage-presenter__progress-section'>

                <span className='percentage-presenter__percentage'>
                    {props.percentage || 0}%
                </span>
                <div className={'progress progress__outer'}>
                    <div
                        className={'progress progress__inner'}
                        style={
                            {
                                backgroundImage: themes[props.theme].progressBarColor,
                                width: props.percentage+'%'
                            }}
                    ></div>
                </div>
            </div>
        </section>
    )
};

export default PercentagePresenter;