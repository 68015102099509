import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme) => {
  return {
    sidebarLink: {
      paddingLeft: 7,
      textDecoration: 'none',
      color: 'inherit',
      textAlign: 'center',
      display: 'block',
      width: '100%',
    },
    activeListItem: {
      borderLeft: '7px solid #70bbfd',
      paddingLeft: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    listItemIcon: {
      width: 17,
      height: 17,
      minWidth: 30,
    },
  };
});

export default useStyles;
