import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import { tableHeadersUnit } from '../../helpers/unitsConfig';
import TableHeader from '../../components/tableHeader';
import TableActions from '../../components/TableActions/TableActions';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FilterUnitCategories from '../../components/filterUnitCategories';
import FilterUnits from '../../components/filterUnits';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from './EditRowStyles';

const tableHeaderComponent = () => {
    return <TableHeader
        headCells={tableHeadersUnit}
        onSelectAllClick={() => { }}
        sort={false}
        order={''}
        orderBy={''}
        rowCount={''} />;
};

const EditRow = ({ validator,
    onEditableUnitChange,
    handleSaveUnit,
    handleCloseEditUnit,
    unresolvedUnits,
    selectedUnit,
    selectedUnitCategory }) => {
    const classes = useStyles();
    return (
        <Table stickyHeader>
            {tableHeaderComponent()}
            <TableBody>
                <TableRow className={classes.tableRow}>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedUnits.unitEditObject.name || ''}
                                onChange={e => onEditableUnitChange(e, 'name')}
                                onBlur={() => validator.current.showMessageFor('name')}
                            />{validator.current.message('name', unresolvedUnits.unitEditObject.name, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FilterUnitCategories
                                unitCategory={unresolvedUnits.unitEditObject.category}
                                getSelectedUnitCategory={(data) => selectedUnitCategory(data, 'edit')}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedUnits.unitEditObject.quantity || ''}
                                onChange={e => onEditableUnitChange(e, 'quantity')}
                                onBlur={() => validator.current.showMessageFor('quantity')}
                            />{validator.current.message('quantity', unresolvedUnits.unitEditObject.quantity, 'numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedUnits.unitEditObject.conversion || ''}
                                onChange={e => onEditableUnitChange(e, 'conversion')}
                                onBlur={() => validator.current.showMessageFor('conversion')}
                            />{validator.current.message('conversion', unresolvedUnits.unitEditObject.conversion, 'numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FilterUnits
                                unit={unresolvedUnits.unitEditObject.subUnit}
                                getSelectedUnit={(data) => selectedUnit(data, 'edit')}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedUnits.unitEditObject.notes || ''}
                                onChange={e => onEditableUnitChange(e, 'notes')}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                variant="outlined"
                                control={
                                    <Switch
                                        checked={unresolvedUnits.unitEditObject.isUnresolved}
                                        onChange={e => onEditableUnitChange(e, 'isUnresolved')}
                                        name=" isUnresolved"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                }
                                label="IsUnresolved"
                            /></FormControl>
                    </TableCell>
                    <TableCell>
                        <TableActions config={{
                            edit: {
                                enabled: false,
                                callback: () => null
                            },
                            delete: {
                                enabled: false,
                                callback: () => null
                            },
                            close: {
                                enabled: true,
                                callback: (e) => handleCloseEditUnit(e, unresolvedUnits.unitEditIndex)
                            },
                            save: {
                                enabled: true,
                                callback: (e) => handleSaveUnit(e)
                            }
                        }} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

}

export { EditRow };