import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllCatalogues } from '../../services/cataloguesService';

export default function FilterCatalogues({ catalogue: initialCatalogue, getSelectedCatalogue, errorMessage }) {

  const [catalogue, setCatalogue] = React.useState(0);
  const [catalogues, setCatalogues] = React.useState([]);
  const [openCatologue, setOpenCatologue] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setCatalogue(initialCatalogue && initialCatalogue.id !== null ? initialCatalogue : 0);
    setCatalogues(initialCatalogue && initialCatalogue.id !== null ? [initialCatalogue] : []);
    getAllCatalogues().then((res) => {
      setCatalogues(res.data);
    }).catch((error) => {
    })
}, [initialCatalogue]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
      setSearch(value);
  };

  const onCataloguesSelectionChange = (event, values) => {
    setCatalogue(values ? values : '');
    const selectedCatalogue = {
      catalogue: values ? values : '',
    };
    getSelectedCatalogue(selectedCatalogue);
  };

  return (
    <div>
      <Autocomplete
        id={"find-catalogue " + Math.random()}
        fullWidth
        open={openCatologue}
        onOpen={() => {
          setOpenCatologue(true);
        }}
        onClose={() => {
          setOpenCatologue(false);
        }}
        onInputChange={e => handleInputChange(e, 'catalogue')}
        onChange={onCataloguesSelectionChange}
        value={catalogues.find(c => c.id === catalogue.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={catalogues !== null ? catalogues : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('CATALOGUE')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
      />
    </div>
  );
}