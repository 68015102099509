import React from 'react';
import Button from '@mui/material/Button';
import TableContainer from "@mui/material/TableContainer";
import {
    TextField
} from "@mui/material";
import useCountryMatchHook from './useCountryMatchHook';
import './CountryMatch.scss'
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import CountryMatchTable from "./CountryMatchTable";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { EditRow } from './EditRow';
import Typography from '@mui/material/Typography';
import t from '../../helpers/languages';

const CountryMatch = (props) => {
    const {
        unresolvedCountries,
        isConfirmDialogOpen,
        resolvedCountries,
        filters,
        selectedCountries,
        onCountryClick,
        onMatchBtnClick,
        setConfirmDialogOpen,
        onSearchTextChange,
        onCountryMatchConfirm,
        validator,
		confirmDialogDeleteOpen,
        onEditableCountryChange,
        handleSaveCountry,
        handleChangePage,
        handleChangeRowsPerPage,
        ref,
		handleDeleteCountry,
        handleCloseEditCountry,
    } = useCountryMatchHook();

    return (
        <div className={'country-match'}>
            <div className={'country-match__selection-wrapper'}>
                <Paper
                    className={'paper'}
                    elevation={4}
                >
                    <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'country-match__search-actions'}>
                                    <Typography variant="h4" >
                                        <div className={'country-match__title'}>
                                            {t('COUNTRY_RESOLUTION')}
                                        </div>
                                    </Typography>
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        onChange={e => onSearchTextChange(e, 'unresolved')}
                                        value={filters.unresolved.search}
                                    />
                                </div>
                                <CountryMatchTable
                                    countries={unresolvedCountries}
                                    countriesCategory={'unresolvedCountry'}
                                    selectedCountries={selectedCountries}
                                    handleChangePage={handleChangePage}
									handleDeleteCountry={handleDeleteCountry}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onCountryClick}
                                />
                            </TableContainer>
                        </Grid>
                        <Grid container item xs={2} direction="column" alignItems="center" justifyContent="center">
                            <div className={'country-match__btn-action-wrapper'}>
                                <Button variant="contained"
                                    color="primary"
                                    onClick={onMatchBtnClick}
                                    size="large"
                                    disabled={!selectedCountries.resolvedCountry || !selectedCountries.unresolvedCountry}
                                >
                                    {t('MATCH')}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'country-match__search-actions'}>
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        fullWidth
                                        inputRef={ref}
                                        onChange={e => onSearchTextChange(e, 'resolved')}
                                        value={filters.resolved.search||''}
                                    />
                                </div>
                                <CountryMatchTable
                                    countries={resolvedCountries}
                                    countriesCategory={'resolvedCountry'}
                                    selectedCountries={selectedCountries}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onCountryClick}
                                />
                            </TableContainer>

                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={
                                    `Are you sure you want to match those Countries: 
                        [${selectedCountries?.unresolvedCountry?.name}]  
                         [${selectedCountries?.resolvedCountry?.name}]?`
                                }
                                dialogTitle={'Country Match'}
                                handleCancel={() => setConfirmDialogOpen(false)}
                                handleOk={onCountryMatchConfirm}
                                open={isConfirmDialogOpen}
                            />
							<ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={confirmDialogDeleteOpen.message}
                                dialogTitle={confirmDialogDeleteOpen.title}
                                handleCancel={confirmDialogDeleteOpen.onCancel}
                                handleOk={confirmDialogDeleteOpen.onConfirm}
                                open={confirmDialogDeleteOpen.isOpen}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            {unresolvedCountries.countryEditIndex !== -1 && unresolvedCountries.countryEditObject?.id && <EditRow
                                unresolvedCountries={unresolvedCountries}
                                validator={validator}
                                onEditableCountryChange={onEditableCountryChange}
                                handleSaveCountry={handleSaveCountry}
                                handleCloseEditCountry={handleCloseEditCountry}
                            />}
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        </div>
    );
}

export default CountryMatch;
