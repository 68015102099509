import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersUnit, newUnit } from '../../helpers/unitsConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllUnitsInfo, editUnit, deleteUnit, addUnit } from '../../services/unitsService';
import { UnitTableFilter } from '../../components/unitTableFilter';
import FilterUnitCategories from '../../components/filterUnitCategories';
import FilterUnits from '../../components/filterUnits';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import './_units.scss';
import t from '../../helpers/languages';

const Units = (props) => {

  const unitInitObject = () => {
    return {
      id: '?',
      name: '',
      category: { name: '', id: '' },
      quantity: '',
      conversion: '',
      notes: '',
      subUnit: { name: '', id: '' },
      isUnresolved: false,
    }
  };


  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('unitFilter');

  const [unitsState, setUnitsState] = useState({
    units: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    unitEditIndex: -1,
    unitEditObject: unitInitObject(),
    unitCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...unitsState.filters,
    category: filters && filters.category && filters.category.id,
    search: filters && filters.search,
    isUnresolved: filters && filters.isUnresolved,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllUnitsInfo(filtersData).then(res => {
      const { results } = res.data;

      setUnitsState({
        ...unitsState,
        units: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUnitsState({
        ...unitsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setUnitsState({
      ...unitsState,
      isLoading: true
    });
    const newCriteria = {
      ...unitsState.filters,
      per_page: unitsState.page.per_page,
      page: newPage + 1
    };
    getAllUnitsInfo(newCriteria).then(res => {
      setUnitsState({
        ...unitsState,
        isLoading: false,
        units: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setUnitsState({
      ...unitsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...unitsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllUnitsInfo(newCriteria).then(res => {
      setUnitsState({
        ...unitsState,
        isLoading: false,
        units: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUnitsState({
        ...unitsState,
        isLoading: false,
      });
    });
  };

  const handleEditUnit = (event, unitIndex) => {

    event.stopPropagation();

    const unitEditObject = Object.assign({}, unitsState.units[unitIndex]);


    setUnitsState({
      ...unitsState,
      unitEditIndex: unitIndex,
      unitEditObject: unitEditObject
    });

  };

  //////////////////////////// here
  const onEditableUnitChange = (event, property) => {

	let newValue = '';
    if (property === "isDiscountable" || property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditUnitItem = Object.assign({}, unitsState.unitEditObject);
    newEditUnitItem[property] = newValue;

    setUnitsState({
      ...unitsState,
      unitEditObject: newEditUnitItem
    });
  };

  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveUnit = (event, props) => {
    event.preventDefault();
    setUnitsState({
      ...unitsState,
      isLoading: true
    });
    const newEditUnitItem = Object.assign({}, unitsState.unitEditObject);

    if (validator.current.allValid()) {
      editUnit(newEditUnitItem.id, newEditUnitItem)
        .then(re => {
          const editedUnit = re.data;
          setUnitsState(oldUnits => {
            return {
              ...oldUnits,
              units: oldUnits.units.map(unit => {
                return unit.id === editedUnit.id
                  ? re.data : unit
              }),
              unitEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setUnitsState({
            ...unitsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setUnitsState({
        ...unitsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditUnit = (e, index) => {
    setUnitsState({
      ...unitsState,
      unitEditIndex: -1
    })
  };

  const handleDeleteUnit = (event, index) => {

    const unitForDeletion = unitsState.units[index];
    setUnitsState({
      ...unitsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Unit',
        onCancel: () => {
          setUnitsState({
            ...unitsState,
            confirmDialog: {
              ...unitsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteUnit(unitForDeletion.id).then(() => {
            const newUnits = [...unitsState.units];
            newUnits.splice(index, 1);
            setUnitsState({
              ...unitsState,
              units: newUnits,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewUnit = (e) => {
    e.preventDefault();

    setUnitsState({
      ...unitsState,
      unitCreateObject: newUnit()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewUnit = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newUnit = {};
    newUnit.name = unitsState.unitCreateObject.name;
    newUnit.category = unitsState.unitCreateObject.category;
    newUnit.quantity = unitsState.unitCreateObject.quantity;
    newUnit.subUnit = unitsState.unitCreateObject.subUnit;
    newUnit.notes = unitsState.unitCreateObject.notes;
    newUnit.conversion = unitsState.unitCreateObject.conversion;
    newUnit.isUnresolved = unitsState.unitCreateObject.isUnresolved;

    if (validator.current.allValid()) {
      addUnit(newUnit)
        .then(res => {
          setUnitsState({
            ...unitsState,
            units: [...unitsState.units, res.data],
            unitCreateObject: null,
            unitEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setUnitsState({
            ...unitsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewUnitObject = (e, property) => {
    
	let newValue='';
    if (property === "isDiscountable" || property === "isUnresolved") {
      newValue = e.target.checked ? true : false;
    }
    else {
      newValue = e.target.value;
    }

    const newEditUnitItem = Object.assign({}, unitsState.unitCreateObject);
    newEditUnitItem[property] = newValue;

    setUnitsState({
      ...unitsState,
      unitCreateObject: newEditUnitItem
    });
  };

  const filterData = (data) => {
    setUnitsState({
      ...unitsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...unitsState.filters,
      search: data && data.search,
      category: data && data.category && data.category.id,
      isUnresolved: data && data.isUnresolved
    };

    getAllUnitsInfo(filtersData).then(res => {
      const { results } = res.data;

      setUnitsState({
        ...unitsState,
        units: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('unitFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUnitsState({
        ...unitsState,
        isLoading: false,
      });
    });

  };


  const selectedUnitCategory = (data, action) => {

    const newEditUnitItem = Object.assign({},
      action === 'edit'
        ? unitsState.unitEditObject
        : unitsState.unitCreateObject
    );

    newEditUnitItem.category = data.unitCategory;

    setUnitsState({
      ...unitsState,
      [
        action === 'edit'
          ? 'unitEditObject'
          : 'unitCreateObject'
      ]: newEditUnitItem
    });
  };

  const selectedUnit = (data, action) => {

    const newEditUnitItem = Object.assign({},
      action === 'edit'
        ? unitsState.unitEditObject
        : unitsState.unitCreateObject
    );

    newEditUnitItem.subUnit = data.unit;

    setUnitsState({
      ...unitsState,
      [
        action === 'edit'
          ? 'unitEditObject'
          : 'unitCreateObject'
      ]: newEditUnitItem
    });
  };


  const tableFilterComponent = () => {
    return <UnitTableFilter
      search={(filters && filters.search) ? filters.search : unitsState.filters.search}
      category={(filters && filters.category) ? filters.category : unitsState.filters.category}
      isUnresolved={(filters && filters.isUnresolved) ? filters.isUnresolved : unitsState.filters.isUnresolved}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersUnit}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(unitsState.units) && unitsState.units.map((unit, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (unitsState.unitEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>{unit.category?.name}</TableCell>
                    <TableCell>{unit.quantity}</TableCell>
                    <TableCell>{unit.conversion}</TableCell>
                    <TableCell>{unit.subUnit?.name}</TableCell>
                    <TableCell>{unit.notes}</TableCell>
                    <TableCell>{unit.isUnresolved && <CheckIcon />}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={unitsState.unitEditObject.name || ''}
                          onChange={e => onEditableUnitChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', unitsState.unitEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterUnitCategories
                          unitCategory={unitsState.unitEditObject.category || null}
                          getSelectedUnitCategory={(data) => selectedUnitCategory(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={unitsState.unitEditObject.quantity || ''}
                          onChange={e => onEditableUnitChange(e, 'quantity')}
                          onBlur={() => validator.current.showMessageFor('quantity')}
                        />{validator.current.message('quantity', unitsState.unitEditObject.quantity, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={unitsState.unitEditObject.conversion || ''}
                          onChange={e => onEditableUnitChange(e, 'conversion')}
                          onBlur={() => validator.current.showMessageFor('conversion')}
                        />{validator.current.message('conversion', unitsState.unitEditObject.conversion, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterUnits
                          unit={unitsState.unitEditObject.subUnit || null}
                          getSelectedUnit={(data) => selectedUnit(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={unitsState.unitEditObject.notes || ''}
                          onChange={e => onEditableUnitChange(e, 'notes')}
                        />
                      </FormControl>
                    </TableCell>

					<TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={unitsState.unitEditObject.isUnresolved}
                              onChange={e => onEditableUnitChange(e, 'isUnresolved')}
                              name="isUnresolved"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="IsUnresolved"
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: unitsState.unitEditIndex !== index,
                    callback: (e) => handleEditUnit(e, index)
                  },
                  delete: {
                    enabled: unitsState.unitEditIndex !== index,
                    callback: (e) => handleDeleteUnit(e, index)
                  },
                  close: {
                    enabled: unitsState.unitEditIndex === index,
                    callback: (e) => handleCloseEditUnit(e, index)
                  },
                  save: {
                    enabled: unitsState.unitEditIndex === index,
                    callback: (e) => handleSaveUnit(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          unitsState.unitCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={unitsState.unitCreateObject.name || ''}
                  onChange={e => onEditNewUnitObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', unitsState.unitCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnitCategories
                  unitCategory={unitsState.unitCreateObject.category || null}
                  getSelectedUnitCategory={(data) => selectedUnitCategory(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={unitsState.unitCreateObject.quantity || ''}
                  onChange={e => onEditNewUnitObject(e, 'quantity')}
                  onBlur={() => validator.current.showMessageFor('quantity')}
                />{validator.current.message('quantity', unitsState.unitCreateObject.quantity, 'numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={unitsState.unitCreateObject.conversion || ''}
                  onChange={e => onEditNewUnitObject(e, 'conversion')}
                  onBlur={() => validator.current.showMessageFor('conversion')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnits
                  unit={unitsState.unitCreateObject?.subUnit || null}
                  getSelectedUnit={(data) => selectedUnit(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={unitsState.unitCreateObject.notes || ''}
                  onChange={e => onEditNewUnitObject(e, 'notes')}
                />
              </FormControl>
            </TableCell>

			<TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={unitsState.unitCreateObject.isUnresolved}
                        onChange={e => onEditNewUnitObject(e, 'isUnresolved')}
                        name="isUnresolved"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="ΙsUnresolved"
                  />
                </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setUnitsState({ ...unitsState, unitCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewUnit()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('UNITS')}
        pagination={{
          total: unitsState.page.total || 0,
          page: unitsState.page.page - 1 || 0,
          perPage: unitsState.page.per_page || 10,
          count: unitsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewUnit}
        newRowBtnEnabled={!unitsState.unitCreateObject}
        confirmDialog={unitsState.confirmDialog.message.length > 0 ? unitsState.confirmDialog : unitsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={unitsState.isLoading}
      />
    </>
  );
};


export default Units;
