import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import clsx from "clsx";
import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import TableActions from '../../components/TableActions/TableActions';
import React from "react";

const UnitMatchTable = ({ units, onRowClick, selectedUnits, unitsCategory, handleChangePage, handleChangeRowsPerPage, handleDeleteUnit }) => {
    const ucat = unitsCategory;
    return (
        <>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Category</TableCell>
                        {unitsCategory === 'unresolvedUnit' && <TableCell align="left">Action</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {units.units.map((unit, index) => {
                        return (
                            <TableRow key={unit.id}
                                onClick={() => onRowClick(unit, unitsCategory, index)}
                                className={clsx({
                                    'unit-item': true,
                                    'unit-item--selected': selectedUnits[unitsCategory]?.id === unit.id
                                })}
                            >
                                <TableCell>{unit.name}</TableCell>
                                <TableCell>{unit?.category?.name}</TableCell>
                                {unitsCategory === 'unresolvedUnit' && <TableCell><
                                    TableActions config={{
                                        edit: {
                                            enabled: false
                                        },
                                        delete: {
                                            enabled: true,
                                            callback: (e) => handleDeleteUnit(e, index)
                                        },
                                        close: {
                                            enabled: false
                                        },
                                        save: {
                                            enabled: false
                                        }
                                    }} /></TableCell>}

                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={units.page && units.page.total}
                onPageChange={(event, newPage) => handleChangePage(event, newPage, ucat)}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, ucat)}
                page={units.page && units.page.page - 1}
                rowsPerPage={units.page && units.page.per_page}
                rowsPerPageOptions={[5, 10, 50]}
            />
        </>
    )
}

export default UnitMatchTable