
const tableHeaders = [
  { id: 'product_code', disablePadding: false, label: 'Product Code', width: '8%', align: 'center' },
  { id: 'product_name', disablePadding: false, label: 'Product Name', width: '20%' },
  { id: 'quantity', disablePadding: false, label: 'Quantity', width: '5%' },
  { id: 'unit_name', disablePadding: false, label: 'Unit Name', width: '8%' },
  { id: 'unit_cost', disablePadding: false, label: 'Unit Cost', width: '9%' },
  { id: 'discount', disablePadding: false, label: 'Discount', width: '9%' },
  { id: 'total_cost', disablePadding: false, label: 'Total Cost', width: '6%' },
  { id: 'leadTimeDays', disablePadding: false, label: 'Lead (days)', width: '8%' },
  { id: 'notes', disablePadding: false, label: 'Notes', width: '20%' },
  { id: 'row-actions', disablePadding: false, width: '10%' },
];
  
// Empty quoteitem
const newQuoteItem = () => {
  return {
    category: { id: '', name: '' },
    notes: '',
    id: '',
    product: { code: '', id: '', name: '' },
    quantity: '',
    discount: '',
    remote_id: '',
    quoteitem_id: '',
    unit: { id: '', name: '' },
    add: true
  }
};

  export { tableHeaders, newQuoteItem };
  