

const tableHeadersProductDictionary = [
  { id: 'productAlias', disablePadding: false, label: 'Product Alias' },
  { id: 'productCode', disablePadding: false, label: 'Product Code' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersProductDictionaryGlobal = [
  { id: 'company', disablePadding: false, label: 'Company' },
  { id: 'productAlias', disablePadding: false, label: 'Product Alias' },
  { id: 'productCode', disablePadding: false, label: 'Product Code' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

// Empty Product dictionary
const newProductDictionary  = () => {
  return {
    id: '?',
    company: { name: '', id: '' },
    productAlias: null,
    productCode: null,
    original: null
  }
};

export { tableHeadersProductDictionary,tableHeadersProductDictionaryGlobal, newProductDictionary };
