import httpService from './httpService';

const getAllCodingSystems = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCodingSystemList(criteria);
  
};

export { getAllCodingSystems };
  