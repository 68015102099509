
const tableHeadersPortRegions = [
  { id: 'name', disablePadding: false, label: 'Port Name' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty port region
const newPortRegion = () => {
  return {};

};

export { tableHeadersPortRegions, newPortRegion  };