import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from "./styles";

const DoughnutChartWithGrid = (props) => {
  const classes = useStyles();

  useEffect(() => {

    const data1 = props.data1;
    const label1 = props.labels1;
    const data2 = props.data2;
    const label2 = props.labels2;
    let randomBackgroundColor1 = [];
    let randomBackgroundColor2 = [];

    let usedColors = new Set();
    const labelkk = props.labels1.concat(props.labels2);
    let dynamicColors = function () {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let color = "rgb(" + r + "," + g + "," + b + ")";

      if (!usedColors.has(color)) {
        usedColors.add(color);
        return color;
      } else {
        return dynamicColors();
      }
    };

    for (let i in label1) {
      randomBackgroundColor1.push(dynamicColors());
    }
    for (let i in label2) {
      randomBackgroundColor2.push(dynamicColors());
    }

    const con = document.getElementById(props.id);
    let c = new Chart(con, {
      type: 'doughnut',
      plugins: [ChartDataLabels],
      data: {
        labels: labelkk,
        datasets: [{
          data: data1,
          // label: "Rfq's",
          backgroundColor: randomBackgroundColor1,

          // [
          //     'rgba(102, 255, 181, 1)',
          //     'rgba(124, 188, 255, 1)',
          //     'rgba(243, 217, 102, 1)',
          //     'rgba(245, 70, 95, 1)',
          //     'rgba(255, 206, 86, 1)',
          // ],
          // borderColor: [
          //     'rgba(102, 255, 181, 1)',
          //     'rgba(124, 188, 255, 1)',
          //     'rgba(243, 217, 102, 1)',
          //     'rgba(245, 70, 95, 1)',
          //     'rgba(255, 206, 86, 1)',
          // ],
          borderWidth: .5,
          lineWidth: .3,
          datalabels: {
            // anchor: 'end',
            display: true,
          }
        },
        {
          data: data2,
          // label: "Rfq's",
          backgroundColor: randomBackgroundColor2,

          // [
          //     'rgba(102, 255, 181, 1)',
          //     'rgba(124, 188, 255, 1)',
          //     'rgba(243, 217, 102, 1)',
          //     'rgba(245, 70, 95, 1)',
          //     'rgba(255, 206, 86, 1)',
          // ],
          // borderColor: [
          //     'rgba(102, 255, 181, 1)',
          //     'rgba(124, 188, 255, 1)',
          //     'rgba(243, 217, 102, 1)',
          //     'rgba(245, 70, 95, 1)',
          //     'rgba(255, 206, 86, 1)',
          // ],
          borderWidth: .5,
          lineWidth: .3,
          datalabels: {
            display: true,
          }
        },
        ]
      },
      options: {
        cutout: '30%',
        layout: {
          padding: {
            left: 30
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true,
              generateLabels: function (chart) {
                // Get the default label list
                const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                const labelsOriginal = original.call(this, chart);

                // Build an array of colors used in the datasets of the chart
                let datasetColors = chart.data.datasets.map(function (e) {
                  return e.backgroundColor;
                });
                datasetColors = datasetColors.flat();

                // Modify the color and hide state of each label
                labelsOriginal.forEach(label => {
                  // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index

                  if (props?.labels1) {
                    label.datasetIndex = label.index < props.labels1.length ? 0 : 1;
                  }
                  else {
                    label.datasetIndex = 0;
                  }
                  // The hidden state must match the dataset's hidden state
                  label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                  // Change the color to match the dataset
                  label.fillStyle = datasetColors[label.index];
                });

                return labelsOriginal;
              }
            },
            onClick: function (mouseEvent, legendItem, legend) {
              // toggle the visibility of the dataset from what it currently is
              legend.chart.getDatasetMeta(
                legendItem.datasetIndex
              ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
              legend.chart.update();
            }
          },
          datalabels: {
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            display: function (context) {
              var dataset = context.dataset;
              var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              return value > count * 1.5;
            },
            font: {
              weight: 'bold'
            },
            padding: 6,
            formatter: (value, ctx) => {
              // let sum = 0;
              // let dataArr = ctx.chart.data.datasets[0].data;
              // dataArr.map(data => {
              //     sum += data;
              // });
              // let percentage = (value * 100 / sum).toFixed(2) + "%";
              return value + "%";
            },
          },

          tooltip: {
            callbacks: {
              title: function (context) {
                const labelIndex = (context[0].datasetIndex * 2) + context[0].dataIndex;
                return context[0].chart.data.labels[labelIndex]
              },
              label: function (context) {
                return context.formattedValue + "%";
              }
            }
          }
        },
        responsive: true,
      },
      // Core options
      aspectRatio: 4 / 3,
      cutoutPercentage: 32,
      layout: {
        padding: 32
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      },

    });
    return () => { c.destroy(); }
  }, [props.stats]);

  // const themes = {
  //     red: {
  //         fontColor: '#ff4861',
  //         progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
  //     },
  //     springGreen: {
  //         fontColor: '#00ff7f',
  //         progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
  //     },
  //     green: {
  //         fontColor: '#b8e986',
  //         progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
  //     },
  //     blue: {
  //         fontColor: '#48b5ff',
  //         progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
  //     },
  // };

  return (


    <section
      className={classes.chartRoot}
    >
      <Grid item xs={12}>
        <Typography variant="h5" component="div" gutterBottom>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <canvas
          id={props.id}
          width={props.doughnutWidth}
        // height={350}
        // height={'100%'}
        >
        </canvas>
      </Grid>
    </section>


  )
};

export default DoughnutChartWithGrid;

