import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import LoginPage from '../pages/login';
import Products from '../pages/products/Products';
import Units from '../pages/units/Units';
import Ports from '../pages/ports/Ports';
import Rfqs from '../pages/rfqs/Rfqs';
import RfqItems from '../pages/rfqItems';
import QuoteItems from '../pages/quoteItems';
import Quotes from '../pages/quotes/Quotes';
import Evaluations from '../pages/evaluations/Evaluations';
import Orders from '../pages/orders/orders';
import Vessels from '../pages/vessels/Vessels';
import Companies from '../pages/companies/Companies';
import OrderItems from '../pages/orderItems';
import ProductMatch from '../pages/productMatch/ProductMatch';
import TransactionItem from '../pages/transactionsItem';
import Transactions from '../pages/transactions';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';
import AccountSettings from '../pages/accountSettings/AccountSettings';
import DataAudit from '../pages/dataAudit/DataAudit';
import Deliveries from '../pages/deliveries/deliveries';
import DeliveriesItems from '../pages/deliveriesItems';
import PortMatch from '../pages/portMatch/PortMatch';
import UnitMatch from '../pages/unitMatch/UnitMatch';
import Countries from '../pages/countries/Countries';
import CountryMatch from '../pages/countryMatch/CountryMatch';
import CompanyInfo from '../pages/companyInfo/CompanyInfo';
import ComAudit from '../pages/comAudit/ComAudit';
import RegionPurchases from '../pages/regionPurchases';
import VesselPurchases from '../pages/vesselPurchases';
import RequestComparison from '../pages/requestComparison';
import OrderItemsEvaluation from '../pages/orderItemsEvaluation';
import RatedProducts from '../pages/ratedProducts';
import PricelistsComparison from '../pages/pricelistsComparison';
import ClearCache from '../pages/clearCache/ClearCache';
import EquivalentsProduct from '../pages/equivalentsProduct/equivalentsProducts';
import ProductDictionaries from '../pages/productDictionaries/ProductDictionaries';
import PortDictionaries from '../pages/portDictionaries/PortDictionaries';
import CountryDictionaries from '../pages/countryDictionaries/CountryDictionaries';
import UnitDictionaries from '../pages/unitDictionaries/UnitDictionaries';
import Regions from '../pages/regions/Regions';
import RegionsInfo from '../pages/regionsInfo/RegionsInfo';

const Routes = (props) => {

    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Transactions}
                    exact
                    path='/'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Transactions}
                    exact
                    path='/transactions'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Evaluations}
                    exact
                    path='/transactions/:id/suppliers/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={OrderItemsEvaluation}
                    exact
                    path='/transactions/:id/rated-products/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Dashboard}
                    exact
                    path='/dashboards'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Products}
                    exact
                    path='/products'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={ProductDictionaries}
                    exact
                    path='/product-dictionaries'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={EquivalentsProduct}
                    exact
                    path='/product-equivalents'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RatedProducts}
                    exact
                    path='/rated-products'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Units}
                    exact
                    path='/units'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={UnitDictionaries}
                    exact
                    path='/unit-dictionaries'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Ports}
                    exact
                    path='/ports'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={PortDictionaries}
                    exact
                    path='/port-dictionaries'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Regions}
                    exact
                    path='/regions'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={UnitMatch}
                    exact
                    path='/Unit/match'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={CountryMatch}
                    exact
                    path='/Country/match'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Countries}
                    exact
                    path='/countries'
                />
                 <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={CountryDictionaries}
                    exact
                    path='/country-dictionaries'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Rfqs}
                    exact
                    path='/Rfqs'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RfqItems}
                    exact
                    path='/Transactions/:id/Rfqs/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RfqItems}
                    exact
                    path='/Rfqs/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={TransactionItem}
                    exact
                    path='/Transactions/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={AccountSettings}
                    exact
                    path='/account-settings'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={AccountSettings}
                    exact
                    path='/account-settings/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Quotes}
                    exact
                    path='/Quotes'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={QuoteItems}
                    exact
                    path='/Transactions/:id/Quotes/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={QuoteItems}
                    exact
                    path='/Quotes/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Orders}
                    exact
                    path='/Orders'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={OrderItems}
                    exact
                    path='/Transactions/:id/Orders/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={OrderItems}
                    exact
                    path='/Orders/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Deliveries}
                    exact
                    path='/Deliveries'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={DeliveriesItems}
                    exact
                    path='/Transactions/:id/Deliveries/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={DeliveriesItems}
                    exact
                    path='/Deliveries/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={ProductMatch}
                    exact
                    path='/Product/match'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Vessels}
                    exact
                    path='/Vessels'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Vessels}
                    exact
                    path='/Vessels/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={Companies}
                    exact
                    path='/Companies'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={CompanyInfo}
                    exact
                    path='/Companies/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={PortMatch}
                    exact
                    path='/Port/match'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={DataAudit}
                    exact
                    path='/data-audit'
                />      
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={ComAudit}
                    exact
                    path='/com-audit'
                />     
               <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RegionPurchases}
                    exact
                    path='/region-purchases'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RegionsInfo}
                    exact
                    path='/regions/:id'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={VesselPurchases}
                    exact
                    path='/vessel-purchases'
                />
               <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={RequestComparison}
                    exact
                    path='/transactions/:id/comparison/'
                />        
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={PricelistsComparison}
                    exact
                    path='/pricelists-comparison'
                />
                <PrivateRoute
                    pageFilter={true} 
                    autonomousPage={true}
                    userState={props.userState}
                    component={ClearCache}
                    exact
                    path='/clear-cache'
                />      
                <PublicRoute
                    pageFilter={false}
                    autonomousPage={true}
                    userState={props.userState}
                    component={LoginPage}
                    exact
                    path='/login'
                />
                <Redirect
                    from='*'
                    to='/'
                />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
