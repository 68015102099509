const tableHeadersBuyer = [
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'port', disablePadding: false, label: 'Port' },
  { id: 'supplier', disablePadding: false, label: 'Supplier' },
  { id: 'catalogue', disablePadding: false, label: 'Catalogue' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersSupplier = [
  { id: 'vessel', disablePadding: false, label: 'Vessel' },
  { id: 'port', disablePadding: false, label: 'Port' },
  { id: 'buyer', disablePadding: false, label: 'Buyer' },
  { id: 'supplier', disablePadding: false, label: 'Supplier' },
  { id: 'catalogue', disablePadding: false, label: 'Catalogue' },
  { id: 'row-actions', disablePadding: false },
];


// Empty rfq
const newBuyerPreference = () => {
  return {
    vessel: { name: '', id: '' },
    port: { name: '', id: '' },
    supplier: { name: '', id: '' },
    category: { name: '', id: '' },
    id: '?',
  }
};
export { tableHeadersBuyer, tableHeadersSupplier, newBuyerPreference };
