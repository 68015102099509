import clsx from 'clsx';
import React, {useState} from 'react';
import { Redirect, Route } from 'react-router-dom';

import Sidenav from '../components/sidenav';
import TopBar from '../components/topBar';

import './_main-app.scss';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [sidenavState, setSidenavState] = useState({
      expanded: false,
      toggle: (val) => setSidenavState({
          ...sidenavState,
          expanded: val === undefined ? !sidenavState.expanded : val
      })
  });

  const authenticated = rest?.userState?.accessToken;

  return (
    <>

      <TopBar {...rest} sideNavState={sidenavState} />
      <Sidenav {...rest} sideNavState={sidenavState} />
      <div className={ clsx('main-app-container', {
        shrink: sidenavState.expanded ,
        expanded: !sidenavState.expanded ,
      }) }
      >
        <Route
          { ...rest }
          render={ (props) => {
            return authenticated
              ? <Component { ...props }
                    userState={rest.userState}
                    pageFilter={rest.pageFilter}
                    autonomousPage={rest.autonomousPage}
                />
              : <Redirect to={{
                pathname: "/login",
                state: { from: props.location }
              }} />;
          } }
        />
      </div>
    </>
  );
};

export default PrivateRoute;
