
const tableHeadersBuyer = [
  { id: 'view', disablePadding: false, width:'5%'},
  { id: 'referencenum', disablePadding: false, label: 'Reference', width: '15%' },
  { id: 'supplier', disablePadding: false, label: 'Supplier', width: '10%' },
  { id: 'vessel', disablePadding: false, label: 'Vessel', width: '10%' },
  { id: 'category', disablePadding: false, label: 'Catalogue', width: '10%' },
  { id: 'deliveredItems', disablePadding: false, label: 'Items', width: '7%' },
  { id: 'port', disablePadding: false, label: 'Port', width: '10%' },
  { id: 'row-actions', disablePadding: false, width: '10%' },
];

const tableHeadersSupplier = [
  { id: 'view', disablePadding: false, width:'5%'},
  { id: 'referencenum', disablePadding: false, label: 'Reference', width: '15%' },
  { id: 'buyer', disablePadding: false, label: 'Customer', width: '10%' },
  { id: 'vessel', disablePadding: false, label: 'Vessel', width: '10%' },
  { id: 'category', disablePadding: false, label: 'Catalogue', width: '10%' },
  { id: 'deliveredItems', disablePadding: false, label: 'Items', width: '7%' },
  { id: 'port', disablePadding: false, label: 'Port', width: '10%' },
  { id: 'row-actions', disablePadding: false, width: '10%' },
];

export { tableHeadersBuyer, tableHeadersSupplier };
