import React, { useCallback } from "react";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllVessels } from '../../services/vesselsService';
import t from '../../helpers/languages';
import useStyles from './style';

export default function InlineEditAutocompleteVessels({ vessel: initialVessel, getSelectedVessel, saved, error, req=false, className }) {
  const classes = useStyles();

  const emptyVessel = {
    id: null,
    name: null
  };

  const [openVessel, setOpenVessel] = React.useState(false);
  const [vessel, setVessel] = React.useState(initialVessel !== '-' && initialVessel !== null && initialVessel.id !== null ? initialVessel : emptyVessel);
  const [vessels, setVessels] = React.useState([initialVessel !== '-' && initialVessel !== null && initialVessel.id !== null ? initialVessel : emptyVessel]);
  const [search, setSearch] = React.useState('');
  const handleSpanClick = useCallback(
    () => setOpenVessel(true),
    [setOpenVessel]);

  const handleInputChange = (e, fetchType) => {

    const value = e && e.target && e.target.value;
    if (value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    vesselSearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  //todo na mpei to then
  const onVesselSelectionChange = (event, values) => {

    setVessel(values ? values : emptyVessel);
    const selectedVessel = {
      vessel: values ? values : null,
    };

    getSelectedVessel(selectedVessel);


  };

  //   useEffect(() => {
  //     const selectedVessel = {
  //       vessel:vessel,
  //     };

  //     vessel&&vessel!==initialVessel&&openVessel===false&&getSelectedVessel(selectedVessel);

  // // eslint-disable-next-line
  //   }, [vessel,openVessel]);

  const vesselSearch = (criteria) => {

    return getAllVessels(criteria).then((res) => {
      setVessels(res.data);
    }).catch((error) => {
    })
  }
  return (
    <span className='inline-text'>
      {openVessel || !saved ? (
        <span>
          <Autocomplete
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            id="find-vessel"
            fullWidth
            onOpen={() => {
              setOpenVessel(true);
            }}
            onClose={() => {
              setOpenVessel(false);
            }}

            onInputChange={e => handleInputChange(e)}
            // onClose={handleClose}
            onChange={onVesselSelectionChange}
            value={vessels.find(v => v.id === vessel.id) || null}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name !== null ? option.name : '')}
            options={vessels !== null ? vessels : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin='none'
                variant="outlined"
                label={t('VESSEL')}
                error={error && !saved}
                helperText={error && !saved && "Value not saved."}
                InputProps={req&&vessel&&vessel.id===null?{...params.InputProps,className:classes.input }:{...params.InputProps}}
              // inputRef={input => input && input.focus()}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openVessel ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            id="outlined-disabled-vessel"
            label={t('VESSEL')}
            defaultValue={vessel && vessel.name}
            variant="outlined"
            InputProps={req&&vessel&&vessel.id===null?{className:classes.input+' '+'required-fields', readOnly: true }:{ readOnly: true}}
          />
        </span>)}
    </span >
  );
};
