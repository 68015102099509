import React from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import InlineEditAutocompleteVessels from '../../components/inlineEditAutocompleteVessels';
import InlineEditAutocompleteCompanies from '../../components/inlineEditAutocompleteCompanies';
import InlineEditAutocompletePorts from '../../components/inlineEditAutocompletePorts';
import InlineEditDate from '../../components/inlineEditDate';
import InlineEditNotes from '../../components/inlineEditNotes';
import OutlineDisplay from '../../components/outlineDisplay';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { Decline } from '../../components/actions';

const RfqInfo = (props) => {

    const selectedVessel = (data) => {
        props.getSelectedVessel(data);
    };

    const selectedCompany = (data) => {
        props.getSelectedCompany(data);
    };

    const selectedPort = (data) => {
        props.getSelectedPort(data);
    };

    const selectedDate = (data) => {
        props.getSelectedDate(data);
    };

    const rfqNotes = (data) => {
        props.getNotes(data);
    };

    const rfqReferenceNumber = (data) => {
        props.getReferenceNumber(data);
    };

    const rfqSupplierNotes = (data) => {
        props.getSupplierNotes(data);
    };

    const action = (data) => {
        return props.handleDecline(data);
    }

    return (
        !props.check && props.edit ? (
            <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                direction="row"
                spacing={3}
            >
                {props.alert && <Grid item xs={12}>
                    <Alert severity="error">
                        <Typography variant="subtitle1" gutterBottom>
                            {props.alert}
                        </Typography>
                    </Alert>
                </Grid>}
                <Grid item xs={3}>
                    <InlineEditAutocompleteVessels vessel={props.vessel} saved={props.savedVessel} req={true} error={props.error}
                        getSelectedVessel={selectedVessel} />
                </Grid>
                <Grid item xs={3}>
                    <InlineEditAutocompletePorts port={props.port} saved={props.savedPort} error={props.error} req={true}
                        getSelectedPort={selectedPort} />
                </Grid>
                <Grid item xs={2}>
                    <InlineEditNotes notes={props.referenceNumber} saved={props.savedReferenceNumber} label={t('REFERENCE_NUMBER')} error={props.error} req={true}
                        getNotes={rfqReferenceNumber} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('SUBMITTED')} value={props.submitted} />
                </Grid>
                <Grid item xs={2}>
                    <InlineEditDate due={props.due} saved={props.savedDue} error={props.error}
                        getSelectedDate={selectedDate} />
                </Grid>
                <Grid item xs={6}>
                    {!props.isRevised ? <InlineEditAutocompleteCompanies companySearchFor={'supplier'} supplier={props.supplier} company={props.company} saved={props.savedSupplier} error={props.error}
                        getSelectedCompany={selectedCompany} req={true} /> : <OutlineDisplay label={t('SUPPLIER')} value={props.company.name} />}
                </Grid>
                <Grid item xs={3}>
                    <OutlineDisplay label={t('CATALOGUE')} value={props.catalogue} />
                </Grid>
                <Grid item xs={3}>
                    <OutlineDisplay label={t('STATUS')} value={props.status} />
                </Grid>
                <Grid item xs={12}>
                    <InlineEditNotes notes={props.notes} saved={props.savedNotes} error={props.error}
                        getNotes={rfqNotes} />
                </Grid>
            </Grid>
        ) : (<>
            <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                direction="row"
                spacing={3}>
                {props.alert && <Grid item xs={12}>
                    <Alert severity="error">
                        <Typography variant="subtitle1" gutterBottom>
                            {props.alert}
                        </Typography>
                    </Alert>
                </Grid>}
                <Grid item xs={3}>
                    <OutlineDisplay label={t('VESSEL')} value={props.vessel ? props.vessel.name : null} />
                </Grid>
                <Grid item xs={3}>
                    <OutlineDisplay label={t('PORT')} value={props.port.name} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('REFERENCE_NUMBER')} value={props.referenceNumber} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('SUBMITTED')} value={props.submitted} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('DUE')} value={props.periodDue} />
                </Grid>
                    <Grid item xs={6}>
                        {props.userRole === "BUYER" ?
                            <OutlineDisplay label={t('SUPPLIER')} value={props.company?.name} /> :
                            <OutlineDisplay label={t('BUYER')} value={props.buyer?.name} />
                        }
                    </Grid>
                <Grid item xs={3}>
                    <OutlineDisplay label={t('CATALOGUE')} value={props.catalogue} />
                </Grid>
                <Grid item xs={3}>
                    <OutlineDisplay label={t('STATUS')} value={props.status} />
                </Grid>
                <Grid item xs={12}>
                    <OutlineDisplay label={t('NOTES')} value={props.notes} />
                </Grid>
                {
                    props.showSupplierNotes && <Grid item xs={12}>
                        <OutlineDisplay label={t('DECLINE_NOTES')} value={props.supplierNotes} />
                    </Grid>
                }
            </Grid>
            {
                props.editSupplier && <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    spacing={3}
                    style={{ marginTop: 14 }}
                > <Grid item xs={10}>
                        <InlineEditNotes notes={props.supplierNotes} saved={props.savedSupplierNotes} error={props.error} label={'DECLINE_NOTES'}
                            getNotes={rfqSupplierNotes} />
                    </Grid>
                    <Grid item xs={2}>
                        <Decline getAction={action} label={'DECLINE'} />
                    </Grid>
                </Grid>
            }</>
        )
    )
}


export default RfqInfo;