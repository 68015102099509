import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllUnitCategories } from '../../services/unitCategoriesService';
export default function FilterUnitCategories({ unitCategory: initialUnitCategory, getSelectedUnitCategory }) {

  const [unitCategory, setUnitCategory] = React.useState(0);
  const [unitCategories, setUnitCategories] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setSearch('');
    setUnitCategory(initialUnitCategory && initialUnitCategory.id !== null ? initialUnitCategory : 0);
    setUnitCategories(initialUnitCategory && initialUnitCategory.id !== null ? [initialUnitCategory] : []);
  }, [initialUnitCategory]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "unitCategory" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value)
    };
  };

  const handleDebounceFn = (val) => {
    unitCategorySearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onUnitCategorySelectionChange = (event, values) => {
    setUnitCategory(values ? values : '');
    const selectedUnitCategory = {
      unitCategory: values ? values : null, // send null in case the user deletes the data.
    };
    getSelectedUnitCategory(selectedUnitCategory);
  };
  const unitCategorySearch = (criteria) => {

    return getAllUnitCategories(criteria).then((res) => {
      setUnitCategories(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id={"find-unitCategory" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'unitCategory')}
        onChange={onUnitCategorySelectionChange}
        value={unitCategories.find(v => v.id === unitCategory.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={unitCategories !== null ? unitCategories : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('CATEGORY')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}