import React, { useCallback, useEffect } from 'react';
import t from "../../helpers/languages";
import { Autocomplete } from '@mui/material';
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import { getAllPorts } from "../../services/portsService";

export default function FilterPorts({ port: initialPort, getSelectedPort }) {
  const [port, setPort] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [ports, setPorts] = React.useState([]);
  const [openPort, setOpenPort] = React.useState(false);

  useEffect(() => {
    setPort(initialPort && initialPort.id !== null ? initialPort : 0);
    setPorts(initialPort && initialPort.id !== null ? [initialPort] : []);
  }, [initialPort]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "port" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    }
  };

  const handleDebounceFn = (val) => {
    const filterData = {
      search: val,
      isUnresolved:false,
    };
    portSearch(filterData);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onPortSelectionChange = (event, values) => {
    setPort(values ? values : "");
    const selectedPort = {
      port: values ? values : null, // return null when the value is empty string
    };
    getSelectedPort(selectedPort);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const portSearch = (criteria) => {
    return getAllPorts(criteria)
      .then((res) => {
        setPorts(res.data);
      })
      .catch((error) => { });
  };

  return (
    <Autocomplete
      id={"find-port" + randomNumber(0, 999)}
      fullWidth
      open={openPort}
      onOpen={() => { setOpenPort(true); }}
      onClose={() => { setOpenPort(false); }}
      onInputChange={(e) => handleInputChange(e, "port")}
      onChange={onPortSelectionChange}
      value={ports.find((v) => v.id === port.id) || null}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option.name !== null ? option.name : "")}
      options={ports !== null ? ports : null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("PORT")}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
