const dateToString = (dateDue,initialDate=null) => {

    if (dateDue !== '-' && dateDue !== null && dateDue===initialDate) {

        const dateString = new Date(dateDue);

        const year = dateString.getUTCFullYear();
        const mes = ('0' + (dateString.getUTCMonth() + 1)).slice(-2);
        const dia = ('0' + dateString.getUTCDate()).slice(-2);
        return year + "-" + mes + "-" + dia;
    } else if(dateDue !== '-' && dateDue !== null&&dateDue.isValid()){

    
        const dateString = new Date(dateDue);

        const year = dateString.getFullYear();
        const mes = ('0' + (dateString.getMonth() + 1)).slice(-2);
        const dia = ('0' + dateString.getDate()).slice(-2);
        return year + "-" + mes + "-" + dia;
    }
    
    
    else {
        return '';
    }
}

export default dateToString;