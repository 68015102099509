import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { debounce, Grid, TextField } from "@mui/material";
import Switch from '@mui/material/Switch';
import FilterCatalogues from "../filterCatalogues";
import FilterCompanies from "../../components/filterCompanies";
import './_equivalentsProductTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const EquivalentsProductTableFilter = ({
  search: initialSearch,
  catalogue: initialCatalogue,
  company: initialCompany,
  companySearchFor,
  isUnresolved: initialIsUnresolved,
  getFilterData
}) => {

  const classes = useStyles();

  const [search, setSearch] = useState(initialSearch || '');
  const [catalogue, setCatalogue] = useState(initialCatalogue || null);
  const [isUnresolved, setIsUnresolved] = useState(initialIsUnresolved || false);
  const [company, setCompany] = useState(initialCompany);

  const handleChange = (event, property) => {
    if (property === "isUnresolved") {
      setIsUnresolved(event.target.checked);
      const filterData = {
        isUnresolved: event.target.checked,
        category: catalogue,
        search: search,
        company: company,
      };

      debounceFn(filterData);
    }
    else {
      setSearch(event.target.value);
      const filterData = {
        isUnresolved: isUnresolved,
        category: catalogue,
        company: company,
        search: event.target.value
      };

      debounceFn(filterData);
    }
  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const selectedCatalogue = (data) => {
    setCatalogue(data.catalogue);
    const filterData = {
      isUnresolved: isUnresolved,
      category: data.catalogue,
      company: company,
      search: search
    };

    getFilterData(filterData);
  }

  const selectedCompany = (data) => {

    setCompany(data.company);
    const filterData = {
      category: catalogue,
      isUnresolved: isUnresolved,
      company: data.company,
      search: search
    };

    getFilterData(filterData);
  }

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCatalogues
              catalogue={catalogue}
              getSelectedCatalogue={selectedCatalogue}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCompanies
              company={company}
              companySearchFor={companySearchFor}
              getSelectedCompany={selectedCompany}
              errorMessage={''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              variant="outlined"
              control={
                <Switch
                  checked={isUnresolved}
                  onChange={e => handleChange(e, 'isUnresolved')}
                  name="isUnresolved"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              }
              label="IsUnresolved"
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}