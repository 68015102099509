import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    inline_text_copy_active: {
      font: 'inherit',
      color: 'inherit',
      textAlign: 'inherit',
      padding: 0,
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      pointerEvents: 'auto',
      position: 'relative',
      zΙndex: 2
    },
    inline_text_input_active: {
      font: 'inherit',
      color: 'inherit',
      textAlign: 'inherit',
      padding: 0,
      background: 'none',
      border: 'none',
      borderBottom: '1px solid #666666',
      outline: 'none',
    },

    inline_text_copy_hidden: { display: 'none', },
    inline_text_input_hidden: {
      display: 'none',
    },
  };
});

export default useStyles;