import React from "react";
import Rating from '@mui/material/Rating';

export default function OutlineRating({value}) {

    return (
        <Rating
            value={value}
            precision={0.5}
            readOnly={true}
        />
    );
};