import React, { useCallback, useEffect  } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllContractsProducts } from '../../services/contractsService';

export default function FilterProductsContract({ product: initialProduct, ports:initialPorts=null, contracts:initialContracts=null, getSelectedProduct, catalogue }) {

  const [product, setProduct] = React.useState(initialProduct && initialProduct.id !== null ? initialProduct : 0);
  const [products, setProducts] = React.useState(initialProduct && initialProduct.id !== null ? [initialProduct] : []);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [search, setSearch] = React.useState('');


  // useEffect(() => {
   
  //   setProduct(initialProduct && initialProduct.id !== null ? initialProduct : 0);
  //   setProducts(initialProduct && initialProduct.id !== null ? [initialProduct] : []);
  // }, [initialProduct]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "product" && value && value.length >= 3 ) {
      setSearch(value);
      debounceFn(value,initialPorts,initialContracts);
    };
  };


  const handleDebounceFn = (val, initialPorts, initialContracts) => {
    productSearch(val, {
      ports: initialPorts,
      contracts: initialContracts,
    })
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onProductSelectionChange = (event, values) => {
    setProduct(values ? values : '');
    const selectedProduct = {
      product: values ? values : '',
    };
    getSelectedProduct(selectedProduct);
  };
  const productSearch = (val,criteria) => {

    return getAllContractsProducts(val,criteria).then((res) => {
      setProducts(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id="find-product"
        fullWidth
        open={openProduct}
        onOpen={() => {
          setOpenProduct(true);
        }}
        onClose={() => {
          setOpenProduct(false);
        }}
        onInputChange={e => handleInputChange(e, 'product')}
        onChange={onProductSelectionChange}
        value={products.find(v => v.id === product.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={products !== null ? products : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('PRODUCT')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}