import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersComAudit } from '../../helpers/comAuditConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import { getAllComAudits } from '../../services/dataAuditService';
import { ComAuditTableFilter } from '../../components/comAuditTableFilter';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from "@mui/material/Collapse";
import Typography from '@mui/material/Typography';
import './_comAudit.scss';
import t from '../../helpers/languages';

const ComAudits = (props) => {

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('comAuditFilter');

  const [comAuditsState, setComAuditsState] = useState({
    comAudits: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });


  const [expanded, setExpanded] = useState({});

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...comAuditsState.filters,
    companyId: filters && filters.company && filters.company.id,
    search: filters && filters.search,
    searchId: filters && filters.transaction&& filters.transaction.id,
    per_page: criteria.per_page,
    page: criteria.page,
    order_type: comAuditsState.page.order_type,
    order_by: comAuditsState.page.order_by,
  };


  useEffect(function () {
    getAllComAudits(filtersData).then(res => {
      const { results } = res.data;
      setComAuditsState({
        ...comAuditsState,
        comAudit: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setComAuditsState({
        ...comAuditsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === comAuditsState.page.order_by && comAuditsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setComAuditsState({
      ...comAuditsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;
    filtersData.page = comAuditsState.page.page;
    filtersData.per_page = comAuditsState.page.per_page;

    getAllComAudits(filtersData).then(res => {
      const { results } = res.data;
      setComAuditsState({
        ...comAuditsState,
        comAudit: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setComAuditsState({
        ...comAuditsState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setComAuditsState({
      ...comAuditsState,
      isLoading: true
    });
    const newCriteria = {
      ...comAuditsState.filters,
      per_page: comAuditsState.page.per_page,
      page: newPage + 1
    };
    getAllComAudits(newCriteria).then(res => {
      setComAuditsState({
        ...comAuditsState,
        isLoading: false,
        comAudit: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setComAuditsState({
      ...comAuditsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...comAuditsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllComAudits(newCriteria).then(res => {
      setComAuditsState({
        ...comAuditsState,
        isLoading: false,
        comAudit: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setComAuditsState({
        ...comAuditsState,
        isLoading: false,
      });
    });
  };

  const filterData = (data) => {
    setComAuditsState({
      ...comAuditsState,
      isLoading: true
    });

    
    filtersData = {
      ...comAuditsState.filters,
      search: data && data.search,
      per_page: criteria.per_page,
      page: 1
    };

    getAllComAudits(filtersData)
      .then((res) => {
        setComAuditsState({
          ...comAuditsState,
          comAudit: res.data.results,
          isLoading: false,
          page: res.data.page,
          filters: res.data.filters,
        });
        localStoreSetObj("comAuditFilter", data);
      })
      .catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setComAuditsState({
          ...comAuditsState,
          isLoading: false,
        });
      });
  };

  const tableFilterComponent = () => {
    return <ComAuditTableFilter
      search={(filters && filters.search) ? filters.search : comAuditsState.filters.search}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeadersComAudit}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };

  const handleExpandClick = (id) => {

    setExpanded({
      ...expanded,
      [id]: !expanded[id]
    });

  };

  const Row = (props) => {

    const { row, index } = props;


    return (<React.Fragment>
      <TableRow
        key={index}
        className={classes.tableRow}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick(row.id)}
          >
            {expanded[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.dateCreated}</TableCell>
        <TableCell>{row.remoteIp}</TableCell>
        <TableCell>{row.path}</TableCell>
        <TableCell>{row.contentType}</TableCell>
        <TableCell>{row.protocol}</TableCell>
      </TableRow>
      <TableRow >
         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded[row.id]} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, whiteSpace: 'pre' }}>
              <Typography variant="h6" gutterBottom>
                Request
              </Typography> 
              <Typography paragraph={true}
                className={classes.wrapTextInTable}
                gutterBottom>
                {row.requestPayload}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Response
              </Typography>
              <Typography paragraph={true}
                className={classes.wrapTextInTable}
                gutterBottom>
                {row.responsePayload}
              </Typography>
              {/* {JSON.stringify(JSON.parse(row.responsePayload), null, 4)} */}
            </Box>
          </Collapse>

        </TableCell> 
      </TableRow>
    </React.Fragment>
    );
  }

  const tableBodyComponent = () => {
    return <TableBody>

      {Array.isArray(comAuditsState.comAudit) && comAuditsState.comAudit.map((data, index) => {
        return (
          <Row key={index} row={data} />
        );
      })}
    </TableBody>
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('COM_AUDIT')}
        pagination={{
          total: comAuditsState.page.total || 0,
          page: comAuditsState.page.page - 1 || 0,
          perPage: comAuditsState.page.per_page || 10,
          count: comAuditsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={''}
        onNewRowClick={''}
        newRowBtnEnabled={''}
        confirmDialog={comAuditsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={comAuditsState.isLoading}
      />
    </>
  );
};


export default ComAudits;
