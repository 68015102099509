import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InlineEditNotes from '../../components/inlineEditNotes';
import InlineEditPass from '../../components/inlineEditPass';
import InlineEditCataloguesMultiple from '../../components/inlineEditCataloguesMultiple';
import { Grid } from "@mui/material";
import './_account-settings.scss';
import clsx from "clsx";

const AccountSettingsEdit = ({
  user,
  isLoading,
  errors,
  onDataChange,
  onFormSubmit,
  getUserName,
  getFirstName,
  getLastName,
  getEmail,
  getPass,
  getNotes,
  getSelectedCatalogue,
  props
}) => {
  const selectedCatalogue = (data) => {
    getSelectedCatalogue(data);
  };

  const userName = (data) => {
    getUserName(data);
  };

  const firstName = (data) => {
    getFirstName(data);
  };

  const lastName = (data) => {
    getLastName(data);
  };

  const email = (data) => {
    getEmail(data);
  };

  const pass = (data) => {
    getPass(data);
  };

  const notes = (data) => {
    getNotes(data);
  };

  return (
    <form className={clsx({
      'account-settings__form': true,
    })}
      onSubmit={onFormSubmit}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditNotes notes={user.userName} saved={errors.isUserNameSaved} label={'Username'} error={errors.error}
              getNotes={userName} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditNotes notes={user.firstName} saved={errors.isFirstNameSaved} label={'Firstname'} error={errors.error}
              getNotes={firstName} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditNotes notes={user.lastName} saved={errors.isLastNameSaved} label={'Lastname'} error={errors.error}
              getNotes={lastName} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditNotes notes={user.email} saved={errors.isEmailSaved} label={'Email'} error={errors.error}
              getNotes={email} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <InlineEditPass pass={user.password} saved={errors.isPassSaved} label={'PASSWORD'} error={errors.error}
              getPass={pass} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditCataloguesMultiple catalogue={user.category} saved={errors.isCatalogueSaved} label={'CATALOGUE'} error={errors.error}
              getSelectedCatalogue={selectedCatalogue} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <FormGroup className={'account-settings__form-raw user-type'}>
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox
                  checked={!!user.isSuperUser}
                  disabled={(props.userState.user.userRole !== 'MBA' && props.userState.user.userRole !== 'ADMIN') ? true : false}
                  onChange={onDataChange}
                  name="isSuperUser"
                />}
                label="Super User"
              />
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox
                  checked={!!user.isStaff}
                  disabled={(props.userState.user.userRole !== 'MBA' && props.userState.user.userRole !== 'ADMIN') ? true : false}
                  onChange={onDataChange}
                  name="isStaff"
                />}
                label="Staff user"
              />
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox
                  checked={!!user.isActive}
                  disabled={(props.userState.user.userRole !== 'MBA' && props.userState.user.userRole !== 'ADMIN') ? true : false}
                  onChange={onDataChange}
                  name="isActive"
                />}
                label="Active User"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InlineEditNotes notes={user.notes} saved={errors.isNotesSaved} label={'Notes'} error={errors.error}
              getNotes={notes} />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};


export default AccountSettingsEdit;
