

const tableHeadersPortDictionary = [
  { id: 'altAlias', disablePadding: false, label: 'Port Alias' },
  { id: 'portCode', disablePadding: false, label: 'Port Code' },

  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersPortDictionaryGlobal = [
  { id: 'company', disablePadding: false, label: 'Company' },
  { id: 'altAlias', disablePadding: false, label: 'Port Alias' },
  { id: 'portCode', disablePadding: false, label: 'Port Code' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

// Empty Port dictionary
const newPortDictionary  = () => {
  return {
    id: '?',
    company: { name: '', id: '' },
    altAlias: null,
    portCode: null,
    original: null
  }
};

export { tableHeadersPortDictionary, tableHeadersPortDictionaryGlobal, newPortDictionary };
