
const tableHeadersProduct = [
  { id: 'code', disablePadding: false, label: 'Code' },
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'category', disablePadding: false, label: 'Catalogue' },
  { id: 'codingSystem', disablePadding: false, label: 'Coding' },
  { id: 'unresolved', disablePadding: false, label: 'Unresolved' },
  { id: 'discountable', disablePadding: false, label: 'Discountable' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty product
const newProduct = () => {
  return {
    id: '?',
    name: '',
    code: '',
    category: { name: null, id: null },
    coding: { name: null, id: null },
    isUnresolved: false,
    isDiscountable: false,
  }
};

export { tableHeadersProduct, newProduct };
