import httpService from './httpService';

const getRFQStats = (criteria = {}) => {
    return httpService.FetchRFQStats(criteria);
};

const getQuoteStats = (criteria = {}) => {
    return httpService.FetchQuoteStats(criteria);
};

const getOrderStats = (criteria = {}) => {
    return httpService.FetchOrderStats(criteria);
};

const getDeliveryStats = (criteria = {}) => {
    return httpService.FetchDeliveryStats(criteria);
};

const getTransactionStats = (criteria = {}) => {
    return httpService.FetchTransactionStats(criteria);
};

const getPortStats = (criteria = {}) => {
    return httpService.FetchPortStats(criteria);
};

const getVesselStats = (criteria = {}) => {
    return httpService.FetchVesselStats(criteria);
};

const getCatalogueStats = (criteria = {}) => {
    return httpService.FetchCatalogueStats(criteria);
};

const getRegionReport = (criteria = {}) => {
    return httpService.FetchRegionReport(criteria);
};

const getVesselReport = (criteria = {}) => {
    return httpService.FetchVesselReport(criteria);
};

export {
    getRFQStats,
    getQuoteStats,
    getOrderStats,
    getDeliveryStats,
    getTransactionStats,
    getPortStats,
    getVesselStats,
    getCatalogueStats,
    getRegionReport,
    getVesselReport
};