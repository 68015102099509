import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { debounce, Grid, TextField } from "@mui/material";
import FilterTransactions from '../filterTransactions';
import './_dataAuditTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const DataAuditTableFilter = ({ search: initialSearch, transaction: initialTransaction, getFilterData }) => {
  const classes = useStyles();

  const [search, setSearch] = useState(initialSearch||'');
  const [transaction, setTransaction] = useState(initialTransaction);

  const handleChange = (event) => {

    setSearch(event.target.value);
    const filterData = {
      search: event.target.value,
      transaction: transaction,
    };

    debounceFn(filterData);

  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const selectedTransaction = (data) => {
    setTransaction(data.transaction);
    const filterData = {
      transaction: data.transaction,
      search: search
    };

    getFilterData(filterData);
  }

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e)}
              name="search"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterTransactions
              transaction={transaction}
              getSelectedTransaction={selectedTransaction}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}