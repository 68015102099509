import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllVessels } from '../../services/vesselsService';
export default function FilterVessels({ vessel: initialVessel, getSelectedVessel, company = null, disabled = false }) {

  const [vessel, setVessel] = React.useState(initialVessel && initialVessel.id !== null && !disabled ? initialVessel : 0);
  const [vessels, setVessels] = React.useState(initialVessel && initialVessel.id !== null && !disabled ? [initialVessel] : []);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(function () {
    disabled && setVessel(0);
    disabled && setVessels([]);
  }, [disabled]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "vessel" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    if (company) {
      vesselSearch({
        search: val,
        companyId: company.id
      })
    } else {
      vesselSearch({
        search: val
      })
    }
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), [company]);

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onVesselSelectionChange = (event, values) => {

    setVessel(values ? values : '');
    const selectedVessel = {
      vessel: values ? values : null, // return null when the value is empty string
    };

    getSelectedVessel(selectedVessel);
  };

  const vesselSearch = (criteria) => {

    getAllVessels(criteria).then((res) => {
      setVessels(res.data);
    }).catch((error) => {
    })
  };

  return (
    <div>
      <Autocomplete
        id={"find-vessel" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        disabled={disabled}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'vessel')}
        onChange={onVesselSelectionChange}
        value={vessels.find(v => v.id === vessel.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={vessels !== null ? vessels : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('VESSEL')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}