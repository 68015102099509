import { useCallback, useEffect, useState, useRef } from "react";
import { getAllUnitsInfo, matchUnits, editUnit, deleteUnit } from "../../services/unitsService";
import SimpleReactValidator from 'simple-react-validator';
import { debounce } from "lodash";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';

const useUnitMatchHook = () => {

    const unitInitObject = () => {
        return {
            id: '?',
            name: '',
            code: '',
            category: { name: null, id: null },
            coding: { name: null, id: null },
            isUnresolved: false,
        }
    };

    const [unresolvedUnits, setUnresolvedUnits] = useState({
        units: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        unitEditIndex: -1,
        unitEditObject: unitInitObject(),
        error: null,
    })

    const [resolvedUnits, setResolvedUnits] = useState({
        units: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        error: null,
    })
    const filtersMatch = localStoreGetObj('unitMatchFilter');
    const ref=useRef(null);
    const [filters, setFilters] = useState({
        unresolved: {
            category: filtersMatch?.unresolved&&filtersMatch.unresolved.categorya&&filtersMatch.unresolved.categorya.id,
            categorya:filtersMatch?.unresolved&&filtersMatch.unresolved.categorya,
            isUnresolved: true,
            search: '',
            page: filtersMatch?.unresolved&&filtersMatch.unresolved.page||1,
        },
        resolved: {
            category: filtersMatch?.resolved&&filtersMatch.resolved.categorya&&filtersMatch.resolved.categorya.id,
            categorya: filtersMatch?.resolved&&filtersMatch.resolved.categorya,
            isUnresolved: 'false',
            search: filtersMatch?.resolved.search,
            page: filtersMatch?.resolved&&filtersMatch.resolved.page||1,
        }
    })
    const [selectedUnits, setSelectedUnits] = useState({
        unresolvedUnit: null,
        resolvedUnit: null,
    })

    const [confirmDialogDeleteOpen, setConfirmDialogDeleteOpen] = useState({
        message: '',
        title: '',
        onCancel: () => { },
        onConfirm: () => { },
        isLoading: false,
        isOpen: false
    });

    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

    useEffect(() => {
        getAllUnitsInfo(filters.unresolved).then(res => {
            const results = res.data.results;
            setUnresolvedUnits({
                ...unresolvedUnits,
                units: results,
                page: res.data.page,
            });
        });
        getAllUnitsInfo(filters.resolved).then(res => {
            const results = res.data.results;
            setResolvedUnits({
                ...resolvedUnits,
                units: results,
                page: res.data.page,
            });
        });
    },
        // eslint-disable-next-line  
        []);

    const loadUnitsByCategory = (category, filters, categorya) => {

        getAllUnitsInfo(filters[category]).then(res => {
            const results = res.data.results;

            if (category === 'unresolved') {
                setUnresolvedUnits({
                    ...unresolvedUnits,
                    units: results,
                    page: res.data.page,
                    unitEditIndex: -1
                })

                setSelectedUnits(prevSelectedUnits => {
                    return {
                        ...prevSelectedUnits,
                        ['unresolvedUnit']: null
                    }
                })
            }
            else {
                setResolvedUnits({
                    ...resolvedUnits,
                    units: results,
                    page: res.data.page,
                })

                setSelectedUnits(prevSelectedUnits => {
                    return {
                        ...prevSelectedUnits,
                        ['resolvedUnit']: null
                    }
                })
            }
        });

        const cat = category === 'unresolved' ? 'unresolved' : 'resolved';

        if (categorya !== undefined) {
            const newFilters = {
                ...filters,
                [cat]: {
                    ...filters[cat],
                    categorya,


                }
            }
            localStoreSetObj('unitMatchFilter', newFilters);
        } else {

            const newFilters = {
                ...filters,
                [cat]: {
                    ...filters[cat],



                }
            }
            localStoreSetObj('unitMatchFilter', newFilters);
        }
    }

    const onFilterUnitCategoryChange = (e, categoryFilter) => {
        const category = e.unitCategory ? e.unitCategory.id : null
        const newFilters = {
            ...filters,
            [categoryFilter]: {
                ...filters[categoryFilter],
                category,
                page: 1
            }
        }
        setFilters(newFilters);
        loadUnitsByCategory(categoryFilter, newFilters, e.unitCategory);
    }

    const onUnitClick = (unit, category, index) => {

        if (category === 'unresolvedUnit') {
            const unitEditObject = Object.assign({}, unresolvedUnits.units[index]);
            setUnresolvedUnits({
                ...unresolvedUnits,
                unitEditIndex: index,
                unitEditObject: unitEditObject
            });

            const input =  ref.current;
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value").set
            nativeInputValueSetter.call(input, unitEditObject.name.substring(0, 4));
            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
        }

        setSelectedUnits(prevSelectedUnits => {
            return {
                ...prevSelectedUnits,
                [category]: unit
            }
        })
    }
    const debounceLoadData = useCallback(debounce(loadUnitsByCategory, 500), []);
    const onSearchTextChange = (e, category) => {
        const newSearchValue = e.target.value;
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                search: newSearchValue,
                page: 1
            }
        }
        setFilters(newFilters);
        category!=='resolved'&&setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditIndex: -1
        });
        debounceLoadData(category, newFilters);
    }
    const onMatchBtnClick = () => {
        setConfirmDialogOpen(true);
    }

    const onUnitMatchConfirm = () => {
        matchUnits({
            id: selectedUnits.unresolvedUnit.id,
            matchId: selectedUnits.resolvedUnit.id
        })
            .then(res => {
                const newFilters = {
                    unresolved: {
                        category: filters.unresolved.category,
                        categorya: filters.unresolved.categorya,
                        isUnresolved: true,
                        search: filters.unresolved.search,
                        page: unresolvedUnits.page.page
                    },
                    resolved: {
                        category: filters.resolved.category,
                        categorya: filters.resolved.categorya,
                        isUnresolved: 'false',
                        search: filters.resolved.search,
                        page: resolvedUnits.page.page
                    }
                }
                setFilters(newFilters)
                loadUnitsByCategory('unresolved', newFilters)
                loadUnitsByCategory('resolved', newFilters)
                setConfirmDialogOpen(false);
            })
    }

    const [, forceUpdate] = useState();
    //todo
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));

    const onEditableUnitChange = (event, property) => {
        let newValue = '';
        if (property === "isUnresolved") {
            newValue = event.target.checked ? true : false;
        }
        else {
            newValue = event.target.value;
        }
        const newEditUnitItem = Object.assign({}, unresolvedUnits.unitEditObject);
        newEditUnitItem[property] = newValue;

        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditObject: newEditUnitItem
        });
    };
    const handleSaveUnit = (event, props) => {
        event.preventDefault();
        setUnresolvedUnits({
            ...unresolvedUnits,
            isLoading: true
        });
        const newEditUnitItem = Object.assign({}, unresolvedUnits.unitEditObject);

        if (validator.current.allValid()) {
            editUnit(newEditUnitItem.id, newEditUnitItem)
                .then(re => {
                    const editedUnit = re.data;
                    if (!re.data.isUnresolved) {
                        const newUnresolvedUnits = [...unresolvedUnits.units];
                        newUnresolvedUnits.splice(unresolvedUnits.unitEditIndex, 1);
                        setUnresolvedUnits({
                            ...unresolvedUnits,
                            units: newUnresolvedUnits,
                            unitEditIndex: -1
                        });
                    }
                    else {
                        setUnresolvedUnits(oldUnits => {
                            return {
                                ...oldUnits,
                                units: oldUnits.units.map(unit => {
                                    return unit.id === editedUnit.id
                                        ? re.data : unit
                                }),
                                unitEditIndex: -1
                            }
                        });
                    }
                }).catch((error) => {
                    setUnresolvedUnits({
                        ...unresolvedUnits,
                        isLoading: false,
                    });
                });
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
            setUnresolvedUnits({
                ...unresolvedUnits,
                isLoading: false,
            });
        }

    };

    const handleCloseEditUnit = (e, index) => {
        setSelectedUnits(prevSelectedUnits => {
            return {
                ...prevSelectedUnits,
                unresolvedUnit: null
            }
        })
        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditIndex: -1
        })
    };

    const selectedUnit = (data) => {

        const newEditUnitItem = Object.assign({}, unresolvedUnits.unitEditObject);

        newEditUnitItem.subUnit = data.unit;

        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditObject: newEditUnitItem
        });
    };


    const selectedUnitCategory = (data) => {

        const newEditUnitItem = Object.assign({}, unresolvedUnits.unitEditObject);

        newEditUnitItem.category = data.unitCategory;

        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditObject: newEditUnitItem
        });
    };

    const handleDeleteUnit = (event, index) => {

        const unitForDeletion = unresolvedUnits.units[index];

        setConfirmDialogDeleteOpen({
            ...confirmDialogDeleteOpen,
            message: 'Are you sure you want to delete?',
            title: 'Delete Unit',
            onCancel: () => {
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                })
            },
            onConfirm: () => {
              deleteUnit(unitForDeletion.id).then(() => {
                const newUnits = [...unresolvedUnits.units];
                newUnits.splice(index, 1);
                setUnresolvedUnits({
                    ...unresolvedUnits,
                  units: newUnits,
                });
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                });
              })
                .catch(err => {
                  alert('ERROR')
                });
            },
            isOpen: true
          });

      };

    const handleChangePage = (e, newPage, category) => {
        let filterCategory = '';
        const np = newPage;
        if (category === 'unresolvedUnit') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: np + 1
            },
        }
        setFilters(newFilters);
        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    const handleChangeRowsPerPage = (e, category) => {


        let filterCategory = '';
        const np = e.target.value;
        if (category === 'unresolvedUnit') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: 1,
                per_page: np
            },
        }
        setFilters(newFilters);
        setUnresolvedUnits({
            ...unresolvedUnits,
            unitEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    return {
        unresolvedUnits,
        setUnresolvedUnits,
        isConfirmDialogOpen,
        resolvedUnits,
        setResolvedUnits,
        filters,
        setFilters,
        selectedUnits,
        selectedUnit,
        setSelectedUnits,
        loadUnitsByCategory,
        onFilterUnitCategoryChange,
        onMatchBtnClick,
        onUnitClick,
        onSearchTextChange,
        setConfirmDialogOpen,
        onUnitMatchConfirm,
        validator,
        confirmDialogDeleteOpen,
        handleDeleteUnit,
        onEditableUnitChange,
        handleSaveUnit,
        handleChangePage,
        handleChangeRowsPerPage,
        handleCloseEditUnit,
        ref,
        selectedUnitCategory
    }
}

export default useUnitMatchHook