import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllVesselDwts } from '../../services/vesselsService';

export default function FilterDwts({ dwt: initialDwt, getSelectedDwt }) {

  const [dwt, setDwt] = React.useState(0);
  const [dwts, setDwts] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setDwt(initialDwt && initialDwt.id !== null ? initialDwt : 0);
    setDwts(initialDwt && initialDwt.id !== null ? [initialDwt] : []);
    getAllVesselDwts().then((res) => {
      setDwts(res.data);
    }).catch((error) => {
    })
  }, [initialDwt]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
      setSearch(value);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }
  const onDwtselectionChange = (event, values) => {
    setDwt(values ? values : '');
    const selectedDwt = {
      dwt: values ? values : '',
    };
    getSelectedDwt(selectedDwt);
  };

  return (
    <div>
      <Autocomplete
        id={"find-dwt" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'dwt')}
        onChange={onDwtselectionChange}
        value={dwts.find(v => v.id === dwt.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={dwts !== null ? dwts : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Dwt')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}