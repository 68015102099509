import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import t from '../../helpers/languages';
import './_login.scss';
import userService from "../../services/userService";
import httpService from "../../services/httpService";
import {getAllAuthorizations} from "../../services/authorizationsService";
import {EventBus} from "../../services/eventBus";

const LoginPage = () => {
  const [ passwordVisibility, setPasswordVisibility ] = useState(false);
  const { register, errors, clearError, triggerValidation, handleSubmit } = useForm();

  const validate = useCallback((prop) => {
    return async () => {
      switch (prop) {
        case 'userName':
          await triggerValidation('userName');
          break;
        case 'password':
          await triggerValidation('password');
          break;
        default:
          break;
      }
    };
  }, [ triggerValidation ]);

  const clearErrorField = useCallback((prop) => {
    return () => {
      switch (prop) {
        case 'userName':
          clearError('userName');
          break;
        case 'password':
          clearError('password');
          break;
        default:
          break;
      }
    };
  }, [ clearError ]);

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility(!passwordVisibility);
  }, [ passwordVisibility ]);

  const loginUser = useCallback(async (data, e) => {
    e.preventDefault();
    await triggerValidation();

    userService.userLogin(data).then((res) => {
      httpService.injectHeader('Authorization', `Bearer ${res.data.access_token}`);
      httpService.defaults.extra = { refresh_token: res.data.refresh_token };
      getAllAuthorizations()
      .then((authorizationRes) => {
        const newUserState = {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
          user : {
            userName: res.data.user_name,
            userRole: res.data.user_role,
            userCompany: res.data.user_company,
            userId: res.data.user_id
          },
          authorizations: authorizationRes.data.authorizationRules
        }
        EventBus.publish('user-state-change',{ detail: newUserState } )
        localStorage.removeItem('portMatchFilter')
        localStorage.removeItem('productMatchFilter')
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error',
        //   message: 'failed',
        // }));
      }).finally(() => {
        //dispatch(setAuthorizationsLoading(false));
      });
    }).catch((error) => {

      //dispatch(loginUserFail(error));
      // dispatch(messagesActions.openSnackbar({
      //   severity: 'error',
      //   message: 'error '+error,
      // }));
    }).finally(() => {
      //dispatch(setUserLoading(false));
    });

    
  }, [  triggerValidation ]);

  return (
    <main className='login-page'>
      <div className='login-page__form-wrapper'>
        <div className='login-page__header'>
          <div className='login-page__header-first-row'>
            <span className='login-page__default-title'>
              {t('LOGIN_TITLE_TEXT_1')}
            </span>
            <span className='login-page__default-title-bold'>
             
            </span>
            <span className='login-page__default-title-blue-bold'>
            
            </span>
          </div>
          <div className='login-page__header-second-row'>
            <span className='login-page__small-title'>
              {t('LOGIN_TITLE_SMALL_TEXT')}
            </span>
          </div>
        </div>
        <form className='login-page__form' noValidate onSubmit={ handleSubmit(loginUser) }>
          <TextField
            autoComplete='username'
            autoFocus
            error={ !!errors.userName }
            fullWidth
            helperText={ errors.userName ? errors.userName.message : t('LOGIN_FORM_USER_NAME_HINT_MESSAGE') }
            id='username'
            InputProps={ {
              startAdornment: (
                <AccountCircleIcon color={ errors.userName ? 'error' : 'action' } />
              ),
            } }
            inputRef={
              register({
                defaultValue: '',
                required: t('LOGIN_FORM_USER_NAME_ERROR_MESSAGE_REQUIRED'),
              })
            }
            label={ t('LOGIN_FORM_USER_NAME_LABEL') }
            margin='normal'
            name={ t('LOGIN_FORM_USER_NAME') }
            onBlur={ validate('userName') }
            onFocus={ clearErrorField('userName') }
            required
            variant='outlined'
          />
          <TextField
            autoComplete='current-password'
            error={ !!errors.password }
            fullWidth
            helperText={ errors.password ? errors.password.message : t('LOGIN_FORM_PASSWORD_HINT_MESSAGE') }
            id='password'
            InputProps={ {
              startAdornment: (
                <LockOutlinedIcon color={ errors.password ? 'error' : 'action' } />
              ),
              endAdornment: (
                <IconButton
                  aria-label='See password'
                  onClick={ togglePasswordVisibility }
                  type='button'
                  size="large">
                  {passwordVisibility ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            } }
            inputRef={
              register({
                defaultValue: '',
                required: t('LOGIN_FORM_PASSWORD_ERROR_MESSAGE_REQUIRED'),
              })
            }
            label={ t('LOGIN_FORM_PASSWORD_LABEL') }
            margin='normal'
            name={ t('LOGIN_FORM_PASSWORD_NAME') }
            onBlur={ validate('password') }
            onFocus={ clearErrorField('password') }
            required
            type={ passwordVisibility ? 'text' : 'password' }
            variant='outlined'
          />
          <div className='login-page__submit-button-container'>
            <Button
              color='primary'
              disabled={
                !!errors.userName || !!errors.password
              }
              disableElevation
              type='submit'
              variant='contained'
            >
              <span>{t('LOGIN')}</span>
            </Button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default LoginPage;
