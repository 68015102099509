import {CircularProgress} from "@mui/material";
import React from "react";
import clsx from 'clsx';
import './PageLoader.scss';

const PageLoader = (props) => {

    return (
        <section className={clsx({
            'page-loader': true,
            'page-loader--loading': props.isLoading,
        })}>
            <CircularProgress color={'primary'} size={60} />
        </section>
    )
}

export default PageLoader;