const localStoreSetObj = (key, obj) => {
    try { return localStorage.setItem(key, JSON.stringify(obj)) }
    catch (error) {
        return false;
    }

}
const localStoreGetObj = (key) => {
    try { return JSON.parse(localStorage.getItem(key)) }
    catch (error) {
        return false;
    }

}

export { localStoreSetObj, localStoreGetObj };
