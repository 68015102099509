import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/tableHeader';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
import TextField from "@mui/material/TextField";
import FilterProducts from '../../components/filterProducts';
import FilterUnits from '../../components/filterUnits';
import TableActions from '../../components/TableActions/TableActions';
import {
  submitDelivery,
  deleteDelivery,
  getDeliveyById,
  editDelivery,
  importDeliveryInvoices,
} from '../../services/deliveriesService';
import { getDeliveryItems, edit, add, deleteDeliveryItem } from '../../services/deliveriesItemsService';
import { tableHeaders, newDeliveriesItem } from '../../helpers/deliveriesItemsConfig';
import DeliveriesInfo from '../../components/deliveriesInfo';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import SimpleReactValidator from 'simple-react-validator';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import { TableFilterItems } from '../../components/tableFilterItems';
import t from '../../helpers/languages';
import FileUploader from '../../components/fileUploader';





const DeliveriesItems = (props) => {

  const [disabled, setDisabled] = React.useState(true);
  const [, setDELIVERIESNotes] = React.useState('');


  const [, setDeliveriesItemEditObject] = useState({});
  const deliveriesInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      delivered_items: '',
      isCompleted: ''
    }
  };

  const [deliveriesState, setDeliveriesState] = useState({
    deliveries: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      deliveries_type: 'asc',
      deliveries_by: ''
    },
    filters: {},
    isLoading: true,
    deliveriesEditIndex: -1,
    deliveriesEditObject: deliveriesInitObject(),
    deliveriesCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
    error: null,
    isDeliveriesSaved: false,
    isNotesSaved: true,
    isDueSaved: true
  });

  const deliveriesItemInitObject = () => {
    return {
      category: { id: '', name: '', code: '' },
      leadTimeDays: '',
      notes: '',
      id: '',
      product: { code: '', id: '', name: '' },
      productDescription: '',
      totalCost: '',
      quantity: '',
      deliveriesId: '',
      remote_id: '',
      deliveriesitem_id: '',
      unit: { id: '', name: '', categoryId: '', categoryName: '' },
      unitCost: '',
      net_price: '',
      unitDescription: '',
      add: true,
      deliveredItems: '',
      supplied: '',
    }
  };


  const [deliveriesItemsState, setDeliveriesItemsState] = useState({
    deliveriesItems: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      deliveries_type: 'asc',
      deliveries_by: 'id'
    },
    filters: {},
    isLoading: true,
    deliveriesItemEditIndex: -1,
    deliveriesItemEditObject: deliveriesItemInitObject(),
    deliveriesItemCreateObject: null,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });


  const pathName = window.location.pathname;
  const regex = /\d+$/;
  let m;

  m = regex.exec(pathName);

  const deliveriesId = m[0];

  const deliveriesCriteria = { id: deliveriesId };

  const deliveriesItemCriteria = urlParams.getUrlParams();
  !deliveriesItemCriteria.per_page && (deliveriesItemCriteria.per_page = 10)
  !deliveriesItemCriteria.page && (deliveriesItemCriteria.page = 1)
  deliveriesItemCriteria.id = deliveriesId;
  urlParams.setUrlParams(deliveriesItemCriteria);
  deliveriesItemCriteria.deliveries_type = deliveriesItemsState.page.deliveries_type;
  deliveriesItemCriteria.deliveries_by = deliveriesItemsState.page.deliveries_type;

  useEffect(() => {
    setDeliveriesState({
      ...deliveriesState,
      isLoading: true
    });


    setDeliveriesItemsState({
      ...deliveriesItemsState,
      isLoading: true
    });

    getDeliveryItems(deliveriesItemCriteria).then(res => {
      const { results } = res.data;
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        deliveriesItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
      });
    });



    getDeliveyById(deliveriesCriteria).then(res => {

      setDeliveriesState({
        ...deliveriesState,
        deliveries: res.data,
        isLoading: false
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setDeliveriesState({
        ...deliveriesState,
        isLoading: false,
      });
    });

    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();


    const isAsc = property === deliveriesItemsState.page.deliveries_by && deliveriesItemsState.page.deliveries_type === 'desc' ? 'asc' : 'desc';
    let filtersData = {
      ...deliveriesItemsState.filters,
      per_page: deliveriesItemsState.page.per_page,
      page: deliveriesItemsState.page.page,
      deliveries_type: isAsc,
      deliveries_by: property,
      id: deliveriesId
    };
    setDeliveriesItemsState({
      ...deliveriesItemsState,
      isLoading: true,
      deliveries_type: isAsc,
      deliveries_by: property,
    });


    getDeliveryItems(filtersData).then(res => {

      setDeliveriesItemsState({
        ...deliveriesItemsState,
        deliveriesItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setDeliveriesItemsState({
      ...deliveriesItemsState,
      isLoading: true,
    });

    const newCriteria = {
      ...deliveriesItemsState.filters,
      per_page: deliveriesItemsState.page.per_page,
      page: newPage + 1
    };

    getDeliveryItems(newCriteria).then(res => {
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
        deliveriesItemsEditIndex: -1,
        deliveriesItemsCreateObject: null,
        deliveriesItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setDeliveriesItemsState({
      ...deliveriesItemsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...deliveriesItemsState.filters,
      page: 1,
      per_page: perPage,
      id: deliveriesId
    };

    getDeliveryItems(newCriteria).then(res => {
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        deliveriesItems: res.data.results,
        deliveriesItemsEditIndex: -1,
        isLoading: false,
        deliveriesItemsCreateObject: null,
        page: res.data.page,
        filters: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
      });
    });
  };

  const handleDeleteDeliveriesItem = (event, index) => {

    const deliveriesItemForDeletion = deliveriesItemsState.deliveriesItems[index];


    setDeliveriesItemsState({
      ...deliveriesItemsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Deliveries Item',
        onCancel: () => {
          setDeliveriesItemsState({
            ...deliveriesItemsState,
            confirmDialog: {
              ...deliveriesItemsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteDeliveryItem(deliveriesItemForDeletion.id).then(() => {
            const newDeliveriesItems = [...deliveriesItemsState.deliveriesItems];
            newDeliveriesItems.splice(index, 1);
            setDeliveriesItemsState({
              ...deliveriesItemsState,
              deliveriesItems: newDeliveriesItems,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const handleEditDeliveriesItem = (event, deliveriesItemIndex) => {
    event.stopPropagation();

    const deliveriesItemEditObject = Object.assign({}, deliveriesItemsState.deliveriesItems[deliveriesItemIndex]);

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      deliveriesItemEditIndex: deliveriesItemIndex,
      deliveriesItemEditObject: deliveriesItemEditObject
    });

  };


  const shouldSetStateToDisabled = (deliveriesItemsState.deliveriesItems.length > 0);

  useEffect(() => {
    if (shouldSetStateToDisabled) {
      setDisabled(
        false

      );
    } else {
      setDisabled(
        true
      )
    }
  }, [shouldSetStateToDisabled])

  const onEditableDeliveriesItemChange = (event, property) => {
    const newValue = event.target.value;
    const newEditDeliveriesItem = Object.assign({}, deliveriesItemsState.deliveriesItemEditObject);
    newEditDeliveriesItem[property] = newValue;

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      deliveriesItemEditObject: newEditDeliveriesItem
    });
  };

  const handleSaveDeliveriesItem = (event, property) => {
    event.preventDefault();
    const newEditDeliveriesItem = Object.assign({}, deliveriesItemsState.deliveriesItemEditObject);
    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: true,
      });
      edit(newEditDeliveriesItem.id, newEditDeliveriesItem)
        .then(re => {
          const editedDeliveriesItem = re.data;
          setDeliveriesItemsState(oldDeliveriesItems => {
            return {
              ...oldDeliveriesItems,
              deliveriesItems: oldDeliveriesItems.deliveriesItems.map(deliveriesItem => {
                return deliveriesItem.id === editedDeliveriesItem.id
                  ? re.data : deliveriesItem
              }),
              deliveriesItemEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setDeliveriesItemsState({
            ...deliveriesItemsState,
            isLoading: false,
          });
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleCloseEditDeliveriesItem = (e, index) => {
    setDeliveriesItemsState({
      ...deliveriesItemsState,
      deliveriesItemEditIndex: -1
    })
  };

  const handleAddNewDeliveriesItem = (e) => {
    e.preventDefault();
    setDeliveriesItemsState({
      ...deliveriesItemsState,
      deliveriesItemCreateObject: newDeliveriesItem()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewDeliveriesItem = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newDeliveriesItem = {};
    newDeliveriesItem.product = deliveriesItemsState.deliveriesItemCreateObject.product;
    newDeliveriesItem.unit = deliveriesItemsState.deliveriesItemCreateObject.unit;
    newDeliveriesItem.quantity = deliveriesItemsState.deliveriesItemCreateObject.quantity;
    newDeliveriesItem.notes = deliveriesItemsState.deliveriesItemCreateObject.notes;
    newDeliveriesItem.deliveriesId = deliveriesState.deliveries.id;
    newDeliveriesItem.unitCost = 0;
    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: true,
      });
      add(newDeliveriesItem)
        .then(res => {
          setDeliveriesItemsState({
            ...deliveriesItemsState,
            deliveriesItems: [...deliveriesItemsState.deliveriesItems, res.data],
            deliveriesItemCreateObject: null,
            deliveriesItemEditIndex: -1,
            isLoading: false,
          })

        })
        .catch(err => {
          setDeliveriesItemsState({
            ...deliveriesItemsState,
            isLoading: false,
          });
          alert('error');
        });
    }
    else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onNewDeliveriesItemChange = (e, property) => {

    let newValue = '';
    newValue = e.target.value;

    const newCreateDeliveriesItem = Object.assign({}, deliveriesItemsState.deliveriesItemCreateObject);
    newCreateDeliveriesItem[property] = newValue;

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      deliveriesItemCreateObject: newCreateDeliveriesItem
    });
  };

  const handleDeleteDeliveries = (event) => {

    const deliveriesForDeletion = deliveriesId;
    setDeliveriesState({
      ...deliveriesState,
      isLoading: true
    });
    setDeliveriesState({
      ...deliveriesState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Deliveries',
        onCancel: () => {
          setDeliveriesState({
            ...deliveriesState,
            confirmDialog: {
              ...deliveriesState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteDelivery(deliveriesForDeletion).then(() => {
            window.location.replace('/deliveries');
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const handleSubmitDeliveries = (e) => {


    const deliveries = {
      id: deliveriesState.deliveries.id
    };
    setDeliveriesState({
      ...deliveriesState,
      isLoading: true
    });
    submitDelivery(deliveries)
      .then(res => {
        setDeliveriesState({
          ...deliveriesState,
          deliveries: res.data,
          isLoading: false,
          isDeliveriesSaved: true
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setDeliveriesState({
          ...deliveriesState,
          isLoading: false,
          isDeliveriesSaved: false
        });
      });
  };

  const selectedProduct = (data, action) => {

    const newEditDeliveriesItem = Object.assign({},
      action === 'edit'
        ? deliveriesItemsState.deliveriesItemEditObject
        : deliveriesItemsState.deliveriesItemCreateObject
    );

    if (data.product.id !== undefined) {
      newEditDeliveriesItem.product.id = data.product.id;
      newEditDeliveriesItem.product.name = data.product.name;
      newEditDeliveriesItem.product.code = data.product.code;
      //todo what is product description
      newEditDeliveriesItem.productDescription = data.product.name;

    } else {
      newEditDeliveriesItem.product.id = '';
      newEditDeliveriesItem.product.name = '';
      newEditDeliveriesItem.product.code = '';
      newEditDeliveriesItem.productDescription = '';
    }

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      [
        action === 'edit'
          ? 'deliveriesItemEditObject'
          : 'deliveriesItemCreateObject'
      ]: newEditDeliveriesItem
    });

  };

  const deliveriesNotes = (data) => {
    setDELIVERIESNotes(data);

    const deliveriesEditObject = Object.assign({}, deliveriesState.deliveries);
    if (data !== undefined && data !== '-' && data !== null) {
      deliveriesEditObject.notes = data.notes;
    } else {
      deliveriesEditObject.notes = null;
    }

    setDeliveriesItemEditObject(deliveriesEditObject);

    editDelivery({ id: deliveriesState.deliveries.id }, deliveriesEditObject)
      .then(res => {
        setDeliveriesState({
          ...deliveriesState,
          deliveries: res.data,
          isLoading: false,
          isNotesSaved: true
        });
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setDeliveriesState({
          ...deliveriesState,
          isLoading: false,
          isNotesSaved: false
        });
      });
  };

  const selectedUnit = (data, action) => {

    const newEditDeliveriesItem = Object.assign({},
      action === 'edit'
        ? deliveriesItemsState.deliveriesItemEditObject
        : deliveriesItemsState.deliveriesItemCreateObject
    );

    if (data.unit.id !== undefined) {
      newEditDeliveriesItem.unit.id = data.unit.id;
      newEditDeliveriesItem.unit.name = data.unit.name;
      newEditDeliveriesItem.unit.categoryId = data.unit.categoryId;
      newEditDeliveriesItem.unit.categoryName = data.unit.categoryName;
      newEditDeliveriesItem.unit.id = data.unit.id;
      //todo what is product description
      newEditDeliveriesItem.unitDescription = data.unit.name;
    } else {
      newEditDeliveriesItem.unit.id = '';
      newEditDeliveriesItem.unit.name = '';
      newEditDeliveriesItem.unit.categoryId = '';
      newEditDeliveriesItem.unit.categoryName = '';
      newEditDeliveriesItem.unitDescription = '';
    }

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      [
        action === 'edit'
          ? 'deliveriesItemEditObject'
          : 'deliveriesItemCreateObject'
      ]: newEditDeliveriesItem
    });

  };

  const handleSearchItems = (data) => {

    setDeliveriesItemsState({
      ...deliveriesItemsState,
      isLoading: true
    });


    let filtersData = {
      search: data.search,
      per_page: deliveriesItemCriteria.per_page,
      page: 1,
      id: deliveriesId
    };
    getDeliveryItems(filtersData).then(res => {
      const { results } = res.data;

      setDeliveriesItemsState({
        ...deliveriesItemsState,
        deliveriesItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setDeliveriesItemsState({
        ...deliveriesItemsState,
        isLoading: false,
      });
    });
  };

  const handleUpload = (data) => {

    importDeliveryInvoices(deliveriesState.deliveries.id, data)
      .then(res => {
        setDeliveriesState({
          ...deliveriesState,
          isLoading: false
        });
        setDeliveriesItemsState({
          ...deliveriesItemsState,
          isLoading: false,
        });

      }).catch((error) => {
        console.log(error);
        setDeliveriesState({
          ...deliveriesState,
          isLoading: false
        });
        setDeliveriesItemsState({
          ...deliveriesItemsState,
          isLoading: false,
        });
      });
  };

  const classes = useStyles();

  const tableFilterComponent = () => {
    return <TableFilterItems
      config={{
        search: {
          enabled: true,
          callback: (e) => handleSearchItems(e)
        }
      }}

    />;
  };
  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      deliveries={deliveriesItemsState.page.deliveries_type}
      deliveriesBy={deliveriesItemsState.page.deliveries_by}
      rowCount={deliveriesItemsState.page.length} />;
  };


  const tableUpload = () => {
    return <FileUploader
      title={t('UPLOAD_INVOICE_DELIVERY_NOTE')}
      accept={'application/pdf'}
      multiple={true}
      handleImport={handleUpload} />
  };




  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        delete: {
          enabled: deliveriesState.deliveries && deliveriesState.deliveries.actions && deliveriesState.deliveries.actions.includes("UPDATE"),
          callback: (e) => handleDeleteDeliveries(e)
        },
        submit: {
          enabled: deliveriesState.deliveries && deliveriesState.deliveries.actions && deliveriesState.deliveries.actions.includes("UPDATE"),
          disabled: disabled,
          callback: (e) => handleSubmitDeliveries(e)
        },
        cancel: {
          enabled: false,
        },
        quote: {
          enabled: false,
        },
        confirm: {
          enabled: false
        },
        revise: {
          enabled: false
        },
        sync: {
          enabled: false
        }
      }}

    />;
  };

  const infoTab = () => {
    return (
      deliveriesState.deliveries.length !== 0 &&
      <DeliveriesInfo
        vessel={deliveriesState.deliveries.vessel ? deliveriesState.deliveries.vessel.name : "-"}
        supplier={deliveriesState.deliveries.supplier ? deliveriesState.deliveries.supplier.name : "-"}
        userRole={props.userState.user.userRole}
        buyer={deliveriesState.deliveries.buyer ? deliveriesState.deliveries.buyer.name : "-"}
        catalogue={deliveriesState.deliveries.category ? deliveriesState.deliveries.category.name : "-"}
        port={deliveriesState.deliveries.port ? deliveriesState.deliveries.port.name : "-"}
        saved={deliveriesState.isDeliveriesSaved}
        referenceNumber={deliveriesState.deliveries.reference_number || "-"}
        disabled={false}
        savedNotes={deliveriesState.isNotesSaved}
        deliveredItems={deliveriesState.deliveries.deliveredItems}
        supplied={deliveriesState.deliveries.supplied}
        getNotes={deliveriesNotes}
        notes={deliveriesState.deliveries.notes}
        check={deliveriesState.deliveries.isCompleted}
        edit={deliveriesState.deliveries.actions && deliveriesState.deliveries.actions.includes("UPDATE")}
      />
    )
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {deliveriesState.deliveries && deliveriesState.deliveries.actions && deliveriesItemsState.deliveriesItems.map((deliveriesItem, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              {
                (deliveriesItemsState.deliveriesItemEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{deliveriesItem.product.code}</TableCell>
                    <TableCell>{deliveriesItem.product.name}</TableCell>
                    <TableCell>{deliveriesItem.quantity}</TableCell>
                    <TableCell>{deliveriesItem.unit && deliveriesItem.unit.name}</TableCell>
                    <TableCell>{deliveriesItem.notes}</TableCell>
                  </>
                  : <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {deliveriesItemsState.deliveriesItemEditObject.product.code}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {deliveriesItemsState.deliveriesItemEditObject.product.name}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          defaultValue={deliveriesItemsState.deliveriesItemEditObject.quantity}
                          onChange={e => onEditableDeliveriesItemChange(e, 'quantity')}
                          onBlur={simpleValidator.current.showMessageFor('quantity')}
                        />
                        {simpleValidator.current.message('quantity', deliveriesItemsState.deliveriesItemEditObject.quantity, 'required|numeric|min:0,num')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {deliveriesItemsState.deliveriesItemEditObject.unit.name}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          defaultValue={deliveriesItemsState.deliveriesItemEditObject.notes}
                          onChange={e => onEditableDeliveriesItemChange(e, 'notes')}
                          fullWidth
                          multiline
                          maxRows={10}
                        />
                      </FormControl>
                    </TableCell>
                  </>
              }
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: deliveriesState.deliveries.actions.includes("UPDATE") && deliveriesItemsState.deliveriesItemEditIndex !== index,
                    callback: (e) => handleEditDeliveriesItem(e, index)
                  },
                  delete: {
                    //todo delete action is missing
                    enabled: deliveriesState.deliveries.actions.includes("UPDATE") && deliveriesItemsState.deliveriesItemEditIndex !== index,
                    callback: (e) => handleDeleteDeliveriesItem(e, index)
                  },
                  close: {
                    enabled: deliveriesItemsState.deliveriesItemEditIndex === index,
                    callback: (e) => handleCloseEditDeliveriesItem(e, index)
                  },
                  save: {
                    enabled: deliveriesItemsState.deliveriesItemEditIndex === index,
                    callback: (e) => handleSaveDeliveriesItem(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        }
        )}
        {
          deliveriesItemsState.deliveriesItemCreateObject &&
          <TableRow className={classes.tableRow}>

            <TableCell onClick={e => e.stopPropagation()}>
              {deliveriesItemsState.deliveriesItemCreateObject.product.code}
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProducts
                  product={deliveriesItemsState.deliveriesItemCreateObject.product}
                  catalogue={deliveriesState.deliveries.category.id || ""}
                  supplier={deliveriesState.deliveries.supplier?.id}
                  port={deliveriesState.deliveries.port?.id}
                  getSelectedProduct={(data) => selectedProduct(data, 'create')}
                  onBlur={simpleValidator.current.showMessageFor('product')} />
                {simpleValidator.current.message('product', deliveriesItemsState.deliveriesItemCreateObject.product.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  defaultValue={deliveriesItemsState.deliveriesItemCreateObject.quantity}
                  onChange={e => onNewDeliveriesItemChange(e, 'quantity')}
                  onBlur={simpleValidator.current.showMessageFor('quantity')} />
                {simpleValidator.current.message('quantity', deliveriesItemsState.deliveriesItemCreateObject.quantity, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnits
                  unit={deliveriesItemsState.deliveriesItemCreateObject.unit}
                  supplier={deliveriesState.deliveries.supplier?.id}
                  port={deliveriesState.deliveries.port?.id}
                  product={deliveriesItemsState.deliveriesItemCreateObject.product?.id}
                  getSelectedUnit={(data) => selectedUnit(data, 'create')}
                  onBlur={simpleValidator.current.showMessageFor('unit')} />
                {simpleValidator.current.message('unit', deliveriesItemsState.deliveriesItemCreateObject.unit.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  defaultValue={deliveriesItemsState.deliveriesItemCreateObject.notes}
                  onChange={e => onNewDeliveriesItemChange(e, 'notes')}
                  fullWidth
                  multiline
                  maxRows={10}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setDeliveriesItemsState({ ...deliveriesItemsState, deliveriesItemCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewDeliveriesItem()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  };


  return (
    <>
      <ProcureDataTable
        toolbarTitle={''}
        infoTabTitle={t('DELIVERIES')}
        pagination={{
          total: deliveriesItemsState.page.total || 0,
          page: deliveriesItemsState.page.page - 1 || 0,
          perPage: deliveriesItemsState.page.per_page || 10,
          count: deliveriesItemsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={handleAddNewDeliveriesItem}
        newRowActionAvailable={deliveriesState.deliveries.actions && deliveriesState.deliveries.actions.includes("UPDATE")}
        newRowBtnEnabled={!deliveriesItemsState.deliveriesItemCreateObject}
        confirmDialog={deliveriesItemsState.confirmDialog.message.length > 0 ? deliveriesItemsState.confirmDialog : deliveriesState.confirmDialog}
        infoTab={infoTab}
        importAction={deliveriesState.deliveries && deliveriesState.deliveries.actions && deliveriesState.deliveries.actions.includes("UPDATE")}
        breadcrump={breadcrump}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        tableUpload={tableUpload}
        uploadAction={props.userState.user.userRole === 'BUYER'}
        isLoading={deliveriesItemsState.isLoading}
        itemsTableTitle={t('ITEMS')}
      />
    </>
  );
};

export default DeliveriesItems;
