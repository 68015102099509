import React from 'react';
import Button from '@mui/material/Button';
import TableContainer from "@mui/material/TableContainer";
import {
    TextField
} from "@mui/material";
import FilterUnitCategories from '../../components/filterUnitCategories';
import useUnitMatchHook from './useUnitMatchHook';
import './UnitMatch.scss'
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import UnitMatchTable from "./UnitMatchTable";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { EditRow } from './EditRow';
import Typography from '@mui/material/Typography';
import t from '../../helpers/languages';

const UnitMatch = (props) => {
    const {
        unresolvedUnits,
        isConfirmDialogOpen,
        resolvedUnits,
        filters,
        selectedUnits,
        selectedUnit,
        onFilterUnitCategoryChange,
        onUnitClick,
        onMatchBtnClick,
        setConfirmDialogOpen,
        onSearchTextChange,
        onUnitMatchConfirm,
        validator,
        confirmDialogDeleteOpen,
        onEditableUnitChange,
        handleSaveUnit,
        handleChangePage,
        handleChangeRowsPerPage,
        handleDeleteUnit,
        handleCloseEditUnit,
        ref,
        selectedUnitCategory
    } = useUnitMatchHook();

    return (
        <div className={'unit-match'}>
            <div className={'unit-match__selection-wrapper'}>
                <Paper
                    className={'paper'}
                    elevation={4}
                >
                    <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'unit-match__search-actions'}>
                                    <Typography variant="h4" >
                                        <div className={'unit-match__title'}>
                                            {t('UNIT_RESOLUTION')}
                                        </div>
                                    </Typography>
                                    <FilterUnitCategories
                                        unitCategory={filters.unresolved.categorya}
                                        getSelectedUnitCategory={e => onFilterUnitCategoryChange(e, 'unresolved')}
                                    />
                                </div>
                                <UnitMatchTable
                                    units={unresolvedUnits}
                                    unitsCategory={'unresolvedUnit'}
                                    selectedUnits={selectedUnits}
                                    handleChangePage={handleChangePage}
                                    handleDeleteUnit={handleDeleteUnit}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onUnitClick}
                                />
                            </TableContainer>
                        </Grid>
                        <Grid container item xs={2} direction="column" alignItems="center" justifyContent="center">
                            <div className={'unit-match__btn-action-wrapper'}>
                                <Button variant="contained"
                                    color="primary"
                                    onClick={onMatchBtnClick}
                                    size="large"
                                    disabled={!selectedUnits.resolvedUnit || !selectedUnits.unresolvedUnit}
                                >
                                    {t('MATCH')}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'unit-match__search-actions'}>
                                    <FilterUnitCategories
                                        unitCategory={filters.resolved.categorya}
                                        getSelectedUnitCategory={e => onFilterUnitCategoryChange(e, 'resolved')}
                                    />
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        fullWidth
                                        inputRef={ref}
                                        onChange={e => onSearchTextChange(e, 'resolved')}
                                        value={filters.resolved.search || ''}
                                    />
                                </div>
                                <UnitMatchTable
                                    units={resolvedUnits}
                                    unitsCategory={'resolvedUnit'}
                                    selectedUnits={selectedUnits}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onUnitClick}
                                />
                            </TableContainer>

                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={
                                    `Are you sure you want to match those Units: 
                        [${selectedUnits?.unresolvedUnit?.name}]  
                         [${selectedUnits?.resolvedUnit?.name}]?`
                                }
                                dialogTitle={'Unit Match'}
                                handleCancel={() => setConfirmDialogOpen(false)}
                                handleOk={onUnitMatchConfirm}
                                open={isConfirmDialogOpen}
                            />
                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={confirmDialogDeleteOpen.message}
                                dialogTitle={confirmDialogDeleteOpen.title}
                                handleCancel={confirmDialogDeleteOpen.onCancel}
                                handleOk={confirmDialogDeleteOpen.onConfirm}
                                open={confirmDialogDeleteOpen.isOpen}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {unresolvedUnits.unitEditIndex !== -1 && unresolvedUnits.unitEditObject?.id && <EditRow
                                unresolvedUnits={unresolvedUnits}
                                validator={validator}
                                onEditableUnitChange={onEditableUnitChange}
                                handleSaveUnit={handleSaveUnit}
                                handleCloseEditUnit={handleCloseEditUnit}
                                selectedUnit={selectedUnit}
                                selectedUnitCategory={selectedUnitCategory}
                            />}
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        </div>
    );
}

export default UnitMatch;
