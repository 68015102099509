import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCountries } from '../../services/countriesService';

export default function FilterPortCountries({ country: initialCountry, getSelectedPortCountry }) {

  const [country, setCountry] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setSearch('');
    setCountry(initialCountry && initialCountry.id !== null ? initialCountry : 0);
    setCountries(initialCountry && initialCountry.id !== null ? [initialCountry] : []);
  }, [initialCountry]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "country" && value && value.length >= 2 && search !== value) {
      setSearch(value);
      debounceFn(value)
    };
  };


  const handleDebounceFn = (val) => {
    countrySearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);


  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onPortCountrySelectionChange = (event, values) => {
    setCountry(values ? values : '');
    const selectedPortCountry = {
      country: values ? values : '',
    };
    getSelectedPortCountry(selectedPortCountry);
  };
  const countrySearch = (criteria) => {

    return getAllCountries(criteria).then((res) => {
      setCountries(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id={"find-country" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'country')}
        onChange={onPortCountrySelectionChange}
        value={countries.find(v => v.id === country.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={countries !== null ? countries : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Country')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}