
const tableHeaders = [
    { id: 'product_code', disablePadding: false, label: 'Product Code', width: '5%', align: 'center'},
    { id: 'product_name', disablePadding: false, label: 'Product Name', width: '30%' },
    { id: 'quantity', disablePadding: false, label: 'Quantity', width: '5%' },
    { id: 'unit_name', disablePadding: false, label: 'Unit Name', width: '5%' },
    { id: 'notes', disablePadding: false, label: 'Notes', width: '45%' },
    { id: 'row-actions', disablePadding: false, width: '10%' },
  ];
  
  // Empty deliveryitem. not used. created for possible future and page compatibility.
const newDeliveriesItem = () => {
  return {
    category: { id: '', name: '' },
    notes: '',
    id: '',
    product: { code: '', id: '', name: '' },
    quantity: '',
    remote_id: '',
    deliveryitem_id: '',
    unit: { id: '', name: '' },
    add: true
  }
};

  export { tableHeaders, newDeliveriesItem };
  