import React, { useCallback } from 'react';
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCompanyCategories } from '../../services/companyCategoriesService';
import useStyles from './style';

export default function FilterCompanyCategories({
    companyCategory: initialCompanyCategory,
    getSelectedCompanyCategory,
    saved,
    errorMessage
}) {

    const empty = {
        id: null,
        name: null
    };

    const [companyCategory, setCompanyCategory] = React.useState(initialCompanyCategory && initialCompanyCategory.id !== null ? initialCompanyCategory : empty);
    const [openCompanyCategory, setOpenCompanyCategory] = React.useState(false);
    const [companyCategories, setCompanyCategories] = React.useState(initialCompanyCategory && initialCompanyCategory.id !== null ? [initialCompanyCategory] : [empty]);
    const [search, setSearch] = React.useState('');

    const classes = useStyles();

    const handleSpanClick = useCallback(() => setOpenCompanyCategory(true), [
        setOpenCompanyCategory
    ]);


    const handleInputChange = (e, fetchType) => {

        const value = e && e.target && e.target.value;
        if (value && value.length >= 3 && search !== value) {
            setSearch(value);
            debounceFn(value);
        };
    };

    const handleDebounceFn = (val) => {
        companyCategorySearch({
            search: val
        })
    };
    const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

    const onCompanyCategorySelectionChange = (event, values) => {
        setCompanyCategory(values ? values : []);
        const selectedCompanyCategory = {
            companyCategory: values ? values : [],
        };
        getSelectedCompanyCategory(selectedCompanyCategory);

    };

    const companyCategorySearch = (criteria) => {

        return getAllCompanyCategories(criteria).then((res) => {
            setCompanyCategories(res.data);
        }).catch((error) => {
        })
    }

    return (
        <span className="inline-text">
            {openCompanyCategory || !saved ? (
                <span>
                    <Autocomplete
                        // set the width to the input length multiplied by the x height
                        // it's not quite right but gets it close
                        id="find-companyCategory"
                        fullWidth
                        open={openCompanyCategory}
                        onOpen={() => {
                            setOpenCompanyCategory(true);
                        }}
                        onClose={() => {
                            setOpenCompanyCategory(false);
                        }}
                        onInputChange={e => handleInputChange(e)}
                        onChange={onCompanyCategorySelectionChange}
                        value={companyCategories.find(v => v.id === companyCategory.id) || null}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
                        options={companyCategories !== null ? companyCategories : null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('CATEGORY')}
                                autoFocus
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps
                                }}
                                error={!!errorMessage}
                                helperText={errorMessage}
                            />
                        )}
                    />
                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openCompanyCategory ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <TextField
                        fullWidth
                        id="outlined-disabled"
                        label={t('CATEGORY')}
                        defaultValue={companyCategory && companyCategory.name}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </span>)}
        </span >
    );
}