import React, {useMemo} from 'react'
import './StatusIndicator.scss'
import _ from 'lodash';

const StatusIndicator = props => {


    const statusConfigurationMappings = {
        "info": ['quoted', 'quote', 'confirmed'],
        "warning": ['pending', 'order', 'order placed'],
        "init" : ['initialized','default','closed'],
        "success": ['revised', 'rfq' ,'in progress'],
        "declined": ['declined'],
        "error": ['overdue', 'cancelled', 'out of sync','fwd to supplier failed']
    }


    const getIndicationFlag = useMemo(() => {
        let status = null;
        Object.keys(statusConfigurationMappings).forEach(key => {
            if(statusConfigurationMappings[key].includes(_.lowerCase(props.status))) {
                status = key
            }
        })

        return status
        // eslint-disable-next-line  
    }, [props.status])

    return (
        <div className={`status-indicator status-indicator--${getIndicationFlag}`}>
            {props.children}
        </div>
    )
}

export {StatusIndicator}