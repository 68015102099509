import { makeStyles } from '@mui/styles';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => {
  return {
    MuiDrawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflowY:'scroll',
      boxSizing: 'border-box',
    },
    drawerPaper: {
      width: drawerWidth,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    drawerOpen: {'& .MuiDrawer-paper': {
       width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    }
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    },
    horizontalLine: {
      marginTop: 10,
      marginBottom: 10,
    },
    accordionWrapper: {
      boxShadow: 'none',
      '&::before': {
        height: 0,
      },
    },
    accordionHeader: {
      paddingLeft: 30,
    },
    accordionHeaderShrinked: {
      paddingRight: 24,
      paddingLeft: 16,
    },
    accordionContent: {
      flexDirection: 'column',
      paddingRight: 0,
      paddingLeft: 0,
    },
    logoutButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: 29,
    },

    sidebarLink: {
      paddingLeft: 7,
      textDecoration: 'none',
      color: 'inherit',
      textAlign: 'center',
      display: 'block',
      width: '100%',
    },
    activeListItem: {
      borderLeft: '7px solid #70bbfd',
      paddingLeft: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    listItemIcon: {
      width: 17,
      height: 17,
      minWidth: 30,
    },


  };
});

export default useStyles;
