import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PercentagePresenter from "../../components/statistics/percentagePresenter";
import Paper from "@mui/material/Paper";
import ChartWithNoAxesAndNumber from "../../components/statistics/chartWithNoAxesAndNumber";
import { getRFQStats, getQuoteStats, getOrderStats } from '../../services/reportsService';
import dateToString from "../../helpers/dateToString";
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Button from '@mui/material/Button';
import './_miniDashboard.scss';


const MiniDashboard = () => {

  const [rfqsStatsState, setRfqsStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfRfqs: null,
    stats: [],
    toDate: null,
  });

  const [quotesStatsState, setQuotesStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfRfqs: null,
    numberOfQuotes: null,
    toDate: null,
  });

  const [ordersStatsState, setOrdersStatsState] = useState({
    isLoading: false,
    companyId: null,
    fromDate: null,
    numberOfQuotes: null,
    numberOfOrders: null,
    toDate: null,
  });


  useEffect(() => {
    setRfqsStatsState({
      ...rfqsStatsState,
      isLoading: true,
    });

    setQuotesStatsState({
      ...quotesStatsState,
      isLoading: true,
    });

    setOrdersStatsState({
      ...ordersStatsState,
      isLoading: true,
    });

    const toDate = new Date();
    const minusDate = new Date();
    const fromDate = new Date(minusDate.setMonth(minusDate.getMonth()-8));
  
      
    const dateArray = {
      from_date: dateToString(moment.utc(fromDate))+'T00:00:00Z',
      to_date: dateToString(moment.utc(toDate))+'T00:00:00Z'
    };
    getRFQStats(dateArray).then(res => {
      const results = res.data;
      setRfqsStatsState({
        ...rfqsStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfRfqs: results.numberOfRfqs,
        stats: results.stats,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setRfqsStatsState({
        ...rfqsStatsState,
        isLoading: false,
      });
    });
    getQuoteStats().then(res => {
      const results = res.data;
      setQuotesStatsState({
        ...quotesStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfRfqs: results.numberOfRfqs,
        numberOfQuotes: results.numberOfQuotes,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setQuotesStatsState({
        ...quotesStatsState,
        isLoading: false,
      });
    });
    getOrderStats().then(res => {
      const results = res.data;
      setOrdersStatsState({
        ...ordersStatsState,
        isLoading: false,
        companyId: results.companyId,
        fromDate: results.fromDate,
        numberOfOrders: results.numberOfOrders,
        numberOfQuotes: results.numberOfQuotes,
        toDate: results.toDate,
      });
    }).catch((error) => {
      console.log(error);
      setOrdersStatsState({
        ...ordersStatsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);


  const calculatePercentage = (a, b) => {

    return b > 0 ? Math.round((a / b) * 100) : 0;

  };



  return (
    <main className='dashboard'>
      <div className='dashboard__total-amount-container'>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          rowSpacing={0} 
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {/* {!rfqsStatsState.isLoading && rfqsStatsState.stats && rfqsStatsState.stats.length !== 0 && ( */}
            <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6} md={4}>
              <Paper
                elevation={4}
              >
                <ChartWithNoAxesAndNumber theme={'red'} stats={rfqsStatsState.stats} amount={rfqsStatsState.numberOfRfqs} label={'Total Rfq\'s'} />
              </Paper>
            </Grid>
          {/* )} */}
          <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
            >
              <PercentagePresenter theme={'green'} percentage={calculatePercentage(quotesStatsState.numberOfQuotes, quotesStatsState.numberOfRfqs)} amount={quotesStatsState.numberOfQuotes} label={'Total Quotes'} />
            </Paper>
          </Grid>
          <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
            >
              <PercentagePresenter theme={'blue'} percentage={calculatePercentage(ordersStatsState.numberOfOrders, ordersStatsState.numberOfQuotes)} amount={ordersStatsState.numberOfOrders} label={'Total Orders'} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<ManageSearchIcon />}
              component={RouterLink}
              to={{ pathname: '/dashboards/' }}
            >
              More
            </Button>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default MiniDashboard;
