const homePageSteps = [
    {
        target: '.button-side-menu',
        content: 'Click here to expand the menu.',
        //disableBeacon: true,
        placement: 'top'
    },
    {
        target: '.table-more-info',
        content: 'Click on the left column of each list to navigate to a page with more details and actions about this item (transactions).',
        // placement: 'top'

    },
];

const transactionSupplierPageSteps = [
    {
        target: '.breadcrumb-info',
        //disableBeacon: true,
        content: 'Click on each part of the breadcrumb to navigate to specific pages. For example click on “transactions” to go back to the transactions list. Click on transaction/rfq/quote number to navigate to specific transaction/rfq/quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.rfqs-quotes-orders-details',
        content: 'Click on the left column of each list to navigate to a page with more details and actions about this item (rfqs/quotes/orders).',
        placement: 'right-end',
        // placementBeacon:'left',

    },
];

const transactionBuyerPageSteps = [
    {
        target: '.breadcrumb-info',
        //disableBeacon: true,
        content: 'Click on each part of the breadcrumb to navigate to specific pages. For example click on “transactions” to go back to the transactions list. Click on transaction/rfq/quote number to navigate to specific transaction/rfq/quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.agent-details',
        content: 'Enter agent details (name/phone/email )by clicking in these text areas.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.rfqs-quotes-orders-details',
        content: 'Click on the left column of each list to navigate to a page with more details and actions about this item (rfqs/quotes/orders).',
        placement: 'right-end',
        // placementBeacon:'left',

    },
];

const rfqBuyerPageSteps = [
    {
        target: '.breadcrumb-info',
        //disableBeacon: true,
        content: 'Click on each part of the breadcrumb to navigate to specific pages. For example click on “transactions” to go back to the transactions list. Click on transaction number to navigate to specific transaction.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.required-fields',
        content: 'Fields with blue background are required before submitting an RFQ.',
        placement: 'right-end',
        // placementBeacon:'left',

    },

];

const rfqSupplierPageSteps = [
    {
        target: '.breadcrumb-info',
        //disableBeacon: true,
        content: 'Click on each part of the breadcrumb to navigate to specific pages. For example click on “transactions” to go back to the transactions list. Click on transaction number to navigate to specific transaction.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.rfq-decline',
        content: 'Click on decline button and add a decline comment to decline the RFQ.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.quote-create',
        content: 'Click on Quote button to create a quote and start quoting.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
];

const quoteSupplierPageSteps = [
    {
        target: '.breadcrumb-info',
        //disableBeacon: true,
        content: 'Click on each part of the breadcrumb to navigate to specific pages. For example click on “transactions” to go back to the transactions list. Click on transaction number to navigate to specific transaction.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.required-fields',
        content: 'Fields with blue background are required before submitting an Quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.rfq-submit',
        content: 'Click on Submit button to submit your quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.excel-export',
        content: 'Click here to export the quote to EXCEL to easily fill in quote prices, quantities, discounts etc..',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.excel-import',
        content: 'Click here to import the EXCEL with quote details (prices, quantities, discounts etc..).',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.pdf-print',
        content: 'Click here to view the pdf version of the quote and print it if required.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.attachment-import',
        content: 'Click here to add file attachments in your quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    {
        target: '.edit-table-row',
        content: 'Click here on each item to edit quote details prices/quantities. After editing the item click save icon to save your changes.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
    // {
    //     target: '.add-table-row',
    //     content: 'Click here to add a new item in your quote.',
    //     placement: 'right-end',
    //     // placementBeacon:'left',

    // },
    // {
    //     target: '.rfq-delete',
    //     content: 'Click here to delete the quote and return back to rfq.',
    //     placement: 'right-end',
    //     // placementBeacon:'left',

    // },
    {
        target: '.table-filter',
        content: 'Type a search text here to find a specific product in the quote.',
        placement: 'right-end',
        // placementBeacon:'left',

    },
];


const pricelistBuyerPageSteps = [
    {
        target: '.table-filter-pricelist',
        //disableBeacon: true,
        content: 'Type a search text in port and contact area.  Please be aware each field requires at least 3 characters in order to return results. After choosing ports and contracts you can create the product list.',
        placement: 'top',
        // placementBeacon:'left',

    },];


export {
    homePageSteps,
    transactionSupplierPageSteps,
    transactionBuyerPageSteps,
    rfqBuyerPageSteps,
    rfqSupplierPageSteps,
    quoteSupplierPageSteps,
    pricelistBuyerPageSteps
};