import httpService from './httpService';

const getAllCurrencies = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCurrencyList(criteria);
};



export { getAllCurrencies };
