import React, { useState, useEffect,useRef } from 'react';
import {
    Box, TextField, IconButton,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import { Message } from '../muiMessage/message';

function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                // border: '1px solid',
                // borderColor: (theme) =>
                //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        messagesBody: {
            width: "calc( 100% - 20px )",
            margin: 10,
        },
        wrapForm: {
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: `${theme.spacing(0)} auto`
        },
        wrapText: {
            width: "100%"
        }
    })
);


const ChatBox = (props) => {
    const {
        messages,
        onMessageSend,
        recipient,
    } = props;

    const [messageInputValue, setMessageInputValue] = useState("");

    const messagesEndRef = useRef(null)
    const classes = useStyles();

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  
    useEffect(() => {
      scrollToBottom()
    }, [messages]);



    const sendMessage = () => {
        if(!messageInputValue.trim()) return;

        let messageTextBeforeSubmit = messageInputValue;
        setMessageInputValue("");
        onMessageSend(messageInputValue.trim())
            .then(() => {})
            .catch(() => {
                setMessageInputValue(messageTextBeforeSubmit);
            })
            .finally(() => setMessageInputValue(''))
    }

    return (
        <>
            <Box
                component="div"
                key='header-title-chat'
                sx={{
                    display: "inline-block",
                    p: 2,
                    textAlign: "center",
                    paddingTop: 10,
                    backgroundColor: '#03a9f4',
                    zIndex: 1,
                }}
            >
                <Box
                    key='header-title-chat-name'
                    component="h2" sx={{
                        color: "#fff",
                    }}>Chat with {recipient.name}</Box>
            </Box>
            <Box
                key='header-content'
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    maxWidth: 480,
                    minHeight: '80%',
                    paddingTop: 10,
                    borderRadius: 1,
                }}>
                <Item
                    key='item-messages'
                    id="scroll-style"
                    sx={{
                        height: '100%',
                        overflowY: 'scroll'
                    }}
                    className={classes.messagesBody}>
                    {!!messages && messages.map(mes =>
                              <Message
                                      key={mes.id}
                                      message={mes.message}
                                      timestamp={mes.date_modified}
                                      position={mes.isFrom ? 'right' : 'left'}
                              />
                    )}
                    <div ref={messagesEndRef} />
                </Item>
                <Item
                    key='item-form'
                    className={classes.wrapForm}>
                    <TextField
                        id="standard-text"
                        label="Message"
                        key='message-item-form'
                        fullWidth
                        autoFocus={true}
                        multiline
                        maxRows={21}
                        value={messageInputValue}
                        onChange={(event)=>{setMessageInputValue(event.target.value)}}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                sendMessage(e);
                            }
                        }}
                    />
                    <IconButton
                        sx={{ p: 2 }}
                        onClick={(e) => {
                            sendMessage(e)
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </Item>
            </Box>
        </>
    );
};

export default ChatBox;