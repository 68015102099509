import React from 'react';
import { Helmet } from 'react-helmet';

import t from '../helpers/languages';
import Routes from '../router';

const App = (props) => {
  return (
    <>
      <Helmet>
        <title>
          {t('PAGE_TITLE')}
        </title>
      </Helmet>
      <Routes
        userState={props.userState}
      />
    </>
  );
};

export default App;

