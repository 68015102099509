import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import FilterVessels from "../../components/filterVessels";
import FilterPorts from "../../components/filterPorts";
import FilterCompanies from "../../components/filterCompanies";
import { tableHeadersBuyer, newBuyerPreference } from '../../helpers/buyerPreferencesConfig';
import { urlParams } from "../../helpers/urlParams";
import { BuyerPreferencesTableFilter } from '../../components/buyerPreferencesTableFilter';
import useStyles from './styles';
import FilterCatalogues from '../../components/filterCatalogues';
import TableActions from '../../components/TableActions/TableActions';
import { getBuyerPreferences, edit, deleteBuyerPreference, add } from '../../services/buyerPreferencesService';
import { getAuthorizationMappings } from '../../services/authorizationsService';
import { getSupplyStatus } from '../../services/supplyStatusService';
import SimpleReactValidator from 'simple-react-validator';
import t from '../../helpers/languages';
import './_buyerPreferences.scss';

const BuyerPreferences = (props) => {

  const buyerObj = { id:props.companyId };
  
  const buyerPreferenceInitObject = () => {
    return {
      vessel: { name: '', id: '' },
      port: { name: '', id: '' },
      supplier: { name: '', id: '' },
      category: { name: '', id: '' },
      id: '?',
    }
  };

  const filters = localStoreGetObj('buyerPreferenceFilter');

  const [buyerPreferencesState, setBuyerPreferencesState] = useState({
    buyerPreferences: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {
    },
    isLoading: true,
    buyerPreferenceEditIndex: -1,
    buyerPreferenceEditObject: buyerPreferenceInitObject(),
    buyerPreferenceCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const [buyerPreferenceStatusState, setBuyerPreferenceStatusState] = useState({
    buyerPreferenceStatuses: []
  });


  const userState = props.userState && props.userState.user;

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10);
  !criteria.page && (criteria.page = 1);

  // let buyer = null;
  // if (!props.autonomousPage) {
  //   const pathName = window.location.pathname;
  //   const regex = /(\d+)/gm;
  //   let m;
  //   m = regex.exec(pathName);
  //   buyer = m[0];
  // }

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};
  // if (userState.userRole === 'MBA' && buyer) {
  //   filtersData = {
  //     ...buyerPreferencesState.filters,
  //     vessel: filters && filters.vessel && filters.vessel.id,
  //     port: filters && filters.port && filters.port.id,
  //     buyer: buyer,
  //     supplier: filters && filters.supplier && filters.supplier.id,
  //     category: filters && filters.category && filters.category.id,
  //     per_page: criteria.per_page,
  //     page: criteria.page,
  //   };
  // } else if (userState.userRole === 'MBA') {
  //   filtersData = {
  //     ...buyerPreferencesState.filters,
  //     vessel: filters && filters.vessel && filters.vessel.id,
  //     port: filters && filters.port && filters.port.id,
  //     buyer: filters && filters.buyer && filters.buyer.id,
  //     supplier: filters && filters.supplier && filters.supplier.id,
  //     category: filters && filters.category && filters.category.id,
  //     per_page: criteria.per_page,
  //     page: criteria.page,
  //   };
  // } else {
    filtersData = {
      ...buyerPreferencesState.filters,
      vessel: filters && filters.vessel && filters.vessel.id,
      buyer: props.companyId,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.supplier && filters.supplier.id,
      category: filters && filters.category && filters.category.id,
      per_page: criteria.per_page,
      page: criteria.page
    };
  //}

  useEffect(function () {

    const statusName = { type: 'buyerPreference' };
    getSupplyStatus(statusName).then(res => {
      setBuyerPreferenceStatusState({
        ...buyerPreferenceStatusState.buyerPreferenceStatuses,
        buyerPreferenceStatuses: res.data,
      });

      getBuyerPreferences(filtersData).then(res => {
        const { results } = res.data;
        setBuyerPreferencesState({
          ...buyerPreferencesState,
          buyerPreferences: results,
          isLoading: false,
          page: res.data.page,
          filters: res.data.filters,
          authorizedActions: {
            ...getAuthorizationMappings('/buyerPreferences', userState.userRole)

          }
        });
        props.autonomousPage && urlParams.setUrlParams({
          page: res.data.page.page,
          per_page: res.data.page.per_page
        });
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setBuyerPreferencesState({
          ...buyerPreferencesState,
          isLoading: false,
        });
      });
    }).catch((error) => { })

    // eslint-disable-next-line  
  }, []);



  const handleChangePage = (event, newPage) => {
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      isLoading: true,
    });

    const newCriteria = {
      ...buyerPreferencesState.filters,
      per_page: buyerPreferencesState.page.per_page,
      page: newPage + 1
    };
    getBuyerPreferences(newCriteria)
      .then(res => {
        setBuyerPreferencesState({
          ...buyerPreferencesState,
          isLoading: false,
          buyerPreferenceEditIndex: -1,
          buyerPreferenceCreateObject: null,
          buyerPreferences: res.data.results,
          page: res.data.page,
          filters: res.data.filters
        });
        props.autonomousPage && urlParams.setUrlParams(res.data.page);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setBuyerPreferencesState({
          ...buyerPreferencesState,
          isLoading: false,
        });
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...buyerPreferencesState.filters,
      page: 1,
      per_page: perPage,
    };

    getBuyerPreferences(newCriteria).then(res => {
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        buyerPreferences: res.data.results,
        buyerPreferenceEditIndex: -1,
        isLoading: false,
        buyerPreferenceCreateObject: null,
        page: res.data.page,
        filters: res.data.filters
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        isLoading: false,
      });
    });
  };

  const handleEditBuyerPreference = (event, buyerPreferenceIndex) => {

    event.stopPropagation();
    const buyerPreferenceEditObject = Object.assign({}, buyerPreferencesState.buyerPreferences[buyerPreferenceIndex]);

    if (!buyerPreferenceEditObject.due) {
      buyerPreferenceEditObject.due = new Date();
    }
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      buyerPreferenceEditIndex: buyerPreferenceIndex,
      buyerPreferenceEditObject: buyerPreferenceEditObject
    });
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const handleSaveBuyerPreference = (event, props) => {
    event.preventDefault();

    const newEditBuyerPreferenceItem = Object.assign({}, buyerPreferencesState.buyerPreferenceEditObject);

    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        isLoading: true
      });
      edit(newEditBuyerPreferenceItem.id, newEditBuyerPreferenceItem)
        .then(re => {
          const editedBuyerPreference = re.data;

          setBuyerPreferencesState(oldBuyerPreferences => {
            return {
              ...oldBuyerPreferences,
              buyerPreferences: oldBuyerPreferences.buyerPreferences.map(buyerPreference => {
                return buyerPreference.id === editedBuyerPreference.id
                  ? re.data : buyerPreference
              }),
              buyerPreferenceEditIndex: -1
            }
          });
        }).catch((error) => {
          setBuyerPreferencesState({
            ...buyerPreferencesState,
            isLoading: false,
          });
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }

  };

  const handleCloseEditBuyerPreference = (e, index) => {
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      buyerPreferenceEditIndex: -1
    })
  };

  const handleDeleteBuyerPreference = (event, index) => {

    const buyerPreferenceForDeletion = buyerPreferencesState.buyerPreferences[index];
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Buyer Preference',
        onCancel: () => {
          setBuyerPreferencesState({
            ...buyerPreferencesState,
            confirmDialog: {
              ...buyerPreferencesState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteBuyerPreference(buyerPreferenceForDeletion.id).then(() => {
            const newBuyerPreferences = [...buyerPreferencesState.buyerPreferences];
            newBuyerPreferences.splice(index, 1);
            setBuyerPreferencesState({
              ...buyerPreferencesState,
              buyerPreferences: newBuyerPreferences,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const filterData = (data) => {
    setBuyerPreferencesState({
      ...buyerPreferencesState,
      isLoading: true
    });

    let filtersData = '';
    // if (userState.userRole === 'MBA') {
    //   filtersData = {
    //     ...buyerPreferencesState.filters,
    //     port: data && data.port && data.port.id,
    //     vessel: data && data.vessel && data.vessel.id,
    //     category: data && data.category && data.category.id,
    //     buyer: data && data.buyer && data.buyer.id,
    //     supplier: data && data.supplier && data.supplier.id,
    //     per_page: criteria.per_page,
    //     page: 1
    //   };
    // } else {
      filtersData = {
        ...buyerPreferencesState.filters,
        port: data && data.port && data.port.id,
        companyId: props.companyId,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        supplier: data && data.supplier && data.supplier.id,
        per_page: criteria.per_page,
        page: 1
      };
    // }

    getBuyerPreferences(filtersData).then(res => {
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        isLoading: false,
        buyerPreferences: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });

      localStoreSetObj('buyerPreferenceFilter', data);

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        isLoading: false,
      });
    });

  };

  const selectedVessel = (data, action) => {

    const newEditBuyerPreferenceItem = Object.assign({},
      action === 'edit'
        ? buyerPreferencesState.buyerPreferenceEditObject
        : buyerPreferencesState.buyerPreferenceCreateObject
    );

    newEditBuyerPreferenceItem.vessel = data.vessel;

    setBuyerPreferencesState({
      ...buyerPreferencesState,
      [
        action === 'edit'
          ? 'buyerPreferenceEditObject'
          : 'buyerPreferenceCreateObject'
      ]: newEditBuyerPreferenceItem
    });
  };

  const selectedPort = (data, action) => {
    const newEditBuyerPreferenceItem = Object.assign({}, action === 'edit'
      ? buyerPreferencesState.buyerPreferenceEditObject
      : buyerPreferencesState.buyerPreferenceCreateObject);

    newEditBuyerPreferenceItem.port = data.port;

    setBuyerPreferencesState({
      ...buyerPreferencesState,
      [action === 'edit' ? 'buyerPreferenceEditObject' : 'buyerPreferenceCreateObject']: newEditBuyerPreferenceItem
    });

  };

  // const selectedBuyer = (data, action) => {

  //   const newEditBuyerPreferenceItem = Object.assign({},
  //     action === 'edit'
  //       ? buyerPreferencesState.buyerPreferenceEditObject
  //       : buyerPreferencesState.buyerPreferenceCreateObject
  //   );

  //   newEditBuyerPreferenceItem.buyer = data.company;


  //   setBuyerPreferencesState({
  //     ...buyerPreferencesState,
  //     [
  //       action === 'edit'
  //         ? 'buyerPreferenceEditObject'
  //         : 'buyerPreferenceCreateObject'
  //     ]: newEditBuyerPreferenceItem
  //   });
  // };

  const selectedSupplier = (data, action) => {

    const newEditBuyerPreferenceItem = Object.assign({},
      action === 'edit'
        ? buyerPreferencesState.buyerPreferenceEditObject
        : buyerPreferencesState.buyerPreferenceCreateObject
    );

    newEditBuyerPreferenceItem.supplier = data.company;


    setBuyerPreferencesState({
      ...buyerPreferencesState,
      [
        action === 'edit'
          ? 'buyerPreferenceEditObject'
          : 'buyerPreferenceCreateObject'
      ]: newEditBuyerPreferenceItem
    });
  };
  const selectedCatalogue = (data, action) => {

    const newEditBuyerPreferenceItem = Object.assign({},
      action === 'edit'
        ? buyerPreferencesState.buyerPreferenceEditObject
        : buyerPreferencesState.buyerPreferenceCreateObject
    );

    newEditBuyerPreferenceItem.category = data.catalogue;

    setBuyerPreferencesState({
      ...buyerPreferencesState,
      [
        action === 'edit'
          ? 'buyerPreferenceEditObject'
          : 'buyerPreferenceCreateObject'
      ]: newEditBuyerPreferenceItem
    });
  };

  const handleAddNewBuyerPreference = (e) => {
    e.preventDefault();

    setBuyerPreferencesState({
      ...buyerPreferencesState,
      buyerPreferenceCreateObject: newBuyerPreference()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };


  const onSaveNewBuyerPreference = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newBuyerPreference = {};
    newBuyerPreference.vessel = buyerPreferencesState.buyerPreferenceCreateObject.vessel;
    newBuyerPreference.port = buyerPreferencesState.buyerPreferenceCreateObject.port;
    const simpleCompanyUser = {
      id: props.userState.user.userCompany,
      name: props.userState.user.userName
    };

    userState.userRole === 'MBA' ? newBuyerPreference.buyer = buyerObj : newBuyerPreference.buyer = simpleCompanyUser;
    newBuyerPreference.supplier = buyerPreferencesState.buyerPreferenceCreateObject.supplier;
    newBuyerPreference.category = buyerPreferencesState.buyerPreferenceCreateObject.category;

    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setBuyerPreferencesState({
        ...buyerPreferencesState,
        isLoading: true,
      });
      add(newBuyerPreference)
        .then(res => {
          setBuyerPreferencesState({
            ...buyerPreferencesState,
            buyerPreferences: [...buyerPreferencesState.buyerPreferences, res.data],
            buyerPreferenceCreateObject: null,
            buyerPreferenceEditIndex: -1,
            isLoading: false,
          })

        })
        .catch(err => {
          setBuyerPreferencesState({
            ...buyerPreferencesState,
            isLoading: false,
          });
        });
    }
    else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const classes = useStyles();

  const tableFilterComponent = () => {
    return <BuyerPreferencesTableFilter
      vessel={(filters && filters.vessel) ? filters.vessel : buyerPreferencesState.filters.vessel}
      port={(filters && filters.port) ? filters.port : buyerPreferencesState.filters.port}
      supplier={(filters && filters.supplier) ? filters.supplier : buyerPreferencesState.filters.supplier}
      buyer={props.companyId}
      productCategory={(filters && filters.category) ? filters.category : buyerPreferencesState.filters.category}
      userRole={userState.userRole}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      // handleSorting={handleSorting}
      headCells={tableHeadersBuyer}
      onSelectAllClick={() => { }}
      sort={false}
      order={buyerPreferencesState.page.order_type}
      orderBy={buyerPreferencesState.page.order_by}
      rowCount={buyerPreferencesState.page.length} />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {buyerPreferencesState.buyerPreferences.map((buyerPreference, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              {
                (buyerPreferencesState.buyerPreferenceEditIndex !== index) && index !== '?'
                  ? <>

                    <TableCell>{buyerPreference.vessel !== null && buyerPreference.vessel.name}</TableCell>
                    <TableCell>{buyerPreference.port && buyerPreference.port.name}</TableCell>
                    {/* {userState.userRole === 'MBA' && <TableCell>{buyerPreference.buyer !== null && buyerPreference.buyer.name}</TableCell>} */}
                    <TableCell>{buyerPreference.supplier !== null && buyerPreference.supplier.name}</TableCell>
                    <TableCell>{buyerPreference.category !== null && buyerPreference.category.name}</TableCell>
                  </>
                  : <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterVessels
                          company={buyerObj}
                          vessel={buyerPreferencesState.buyerPreferenceEditObject.vessel || null}
                          getSelectedVessel={(data) => selectedVessel(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterPorts
                          port={buyerPreferencesState.buyerPreferenceEditObject.port || null}
                          getSelectedPort={(data) => selectedPort(data, 'edit')} />
                      </FormControl>
                    </TableCell>
                    {/* {userState.userRole === 'MBA' && (<TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCompanies
                          company={buyerPreferencesState.buyerPreferenceEditObject.buyer || null}
                          companySearchFor={'buyer'}
                          getSelectedCompany={(data) => selectedBuyer(data, 'edit')}
                          errorMessage={null}
                        />
                        {simpleValidator.current.message('buyer', buyerPreferencesState.buyerPreferenceEditObject.buyer, 'required')}
                      </FormControl>
                    </TableCell>)} */}
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCompanies
                          company={buyerPreferencesState.buyerPreferenceEditObject.supplier || null}
                          companySearchFor={'supplier'}
                          getSelectedCompany={(data) => selectedSupplier(data, 'edit')}
                          errorMessage={null}
                        />
                        {simpleValidator.current.message('supplier', buyerPreferencesState.buyerPreferenceEditObject.supplier.id, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCatalogues
                          catalogue={buyerPreferencesState.buyerPreferenceEditObject.category || null}
                          getSelectedCatalogue={(data) => selectedCatalogue(data, 'edit')}
                          onBlur={simpleValidator.current.showMessageFor('category')}
                        />
                        {simpleValidator.current.message('catalogue', buyerPreferencesState.buyerPreferenceEditObject.category.id, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>

                    <TableCell onClick={e => e.stopPropagation()}>
                      {/*<BuyerPreferenceRowStatus status={buyerPreference.status} />*/}
                    </TableCell>
                  </>
              }
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: buyerPreferencesState.buyerPreferenceEditIndex !== index,
                    callback: (e) => handleEditBuyerPreference(e, index)
                  },
                  delete: {
                    //TODO !!IMPORTANT we can delete buyerPreferences even if we dont have a DELETE action !!!
                    enabled: buyerPreferencesState.buyerPreferenceEditIndex !== index,
                    callback: (e) => handleDeleteBuyerPreference(e, index)
                  },
                  close: {
                    enabled: buyerPreferencesState.buyerPreferenceEditIndex === index,
                    callback: (e) => handleCloseEditBuyerPreference(e, index)
                  },
                  save: {
                    enabled: buyerPreferencesState.buyerPreferenceEditIndex === index,
                    callback: (e) => handleSaveBuyerPreference(e, props)
                  },
                  quote: {
                    enabled: false,
                    callback: (e) => null
                  },
                }} />
              </TableCell>
            </TableRow>
          );
        })}
        {
          buyerPreferencesState.buyerPreferenceCreateObject &&
          <TableRow className={classes.tableRow}>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterVessels
                  company={buyerObj}
                  vessel={buyerPreferencesState.buyerPreferenceCreateObject.vessel || null}
                  getSelectedVessel={(data) => selectedVessel(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterPorts
                  port={buyerPreferencesState.buyerPreferenceCreateObject.port || null}
                  getSelectedPort={(data) => selectedPort(data, 'create')} />
              </FormControl>
            </TableCell>
            {/* {userState.userRole === 'MBA' && (<TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCompanies
                  company={buyerPreferencesState.buyerPreferenceCreateObject.buyer || null}
                  companySearchFor={'buyer'}
                  getSelectedCompany={(data) => selectedBuyer(data, 'create')}
                  errorMessage={null}
                />
                {simpleValidator.current.message('buyer', buyerPreferencesState.buyerPreferenceCreateObject.buyer, 'required')}
              </FormControl>
            </TableCell>)} */}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCompanies
                  company={buyerPreferencesState.buyerPreferenceCreateObject.supplier || null}
                  companySearchFor={'supplier'}
                  getSelectedCompany={(data) => selectedSupplier(data, 'create')}
                  errorMessage={null}
                />
                {simpleValidator.current.message('supplier', buyerPreferencesState.buyerPreferenceCreateObject.supplier.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCatalogues
                  catalogue={buyerPreferencesState.buyerPreferenceCreateObject.category || null}
                  getSelectedCatalogue={(data) => selectedCatalogue(data, 'create')}
                  onBlur={simpleValidator.current.showMessageFor('category')}
                />
                {simpleValidator.current.message('catalogue', buyerPreferencesState.buyerPreferenceCreateObject.category.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              {/*<BuyerPreferenceRowStatus status={buyerPreference.status} />*/}
            </TableCell>

            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setBuyerPreferencesState({ ...buyerPreferencesState, buyerPreferenceCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewBuyerPreference()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }

  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('BUYER_PREFERENCES')}
        pagination={{
          total: buyerPreferencesState.page.total || 0,
          page: buyerPreferencesState.page.page - 1 || 0,
          perPage: buyerPreferencesState.page.per_page || 10,
          count: buyerPreferencesState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewBuyerPreference}
        newRowBtnEnabled={!buyerPreferencesState.buyerPreferenceCreateObject}
        confirmDialog={buyerPreferencesState.confirmDialog}
        tableFilter={props.pageFilter ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={buyerPreferencesState.isLoading}
      />
    </>
  );
};

export default BuyerPreferences;