import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px',
      width: '100%',
    },
    button: {
      margin: 6,
    },
    breadcrumbs:{
      padding: 12,
    }
  }
});

export default useStyles;
