const validationRules = {
    quantity: 'required|numeric|min:0,num',
    unitCost: 'required|numeric|min:0,num',
    discount: 'between:0,100,num',
    leadTimeDays: 'numeric|min:0,num'
}

const required = value => value.toString().trim().length > 0
const isNumeric = value => {
    const number = Number(value);
    return !isNaN(number)
}
const isPositive = value => isNumeric(value) && value >= 0;
const max = (value, max) => value <= max;


export const ValidateField = (field, value) => {
    let errors = {};
    if(['quantity', 'unitCost'].includes(field)) {
        if(!required(value)) {
            errors[field] = `Field '${field}' is required`
            return errors;
        }
        if(!isNumeric(value)) {
            errors[field] = `Field '${field}'  must be a number`
            return errors;
        }
        if(!isPositive(value)) {
            errors[field] = `Field '${field}'  must be positive number`
            return errors;
        }
    }
    if(['unitCost'].includes(field)) {
        if(!required(value)) {
            errors[field] = `Field '${field}' is required`
            return errors;
        }
        if(!isPositive(value)) {
            errors[field] = `Field '${field}'  must be positive number`
            return errors;
        }
    }
    if(['discount'].includes(field)) {
        if(!isNumeric(value)) {
            errors[field] = `Field '${field}' must be a number`
            return errors;
        }
        // if(!isPositive(value)) {
        //     errors[field] = `Field '${field}'  must be positive number`
        //     return errors;
        // }
        if(!max(value, 100)) {
            errors[field] = `Field '${field}'  must be bellow 100`
            return errors;
        }
    }
    if(['leadTimeDays'].includes(field)) {
        if(!isNumeric(value)) {
            errors[field] = `Field '${field}' must be a number`
            return errors;
        }
        if(!isPositive(value)) {
            errors[field] = `Field '${field}'  must be positive number`
            return errors;
        }
    }
}