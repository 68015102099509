import httpService from './httpService';
import qs from 'qs';

const { REACT_APP_KUBERNETES_AUTH_KEY } = process.env;
const { REACT_APP_OAUTH_URL } = process.env;

const userLogin = (userData) => {
  httpService.injectHeader('Authorization',`Basic ${ REACT_APP_KUBERNETES_AUTH_KEY }`);

  const data =  qs.stringify({
     grant_type: 'password',
     username: userData.userName,
     password: userData.password
  });
  return httpService({
    method: 'post',
    url: '/o/token/',
    baseURL:REACT_APP_OAUTH_URL,
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" } },
  );
};

const getUsersByCompanyId = (criteria = {}) => {
  return httpService.FetchUserList(criteria);
};

const editUser = (id, data) => {
  return httpService.UpdateUser({}, data);
};

const deleteUser = (id) => {
  return httpService.DeleteUser(id);
};

const addUser = (data) => {
 return httpService.CreateUser({},data);
};

const getUserById = (criteria = {}) => {
  return httpService.FetchUser(criteria);
};


const getUserPreferences = (criteria = {}) => {
  return httpService.FetchUserPreferences(criteria);
};

const addUserPreference = (id,data) => {
  return httpService.CreateUserPreference({},data);
};

const editUserPreference = (id,data) => {
  return httpService.UpdateUserPreference({},data);
};

const clearUserCache = (criteria = {}) => {
  return httpService.ClearUserCache({},criteria);
};

export default {
  userLogin,
  editUser,
  deleteUser,
  addUser,
  getUsersByCompanyId,
  getUserById,
  getUserPreferences,
  addUserPreference,
  editUserPreference,
  clearUserCache
};
