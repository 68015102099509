import React from 'react';
import Typography from '@mui/material/Typography';
import InlineEditNotes from '../inlineEditNotes';
import InlineRating from '../inlineRating';
import Grid from '@mui/material/Grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: theme.spacing('1px'),
        },
    },
}));

export default function HalfRatingWithNotes(props) {


    const evalNotes = (data) => {
        props.getNotes(data);
    };
    const evalStars = (data) => {
        props.getStars(data);
    };


    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                spacing={3}>
                <Grid item xs={3}>
                    <Typography component="legend">{props.title}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <InlineRating stars={props.stars} saved={props.savedStars} error={props.error}
                        getStars={evalStars} />
                </Grid>
                <Grid item xs={6}>
                    <InlineEditNotes notes={props.notes} saved={props.savedNotes} error={props.error}
                        getNotes={evalNotes}></InlineEditNotes>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        tooltip="Delete evaluation item"
                        onClick={props.deleteEvaluateItem}
                        size="large">
                        <DeleteOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
}