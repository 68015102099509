
const tableHeadersDataAudit = [
  { id: 'data', disablePadding: false, label: 'Data'},
  { id: 'model', disablePadding: false, label: 'Model'},
  { id: 'action', disablePadding: false, label: 'Action'},
  { id: 'userInfo', disablePadding: false, label: 'User Info' },
  { id: 'time', disablePadding: false, label: 'Time' },
];

// Empty Data Audit
const newDataAudit = () => {
  return {
    id: '?',
    model: null,
    userInfo: null,
    time: null,
    action: null,
  }
};

export { tableHeadersDataAudit, newDataAudit };
