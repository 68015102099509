import React from 'react';
import Grid from '@mui/material/Grid';
import useStyles from './styles';
import Typography from '@mui/material/Typography';

const MiniCard = (props) => {

    const classes = useStyles();

    return (
        <span className={classes.miniBox}>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start" >
                <Typography variant="h5" component="span" gutterBottom>
                    {props.title}
                </Typography>
                <Typography variant="h3" component="span" gutterBottom>
                    {props.number}
                </Typography>
            </Grid>
        </span>

    );
};

export default MiniCard;