import React, { useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import OutlineDisplay from '../../components/outlineDisplay';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const TransactionInfo = (props) => {

    const [agentState, setAgentState] = useState({
        isLoading: true,
        error: null,
        name: props.agentName ? props.agentName : null,
        email: props.agentEmail ? props.agentEmail : null,
        address: props.agentAddress ? props.agentAddress : null,
        faxNo: props.agentFaxNo ? props.agentFaxNo : null,
        telephoneNo: props.agentTelephoneNo ? props.agentTelephoneNo : null,
    });


    const onEditItemChange = (event, property) => {
        const newValue = event.target.value!==''?event.target.value:null;
        const newEditItem = Object.assign({}, agentState);
        newEditItem[property] = newValue;

        setAgentState(
            newEditItem
        );
    };

    const agentData = (event) => {
        event.preventDefault();
        props.agentData(agentState);
    };

    
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    {props.infoTabTitle}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <OutlineDisplay label={t('BUYER')} value={props.buyer.name} />
            </Grid>
            <Grid item xs={3}>
                <OutlineDisplay label={t('PORT')} value={props.port.name} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('VESSEL')} value={props.vessel.name} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('STATUS')} value={props.status} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('DATE')} value={props.date} />
            </Grid>
            <Grid className='agent-details' item xs={2}>
                {props.edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('AGENT_NAME')}
                        value={agentState.name||''}
                        onChange={e => onEditItemChange(e, 'name')}
                    /> :
                    <OutlineDisplay label={t('AGENT_NAME')} value={props.agentName} />
                }
            </Grid>
            <Grid item xs={4}>
                {props.edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('AGENT_ADDRESS')}
                        value={agentState.address||''}
                        onChange={e => onEditItemChange(e, 'address')}
                    /> :
                    <OutlineDisplay label={t('AGENT_ADDRESS')} value={props.agentAddress} />}
            </Grid>
            <Grid item xs={2}>
                {props.edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('AGENT_EMAIL')}
                        value={agentState.email||''}
                        onChange={e => onEditItemChange(e, 'email')}
                    /> :
                    <OutlineDisplay label={t('AGENT_EMAIL')} value={props.agentEmail} />}
            </Grid>
            <Grid item xs={2}>
                {props.edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('AGENT_TELEPHONE')}
                        value={agentState.telephoneNo||''}
                        onChange={e => onEditItemChange(e, 'telephoneNo')}
                    /> :
                    <OutlineDisplay label={t('AGENT_TELEPHONE')} value={props.agentTelephoneNo} />}
            </Grid>
            <Grid item xs={props.edit ? 1 : 2}>
                {props.edit ?
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('AGENT_FAX')}
                        value={agentState.faxNo || ''}
                        onChange={e => onEditItemChange(e, 'faxNo')}
                    /> :
                    <OutlineDisplay label={t('AGENT_FAX')} value={props.agentFaxNo} />}
            </Grid>
            {props.edit && <Grid item xs={1}>
                <Button
                    variant="contained"
                    onClick={agentData}
                    fullWidth
                >
                    Update
                </Button>
            </Grid>}
        </Grid>
    );
}

export default TransactionInfo;