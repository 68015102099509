import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    actionButton: {
       margin: '0 7px 0 0',
       display: 'inlineBlock',
    },
    // actionIcon: {
    //   fontSize: 16,
    // }
}
});

export default useStyles;
