import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    topBar: {
      backgroundColor: theme.palette.primary.contrastText,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    userMenu: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    firstName: {
      textTransform: 'initial',
      fontSize: '13px',
      color: '#646777',
      margin: '0 3px 0 10px',
    },
    lastName: {
      textTransform: 'initial',
      fontSize: '13px',
      color: '#646777',
    },
    burgerIcon: {
      color: 'rgb(177,195,200)',
    },
    expandIcon: {
      marginLeft: '10px',
      marginTop: '2px',
      fontSize: '20px',
      color: 'rgb(177,195,200)',
    },
  };
});

export default useStyles;
