
const tableHeadersUnitDictionary = [
  { id: 'unitAlias', disablePadding: false, label: 'Unit Alias' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersUnitDictionaryGlobal = [
  { id: 'company', disablePadding: false, label: 'Company' },
  { id: 'unitAlias', disablePadding: false, label: 'Unit Alias' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

// Empty Unit dictionary
const newUnitDictionary  = () => {
  return {
    id: '?',
    company: { name: '', id: '' },
    unitAlias: null,
    original: null
  }
};

export { tableHeadersUnitDictionary, tableHeadersUnitDictionaryGlobal, newUnitDictionary };
