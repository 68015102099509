import React from "react";
import {
  createStyles,
  makeStyles,
  // Theme 
} from "@mui/styles";
import { deepOrange } from "@mui/material/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    messageRowRight: {
      display: "flex",
      alignItems: 'flex-end',
      flexDirection: 'column'
    },
    messageBlue: {
      position: "relative",
      // inlineSize: '350px',
      overflowWrap: 'break-word',
      marginLeft: "20px",
      padding: "10px",
      backgroundColor: "#d3d3d3 ",
      width: 'auto',
      maxWidth: 350,
      textAlign: "left",
      font: "400 1.2em 'Open Sans', sans-serif",
      border: "1px solid #d3d3d3",
      borderRadius: "10px 10px 10px 0px",
      color: '#3d3d3d',
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      // marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#800080",
      // inlineSize: '350px',
      overflowWrap: 'break-word',
      width: 'auto',
      maxWidth: 350,
      textAlign: "left",
      font: "400 1.2em 'Open Sans', sans-serif",
      border: "1px solid #800080",
      borderRadius: "10px 10px 0px 10px",
      color: '#ffffff',
    },

    messageContent: {
      padding: 0,
      margin: 0
    },
    messageNotReadContent: {
      padding: 0,
      margin: 0,
      color: '#FFFFFF',
    },
    messageTimeStamp: {
      fontSize: ".85em",
      fontWeight: "300",
    },
    timestamp: {
      position: "relative",
      marginBottom: "10px",
      paddingLeft: 21,
      paddingRight: 21,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    displayName: {
      marginLeft: "20px"
    }
  })
);

export const Message = (props) => {
  const classes = useStyles();
  const {
      message = 'no message',
      timestamp = "",
      position
  } = props;

  const toLocalTime = timestamp => {
      const date = new Date(timestamp);

      return date.toLocaleString();
  }

  return (
    <div className={position === 'right' ? classes.messageRowRight : null}>
      <div className={position === 'right' ? classes.messageOrange: classes.messageBlue}>
        <p className={classes.messageContent}>{message}</p>
      </div>
      <div className={classes.timestamp}>
        <div className={classes.messageTimeStamp}>
          { toLocalTime(timestamp) }
        </div>
      </div>
    </div>
  );
};
