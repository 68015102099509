import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    miniBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingLeft: '20px',
      paddingBottom: '20px',
      marginTop:'21px'
    }}
});

export default useStyles;
