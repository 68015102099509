import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersEquivalentProduct } from '../../helpers/equivalentsProductConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { editProduct } from '../../services/productsService';
import {
  getAllEquivalentProductsInfo,
  resolveEquivalentProduct
} from '../../services/equivalentsProductService';
import { getAuthorizationMappings } from '../../services/authorizationsService';
import { EquivalentsProductTableFilter } from '../../components/equivalentsProductTableFilter';
import FilterProductEquivalentCategories from '../../components/filterProductEquivalentCategories';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import './_equivalentsProduct.scss';
import t from '../../helpers/languages';

const EquivalentsProduct = (props) => {

  const equivalentProductInitObject = () => {
    return {
      id: '?',
      name: '',
      code: '',
      category: { name: null, id: null },
      vs: null,
      dft:null,
      isUnresolved: false,
    }
  };

  const classes = useStyles();

  const userState = props.userState && props.userState.user;

  const filters = localStoreGetObj('equivalentsProductFilter');

  const [equivalentsProductState, setEquivalentsProductState] = useState({
    equivalentsProduct: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    equivalentProductEditIndex: -1,
    equivalentProductEditObject: equivalentProductInitObject(),
    equivalentProductCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...equivalentsProductState.filters,
    catalogue: filters && filters.category && filters.category.id,
    search: filters && filters.search,
    supplier: filters && filters.company && filters.company.id,
    isUnresolved: filters && filters.isUnresolved,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllEquivalentProductsInfo(filtersData).then(res => {
      const { results } = res.data;

      setEquivalentsProductState({
        ...equivalentsProductState,
        equivalentsProduct: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/equivalentsProduct', userState.userRole)
        }
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setEquivalentsProductState({
      ...equivalentsProductState,
      isLoading: true
    });
    const newCriteria = {
      ...equivalentsProductState.filters,
      per_page: equivalentsProductState.page.per_page,
      page: newPage + 1
    };
    getAllEquivalentProductsInfo(newCriteria).then(res => {
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
        equivalentsProduct: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setEquivalentsProductState({
      ...equivalentsProductState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...equivalentsProductState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllEquivalentProductsInfo(newCriteria).then(res => {
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
        equivalentsProduct: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
      });
    });
  };

  const handleEditContractProduct = (event, equivalentProductIndex) => {

    event.stopPropagation();

    const equivalentProductEditObject = Object.assign({}, equivalentsProductState.equivalentsProduct[equivalentProductIndex]);


    setEquivalentsProductState({
      ...equivalentsProductState,
      equivalentProductEditIndex: equivalentProductIndex,
      equivalentProductEditObject: equivalentProductEditObject
    });

  };

  const onEditableContractProductChange = (event, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
      newValue = event.target.checked ? true : false;
    }
    else if (property === "vs") {
      newValue = parseFloat(event.target.value);
    } else {
      newValue = event.target.value;
    }

    const newEditContractProductItem = Object.assign({}, equivalentsProductState.equivalentProductEditObject);
    newEditContractProductItem[property] = newValue;

    setEquivalentsProductState({
      ...equivalentsProductState,
      equivalentProductEditObject: newEditContractProductItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveContractProduct = (event, props) => {
    event.preventDefault();
    setEquivalentsProductState({
      ...equivalentsProductState,
      isLoading: true
    });
    const newEditContractProductItem = Object.assign({}, equivalentsProductState.equivalentProductEditObject);

    if (validator.current.allValid()) {
      resolveEquivalentProduct(newEditContractProductItem.id, newEditContractProductItem)
        .then(re => {
          const editedProduct = re.data;
          setEquivalentsProductState(oldEquivalentsProduct => {
            return {
              ...oldEquivalentsProduct,
              equivalentsProduct: oldEquivalentsProduct.equivalentsProduct.map(product => {
                return product.id === editedProduct.id
                  ? re.data : product
              }),
              equivalentProductEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setEquivalentsProductState({
            ...equivalentsProductState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditProduct = (e, index) => {
    setEquivalentsProductState({
      ...equivalentsProductState,
      equivalentProductEditIndex: -1
    })
  };

  const filterData = (data) => {
    setEquivalentsProductState({
      ...equivalentsProductState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...equivalentsProductState.filters,
      search: data && data.search,
      coding: data && data.coding && data.coding.id,
      supplier: data && data.company && data.company.id,
      catalogue: data && data.category && data.category.id,
      isUnresolved: data && data.isUnresolved,
      per_page: criteria.per_page,
      page: 1
    };

    getAllEquivalentProductsInfo(filtersData).then(res => {
      const { results } = res.data;

      setEquivalentsProductState({
        ...equivalentsProductState,
        equivalentsProduct: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/equivalentsProduct', userState.userRole)
        }
      });
      localStoreSetObj('equivalentsProductFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setEquivalentsProductState({
        ...equivalentsProductState,
        isLoading: false,
      });
    });

  };

  const selectedProductCategory = (data, action) => {

    const newEditContractProductItem = Object.assign({},
      action === 'edit'
        ? equivalentsProductState.equivalentProductEditObject
        : equivalentsProductState.equivalentProductCreateObject
    );

    newEditContractProductItem.category = data.category;

    setEquivalentsProductState({
      ...equivalentsProductState,
      [
        action === 'edit'
          ? 'equivalentProductEditObject'
          : 'equivalentProductCreateObject'
      ]: newEditContractProductItem
    });
  };


  const tableFilterComponent = () => {
    return <EquivalentsProductTableFilter
      search={(filters && filters.search) ? filters.search : equivalentsProductState.filters.search}
      catalogue={(filters && filters.category) ? filters.category : equivalentsProductState.filters.category}
      companySearchFor={'equivalentProductSupplier'} 
      company={(filters && filters.company) ? filters.company : equivalentsProductState.filters.company}
      isUnresolved={(filters && filters.isUnresolved) ? filters.isUnresolved : equivalentsProductState.filters.isUnresolved}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersEquivalentProduct}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(equivalentsProductState.equivalentsProduct) && equivalentsProductState.equivalentsProduct.map((product, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (equivalentsProductState.equivalentProductEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{product.code}</TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.category?.name}</TableCell>
                    <TableCell>{product.supplier?.name}</TableCell>
                    <TableCell>{product.isUnresolved && <CheckIcon />}</TableCell>
                    <TableCell>{product.vs}</TableCell>
                    <TableCell>{product.dft}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.code}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {product.name}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {/* <FormControl variant="outlined" fullWidth>
                        <FilterProductEquivalentCategories
                          category={equivalentsProductState.equivalentProductEditObject.category || null}
                          getSelectedProductCategory={(data) => selectedProductCategory(data, 'edit')}
                        />
                      </FormControl> */}
                      {product.category?.name}
                    </TableCell>
                    <TableCell>{product.supplier?.name}</TableCell>
                    <TableCell>{product.isUnresolved && <CheckIcon />}</TableCell>
                    {/* <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={equivalentsProductState.equivalentProductEditObject.isUnresolved}
                              onChange={e => onEditableContractProductChange(e, 'isUnresolved')}
                              name="isUnresolved"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="isUnresolved"
                        />
                      </FormControl>
                    </TableCell> */}
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          defaultValue={equivalentsProductState.equivalentProductEditObject.vs}
                          onChange={e => onEditableContractProductChange(e, 'vs')}
                          fullWidth
                          variant="outlined"
                        />
                        {validator.current.message('vs', equivalentsProductState.equivalentProductEditObject.vs, 'required|numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          defaultValue={equivalentsProductState.equivalentProductEditObject.dft}
                          onChange={e => onEditableContractProductChange(e, 'dft')}
                          fullWidth
                          variant="outlined"
                        />
                        {validator.current.message('dft', equivalentsProductState.equivalentProductEditObject.dft, 'required|numeric')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: equivalentsProductState.equivalentProductEditIndex !== index&&product.category?.catalogue?.id===2 ,
                    callback: (e) => handleEditContractProduct(e, index)
                  },
                  delete: {
                    enabled: false,
                  },
                  close: {
                    enabled: equivalentsProductState.equivalentProductEditIndex === index,
                    callback: (e) => handleCloseEditProduct(e, index)
                  },
                  save: {
                    enabled: equivalentsProductState.equivalentProductEditIndex === index,
                    callback: (e) => handleSaveContractProduct(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}
        
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PRODUCT_EQUIVALENTS')}
        pagination={{
          total: equivalentsProductState.page.total || 0,
          page: equivalentsProductState.page.page - 1 || 0,
          perPage: equivalentsProductState.page.per_page || 10,
          count: equivalentsProductState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={false}
        newRowBtnEnabled={false}
        confirmDialog={equivalentsProductState.confirmDialog.message.length > 0 ? equivalentsProductState.confirmDialog : equivalentsProductState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={equivalentsProductState.isLoading}
      />
    </>
  );
};


export default EquivalentsProduct;
