import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersCompanyDictionary, newCompanyDictionary } from '../../helpers/companiesDictionaryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterCompanies from "../../components/filterCompanies";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllCompanyDictionariesInfo, editCompanyDictionary, deleteCompanyDictionary, addCompanyDictionary } from '../../services/companiesDictionaryService';
import { FilterByText } from '../../components/filterByText';
import './_CompanyDictionaries.scss';
import t from '../../helpers/languages';

const CompanyDictionaries = (props) => {

  const companyDictionaryInitObject = () => {
    return {
      id: '?',
      company: null,
      nameAlias: null,
      codeAlias: null,
      original: null
    }
  };

  const classes = useStyles();

  const filters = localStoreGetObj('companyDictionaryFilter');

  const [companiesDictionaryState, setcompanyDictionariesState] = useState({
    companiesDictionary: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    companyDictionaryEditIndex: -1,
    companyDictionaryEditObject: companyDictionaryInitObject(),
    companyDictionaryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...companiesDictionaryState.filters,
    companyId: props.companyId,
    search: filters && filters.companyDictionary && filters.companyDictionary,
    per_page: criteria.per_page,
    page: criteria.page,
    order_type: companiesDictionaryState.page.order_type,
    order_by: companiesDictionaryState.page.order_by,
  };

  useEffect(function () {
    getAllCompanyDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        companiesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === companiesDictionaryState.page.order_by && companiesDictionaryState.page.order_type === 'desc' ? 'asc' : 'desc';

    setcompanyDictionariesState({
      ...companiesDictionaryState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllCompanyDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        companiesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      isLoading: true
    });
    const newCriteria = {
      ...companiesDictionaryState.filters,
      per_page: companiesDictionaryState.page.per_page,
      page: newPage + 1
    };
    getAllCompanyDictionariesInfo(newCriteria).then(res => {
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
        companiesDictionary: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...companiesDictionaryState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllCompanyDictionariesInfo(newCriteria).then(res => {
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
        companiesDictionary: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
      });
    });
  };

  const handleEditCompanyDictionary = (event, companyDictionaryIndex) => {

    event.stopPropagation();

    const companyDictionaryEditObject = Object.assign({}, companiesDictionaryState.companiesDictionary[companyDictionaryIndex]);


    setcompanyDictionariesState({
      ...companiesDictionaryState,
      companyDictionaryEditIndex: companyDictionaryIndex,
      companyDictionaryEditObject: companyDictionaryEditObject
    });

  };

  const onEditableCompanyDictionaryChange = (event, property) => {

    const newValue = event.target.value;

    const newEditCompanyDictionaryItem = Object.assign({}, companiesDictionaryState.companyDictionaryEditObject);
    newEditCompanyDictionaryItem[property] = newValue;

    setcompanyDictionariesState({
      ...companiesDictionaryState,
      companyDictionaryEditObject: newEditCompanyDictionaryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveCompanyDictionary = (event, props) => {
    event.preventDefault();
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      isLoading: true
    });
    const newEditCompanyDictionaryItem = Object.assign({}, companiesDictionaryState.companyDictionaryEditObject);

    if (validator.current.allValid()) {
      editCompanyDictionary(newEditCompanyDictionaryItem.id, newEditCompanyDictionaryItem)
        .then(re => {
          const editedCompanyDictionary = re.data;
          setcompanyDictionariesState(oldcompanyDictionaries => {
            return {
              ...oldcompanyDictionaries,
              companiesDictionary: oldcompanyDictionaries.companiesDictionary.map(companyDictionary => {
                return companyDictionary.id === editedCompanyDictionary.id
                  ? re.data : companyDictionary
              }),
              companyDictionaryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setcompanyDictionariesState({
            ...companiesDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditCompanyDictionary = (e, index) => {
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      companyDictionaryEditIndex: -1
    })
  };

  const handleDeleteCompanyDictionary = (event, index) => {

    const companyDictionaryForDeletion = companiesDictionaryState.companiesDictionary[index];
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete CompanyDictionary',
        onCancel: () => {
          setcompanyDictionariesState({
            ...companiesDictionaryState,
            confirmDialog: {
              ...companiesDictionaryState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteCompanyDictionary(companyDictionaryForDeletion.id).then(() => {
            const newcompanyDictionaries = [...companiesDictionaryState.companiesDictionary];
            newcompanyDictionaries.splice(index, 1);
            setcompanyDictionariesState({
              ...companiesDictionaryState,
              companiesDictionary: newcompanyDictionaries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewCompanyDictionary = (e) => {
    e.preventDefault();

    const newCompanyDict=newCompanyDictionary();

    newCompanyDict.company={id:props.companyId};

    setcompanyDictionariesState({
      ...companiesDictionaryState,
      companyDictionaryCreateObject: newCompanyDict
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewCompanyDictionary = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newCompanyDictionary = {};
    
    newCompanyDictionary.company = companiesDictionaryState.companyDictionaryCreateObject.company;
    newCompanyDictionary.codeAlias = companiesDictionaryState.companyDictionaryCreateObject.codeAlias;
    newCompanyDictionary.nameAlias = companiesDictionaryState.companyDictionaryCreateObject.nameAlias;
    newCompanyDictionary.nameAlias = companiesDictionaryState.companyDictionaryCreateObject.nameAlias;
    newCompanyDictionary.original = companiesDictionaryState.companyDictionaryCreateObject.original;

    if (validator.current.allValid()) {
      addCompanyDictionary(newCompanyDictionary)
        .then(res => {
          setcompanyDictionariesState({
            ...companiesDictionaryState,
            companiesDictionary: [...companiesDictionaryState.companiesDictionary, res.data],
            companyDictionaryCreateObject: null,
            companyDictionaryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setcompanyDictionariesState({
            ...companiesDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewCompanyDictionaryObject = (e, property) => {
    const newValue = e.target.value;

    const newEditCompanyDictionaryItem = Object.assign({}, companiesDictionaryState.companyDictionaryCreateObject);
    newEditCompanyDictionaryItem[property] = newValue;

    setcompanyDictionariesState({
      ...companiesDictionaryState,
      companyDictionaryCreateObject: newEditCompanyDictionaryItem
    });
  };

  const filterData = (data) => {
    setcompanyDictionariesState({
      ...companiesDictionaryState,
      isLoading: true
    });

    filtersData = {
      ...companiesDictionaryState.filters,
      search: data && data.searchText,
      per_page: criteria.per_page,
      page: 1
    };

    getAllCompanyDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;

      setcompanyDictionariesState({
        ...companiesDictionaryState,
        companiesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('companyDictionaryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcompanyDictionariesState({
        ...companiesDictionaryState,
        isLoading: false,
      });
    });

  };


  const selectedOriginal = (data, action) => {

    const newEditCompanyDictionaryItem = Object.assign({},
      action === 'edit'
        ? companiesDictionaryState.companyDictionaryEditObject
        : companiesDictionaryState.companyDictionaryCreateObject
    );

    newEditCompanyDictionaryItem.original = data.company;


    setcompanyDictionariesState({
      ...companiesDictionaryState,
      [
        action === 'edit'
          ? 'companyDictionaryEditObject'
          : 'companyDictionaryCreateObject'
      ]: newEditCompanyDictionaryItem
    });
  };

  const tableFilterComponent = () => {
    return <FilterByText
      searchText={(filters && filters.companyDictionary) ? filters.companyDictionary : companiesDictionaryState.filters.companyDictionary}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={ tableHeadersCompanyDictionary }
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(companiesDictionaryState.companiesDictionary) && companiesDictionaryState.companiesDictionary.map((companyDictionary, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (companiesDictionaryState.companyDictionaryEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{companyDictionary.nameAlias}</TableCell>
                    <TableCell>{companyDictionary.codeAlias}</TableCell>
                    <TableCell>{companyDictionary.original.name}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={companiesDictionaryState.companyDictionaryEditObject.nameAlias|| ''}
                          onChange={e => onEditableCompanyDictionaryChange(e, 'nameAlias')}
                          onBlur={() => validator.current.showMessageFor('nameAlias')}
                        />{validator.current.message('nameAlias', companiesDictionaryState.companyDictionaryEditObject.nameAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={companiesDictionaryState.companyDictionaryEditObject.codeAlias || ''}
                          onChange={e => onEditableCompanyDictionaryChange(e, 'codeAlias')}
                          onBlur={() => validator.current.showMessageFor('codeAlias')}
                          />{validator.current.message('codeAlias', companiesDictionaryState.companyDictionaryEditObject.codeAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={companiesDictionaryState.companyDictionaryEditObject.original || null}
                            getSelectedCompany={(data) => selectedOriginal(data, 'edit')}
                            buyer={!props.supplier}
                            errorMessage={null}
                          />
                          {validator.current.message('original', companiesDictionaryState.companyDictionaryEditObject.original, 'required')}
                        </FormControl>
                      </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: companiesDictionaryState.companyDictionaryEditIndex !== index,
                    callback: (e) => handleEditCompanyDictionary(e, index)
                  },
                  delete: {
                    enabled: companiesDictionaryState.companyDictionaryEditIndex !== index,
                    callback: (e) => handleDeleteCompanyDictionary(e, index)
                  },
                  close: {
                    enabled: companiesDictionaryState.companyDictionaryEditIndex === index,
                    callback: (e) => handleCloseEditCompanyDictionary(e, index)
                  },
                  save: {
                    enabled: companiesDictionaryState.companyDictionaryEditIndex === index,
                    callback: (e) => handleSaveCompanyDictionary(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          companiesDictionaryState.companyDictionaryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={companiesDictionaryState.companyDictionaryCreateObject.nameAlias || ''}
                  onChange={e => onEditNewCompanyDictionaryObject(e, 'nameAlias')}
                  onBlur={() => validator.current.showMessageFor('nameAlias')}
                />{validator.current.message('nameAlias', companiesDictionaryState.companyDictionaryCreateObject.nameAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={companiesDictionaryState.companyDictionaryCreateObject.codeAlias || ''}
                  onChange={e => onEditNewCompanyDictionaryObject(e, 'codeAlias')}
                  onBlur={() => validator.current.showMessageFor('codeAlias')}
                  />{validator.current.message('codeAlias', companiesDictionaryState.companyDictionaryCreateObject.codeAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={companiesDictionaryState.companyDictionaryCreateObject.original || null}
                    getSelectedCompany={(data) => selectedOriginal(data, 'create')}
                    buyer={!props.supplier}
                    errorMessage={null}
                  />
                  {validator.current.message('original', companiesDictionaryState.companyDictionaryCreateObject.original, 'required')}
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setcompanyDictionariesState({ ...companiesDictionaryState, companyDictionaryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewCompanyDictionary()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('COMPANYDICTIONARY')}
        pagination={{
          total: companiesDictionaryState.page.total || 0,
          page: companiesDictionaryState.page.page - 1 || 0,
          perPage: companiesDictionaryState.page.per_page || 10,
          count: companiesDictionaryState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewCompanyDictionary}
        newRowBtnEnabled={!companiesDictionaryState.companyDictionaryCreateObject}
        confirmDialog={companiesDictionaryState.confirmDialog.message.length > 0 ? companiesDictionaryState.confirmDialog : companiesDictionaryState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={companiesDictionaryState.isLoading}
      />
    </>
  );
};


export default CompanyDictionaries;
