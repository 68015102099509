import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    visuallyHidden: {
      border: 0,
      clipPath: 'inset(0 calc(100% - 0) calc(100% - 0) 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    oddColumn:{backgroundColor: theme.palette.action.hover}
  };
});

export default useStyles;
