import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import useStyles from './styles';

const TableHeaderColumnGroup = (props) => {
  const { headCells, headCellsConfig, order, orderBy, handleSorting, sort = true } = props;

  const createSortHandler = (property) => {
    return (event) => {
      handleSorting(event, property);
    };
  };

  const classes = useStyles();

  // const headersItems=headCellsConfig;
  // headCells[0]?.items.map(item=>{
  //   headersItems.push( { id: item, disablePadding: false, label: item })
  // });

  return (<>
    <colgroup>
      <col key={'index34'} ></col>
      <col key={'index39'} ></col>
      <col key={'index40'} ></col>
      {headCellsConfig.length===4&&<col key={'index430'} ></col>}
      {headCells?.map((heads, index) => {
          return (heads.items.map((headCell, aindex) => {
            return (
              <col className={index % 2 === 0 ? classes.oddColumn:''}
              colSpan={headCell.items?.length}
              key={'index15' + aindex}
            >
              
            </col>
            );
          })
          )
        })
        }
    </colgroup>
    <TableHead>
      <TableRow>
        <TableCell
          key={'index'}
          align={'center'}
          colSpan={headCellsConfig.length}
        >
        </TableCell>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={'index' + index}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width}
              align={'center'}
              scope={'col'}
              className={index % 2 === 0 ? classes.oddColumn:''}
              colSpan={headCell.items?.length}
            >
              {headCell.group}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        {headCellsConfig?.map((headCell, index) => {
          return (
            <TableCell
              key={'index1' + index}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width}
              align={index===0?'left':'center'}
              scope={'col'}
            >
              {headCell.label}
            </TableCell>
          );
        })}
        {headCells?.map((heads, index) => {
          return (heads.items.map((headCell, aindex) => {
            return (
              <TableCell
                key={'index2' + aindex}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                width={headCell.width}
                align={'center'}
                scope={'col'}
              >
                {headCell}
              </TableCell>
            );
          })
          )
        })
        }
      </TableRow>
    </TableHead>
  </>
  );
};

export default TableHeaderColumnGroup;
