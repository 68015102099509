import React, { useCallback } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllContinents } from '../../services/continentsService';

export default function FilterContinents({ continent: initialContinent, getSelectedContinent }) {


  const [continent, setContinent] = React.useState(initialContinent && initialContinent.id !== null ? initialContinent : 0);
  const [continents, setContinents] = React.useState(initialContinent && initialContinent.id !== null ? [initialContinent] : []);
  const [openContinent, setOpenContinent] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "continent" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    continentSearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onContinentSelectionChange = (event, values) => {

    setContinent(values ? values : "");
    const selectedContinent = {
      continent: values ? values : null,
    };

    getSelectedContinent(selectedContinent);
  };

  const continentSearch = (criteria) => {

    getAllContinents(criteria).then((res) => {
      setContinents(res.data);
    }).catch((error) => {
    })
  };

  return (
    <div>
      <Autocomplete
        id="find-continent"
        fullWidth
        open={openContinent}
        onOpen={() => {
          setOpenContinent(true);
        }}
        onClose={() => {
          setOpenContinent(false);
        }}
        onInputChange={e => handleInputChange(e, 'continent')}
        onChange={onContinentSelectionChange}
        value={continents.find(v => v.id === continent.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={continents !== null ? continents : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('CONTINENT')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}