import { createTheme} from '@mui/material/styles';

const materialTheme = createTheme(({
  palette: {
    primary: {
      light: '#81d4fa',
      main: '#03a9f4',
      dark: '#0277bd',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffb900',
      main: '#ff7730',
      contrastText: '#ffffff',
    },
    tertiary: {
      light: '#ff2975',
      main: '#f50057',
      dark: '#c20045',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      inputAdornedStart: {
        paddingLeft: '10px',
      },
    },
    MuiSnackbar: {
      root: {
        width: '20em',
      },
    },
    MuiAlert: {
      root: {
        width: '100%',
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingRight: 26,
      },
    },
  },
}));

export default materialTheme;
