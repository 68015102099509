import React, { useCallback } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllRegions } from '../../services/regionsService';

export default function FilterRegions({ region: initialRegion, getSelectedRegion }) {


  const [region, setRegion] = React.useState(initialRegion && initialRegion.id !== null ? initialRegion : 0);
  const [regions, setRegions] = React.useState(initialRegion && initialRegion.id !== null ? [initialRegion] : []);
  const [openRegion, setOpenRegion] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "region" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    regionSearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onRegionSelectionChange = (event, values) => {

    setRegion(values ? values : "");
    const selectedRegion = {
      region: values ? values : null,
    };

    getSelectedRegion(selectedRegion);
  };

  const regionSearch = (criteria) => {

    getAllRegions(criteria).then((res) => {
      setRegions(res.data);
    }).catch((error) => {
    })
  };

  return (
    <div>
      <Autocomplete
        id="find-region"
        fullWidth
        open={openRegion}
        onOpen={() => {
          setOpenRegion(true);
        }}
        onClose={() => {
          setOpenRegion(false);
        }}
        onInputChange={e => handleInputChange(e, 'region')}
        onChange={onRegionSelectionChange}
        value={regions.find(v => v.id === region.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={regions !== null ? regions : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('REGION')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}