import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from "@mui/icons-material/Save";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
import useStyles from './styles';
import ClearIcon from "@mui/icons-material/Clear";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { debounce } from "lodash";
import t from '../../helpers/languages';

export const TableFilterItems = (props) => {
  const classes = useStyles();

  const [search, setSearch] = React.useState('');

  // const handleSearchItems = (event) => {
  //   event.preventDefault();

  //   const filterData = search;

  //   props.config.search.callback(filterData);
  // }

  const handleChange = (event) => {
    const value = event && event.target && event.target.value;
    setSearch({ ...search, search: value });
    if (value && value.length >= 3 && search !== value) {
      const filterData = {
        search: value
      };
      debounceFn(filterData);
    }

    if (value.length === 0) {
      const filterData = {
        search: ''
      };
      debounceFn(filterData);
    }
  };

  const handleDebounceFn = (val) => {
    searchItems(val);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const clearSearch = () => {
    setSearch({ ...search, search: '' });
    const filterData = {
      search: ''
    };

    props.config.search.callback(filterData);
  };

  const searchItems = (criteria) => {

    props.config.search.callback(criteria);

  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      >
      <Grid item xs>
        <Paper component="form" className={classes.root + " " + "table-filter"}>
          <InputBase
            className={classes.input}
            placeholder="Search items"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChange}
            value={search && search.search}
          />
          {/* <IconButton onClick={e => handleSearchItems(e)} className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton> */}
          <IconButton
            edge="end"
            size="small"
            disabled={!search}
            onClick={clearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item  xs="auto">
        {(props.config.edit?.enabled || props.config.close?.enabled) && <>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          <div className={'tableFilterItemsActions'}>
            <Stack spacing={0.5} direction='row'>
              {props.config.edit.enabled &&
                <Button
                  variant="contained"
                  color='primary'
                  startIcon={<EditIcon />}
                  onClick={props.config.edit.callback}
                >
                  {t('EDIT_ITEMS')}
                </Button>
              }
              {props.config.save.enabled &&
                <Button
                  variant="contained"
                  color='success'
                  startIcon={<SaveIcon />}
                  onClick={props.config.save.callback}
                >
                  {t('SAVE')}
                </Button>
              }
              {props.config.close.enabled &&
                <Button
                  variant="contained"
                  color='error'
                  startIcon={<CancelIcon />}
                  onClick={props.config.close.callback}
                >
                  {t('Cancel')}
                </Button>
              }
            </Stack>
          </div>
        </>
        }
      </Grid>
    </Grid>
  );
}
