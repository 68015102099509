import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import Chip from '@mui/material/Chip';

const exportExcel = ({ title, handleExport }) => {

    const handleClick = (event) => {
        handleExport(event);
    }

    return <Chip
        aria-label={`${title}`}
        variant="outlined"
        style={{ color: '#000000', opacity: '55%', marginRight: '2px', marginLeft: '2px' }}
        onClick={(event) => {
            return handleClick(event)
        }}
        size="medium"
        label={title}
        icon={<GetAppIcon />}
    >
    </Chip>;
};

export default exportExcel;