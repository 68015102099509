import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
    const authenticated = rest.userState.accessToken;

  return (
    <Route
      { ...rest }
      render={ (props) => {
        return !authenticated
          ? <Component { ...props } 
         />
          : <Redirect to={{
            pathname: props.history.location.state && props.history.location.state.from.pathname,
            state: { from: props.location }
          }} />;
      } }
    />
  );
};

export default PublicRoute;
