import httpService from './httpService';

const getQuotes = (criteria = {}) => {
  return httpService.FetchQuoteList(criteria);
};

const getQuoteById = (criteria = {}) => {
  return httpService.FetchQuoteInfo(criteria);
};


const editQuote = (id, data) => {
  return httpService.UpdateQuote({}, data);
};

const printQuote = (id) => {
  return httpService.PrintQuoteInfo(id)
};

const exportQuote = (id) => {

  return httpService.ExportQuoteInfo(id)
};

const importQuote = (id, data) => {
  
  let fd = new FormData();

  fd.append('file', data);
  
  return httpService.ImportQuoteInfo(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};

const addQuoteAttachment = (id, data) => {
  let fd = new FormData();
 
  data.map((file, index) => {
    return fd.append('file', file)
  });

  return httpService.CreateQuoteAttachment(
    id, fd,

    {
      headers: {
        'Content-Type': 'multipart/form-data',

      },

    }
  );
};

const getQuoteAttachment = (criteria = {}) => {
  return httpService.FetchQuoteAttachment(criteria, { responseType: 'arraybuffer' });
};

const deleteQuoteAttachment = (data) => {
  return httpService.DeleteQuoteAttachment(data);
};


const reviseQuote = (id) => {
  return httpService.ReviseQuote(id);
};

const syncQuote = (id) => {
  return httpService.SyncQuote(id);
};

const deleteQuote = (id) => {
  return httpService.DeleteQuote(id);
};

const submitQuote = (data) => {
  return httpService.SubmitQuote(data);
};

const createOrderFromQuote = (data) => {

  return httpService.CreateOrderFromQuote(data);
};


export { getQuotes, getQuoteById, deleteQuote, addQuoteAttachment, printQuote, exportQuote, getQuoteAttachment, deleteQuoteAttachment, reviseQuote, editQuote, submitQuote, importQuote, createOrderFromQuote, syncQuote };
