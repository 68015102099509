import React, { useState } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import Chip from '@mui/material/Chip';
import checkFileInputs from '../../helpers/checkFileInputs';
import Message from '../messages';
import uuid from "../../helpers/Uuid";

const FileUploader = ({ title, accept, handleImport, disabled=false, multiple=false }) => {

  const [messages, setMessages] = useState([]);


  const onMessageClose = uuid => {
    setMessages(oldMessages => oldMessages.filter(message => message.id !== uuid));
  }

  const onErrorMessage = message => {
    const newMessage = {
      id: uuid(),
      message
    };
    setMessages(oldMessages => [...oldMessages, newMessage])
  }


  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {

    const filesUploaded = event.target.files;

    const checkedFiles = checkFileInputs(filesUploaded, accept);
	const acceptedFiles = checkedFiles.acceptedFiles;
	const rejectedFiles = checkedFiles.rejectedFiles;

    rejectedFiles.length && rejectedFiles.map(file => {
      onErrorMessage('File ' + file + ' was rejected. File type not supported.');
    }
    );


    let filesSize = 0;
    for (let i = 0; i < filesUploaded.length; i++) {
      filesSize = filesSize + filesUploaded[i].size / 1024 / 1024;

    };

    // if (filesSize > 50) {
    //   alert('File size exceeds 50 MB');

    // } else {
    //   handleImport(multiple ? filesUploaded : filesUploaded[0]);
    // }

    if (acceptedFiles.length > 0 && filesSize <= 50) {
      handleImport(multiple ? acceptedFiles : acceptedFiles[0]);
    }
    else if (filesSize > 50) {
      alert('File size exceeds 50 MB');
    }

    event.target.value = null;
  };


  return <>
    <Message
      messages={messages}
      onMessageClose={onMessageClose}
    />
    <label htmlFor="icon-button-file" className='excel-import'>
      <Chip
        onClick={handleClick}
        aria-label={title}
        component="span"
        size="medium"
        label={title}
        style={{ color: '#000000', opacity: !disabled?'55%':'20%', marginRight: '2px', marginLeft: '2px' }}
        variant="outlined"
        icon={<PublishIcon />}
      >
      </Chip>
    </label>
    <input
      type="file"
      multiple={multiple}
      accept={accept}
      disabled={disabled}
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
    />

  </>;
};

export default FileUploader;