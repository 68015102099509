import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React from 'react';
import './messages.scss';

function TransitionRight(props) {
  return <Slide { ...props } direction='left' />;
}

const Message = (props) => {
  return (
    <Snackbar
      anchorOrigin={ {
        vertical: 'top',
        horizontal: props.horizontal?props.horizontal:'right',
      }}
      open={ !!props.messages.length }
      TransitionComponent={ TransitionRight }
    >
        <div className={'messages-wrapper'}>
            {
                props.messages.map((message) => {
                    setTimeout(() => props.onMessageClose(message.id), 6000);

                    return (
                        <Alert
                            key={message.id}
                            elevation={ 6 }
                            onClose={ () => props.onMessageClose(message.id)}
                            severity={props.severity?props.severity:'error'}
                        >
                            <div style={{whiteSpace: 'pre'}}>{message.message}</div>
                        </Alert>
                    )
                })
            }
        </div>
    </Snackbar>
  );
};

export default Message;
