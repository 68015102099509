import OpenAPIClientAxios from 'openapi-client-axios';
import {EventBus} from "./eventBus";

const openApiConfig = require('./../bff-api-schema.json');
const api = new OpenAPIClientAxios({definition: openApiConfig});
const httpService = api.initSync();
const {REACT_APP_CLUSTER_URL, REACT_APP_KUBERNETES_AUTH_KEY,REACT_APP_OAUTH_URL} = process.env;

httpService.defaults.baseURL = REACT_APP_CLUSTER_URL + '/bff';
httpService.defaults.headers.common['Content-Type'] = 'application/json;charset=utf8';
httpService.defaults.extra = {};
httpService.injectHeader = (headerKey, headerValue) => {
    Object.assign(
        httpService.defaults.headers,
        {
            ...httpService.defaults.headers,
            ...{[headerKey]: headerValue},
        },
    );
};
httpService.removeHeader = (headerKey) => {
    if (httpService.defaults.headers && httpService.defaults.headers[headerKey]) {
        delete httpService.defaults.headers[headerKey];
    }
};

const shouldIntercept = (error) => {
    return error.response.status === 401 && error.response?.data?.error === 'invalid_token';
};

const setTokenData = (tokenData = {}, axiosClient) => {

};

const handleTokenRefresh = () => {
    return new Promise((resolve, reject) => {
        fetch(REACT_APP_OAUTH_URL + `/o/token/`,
            {
                headers: {
                    'Authorization': `Basic ${REACT_APP_KUBERNETES_AUTH_KEY}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'POST',
                body: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': httpService.defaults.extra.refresh_token
                })
            })
            .then(async res => {
                if ([400, 401].includes(res.status)) {
                    reject({...res, refreshExpired: true});
                } else {
                    const data = await res.json();
                    const {access_token, refresh_token} = data;
                    httpService.removeHeader('Authorization');
                    httpService.injectHeader('Authorization', `Bearer ${access_token}`);
                    httpService.defaults.extra.refresh_token = refresh_token;
                    EventBus.publish('jwt-token-update', {
                        accessToken: access_token,
                        refreshToken: refresh_token
                    });
                    resolve(data);
                }
            })
            .catch((err) => {
                reject(err);
            })
    });
};

const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;
};

const onNewRequestStart = (req) => {
    requestsPending += 1;
    EventBus.publish('loading-state', true);
};
const onRequestResolve = (res) => {
    if (requestsPending > 1) {
        requestsPending -= 1;
    } else {
        requestsPending = 0;
    }

    if (requestsPending === 0) {
        EventBus.publish('loading-state', false);
    }
};


let isRefreshing = false;
let failedQueue = [];
let customOptions = {};
let requestsPending = 0;

const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
};
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const interceptor = (error) => {
    if (!options.shouldIntercept(error) || error.config._retry || error.config._queued) {
        const errorMessage = error.response?.data?.error_description || "Something went wrong, please try again."
        EventBus.publish('error-message', errorMessage);
        onRequestResolve();

        return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
        return new Promise(function (resolve, reject) {
            failedQueue.push({resolve, reject})
        }).then(token => {
            originalRequest._queued = true;
            options.attachTokenToRequest(originalRequest, token);
            onRequestResolve();
            return httpService.request(originalRequest);
        }).catch(err => {
            // Ignore refresh token request's "err" and return actual "error" for the original request
            onRequestResolve();
            return Promise.reject(error);
        })
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
        options.handleTokenRefresh.call(options.handleTokenRefresh)
            .then((tokenData) => {
                options.setTokenData(tokenData, httpService);
                options.attachTokenToRequest(originalRequest, tokenData.access_token);
                processQueue(null, tokenData.access_token);
                resolve(httpService.request(originalRequest));
            })
            .catch((err) => {
                if (err.refreshExpired) {
                    EventBus.publish('jwt-token-update', {
                        accessToken: null,
                        refreshToken: null
                    });
                }
                processQueue(err, null);
                reject(err);
            })
            .finally(() => {
                onRequestResolve();
                isRefreshing = false;
            })
    });
};

/**
 *  Add extra axios flag ('no-loading-indicator'), in order to bypass the interception process
 *  accordingly (e.x chat messaging should not display request-loader on each message send)
 */
httpService.interceptors.request.use(req => {
    if(!req?.extra?.['no-loading-indicator']) {
        onNewRequestStart(req);
    }

    return req;
}, undefined);

httpService.interceptors.response.use((res => {
    if(!res?.extra?.['no-loading-indicator']) {
        onRequestResolve(res);
    }

    return res;
}), interceptor);

export default httpService;
export { handleTokenRefresh }
