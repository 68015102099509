import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from "./styles";

const  ChartWithGrid = (props) => {
    const classes = useStyles();

    useEffect(() => {

        const data = props.data;
        const label = props.labels;
        const con = document.getElementById('myChartNoAxesVertical');
        let c =  new Chart(con, {
            type: 'bar',
            plugins: [ChartDataLabels],
            data: {
                labels: label,
                datasets: [{
                    data: data,
                    borderWidth: 2,
                    borderRadius: 5,
                    borderSkipped: false,
                    borderColor: 'rgba(124, 188, 255, 1)',
                    // label: "Rfq's",
                    backgroundColor: 'rgba(124, 188, 255, 0.5)',
                    // borderColor: [
                    //     'rgba(255, 99, 132, 1)',
                    //     'rgba(54, 162, 235, 1)',
                    //     'rgba(255, 206, 86, 1)',
                    //     'rgba(75, 192, 192, 1)',
                    //     'rgba(153, 102, 255, 1)',
                    //     'rgba(255, 159, 64, 1)'
                    // ],
                    // borderWidth: .5,
                    lineWidth: .3
                }]
            },
            options: {
                responsive: true,
                indexAxis: 'y',
                layout: {
                    padding: {
                        right: 30
                    }
                },
                // maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        formatter: (value, ctx) => {
                            return  value.toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0, style: 'currency', currency: 'USD' }); 
                        },
                        anchor: 'end',
                        align: 'end',
                        labels: {
                          value: {
                            color: 'black'
                          }
                        }
                    }
                },
                scales:
                {
                    x: {
                        display: true,
                        beginAtZero: true,
                        grid: {
                            display: true,

                        }, 
                        // border: {
                        //     display: false
                        // },
                        ticks: {
                            autoSkip: false
                        }
                    },
                    y: {
                        display: true,
                        
                    }
                }
            }
        });
        return () => { c.destroy(); }
    }, [props.stats]);

    const themes = {
        red: {
            fontColor: '#ff4861',
            progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
        },
        springGreen: {
            fontColor: '#00ff7f',
            progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
        },
        green: {
            fontColor: '#b8e986',
            progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
        },
        blue: {
            fontColor: '#48b5ff',
            progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
        },
    };

    return (
        <section
            className={classes.chartRoot}
        >
                <Grid item xs={12}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <canvas
                        id="myChartNoAxesVertical"
                        width={'650px'}
                    // height={'100%'}
                    >
                    </canvas>
                </Grid>
        </section>
    )
};

export default ChartWithGrid;