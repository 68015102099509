import React from "react";
import TextField from "@mui/material/TextField";
import useStyles from './styles';

export default function OutlineDisplay({label,value}) {
    
    const classes = useStyles()
    return (
        <TextField
            fullWidth
            disabled
            multiline
            className={classes.disableBlackColor}
            label={label}
            value={value||''}
            variant="outlined"
            />
            );
};