import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersCountriesRegion, newCountriesRegion } from '../../helpers/countriesRegionConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllRegionCountries,  addOriginalRegionCountry, deleteOriginalRegionData } from '../../services/originalRegionService';
import FilterCountries from "../../components/filterCountries";
import { FilterByText } from '../../components/filterByText';
import './_countriesRegion.scss';
import t from '../../helpers/languages';

const CountriesRegion = (props) => {

  const countryInitObject = () => {
    return  {} ;
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('countriesRegionFilter');

  const [countriesRegionState, setCountriesRegionState] = useState({
    countriesRegion: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    countryEditIndex: -1,
    countryEditObject: countryInitObject(),
    countryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};

  let regionId = null;

  if (!props.autonomousPage) {
    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;
    m = regex.exec(pathName);
    regionId = m[0];
  }


  filtersData = {
    ...countriesRegionState.filters,
    search: filters && filters.search,
    isUnresolved: filters && filters.isUnresolved,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllRegionCountries(regionId,filtersData).then(res => {
      const { results } = res.data;

      setCountriesRegionState({
        ...countriesRegionState,
        countriesRegion: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setCountriesRegionState({
      ...countriesRegionState,
      isLoading: true
    });
    const newCriteria = {
      ...countriesRegionState.filters,
      per_page: countriesRegionState.page.per_page,
      page: newPage + 1
    };
    getAllRegionCountries(regionId, newCriteria).then(res => {
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
        countriesRegion: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setCountriesRegionState({
      ...countriesRegionState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...countriesRegionState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllRegionCountries(regionId, newCriteria).then(res => {
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
        countriesRegion: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
      });
    });
  };

  const handleEditCountry = (event, countryIndex) => {

    event.stopPropagation();

    const countryEditObject = Object.assign({}, countriesRegionState.countriesRegion[countryIndex]);


    setCountriesRegionState({
      ...countriesRegionState,
      countryEditIndex: countryIndex,
      countryEditObject: countryEditObject
    });

  };

  const onEditableCountryChange = (event, property) => {
   
    const  newValue = event.target.value;

    const newEditCountryItem = Object.assign({}, countriesRegionState.countryEditObject);
    newEditCountryItem[property] = newValue;

    setCountriesRegionState({
      ...countriesRegionState,
      countryEditObject: newEditCountryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveCountry = (event, props) => {
    event.preventDefault();
    setCountriesRegionState({
      ...countriesRegionState,
      isLoading: true
    });
    const newEditCountryItem = Object.assign({}, countriesRegionState.countryEditObject);

    if (validator.current.allValid()) {
      editCountry(newEditCountryItem.id, newEditCountryItem)
        .then(re => {
          const editedCountry = re.data;
          setCountriesRegionState(oldCountriesRegion => {
            return {
              ...oldCountriesRegion,
              countriesRegion: oldCountriesRegion.countriesRegion.map(country => {
                return country.id === editedCountry.id
                  ? re.data : country
              }),
              countryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCountriesRegionState({
            ...countriesRegionState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditCountry = (e, index) => {
    setCountriesRegionState({
      ...countriesRegionState,
      countryEditIndex: -1
    })
  };

  const handleDeleteCountry = (event, index) => {


    const countryForDeletion =  {};
    
    countryForDeletion.countries=[{
      countryId: countriesRegionState.countriesRegion[index].countryId,
      name: countriesRegionState.countriesRegion[index].name,
    }];


    setCountriesRegionState({
      ...countriesRegionState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Country',
        onCancel: () => {
          setCountriesRegionState({
            ...countriesRegionState,
            confirmDialog: {
              ...countriesRegionState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOriginalRegionData(regionId,countryForDeletion).then(() => {
            const newCountriesRegion = [...countriesRegionState.countriesRegion];
            newCountriesRegion.splice(index, 1);
            setCountriesRegionState({
              ...countriesRegionState,
              countriesRegion: newCountriesRegion,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewCountry = (e) => {
    e.preventDefault();

    setCountriesRegionState({
      ...countriesRegionState,
      countryCreateObject: newCountriesRegion()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewCountry = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newCountriesRegion = {};
    newCountriesRegion.countries = [{
      countryId: countriesRegionState.countryCreateObject.country.id,
      name: countriesRegionState.countryCreateObject.country.name,
    }];

    if (validator.current.allValid()) {
      addOriginalRegionCountry(regionId, newCountriesRegion)
        .then(res => {
          setCountriesRegionState({
            ...countriesRegionState,
            countriesRegion: [...countriesRegionState.countriesRegion, res.data],
            countryCreateObject: null,
            countryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCountriesRegionState({
            ...countriesRegionState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewCountryObject = (e, property) => {

    const newValue = e.target.value;

    const newEditCountryItem = Object.assign({}, countriesRegionState.countryCreateObject);
    newEditCountryItem[property] = newValue;

    setCountriesRegionState({
      ...countriesRegionState,
      countryCreateObject: newEditCountryItem
    });
  };

  const selectedCountry = (data, action) => {

    const newEditCountryItem = Object.assign({},
      action === 'edit'
        ? countriesRegionState.countryEditObject
        : countriesRegionState.countryCreateObject
    );

    newEditCountryItem.country = data.country;

 
    setCountriesRegionState({
      ...countriesRegionState,
      [action === "edit" ? "countryEditObject" : "countryCreateObject"]:
       newEditCountryItem,
    });
  };

  const filterData = (data) => {
    setCountriesRegionState({
      ...countriesRegionState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...countriesRegionState.filters,
      search: data && data.searchText,
    };

    getAllRegionCountries(regionId,filtersData).then(res => {
      const { results } = res.data;

      setCountriesRegionState({
        ...countriesRegionState,
        countriesRegion: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('countriesRegionFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesRegionState({
        ...countriesRegionState,
        isLoading: false,
      });
    });

  };


  const tableFilterComponent = () => {
    return <FilterByText
      searchText={(filters && filters.searchText) ? filters.searchText : countriesRegionState.filters.search}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersCountriesRegion}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(countriesRegionState.countriesRegion) && countriesRegionState.countriesRegion.map((country, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (countriesRegionState.countryEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{country.name}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCountries
                          country={countriesRegionState.countryEditObject.country || null}
                          getSelectedCountry={(data) => selectedCountry(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: false,
                    callback: () => null
                  },
                  delete: {
                    enabled: countriesRegionState.countryEditIndex !== index,
                    callback: (e) => handleDeleteCountry(e, index)
                  },
                  close: {
                    enabled: countriesRegionState.countryEditIndex === index,
                    callback: (e) => handleCloseEditCountry(e, index)
                  },
                  save: {
                    enabled: countriesRegionState.countryEditIndex === index,
                    callback: (e) => handleSaveCountry(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          countriesRegionState.countryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                  <FilterCountries
                    country={countriesRegionState.countryCreateObject.country || null}
                    getSelectedCountry={(data) => selectedCountry(data, 'create')}
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setCountriesRegionState({ ...countriesRegionState, countryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewCountry()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('COUNTRY_REGIONS')}
        pagination={{
          total: countriesRegionState.page.total || 0,
          page: countriesRegionState.page.page - 1 || 0,
          perPage: countriesRegionState.page.per_page || 10,
          count: countriesRegionState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewCountry}
        newRowBtnEnabled={!countriesRegionState.countryCreateObject}
        confirmDialog={countriesRegionState.confirmDialog.message.length > 0 ? countriesRegionState.confirmDialog : countriesRegionState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={countriesRegionState.isLoading}
      />
    </>
  );
};


export default CountriesRegion;
