import React, { useCallback } from "react";
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCodingSystems } from '../../services/codingSystemsService';
import useStyles from './style';

export default function ΙnlineEditAutocompleteCodingSystems({
    codingSystem: initialCodingSystem,
    getSelectedCodingSystem,
    saved,
    errorMessage
}) {

    const empty = {
        id: null,
        name: null
    };

    const [codingSystem, setCodingSystem] = React.useState(initialCodingSystem !== null ? initialCodingSystem : empty);
    const [openCodingSystem, setOpenCodingSystem] = React.useState(false);
    const [codingSystems, setCodingSystems] = React.useState(initialCodingSystem !== null ? [initialCodingSystem] : [empty]);
    const [search, setSearch] = React.useState('');

    const handleSpanClick = useCallback(() => setOpenCodingSystem(true), [
        setOpenCodingSystem
    ]);
    const classes = useStyles();
    const handleInputChange = (e, fetchType) => {

        const value = e && e.target && e.target.value;
        if (value && value.length >= 3 && search !== value) {
            setSearch(value);
            debounceFn(value)
        };
    };
    const randomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const handleDebounceFn = (val) => {
        codingSystemSearch({
            search: val,
        })
    };
    const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

    const onCodingSystemSelectionChange = (event, values) => {
        setCodingSystem(values ? values : []);
        const selectedCodingSystem = {
            codingSystem: values ? values : [],
        };
        getSelectedCodingSystem(selectedCodingSystem);

    };

    const codingSystemSearch = (criteria) => {

        return getAllCodingSystems(criteria).then((res) => {
            setCodingSystems(res.data);
        }).catch((error) => {
        })
    }

    return (
        <span className="inline-text">
            {openCodingSystem || !saved ? (
                <span>
                    <Autocomplete
                        // set the width to the input length multiplied by the x height
                        // it's not quite right but gets it close
                        id={"find-codingSystem" + randomNumber(0, 999)}
                        fullWidth
                        open={openCodingSystem}
                        onOpen={() => {
                            setOpenCodingSystem(true);
                        }}
                        onClose={() => {
                            setOpenCodingSystem(false);
                        }}
                        onInputChange={e => handleInputChange(e)}
                        onChange={onCodingSystemSelectionChange}
                        value={codingSystems.find(v => v.id === codingSystem.id) || null}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
                        options={codingSystems !== null ? codingSystems : null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                label={t('CODING')}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps
                                }}
                                error={!!errorMessage}
                                helperText={errorMessage}
                            />
                        )}
                    />
                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openCodingSystem ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <TextField
                        fullWidth
                        id="outlined-disabled-company"
                        label={t('CODING')}
                        defaultValue={codingSystem && codingSystem.name}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </span>)}
        </span >
    );
};