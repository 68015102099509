import React, { useState, useCallback } from 'react';
// import { makeStyles } from '@mui/styles';
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import './_searchByTextTableFilter.scss';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing('1px'),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing('2px'),
//   },
// }));

export const SearchByTextTableFilter = ({ searchText: initialSearchText, label = "Search", getFilterData }) => {
  // const classes = useStyles();

  const [searchText, setSearchText] = useState(initialSearchText || '');


  const handleChange = (e, property) => {
    const value = e && e.target && e.target.value;
    setSearchText(value);
    if (value && value.length >= 3 && searchText !== value) {
      debounceFn(value);
    } else if (value === '') {
      debounceFn(value);
    }
  };
  

  const handleDebounceFn = (val) => {
    const filterData = {
      searchText: val
    };
    getFilterData(filterData);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);
  return (
    <TextField
      value={searchText}
      //autoFocus

      variant="outlined"
      // className={classes.formControl}
      onChange={e => handleChange(e, 'search')}
      name="search"
      label={label}
      InputProps={{
    
        endAdornment: searchText && (
          <IconButton aria-label="clear" onClick={() => handleChange("")} size="large"><ClearIcon fontSize='small'/></IconButton>
        )
      }}
    />
  );
}