import React, { useCallback, useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllContractsPackages } from '../../services/contractsService';
import InputAdornment from '@mui/material/InputAdornment';

export default function FilterContractsPackages({ 
  package: initialContractsPackage, 
  contracts:initialContracts=null,
  product: initialProduct=null, 
  inputAdornment=null,
  onFocusa=null,
  inputRefa=null,
  getSelectedContractsPackage }) {

  const [contractsPackage, setContractsPackage] = React.useState(initialContractsPackage && initialContractsPackage.id !== null ? initialContractsPackage : 0);
  const [contractsPackages, setContractsPackages] = React.useState(initialContractsPackage && initialContractsPackage.id !== null ? [initialContractsPackage] : []);
  const [opencontractsPackage, setOpencontractsPackage] = React.useState(false);
  
  const filterData = {
    contracts: initialContracts,
  };

  useEffect(() => {
    if (initialContracts) {
      getAllContractsPackages({productId:initialProduct},filterData).then((res) => {
        setContractsPackages(res.data);
      }).catch((error) => {
      })
    } else {
      setContractsPackage(0);
      setContractsPackages([]);
    }
  }, [initialProduct]);


  const onContractsPackageSelectionChange = (event, values) => {
    setContractsPackage(values ? values : '');
    const selectedContractsPackage = {
      unit: values ? values : null, // send null in case the user deletes the data.
    };
    getSelectedContractsPackage(selectedContractsPackage);
  };

  return (
    <div>
      <Autocomplete
        id="find-contractscontractsPackage"
        fullWidth
        open={opencontractsPackage}
        onOpen={() => {
          setOpencontractsPackage(true);
        }}
        onClose={() => {
          setOpencontractsPackage(false);
        }}
        onFocus={onFocusa}
        disabled={!initialProduct}
        onChange={onContractsPackageSelectionChange}
        value={contractsPackages.find(v => v.id ===contractsPackage.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={contractsPackages !== null ? contractsPackages : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('PACKAGE')}
            variant="outlined"
            inputRef={inputRefa}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                inputAdornment&&<InputAdornment position="start">
                 {inputAdornment + ' /'}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}