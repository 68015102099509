import httpService from './httpService';

const getRfqItems = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchRFQItems(criteria);

};
const deleteRfqItem = (id) => {
  return httpService.DeleteRFQItem(id);
};

const edit = (id, data) => {
   return httpService.UpdateRFQItem({}, data);
};

const add = (data) => {
  return httpService.CreateRFQItem({},data);
};

export { getRfqItems, edit, add, deleteRfqItem };
