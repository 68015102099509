import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersProductDictionary, tableHeadersProductDictionaryGlobal, newProductDictionary } from '../../helpers/productsDictionaryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterProducts from "../../components/filterProducts";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllProductDictionariesInfo, editProductDictionary, deleteProductDictionary, addProductDictionary } from '../../services/productsDictionaryService';
import { DictionaryTableFilter } from '../../components/dictionaryTableFilter';
import FilterCompanies from "../../components/filterCompanies";
import './_ProductDictionaries.scss';
import t from '../../helpers/languages';

const ProductDictionaries = (props) => {

  const productDictionaryInitObject = () => {
    return {
      id: '?',
      company: { name: '', id: '' },
      productAlias: null,
      productCode: null,
      original: null
    }
  };

  const classes = useStyles();

  const filters = localStoreGetObj('productDictionaryFilter');

  const [productsDictionaryState, setproductDictionariesState] = useState({
    productsDictionary: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    productDictionaryEditIndex: -1,
    productDictionaryEditObject: productDictionaryInitObject(),
    productDictionaryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};



  if (props.autonomousPage) {
    filtersData = {
      ...productsDictionaryState.filters,
      companyId: filters && filters.company && filters.company.id,
      search: filters && filters.product,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: productsDictionaryState.page.order_type,
      order_by: productsDictionaryState.page.order_by,
    };
  }
  else {
    filtersData = {
      ...productsDictionaryState.filters,
      companyId: props.companyId,
      search: filters && filters.product,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: productsDictionaryState.page.order_type,
      order_by: productsDictionaryState.page.order_by,
    };
  }


  useEffect(function () {
    getAllProductDictionariesInfo(filtersData).then(res => {

      const { results } = res.data;
      setproductDictionariesState({
        ...productsDictionaryState,
        productsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === productsDictionaryState.page.order_by && productsDictionaryState.page.order_type === 'desc' ? 'asc' : 'desc';

    setproductDictionariesState({
      ...productsDictionaryState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllProductDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setproductDictionariesState({
        ...productsDictionaryState,
        productsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setproductDictionariesState({
      ...productsDictionaryState,
      isLoading: true
    });
    const newCriteria = {
      ...productsDictionaryState.filters,
      per_page: productsDictionaryState.page.per_page,
      page: newPage + 1
    };
    getAllProductDictionariesInfo(newCriteria).then(res => {
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
        productsDictionary: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to product id gia mba member
    setproductDictionariesState({
      ...productsDictionaryState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...productsDictionaryState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllProductDictionariesInfo(newCriteria).then(res => {
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
        productsDictionary: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
      });
    });
  };

  const handleEditProductDictionary = (event, productDictionaryIndex) => {

    event.stopPropagation();

    const productDictionaryEditObject = Object.assign({}, productsDictionaryState.productsDictionary[productDictionaryIndex]);


    setproductDictionariesState({
      ...productsDictionaryState,
      productDictionaryEditIndex: productDictionaryIndex,
      productDictionaryEditObject: productDictionaryEditObject
    });

  };

  const onEditableProductDictionaryChange = (event, property) => {

    const newValue = event.target.value;

    const newEditProductDictionaryItem = Object.assign({}, productsDictionaryState.productDictionaryEditObject);
    newEditProductDictionaryItem[property] = newValue;

    setproductDictionariesState({
      ...productsDictionaryState,
      productDictionaryEditObject: newEditProductDictionaryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveProductDictionary = (event, props) => {
    event.preventDefault();
    setproductDictionariesState({
      ...productsDictionaryState,
      isLoading: true
    });
  
    

    const simpleCompanyUser = {
      id: props.companyId,
    }

    let createNull = {
      id: null,
    };
    if (productsDictionaryState.productDictionaryEditObject.company?.id !== ''&&productsDictionaryState.productDictionaryEditObject.company!=='') {
      createNull = productsDictionaryState.productDictionaryEditObject.company;
    }

    const newEditProductDictionaryItem = Object.assign({}, productsDictionaryState.productDictionaryEditObject);
    
    props.autonomousPage ? newEditProductDictionaryItem.company = createNull : newEditProductDictionaryItem.company = simpleCompanyUser;

    if (validator.current.allValid()) {
      editProductDictionary(newEditProductDictionaryItem.id, newEditProductDictionaryItem)
        .then(re => {
          const editedProductDictionary = re.data;
          setproductDictionariesState(oldproductDictionaries => {
            return {
              ...oldproductDictionaries,
              productsDictionary: oldproductDictionaries.productsDictionary.map(productDictionary => {
                return productDictionary.id === editedProductDictionary.id
                  ? re.data : productDictionary
              }),
              productDictionaryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setproductDictionariesState({
            ...productsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditProductDictionary = (e, index) => {
    setproductDictionariesState({
      ...productsDictionaryState,
      productDictionaryEditIndex: -1
    })
  };

  const handleDeleteProductDictionary = (event, index) => {

    const productDictionaryForDeletion = productsDictionaryState.productsDictionary[index];
    setproductDictionariesState({
      ...productsDictionaryState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete ProductDictionary',
        onCancel: () => {
          setproductDictionariesState({
            ...productsDictionaryState,
            confirmDialog: {
              ...productsDictionaryState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteProductDictionary(productDictionaryForDeletion.id).then(() => {
            const newproductDictionaries = [...productsDictionaryState.productsDictionary];
            newproductDictionaries.splice(index, 1);
            setproductDictionariesState({
              ...productsDictionaryState,
              productsDictionary: newproductDictionaries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewProductDictionary = (e) => {
    e.preventDefault();

    const newProductDict = newProductDictionary();

    //newProductDict.company={id:props.companyId};

    setproductDictionariesState({
      ...productsDictionaryState,
      productDictionaryCreateObject: newProductDict
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewProductDictionary = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newProductDictionary = {};

    const simpleCompanyUser = {
      id: props.companyId,
    }

    let createNull = null;
    if (productsDictionaryState.productDictionaryCreateObject.company|| !productsDictionaryState.productDictionaryCreateObject.company.length === 0 ) {
      createNull = productsDictionaryState.productDictionaryCreateObject.company;
    }

    props.autonomousPage ? newProductDictionary.company = createNull : newProductDictionary.company = simpleCompanyUser;
    newProductDictionary.productAlias = productsDictionaryState.productDictionaryCreateObject.productAlias;
    newProductDictionary.productCode = productsDictionaryState.productDictionaryCreateObject.productCode;
    newProductDictionary.original = productsDictionaryState.productDictionaryCreateObject.original;

    if (validator.current.allValid()) {
      addProductDictionary(newProductDictionary)
        .then(res => {
          setproductDictionariesState({
            ...productsDictionaryState,
            productsDictionary: [...productsDictionaryState.productsDictionary, res.data],
            productDictionaryCreateObject: null,
            productDictionaryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setproductDictionariesState({
            ...productsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewProductDictionaryObject = (e, property) => {
    const newValue = e.target.value;

    const newEditProductDictionaryItem = Object.assign({}, productsDictionaryState.productDictionaryCreateObject);
    newEditProductDictionaryItem[property] = newValue;

    setproductDictionariesState({
      ...productsDictionaryState,
      productDictionaryCreateObject: newEditProductDictionaryItem
    });
  };

  const filterData = (data) => {
    setproductDictionariesState({
      ...productsDictionaryState,
      isLoading: true
    });

    let filtersData = '';
    if (props.autonomousPage) {
      filtersData = {
        ...productsDictionaryState.filters,
        search: data && data.searchText,
        per_page: criteria.per_page,
        page: 1,
        companyId: data && data.company && data.company.id
      };
    } else {
      filtersData = {
        ...productsDictionaryState.filters,
        search: data && data.searchText,
        companyId: props.companyId,
        per_page: criteria.per_page,
        page: 1,
      };
    }

    getAllProductDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;

      setproductDictionariesState({
        ...productsDictionaryState,
        productsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('productDictionaryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setproductDictionariesState({
        ...productsDictionaryState,
        isLoading: false,
      });
    });

  };


  const selectedCompany = (data, action) => {

    const newEditProductDictionaryItem = Object.assign({},
      action === 'edit'
        ? productsDictionaryState.productDictionaryEditObject
        : productsDictionaryState.productDictionaryCreateObject
    );


    newEditProductDictionaryItem.company = data.company;


    setproductDictionariesState({
      ...productsDictionaryState,
      [
        action === 'edit'
          ? 'productDictionaryEditObject'
          : 'productDictionaryCreateObject'
      ]: newEditProductDictionaryItem
    });
  };

  const selectedOriginal = (data, action) => {

    const newEditProductDictionaryItem = Object.assign({},
      action === 'edit'
        ? productsDictionaryState.productDictionaryEditObject
        : productsDictionaryState.productDictionaryCreateObject
    );

    newEditProductDictionaryItem.original = data.product;


    setproductDictionariesState({
      ...productsDictionaryState,
      [
        action === 'edit'
          ? 'productDictionaryEditObject'
          : 'productDictionaryCreateObject'
      ]: newEditProductDictionaryItem
    });
  };

  const tableFilterComponent = () => {
    return <DictionaryTableFilter
      searchText={(filters && filters.product) ? filters.product : productsDictionaryState.filters.product}
      company={(filters && filters.company) ? filters.company : productsDictionaryState.filters.company}
      autonomousPage={props.autonomousPage}
      getFilterData={filterData}
      filterLabel={'Product'}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.autonomousPage ? tableHeadersProductDictionaryGlobal : tableHeadersProductDictionary}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(productsDictionaryState.productsDictionary) && productsDictionaryState.productsDictionary.map((productDictionary, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (productsDictionaryState.productDictionaryEditIndex !== index) && index !== '?'
                  ? <>
                    {props.autonomousPage && <TableCell>{productDictionary.company?.name}</TableCell>}
                    <TableCell>{productDictionary.productAlias}</TableCell>
                    <TableCell>{productDictionary.productCode}</TableCell>
                    <TableCell>{productDictionary.original?.name}</TableCell>
                  </> :
                  <>
                    {props.autonomousPage && (
                      <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={productsDictionaryState.productDictionaryEditObject.company || null}
                            getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                            buyer={true}
                            errorMessage={null}
                          />
                        </FormControl>
                      </TableCell>
                    )
                    }
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={productsDictionaryState.productDictionaryEditObject.productAlias || ''}
                          onChange={e => onEditableProductDictionaryChange(e, 'productAlias')}
                          onBlur={() => validator.current.showMessageFor('productAlias')}
                        />{validator.current.message('productAlias', productsDictionaryState.productDictionaryEditObject.productAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={productsDictionaryState.productDictionaryEditObject.productCode || ''}
                          onChange={e => onEditableProductDictionaryChange(e, 'productCode')}
                          onBlur={() => validator.current.showMessageFor('productCode')}
                        />{validator.current.message('productCode', productsDictionaryState.productDictionaryEditObject.productCode, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterProducts
                          product={productsDictionaryState.productDictionaryEditObject.original || null}
                          getSelectedProduct={(data) => selectedOriginal(data, 'edit')}
                          errorMessage={null}
                        />
                        {validator.current.message('original', productsDictionaryState.productDictionaryEditObject.original, 'required')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: productsDictionaryState.productDictionaryEditIndex !== index,
                    callback: (e) => handleEditProductDictionary(e, index)
                  },
                  delete: {
                    enabled: productsDictionaryState.productDictionaryEditIndex !== index,
                    callback: (e) => handleDeleteProductDictionary(e, index)
                  },
                  close: {
                    enabled: productsDictionaryState.productDictionaryEditIndex === index,
                    callback: (e) => handleCloseEditProductDictionary(e, index)
                  },
                  save: {
                    enabled: productsDictionaryState.productDictionaryEditIndex === index,
                    callback: (e) => handleSaveProductDictionary(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          productsDictionaryState.productDictionaryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            {props.autonomousPage && (
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={productsDictionaryState.productDictionaryCreateObject.company || null}
                    getSelectedCompany={(data) => selectedCompany(data, 'create')}
                    buyer={true}
                    errorMessage={null}
                  />
                </FormControl>
              </TableCell>
            )}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={productsDictionaryState.productDictionaryCreateObject.productAlias || ''}
                  onChange={e => onEditNewProductDictionaryObject(e, 'productAlias')}
                  onBlur={() => validator.current.showMessageFor('productAlias')}
                />{validator.current.message('productAlias', productsDictionaryState.productDictionaryCreateObject.productAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={productsDictionaryState.productDictionaryCreateObject.productCode || ''}
                  onChange={e => onEditNewProductDictionaryObject(e, 'productCode')}
                  onBlur={() => validator.current.showMessageFor('productCode')}
                />{validator.current.message('productCode', productsDictionaryState.productDictionaryCreateObject.productCode, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProducts
                  product={productsDictionaryState.productDictionaryCreateObject.original}
                  getSelectedProduct={(data) => selectedOriginal(data, 'create')}
                  errorMessage={null}
                />
                {validator.current.message('original', productsDictionaryState.productDictionaryCreateObject.original, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setproductDictionariesState({ ...productsDictionaryState, productDictionaryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewProductDictionary()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PRODUCT_DICTIONARIES')}
        pagination={{
          total: productsDictionaryState.page.total || 0,
          page: productsDictionaryState.page.page - 1 || 0,
          perPage: productsDictionaryState.page.per_page || 10,
          count: productsDictionaryState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewProductDictionary}
        newRowBtnEnabled={!productsDictionaryState.productDictionaryCreateObject}
        confirmDialog={productsDictionaryState.confirmDialog.message.length > 0 ? productsDictionaryState.confirmDialog : productsDictionaryState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={productsDictionaryState.isLoading}
      />
    </>
  );
};


export default ProductDictionaries;
