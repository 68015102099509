import React, { useCallback } from "react";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllPorts } from '../../services/portsService';
import t from '../../helpers/languages';
import useStyles from './style';

export default function InlineEditAutocompletePorts({ port: initialPort, getSelectedPort, saved, error, req=false }) {

  const classes = useStyles();

  const empty = {
    id: null,
    name: null
  };

  const [openPort, setOpenPort] = React.useState(false);
  const [port, setPort] = React.useState(initialPort !== '-' && initialPort !== null && initialPort.id !== null ? initialPort : empty);
  const [Ports, setPorts] = React.useState([initialPort !== '-' && initialPort !== null && initialPort.id !== null ? initialPort : empty]);
  const [search, setSearch] = React.useState('');

  const handleSpanClick = useCallback(() => setOpenPort(true), [
    setOpenPort
  ]);

  const handleInputChange = (e, fetchType) => {

    const value = e && e.target && e.target.value;
    if (value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    const filterData = {
      search: val,
      isUnresolved:false,
    };
    portSearch(filterData);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onPortSelectionChange = (event, values) => {

    setPort(values ? values : empty);
    const selectedPort = {
      port: values ? values : null,
    };

    getSelectedPort(selectedPort);

  };



  const portSearch = (criteria) => {

    return getAllPorts(criteria).then((res) => {
      setPorts(res.data);
    }).catch((error) => {
    })
  }

  return (
    <span className="inline-text">
      {openPort || !saved ? (
        <span>
          <Autocomplete
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            id="find-port"
            fullWidth
            onOpen={() => {
              setOpenPort(true);
            }}
            onClose={() => {
              setOpenPort(false);
            }}
            openOnFocus={true}
            onInputChange={e => handleInputChange(e)}
            onChange={onPortSelectionChange}
            value={Ports.find(v => v.id === port.id) || null}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name !== null ? option.name : '')}
            options={Ports !== null ? Ports : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin='none'
                variant="outlined"
                error={error && !saved}
                label={t('PORT')}
                helperText={error && !saved && "Value not saved."}
                InputProps={req&&port&&port.id===null?{...params.InputProps,className:classes.input }:{...params.InputProps}}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openPort ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            id="outlined-disabled"
            label={t('PORT')}
            defaultValue={port && port.name}
            variant="outlined"
            InputProps={req&&port&&port.id===null?{className:classes.input+' '+'required-fields', readOnly: true }:{ readOnly: true}}
          />
        </span>)}
    </span >
  );
};
