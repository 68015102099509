import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme) => {
  return {
    logoutIcon: {
      fontSize: '18px',
      marginRight: '10px',
      color: '#646777',
    },
    menuItem: {
      width: '75px',
      fontWeight: '600',
      fontSize: '13px',
      color: '#646777',
    },
  };
});

export default useStyles;
