import { Grid } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
// import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@mui/material/Link';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import FilterVessels from "../../components/filterVessels";
import FilterPorts from "../../components/filterPorts";
import FilterCompanies from "../../components/filterCompanies";
import { tableHeadersBuyer, tableHeadersSupplier, newRfq } from '../../helpers/rfqsConfig';
import { urlParams } from "../../helpers/urlParams";
import { TableFilter } from '../../components/tableFilter';
import useStyles from './styles';
import FilterCatalogues from '../../components/filterCatalogues';
import TableActions from '../../components/TableActions/TableActions';
import { getRfqs, editRfq, deleteRfq, addRfq } from '../../services/rfqsService';
import { getAuthorizationMappings } from './../../services/authorizationsService';
import { getSupplyStatus } from '../../services/supplyStatusService';
import SimpleReactValidator from 'simple-react-validator';
import t from '../../helpers/languages';
import './_rfqs.scss';
import { StatusIndicator } from "../../common/StatusIndicator/StatusIndicator";
import dateToString from "../../helpers/dateToString";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";


const Rfqs = (props) => {
  const rfqInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      status: '',
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      rfq_id: '?',
      reference_number: '',
      due: '',
      supplier: { name: '', id: '' },
      items: '',
      buyer: { name: '', id: '' },
      isCompleted: ''
    }
  };

  const filters = localStoreGetObj('rfqFilter');

  const [rfqsState, setRfqsState] = useState({
    rfqs: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {
    },
    isLoading: true,
    rfqEditIndex: -1,
    rfqEditObject: rfqInitObject(),
    rfqCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const [rfqStatusState, setRfqStatusState] = useState({
    rfqStatuses: []
  });


  const userState = props.userState && props.userState.user;


  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10);
  !criteria.page && (criteria.page = 1);
  urlParams.setUrlParams(criteria);

  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...rfqsState.filters,
      port: filters && filters.port && filters.port.id,
      buyer: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...rfqsState.filters,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.company && filters.company.id,
      vessel:  filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }

  useEffect(function () {

    const statusName = { type: 'rfq' };
    getSupplyStatus(statusName).then(res => {
      setRfqStatusState({
        ...rfqStatusState.rfqStatuses,
        rfqStatuses: res.data,
      });

      getRfqs(filtersData).then(res => {
        const { results } = res.data;
        setRfqsState({
          ...rfqsState,
          rfqs: results,
          isLoading: false,
          page: res.data.page,
          filters: res.data.filters,
          authorizedActions: {
            ...getAuthorizationMappings('/rfqs', userState.userRole)

          }
        });
        urlParams.setUrlParams({
          page: res.data.page.page,
          per_page: res.data.page.per_page
        });
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
        });
      });
    }).catch((error) => { })

    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    event.preventDefault();

    const isAsc = property === rfqsState.page.order_by && rfqsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setRfqsState({
      ...rfqsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;
    filtersData.page = rfqsState.page.page;
    filtersData.per_page = rfqsState.page.per_page;
    getRfqs(filtersData).then(res => {
      const { results } = res.data;
      setRfqsState({
        ...rfqsState,
        rfqs: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqsState({
        ...rfqsState,
        isLoading: false,
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setRfqsState({
      ...rfqsState,
      isLoading: true,
    });

    const newCriteria = {
      ...rfqsState.filters,
      per_page: rfqsState.page.per_page,
      page: newPage + 1
    };
    getRfqs(newCriteria)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          rfqEditIndex: -1,
          rfqCreateObject: null,
          rfqs: res.data.results,
          page: res.data.page,
          filters: res.data.filters
        });
        urlParams.setUrlParams(res.data.page);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
        });
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...rfqsState.filters,
      page: 1,
      per_page: perPage,
    };

    getRfqs(newCriteria).then(res => {
      setRfqsState({
        ...rfqsState,
        rfqs: res.data.results,
        rfqEditIndex: -1,
        isLoading: false,
        rfqCreateObject: null,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqsState({
        ...rfqsState,
        isLoading: false,
      });
    });
  };

  const handleEditRfq = (event, rfqIndex) => {

    event.stopPropagation();
    const rfqEditObject = Object.assign({}, rfqsState.rfqs[rfqIndex]);

    if (!rfqEditObject.due) {
      rfqEditObject.due = new Date();
    }
    setRfqsState({
      ...rfqsState,
      rfqEditIndex: rfqIndex,
      rfqEditObject: rfqEditObject
    });
  };

  const onEditableRfqChange = (event, property) => {

    const newValue = property === 'due' ? event : event.target.value;

    const newEditRfqItem = Object.assign({}, rfqsState.rfqEditObject);
    newEditRfqItem[property] = newValue;

    setRfqsState({
      ...rfqsState,
      rfqEditObject: newEditRfqItem
    });
  };
  const simpleValidatorEdit = useRef(new SimpleReactValidator());
  const simpleValidatorCreate = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const handleSaveRfq = (event, props) => {
    event.preventDefault();

    const newEditRfqItem = Object.assign({}, rfqsState.rfqEditObject);
    if (!moment(rfqsState.rfqEditObject.due, "yyyy-MM-DD", true).isValid() && rfqsState.rfqEditObject.due !== null) {
      const due = new Date(rfqsState.rfqEditObject.due);
      newEditRfqItem.due = dateToString(moment(due))+'T00:00:00Z';
    } else if (moment(rfqsState.rfqEditObject.due, "yyyy-MM-DD", true).isValid()) {
      const due = new Date(rfqsState.rfqEditObject.due);
      newEditRfqItem.due = dateToString(moment(due))+'T00:00:00Z';
    }
    else {
      newEditRfqItem.due = null;
    }
    const formValid = simpleValidatorEdit.current.allValid()
    if (formValid && rfqsState.rfqEditObject.status) {
      setRfqsState({
        ...rfqsState,
        isLoading: true
      });
      editRfq(newEditRfqItem.id, newEditRfqItem)
        .then(re => {
          const editedRfq = re.data;

          setRfqsState(oldRfqs => {
            return {
              ...oldRfqs,
              rfqs: oldRfqs.rfqs.map(rfq => {
                return rfq.id === editedRfq.id
                  ? re.data : rfq
              }),
              rfqEditIndex: -1
            }
          });
        }).catch((error) => {
          setRfqsState({
            ...rfqsState,
            isLoading: false,
          });
        });
    } else {
      simpleValidatorEdit.current.showMessages();
      forceUpdate(1);
    }

  };

  const handleCloseEditRfq = (e, index) => {
    setRfqsState({
      ...rfqsState,
      rfqEditIndex: -1
    })
  };

  const handleDeleteRfq = (event, index) => {
    const rfqForDeletion = rfqsState.rfqs[index];
    setRfqsState({
      ...rfqsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Rfq',
        onCancel: () => {
          setRfqsState({
            ...rfqsState,
            confirmDialog: {
              ...rfqsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteRfq(rfqForDeletion.id)
            .then(() => {
              const newRfqs = [...rfqsState.rfqs];
              newRfqs.splice(index, 1);
              setRfqsState({
                ...rfqsState,
                rfqs: newRfqs,
              });
            })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const filterData = (data) => {
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    let filtersData = '';

    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...rfqsState.filters,
        buyer: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...rfqsState.filters,
        supplier: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    }


    getRfqs(filtersData).then(res => {
      setRfqsState({
        ...rfqsState,
        isLoading: false,
        rfqs: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('rfqFilter', data);

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqsState({
        ...rfqsState,
        isLoading: false,
      });
    });

  };

  const selectedVessel = (data, action) => {

    const newEditRfqItem = Object.assign({},
      action === 'edit'
        ? rfqsState.rfqEditObject
        : rfqsState.rfqCreateObject
    );

    newEditRfqItem.vessel = data.vessel;

    setRfqsState({
      ...rfqsState,
      [
        action === 'edit'
          ? 'rfqEditObject'
          : 'rfqCreateObject'
      ]: newEditRfqItem
    });
  };

  const selectedCompany = (data, action) => {

    const newEditRfqItem = Object.assign({},
      action === 'edit'
        ? rfqsState.rfqEditObject
        : rfqsState.rfqCreateObject
    );

    newEditRfqItem.supplier = data.company;


    setRfqsState({
      ...rfqsState,
      [
        action === 'edit'
          ? 'rfqEditObject'
          : 'rfqCreateObject'
      ]: newEditRfqItem
    });
  };

  const selectedPort = (data, action) => {
    const newEditRfqItem = Object.assign({}, action === 'edit'
      ? rfqsState.rfqEditObject
      : rfqsState.rfqCreateObject);

    newEditRfqItem.port = data.port;

    setRfqsState({
      ...rfqsState,
      [action === 'edit' ? 'rfqEditObject' : 'rfqCreateObject']: newEditRfqItem
    });

  };

  const selectedCatalogue = (data, action) => {

    const newEditRfqItem = Object.assign({},
      action === 'edit'
        ? rfqsState.rfqEditObject
        : rfqsState.rfqCreateObject
    );

    newEditRfqItem.category = data.catalogue;

    setRfqsState({
      ...rfqsState,
      [
        action === 'edit'
          ? 'rfqEditObject'
          : 'rfqCreateObject'
      ]: newEditRfqItem
    });
  };

  const handleAddNewRfq = (e) => {
    e.preventDefault();

    setRfqsState({
      ...rfqsState,
      rfqCreateObject: newRfq()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };


  const onSaveNewRfq = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newRfq = {};
    newRfq.reference_number = rfqsState.rfqCreateObject.reference_number;
    newRfq.supplier = rfqsState.rfqCreateObject.supplier;
    newRfq.category = rfqsState.rfqCreateObject.category;
    newRfq.vessel = rfqsState.rfqCreateObject.vessel;
    newRfq.due = rfqsState.rfqCreateObject.due !== '' && rfqsState.rfqCreateObject.due !== null ?  dateToString(moment(rfqsState.rfqCreateObject.due))+'T00:00:00Z' : null;
    newRfq.port = rfqsState.rfqCreateObject.port;

    newRfq.rfqitems = 'Null';
    newRfq.description = 'test';


    const formValid = simpleValidatorCreate.current.allValid()
    if (formValid) {
      setRfqsState({
        ...rfqsState,
        isLoading: true,
      });
      addRfq(newRfq)
        .then(res => {
          setRfqsState({
            ...rfqsState,
            rfqs: [...rfqsState.rfqs, res.data],
            rfqCreateObject: null,
            rfqEditIndex: -1,
            isLoading: false,
          })
        })
        .catch(err => {
          setRfqsState({
            ...rfqsState,
            isLoading: false,
          });
        });
    }
    else {
      simpleValidatorCreate.current.showMessages();
      forceUpdate(1);
    }
  };



  const onEditNewRfqObject = (e, property) => {

    let newValue = '';
    if (property === 'due') {
      newValue = e;
    } else {
      newValue = e.target.value;
    }

    const newEditRfqItem = Object.assign({}, rfqsState.rfqCreateObject);
    newEditRfqItem[property] = newValue;

    setRfqsState({
      ...rfqsState,
      rfqCreateObject: newEditRfqItem
    });
  };


  const classes = useStyles();

  const tableFilterComponent = () => {
    return <TableFilter
      companyField={true}
      searchByText={false}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"} // set buyer filter for supplier and vice versa.
      company={(filters && filters.company) ? filters.company : rfqsState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : rfqsState.filters.vessel}
      productCategory={(filters && filters.category) ? filters.category : rfqsState.filters.category}
      port={(filters && filters.port) ? filters.port : rfqsState.filters.port}
      from={(filters && filters.from) ? filters.from : rfqsState.filters.from_date}
      to={(filters && filters.to) ? filters.to : rfqsState.filters.to_date}
      status={(filters && filters.status) ? filters.status : rfqsState.filters.status}
      statuses={rfqStatusState.rfqStatuses}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      order={rfqsState.page.order_type}
      orderBy={rfqsState.page.order_by}
      rowCount={rfqsState.page.length} />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {rfqsState.rfqs.map((rfq, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              {
                (rfqsState.rfqEditIndex !== index) && index !== '?'
                  ? <>
                     <TableCell padding='none' size='small'>
                      <IconButton
                        aria-label="expand row"
                        style={{padding:'0px'}}
                        component={RouterLink}
                        to={{ pathname: `/transactions/${rfq.transaction.id}/rfqs/${rfq.id}` }}
                      >
                        <ManageSearchIcon></ManageSearchIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell>{rfq.reference_number?rfq.reference_number:'N/A'}</TableCell>
                    <TableCell>{(rfq.buyer !== null && rfq.supplier !== null) ? (userState.userRole === 'SUPPLIER' ? rfq.buyer.name : rfq.supplier.name) : ''}</TableCell>
                    <TableCell>{rfq.vessel !== null && rfq.vessel.name}</TableCell>
                    <TableCell>{rfq.category !== null && rfq.category.name}</TableCell>
                    <TableCell>{rfq.items}</TableCell>
                    <TableCell>{rfq.submitted && rfq.submitted.slice(0, 10)}</TableCell>
                    <TableCell>{rfq.due && rfq.due.slice(0, 10)}</TableCell>
                    <TableCell>{rfq.port && rfq.port.name}</TableCell>
                    <TableCell>
                      <StatusIndicator status={rfq.status}>
                        {rfq.status}
                      </StatusIndicator>
                    </TableCell>
                  </>
                  : <>
                    <TableCell onClick={e => e.stopPropagation()}>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={rfqsState.rfqEditObject.reference_number}
                          onChange={e => onEditableRfqChange(e, 'reference_number')}
                          onBlur={simpleValidatorEdit.current.showMessageFor('reference_number')}
                          />
                          {simpleValidatorEdit.current.message('reference_number', rfqsState.rfqEditObject.reference_number, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {!rfq.isRevised ? <FormControl variant="outlined" fullWidth>
                        <FilterCompanies
                          company={rfqsState.rfqEditObject.supplier || null}
                          companySearchFor={'supplier'}
                          getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                          errorMessage={null}
                        />
                      </FormControl> : (rfq.buyer !== null && rfq.supplier !== null) ? (userState.userRole === 'SUPPLIER' ? rfq.buyer.name : rfq.supplier.name) : ''}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                    <FormControl variant="outlined" fullWidth>
                      <FilterVessels
                        vessel={rfqsState.rfqEditObject.vessel || null}
                        getSelectedVessel={(data) => selectedVessel(data, 'edit')}
                      />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {!rfq.items ? <FormControl variant="outlined" fullWidth>
                        <FilterCatalogues
                          catalogue={rfqsState.rfqEditObject.category || null}
                          getSelectedCatalogue={(data) => selectedCatalogue(data, 'edit')}
                        />
                        {simpleValidatorEdit.current.message('catalogue', rfqsState.rfqEditObject.category.id, 'required|numeric|min:1,num')}
                      </FormControl> : rfq.category !== null && rfq.category.name}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {rfqsState.rfqEditObject.items}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {rfqsState.rfqEditObject.submitted}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                       <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Grid container item md={12} wrap='nowrap' justifyContent="flex-start" alignItems="flex-end">
                            <DatePicker
                              inputFormat="yyyy-MM-DD"
                              clearable
                              minDate={new Date(2015, 1, 1)}
                              maxDate={new Date(2030, 1, 1)}
                              inputVariant="outlined"
                              value={rfqsState && rfqsState.rfqEditObject && rfqsState.rfqEditObject.due}
                              onChange={e => onEditableRfqChange(e, 'due')}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                    <FormControl variant="outlined" fullWidth>
                      <FilterPorts
                        port={rfqsState.rfqEditObject.port || null}
                        getSelectedPort={(data) => selectedPort(data, 'edit')} />
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {/*<RfqRowStatus status={rfq.status} />*/}
                    </TableCell>
                  </>
              }
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: rfq.actions.includes("UPDATE") && !rfq.isCompleted && rfqsState.rfqEditIndex !== index,
                    callback: (e) => handleEditRfq(e, index)
                  },
                  delete: {
                    enabled: rfq.actions.includes("DELETE") && rfqsState.rfqEditIndex !== index,
                    callback: (e) => handleDeleteRfq(e, index)
                  },
                  close: {
                    enabled: rfqsState.rfqEditIndex === index,
                    callback: (e) => handleCloseEditRfq(e, index)
                  },
                  save: {
                    enabled: rfqsState.rfqEditIndex === index,
                    callback: (e) => handleSaveRfq(e, props)
                  },
                  quote: {
                    enabled: false,
                    callback: (e) => null
                  },
                }} />
              </TableCell>
            </TableRow>
          );
        })}
        {
          rfqsState.rfqCreateObject &&
          <TableRow className={classes.tableRow}>
            <TableCell onClick={e => e.stopPropagation()}>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  placeholder={'Reference No'}
                  label={'Reference No'}
                  value={rfqsState.rfqCreateObject.reference_number}
                  onChange={e => onEditNewRfqObject(e, 'reference_number')}
                  onBlur={simpleValidatorCreate.current.showMessageFor('reference_number')}
                  />
                  {simpleValidatorCreate.current.message('reference_number', rfqsState.rfqCreateObject.reference_number, 'required')}
              </FormControl>
            </TableCell>
            <TableCell >
            <FormControl variant="outlined" fullWidth>
              <FilterCompanies
                company={rfqsState.rfqCreateObject.supplier || null}
                companySearchFor={'supplier'}
                getSelectedCompany={(data) => selectedCompany(data, 'create')}
                errorMessage={!rfqsState.rfqCreateObject.supplier}
              />
              </FormControl>
            </TableCell>
            <TableCell >
            <FormControl variant="outlined" fullWidth>
              <FilterVessels
                vessel={rfqsState.rfqCreateObject.vessel || null}
                getSelectedVessel={(data) => selectedVessel(data, 'create')} />
                </FormControl>
            </TableCell>
            <TableCell >
            <FormControl variant="outlined" fullWidth>
              <FilterCatalogues
                catalogue={rfqsState.rfqCreateObject.category || null}
                getSelectedCatalogue={(data) => selectedCatalogue(data, 'create')}
              />
              {simpleValidatorCreate.current.message('catalogue', rfqsState.rfqCreateObject.category.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              {rfqsState.rfqCreateObject.items}
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              {rfqsState.rfqCreateObject.submitted}
            </TableCell>
            <TableCell >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container item wrap='nowrap' justifyContent="flex-start" alignItems="flex-end" style={{ minWidth: '50px' }}>
                    <DatePicker
                      inputFormat="yyyy-MM-DD"
                      clearable
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      inputVariant="outlined"
                      value={rfqsState.rfqCreateObject.due || null}
                      onChange={e => onEditNewRfqObject(e, 'due')}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </LocalizationProvider>
              </FormControl>
            </TableCell>
            <TableCell >
              <FilterPorts
                port={rfqsState.rfqCreateObject.port || null}
                getSelectedPort={(data) => selectedPort(data, 'create')} />
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setRfqsState({ ...rfqsState, rfqCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewRfq()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }

  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('RFQS')}
        pagination={{
          total: rfqsState.page.total || 0,
          page: rfqsState.page.page - 1 || 0,
          perPage: rfqsState.page.per_page || 10,
          count: rfqsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={rfqsState.authorizedActions.create}
        onNewRowClick={handleAddNewRfq}
        newRowBtnEnabled={!rfqsState.rfqCreateObject}
        confirmDialog={rfqsState.confirmDialog}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={rfqsState.isLoading}
      />
    </>
  );
};

export default withRouter(Rfqs);