const tableHeaders = [
    { id: 'product', disablePadding: false, label: 'Product' },
    { id: 'quantity', disablePadding: false, label: 'Quantity' },
    { id: 'unit', disablePadding: false, label: 'Unit'},
  ];
  
  const tableHeadersPrice = [
     { id:  'action', disablePadding: false, label: '' },
      { id: 'product', disablePadding: false, label: 'Product' },
      { id: 'quantity', disablePadding: false, label: 'Quantity (Lt/Kg)' },
      { id: 'package', disablePadding: false, label: 'Packaging'},
    ];


// Empty product
const newProduct = () => {
  return {
    product: { name: '', id: '' },
    quantity: '',
    unit: '',
  }
};

  export { tableHeaders, tableHeadersPrice, newProduct };