import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllVesselAges } from '../../services/vesselsService';

export default function FilterAges({ age: initialAge, getSelectedAge }) {

  const [age, setAge] = React.useState(0);
  const [ages, setAges] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setAge(initialAge && initialAge.id !== null ? initialAge : 0);
    setAges(initialAge && initialAge.id !== null ? [initialAge] : []);
    getAllVesselAges().then((res) => {
      setAges(res.data);
    }).catch((error) => {
    })
  }, [initialAge]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };


  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onAgeSelectionChange = (event, values) => {
    setAge(values ? values : '');
    const selectedAge = {
      age: values ? values : '',
    };
    getSelectedAge(selectedAge);
  };

  return (
    <div>
      <Autocomplete
        id={"find-age" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'age')}
        onChange={onAgeSelectionChange}
        value={ages.find(v => v.id === age.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={ages !== null ? ages : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Age')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}