
const tableHeadersComAudit = [
  { id: 'data', disablePadding: false, label: 'Data'},
  { id: 'dateCreated', disablePadding: false, label: 'Date'},
  { id: 'remoteIp', disablePadding: false, label: 'Remote Ip'},
  { id: 'path', disablePadding: false, label: 'Path'},
  { id: 'contentType', disablePadding: false, label: 'Content Type' },
  { id: 'protocol', disablePadding: false, label: 'Protocol' },
];

export { tableHeadersComAudit };
