import React from 'react';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import useStyles from "./styles";
// import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import useAccountSettingsForm from "./AccountSettingsFormHook";
import AccountSettingsEdit from './AccountSettingsEdit';
import AccountPreferencesEdit from './AccountPreferencesEdit';
import useAccountPreference from "./AccountPreferenceFormHook";
import { TabPanel, a11yProps } from "../../components/tabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";

const AccountSettings = (props) => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pathName = window.location.pathname;
  const regex = /(\d+)/gm;
  let m;

  m = regex.exec(pathName);
  let userId = props.userState.user.userId;
  if (m) {
      userId = m[0]
  }

  const filtersData = {
      id: userId
  };

  const {
    user,
    isLoading,
    errors,
    onDataChange,
    onFormSubmit,
    getUserName,
    getFirstName,
    getLastName,
    getEmail,
    getPass,
    getNotes,
    getSelectedCatalogue,
  } = useAccountSettingsForm(filtersData);

  const { prefsState, getPrefVal } = useAccountPreference(filtersData);

  return (
    <div className={classes.accountSettingsInfoWrapper}>
      <SimpleBreadcrumbs
        buttons={false}
        norfq={true}
      />
      <Paper
        className={classes.paper}
        elevation={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={3}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab label="Settings" value={0}  {...a11yProps(0)}>
                </Tab>
                <Tab label="Preferences" value={1}  {...a11yProps(1)}>
                </Tab>
              </Tabs>
              <TabPanel value={value} index={0}>
                <AccountSettingsEdit
                  props={props}
                  user={user}
                  isLoading={isLoading}
                  errors={errors}
                  onDataChange={onDataChange}
                  onFormSubmit={onFormSubmit}
                  getUserName={getUserName}
                  getFirstName={getFirstName}
                  getLastName={getLastName}
                  getEmail={getEmail}
                  getPass={getPass}
                  getNotes={getNotes}
                  getSelectedCatalogue={getSelectedCatalogue}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <AccountPreferencesEdit
                  prefsState={prefsState}
                  getPrefVal={getPrefVal}
                />
              </TabPanel>
              {/*         
        <ConfirmationDialog
          ariaLabelledBy={''}
          confirmationMessage={erpConfigs.confirmDialog.message}
          dialogTitle={erpConfigs.confirmDialog.title}
          handleCancel={erpConfigs.confirmDialog.onCancel}
          handleOk={erpConfigs.confirmDialog.onConfirm}
          open={erpConfigs.confirmDialog.isOpen}
        /> */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div >

  );
}

export default AccountSettings;