import React, { useEffect, useState, useRef  } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeaderColumnGroup from '../../components/tableHeaderColumnGroup';
import { urlParams } from "../../helpers/urlParams";
import { styled } from '@mui/material/styles';
import useStyles from './styles';
import { compareContracts } from '../../services/contractsService';
import { TableFilterRequestComparison } from '../../components/tableFilterRequestComparison';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableActions from '../../components/TableActions/TableActions';
import TextField from '@mui/material/TextField';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersPrice, newProduct } from '../../helpers/requestComparisonConfig';
import FilterProductsContract from '../../components/filterProductsContract';
import FilterContractsPackages from '../../components/filterContractsPackages';
import FormControl from '@mui/material/FormControl';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import SimpleReactValidator from 'simple-react-validator';
import OnlineAssistant from '../../components/onlineAssistant';
import t from '../../helpers/languages';
import './_pricelistsComparison.scss';

const PricelistsComparison = (props) => {

  const productInitObject = () => {
    return {
      product: { name: '', id: '' },
      quantity: '',
      unit: '',
      add: true
    }
  };

  const classes = useStyles();

  const [compareTransactionsState, setCompareTransactionsState] = useState({
    isLoading: false,
    filters: {},
    page: {
      page: 1,
      count: 0,
      per_page: 50,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    headers: [],
    prices: [],
    products: [],
    productEditIndex: -1,
    productEditObject: productInitObject(),
    productCreateObject: null,
    totals: null,
    totalsUSD: null,
  });

  const filters = localStoreGetObj('productsComparisonFilter');

  // const pathName = window.location.pathname;
  // const regex = /\d+/;
  // let m;

  // m = regex.exec(pathName);

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 50)
  !criteria.page && (criteria.page = 1)



  urlParams.setUrlParams(criteria);

  const filterData = (data) => {
    setCompareTransactionsState({
      ...compareTransactionsState,
      isLoading: true,
    });

    let filtersData = {
      // ...regionPurchasesState.filters,
      ports: data && data.port?.map((data) => {
        return {
          portId: data.id,
          countryId: data.countryId,
        }
      }),
      portFirst: data && data.portFirst,
      searchDate: data && data.searchDate?data.searchDate:new Date(),
      contracts: data && data.contract && data.contract.map(({ id }) => id),
      per_page: criteria.per_page,
      page: 1
    };

 
    setCompareTransactionsState({
      ...compareTransactionsState,
      isLoading: false,
      filters: filtersData,
    });

    handleFilterData(data.port, data.contract, data.portFirst);

   

  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#f5f5f9',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={false}
     

    />;
  };

  const handleAddNewProduct = (e) => {
    e.preventDefault();

    setCompareTransactionsState({
      ...compareTransactionsState,
      productCreateObject: newProduct()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const selectedPackage = (data, action) => {

    // when FilterUnits returns null, make it empty string so
    // that the validator wont break when unit value is deleted 
    if (data.unit === null) {
      data.unit = '';
    }

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? compareTransactionsState.productEditObject
        : compareTransactionsState.productCreateObject
    );

    newEditProductItem.unit = data.unit;

    setCompareTransactionsState({
      ...compareTransactionsState,
      [
        action === 'edit'
          ? 'productEditObject'
          : 'productCreateObject'
      ]: newEditProductItem
    });
   
  };

  const selectedProduct = (data, action) => {
    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? compareTransactionsState.productEditObject
        : compareTransactionsState.productCreateObject
    );

    newEditProductItem.product = data.product;
    // if(!data.product){
    //   newEditProductItem.unit = 0;
    // };
    setCompareTransactionsState({
      ...compareTransactionsState,
      [
        action === 'edit'
          ? 'productEditObject'
          : 'productCreateObject'
      ]: newEditProductItem
    });

    

  };

  const handleDeleteProduct = (event, index) => {

    const productForDeletion = compareTransactionsState.products[index];
  
    //const productS = compareTransactionsState.products.filter(i => i.product.id !== productForDeletion.product.id)

    const productS = [...compareTransactionsState.products];
   
    productS.splice(index, 1);
    
    
    let filtersData  = {
      // ...regionPurchasesState.filters,
      ports: compareTransactionsState.filters.ports,
      portFirst: compareTransactionsState.filters.portFirst,
      contracts: compareTransactionsState.filters.contracts,
      per_page: compareTransactionsState.filters.per_page,
      page: compareTransactionsState.filters.page,
      products: 
      productS.map((data) => {
         return {
            productId: data.product.id,
            unitId: data.unit.id,
            quantity: data.quantity
          }
        })
      
    };
    if (productS.length > 0) {
      compareContracts(filtersData).then(res => {
        const results = res.data;

        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
          headers: results.headers,
          prices: results.prices,
          products: productS,
          page: res.data.page,
          totals: res.data.totals,
          filters: filtersData,
          totalsUSD: res.data.totalsUSD,
          productCreateObject: null,
          productEditObject: -1,
        });


      }).catch((error) => {
        console.log(error);
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
      });
      });
      

    } else {
      setCompareTransactionsState({
        ...compareTransactionsState,
        isLoading: false,
        headers: [],
        prices: [],
        products: [],
        productEditIndex: -1,
        productEditObject: productInitObject(),
        productCreateObject: null,
        totals: null,
        totalsUSD: null,
      });
    }
  };
  
  const handleFilterData = (port, contract, porstFirst) => {
    
    let filtersData = {
      // ...regionPurchasesState.filters,
      ports: port&&port.map((data) => {
        return {
          portId: data.id,
          countryId: data.countryId,
        }
      }),
      portFirst: porstFirst,
      contracts: contract&&contract.map(({ id }) => id),
      per_page: compareTransactionsState.filters.per_page,
      page: compareTransactionsState.filters.page,
      products:
        compareTransactionsState.products.map((data) => {
          return {
            productId: data.product.id,
            unitId: data.unit.id,
            quantity: data.quantity
          }
        })
    };
    if (compareTransactionsState.products.length > 0 && port.length>0&&contract.length>0) {
      compareContracts(filtersData).then(res => {
        const results = res.data;

        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
          headers: results.headers,
          prices: results.prices,
          page: res.data.page,
          totals: res.data.totals,
          filters: filtersData,
          totalsUSD: res.data.totalsUSD,
          productCreateObject: null,
          productEditObject: -1,
        });
      }).catch((error) => {
        console.log(error);
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
        });
      });
    }
    else if ((port&&port.length === 0) || (contract&&contract.length === 0)) {
      setCompareTransactionsState({
        ...compareTransactionsState,
        isLoading: false,
        headers: [],
        prices: [],
        products: [],
        filters: filtersData,
        productEditIndex: -1,
        productEditObject: productInitObject(),
        productCreateObject: null,
        totals: null,
        totalsUSD: null,
      });
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const onSaveNewProduct = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newProduct= {};
    newProduct.product = compareTransactionsState.productCreateObject.product;
    newProduct.unit = compareTransactionsState.productCreateObject.unit;
    newProduct.quantity = compareTransactionsState.productCreateObject.quantity;
    const formValid = simpleValidator.current.allValid()

    let newProducts = [];
    let productsAdd= [];

    let productsAdda= [...compareTransactionsState.products,newProduct];

 
    if (compareTransactionsState.products.length !== 0) {
      newProducts = 
        productsAdda.map(data => {
          return {
            productId: data.product.id,
            unitId: data.unit.id,
            quantity: data.quantity
          }
        });
        
      
      productsAdd=productsAdda;

    }

    else {
      newProducts = [

        {
          productId: newProduct.product.id,
          unitId: newProduct.unit.id,
          quantity: newProduct.quantity
        }
      ]
      productsAdd=[newProduct];
    }
       
    let filtersData  = {
      // ...regionPurchasesState.filters,
      ports: compareTransactionsState.filters.ports,
      portFirst: compareTransactionsState.filters.portFirst,
      contracts: compareTransactionsState.filters.contracts,
      per_page: compareTransactionsState.filters.per_page,
      page: compareTransactionsState.filters.page,
      products: newProducts
    };


    if (formValid) {
      setCompareTransactionsState({
        ...compareTransactionsState,
        isLoading: true,
      });
      compareContracts(filtersData).then(res => {
        const results = res.data;
        
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
          headers: results.headers,
          prices: results.prices,
          products: productsAdd,
          page: res.data.page,
          totals: res.data.totals,
          filters: filtersData,
          totalsUSD: res.data.totalsUSD,
          productCreateObject: null,
          productEditObject: -1,
        });
    
     
      }).catch((error) => {
        console.log(error);
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
        });
      });

      // add(newProduct)
      //   .then(res => {
      //     setCompareTransactionsState({
      //       ...compareTransactionsState,
      //       prices: [...compareTransactionsState.prices, res.data],
      //       productCreateObject: null,
      //       productEditObject: -1,
      //       isLoading: false,
      //     })

      //   })
      //   .catch(err => {
      //     console.log(err);
      //     setCompareTransactionsState({
      //       ...compareTransactionsState,
      //       isLoading: false,
      //     });
      //   });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  
  };

  const handleSaveProduct = (event, indexa) => {
    event.preventDefault();

    const newEditProduct = Object.assign({}, compareTransactionsState.productEditObject);

    const formValid = simpleValidator.current.allValid();
    if (formValid) {
   

    const newEditProducts = compareTransactionsState.products.map((product, index) => {
      return index === indexa
        ? newEditProduct : product
    });

    setCompareTransactionsState({
      ...compareTransactionsState,
      ...compareTransactionsState.products,
      products: [...newEditProducts]

    });


    let filtersData  = {
      // ...regionPurchasesState.filters,
      ports: compareTransactionsState.filters.ports,
      portFirst: compareTransactionsState.filters.portFirst,
      contracts: compareTransactionsState.filters.contracts,
      per_page: compareTransactionsState.filters.per_page,
      page: compareTransactionsState.filters.page,
      products: 
        newEditProducts.map((data) => {
         return {
            productId: data.product.id,
            unitId: data.unit.id,
            quantity: data.quantity
          }
        })
      
    };
    
    
      setCompareTransactionsState({
        ...compareTransactionsState,
        isLoading: true,
      });
      compareContracts(filtersData).then(res => {
        const results = res.data;
        
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
          headers: results.headers,
          prices: results.prices,
          products: [...newEditProducts],
          page: res.data.page,
          totals: res.data.totals,
          filters: filtersData,
          totalsUSD: res.data.totalsUSD,
          productEditIndex: -1,
        });
        }).catch((error) => {
          console.log(error);
          setCompareTransactionsState({
            ...compareTransactionsState,
            isLoading: false,
          });
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleEditProduct = (event, index) => {
    event.stopPropagation();

    const productEditObject = Object.assign({}, compareTransactionsState.products[index]);

    setCompareTransactionsState({
      ...compareTransactionsState,
      productEditIndex: index,
      productEditObject: productEditObject
    });

  };

  const handleCloseEditProduct = (e, index) => {
    setCompareTransactionsState({
      ...compareTransactionsState,
      productEditIndex: -1,
    })
  };

  const onEditableProductChange = (event, property) => {
    const newValue = event.target.value;
    const newEditProduct = Object.assign({}, compareTransactionsState.productEditObject);
    newEditProduct[property] = newValue;

    setCompareTransactionsState({
      ...compareTransactionsState,
      productEditObject: newEditProduct 
    });
  };

  const onNewProductChange = (e, property) => {

    let newValue = '';
    newValue = e.target.value;

    const newCreateProduct = Object.assign({}, compareTransactionsState.productCreateObject);
    newCreateProduct[property] = newValue;

    setCompareTransactionsState({
      ...compareTransactionsState,
      productCreateObject: newCreateProduct
    });
  };

  const tableFilterComponent = () => {
    return <TableFilterRequestComparison
      portFirst={filters && filters.portFirst}
      contract={filters && filters.contract}
      port={filters && filters.port}
      searchDate={filters && filters.searchDate?filters.searchDate:new Date()}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return !compareTransactionsState.isLoading &&compareTransactionsState.headers?.length>0&&<TableHeaderColumnGroup
      handleSorting={() => { }}
      headCells={compareTransactionsState.headers}
      headCellsConfig={tableHeadersPrice}
      onSelectAllClick={() => { }}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };

 
  const tableBodyComponent = () => {
    return (
      <TableBody>
        {compareTransactionsState.prices && compareTransactionsState.prices.map((data, index) => {
    
          return (
            <TableRow
              key={'index5' + index}
              className={classes.tableRow}
            >
             <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: compareTransactionsState.productEditIndex !== index,
                    callback: (e) => handleEditProduct(e, index)
                  },
                  delete: {
                    enabled: compareTransactionsState.productEditIndex !== index,
                    callback: (e) => handleDeleteProduct(e, index)
                  },
                  close: {
                    enabled: compareTransactionsState.productEditIndex === index,
                    callback: (e) => handleCloseEditProduct(e, index)
                  },
                  save: {
                    enabled: compareTransactionsState.productEditIndex === index,
                    callback: (e) => handleSaveProduct(e, index)
                  },
                  quote: {
                    enabled: false,
                    callback: (e) => null
                  },
                }} />
              </TableCell>
              {
                (compareTransactionsState.productEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell key={'index6' + index} align={'left'}>
                      {data.productName}
                    </TableCell>
                    <TableCell key={'index7' + index} align={'center'}>
                      {data.quantity}
                    </TableCell>
                    <TableCell key={'index8' + index} align={'center'}>
                      {data.unitName}
                    </TableCell>
                  </>
                  : <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterProductsContract
                          product={compareTransactionsState.productEditObject.product}
                          catalogue={""}
                          contracts={compareTransactionsState.filters.contracts}
                          ports={compareTransactionsState.filters.ports}
                          getSelectedProduct={(data) => selectedProduct(data, 'edit')}
                        />
                      </FormControl>
                      {simpleValidator.current.message('product',compareTransactionsState.productEditObject.product.id, 'required|numeric|min:1,num')}
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          defaultValue={compareTransactionsState.productEditObject.quantity}
                          fullWidth
                          multiline
                          label={'Quantity (Lt/Kg)'}
                          variant="outlined"
                          maxRows={10}
                          onChange={e => onEditableProductChange(e, 'quantity')}
                          onBlur={simpleValidator.current.showMessageFor('quantity')} />
                        {simpleValidator.current.message('quantity', compareTransactionsState.productEditObject.quantity, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterContractsPackages
                          package={compareTransactionsState.productEditObject.unit}
                          contracts={compareTransactionsState.filters.contracts}
                          product={compareTransactionsState.productEditObject.product.id}
                          getSelectedContractsPackage={(data) => selectedPackage(data, 'edit')}
                        />
                         {simpleValidator.current.message('package',compareTransactionsState.productEditObject.unit.id, 'required|numeric|min:0,num')}
                      </FormControl>
                    </TableCell>
                  </>
              }
              {data.costs.map((cost, dindex) => {
                return (
                  <HtmlTooltip
                    key={'index29' + dindex}
                    title={
                      cost !== null&&<React.Fragment>
                        Equivalent: {cost !== null ? cost.equivalentProductName : '-'} <br />
                        {cost&&cost.packingCharge!==null? 'Packing Charge: '+ cost?.packingCharge:''} {cost&&cost.packingCharge!==null&&<br />}
                        Net Price per Unit: {cost !== null ? cost.price : '-'}/{cost !== null ? cost.priceUnitName : '-'}<br />
                        Base price:{cost !== null ? cost.basePrice : '-'} <br />
                        -Discount:{cost !== null ? cost.discount : '-'} <br />
                        +Port diff:{cost !== null ? cost.portDiff : '-'} 
                      </React.Fragment>
                    }
                  >
                    <TableCell key={'index9' + dindex} align={'center'}>{cost !== null ? cost.totalCost : '-'}</TableCell>
                  </HtmlTooltip>
                )
              })}
            </TableRow>   
          );
        })}
        {
          compareTransactionsState.productCreateObject &&
          <TableRow className={classes.tableRow}>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setCompareTransactionsState({ ...compareTransactionsState, productCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewProduct()
                }
              }}>
              </TableActions>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
            <FormControl variant="outlined" fullWidth>
                <FilterProductsContract
                  product={compareTransactionsState.productCreateObject.product}
                  catalogue={""}
                  contracts={compareTransactionsState.filters.contracts}
                  ports={compareTransactionsState.filters.ports}
                  getSelectedProduct={(data) => selectedProduct(data, 'create')}
                  />
                  {simpleValidator.current.message('product',compareTransactionsState.productCreateObject.product.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    defaultValue={compareTransactionsState.productCreateObject.quantity}
                    fullWidth
                    multiline
                    label={'Quantity (Lt/Kg)'}
                    variant="outlined"
                    maxRows={10}
                    onChange={e => onNewProductChange(e, 'quantity')}
                    onBlur={simpleValidator.current.showMessageFor('quantity')} />
                    {simpleValidator.current.message('quantity', compareTransactionsState.productCreateObject.quantity, 'required|numeric|min:1,num')}
                </FormControl>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterContractsPackages
                    package={compareTransactionsState.productCreateObject.unit}
                    contracts={compareTransactionsState.filters.contracts}
                    ports={compareTransactionsState.filters.ports}
                    product={compareTransactionsState.productCreateObject.product.id}
                    getSelectedContractsPackage={(data) => selectedPackage(data, 'create')}
                  />
                  {simpleValidator.current.message('package',compareTransactionsState.productCreateObject.unit.id, 'required|numeric|min:0,num')}
                </FormControl>
              </TableCell>
              {compareTransactionsState.filters.contracts?.map((contract,i) => {
                return <TableCell  key={i} >

                </TableCell>
              })}
          </TableRow>
        }
        {!compareTransactionsState.isLoading && compareTransactionsState.totals && <TableRow>
          <TableCell colSpan={3} align="right"></TableCell>
          <TableCell align="center"><Typography variant="subtitle2" gutterBottom>Total</Typography></TableCell>
          {compareTransactionsState.totals.map((total, indexa) => {
            return (
              <TableCell key={'indexa' + indexa} align="center"><Typography variant="subtitle2" gutterBottom>{total}</Typography></TableCell>);
          })}
        </TableRow>}
        {!compareTransactionsState.isLoading && compareTransactionsState.totals && <TableRow>
          <TableCell colSpan={3} align="right"></TableCell>
          <TableCell align="center"><Typography variant="subtitle2" gutterBottom>Total USD</Typography></TableCell>
          {compareTransactionsState.totalsUSD.map((total, indexaa) => {
            return (
              <TableCell key={'indexaa' + indexaa} align="center"><Typography variant="subtitle2" gutterBottom>{total}</Typography></TableCell>);
          })}
        </TableRow>}
      </TableBody>
    );
  }

  return (
    <><OnlineAssistant
      path={'pricelists'}
      user={props.userState.user.userRole}
    />
      <ProcureDataTable
        toolbarTitle={<>Request Comparison - <span ><a href="https://vimeo.com/912921493" target="_blank"
          style={{
            textDecoration: 'none',
            color: 'blue'}}>
          Tutorial video</a></span></>
    }
        pagination={{
          total: 0,
          page: 0,
          perPage: 50,
          count: 0,
          disabled: true,
          handleChangePage: () => { },
          handleChangeRowsPerPage: () => { },
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={handleAddNewProduct}
        confirmDialog={{
          message: '',
          title: '',
          onCancel: () => { },
          onConfirm: () => { },
          isLoading: false,
          isOpen: false
        }}
        tableFilter={tableFilterComponent}
        breadcrump={breadcrump}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        newRowBtnEnabled={
          !compareTransactionsState.productCreateObject
          && compareTransactionsState.productEditIndex===-1
          && compareTransactionsState.filters.ports
          && compareTransactionsState.filters.ports.length > 0
          && compareTransactionsState.filters.contracts
          && compareTransactionsState.filters.contracts.length > 0
        }
        newRowActionAvailable={true}
        isLoading={compareTransactionsState.isLoading}
      /></>
  );
};

export default PricelistsComparison;