import httpService from './httpService';

const getAllCompanyCategories = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchCompanyCategories(criteria);
  
};

export { getAllCompanyCategories };
  