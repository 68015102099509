import React from 'react';
import './_userInitials.scss';

const UserInitials = ({ firstName, lastName }) => {
  return (
    <div className='user-initials'>
      <span className='user-initials__letter'>
        { firstName && firstName.slice(0, 1).toUpperCase() }
      </span>
      <span className='user-initials__letter'>
        { lastName && lastName.slice(0, 1).toUpperCase() }
      </span>
    </div>
  );
};

export default UserInitials;
