import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState, useRef } from 'react';
import TextField from "@mui/material/TextField";
import TableActions from '../../components/TableActions/TableActions';
import TableHeader from '../../components/tableHeader';
import FormControl from '@mui/material/FormControl';
import { tableHeaders, newRfqItem } from '../../helpers/rfqItemsConfig';
import {
  getRfqById,
  editRfq,
  deleteRfq,
  submitRFQ,
  reviseRfq,
  declineRFQ,
  createQuoteFromRFQ,
  getRfqAttachment,
  addRfqAttachment,
  deleteRfqAttachment,
  printRfq
} from '../../services/rfqsService';
import { getRfqItems, edit, add, deleteRfqItem } from '../../services/rfqItemsService';
import RfqInfo from '../../components/rfqInfo';
import { TableFilterItems } from '../../components/tableFilterItems';
import useStyles from './styles';
import FilterProducts from '../../components/filterProducts';
import DropzoneComponent from '../../components/dropzoneComponent/DropzoneComponent';
import FilterUnits from '../../components/filterUnits';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { getAuthorizationMappings } from './../../services/authorizationsService';
import SimpleReactValidator from 'simple-react-validator';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import PrintPdf from '../../components/printPdf';
import OnlineAssistant from '../../components/onlineAssistant';
import MembersChat from '../../components/membersChat';
import t from '../../helpers/languages';

const RfqItems = (props) => {
  const [, setRfqItemEditObject] = useState({});
  const [newFiles, setNewFiles] = useState([]);
  const [dropkey, setDropKey] = useState(1);
  const [, setVessel] = React.useState('');
  const [, setRFQNotes] = React.useState('');
  const [, setRFQFiles] = React.useState('');
  const [, setCompany] = React.useState('');
  const [, setPort] = React.useState('');
  const [, setDate] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);


  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //   };
  // });

  const [rfqsState, setRfqsState] = useState({
    rfqs: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {},
    isLoading: true,
    rfqEditIndex: -1,
    rfqCreateObject: null,
    error: null,
    isRfqSaved: false,
    isVesselSaved: true,
    isNotesSaved: true,
    isPortSaved: true,
    isSupplierSaved: true,
    isDueSaved: true,
    isReferenceNumberSaved: true,
    isSupplierNotesSaved: true,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });



  const rfqItemInitObject = () => {
    return {
      category: { id: '', name: '' },
      notes: '',
      id: '',
      product: { code: '', id: '', name: '' },
      quantity: '',
      remote_id: '',
      rfqitem_id: '',
      unit: { id: '', name: '' },
      add: true
    }
  };

  const [rfqItemsState, setRfqItemsState] = useState({
    rfqItems: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    rfqItemEditIndex: -1,
    rfqItemEditObject: rfqItemInitObject(),
    rfqItemCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });



  const pathName = window.location.pathname;
  const regex = /\d+$/;
  let m;

  m = regex.exec(pathName);


  const rfqId = m[0];

  const rfqCriteria = { id: rfqId };

  //const dispatch = useDispatch();

  const rfqItemCriteria = urlParams.getUrlParams();
  !rfqItemCriteria.per_page && (rfqItemCriteria.per_page = 10)
  !rfqItemCriteria.page && (rfqItemCriteria.page = 1)
  rfqItemCriteria.id = rfqId;
  urlParams.setUrlParams(rfqItemCriteria);
  rfqItemCriteria.order_type = rfqItemsState.page.order_type;
  rfqItemCriteria.order_by = rfqItemsState.page.order_type;

  useEffect(() => {
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });

    setRfqItemsState({
      ...rfqItemsState,
      isLoading: true
    });

    getRfqItems(rfqItemCriteria).then(res => {
      const { results } = res.data;

      setRfqItemsState({
        ...rfqItemsState,
        rfqItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/rfqitems', props.userState.user.userRole)
        }
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
      });
    });

    getRfqById(rfqCriteria).then(res => {
      setRfqsState({
        ...rfqsState,
        rfqs: res.data,
        isLoading: false
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqsState({
        ...rfqsState,
        isLoading: false,
      });
    });

    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();


    const isAsc = property === rfqItemsState.page.order_by && rfqItemsState.page.order_type === 'desc' ? 'asc' : 'desc';

    let filtersData = {
      ...rfqItemsState.filters,
      per_page: rfqItemsState.page.per_page,
      page: rfqItemsState.page.page,
      order_type: isAsc,
      order_by: property,
      id: rfqId
    };

    //rfqItemCriteria.filters.search = rfqItemsState.filters.search;
    setRfqItemsState({
      ...rfqItemsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });


    getRfqItems(filtersData).then(res => {

      setRfqItemsState({
        ...rfqItemsState,
        rfqItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
      });
    });
  };


  const handleChangePage = (event, newPage) => {
    setRfqItemsState({
      ...rfqItemsState,
      isLoading: true,
    });

    const newCriteria = {
      ...rfqItemsState.filters,
      id: rfqId,
      per_page: rfqItemsState.page.per_page,
      page: newPage + 1
    };
    getRfqItems(newCriteria).then(res => {
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
        rfqItemEditIndex: -1,
        rfqItemsCreateObject: null,
        rfqItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRfqItemsState({
      ...rfqItemsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...rfqItemsState.filters,
      page: 1,
      per_page: perPage,
      id: rfqId
    };

    getRfqItems(newCriteria).then(res => {
      setRfqItemsState({
        ...rfqItemsState,
        rfqItems: res.data.results,
        rfqItemEditIndex: -1,
        isLoading: false,
        rfqItemsCreateObject: null,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
      });
    });
  };

  const handleDeleteRfqItem = (event, index) => {

    const rfqItemForDeletion = rfqItemsState.rfqItems[index];
    setRfqItemsState({
      ...rfqItemsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Rfq Item',
        onCancel: () => {
          setRfqItemsState({
            ...rfqItemsState,
            confirmDialog: {
              ...rfqItemsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteRfqItem(rfqItemForDeletion.id).then(() => {
            const newRfqItems = [...rfqItemsState.rfqItems];
            newRfqItems.splice(index, 1);
            setRfqItemsState({
              ...rfqItemsState,
              rfqItems: newRfqItems,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const handleEditRfqItem = (event, rfqItemIndex) => {
    event.stopPropagation();

    const rfqItemEditObject = Object.assign({}, rfqItemsState.rfqItems[rfqItemIndex]);

    setRfqItemsState({
      ...rfqItemsState,
      rfqItemEditIndex: rfqItemIndex,
      rfqItemEditObject: rfqItemEditObject
    });

  };

  const onEditableRfqItemChange = (event, property) => {
    const newValue = event.target.value;
    const newEditRfqItem = Object.assign({}, rfqItemsState.rfqItemEditObject);
    newEditRfqItem[property] = newValue;

    setRfqItemsState({
      ...rfqItemsState,
      rfqItemEditObject: newEditRfqItem
    });
  };

  const handleSaveRfqItem = (event, property) => {
    event.preventDefault();
    const newEditRfqItem = Object.assign({}, rfqItemsState.rfqItemEditObject);
    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: true,
      });

      edit(newEditRfqItem.id, newEditRfqItem)
        .then(re => {
          const editedRfqItem = re.data;
          setRfqItemsState(oldRfqItems => {
            return {
              ...oldRfqItems,
              rfqItems: oldRfqItems.rfqItems.map(rfqItem => {
                return rfqItem.id === editedRfqItem.id
                  ? re.data : rfqItem
              }),
              rfqItemEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setRfqItemsState({
            ...rfqItemsState,
            isLoading: false,
          });
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleCloseEditRfqItem = (e, index) => {
    setRfqItemsState({
      ...rfqItemsState,
      rfqItemEditIndex: -1
    })
  };

  const handleAddNewRfqItem = (e) => {
    e.preventDefault();
    setRfqItemsState({
      ...rfqItemsState,
      rfqItemCreateObject: newRfqItem()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewRfqItem = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newRfqItem = {};
    newRfqItem.product = rfqItemsState.rfqItemCreateObject.product;
    newRfqItem.unit = rfqItemsState.rfqItemCreateObject.unit;
    newRfqItem.quantity = rfqItemsState.rfqItemCreateObject.quantity;
    newRfqItem.notes = rfqItemsState.rfqItemCreateObject.notes;
    newRfqItem.rfqId = rfqsState.rfqs.id;
    const formValid = simpleValidator.current.allValid()
    if (formValid) {
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: true,
      });
      add(newRfqItem)
        .then(res => {
          setRfqItemsState({
            ...rfqItemsState,
            rfqItems: [...rfqItemsState.rfqItems, res.data],
            rfqItemCreateObject: null,
            rfqItemEditIndex: -1,
            isLoading: false,
          })

        })
        .catch(err => {
          setRfqItemsState({
            ...rfqItemsState,
            isLoading: false,
          });
        });
    }
    else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onNewRfqItemChange = (e, property) => {

    let newValue = '';
    newValue = e.target.value;

    const newCreateRfqItem = Object.assign({}, rfqItemsState.rfqItemCreateObject);
    newCreateRfqItem[property] = newValue;

    setRfqItemsState({
      ...rfqItemsState,
      rfqItemCreateObject: newCreateRfqItem
    });
  };

  const selectedProduct = (data, action) => {
    const newEditRfqItem = Object.assign({},
      action === 'edit'
        ? rfqItemsState.rfqItemEditObject
        : rfqItemsState.rfqItemCreateObject
    );

    newEditRfqItem.product = data.product;

    setRfqItemsState({
      ...rfqItemsState,
      [
        action === 'edit'
          ? 'rfqItemEditObject'
          : 'rfqItemCreateObject'
      ]: newEditRfqItem
    });

  };


  const selectedVessel = (data) => {
    setVessel(data);

    const rfqEditObject = Object.assign({}, rfqsState.rfqs);
    if (data.vessel !== null && data.vessel.id !== 1000000) {
      const ds = {
        id: data.vessel.id,
        name: data.vessel.name
      }
      rfqEditObject.vessel = ds;
    } else {
      rfqEditObject.vessel = null;
    }

    setRfqItemEditObject(rfqEditObject);

    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isVesselSaved: true,
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isVesselSaved: false
        });
      });

  };


  const selectedCompany = (data) => {
    setCompany(data);
    const rfqEditObject = Object.assign({}, rfqsState.rfqs);

    if (data.company !== null && data.company.id !== 1000000) {
      const ds = {
        id: data.company.id,
        name: data.company.name
      }
      rfqEditObject.supplier = ds;
    } else {
      rfqEditObject.supplier = null;
    }
    setRfqItemEditObject(rfqEditObject);

    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isSupplierSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isSupplierSaved: false
        });
      });
  };

  const selectedPort = (data) => {
    setPort(data);

    const rfqEditObject = Object.assign({}, rfqsState.rfqs);
    if (data.port !== null && data.port.id !== 1000000) {
      const dp = {
        id: data.port.id,
        name: data.port.name
      }
      rfqEditObject.port = dp;
    } else {
      // const dp = {
      //   id: null,
      //   name: null
      // }
      rfqEditObject.port = null;
    }

    setRfqItemEditObject(rfqEditObject);

    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isPortSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isPortSaved: false
        });
      });
  };

  const selectedDate = (data) => {
    setDate(data);

    const rfqEditObject = Object.assign({}, rfqsState.rfqs);
    if (data.due !== undefined && data.due !== 'empty') {
      const due = new Date(data.due);
      rfqEditObject.due = due.toISOString();
    } else {
      rfqEditObject.due = null;
    }
    setRfqItemEditObject(rfqEditObject);

    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isDueSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isDueSaved: false
        });
      });
  };

  const rfqNotes = (data) => {
    setRFQNotes(data);

    const rfqEditObject = Object.assign({}, rfqsState.rfqs);
    if (data !== undefined && data !== '-' && data !== null) {
      rfqEditObject.notes = data.notes;
    } else {
      rfqEditObject.notes = null;
    }

    setRfqItemEditObject(rfqEditObject);
 
    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isNotesSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isNotesSaved: false
        });
      });
  };

  const rfqReferenceNumber = (data) => {

    const rfqEditObject = Object.assign({}, rfqsState.rfqs);

    if (data !== undefined && data !== '-' && data !== null) {
      rfqEditObject.reference_number = data.notes;
    } else {
      rfqEditObject.reference_number = '';
    }

    setRfqItemEditObject(rfqEditObject);
 
    editRfq({ id: rfqsState.rfqs.id }, rfqEditObject)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isReferenceNumberSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isReferenceNumberSaved: false
        });
      });
  };

  const rfqFiles = (data) => {
    setRFQFiles(data);
 
    addRfqAttachment(rfqsState.rfqs.id, data).then((response) => {
      const newFile=response.data;
      setNewFiles([...newFiles, newFile]); 
      setRfqsState({
        ...rfqsState,
        rfqs: {
          ...rfqsState.rfqs,
          attachments: [
            ...rfqsState.rfqs.attachments,
            newFile
          ]
        }
      })
    }).catch((error) => {
      console.log(error);
    });
  };

  const rfqFileClicked = (data) => {
    const fileInfo = {
      id: rfqId,
      fileId: data.id
    }
 
    getRfqAttachment(fileInfo).then((response) => {

      const binaryString = window.atob(response.data.blob);

      const binaryLen = binaryString.length;

      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }


      const url = window.URL.createObjectURL(new Blob([bytes], {type:response.data.type}));
 
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.name);

    
      document.body.appendChild(link);
      link.click();
    });
  };

  const rfqFileDeleted = (data) => {
    const fileInfo = {
      id: rfqId,
      fileStorageid: data.filestorageId
    }

    const atts = rfqsState.rfqs.attachments.filter(attachment => attachment.id !== data.id);
    deleteRfqAttachment(fileInfo).then((response) => {
      if (response) {
        setRfqsState({
          ...rfqsState,
          rfqs: {
            ...rfqsState.rfqs,
            attachments: atts
          }
        })
      }
    });
  };

  const shouldSetStateToDisabled = (rfqsState.rfqs
    && rfqsState.rfqs.vessel
    && rfqsState.rfqs.vessel.id !== null
    && rfqsState.rfqs.supplier
    && rfqsState.rfqs.supplier !== null
    && rfqsState.rfqs.port
    && rfqsState.rfqs.port.id !== null
    && rfqsState.rfqs.reference_number
    && (rfqItemsState.rfqItems).length > 0
    && rfqItemsState.rfqItemEditIndex === -1&&rfqItemsState.rfqItemCreateObject===null
    );
  useEffect(() => {
    if (shouldSetStateToDisabled) {
      setDisabled(
        false
      );
    } else {
      setDisabled(
        true
      )
    }
  }, [shouldSetStateToDisabled])

  const handleDeleteRfq = (event) => {

    const rfqForDeletion = rfqId;
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });

    setRfqsState({
      ...rfqsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Rfq',
        onCancel: () => {
          setRfqsState({
            ...rfqsState,
            confirmDialog: {
              ...rfqsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteRfq(rfqForDeletion).then(() => {
            window.location.replace('/transactions/' + rfqsState.rfqs.transaction.id);
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };


  const handleReviseRfq = (e) => {

    const rfq = {
      id: rfqsState.rfqs.id
    };

    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    reviseRfq(rfq)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isRfqSaved: true
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isRfqSaved: false
        });
      });
  };

  const handlePrint = (e) => {

    const rfq = {
      id: rfqsState.rfqs.id
    };

    printRfq(rfq)
      .then(response => {
        const binaryString = window.atob(response.data.blob);

        const binaryLen = binaryString.length;
  
        const bytes = new Uint8Array(binaryLen);
  
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
  
  
        const url = window.URL.createObjectURL(new Blob([bytes], {type:response.data.type}));
   
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
      });
  };

  const handleDeclineRfq = (e) => {

    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    declineRFQ({ id: rfqsState.rfqs.id }, rfqsState.rfqs)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isRfqSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isRfqSaved: false
        });
      });
  };

  
  const handleSubmitRfq = (e) => {

    const rfq = {
      id: rfqsState.rfqs.id
    };
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    submitRFQ(rfq)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          rfqs: res.data,
          isLoading: false,
          isRfqSaved: true
        });
        setNewFiles(() => { return []  } );
        setDropKey(  dropkey+ 1 );
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
          isRfqSaved: false
        });
      });
  };

  const rfqSupplierNotes = (data) => {
   
    const rfqEditObject = Object.assign({}, rfqsState.rfqs);
    if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
      rfqEditObject.supplierNotes = data.notes;
    } else {
      rfqEditObject.supplierNotes = null;
    }

    setRfqItemEditObject(rfqEditObject);

    setRfqsState({
      ...rfqsState,
      rfqs:rfqEditObject,
      isLoading: false,
      isSupplierNotesSaved: true
    });
  };

  const handleQuote = (e) => {

    const rfq = {
      id: rfqsState.rfqs.id
    };
    setRfqsState({
      ...rfqsState,
      isLoading: true
    });
    createQuoteFromRFQ(rfq)
      .then(res => {
        setRfqsState({
          ...rfqsState,
          isLoading: false,
        });
        window.location.assign('/transactions/' + rfqsState.rfqs.transaction.id + '/quotes/' + res.data);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setRfqsState({
          ...rfqsState,
          isLoading: false,
        });
      });

  };

  
  const handleEvaluateSupplier = (e) => {

    window.location.assign('/transactions/' + rfqsState.rfqs.transaction.id + '/suppliers/' + rfqsState.rfqs.supplier.id);

  };


  const handleEvaluateItems = (e) => {

    window.location.assign('/transactions/' + rfqsState.rfqs.transaction.id + '/rated-products/' + rfqsState.rfqs.supplier.id);

  };

  const selectedUnit = (data, action) => {

    // when FilterUnits returns null, make it empty string so
    // that the validator wont break when unit value is deleted 
    if (data.unit === null) {
      data.unit = '';
    }

    const newEditRfqItem = Object.assign({},
      action === 'edit'
        ? rfqItemsState.rfqItemEditObject
        : rfqItemsState.rfqItemCreateObject
    );

    newEditRfqItem.unit = data.unit;

    setRfqItemsState({
      ...rfqItemsState,
      [
        action === 'edit'
          ? 'rfqItemEditObject'
          : 'rfqItemCreateObject'
      ]: newEditRfqItem
    });

  };

  const handleSearchItems = (data) => {

    setRfqItemsState({
      ...rfqItemsState,
      isLoading: true
    });

    let filtersData = {
      search: data.search,
      per_page: rfqItemCriteria.per_page,
      page: 1,
      id: rfqId
    };

    getRfqItems(filtersData).then(res => {
      const { results } = res.data;

      setRfqItemsState({
        ...rfqItemsState,
        rfqItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRfqItemsState({
        ...rfqItemsState,
        isLoading: false,
      });
    });
  };

  const classes = useStyles();

  const tableFilterComponent = () => {
    return <TableFilterItems
      config={{
        search: {
          enabled: true,
          callback: (e) => handleSearchItems(e)
        }
      }}

    />;
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      order={rfqItemsState.page.order_type}
      orderBy={rfqItemsState.page.order_by}
      rowCount={rfqItemsState.page.length} />;
  };

  const tablePrint = () => {
    return <PrintPdf title={t('PRINT')} handlePrint={handlePrint}/>
  };

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        revise: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("REVISE"),
          callback: (e) => handleReviseRfq(e)
        },
        delete: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("DELETE"),
          callback: (e) => handleDeleteRfq(e)
        },
        sync: {
          enabled: false,
        },
        submit: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("SUBMIT"),
          disabled: disabled,
          callback: (e) => handleSubmitRfq(e)
        },
        quote: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("QUOTE"),
          callback: (e) => handleQuote(e)
        },
        evaluateItems: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("EVALUATE_ITEMS"),
          callback: (e) => handleEvaluateItems(e)
        },
        evaluateSupplier: {
          enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("EVALUATE_SUPPLIER"),
          callback: (e) => handleEvaluateSupplier(e)
        },
        // decline: {
        //   enabled: rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("DECLINE"),
        //   callback: (e) => handleDeclineRfq(e)
        // },
      }}

    />;
  };


  const infoTab = () => {
    return (
      (rfqsState.rfqs).length !== 0 &&
      <RfqInfo
        vessel={rfqsState.rfqs.vessel|| "-"}
        company={rfqsState.rfqs.supplier|| "-"}
        userRole={props.userState.user.userRole}
        buyer={rfqsState.rfqs.buyer}
        alert={rfqsState.rfqs.alert}
        referenceNumber={rfqsState.rfqs.reference_number}
        catalogue={rfqsState.rfqs.category ? rfqsState.rfqs.category.name : "-"}
        port={rfqsState.rfqs.port ? rfqsState.rfqs.port : "-"}
        due={rfqsState.rfqs.due}
        submitted={rfqsState.rfqs.submitted ? rfqsState.rfqs.submitted.slice(0, 10) : "-"}
        periodDue={rfqsState.rfqs.periodDue || "-"}
        status={rfqsState.rfqs.status || "-"}
        check={rfqsState.rfqs.isCompleted}
        isRevised={rfqsState.rfqs.isRevised}
        supplierNotes={rfqsState.rfqs.supplierNotes}
        notes={rfqsState.rfqs.notes}
        edit={rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("UPDATE")}
        companySearchFor={'supplier'}
        saved={rfqsState.isRfqSaved}
        savedVessel={rfqsState.isVesselSaved}
        savedPort={rfqsState.isPortSaved}
        savedDue={rfqsState.isDueSaved}
        savedNotes={rfqsState.isNotesSaved}
        savedSupplierNotes={rfqsState.isSupplierNotesSaved}
        savedReferenceNumber = {rfqsState.isReferenceNumberSaved}
        savedSupplier={rfqsState.isSupplierSaved}
        error={rfqsState.error}
        getSupplierNotes={rfqSupplierNotes}
        getNotes={rfqNotes}
        showSupplierNotes={rfqsState.rfqs.status==='Declined'}
        editSupplier={rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("DECLINE")}
        getReferenceNumber={rfqReferenceNumber}
        getSelectedVessel={selectedVessel}
        getSelectedCompany={selectedCompany}
        getSelectedPort={selectedPort}
        getSelectedDate={selectedDate}
        handleDecline={handleDeclineRfq}
      />
    )
  };



  const attachments = () => {
    return (<DropzoneComponent
      initialFiles={rfqsState.rfqs.attachments}
      title={'Attachments'}
      enabled={rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("UPDATE")}
      newFiles={newFiles}
      key={dropkey}
      getFiles={rfqFiles}
      getFileClicked={rfqFileClicked}
      getFileDeleted={rfqFileDeleted}
    />)
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {rfqsState.rfqs && rfqsState.rfqs.actions && rfqItemsState.rfqItems.map((rfqItem, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              {
                (rfqItemsState.rfqItemEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{rfqItem.product.code}</TableCell>
                    <TableCell>{rfqItem.product.name}</TableCell>
                    <TableCell>{rfqItem.quantity}</TableCell>
                    <TableCell>{rfqItem.unit.name}</TableCell>
                    <TableCell>{rfqItem.notes}</TableCell>
                  </>
                  : <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      {rfqItemsState.rfqItemEditObject.product.code}
                    </TableCell>

                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterProducts
                          product={rfqItemsState.rfqItemEditObject.product}
                          catalogue={rfqsState.rfqs.category.id || ""}
                          supplier={rfqsState.rfqs.supplier?.id}
                          port={rfqsState.rfqs.port?.id}
                          getSelectedProduct={(data) => selectedProduct(data, 'edit')}
                          onBlur={simpleValidator.current.showMessageFor('product')}
                        />
                        {simpleValidator.current.message('product', rfqItemsState.rfqItemEditObject.product.id, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>

                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          defaultValue={rfqItemsState.rfqItemEditObject.quantity}
                          onChange={e => onEditableRfqItemChange(e, 'quantity')}
                          onBlur={simpleValidator.current.showMessageFor('quantity')}
                        />
                        {simpleValidator.current.message('quantity', rfqItemsState.rfqItemEditObject.quantity, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>

                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterUnits
                          unit={rfqItemsState.rfqItemEditObject.unit}
                          supplier={rfqsState.rfqs.supplier?.id}
                          port={rfqsState.rfqs.port?.id}
                          product={rfqItemsState.rfqItemEditObject.product?.id}
                          getSelectedUnit={(data) => selectedUnit(data, 'edit')}
                          onBlur={simpleValidator.current.showMessageFor('unit')}
                        />
                        {simpleValidator.current.message('unit', rfqItemsState.rfqItemEditObject.unit.id, 'required|numeric|min:1,num')}
                      </FormControl>
                    </TableCell>

                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          defaultValue={rfqItemsState.rfqItemEditObject.notes}
                          onChange={e => onEditableRfqItemChange(e, 'notes')}
                          fullWidth
                          multiline
                          variant="outlined"
                          maxRows={10}
                        />
                      </FormControl>
                    </TableCell>
                  </>
              }
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: rfqsState.rfqs.actions.includes("UPDATE") && rfqItemsState.rfqItemEditIndex !== index,
                    callback: (e) => handleEditRfqItem(e, index)
                  },
                  delete: {
                    enabled: rfqsState.rfqs.actions.includes("UPDATE") && rfqItemsState.rfqItemEditIndex !== index,
                    callback: (e) => handleDeleteRfqItem(e, index)
                  },
                  close: {
                    enabled: rfqItemsState.rfqItemEditIndex === index,
                    callback: (e) => handleCloseEditRfqItem(e, index)
                  },
                  save: {
                    enabled: rfqItemsState.rfqItemEditIndex === index,
                    callback: (e) => handleSaveRfqItem(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        }
        )}
        {
          rfqItemsState.rfqItemCreateObject &&
          <TableRow className={classes.tableRow}>
            <TableCell onClick={e => e.stopPropagation()}>
              {rfqItemsState.rfqItemCreateObject.product.code}
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProducts
                  product={rfqItemsState.rfqItemCreateObject.product}
                  catalogue={rfqsState.rfqs.category.id || ""}
                  supplier={rfqsState.rfqs.supplier?.id}
                  port={rfqsState.rfqs.port?.id}
                  getSelectedProduct={(data) => selectedProduct(data, 'create')}
                  onBlur={simpleValidator.current.showMessageFor('product')} />
                {simpleValidator.current.message('product', rfqItemsState.rfqItemCreateObject.product.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  defaultValue={rfqItemsState.rfqItemCreateObject.quantity}
                  onChange={e => onNewRfqItemChange(e, 'quantity')}
                  onBlur={simpleValidator.current.showMessageFor('quantity')} />
                {simpleValidator.current.message('quantity', rfqItemsState.rfqItemCreateObject.quantity, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnits
                  unit={rfqItemsState.rfqItemCreateObject.unit}
                  getSelectedUnit={(data) => selectedUnit(data, 'create')}
                  supplier={rfqsState.rfqs.supplier?.id}
                  port={rfqsState.rfqs.port?.id}
                  product={rfqItemsState.rfqItemCreateObject.product?.id}
                  onBlur={simpleValidator.current.showMessageFor('unit')} />
                {simpleValidator.current.message('unit', rfqItemsState.rfqItemCreateObject.unit.id, 'required|numeric|min:1,num')}
              </FormControl>
            </TableCell>

            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  defaultValue={rfqItemsState.rfqItemCreateObject.notes}
                  fullWidth
                  multiline
                  variant="outlined"
                  maxRows={10}
                  onChange={e => onNewRfqItemChange(e, 'notes')}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setRfqItemsState({ ...rfqItemsState, rfqItemCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewRfqItem()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  };


  return (
    <>{rfqsState.rfqs && rfqsState.rfqs.transaction&&rfqsState.rfqs.supplier && <MembersChat
      entityId={rfqsState.rfqs.id}
      entityType={'rfq'}
      relatedId={rfqsState.rfqs.transaction.id}
      ownerId={ props.userState.user.userId }
      recipient= { props.userState.user.userRole==="BUYER" ? rfqsState.rfqs.supplier:rfqsState.rfqs.buyer }
      supplierId= { rfqsState.rfqs.supplier.id }
      user={props.userState}
    />}
      {rfqsState.rfqs && rfqsState.rfqs.actions && <OnlineAssistant
        path={'rfq'}
        user={props.userState.user.userRole}
        submit={rfqsState.rfqs && rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("SUBMIT")}
      />}
      <ProcureDataTable
        toolbarTitle={''}
        infoTabTitle={t('REQUEST_FOR_QUOTATION')}
        pagination={{
          total: rfqItemsState.page.total || 0,
          page: rfqItemsState.page.page - 1 || 0,
          perPage: rfqItemsState.page.per_page || 10,
          count: rfqItemsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={handleAddNewRfqItem}
        newRowActionAvailable={rfqsState.rfqs.actions && rfqsState.rfqs.actions.includes("UPDATE")}
        newRowBtnEnabled={!rfqItemsState.rfqItemCreateObject}
        confirmDialog={rfqItemsState.confirmDialog.message.length > 0 ? rfqItemsState.confirmDialog : rfqsState.confirmDialog}
        infoTab={infoTab}
        breadcrump={breadcrump}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        tablePrint={tablePrint}
        attachments={attachments}
        isLoading={rfqItemsState.isLoading}
        itemsTableTitle={t('ITEMS')}
      />
    </>
  );
};

export default RfqItems;