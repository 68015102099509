import React, { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeader from '../../components/tableHeader';
import { urlParams } from "../../helpers/urlParams";
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import useStyles from './styles';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/regionPurchasesConfig';
import { getRegionReport } from '../../services/reportsService';
import { TableFilterRegionPurchases } from '../../components/tableFilterRegionPurchases';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import ChartWithGrid from "../../components/statistics/chartWithGrid";
import DoughnutChartWithGrid from "../../components/statistics/doughnutChartWithGrid";
import MiniCard from '../../components/miniCard';
import t from '../../helpers/languages';
import './_regionPurchases.scss';


const RegionPurchases = (props) => {

  const classes = useStyles();


  const [regionPurchasesState, setRegionPurchasesState] = useState({
    isLoading: false,
    filters: {},
    page: {
      page: 1,
      count: 0,
      per_page: 20,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    portStatData: [],
    regionStatData: [],
    reportData: [],
    totalAmount:null,
    totalAmountUSD:null,
  });

  const filters = localStoreGetObj('regionPurchasesFilter');

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 20)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  const userState = props.userState && props.userState.user;

  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...regionPurchasesState.filters,
      port: filters && filters.port && filters.port.id,
      buyer: filters && filters.company && filters.company.id,
      category: filters && filters.category && filters.category.id,
      continent: filters && filters.continent && filters.continent.id,
      region: filters && filters.region && filters.region.id,
      country: filters && filters.country && filters.country.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...regionPurchasesState.filters,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.company && filters.company.id,
      category: filters && filters.category && filters.category.id,
      continent: filters && filters.continent && filters.continent.id,
      region: filters && filters.region && filters.region.id,
      country: filters && filters.country && filters.country.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }

  useEffect(() => {

    setRegionPurchasesState({
      ...regionPurchasesState,
      isLoading: true,
    });

    getRegionReport(filtersData).then(res => {
      const results = res.data;
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
        portStatData: results.portStatData,
        regionStatData: results.regionStatData,
        reportData: results.reportData,
        page: res.data.page,
        totalAmount: res.data.totalAmount,
        totalAmountUSD: res.data.totalAmountUSD,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      console.log(error);
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setRegionPurchasesState({
      ...regionPurchasesState,
      isLoading: true
    });
    
    const newCriteria = {
      ...regionPurchasesState.filters,
      per_page:regionPurchasesState.page.per_page,
      page: newPage + 1
    };

    getRegionReport(newCriteria).then(res => {
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
        reportData: res.data.reportData,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRegionPurchasesState({
      ...regionPurchasesState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...regionPurchasesState.filters,
      page: 1,
      per_page: perPage,
    };

    getRegionReport(newCriteria).then(res => {
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
        reportData: res.data.reportData,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
      });
    });
  };

  const filterData = (data) => {
    setRegionPurchasesState({
      ...regionPurchasesState,
      isLoading: true,
    });

    let filtersData = '';
 
    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...regionPurchasesState.filters,
        port: data && data.port && data.port.id,
        buyer: data && data.company && data.company.id,
        category: data && data.category && data.category.id,
        continent: data && data.continent && data.continent.id,
        region: data && data.region && data.region.id,
        country: data && data.country && data.country.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...regionPurchasesState.filters,
        port: data && data.port && data.port.id,
        supplier: data && data.company && data.company.id,
        category: data && data.category && data.category.id,
        continent: data && data.continent && data.continent.id,
        region: data && data.region && data.region.id,
        country: data && data.country && data.country.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    }
    
    getRegionReport(filtersData).then(res => {
      const results = res.data;
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
        portStatData: results.portStatData,
        regionStatData: results.regionStatData,
        reportData: results.reportData,
        page: res.data.page,
        totalAmount: res.data.totalAmount,
        totalAmountUSD: res.data.totalAmountUSD,
        filters: res.data.filters
      });
      localStoreSetObj('regionPurchasesFilter', data);
    }).catch((error) => {
      console.log(error);
      setRegionPurchasesState({
        ...regionPurchasesState,
        isLoading: false,
      });
    });
  };




  const tableFilterComponent = () => {
    return <TableFilterRegionPurchases
      companyField={true}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"} // set buyer filter for supplier and vice versa.
      company={(filters && filters.company) ? filters.company : regionPurchasesState.filters.company}
      companyCategory={(filters && filters.category) ? filters.category : regionPurchasesState.filters.category}
      continent={(filters && filters.continent) ? filters.continent : regionPurchasesState.filters.continent}
      region={(filters && filters.region) ? filters.region : regionPurchasesState.filters.region}
      country={(filters && filters.country) ? filters.country : regionPurchasesState.filters.country}
      port={(filters && filters.port) ? filters.port : regionPurchasesState.filters.port}
      from={(filters && filters.from) ? filters.from : regionPurchasesState.filters.from_date}
      to={(filters && filters.to) ? filters.to : regionPurchasesState.filters.to_date}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={() => { }}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {!regionPurchasesState.isLoading && regionPurchasesState.reportData && regionPurchasesState.reportData.map((data, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>{data.region && data.region.name}</TableCell>
              <TableCell>{(data.buyer !== undefined && data.supplier !== undefined) ? (userState.userRole === 'SUPPLIER' ? data.buyer.name : data.supplier.name) : ''}</TableCell>
              <TableCell>{data.supplierCategory && data.supplierCategory.name}</TableCell>
              <TableCell>{data.port && data.port.name}</TableCell>
              <TableCell>{data.totalAmountUSD}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <main className='dashboard'>
      <div className='dashboard__total-amount-container'>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={3}
        >
          <Grid item md={8}>
            <ProcureDataTable
              toolbarTitle={t('REGION_PURCHASES')}
              pagination={{
                total: regionPurchasesState.page.total || 0,
                page: regionPurchasesState.page.page - 1 || 0,
                perPage: regionPurchasesState.page.per_page || 10,
                count: regionPurchasesState.page.count || 0,
                handleChangePage: handleChangePage,
                handleChangeRowsPerPage: handleChangeRowsPerPage,
              }}
              addNewRawBtnTitle={''}
              onNewRowClick={() => { }}

              confirmDialog={{
                message: '',
                title: '',
                onCancel: () => { },
                onConfirm: () => { },
                isLoading: false,
                isOpen: false
              }}
              tableFilter={tableFilterComponent}
              autonomousPage={props.autonomousPage}
              tableHeader={tableHeaderComponent}
              tableBody={tableBodyComponent}
              noWidth={true}
              isLoading={regionPurchasesState.isLoading}
            />
          </Grid>
          <Grid item md={4}>
            <Grid item xs={12}>
              <Paper
                elevation={4}
              >
                <MiniCard
                  title={'Total Amount Spent '}
                  number={regionPurchasesState.totalAmountUSD}>
                </MiniCard>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
              >
                {!regionPurchasesState.isLoading && <ChartWithGrid
                  theme={'red'}
                  data={regionPurchasesState.portStatData.map((stat) => (stat.totalAmount))}
                  labels={regionPurchasesState.portStatData.map((stat) => (stat.port.name))}
                  amount={''}
                  title={'Top spending ports'}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
                sx={{marginBottom:'10px'}}
              >
                {!regionPurchasesState.isLoading &&<DoughnutChartWithGrid
                  theme={'green'}
                  id={'regionStatData'}
                  doughnutWidth={'650px'}
                  data={regionPurchasesState.regionStatData.map((stat) => (stat.percent))}
                  labels={regionPurchasesState.regionStatData.map((stat) => (stat.region.name))}
                  title={'Spending by Region'}
                  subtitleToolbarTitle={''}
                />}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default RegionPurchases;