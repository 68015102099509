import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState, useRef } from "react";

import { ProcureDataTable } from "../../common/ProcureDataTable/ProcureDataTable";
import TableHeader from "../../components/tableHeader";
import { localStoreSetObj, localStoreGetObj } from "../../helpers/storage";
import {
  tableHeadersCompany,
  newCompany,
} from "../../helpers/companyConfig.js";
import { urlParams } from "../../helpers/urlParams";
import useStyles from "./styles";
import TableActions from "../../components/TableActions/TableActions";
import SimpleReactValidator from "simple-react-validator";
import {
  getAllCompaniesInfo,
  editCompany,
  deleteCompany,
  addCompany,
} from "../../services/companiesService";
import { getAuthorizationMappings } from './../../services/authorizationsService';
import FilterPorts from "../../components/filterPorts";
import FilterCodingSystems from "../../components/filterCodingSystems";
import FilterCompanyCategories from "../../components/filterCompanyCategories";
import { CompanyTableFilter } from "../../components/companyTableFilter";
import { Link as RouterLink } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import FilterCountries from '../../components/filterCountries';
import t from "../../helpers/languages";
import "./_companies.scss";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const Companies = (props) => {
  const simpleValidator = useRef(new SimpleReactValidator());

  const companyInitObject = () => {
    return {
      id: "?",
      name: "",
      admin: "",
      supplier: false,
      codingSystem: null,
      address: "",
      country: "",
      enabled: "",
      notes: "",
      port: "",
      email: null,
      category: "",
    };
  };

  const classes = useStyles();

  const filters = localStoreGetObj('companyFilter');
  
  const userState = props.userState && props.userState.user;

  const [companiesState, setCompaniesState] = useState({
    companies: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: "asc",
      order_by: "id",
    },
    filters: {},
    isLoading: false,
    companyEditIndex: -1,
    companyEditObject: companyInitObject(),
    companyCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    confirmDialog: {
      message: "",
      title: "",
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false,
    },
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10);
  !criteria.page && (criteria.page = 1);

  urlParams.setUrlParams(criteria);

  let filtersData = {};

  if (userState.userRole === 'SUPPLIER' || userState.userRole === 'BUYER') {
    filtersData = {
      ...companiesState.filters,
      search: filters && filters.company,
      isSupplier: true,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: companiesState.page.order_type,
      order_by: companiesState.page.order_by,
    }
  } else {
    filtersData = {
      ...companiesState.filters,
      search: filters && filters.company,
      isSupplier: filters && filters.supplier,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: companiesState.page.order_type,
      order_by: companiesState.page.order_by,
    }
  }

  useEffect(function () {
    setCompaniesState({
      ...companiesState,
      isLoading: true,
    });


    getAllCompaniesInfo(filtersData)
      .then((res) => {
        const { results } = res.data;
        setCompaniesState({
          ...companiesState,
          companies: results,
          isLoading: false,
          page: res.data.page,
          filters: res.data.filters,
          authorizedActions: {
            ...getAuthorizationMappings('/companies', userState.userRole)
          }
        });
        urlParams.setUrlParams({
          page: res.data.page.page,
          per_page: res.data.page.per_page
        });
      })
      .catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setCompaniesState({
          ...companiesState,
          isLoading: false,
        });
      });
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setCompaniesState({
      ...companiesState,
      isLoading: true,
    });
    filtersData = {
      ...companiesState.filters,
      per_page: companiesState.page.per_page,
      page: newPage + 1,
    };
    getAllCompaniesInfo(filtersData).then((res) => {
      setCompaniesState({
        ...companiesState,
        isLoading: false,
        companies: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setCompaniesState({
      ...companiesState,
      isLoading: true,
    });
    const perPage = parseInt(event.target.value, 10);

    filtersData = {
      ...companiesState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllCompaniesInfo(filtersData)
      .then((res) => {
        setCompaniesState({
          ...companiesState,
          isLoading: false,
          companies: res.data.results,
          page: res.data.page,
          filters: res.data.filters,
        });
        urlParams.setUrlParams(filtersData);
      })
      .catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setCompaniesState({
          ...companiesState,
          isLoading: false,
        });
      });
  };

  const handleEditCompany = (event, companyIndex) => {
    event.stopPropagation();

    const companyEditObject = Object.assign(
      {},
      companiesState.companies[companyIndex]
    );

    setCompaniesState({
      ...companiesState,
      companyEditIndex: companyIndex,
      companyEditObject: companyEditObject,
    });
  };

  const onEditableCompanyChange = (e, property) => {
    let newValue = "";
    if (property === "supplier") {
      newValue = e.target.checked;
    } else {
      newValue = e.target.value;
    }

    const newEditCompanyItem = Object.assign(
      {},
      companiesState.companyEditObject
    );
    newEditCompanyItem[property] = newValue;

    setCompaniesState({
      ...companiesState,
      companyEditObject: newEditCompanyItem,
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        customEmail: {
          // name the rule
          message:
            "The :attribute must be a valid IP address and must be :values.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) && params.indexOf(val) === -1;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
      autoForceUpdate: { forceUpdate: forceUpdate },
      showMessages: true,
    })
  );

  const handleSaveCompany = (event, props) => {
    event.preventDefault();
    setCompaniesState({
      ...companiesState,
      isLoading: true,
    });
    const newEditCompanyItem = Object.assign(
      {},
      companiesState.companyEditObject
    );

    if (validator.current.allValid()) {
      editCompany(newEditCompanyItem.id, newEditCompanyItem)
        .then((re) => {
          const editedCompany = re.data;
          setCompaniesState((oldCompanies) => {
            return {
              ...oldCompanies,
              companies: oldCompanies.companies.map((company) => {
                return company.id === editedCompany.id ? re.data : company;
              }),
              companyEditIndex: -1,
            };
          });
        })
        .catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCompaniesState({
            ...companiesState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setCompaniesState({
        ...companiesState,
        isLoading: false,
      });
    }
  };

  const handleCloseEditCompany = (e, index) => {
    setCompaniesState({
      ...companiesState,
      companyEditIndex: -1,
    });
  };

  const handleDeleteCompany = (event, index) => {
    const companyForDeletion = companiesState.companies[index];
    setCompaniesState({
      ...companiesState,
      confirmDialog: {
        message: "Are you sure you want to delete?",
        title: "Delete Company",
        onCancel: () => {
          setCompaniesState({
            ...companiesState,
            confirmDialog: {
              ...companiesState.confirmDialog,
              isOpen: false,
            },
          });
        },
        onConfirm: () => {
          deleteCompany(companyForDeletion.id)
            .then(() => {
              const newCompanies = [...companiesState.companies];
              newCompanies.splice(index, 1);
              setCompaniesState({
                ...companiesState,
                companies: newCompanies,
              });
            })
            .catch((err) => {
              alert("ERROR");
            });
        },
        isLoading: false,
        isOpen: true,
      },
    });
  };

  const handleAddNewCompany = (e) => {
    e.preventDefault();

    setCompaniesState({
      ...companiesState,
      companyCreateObject: newCompany(),
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewCompany = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newCompany = {};
    newCompany.name = companiesState.companyCreateObject.name;
    newCompany.address = companiesState.companyCreateObject.address;
    newCompany.category = companiesState.companyCreateObject.category;
    newCompany.email = companiesState.companyCreateObject.email;
    newCompany.admin = companiesState.companyCreateObject.admin;
    newCompany.codingSystem = companiesState.companyCreateObject.codingSystem;
    newCompany.enabled = companiesState.companyCreateObject.enabled;
    newCompany.notes = companiesState.companyCreateObject.notes;
    newCompany.country = companiesState.companyCreateObject.country;
    newCompany.port = companiesState.companyCreateObject.port;
    newCompany.supplier = companiesState.companyCreateObject.supplier;

    if (validator.current.allValid()) {
      addCompany(newCompany)
        .then((res) => {
          setCompaniesState({
            ...companiesState,
            companies: [...companiesState.companies, res.data],
            companyCreateObject: null,
            companyEditIndex: -1,
            isLoading: false,
          });
        })
        .catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCompaniesState({
            ...companiesState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const selectedPort = (data, action) => {
    const newEditCompanyItem = Object.assign(
      {},
      action === "edit"
        ? companiesState.companyEditObject
        : companiesState.companyCreateObject
    );

    newEditCompanyItem.port = data.port;

    setCompaniesState({
      ...companiesState,
      [action === "edit" ? "companyEditObject" : "companyCreateObject"]:
        newEditCompanyItem,
    });
  };

  const selectedCompanyCategory = (data, action) => {
    const newEditCompanyItem = Object.assign(
      {},
      action === "edit"
        ? companiesState.companyEditObject
        : companiesState.companyCreateObject
    );

    newEditCompanyItem.category = data.companyCategory;

    setCompaniesState({
      ...companiesState,
      [action === "edit" ? "companyEditObject" : "companyCreateObject"]:
        newEditCompanyItem,
    });
  };

  const selectedCodingSystem = (data, action) => {
    const newEditCompanyItem = Object.assign(
      {},
      action === "edit"
        ? companiesState.companyEditObject
        : companiesState.companyCreateObject
    );

    newEditCompanyItem.codingSystem = data.codingSystem;

    setCompaniesState({
      ...companiesState,
      [action === "edit" ? "companyEditObject" : "companyCreateObject"]:
        newEditCompanyItem,
    });
  };

  const selectedCountry = (data, action) => {

    const newEditCompanyItem = Object.assign({},
      action === 'edit'
        ? companiesState.companyEditObject
        : companiesState.companyCreateObject
    );

    newEditCompanyItem.country = data.country;

    setCompaniesState({
      ...companiesState,
      [action === "edit" ? "companyEditObject" : "companyCreateObject"]:
        newEditCompanyItem,
    });
  };

  

  const onEditNewCompanyObject = (e, property) => {
    let newValue = "";
    if (property === "supplier") {
      newValue = e.target.checked;
    } else {
      newValue = e.target.value;
    }

    const newEditCompanyItem = Object.assign(
      {},
      companiesState.companyCreateObject
    );
    newEditCompanyItem[property] = newValue;

    setCompaniesState({
      ...companiesState,
      companyCreateObject: newEditCompanyItem,
    });
  };

  const filterData = (data) => {
    setCompaniesState({
      ...companiesState,
      isLoading: true,
    });

    filtersData = {
      ...companiesState.filters,
      isSupplier: data && data.supplier,
      search: data && data.company,
    };

    getAllCompaniesInfo(filtersData)
      .then((res) => {
        const { results } = res.data;

        setCompaniesState({
          ...companiesState,
          companies: results,
          isLoading: false,
          page: res.data.page,
          filters: res.data.filters,
          authorizedActions: {
            ...getAuthorizationMappings('/companies', userState.userRole)
          }
        });
        localStoreSetObj("companyFilter", data);
      })
      .catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setCompaniesState({
          ...companiesState,
          isLoading: false,
        });
      });
  };

  const tableFilterComponent = () => {
    return (
      <CompanyTableFilter
        company={(filters && filters.company)? filters.company :companiesState.filters.company}
        supplier={(filters && filters.supplier) ?filters.supplier : companiesState.filters.supplier}
        enabledSupplier={companiesState.authorizedActions.create}
        getFilterData={filterData}
      />
    );
  };

  const tableHeaderComponent = () => {
    return (
      <TableHeader
        headCells={tableHeadersCompany}
        onSelectAllClick={() => { }}
        sort={false}
        order={""}
        orderBy={""}
        rowCount={""}
      />
    );
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(companiesState.companies) &&
          companiesState.companies.map((company, index) => {
            return (
              <TableRow key={index} className={classes.tableRow}>
                {companiesState.companyEditIndex !== index && index !== "?" ? (
                  <>                            
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        style={{ padding: '0px' }}
                        component={RouterLink}
                        to={{ pathname: `/companies/${company.id}` }}
                      >
                        <ManageSearchIcon></ManageSearchIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.supplier && <CheckIcon />}</TableCell>
                    <TableCell>{company.address}</TableCell>
                    <TableCell>{company.port && company.port.name}</TableCell>
                    <TableCell>{company.country && company.country.name}</TableCell>
                    <TableCell>{company.email}</TableCell>
                    <TableCell>{company.category.name}</TableCell>
                    <TableCell>
                      {company.codingSystem && company.codingSystem.name}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={companiesState.companyEditObject.name || ""}
                          onChange={(e) => onEditableCompanyChange(e, "name")}
                          onBlur={() =>
                            validator.current.showMessageFor("name")
                          }
                        />
                        {validator.current.message(
                          "name",
                          companiesState.companyEditObject.name,
                          "required"
                        )}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <Checkbox
                          checked={companiesState.companyEditObject.supplier}
                          onChange={(e) =>
                            onEditableCompanyChange(e, "supplier")
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={companiesState.companyEditObject.address || ""}
                          onChange={(e) =>
                            onEditableCompanyChange(e, "address")
                          }
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterPorts
                          port={companiesState.companyEditObject.port || null}
                          getSelectedPort={(data) => selectedPort(data, "edit")}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCountries
                          country={companiesState.companyEditObject.country || null}
                          getSelectedCountry={(data) => selectedCountry(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={companiesState.companyEditObject.email || ""}
                          onChange={(e) => onEditableCompanyChange(e, "email")}
                          onBlur={() =>
                            validator.current.showMessageFor("email")
                          }
                        />
                        {validator.current.message(
                          "email",
                          companiesState.companyEditObject.email,
                          "required"
                        )}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCompanyCategories
                          companyCategory={
                            companiesState.companyEditObject.category || null
                          }
                          getSelectedCompanyCategory={(data) =>
                            selectedCompanyCategory(data, "edit")
                          }
                          onBlur={simpleValidator.current.showMessageFor(
                            "companyCategory"
                          )}
                        />
                        {simpleValidator.current.message(
                          "companyCategory",
                          companiesState.companyEditObject.category.id,
                          "required|numeric|min:1,num"
                        )}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCodingSystems
                          codingSystem={
                            companiesState.companyEditObject.codingSystem
                          }
                          getSelectedCodingSystem={(data) =>
                            selectedCodingSystem(data, "edit")
                          }
                        />
                      </FormControl>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <TableActions
                    config={{
                      edit: {
                        enabled: companiesState.authorizedActions.update&&companiesState.companyEditIndex !== index,
                        callback: (e) => handleEditCompany(e, index),
                      },
                      delete: {
                        enabled: companiesState.authorizedActions.delete&&companiesState.companyEditIndex !== index,
                        callback: (e) => handleDeleteCompany(e, index),
                      },
                      close: {
                        enabled: companiesState.companyEditIndex === index,
                        callback: (e) => handleCloseEditCompany(e, index),
                      },
                      save: {
                        enabled: companiesState.companyEditIndex === index,
                        callback: (e) => handleSaveCompany(e, props),
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        {companiesState.companyCreateObject && (
          <TableRow className={classes.tableRow}>
            <TableCell onClick={e => e.stopPropagation()}>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={companiesState.companyCreateObject.name || ""}
                  onChange={(e) => onEditNewCompanyObject(e, "name")}
                  onBlur={() => validator.current.showMessageFor("name")}
                />
                {validator.current.message(
                  "name",
                  companiesState.companyCreateObject.name,
                  "required"
                )}
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <Checkbox
                  checked={companiesState.companyCreateObject.supplier}
                  onChange={(e) => onEditNewCompanyObject(e, "supplier")}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={companiesState.companyCreateObject.address || ""}
                  onChange={(e) => onEditNewCompanyObject(e, "address")}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterPorts
                  port={companiesState.companyCreateObject.port || null}
                  getSelectedPort={(data) => selectedPort(data, "create")}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCountries
                  country={companiesState.companyCreateObject.country || null}
                  getSelectedCountry={(data) => selectedCountry(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={companiesState.companyCreateObject.email || ""}
                  onChange={(e) => onEditNewCompanyObject(e, "email")}
                  onBlur={() => validator.current.showMessageFor("email")}
                />
                {validator.current.message(
                  "email",
                  companiesState.companyCreateObject.name,
                  "required"
                )}
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCompanyCategories
                  companyCategory={
                    companiesState.companyCreateObject.category || null
                  }
                  getSelectedCompanyCategory={(data) =>
                    selectedCompanyCategory(data, "create")
                  }
                  onBlur={simpleValidator.current.showMessageFor(
                    "companyCategory"
                  )}
                />
                {simpleValidator.current.message(
                  "companyCategory",
                  companiesState.companyCreateObject.category.id,
                  "required|numeric|min:1,num"
                )}
              </FormControl>
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCodingSystems
                  codingSystem={companiesState.companyCreateObject.codingSystem}
                  getSelectedCodingSystem={(data) =>
                    selectedCodingSystem(data, "create")
                  }
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions
                config={{
                  edit: {
                    enabled: false,
                    callback: () => null,
                  },
                  delete: {
                    enabled: false,
                    callback: () => null,
                  },
                  close: {
                    enabled: true,
                    callback: (e) =>
                      setCompaniesState({
                        ...companiesState,
                        companyCreateObject: null,
                      }),
                  },
                  save: {
                    enabled: true,
                    callback: (e) => onSaveNewCompany(),
                  },
                }}
              ></TableActions>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t("COMPANIES")}
        pagination={{
          total: companiesState.page.total || 0,
          page: companiesState.page.page - 1 || 0,
          perPage: companiesState.page.per_page || 10,
          count: companiesState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={""}
        newRowActionAvailable={companiesState.authorizedActions.create}
        onNewRowClick={handleAddNewCompany}
        newRowBtnEnabled={!companiesState.companyCreateObject}
        confirmDialog={
          companiesState.confirmDialog.message.length > 0
            ? companiesState.confirmDialog
            : companiesState.confirmDialog
        }
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={companiesState.isLoading}
      />
    </>
  );
};

export default Companies;
