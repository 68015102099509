import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import { tableHeadersProduct } from '../../helpers/productsConfig';
import TableHeader from '../../components/tableHeader';
import TableActions from '../../components/TableActions/TableActions';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FilterProductCategories from '../../components/filterProductCategories';
import FilterCodingSystems from "../../components/filterCodingSystems";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from './EditRowStyles';

const tableHeaderComponent = () => {
    return <TableHeader
        headCells={tableHeadersProduct}
        onSelectAllClick={() => { }}
        sort={false}
        order={''}
        orderBy={''}
        rowCount={''} />;
};

const EditRow = ({ validator,
    onEditableProductChange,
    handleSaveProduct,
    handleCloseEditProduct,
    unresolvedProducts,
    selectedCodingSystem,
    selectedProductCategory }) => {
    const classes = useStyles();
    return (
        <Table stickyHeader>
            {tableHeaderComponent()}
            <TableBody>
                <TableRow className={classes.tableRow}>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedProducts.productEditObject.code || ''}
                                onChange={e => onEditableProductChange(e, 'code')}
                                onBlur={() => validator.current.showMessageFor('code')}
                            />{validator.current.message('code', unresolvedProducts.productEditObject.code, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedProducts.productEditObject.name || ''}
                                onChange={e => onEditableProductChange(e, 'name')}
                                onBlur={() => validator.current.showMessageFor('name')}
                            />{validator.current.message('name', unresolvedProducts.productEditObject.name, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FilterProductCategories
                                category={unresolvedProducts.productEditObject.category}
                                getSelectedProductCategory={(data) => selectedProductCategory(data, 'edit')}
                            />
                            {validator.current.message('category', unresolvedProducts.productEditObject.category && unresolvedProducts.productEditObject.category.id, 'numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FilterCodingSystems
                                codingSystem={unresolvedProducts.productEditObject.coding}
                                getSelectedCodingSystem={(data) => selectedCodingSystem(data, 'edit')}
                            />{validator.current.message('coding', unresolvedProducts.productEditObject.coding && unresolvedProducts.productEditObject.coding.id, 'required|numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                variant="outlined"
                                control={
                                    <Switch
                                        checked={unresolvedProducts.productEditObject.isUnresolved}
                                        onChange={e => onEditableProductChange(e, 'isUnresolved')}
                                        name=" isUnresolved"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                }
                                label="IsUnresolved"
                            /></FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                variant="outlined"
                                control={
                                    <Switch
                                        checked={unresolvedProducts.productEditObject.isDiscountable}
                                        onChange={e => onEditableProductChange(e, 'isDiscountable')}
                                        name="isDiscountable"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                }
                                label="ΙsDiscountable"
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <TableActions config={{
                            edit: {
                                enabled: false,
                                callback: () => null
                            },
                            delete: {
                                enabled: false,
                                callback: () => null
                            },
                            close: {
                                enabled: true,
                                callback: (e) => handleCloseEditProduct(e, unresolvedProducts.productEditIndex)
                            },
                            save: {
                                enabled: true,
                                callback: (e) => handleSaveProduct(e)
                            }
                        }} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

}

export { EditRow };