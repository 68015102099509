import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import Typography from '@mui/material/Typography';
import './style.scss';

const DoughnutChartWithPercentage = (props) => {

    useEffect(() => {

        const data = props.stats.map((stat) => (stat.percent));
        const label = props.stats.map((stat) => (stat.catalogue.name));

        const con = document.getElementById('doughnutChartWithPercentage');
        let c = new Chart(con, {
            type: 'doughnut',
            data: {
                labels: label,
                datasets: [{
                    data: data,
                    label: "Rfq's",
                    backgroundColor: [
                        'rgba(102, 255, 181, 1)',
                        'rgba(124, 188, 255, 1)',
                        'rgba(243, 217, 102, 1)',
                        'rgba(245, 70, 95, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    // borderColor: [
                    //     'rgba(102, 255, 181, 1)',
                    //     'rgba(124, 188, 255, 1)',
                    //     'rgba(243, 217, 102, 1)',
                    //     'rgba(245, 70, 95, 1)',
                    //     'rgba(255, 206, 86, 1)',
                    // ],
                    borderWidth: .5,
                    lineWidth: .3
                }]
            },
            options: {
                cutout: '70%',
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            usePointStyle: true
                        }
                    },
                },
                responsive: false,
            }
        });
        return () => { c.destroy(); }
    }, [props.stats]);

    // const themes = {
    //     red: {
    //         fontColor: '#ff4861',
    //         progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
    //     },
    //     springGreen: {
    //         fontColor: '#00ff7f',
    //         progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
    //     },
    //     green: {
    //         fontColor: '#b8e986',
    //         progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
    //     },
    //     blue: {
    //         fontColor: '#48b5ff',
    //         progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
    //     },
    // };

    return (
        <section
            className='doughnutPerc-presenter'
        >
            <div className='column-doughnutPerc'>
                <Typography variant="h5" gutterBottom>{props.label}
                </Typography>

                {/* <Typography variant="subtitle1" gutterBottom>
                {props.subtitleToolbarTitle}
            </Typography> */}
            </div>
            <div className='column-doughnutPerc'>
                <div
                    className='doughnutPerc-presenter__total-amount'
                >
                </div>
                <canvas
                    id="doughnutChartWithPercentage"
                    width={250}
                    height={'100%'}
                >
                </canvas>
            </div>
        </section>
    )
};

export default DoughnutChartWithPercentage;