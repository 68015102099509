import httpService from './httpService';

const getRfqs = (criteria = {}) => {
  return httpService.FetchRFQList(criteria);
};

const getRfqById = (criteria = {}) => {
  return httpService.FetchRFQInfo(criteria);
};

const deleteRfq = (id) => {
  return httpService.DeleteRFQ(id);
};

const reviseRfq = (id) => {
  return httpService.ReviseRFQ(id);
};

const editRfq = (id, data) => {
  return httpService.UpdateRFQ({},data)
};

const printRfq = (id) => {
  return httpService.PrintRFQInfo(id)
};

const addRfqAttachment = (id, data) => {
  let fd = new FormData();
  data.map((file, index) => {
    return fd.append('file', file)
  });
  return httpService.CreateRFQAttachment(
    id, fd,

    {
      headers: {
        'Content-Type': 'multipart/form-data',

      },

    });
};

const getRfqAttachment = (criteria = {}) => {
  return httpService.FetchRFQAttachment(criteria, {responseType: 'arraybuffer'});
};

const deleteRfqAttachment = (data) => {
  return httpService.DeleteRFQAttachment(data);
};

const addRfq = (data) => {
  return httpService.CreateRFQ({},data);
};

const addRfqSupplier = (data) => {
  return httpService.AddRFQSupplier(data);
};

const createQuoteFromRFQ = (data) => {
  return httpService.CreateQuoteFromRFQ(data);
};

const submitRFQ = (data) => {
  return httpService.SubmitRFQ(data);
};

const declineRFQ = (id, data) => {
  return httpService.DeclineRFQ({ id: id.id }, data);
};

// const updateRfqStatus = (id, data) => {
//   return httpService.UpdateRfqStatus(  { id: id.id }, data);
// };

export {
  getRfqs,
  getRfqById,
  deleteRfq,
  declineRFQ,
  editRfq,
  addRfq,
  reviseRfq,
  getRfqAttachment,
  addRfqSupplier,
  createQuoteFromRFQ,
  addRfqAttachment,
  submitRFQ,
  deleteRfqAttachment,
  printRfq
};
