
const tableHeadersUser = [
  { id: 'view', disablePadding: false, },
  { id: 'userName', disablePadding: false, label: 'userName' },
  { id: 'firstName', disablePadding: false, label: 'firstName',},
  { id: 'lastName', disablePadding: false, label: 'lastName' },
  { id: 'email', disablePadding: false, label: 'email' },
  { id: 'joined', disablePadding: false, label: 'joined' },
  { id: 'isActive', disablePadding: false, label: 'isActive' },
  { id: 'isSuperUser', disablePadding: false, label: 'isSuperUser' },
  { id: 'actions', disablePadding: false, label: '' },
];
// Empty AccountSettings
const newUser = () => {
  return {
    id: '?',
    firstName: null,
    lastName: null,
    userName: null,
    email: null,
    companyId: null,
    password: null,
    joined: null,
    isActive: true,
    isSuperUser: false,
    showPassword: false,
  }
};

export { tableHeadersUser, newUser };
