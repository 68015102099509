import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px',
      width: '100%',
    },
    createProductButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 20,
    },
    paper: {
      width: '100%',
      padding: '0 20px',
      marginBottom: theme.spacing('2px'),
    },
    table: {
      minWidth: '1160px',
    },
    tableRow: {
      cursor: 'pointer',
    },
    editButton: {
      padding: 3,
    },
    editIcon: {
      fontSize: 32,
    },
    addProductButton: {
      position: 'fixed',
      right: 30,
      bottom: 30,
    },
    blueHover: {
      padding: '0px',
      verticalAlign: 'left',
      justifyContent:  'left',
      alignItems: 'left',
      textAlign: 'left',
      position: 'relative',
      color:'inherit',
      display: 'flex',
      flexGrow: 1,
      width: 'auto',
      "&:hover": {
        color: '#036df4'
      }
    }
  };
});

export default useStyles;
