import { createTheme } from "@mui/material/styles";

const overrides = createTheme(({
    overrides: {
      MuiDropzoneArea: {
        icon: {
          display:'none',
        },
      },
      MuiDropzonePreviewList: {
        root: {
          justifyContent: 'left'
        }
      }
    }
  }));

  export default overrides;