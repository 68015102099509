const tableHeaders = [
    { id: 'product_code', disablePadding: false, label: 'Product Code', width: '8%', align: 'center'},
    { id: 'product_name', disablePadding: false, label: 'Product Name', width: '12%' },
    { id: 'quantity', disablePadding: false, label: 'Qnt (Req/Avail)', width: '9%' },
    { id: 'unit_name', disablePadding: false, label: 'Unit (Req/Avail)', width: '20%' },
    { id: 'unit_cost', disablePadding: false, label: 'Unit Cost (Req/Avail)' , width: '11%'},
    { id: 'discount', disablePadding: false, label: 'Discount', width: '8%' },
    { id: 'total_cost', disablePadding: false, label: 'Cost', width: '6%' },
    { id: 'notes', disablePadding: false, label: 'Notes', width: '20%' },
    { id: 'row-actions', disablePadding: false, width: '6%' },
  ];
  
  const tableHeadersEvaluate = [
    { id: 'product_code', disablePadding: false, label: 'Product Code', width: '8%', align: 'center'},
    { id: 'product_name', disablePadding: false, label: 'Product Name', width: '20%' },
    { id: 'evaluate', disablePadding: false, label: 'Evaluate', width: '10%' },
    { id: 'notes', disablePadding: false, label: 'Notes', width: '62%' },
  ];

  // Empty orderitem
const newOrderItem = () => {
  return {
    category: { id: '', name: '' },
    notes: '',
    id: '',
    product: { code: '', id: '', name: '' },
    quantity: '',
    remote_id: '',
    orderitem_id: '',
    unit: { id: '', name: '' },
    add: true
  }
};

  export { tableHeaders, tableHeadersEvaluate, newOrderItem };
  