import httpService from './httpService';

const getQuoteItems = (criteria = {}) => {
  return httpService.FetchQuoteItemList(criteria);
};

// const add = (data) => {
//   return httpService.CreateQuoteItem({}, data);
// };

const edit = (id, data) => {
  return httpService.UpdateQuoteItem({}, data);
};

// const deleteQuoteItem = (id) => {
//   return httpService.DeleteQuoteItem(id);
// };

const bulkEditQuoteItems = (id,data) => {
  return httpService.UpdateListOfQuoteItems({id},data);
};

export { getQuoteItems, edit, bulkEditQuoteItems };
