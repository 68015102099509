
const tableHeadersBuyer = [
  { id: 'view', disablePadding: false, width:'1%'},
  { id: 'referencenum', disablePadding: false, label: 'Reference', width: '15%' },
  { id: 'supplier', disablePadding: false, label: 'Supplier', width: '10%' },
  { id: 'vessel', disablePadding: false, label: 'Vessel', width: '10%' },
  { id: 'category', disablePadding: false, label: 'Catalogue', width: '10%' },
  { id: 'total', disablePadding: false, label: 'Total', width: '5%' },
  { id: 'submitted', disablePadding: false, label: 'Submitted', width: '10%' },
  { id: 'due', disablePadding: false, label: 'Due', width: '7%' },
  { id: 'port', disablePadding: false, label: 'Port', width: '18%' },
  { id: 'status', disablePadding: false, label: 'Status',align:'center', width: '5%' },
  { id: 'row-actions', disablePadding: false, width: '10%' },
];

const tableHeadersSupplier = [
  { id: 'view', disablePadding: false, width:'1%'},
  { id: 'referencenum', disablePadding: false, label: 'Reference', width: '15%' },
  { id: 'buyer', disablePadding: false, label: 'Customer', width: '10%' },
  { id: 'vessel', disablePadding: false, label: 'Vessel', width: '10%' },
  { id: 'category', disablePadding: false, label: 'Catalogue', width: '10%' },
  { id: 'total', disablePadding: false, label: 'Total', width: '5%' },
  { id: 'submitted', disablePadding: false, label: 'Submitted', width: '10%' },
  { id: 'due', disablePadding: false, label: 'Due', width: '7%' },
  { id: 'port', disablePadding: false, label: 'Port', width: '18%' },
  { id: 'status', disablePadding: false, label: 'Status', align:'center',width: '5%' },
  { id: 'row-actions', disablePadding: false, width: '10%' },
];

export { tableHeadersBuyer, tableHeadersSupplier };
