import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { urlParams } from "../../helpers/urlParams";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/quotesConfig';
import { TableFilter } from '../../components/tableFilter';
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import { getSupplyStatus } from '../../services/supplyStatusService';
import { getQuotes, deleteQuote } from '../../services/quotesService';
import t from '../../helpers/languages';
import {StatusIndicator} from "../../common/StatusIndicator/StatusIndicator";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const Quotes = (props) => {
  const quoteInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      status: '',
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      due: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      quoted_items: '',
      isCompleted: ''
    }
  };

  const filters = localStoreGetObj('quoteFilter');
  const [quoteStatusState, setQuoteStatusState] = useState({
    quoteStatuses: []
  });

  const [quotesState, setQuotesState] = useState({
    quotes: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {},
    isLoading: true,
    quoteEditIndex: -1,
    quoteEditObject: quoteInitObject(),
    quoteCreateObject: null,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const userState = props.userState && props.userState.user;

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...quotesState.filters,
      port: filters && filters.port && filters.port.id,
      buyer: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...quotesState.filters,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }
 

  useEffect(() => {
    const statusName = { type: 'quote' };

    getSupplyStatus(statusName).then(res => {
      setQuoteStatusState({
        ...quoteStatusState.quoteStatuses,
        quoteStatuses: res.data,
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
    }).finally(() => {

    });

    getQuotes(filtersData).then(res => {
      const { results } = res.data;
      setQuotesState({
        ...quotesState,
        quotes: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuotesState({
        ...quotesState,
        isLoading: false,
      });
    })
// eslint-disable-next-line  
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();

    const isAsc = property === quotesState.page.order_by && quotesState.page.order_type === 'desc' ? 'asc' : 'desc';

    setQuotesState({
      ...quotesState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;
    filtersData.page = quotesState.page.page;
    filtersData.per_page = quotesState.page.per_page;

    getQuotes(filtersData).then(res => {
      const { results } = res.data;
      setQuotesState({
        ...quotesState,
        quotes: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuotesState({
        ...quotesState,
        isLoading: false,
      });
    })

  };


  const handleChangePage = (event, newPage) => {
    setQuotesState({
      ...quotesState,
      isLoading: true
    });
    
    const newCriteria = {
      ...quotesState.filters,
      per_page:quotesState.page.per_page,
      page: newPage + 1
    };

    getQuotes(newCriteria).then(res => {
      setQuotesState({
        ...quotesState,
        isLoading: false,
        quotes: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQuotesState({
      ...quotesState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...quotesState.filters,
      page: 1,
      per_page: perPage,
    };

    getQuotes(newCriteria).then(res => {
      setQuotesState({
        ...quotesState,
        isLoading: false,
        quotes: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuotesState({
        ...quotesState,
        isLoading: false,
      });
    });
  };

  const classes = useStyles();

  const filterData = (data) => {
    setQuotesState({
      ...quotesState,
      isLoading: true
    });

    let filtersData = '';

    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...quotesState.filters,
        buyer: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...quotesState.filters,
        supplier: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    }

    getQuotes(filtersData).then(res => {
      setQuotesState({
        ...quotesState,
        isLoading: false,
        quotes: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('quoteFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuotesState({
        ...quotesState,
        isLoading: false,
      });
    });

  };

  const handleDeleteQuote = (event, index) => {

    const quoteForDeletion = quotesState.quotes[index];
    setQuotesState({
      ...quotesState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Quote',
        onCancel: () => {
          setQuotesState({
            ...quotesState,
            confirmDialog: {
              ...quotesState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteQuote(quoteForDeletion.id).then(() => {
            const newQuotes = [...quotesState.quotes];
            newQuotes.splice(index, 1);
            setQuotesState({
              ...quotesState,
              quotes: newQuotes,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const tableFilterComponent = () => {
    return <TableFilter
      companyField={true}
      searchByText={false}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"}
      company={(filters && filters.company) ? filters.company : quotesState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : quotesState.filters.vessel}
      productCategory={(filters && filters.category) ? filters.category : quotesState.filters.category}
      port={(filters && filters.port) ? filters.port : quotesState.filters.port}
      from={(filters && filters.from) ? filters.from : quotesState.filters.from_date}
      to={(filters && filters.to) ? filters.to : quotesState.filters.to_date}
      status={(filters && filters.status) ? filters.status : quotesState.filters.status}
      statuses={quoteStatusState.quoteStatuses}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      order={quotesState.page.order_type}
      orderBy={quotesState.page.order_by}
      rowCount={quotesState.page.length} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {quotesState.quotes && quotesState.quotes.map((quote, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  style={{ padding: '0px' }}
                  component={RouterLink}
                  to={{ pathname: `/transactions/${quote.transaction.id}/quotes/${quote.id}` }}
                >
                  <ManageSearchIcon></ManageSearchIcon>
                </IconButton>
              </TableCell>
              <TableCell>{quote.reference_number?quote.reference_number:'N/A'}</TableCell>
              <TableCell>{(quote.buyer !== undefined && quote.supplier !== undefined) ? (userState.userRole === 'SUPPLIER' ? quote.buyer.name : quote.supplier.name) : ''}</TableCell>
              <TableCell>{quote.vessel && quote.vessel.name}</TableCell>
              <TableCell>{quote.category&&quote.category.name}</TableCell>
              <TableCell>{quote.quoted_items}</TableCell>
              <TableCell>{quote.submitted&&quote.submitted.slice(0, 10)}</TableCell>
              <TableCell>{quote.due&&quote.due.slice(0, 10)}</TableCell>
              <TableCell>{quote.port && quote.port.name}</TableCell>
              <TableCell>
                <StatusIndicator status={quote.status}>
                  {quote.status}
                </StatusIndicator>
              </TableCell>
              <TableCell>
                <TableActions config={{
                   edit: {
                    enabled: false,
                    callback: () => null
                  },
                  delete: {
                    enabled: quote.actions.includes("DELETE") && quotesState.quoteEditIndex !== index,
                    callback: (e) => handleDeleteQuote(e, index)
                  },
                  close: {
                    enabled: false,
                    callback: (e) => null
                  },
                  save: {
                    enabled: false,
                    callback: (e) => null
                  },
                  quote: {
                    enabled: false,
                    callback: (e) => null
                  },
                }} />
                </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('QUOTES')}
        pagination={{
          total: quotesState.page.total || 0,
          page: quotesState.page.page - 1 || 0,
          perPage: quotesState.page.per_page || 10,
          count: quotesState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={() => { }}
        confirmDialog={quotesState.confirmDialog}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={quotesState.isLoading}
      />
    </>
  );
};

export default Quotes;
