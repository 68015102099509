import httpService from './httpService';


const getAllProductDictionariesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.GetProductDicts(criteria);
};

const deleteProductDictionary = (id) => {
  return httpService.DeleteProductDict(id);
};

const editProductDictionary = (id, data) => {
   return httpService.UpdateProductDict({}, data);
};

const addProductDictionary = (data) => {
  return httpService.CreateProductDict({},data);
};

export { getAllProductDictionariesInfo, deleteProductDictionary, addProductDictionary, editProductDictionary };
