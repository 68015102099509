import React, { useCallback, useEffect} from "react";
import useStyles from './style';
import Rating from '@mui/material/Rating';

export default function InlineRating({ stars: initialStars, getStars, saved, step=0.5, error, label = 'STARS' }) {
    const classes = useStyles();
    const emptyStars = null;
    const [openStars, setOpenStars] = React.useState(false);
    const [stars, setStars] = React.useState(initialStars||null);

    useEffect(() => {
        setStars(initialStars!==null?initialStars:null);
      }, [initialStars]);

      
    const handleSpanClick = useCallback(() => setOpenStars(true), [
        setOpenStars
    ]);

    const onStarsSelectionChange = (event, value) => {
        setStars(value);
        onClose(value);
    };

    // const handleStars = (val) => {
    //     setStars(val);
    //     getStars(emptyStars);
    //     setOpenStars(false);
    // };

    const onClose = (value) => {
        setOpenStars(false);
        const entryStars = {
            stars: value
        };
        getStars(entryStars);
    }


    return (
        <span>
            {openStars || !saved ? (
                <span>
                    <Rating
                        name="half-rating"
                        value={stars}
                        error={error && !saved}
                        onChange={(event, newValue) => { onStarsSelectionChange(event, newValue) }}
                        onFocus={() => {
                            setOpenStars(true);
                        }}
                      
                        // onBlur={() => onClose()}
                        precision={step}
                    />
                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openStars ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <Rating
                        name="half-rating"
                        value={stars ? stars : emptyStars}
                        precision={step}
                        readOnly={true}
                    />

                </span>)}
        </span >
    );
}