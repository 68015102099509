import httpService from './httpService';

const getAllUnits = (criteria = {}) => {
  return httpService.FetchUnitList(criteria);
};

const getAllUnitsInfo = (criteria = {}) => {
  return httpService.FetchUnitInfoList(criteria);
};

const deleteUnit = (id) => {
  return httpService.DeleteUnit(id);
};

const editUnit = (id, data) => {
  return httpService.UpdateUnit({}, data);
};

const addUnit = (data) => {
  return httpService.CreateUnit({}, data);
};

const matchUnits = (data) => {
  return httpService.MatchUnit(data);
}

const importUnitsFile = (data) => {
  
  let fd = new FormData();

  fd.append('file', new Blob([data], {
    type: "text/tab-separated-values"
  }));

  return httpService.UploadUnitsFile({},fd,
    {
      headers: {
        'Content-Type': 'text/tab-separated-values',
      },
    })
};

const clearUnitCache = (criteria = {}) => {
  return httpService.ClearUnitCache({},criteria);
};

export { getAllUnits, getAllUnitsInfo, addUnit, editUnit, deleteUnit, matchUnits, importUnitsFile, clearUnitCache };
