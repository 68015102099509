
const tableHeadersRegion = [
  { id: 'view', disablePadding: false, },
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty Region
const newRegion = () => {
  return {
    id: '?',
    name: null,
  }
};

export { tableHeadersRegion, newRegion };