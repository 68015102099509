import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersRegion, newRegion } from '../../helpers/regionsConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllOriginalRegions, 
  deleteOriginalRegion , 
  editOriginalRegion,
  addOriginalRegion,
  cleanOriginalRegion } from '../../services/originalRegionService';
import { FilterByText } from '../../components/filterByText';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";
import { Link as RouterLink } from "react-router-dom";
import './_regions.scss';
import t from '../../helpers/languages';

const Regions = (props) => {

  const regionInitObject = () => {
    return {
      id: '?',
      name: null,
    }
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('regionFilter');

  const [regionsState, setRegionsState] = useState({
    regions: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    regionEditIndex: -1,
    regionEditObject: regionInitObject(),
    regionCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...regionsState.filters,
    search: filters && filters.searchText,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllOriginalRegions(filtersData).then(res => {
      const { results } = res.data;

      setRegionsState({
        ...regionsState,
        regions: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRegionsState({
        ...regionsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setRegionsState({
      ...regionsState,
      isLoading: true
    });
    const newCriteria = {
      ...regionsState.filters,
      per_page: regionsState.page.per_page,
      page: newPage + 1
    };
    getAllOriginalRegions(newCriteria).then(res => {
      setRegionsState({
        ...regionsState,
        isLoading: false,
        regions: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setRegionsState({
      ...regionsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...regionsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllOriginalRegions(newCriteria).then(res => {
      setRegionsState({
        ...regionsState,
        isLoading: false,
        regions: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRegionsState({
        ...regionsState,
        isLoading: false,
      });
    });
  };

  const handleEditRegion = (event, regionIndex) => {

    event.stopPropagation();

    const regionEditObject = Object.assign({}, regionsState.regions[regionIndex]);


    setRegionsState({
      ...regionsState,
      regionEditIndex: regionIndex,
      regionEditObject: regionEditObject
    });

  };

  const onEditableRegionChange = (event, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditRegionItem = Object.assign({}, regionsState.regionEditObject);
    newEditRegionItem[property] = newValue;

    setRegionsState({
      ...regionsState,
      regionEditObject: newEditRegionItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveEditRegion = (event, props) => {
    event.preventDefault();
    setRegionsState({
      ...regionsState,
      isLoading: true
    });
    const newEditRegionItem = Object.assign({}, regionsState.regionEditObject);

    if (validator.current.allValid()) {
      editOriginalRegion(newEditRegionItem.id, newEditRegionItem)
        .then(re => {
          const editedRegion = re.data;
          setRegionsState(oldCountries => {
            return {
              ...oldCountries,
              regions: oldCountries.regions.map(region => {
                return region.id === editedRegion.id
                  ? re.data : region
              }),
              regionEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setRegionsState({
            ...regionsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setRegionsState({
        ...regionsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditRegion = (e, index) => {
    setRegionsState({
      ...regionsState,
      regionEditIndex: -1
    })
  };

  const handleDeleteRegion = (event, index) => {

    const regionForDeletion = regionsState.regions[index];
    setRegionsState({
      ...regionsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Region',
        onCancel: () => {
          setRegionsState({
            ...regionsState,
            confirmDialog: {
              ...regionsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteOriginalRegion(regionForDeletion.id).then(() => {
            const newCountries = [...regionsState.regions];
            newCountries.splice(index, 1);
            setRegionsState({
              ...regionsState,
              regions: newCountries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewRegionPort = (e) => {
    e.preventDefault();

    setRegionsState({
      ...regionsState,
      regionCreateObject: newRegion()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewRegion = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newRegion = {};
    newRegion.name = regionsState.regionCreateObject.name;


    if (validator.current.allValid()) {
      addOriginalRegion(newRegion)
        .then(res => {
          setRegionsState({
            ...regionsState,
            regions: [...regionsState.regions, res.data],
            regionCreateObject: null,
            regionEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setRegionsState({
            ...regionsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewRegionPortObject = (e, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = e.target.checked ? true : false;
    }
    else {
        newValue = e.target.value;
    }

    const newEditRegionItem = Object.assign({}, regionsState.regionCreateObject);
    newEditRegionItem[property] = newValue;

    setRegionsState({
      ...regionsState,
      regionCreateObject: newEditRegionItem
    });
  };

  const filterData = (data) => {
    setRegionsState({
      ...regionsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...regionsState.filters,
      search: data && data.searchText,
    };

    getAllOriginalRegions(filtersData).then(res => {
      const { results } = res.data;

      setRegionsState({
        ...regionsState,
        regions: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('regionFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRegionsState({
        ...regionsState,
        isLoading: false,
      });
    });

  };


  const tableFilterComponent = () => {
    return <FilterByText
      searchText={(filters && filters.searchText) ? filters.searchText : regionsState.filters.search}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersRegion}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(regionsState.regions) && regionsState.regions.map((region, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (regionsState.regionEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        style={{ padding: '0px' }}
                        component={RouterLink}
                        to={{ pathname: `/regions/${region.id}` }}
                      >
                        <ManageSearchIcon></ManageSearchIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell>{region.name}</TableCell>
                  </> :
                  <>
                  <TableCell onClick={e => e.stopPropagation()}></TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={regionsState.regionEditObject.name || ''}
                          onChange={e => onEditableRegionChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', regionsState.regionEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: regionsState.regionEditIndex !== index,
                    callback: (e) => handleEditRegion(e, index)
                  },
                  delete: {
                    enabled: regionsState.regionEditIndex !== index,
                    callback: (e) => handleDeleteRegion(e, index)
                  },
                  close: {
                    enabled: regionsState.regionEditIndex === index,
                    callback: (e) => handleCloseEditRegion(e, index)
                  },
                  save: {
                    enabled: regionsState.regionEditIndex === index,
                    callback: (e) => handleSaveEditRegion(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          regionsState.regionCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}></TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={regionsState.regionCreateObject.name || ''}
                  onChange={e => onEditNewRegionPortObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', regionsState.regionCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setRegionsState({ ...regionsState, regionCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewRegion()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('REGIONS')}
        pagination={{
          total: regionsState.page.total || 0,
          page: regionsState.page.page - 1 || 0,
          perPage: regionsState.page.per_page || 10,
          count: regionsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewRegionPort}
        newRowBtnEnabled={!regionsState.regionCreateObject}
        confirmDialog={regionsState.confirmDialog.message.length > 0 ? regionsState.confirmDialog : regionsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={regionsState.isLoading}
      />
    </>
  );
};


export default Regions;
