import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersUnitDictionary, tableHeadersUnitDictionaryGlobal, newUnitDictionary } from '../../helpers/unitsDictionaryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterUnits from "../../components/filterUnits";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllUnitDictionariesInfo, editUnitDictionary, deleteUnitDictionary, addUnitDictionary } from '../../services/unitsDictionaryService';
import './_UnitDictionaries.scss';
import t from '../../helpers/languages';
import { DictionaryTableFilter } from '../../components/dictionaryTableFilter';
import FilterCompanies from "../../components/filterCompanies";

const UnitDictionaries = (props) => {

  const unitDictionaryInitObject = () => {
    return {
      id: '?',
      company: { name: '', id: '' },
      unitAlias: null,
      original: null
    }
  };

  const classes = useStyles();

  const filters = localStoreGetObj('unitDictionaryFilter');

  const [unitsDictionaryState, setunitDictionariesState] = useState({
    unitsDictionary: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    unitDictionaryEditIndex: -1,
    unitDictionaryEditObject: unitDictionaryInitObject(),
    unitDictionaryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};

  if (props.autonomousPage) {
    filtersData = {
      ...unitsDictionaryState.filters,
      companyId: filters && filters.company && filters.company.id,
      search: filters && filters.unit,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: unitsDictionaryState.page.order_type,
      order_by: unitsDictionaryState.page.order_by,
    };
  }
  else {
    filtersData = {
      ...unitsDictionaryState.filters,
      companyId: props.companyId,
      search: filters && filters.unit,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: unitsDictionaryState.page.order_type,
      order_by: unitsDictionaryState.page.order_by,
    };
  }

  useEffect(function () {
    getAllUnitDictionariesInfo(filtersData).then(res => {
      
      const { results } = res.data;
      setunitDictionariesState({
        ...unitsDictionaryState,
        unitsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === unitsDictionaryState.page.order_by && unitsDictionaryState.page.order_type === 'desc' ? 'asc' : 'desc';

    setunitDictionariesState({
      ...unitsDictionaryState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllUnitDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setunitDictionariesState({
        ...unitsDictionaryState,
        unitsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setunitDictionariesState({
      ...unitsDictionaryState,
      isLoading: true
    });
    const newCriteria = {
      ...unitsDictionaryState.filters,
      per_page: unitsDictionaryState.page.per_page,
      page: newPage + 1
    };
    getAllUnitDictionariesInfo(newCriteria).then(res => {
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
        unitsDictionary: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to unit id gia mba member
    setunitDictionariesState({
      ...unitsDictionaryState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...unitsDictionaryState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllUnitDictionariesInfo(newCriteria).then(res => {
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
        unitsDictionary: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
      });
    });
  };

  const handleEditUnitDictionary = (event, unitDictionaryIndex) => {

    event.stopPropagation();

    const unitDictionaryEditObject = Object.assign({}, unitsDictionaryState.unitsDictionary[unitDictionaryIndex]);


    setunitDictionariesState({
      ...unitsDictionaryState,
      unitDictionaryEditIndex: unitDictionaryIndex,
      unitDictionaryEditObject: unitDictionaryEditObject
    });

  };

  const onEditableUnitDictionaryChange = (event, property) => {

    const newValue = event.target.value;

    const newEditUnitDictionaryItem = Object.assign({}, unitsDictionaryState.unitDictionaryEditObject);
    newEditUnitDictionaryItem[property] = newValue;

    setunitDictionariesState({
      ...unitsDictionaryState,
      unitDictionaryEditObject: newEditUnitDictionaryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveUnitDictionary = (event, props) => {
    event.preventDefault();
    setunitDictionariesState({
      ...unitsDictionaryState,
      isLoading: true
    });

    const simpleCompanyUser = {
      id: props.companyId,
    }
    let createNull = {
      id: null,
    };
    if (unitsDictionaryState.unitDictionaryEditObject.company?.id !== '' && unitsDictionaryState.unitDictionaryEditObject.company!=='') {
      createNull = unitsDictionaryState.unitDictionaryEditObject.company;
    }

    const newEditUnitDictionaryItem = Object.assign({}, unitsDictionaryState.unitDictionaryEditObject);

    props.autonomousPage ? newEditUnitDictionaryItem.company = createNull : newEditUnitDictionaryItem.company = simpleCompanyUser;

    if (validator.current.allValid()) {
      editUnitDictionary(newEditUnitDictionaryItem.id, newEditUnitDictionaryItem)
        .then(re => {
          const editedUnitDictionary = re.data;
          setunitDictionariesState(oldunitDictionaries => {
            return {
              ...oldunitDictionaries,
              unitsDictionary: oldunitDictionaries.unitsDictionary.map(unitDictionary => {
                return unitDictionary.id === editedUnitDictionary.id
                  ? re.data : unitDictionary
              }),
              unitDictionaryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setunitDictionariesState({
            ...unitsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditUnitDictionary = (e, index) => {
    setunitDictionariesState({
      ...unitsDictionaryState,
      unitDictionaryEditIndex: -1
    })
  };

  const handleDeleteUnitDictionary = (event, index) => {

    const unitDictionaryForDeletion = unitsDictionaryState.unitsDictionary[index];
    setunitDictionariesState({
      ...unitsDictionaryState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete UnitDictionary',
        onCancel: () => {
          setunitDictionariesState({
            ...unitsDictionaryState,
            confirmDialog: {
              ...unitsDictionaryState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteUnitDictionary(unitDictionaryForDeletion.id).then(() => {
            const newunitDictionaries = [...unitsDictionaryState.unitsDictionary];
            newunitDictionaries.splice(index, 1);
            setunitDictionariesState({
              ...unitsDictionaryState,
              unitsDictionary: newunitDictionaries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewUnitDictionary = (e) => {
    e.preventDefault();

    const newUnitDict = newUnitDictionary();

    // newUnitDict.company = { id: props.companyId };

    setunitDictionariesState({
      ...unitsDictionaryState,
      unitDictionaryCreateObject: newUnitDict
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewUnitDictionary = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newUnitDictionary = {};

    const simpleCompanyUser = {
      id: props.companyId,
    }
    let createNull = null;
    if (unitsDictionaryState.unitDictionaryCreateObject.company|| !unitsDictionaryState.unitDictionaryCreateObject.company.length === 0 ) {
      createNull = unitsDictionaryState.unitDictionaryCreateObject.company;
    }

    props.autonomousPage ? newUnitDictionary.company = createNull : newUnitDictionary.company = simpleCompanyUser;

    newUnitDictionary.unitAlias = unitsDictionaryState.unitDictionaryCreateObject.unitAlias;
    newUnitDictionary.original = unitsDictionaryState.unitDictionaryCreateObject.original;

    if (validator.current.allValid()) {
      addUnitDictionary(newUnitDictionary)
        .then(res => {
          setunitDictionariesState({
            ...unitsDictionaryState,
            unitsDictionary: [...unitsDictionaryState.unitsDictionary, res.data],
            unitDictionaryCreateObject: null,
            unitDictionaryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setunitDictionariesState({
            ...unitsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewUnitDictionaryObject = (e, property) => {
    const newValue = e.target.value;

    const newEditUnitDictionaryItem = Object.assign({}, unitsDictionaryState.unitDictionaryCreateObject);
    newEditUnitDictionaryItem[property] = newValue;

    setunitDictionariesState({
      ...unitsDictionaryState,
      unitDictionaryCreateObject: newEditUnitDictionaryItem
    });
  };

  const filterData = (data) => {
    setunitDictionariesState({
      ...unitsDictionaryState,
      isLoading: true
    });

    let filtersData = '';
    if (props.autonomousPage) {
      filtersData = {
        ...unitsDictionaryState.filters,
        search: data && data.searchText,
        companyId: data && data.company && data.company.id,
        per_page: criteria.per_page,
        page: 1,
      };
    } else {
      filtersData = {
        ...unitsDictionaryState.filters,
        search: data && data.searchText,
        companyId: props.companyId,
        per_page: criteria.per_page,
        page: 1,
      };
    }

    getAllUnitDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;

      setunitDictionariesState({
        ...unitsDictionaryState,
        unitsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('unitDictionaryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setunitDictionariesState({
        ...unitsDictionaryState,
        isLoading: false,
      });
    });

  };

  const selectedCompany = (data, action) => {
    const newEditUnitDictionaryItem = Object.assign({},
      action === 'edit'
        ? unitsDictionaryState.unitDictionaryEditObject
        : unitsDictionaryState.unitDictionaryCreateObject
    );
    newEditUnitDictionaryItem.company = data.company;
    setunitDictionariesState({
      ...unitsDictionaryState,
      [
        action === 'edit'
          ? 'unitDictionaryEditObject'
          : 'unitDictionaryCreateObject'
      ]: newEditUnitDictionaryItem
    });
  };

  const selectedOriginal = (data, action) => {

    const newEditUnitDictionaryItem = Object.assign({},
      action === 'edit'
        ? unitsDictionaryState.unitDictionaryEditObject
        : unitsDictionaryState.unitDictionaryCreateObject
    );

    newEditUnitDictionaryItem.original = data.unit;


    setunitDictionariesState({
      ...unitsDictionaryState,
      [
        action === 'edit'
          ? 'unitDictionaryEditObject'
          : 'unitDictionaryCreateObject'
      ]: newEditUnitDictionaryItem
    });
  };

  const tableFilterComponent = () => {
    return <DictionaryTableFilter
      searchText={(filters && filters.unit) ? filters.unit: unitsDictionaryState.filters.unit}
      company={(filters && filters.company) ? filters.company : unitsDictionaryState.filters.company}
      autonomousPage={props.autonomousPage}
      getFilterData={filterData}
      filterLabel={'Unit'}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.autonomousPage ? tableHeadersUnitDictionaryGlobal : tableHeadersUnitDictionary}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(unitsDictionaryState.unitsDictionary) && unitsDictionaryState.unitsDictionary.map((unitDictionary, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (unitsDictionaryState.unitDictionaryEditIndex !== index) && index !== '?'
                  ? <>
                  {props.autonomousPage && <TableCell>{unitDictionary.company?.name}</TableCell>}
                    <TableCell>{unitDictionary.unitAlias}</TableCell>
                    <TableCell>{unitDictionary.original?.name}</TableCell>
                  </> :
                  <>
                  {props.autonomousPage && (
                      <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={unitsDictionaryState.unitDictionaryEditObject.company || null}
                            getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                            buyer={true}
                            errorMessage={null}
                          />
                        </FormControl>
                      </TableCell>
                    )
                    }
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={unitsDictionaryState.unitDictionaryEditObject.unitAlias || ''}
                          onChange={e => onEditableUnitDictionaryChange(e, 'unitAlias')}
                          onBlur={() => validator.current.showMessageFor('unitAlias')}
                        />{validator.current.message('unitAlias', unitsDictionaryState.unitDictionaryEditObject.unitAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterUnits
                          unit={unitsDictionaryState.unitDictionaryEditObject.original || null}
                          getSelectedUnit={(data) => selectedOriginal(data, 'edit')}
                          errorMessage={null}
                        />
                        {validator.current.message('original', unitsDictionaryState.unitDictionaryEditObject.original, 'required')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: unitsDictionaryState.unitDictionaryEditIndex !== index,
                    callback: (e) => handleEditUnitDictionary(e, index)
                  },
                  delete: {
                    enabled: unitsDictionaryState.unitDictionaryEditIndex !== index,
                    callback: (e) => handleDeleteUnitDictionary(e, index)
                  },
                  close: {
                    enabled: unitsDictionaryState.unitDictionaryEditIndex === index,
                    callback: (e) => handleCloseEditUnitDictionary(e, index)
                  },
                  save: {
                    enabled: unitsDictionaryState.unitDictionaryEditIndex === index,
                    callback: (e) => handleSaveUnitDictionary(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          unitsDictionaryState.unitDictionaryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
           {props.autonomousPage && (
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={unitsDictionaryState.unitDictionaryCreateObject.company || null}
                    getSelectedCompany={(data) => selectedCompany(data, 'create')}
                    buyer={true}
                    errorMessage={null}
                  />
                </FormControl>
              </TableCell>
            )}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={unitsDictionaryState.unitDictionaryCreateObject.unitAlias || ''}
                  onChange={e => onEditNewUnitDictionaryObject(e, 'unitAlias')}
                  onBlur={() => validator.current.showMessageFor('unitAlias')}
                />{validator.current.message('unitAlias', unitsDictionaryState.unitDictionaryCreateObject.unitAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterUnits
                  unit={unitsDictionaryState.unitDictionaryCreateObject.original || null}
                  getSelectedUnit={(data) => selectedOriginal(data, 'create')}
                  errorMessage={null}
                />
                {validator.current.message('original', unitsDictionaryState.unitDictionaryCreateObject.original, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setunitDictionariesState({ ...unitsDictionaryState, unitDictionaryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewUnitDictionary()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('UNIT_DICTIONARIES')}
        pagination={{
          total: unitsDictionaryState.page.total || 0,
          page: unitsDictionaryState.page.page - 1 || 0,
          perPage: unitsDictionaryState.page.per_page || 10,
          count: unitsDictionaryState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewUnitDictionary}
        newRowBtnEnabled={!unitsDictionaryState.unitDictionaryCreateObject}
        confirmDialog={unitsDictionaryState.confirmDialog.message.length > 0 ? unitsDictionaryState.confirmDialog : unitsDictionaryState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={unitsDictionaryState.isLoading}
      />
    </>
  );
};


export default UnitDictionaries;
