
const tableHeadersCountriesRegion = [
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty Country
const newCountriesRegion = () => {
  return {}
};

export {  tableHeadersCountriesRegion, newCountriesRegion  };