import httpService from './httpService';


const getItemsEvaluation = (criteria) => {
    return httpService.FetchItemRatings(criteria);
};

const addItemEvaluation = (data) => {
    return httpService.CreateItemRating({}, data);
};

const deleteItemEvaluation = (id) => {
    return httpService.DeleteItemRating(id);
};

const editItemEvaluation = (id, data) => {
    return httpService.UpdateItemRating({id}, data);
};


export { getItemsEvaluation, addItemEvaluation, deleteItemEvaluation, editItemEvaluation };
