import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    //width: 400,
  },
  input: {
    marginLeft: theme.spacing('1px'),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  tableFilterItemsActions: {
    svg: {
      margin: '0 7px 0 0',
      display: 'inline-block',
      //  font-size: 20px;
    }
  },
}));

export default useStyles;
