import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/tableHeader';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { getRatedProducts } from '../../services/ratedProductsService';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeaders } from '../../helpers/ratedProductsConfig';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { RatedProductsTableFilter } from '../../components/ratedProductsTableFilter';
import Rating from '@mui/material/Rating';

import t from '../../helpers/languages';

const RatedProducts = (props) => {


  const [ ratedProductsState, setRatedProductsState] = useState({
    ratedProducts: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
  });


  const filters = localStoreGetObj('ratedProductsFilter');

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...ratedProductsState.filters,
    catalogue: filters && filters.catalogue && filters.catalogue.id,
    search: filters && filters.search,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  const loadRatedProducts = () => {
    setRatedProductsState({
      ...ratedProductsState,
      isLoading: true
    });
    getRatedProducts(filtersData).then(res => {

      const { results } = res.data;

      setRatedProductsState({
        ...ratedProductsState,
        ratedProducts: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
      });
    });
  }


  
  useEffect(() => {
    loadRatedProducts();
    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();
    const isAsc = property === ratedProductsState.page.order_by && ratedProductsState.page.order_type === 'desc' ? 'asc' : 'desc';
    let filtersData = {
      ...ratedProductsState.filters,
      per_page: ratedProductsState.page.per_page,
      page: ratedProductsState.page.page,
      order_type: isAsc,
      order_by: property,
    };
    setRatedProductsState({
      ...ratedProductsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    getRatedProducts(filtersData).then(res => {

      setRatedProductsState({
        ...ratedProductsState,
        ratedProducts: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setRatedProductsState({
      ...ratedProductsState,
      isLoading: true,
    });

    const newCriteria = {
      ...ratedProductsState.filters,
      per_page: ratedProductsState.page.per_page,
      page: newPage + 1,
    };

    getRatedProducts(newCriteria).then(res => {
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
        ratedProducts: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRatedProductsState({
      ...ratedProductsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...ratedProductsState.filters,
      page: 1,
      per_page: perPage,
    };



    getRatedProducts(newCriteria).then(res => {
     
      const { results } = res.data;
     
      //initialize saved items
      let savedArray = [];
      for (let i = 0; i < results.length; i++) {
        savedArray[i] = true;
      }

      setRatedProductsState({
        ...ratedProductsState,
        ratedProducts: results,
        isLoading: false,
        page: res.data.page,
        filters: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' '+error,
      // }));
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
      });
    });
  };


  const filterData = (data) => {

    setRatedProductsState({
      ...ratedProductsState,
      isLoading: true
    });


    let filtersData = {
      search: data.search,
      catalogue: data && data.catalogue && data.catalogue.id,
      per_page: criteria.per_page,
      page: 1,
    };
    getRatedProducts(filtersData).then(res => {
      const { results } = res.data;

      //initialize saved items
      let savedArray = [];
      for (let i = 0; i < results.length; i++) {
        savedArray[i] = true;
      }

      setRatedProductsState({
        ...ratedProductsState,
        ratedProducts: results,
        ratedProductsEditIndex: -1,
        ratedProductsCreateObject: null,
        isLoading: false,
        isStarsSaved: savedArray,
        isNotesSaved: savedArray,
        page: res.data.page,
        filters: res.data.filters,
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
      localStoreSetObj('ratedProductsFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setRatedProductsState({
        ...ratedProductsState,
        isLoading: false,
      });
    });
  };

  const classes = useStyles();


  const tableFilterComponent = () => {
    return <RatedProductsTableFilter
      search={(filters && filters.search) ? filters.search : ratedProductsState.filters.search}
      catalogue={(filters && filters.catalogue) ? filters.catalogue : ratedProductsState.filters.catalogue}
      getFilterData={filterData}
    />
  };


  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      order={ratedProductsState.page.order_type}
      orderBy={ratedProductsState.page.order_by}
      rowCount={ratedProductsState.page.length} />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {!ratedProductsState.isLoading && Array.isArray(ratedProductsState.ratedProducts)  && ratedProductsState.ratedProducts.map((ratedProduct, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>{ratedProduct.productCode}</TableCell>
              <TableCell>{ratedProduct.productName}</TableCell>
              <TableCell>{ratedProduct.category.name}</TableCell>
              <TableCell>
                <Rating
                  name="half-rating"
                  value={ratedProduct.avgRating}
                  precision={0.5}
                  readOnly={true}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('RATED_PRODUCTS')}
        pagination={{
          total: ratedProductsState.page.total || 0,
          page: ratedProductsState.page.page - 1 || 0,
          perPage: ratedProductsState.page.per_page || 10,
          count: ratedProductsState.page.count || 0,
          disabled: false,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={false}
        newRowBtnEnabled={false}
        confirmDialog={ratedProductsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        isLoading={ratedProductsState.isLoading}
      />
    </>
  );
};
export default RatedProducts;
