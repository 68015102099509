import React, { Fragment, useState } from 'react';
import 'date-fns';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import t from '../../helpers/languages';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Grid } from "@mui/material";
import FilterPorts from "../../components/filterPorts";
import FilterVessels from "../../components/filterVessels";
import FilterCatalogues from "../../components/filterCatalogues";
import FilterCompanies from "../../components/filterCompanies";
import dateToString from '../../helpers/dateToString';
import { SearchByTextTableFilter } from '../../components/searchByTextTableFilter';
import moment from 'moment';
import './_tableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),

  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const TableFilter = ({ company: initialCompany, vessel: initialVessel, productCategory: initialProductCategory, port: initialPort, from: initialFrom, to: initialTo, status: initialStatus, statuses: initialStatuses, referenceNumber:initialReferenceNumber, companyField, companySearchFor, searchByText, getFilterData }) => {
  const classes = useStyles();

  const [company, setCompany] = useState(initialCompany);
  const [vessel, setVessel] = useState(initialVessel);
  const [port, setPort] = useState(initialPort);
  const [productCategory, setProductCategory] = useState(initialProductCategory);
  const [from, setFrom] = useState(initialFrom ? moment.utc(initialFrom) : null);
  const [to, setTo] = useState(initialTo ? moment.utc(initialTo) : null);
  const [status, setStatus] = useState(initialStatus || '');
  const [referenceNumber, setReferenceNumber] = useState(initialReferenceNumber);

  const selectedPort = (data) => {
    setPort(data.port);
    const filterData = {
      port: data.port,
      vessel: vessel,
      category: productCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: status,
      company: company,
      search: referenceNumber
    };

    getFilterData(filterData);
  }

  const selectedVessel = (data) => {
    setVessel(data.vessel);
    const filterData = {
      port: port,
      vessel: data.vessel,
      category: productCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: status,
      company: company,
      search: referenceNumber
    };

    getFilterData(filterData);
  }

  const selectedCatalogue = (data) => {

    setProductCategory(data.catalogue);
    const filterData = {
      port: port,
      vessel: vessel,
      category: data.catalogue,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: status,
      company: company,
      search: referenceNumber
    };

    getFilterData(filterData);

  }

  const selectedCompany = (data) => {

    setCompany(data.company);
    const filterData = {
      port: port,
      vessel: vessel,
      category: productCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: status,
      company: data.company,
      search: referenceNumber
    };

    getFilterData(filterData);
  }


  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const filterData = {
  //     port: port,
  //     vessel: vessel ,
  //     category: productCategory,
  //     from: moment(from).isValid()? dateToString(from)+'T00:00:00Z':from,
  //     to: moment(to).isValid()? dateToString(to)+'T00:00:00Z':to,
  //     status: status,
  //     company: company
  //   };

  //   getFilterData(filterData);
  // };


  const onDateFromChange = (date) => {
    setFrom(date);

    if (moment(date).isValid() && !isNaN(date)) {
      const filterData = {
        port: port,
        vessel: vessel,
        category: productCategory,
        from: dateToString(date) + 'T00:00:00Z',
        to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
        status: status,
        company: company,
        search: referenceNumber
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        port: port,
        vessel: vessel,
        category: productCategory,
        from: date,
        to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
        status: status,
        company: company,
        search: referenceNumber
      };

      getFilterData(filterData);
    }


  };

  const onDateToChange = (date) => {
    setTo(date);

    if (moment(date).isValid() && !isNaN(date)) {
      const filterData = {
        port: port,
        vessel: vessel,
        category: productCategory,
        from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
        to: dateToString(date) + 'T00:00:00Z',
        status: status,
        company: company,
        search: referenceNumber
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        port: port,
        vessel: vessel,
        category: productCategory,
        from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
        to: date,
        status: status,
        company: company,
        search: referenceNumber
      };

      getFilterData(filterData);
    }

  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    const filterData = {
      port: port,
      vessel: vessel,
      category: productCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: event.target.value,
      company: company,
      search: referenceNumber
    };

    getFilterData(filterData);
  };

  const searchText = (data) => {

    setReferenceNumber(data.searchText);
    const filterData = {
      port: port,
      vessel: vessel,
      category: productCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      status: status,
      company: data.company,
      search: data.searchText
    };

    getFilterData(filterData);
  }

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {searchByText && (
          <Grid item xs={2} >
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <SearchByTextTableFilter
                searchText={referenceNumber}
                getFilterData={searchText}
                label='Reference No'
              />
            </FormControl>
          </Grid>)}
        {companyField && (
          <Grid item xs={2} >
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterCompanies
                company={company}
                companySearchFor={companySearchFor}
                getSelectedCompany={selectedCompany}
                errorMessage={''}
              />
            </FormControl>
          </Grid>)}
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterPorts
              port={port}
              getSelectedPort={selectedPort} />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterVessels
              vessel={vessel}
              getSelectedVessel={selectedVessel} />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCatalogues
              catalogue={productCategory}
              getSelectedCatalogue={selectedCatalogue}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-status-native-simple">{t('STATUS')}</InputLabel>
            <Select
              variant="outlined"
              value={initialStatuses.length !== 0 ? status : ''}
              onChange={handleStatusChange}
              label={t('STATUS')}
              inputProps={{
                name: 'status',
                id: 'outlined-status-native-simple',
              }}>
              <MenuItem value=''>---</MenuItem>
              {initialStatuses.map((status, index) => {
                return <MenuItem
                  key={status.id}
                  value={status.id}
                >{status.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={1}>
                <Fragment>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-from"
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      label={t('FROM')}
                      value={from}
                      inputFormat="yyyy-MM-DD"
                      inputVariant="outlined"
                      onChange={onDateFromChange}
                      initialFocusedDate={from}
                      clearable
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-to"
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      label={t('TO')}
                      inputFormat="yyyy-MM-DD"
                      value={to}
                      inputVariant="outlined"
                      onChange={onDateToChange}
                      clearable
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Fragment>
              </Grid>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        {/* <FormControl className={classes.formControl}>
          <Button
            type={'submit'}
            variant="contained"
            size="medium"
            style={{ width: 'auto', padding: '16px' }}
            startIcon={<SearchRoundedIcon></SearchRoundedIcon>}
            color="primary"
            onClick={e => handleSubmit(e)}>
            Search
          </Button>
        </FormControl> */}
      </Grid>
    </form>
  );
}