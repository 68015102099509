import httpService from './httpService';
const apijson = require('./../bff-api-schema.json');

const getAllAuthorizations = () => {
  return httpService.FetchAuthorizations();
};

const getAuthorizationMappings = (path, userRole) => {

  const isActionApplicable = (actionName) => {
    try {
      return apijson.paths[path][actionName]
              .security[0]
              .authorizations
              .map(role => role.toLowerCase())
              .includes(userRole.toLowerCase())
    }catch (e) {
      return false;
    }
  };

  return {
    // Available actions supported by API are: get, put, post, delete
    read: isActionApplicable('get'),
    update: isActionApplicable('put'),
    create: isActionApplicable('post'),
    delete: isActionApplicable('delete')
  }
};


export { getAllAuthorizations, getAuthorizationMappings };
