import httpService from './httpService';

const getDeliveriesList = (criteria = {}) => {
  return httpService.FetchDeliveryList(criteria);
};

const submitDelivery = (data) => {
  return httpService.SubmitDelivery(data);
};

const deleteDelivery = (id) => {
  return httpService.DeleteDelivery(id);
};

const getDeliveyById = (criteria = {}) => {
  return httpService.FetchDeliveryInfo(criteria);
};

const editDelivery = (id, data) => {
  return httpService.UpdateDelivery({}, data);
};

const importDeliveryInvoices = (id, data) => {
  
  let fd = new FormData();

  for (let i = 0; i < data.length; i++) {
    fd.append('files', data[i])
  };

  return httpService.UploadDeliveryInvoices(id, fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
};



export { getDeliveriesList, submitDelivery, deleteDelivery, getDeliveyById, editDelivery, importDeliveryInvoices };
