import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { debounce, Grid, TextField } from "@mui/material";
import Switch from '@mui/material/Switch';
import './_companyTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const CompanyTableFilter = ({ company: initialCompany, supplier: initialSupplier,enabledSupplier, getFilterData }) => {
  const classes = useStyles();

  const [company, setCompany] = useState(initialCompany || '');
  const [supplier, setSupplier] = useState(initialSupplier || false);



  const handleChange = (event, property) => {
    if (property === "supplier") {
      setSupplier(event.target.checked);
      const filterData = {
        supplier: event.target.checked,
        company: company
      };
      debounceFn(filterData);

    } else {
      setCompany(event.target.value);
      const filterData = {
        supplier: supplier,
        company: event.target.value
      };

      debounceFn(filterData);
    }
  };


  const handleDebounceFn = (val) => {
    getFilterData(val);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              style={{ width: '200px' }}
              value={company}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        {enabledSupplier && <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              variant="outlined" className={classes.formControl}
              control={
                <Switch
                  checked={supplier}
                  onChange={e => handleChange(e, 'supplier')}
                  name="supplier"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              }
              label="Supplier"
            />
          </FormControl>
        </Grid>}
      </Grid>
    </form>
  );
}