import httpService from './httpService';

const getAllProductCategories = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchProductCategories(criteria);
};

const getProductCategoryById = (criteria = {}) => {
  return httpService.FetchProductCategory(criteria);
};


const deleteProductCategory = (portId) => {
  return httpService.delete(`/api/ProductCategories/ ${ portId }/`);
};

export { getAllProductCategories, deleteProductCategory, getProductCategoryById };
