// Flattens the given object by joining the keys at all levels.
const flattenObject = (o) => {
  const result = {};
  Object.keys(o).forEach((key) => {
    const value = o[key];
    if ('object' !== (typeof value) || null === value) {
      result[key] = value;
    } else {
      const flattened = flattenObject(value);
      Object.keys(flattened).forEach((flattenedKey) => {
        result[`${ key }.${ flattenedKey }`] = flattened[flattenedKey];
      });
    }
  });
  return result;
};

export {
  flattenObject,
};
