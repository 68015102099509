// import MomentUtils from '@date-io/moment';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React, {useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import materialTheme from './assets/materialTheme';
import App from './pages/App';
//import * as serviceWorker from './serviceWorker';
import httpService from "./services/httpService";
import Message from "./components/messages";
import {EventBus} from "./services/eventBus";
import uuid from "./helpers/Uuid";
import PageLoader from "./common/PageLoader/PageLoader";
import './assets/styles/_main.scss';
import './styles.scss';
import {localStorageVersionHandler} from "./helpers/localStorageVersionHandler";
import markerSDK  from '@marker.io/browser';

if (process.env.NODE_ENV === 'qa') {

  const marker = async () => {
    const widget = await markerSDK.loadWidget({

      project: '6569f11bd92a0bae64c6c953',

    });
  }
}

const RootElement = () => {
  const [userState, setUserState] = useState(null);

  const [messages, setMessages] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const getInitUserState = () => {
    return {
      accessToken: null,
      refreshToken: null,
      user : {
        userName: '',
        userRole: '',
        userCompany: '',
        userId: ''
      },
      authorizations: [],
    }
  }

  const onOnlineStatusReceived = () => {
    // Stub --- use Redux for a popUp notification
  };
  const onOfflineStatusReceived = () => {
    // Stub --- use Redux for a popUp notification
  };

  const onUserStateChange = (event) => {
    const newUserState = {
      ...userState,
      ...event.detail
    };
    setUserState(oldUserState => {
      return {
        ...oldUserState,
        ...event.detail
      }
    });
    if(!newUserState.refreshToken) {
      // console.log(newUserState);
      window.localStorage.setItem('userStore', '');
      window.history.pushState(null,null,'/login');
    } else {
      // console.log(newUserState)
      window.localStorage.setItem('userStore', JSON.stringify(newUserState));
    }
  };

  const onJwtUpdate = event => {
    const { accessToken, refreshToken } = event;

    const stateObj = JSON.parse(window.localStorage.getItem('userStore') || '{}');
    if(stateObj) {
      window.localStorage.setItem('userStore', JSON.stringify({
        ...stateObj,
        accessToken,
        refreshToken
      }))
    }

      setUserState(oldUserState => {
        return {
          ...oldUserState,
          accessToken: accessToken || null,
          refreshToken: refreshToken || null
        }
      })
      if(!accessToken && !refreshToken) {
        onErrorMessage("Your authorization has been expired, please login again.");
      }
  };

  const onMessageClose = uuid => {
    setMessages(oldMessages => oldMessages.filter(message => message.id !== uuid));
  }

  const onErrorMessage = message => {
    const newMessage = {
      id: uuid(),
      message
    };
    setMessages(oldMessages => [...oldMessages, newMessage])
  }

  const onLoading = isLoading => {
    setLoading(isLoading);
  }

  useEffect(() => {
    localStorageVersionHandler()
    
    EventBus.subscribe('online', onOnlineStatusReceived);
    EventBus.subscribe('offline', onOfflineStatusReceived);
    EventBus.subscribe('user-state-change', onUserStateChange);
    EventBus.subscribe('jwt-token-update', onJwtUpdate);
    EventBus.subscribe('error-message', onErrorMessage);
    EventBus.subscribe('loading-state', onLoading);

    const state = window.localStorage.getItem('userStore') || '{}';
    const stateObject = JSON.parse(state);

    if (stateObject) {
      setUserState(oldState => {
        return {
          ...oldState,
          ...getInitUserState(),
          ...stateObject
        }
      });
      httpService.injectHeader('Authorization', `Bearer ${ stateObject.accessToken }`);
      httpService.defaults.extra = { ...httpService.defaults.extra, refresh_token: stateObject.refreshToken };
    }

    return () => {

    };
    // eslint-disable-next-line  
  }, []);

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ materialTheme }>
        <LocalizationProvider  dateAdapter={AdapterMoment}>
          <>
            <CssBaseline />
            <PageLoader
              isLoading={isLoading}
            />
            {
              userState?.user &&
              <App userState={userState} />
            }
            <Message
              messages={messages}
              onMessageClose={onMessageClose}
            />
          </>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
};
createRoot(document.getElementById('root')).render(<RootElement />)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
