
const tableHeadersCompany = [
  { id: 'view', disablePadding: false, },
  { id: 'name', disablePadding: false, label: 'Name',},
  { id: 'supplier', disablePadding: false, label: 'Supplier'},
  { id: 'address', disablePadding: false, label: 'Address' },
  { id: 'port', disablePadding: false, label: 'Port' },
  { id: 'country', disablePadding: false, label: 'Country' },
  { id: 'email', disablePadding: false, label: 'email' },
  { id: 'category', disablePadding: false, label: 'Category' },
  { id: 'codingSystem', disablePadding: false, label: 'Coding' },
  { id: 'actions', disablePadding: false, label: ''},
];
// Empty Company
const newCompany = () => {
  return {
    id: '?',
    name: null,
    admin:false,
    supplier: false,
    codingSystem:null,
    address: null,
    country:null,
    enabled:true,
    notes:null,
    port: '',
    email: null,
    category: ''
  }
};

export { tableHeadersCompany, newCompany };
