import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeaderInfo from '../../components/tableHeaderInfo';
import TableRow from '@mui/material/TableRow';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/quotesConfig';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import {StatusIndicator} from "../../common/StatusIndicator/StatusIndicator";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    blueHover: {
        padding: '0px',
        verticalAlign: 'left',
        justifyContent:  'left',
        alignItems: 'left',
        textAlign: 'left',
        position: 'relative',
        color:'inherit',
        display: 'flex',
        flexGrow: 1,
        width: 'auto',
        "&:hover": {
          color: '#036df4'
        }
      }
});


const QuoteInfoTable=(props)=> {

    const classes = useStyles();

    return <>
        <Typography variant="h5" gutterBottom>
            {props.toolbarTitle}
        </Typography>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHeaderInfo
                    headCells={props.role === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
                />
                <TableBody>
                    {props.quotes.map((quote) => (
                        <TableRow key={quote.id}>
                            <TableCell>
                                <IconButton
                                    className='rfqs-quotes-orders-details'
                                    aria-label="expand row"
                                    style={{ padding: '0px' }}
                                    component={RouterLink}
                                    to={{ pathname: `/transactions/${props.transactionId}/quotes/${quote.id}` }}
                                >
                                    <ManageSearchIcon></ManageSearchIcon>
                                </IconButton>
                            </TableCell>
                            <TableCell>{quote.reference_number?quote.reference_number:'N/A'}</TableCell>
                            <TableCell>{(quote.buyer !== undefined && quote.supplier !== undefined) ? (props.role === 'SUPPLIER' ? quote.buyer.name : quote.supplier.name) : ''}</TableCell>
                            <TableCell>{quote.vessel && quote.vessel.name}</TableCell>
                            <TableCell>{quote.category&&quote.category.name}</TableCell>
                            <TableCell>{quote.quoted_items}</TableCell>
                            <TableCell>{quote.submitted&&quote.submitted.slice(0, 10)}</TableCell>
                            <TableCell>{quote.due && quote.due.slice(0, 10)}</TableCell>
                            <TableCell>{quote.port&&quote.port.name}</TableCell>
                            <TableCell>
                                <StatusIndicator status={quote.status}>
                                    {quote.status} 
                                </StatusIndicator>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default QuoteInfoTable;