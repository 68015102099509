import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllVesselSizes } from '../../services/vesselsService';

export default function FilterVesselSizes({ vesselSize: initialvesselSize, getSelectedVesselSize }) {

  const [vesselSize, setvesselSize] = React.useState(0);
  const [VesselSizes, setVesselSizes] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setvesselSize(initialvesselSize && initialvesselSize.id !== null ? initialvesselSize : 0);
    setVesselSizes(initialvesselSize && initialvesselSize.id !== null ? [initialvesselSize] : []);
    getAllVesselSizes().then((res) => {
      setVesselSizes(res.data);
    }).catch((error) => {
    })
  }, [initialvesselSize]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onVesselSizeselectionChange = (event, values) => {
    setvesselSize(values ? values : '');
    const selectedVesselType = {
      vesselSize: values ? values :  null, // return null when the value is empty string
    };
    getSelectedVesselSize(selectedVesselType);
  };


  return (
    <div>
      <Autocomplete
        id={"find-vesselSize" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'vesselSize')}
        onChange={onVesselSizeselectionChange}
        value={VesselSizes.find(v => v.id === vesselSize.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={VesselSizes !== null ? VesselSizes : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('VESSEL_SIZE')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}