import React, {useEffect, useCallback } from "react";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCurrencies } from '../../services/currenciesService';
import t from '../../helpers/languages';
import useStyles from './style';

export default function InlineEditAutocompleteCurrencies({ currency: initialCurrency, getSelectedCurrency, saved, error, req=false }) {
  const classes = useStyles();
  const emptyCurrency = {
    id: null,
    name: null
  };

  const [openCurrency, setOpenCurrency] = React.useState(false);
  const [currency, setCurrency] = React.useState(initialCurrency !== '-' && initialCurrency !== null && initialCurrency.id !== null ? initialCurrency : emptyCurrency);
  const [currencies, setCurrencies] = React.useState([initialCurrency !== '-' && initialCurrency !== null && initialCurrency.id !== null ? initialCurrency : emptyCurrency]);
  const [search, setSearch] = React.useState('');


  useEffect(() => {
    setCurrency(initialCurrency !== '-' && initialCurrency !== null && initialCurrency.id !== null ? initialCurrency : emptyCurrency);
    setCurrencies([initialCurrency !== '-' && initialCurrency !== null && initialCurrency.id !== null ? initialCurrency : emptyCurrency]);
    getAllCurrencies().then((res) => {
      setCurrencies(res.data);
    }).catch((error) => {
    })
}, [initialCurrency]);

  const handleSpanClick = useCallback(() => setOpenCurrency(true), [
    setOpenCurrency
  ]);

  const handleInputChange = (e, fetchType) => {

    const value = e && e.target && e.target.value;

      setSearch(value);
   
  };

  const onCurrencySelectionChange = (event, values) => {
    setCurrency(values ? values : emptyCurrency);

    const selectedCurrency = {
      currency: values ? values : null,
    };

    getSelectedCurrency(selectedCurrency);

  };

  return (
    <span className="inline-text">
      {openCurrency || !saved ? (
        <span>
          <Autocomplete
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            id="find-currency"
            fullWidth
            onOpen={() => {
              setOpenCurrency(true);
            }}
            onClose={() => {
              setOpenCurrency(false);
            }}
            openOnFocus={true}
            onInputChange={e => handleInputChange(e)}
            // onClose={handleClose}
            onChange={onCurrencySelectionChange}
            value={currencies.find(v => v.id === currency.id) || null}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name !== null ? option.name : '')}
            options={currencies !== null ? currencies : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin='none'
                variant="outlined"
                label={t('CURRENCY')}
                error={error && !saved}
                helperText={error && !saved && "Value not saved."}
                InputProps={req&&currency&&currency.id===null?{...params.InputProps,className:classes.input }:{...params.InputProps}}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openCurrency ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            label={t('CURRENCY')}
            defaultValue={currency ? currency.name : emptyCurrency.name}
            variant="outlined"
            InputProps={req&&currency&&currency.id===null?{className:classes.input+' '+'required-fields', readOnly: true }:{ readOnly: true}}
          />
        </span>)}
    </span >
  );
};
