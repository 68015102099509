import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersPort, newPort } from '../../helpers/portConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllPortsInfo, addPort, editPort, deletePort } from '../../services/portsService';
import { PortTableFilter } from '../../components/portTableFilter';
import FilterPortCountries from '../../components/filterPortCountries';
import CheckIcon from '@mui/icons-material/Check';
import './_ports.scss';
import t from '../../helpers/languages';

const Ports = (props) => {

  const portInitObject = () => {
    return {
      id: '?',
      name: '',
      country: { name: '', id: '' },
      latitude: '',
      longitude: '',
      timezone: '',
      loCode: '',
      isUnresolved: false,
    }
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('portFilter');

  const [portsState, setPortsState] = useState({
    ports: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    portEditIndex: -1,
    portEditObject: portInitObject(),
    portCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...portsState.filters,
    country: filters && filters.country && filters.country.id,
    search: filters && filters.search,
    isUnresolved: filters && filters.isUnresolved,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllPortsInfo(filtersData).then(res => {
      const { results } = res.data;

      setPortsState({
        ...portsState,
        ports: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsState({
        ...portsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setPortsState({
      ...portsState,
      isLoading: true
    });
    const newCriteria = {
      ...portsState.filters,
      per_page: portsState.page.per_page,
      page: newPage + 1
    };
    getAllPortsInfo(newCriteria).then(res => {
      setPortsState({
        ...portsState,
        isLoading: false,
        ports: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setPortsState({
      ...portsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...portsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllPortsInfo(newCriteria).then(res => {
      setPortsState({
        ...portsState,
        isLoading: false,
        ports: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsState({
        ...portsState,
        isLoading: false,
      });
    });
  };

  const handleEditPort = (event, portIndex) => {

    event.stopPropagation();

    const portEditObject = Object.assign({}, portsState.ports[portIndex]);


    setPortsState({
      ...portsState,
      portEditIndex: portIndex,
      portEditObject: portEditObject
    });

  };

  const onEditablePortChange = (event, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }


    const newEditPortItem = Object.assign({}, portsState.portEditObject);
    newEditPortItem[property] = newValue;

    setPortsState({
      ...portsState,
      portEditObject: newEditPortItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSavePort = (event, props) => {
    event.preventDefault();
    setPortsState({
      ...portsState,
      isLoading: true
    });
    const newEditPortItem = Object.assign({}, portsState.portEditObject);

    if (validator.current.allValid()) {
      editPort(newEditPortItem.id, newEditPortItem)
        .then(re => {
          const editedPort = re.data;
          setPortsState(oldPorts => {
            return {
              ...oldPorts,
              ports: oldPorts.ports.map(port => {
                return port.id === editedPort.id
                  ? re.data : port
              }),
              portEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPortsState({
            ...portsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setPortsState({
        ...portsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditPort = (e, index) => {
    setPortsState({
      ...portsState,
      portEditIndex: -1
    })
  };

  const handleDeletePort = (event, index) => {

    const portForDeletion = portsState.ports[index];
    setPortsState({
      ...portsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Port',
        onCancel: () => {
          setPortsState({
            ...portsState,
            confirmDialog: {
              ...portsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deletePort(portForDeletion.id).then(() => {
            const newPorts = [...portsState.ports];
            newPorts.splice(index, 1);
            setPortsState({
              ...portsState,
              ports: newPorts,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewPort = (e) => {
    e.preventDefault();

    setPortsState({
      ...portsState,
      portCreateObject: newPort()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewPort = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newPort = {};
    newPort.name = portsState.portCreateObject.name;
    newPort.country = portsState.portCreateObject.country;
    newPort.latitude = portsState.portCreateObject.latitude;
    newPort.longitude = portsState.portCreateObject.longitude;
    newPort.timezone = portsState.portCreateObject.timezone;
    newPort.loCode = portsState.portCreateObject.loCode;
    newPort.isUnresolved = portsState.portCreateObject.isUnresolved;

    if (validator.current.allValid()) {
      addPort(newPort)
        .then(res => {
          setPortsState({
            ...portsState,
            ports: [...portsState.ports, res.data],
            portCreateObject: null,
            portEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setPortsState({
            ...portsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewPortObject = (event, property) => {
    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditPortItem = Object.assign({}, portsState.portCreateObject);
    newEditPortItem[property] = newValue;

    setPortsState({
      ...portsState,
      portCreateObject: newEditPortItem
    });
  };

  const filterData = (data) => {
    setPortsState({
      ...portsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...portsState.filters,
      search: data && data.search,
      country: data && data.country && data.country.id,
      isUnresolved: data && data.isUnresolved
    };

    getAllPortsInfo(filtersData).then(res => {
      const { results } = res.data;

      setPortsState({
        ...portsState,
        ports: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('portFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setPortsState({
        ...portsState,
        isLoading: false,
      });
    });

  };


  const selectedPortCountry = (data, action) => {

    const newEditPortItem = Object.assign({},
      action === 'edit'
        ? portsState.portEditObject
        : portsState.portCreateObject
    );

    newEditPortItem.country = data.country;

    setPortsState({
      ...portsState,
      [
        action === 'edit'
          ? 'portEditObject'
          : 'portCreateObject'
      ]: newEditPortItem
    });
  };

  const tableFilterComponent = () => {
    return <PortTableFilter
      search={(filters && filters.search) ? filters.search : portsState.filters.search}
      country={(filters && filters.country) ? filters.country : portsState.filters.country}
      isUnresolved={(filters && filters.isUnresolved) ? filters.isUnresolved : portsState.filters.isUnresolved}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersPort}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(portsState.ports) && portsState.ports.map((port, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (portsState.portEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{port.name}</TableCell>
                    <TableCell>{port.country?.name}</TableCell>
                    <TableCell>{port.latitude}</TableCell>
                    <TableCell>{port.longitude}</TableCell>
                    <TableCell>{port.timezone}</TableCell>
                    <TableCell>{port.loCode}</TableCell>
                    <TableCell>{port.isUnresolved && <CheckIcon />}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsState.portEditObject.name || ''}
                          onChange={e => onEditablePortChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', portsState.portEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterPortCountries
                          country={portsState.portEditObject.country || null}
                          getSelectedPortCountry={(data) => selectedPortCountry(data, 'edit')}
                        />{validator.current.message('country', portsState.portEditObject.country, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsState.portEditObject.latitude || ''}
                          onChange={e => onEditablePortChange(e, 'latitude')}
                          onBlur={() => validator.current.showMessageFor('latitude')}
                        />{validator.current.message('latitude', portsState.portEditObject.latitude, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsState.portEditObject.longitude || ''}
                          onChange={e => onEditablePortChange(e, 'longitude')}
                          onBlur={() => validator.current.showMessageFor('longitude')}
                        />{validator.current.message('longitude', portsState.portEditObject.longitude, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsState.portEditObject.timezone || ''}
                          onChange={e => onEditablePortChange(e, 'timezone')}
                          onBlur={() => validator.current.showMessageFor('timezone')}
                        />{validator.current.message('timezone', portsState.portEditObject.timezone, 'max:10')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsState.portEditObject.loCode || ''}
                          onChange={e => onEditablePortChange(e, 'loCode')}
                          onBlur={() => validator.current.showMessageFor('loCode')}
                        />{validator.current.message('loCode', portsState.portEditObject.loCode, 'max:10')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={portsState.portEditObject.isUnresolved}
                              onChange={e => onEditablePortChange(e, 'isUnresolved')}
                              name="isUnresolved"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="isUnresolved"
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: portsState.portEditIndex !== index,
                    callback: (e) => handleEditPort(e, index)
                  },
                  delete: {
                    enabled: portsState.portEditIndex !== index,
                    callback: (e) => handleDeletePort(e, index)
                  },
                  close: {
                    enabled: portsState.portEditIndex === index,
                    callback: (e) => handleCloseEditPort(e, index)
                  },
                  save: {
                    enabled: portsState.portEditIndex === index,
                    callback: (e) => handleSavePort(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          portsState.portCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsState.portCreateObject.name || ''}
                  onChange={e => onEditNewPortObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', portsState.portCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterPortCountries
                  country={portsState.portCreateObject.country || null}
                  getSelectedPortCountry={(data) => selectedPortCountry(data, 'create')}
                />{validator.current.message('country', portsState.portCreateObject.country.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsState.portCreateObject.latitude || ''}
                  onChange={e => onEditNewPortObject(e, 'latitude')}
                  onBlur={() => validator.current.showMessageFor('latitude')}
                />{validator.current.message('latitude', portsState.portCreateObject.latitude, 'numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsState.portCreateObject.longitude || ''}
                  onChange={e => onEditNewPortObject(e, 'longitude')}
                  onBlur={() => validator.current.showMessageFor('longitude')}
                />{validator.current.message('longitude', portsState.portCreateObject.longitude, 'numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsState.portCreateObject.timezone || ''}
                  onChange={e => onEditNewPortObject(e, 'timezone')}
                  onBlur={() => validator.current.showMessageFor('timezone')}
                />{validator.current.message('timezone', portsState.portCreateObject.timezone, 'max:10')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsState.portCreateObject.loCode || ''}
                  onChange={e => onEditNewPortObject(e, 'loCode')}
                  onBlur={() => validator.current.showMessageFor('loCode')}
                />{validator.current.message('loCode', portsState.portCreateObject.loCode, 'max:10')}
              </FormControl>
            </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={portsState.portCreateObject.isUnresolved}
                        onChange={e => onEditNewPortObject(e, 'isUnresolved')}
                        name="isUnresolved"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="isUnresolved"
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setPortsState({ ...portsState, portCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewPort()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PORTS')}
        pagination={{
          total: portsState.page.total || 0,
          page: portsState.page.page - 1 || 0,
          perPage: portsState.page.per_page || 10,
          count: portsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewPort}
        newRowBtnEnabled={!portsState.portCreateObject}
        confirmDialog={portsState.confirmDialog.message.length > 0 ? portsState.confirmDialog : portsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={portsState.isLoading}
      />
    </>
  );
};


export default Ports;
