import React from 'react';
import ContractsRegion from '../contractsRegion/ContractsRegion';
import PortsRegion from '../portsRegion/PortsRegion';
import CountriesRegion from '../countriesRegion/CountriesRegion';
import useStyles from "./styles";
import { TabPanel, a11yProps } from "../../components/tabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import SimpleBreadcrumbs from '../../components/breadcrumbs';

const RegionsInfo = (props) => {

	const classes = useStyles();
	const [value, setValue] = React.useState(0);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.companyInfoWrapper}>
			<SimpleBreadcrumbs
				buttons={false}
				norfq={true}
			/>
			<Paper
				className={classes.paper}
				elevation={4}
			>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					direction="row"
					spacing={3}>
					<Grid item xs={12}>
						<div className={classes.root}>
							<Tabs
								orientation="vertical"
								variant="scrollable"
								value={value}
								onChange={handleChange}
								aria-label="Vertical tabs example"
								className={classes.tabs}
							>
								<Tab label="Ports" value={0}  {...a11yProps(0)}>
								</Tab>
								<Tab label="Countries" value={1}  {...a11yProps(1)}>
								</Tab>
								<Tab label="Contract Regions" value={2}  {...a11yProps(3)}>
								</Tab>
							</Tabs>
							<TabPanel value={value} index={0}>
								<PortsRegion
									userState={props.userState}
									pageFilter={true}
									autonomousPage={false}
								/>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<CountriesRegion
									userState={props.userState}
									pageFilter={true}
									autonomousPage={false}
								/>
							</TabPanel>
							<TabPanel value={value} index={2}>
								<ContractsRegion
									userState={props.userState}
									pageFilter={true}
									autonomousPage={false}
								/>
							</TabPanel>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</div >
	);
}

export default RegionsInfo;