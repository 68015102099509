import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { Link as RouterLink } from 'react-router-dom';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/ordersConfig';
import { TableFilter } from '../../components/tableFilter';
import { getSupplyStatus } from '../../services/supplyStatusService';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { getOrders } from '../../services/ordersService';
import t from '../../helpers/languages';
import {StatusIndicator} from "../../common/StatusIndicator/StatusIndicator";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const Orders = (props) => {
  const orderInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      status: '',
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      due: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      order_items: '',
      isCompleted: ''
    }
  };

  const filters = localStoreGetObj('orderFilter');

  const [orderStatusState, setOrderStatusState] = useState({
    orderStatuses: []
  });

  const [ordersState, setOrdersState] = useState({
    orders: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {},
    isLoading: true,
    orderEditIndex: -1,
    orderEditObject: orderInitObject(),
    orderCreateObject: null,
    error: null,
  });

  //const { userState } = props;
  const userState = props.userState && props.userState.user;


  const classes = useStyles();

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);


  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...ordersState.filters,
      port: filters && filters.port && filters.port.id,
      buyer: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...ordersState.filters,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.company && filters.company.id,
      vessel:  filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      status: filters && filters.status,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }

  useEffect(() => {
    const statusName = { type: 'order' };
    getSupplyStatus(statusName).then(res => {
      setOrderStatusState({
        ...orderStatusState.orderStatuses,
        orderStatuses: res.data,
      });
    }).catch((error) => {

    }).finally(() => {
      
    });

    getOrders(filtersData).then(res => {
      const { results } = res.data;
      setOrdersState({
        ...ordersState,
        orders: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    event.preventDefault();

    const isAsc = property===ordersState.page.order_by&&ordersState.page.order_type==='desc'?'asc':'desc';
   
    setOrdersState({
      ...ordersState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getOrders(filtersData).then(res => {
      const { results } = res.data;
      setOrdersState({
        ...ordersState,
        orders: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    })

  };


  const handleChangePage = (event, newPage) => {
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    const newCriteria = {
      ...ordersState.filters,
      per_page:ordersState.page.per_page,
      page: newPage + 1
    };

    getOrders(newCriteria).then(res => {
      setOrdersState({
        ...ordersState,
        isLoading: false,
        orders: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setOrdersState({
      ...ordersState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...ordersState.filters,
      page: 1,
      per_page: perPage,
    };

    getOrders(newCriteria).then(res => {
      setOrdersState({
        ...ordersState,
        isLoading: false,
        orders: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  };


  const filterData = (data) => {
    setOrdersState({
      ...ordersState,
      isLoading: true
    });

    let filtersData = '';

    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...ordersState.filters,
        buyer: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...ordersState.filters,
        supplier: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        status: data && data.status,
        per_page: criteria.per_page,
        page: 1
      };
    }

    getOrders(filtersData).then(res => {
      setOrdersState({
        ...ordersState,
        isLoading: false,
        orders: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('orderFilter', data);
    }).catch((error) => {

      setOrdersState({
        ...ordersState,
        isLoading: false,
      });
    });
  };

  const tableFilterComponent = () => {
    return <TableFilter
      companyField={true}
      searchByText={false}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"} // set buyer filter for supplier and vice versa.
      company={(filters && filters.company) ? filters.company : ordersState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : ordersState.filters.vessel}
      productCategory={(filters && filters.category) ? filters.category : ordersState.filters.category}
      port={(filters && filters.port) ? filters.port : ordersState.filters.port}
      from={(filters && filters.from) ? filters.from : ordersState.filters.from_date}
      to={(filters && filters.to) ? filters.to : ordersState.filters.to_date}
      status={(filters && filters.status) ? filters.status : ordersState.filters.status}
      statuses={orderStatusState.orderStatuses}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      order={ordersState.page.order_type}
      orderBy={ordersState.page.order_by}
      rowCount={ordersState.page.length} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {ordersState.orders && ordersState.orders.map((order, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>
                <IconButton
                  className='table-more-info'
                  aria-label="expand row"
                  style={{padding:'0px'}}
                  component={RouterLink}
                  to={{ pathname: `/transactions/${order.transaction.id}/orders/${order.id}` }}
                >
                  <ManageSearchIcon></ManageSearchIcon>
                </IconButton>
              </TableCell>
              <TableCell>{order.reference_number?order.reference_number:'N/A'}</TableCell>
              <TableCell>{(order.buyer !== undefined && order.supplier !== undefined) ? (userState.userRole === 'SUPPLIER' ? order.buyer.name : order.supplier.name) : ''}</TableCell>
              <TableCell>{order.vessel&&order.vessel.name}</TableCell>
              <TableCell>{order.category&&order.category.name}</TableCell>
              <TableCell>{order.amountWithCurrency}</TableCell>
              <TableCell>{order.submitted&&order.submitted.slice(0, 10)}</TableCell>
              <TableCell>{order.due&&order.due.slice(0, 10)}</TableCell>
              <TableCell>{order.port&&order.port.name}</TableCell>
              <TableCell>
                <StatusIndicator status={order.status}>
                  {order.status}
                </StatusIndicator>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('ORDERS')}
        pagination={{
          total: ordersState.page.total || 0,
          page: ordersState.page.page - 1 || 0,
          perPage: ordersState.page.per_page || 10,
          count: ordersState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={() => { }}

        confirmDialog={{
          message: '',
          title: '',
          onCancel: () => { },
          onConfirm: () => { },
          isLoading: false,
          isOpen: false
        }}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={ordersState.isLoading}
      />
    </>
  );
};


export default Orders;