import React, { useCallback } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllPorts } from '../../services/portsService';

export default function FilterPortsMultiple({ port: initialPort, getSelectedPort }) {

  const [port, setPort] = React.useState(initialPort !== undefined ? initialPort : []);
  const [ports, setPorts] = React.useState(initialPort !== undefined ? initialPort : []);
  const [openPort, setOpenPort] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "port" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);

    };
  };

  const handleDebounceFn = (val) => {
    const filterData = {
      search: val,
      isUnresolved:false,
    };
    portSearch(filterData);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onPortSelectionChange = (event, values) => {
    setPort(values ? values : '');
    const selectedPort = {
      port: values ? values : '',
    };
    getSelectedPort(selectedPort);
  };

  // Join Without Dupes.
  const joinWithoutDupes = (A, B) => {
    const a = new Set(A.map(x => x.item))
    const b = new Set(B.map(x => x.item))
    return [...A.filter(x => !b.has(x.item)), ...B.filter(x => !a.has(x.item))]
  }

  const portSearch = (criteria) => {

    return getAllPorts(criteria).then((res) => {
      const dataRes = res.data;
      setPorts(joinWithoutDupes(dataRes, ports));
    }).catch((error) => {
    })
  }

  return (
    <Autocomplete
      multiple
      id="find-port-multiple"
      fullWidth
      open={openPort}
      onOpen={() => {
        setOpenPort(true);
      }}
      onClose={() => {
        setOpenPort(false);
      }}
      onInputChange={e => handleInputChange(e, 'port')}
      onChange={onPortSelectionChange}
      value={ports !== undefined ? port : []}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      getOptionLabel={(option) => (option.name)}
      options={ports !== null && ports.length !== 0 ? ports : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('PORT')}
          variant="outlined"
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
}