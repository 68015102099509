import React, { useEffect, useCallback } from "react";
import useStyles from './style';
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import t from '../../helpers/languages';

export default function InlineEditNotes({ notes: initialNotes, getNotes, saved, error, label='NOTES', req=false}) {
    const classes = useStyles();
    const emptyNotes = '';
    const [openNotes, setOpenNotes] = React.useState(false);
    const [notes, setNotes] = React.useState(initialNotes);
    
    useEffect(() => {
        setNotes(initialNotes!==null?initialNotes:null);
      }, [initialNotes]);

    const handleSpanClick = useCallback(() => setOpenNotes(true), [
        setOpenNotes
    ]);

    const onNotesSelectionChange = (event, values) => {
        
        setNotes(event.target.value);
    };

    // const handleNotes = (val) => {
    //     setNotes(val);
    //     getNotes(emptyNotes);
    //     setOpenNotes(false);
    // };

    const onClose=()=>{
          setOpenNotes(false);
          const entryNotes={
              notes:notes
          };
          getNotes(entryNotes);
    }

    const randomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
      }

    return (
        <span className="inline-text">
            {openNotes || !saved ? (
                <span>
                    <Grid container item md={12} wrap='wrap' justifyContent="flex-start" alignItems="flex-end">
                        <TextField
                            value={notes?notes:''}
                            autoFocus
                            //key={'index-inline-notes' + randomNumber(0,999)}
                            margin='none'
                            variant="outlined"
                            onChange={e => onNotesSelectionChange(e, 'notes')}
                            error={error && !saved}
                            placeholder={t(label)}
                            label={t(label)}
                            onFocus={(event) => {
                                
                                const lengthOfInput = event.target.value.length;
                                setOpenNotes(true);
                                return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
                            }}
                            fullWidth
                            multiline
                            InputProps={req&&!notes?{className:classes.input}:{}}
                            onBlur={() =>onClose()}
                            helperText={error && !saved && "Value not saved."}
                         />

                        {/* <IconButton
                            edge="end"
                            size="small"
                            disabled={!notes}
                            onClick={() => handleNotes('')}
                        >
                            <ClearIcon />
                        </IconButton> */}
                    </Grid>

                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openNotes ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                        <TextField
                            fullWidth
                            key={'index-inline-notes-display' + randomNumber(0,999)}
                            multiline
                            label={t(label)}
                            value={notes ? notes : emptyNotes}
                            variant="outlined"
                            InputProps={req&&!notes?{className:classes.input+' '+'required-fields', readOnly: true }:{readOnly: true }}
                        />
                </span>)}
        </span >
    );
};