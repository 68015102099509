import httpService from './httpService';
const createMessage = (id, entity, messageText) => {
  return httpService.CreateMessageByEntity({id, entity}, {message: messageText}, {
    extra: {'no-loading-indicator': true}
  });
};

const getMessage = (id, entity) => {
  return httpService.GetMessagesByEntity({id, entity}, undefined, {
    extra: {'no-loading-indicator': true}
  });
};

const getUnreadMessages = (id, entity) => {
  return httpService.GetUnreadEntityMessageCount({id, entity}, undefined,{
    extra: {'no-loading-indicator': true}
  });
};

const setMessagesRead = (id, entity) => {
  return httpService.SetAllEntityMessagesRead({id, entity}, undefined, {
    extra: {'no-loading-indicator': true}
  });
};

export {
  createMessage,
  getMessage,
  getUnreadMessages,
  setMessagesRead
};
