
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InlineEditNotes from '../../components/inlineEditNotes';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import './_account-settings.scss';
import clsx from "clsx";

const AccountPreferencesEdit = ({
    prefsState,
    getPrefVal
}) => {


    const prefVal = (data, index) => {
        getPrefVal(data, index);
    };

    let category=null;

    const Heading = (headtext) => {
        category=headtext;
        return (<Typography variant="h5" style={{ marginTop: 15 }} gutterBottom>
            {headtext}
        </Typography>)
    }
    return prefsState !== '?' &&!prefsState.isLoading&& (
        
        <div className={'company-form'}>
            {<form className={clsx({
                'company-form__form': true,
            })}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >     {prefsState.items.map((pref, index) => {
       
                    return (
                        <Grid item xs={12}  key={'preference-grid' + index}>
                            {category !== pref.preference.category.description&&
                                    Heading(pref.preference.category.description)}
                            <FormControl variant="outlined" fullWidth>
                                <InlineEditNotes
                                    key={'preference-item' + index}
                                    notes={pref.preference.id!==null?pref.value:pref.preference.defaultValue}
                                    saved={prefsState.isPreferenceSaved[index]!==null?true:prefsState.isPreferenceSaved[index]}
                                    label={pref.preference.description}
                                    error={prefsState.error}
                                    getNotes={(data) => prefVal(data, index)}
                                />
                            </FormControl>
                        </Grid>
                    );
                })
                    }
                </Grid>
            </form>
            }
        </div >
    );

};


export default AccountPreferencesEdit;