import React, { useCallback, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllSuppliersInfo } from '../../services/companiesService';

export default function FilterSuppliers({
    supplier: initialSupplier,
    getSelectedSupplier,
    errorMessage
}) {

    const [supplier, setSupplier] = React.useState(initialSupplier && initialSupplier.id !== null ? initialSupplier : 0);
    const [openSupplier, setOpenSupplier] = React.useState(false);
    const [companies, setSuppliers] = React.useState(initialSupplier && initialSupplier.id !== null ? [initialSupplier] : []);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        setSupplier(initialSupplier && initialSupplier.id !== null ? initialSupplier : 0);
        setSuppliers(initialSupplier && initialSupplier.id !== null ? [initialSupplier] : []);
        !initialSupplier && setSearch('');
    }, [initialSupplier]);

    let labelFilter = t('ADD_SUPPLIER');

    const handleInputChange = (e, fetchType) => {
        const value = e && e.target && e.target.value;
        if (value && value.length >= 3 && search !== value) {
            setSearch(value);
            debounceFn(value);
        };
    };

    const randomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const handleDebounceFn = (val) => {
        supplierSearch({
            search: val
        })
    };
    const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

    const onSupplierSelectionChange = (event, values) => {
        setSupplier(values ? values : '');
        const selectedSupplier = {
            supplier: values ? values : '',
        };
        getSelectedSupplier(selectedSupplier);

    };

    const supplierSearch = (criteria) => {
        return getAllSuppliersInfo(criteria).then((res) => {
            setSuppliers(res.data);
        }).catch((error) => {
        })
    }

    return (
        <div>
            <Autocomplete
                // set the width to the input length multiplied by the x height
                // it's not quite right but gets it close
                id={"find-supplier" + randomNumber(0, 999)}
                fullWidth
                open={openSupplier}
                onOpen={() => {
                    setOpenSupplier(true);
                }}
                onClose={() => {
                    setOpenSupplier(false);
                }}
                openOnFocus={true}
                onInputChange={e => handleInputChange(e)}
                onChange={onSupplierSelectionChange}
                value={companies.find(v => v.id === supplier.id) || null}
                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                getOptionLabel={(option) => (option.name !== null ? option.name + ' - ' + option.category?.name + ' - ' + option.email : '')}
                options={companies !== null ? companies : null}
                renderInput={(params) => (
                    <TextField
                        fullWidth
                        {...params}
                        label={labelFilter}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                )}
            />
        </div>
    );
};
