import React from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import OutlineDisplay from '../../components/outlineDisplay';
import InlineEditNotes from '../../components/inlineEditNotes';
// import InlineEditDate from '../../components/inlineEditDate';

const DeliveriesInfo = (props) => {

    const deliveriesNotes = (data) => {
        props.getNotes(data);
    };

    return (
            <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <OutlineDisplay label={t('VESSEL')} value={props.vessel} />
                </Grid>
                <Grid item xs={4}>
                    <OutlineDisplay label={t('PORT')} value={props.port} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('REFERENCE_NUMBER')} value={props.referenceNumber} />
                </Grid>
                <Grid item xs={2}>
                    <OutlineDisplay label={t('SUPPLIED')} value={props.supplied} />
                </Grid>
                <Grid item xs={8}>
                  {props.userRole === "BUYER" ?
                      <OutlineDisplay label={t('SUPPLIER')} value={props.supplier} /> :
                      <OutlineDisplay label={t('BUYER')} value={props.buyer} />
                  }
                </Grid>
                <Grid item xs={4}>
                    <OutlineDisplay label={t('CATALOGUE')} value={props.catalogue} />
                </Grid>
                <Grid item xs={12}>
                    {props.edit ?
                        <InlineEditNotes notes={props.notes} saved={props.savedNotes} error={props.error}
                            getNotes={deliveriesNotes} /> : <OutlineDisplay label={t('NOTES')} value={props.notes} />}
                </Grid>
            </Grid>
    )
}


export default DeliveriesInfo;