import httpService from './httpService';


const getAllCompanyDictionariesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.GetCompanyDicts(criteria);
};

const deleteCompanyDictionary = (id) => {
  return httpService.DeleteCompanyDict(id);
};

const editCompanyDictionary = (id, data) => {
   return httpService.UpdateCompanyDict({}, data);
};

const addCompanyDictionary = (data) => {
  return httpService.CreateCompanyDict({},data);
};

export { getAllCompanyDictionariesInfo, deleteCompanyDictionary, addCompanyDictionary, editCompanyDictionary };
