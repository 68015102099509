import React, { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeaderColumnGroup from '../../components/tableHeaderColumnGroup';
import { urlParams } from "../../helpers/urlParams";
import { styled } from '@mui/material/styles';
import useStyles from './styles';
import { compareTransaction } from '../../services/transactionsService';
import { TableFilterRequestComparison } from '../../components/tableFilterRequestComparison';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeaders } from '../../helpers/requestComparisonConfig';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import t from '../../helpers/languages';
import './_requestComparison.scss';

const RequestComparison = (props) => {

  const classes = useStyles();

  const [compareTransactionsState, setCompareTransactionsState] = useState({
    isLoading: false,
    filters: {},
    page: {
      page: 1,
      count: 0,
      per_page: 50,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    headers: [],
    prices: [],
    totals: null,
    totalsUSD: null,
  });

  const filters = localStoreGetObj('productComparisonFilter');
  
  const pathName = window.location.pathname;
  const regex = /\d+/;
  let m;

  m = regex.exec(pathName);

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 50)
  !criteria.page && (criteria.page = 1)

  const trsId = m[0];

  urlParams.setUrlParams(criteria);

  const filterData = (data) => {
    setCompareTransactionsState({
      ...compareTransactionsState,
      isLoading: true,
    });

    let filtersData  = {
        // ...regionPurchasesState.filters,
        ports: data && data.port?.map((data) => {
          return {
            portId: data.id,
            countryId: data.countryId,
          }
        }),
        portFirst: data && data.portFirst,
        contracts: data && data.contract && data.contract.map(({id}) => id),
        per_page: criteria.per_page,
        page: 1
      };

    if (data && data.port && data.contract && data.port.length>0 && data.contract.length>0) {
      compareTransaction(trsId, filtersData).then(res => {
        const results = res.data;
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
          headers: results.headers,
          prices: results.prices,
          page: res.data.page,
          totals: res.data.totals,
          totalsUSD: res.data.totalsUSD,
        });
        localStoreSetObj('productComparisonFilter', data);
      }).catch((error) => {
        console.log(error);
        setCompareTransactionsState({
          ...compareTransactionsState,
          isLoading: false,
        });
      });
    }
  };



  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#f5f5f9',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={false}
     

    />;
  };

  const tableFilterComponent = () => {
    return <TableFilterRequestComparison
      portFirst={false}
      contract={filters && filters.contract}
      port={filters && filters.port}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return !compareTransactionsState.isLoading &&compareTransactionsState.headers?.length>0&&<TableHeaderColumnGroup
      handleSorting={() => { }}
      headCells={compareTransactionsState.headers}
      headCellsConfig={tableHeaders}
      onSelectAllClick={() => { }}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {!compareTransactionsState.isLoading &&compareTransactionsState.prices && compareTransactionsState.prices.map((data, index) => {
    
          return (
            <TableRow
              key={'index5' + index}
              className={classes.tableRow}
            >
              <TableCell key={'index6' + index} align={'left'}>{data.productName}</TableCell>
              <TableCell key={'index7' + index} align={'center'}>{data.quantity}</TableCell>
              <TableCell key={'index8' + index} align={'center'}>{data.unitName}</TableCell>
              {data.costs.map((cost, dindex) => {
                return (
                  <HtmlTooltip
                    key={'index29' + dindex}
                    title={
                      cost !== null&&<React.Fragment>
                        Equivalent: {cost !== null ? cost.equivalentProductName : ''} <br />
                        {cost&&cost.packingCharge!==null? 'Packing Charge: '+ cost?.packingCharge:''} {cost&&cost.packingCharge!==null&&<br />}
                        Net Price per Unit: {cost !== null ? cost.price : ''}/{cost !== null ? cost.priceUnitName : ''}
                      </React.Fragment>
                    }
                  >
                    <TableCell key={'index9' + dindex} align={'center'}>{cost !== null ? cost.totalCost : ''}</TableCell>
                  </HtmlTooltip>
                )
              })}
            </TableRow>
          );
        })}
        {!compareTransactionsState.isLoading && compareTransactionsState.totals && <TableRow>
          <TableCell colSpan={2} align="right"></TableCell>
          <TableCell align="center"><Typography variant="subtitle2" gutterBottom>Total</Typography></TableCell>
          {compareTransactionsState.totals.map((total, indexa) => {
            return (
              <TableCell key={'indexa' + indexa} align="center"><Typography variant="subtitle2" gutterBottom>{total}</Typography></TableCell>);
          })}
        </TableRow>}
        {!compareTransactionsState.isLoading && compareTransactionsState.totals && <TableRow>
          <TableCell colSpan={2} align="right"></TableCell>
          <TableCell align="center"><Typography variant="subtitle2" gutterBottom>Total USD</Typography></TableCell>
          {compareTransactionsState.totalsUSD.map((total, indexaa) => {
            return (
              <TableCell key={'indexaa' + indexaa} align="center"><Typography variant="subtitle2" gutterBottom>{total}</Typography></TableCell>);
          })}
        </TableRow>}
      </TableBody>
    );
  }

  return (
    <ProcureDataTable
      toolbarTitle={t('REQUEST_COMPARISON')}
      pagination={{
        total:  0,
        page:  0,
        perPage: 50,
        count:  0,
        disabled:true,
        handleChangePage: () => { },
        handleChangeRowsPerPage: () => { },
      }}
      addNewRawBtnTitle={''}
      onNewRowClick={() => { }}
      confirmDialog={{
        message: '',
        title: '',
        onCancel: () => { },
        onConfirm: () => { },
        isLoading: false,
        isOpen: false
      }}
      tableFilter={tableFilterComponent}
      breadcrump={breadcrump}
      autonomousPage={props.autonomousPage}
      tableHeader={tableHeaderComponent}
      tableBody={tableBodyComponent}
      isLoading={compareTransactionsState.isLoading}
    />
  );
};

export default RequestComparison;