import React, { useCallback } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllProducts } from '../../services/productsService';

export default function FilterProducts({ product: initialProduct, port:initialPort=null, supplier:initialSupplier=null, getSelectedProduct, catalogue }) {

  const [product, setProduct] = React.useState(initialProduct && initialProduct.id !== null ? initialProduct : 0);
  const [products, setProducts] = React.useState(initialProduct && initialProduct.id !== null ? [initialProduct] : []);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "product" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    productSearch({
      search: val,
      category: catalogue,
      port:initialPort, 
      supplier:initialSupplier
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onProductSelectionChange = (event, values) => {
    setProduct(values ? values : '');
    const selectedProduct = {
      product: values ? values : '',
    };
    getSelectedProduct(selectedProduct);
  };
  const productSearch = (criteria) => {

    return getAllProducts(criteria).then((res) => {
      setProducts(res.data);
    }).catch((error) => {
    })
  }

  return (
    <div>
      <Autocomplete
        id="find-product"
        fullWidth
        open={openProduct}
        onOpen={() => {
          setOpenProduct(true);
        }}
        onClose={() => {
          setOpenProduct(false);
        }}
        onInputChange={e => handleInputChange(e, 'product')}
        onChange={onProductSelectionChange}
        value={products.find(v => v.id === product.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : '')}
        options={products !== null ? products : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('PRODUCT')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}