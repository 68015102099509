
const tableHeadersVesselBuyer = [

  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'yearBuilt', disablePadding: false, label: 'Year Built' },
  { id: 'dwt', disablePadding: false, label: 'DWT' },
  { id: 'vesselType', disablePadding: false, label: 'Vessel Type' },
  { id: 'vesselSize', disablePadding: false, label: 'Vessel Size' },
  { id: 'imonumber', disablePadding: false, label: 'IMO Number' },
  { id: 'isActive', disablePadding: false, label: 'isActive' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersVesselMBA = [

  { id: 'name', disablePadding: false, label: 'Name', width:'15%'},
  { id: 'companyId', disablePadding: false, label: 'Company', width:'15%'},
  { id: 'yearBuilt', disablePadding: false, label: 'Year Built', width:'10%'},
  { id: 'dwt', disablePadding: false, label: 'DWT', width:'10%'},
  { id: 'vesselType', disablePadding: false, label: 'Vessel Type', width:'15%'},
  { id: 'vesselSize', disablePadding: false, label: 'Vessel Size', width:'15%'},
  { id: 'imonumber', disablePadding: false, label: 'IMO Number', width:'10%'},
  { id: 'isActive', disablePadding: false, label: 'isActive', width:'3%'},
  { id: 'row-actions', disablePadding: false, width:'7%'},
];

// Empty vessel
const newVessel = () => {
  return {
    id: '?',
    name: '',
    company: { name: '', id: '' },
    dwt:'',
    vesselType:{ name: '', id: '' },
    vesselSize:{ name: '', id: '' },
    yearBuilt: '',
    isActive: true,
    imonumber: ''
  }
};

export { tableHeadersVesselBuyer, tableHeadersVesselMBA, newVessel };
