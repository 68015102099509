import React, { useEffect, useCallback } from "react";
import useStyles from './style';
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import t from '../../helpers/languages';

export default function InlineEditPass({ pass: initialPass, getPass, saved, error, label = 'PASSWORD' }) {
    const classes = useStyles();
    const [openPass, setOpenPass] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);
    const [pass, setPass] = React.useState(initialPass);


    useEffect(() => {
        setPass(initialPass !== null ? initialPass : null);
    }, [initialPass]);

    const handleSpanClick = useCallback(() => setOpenPass(true), [
        setOpenPass
    ]);
    
    const onPassSelectionChange = (event, values) => {
        setPass(event.target.value);
    };

    // const handlePass = (val) => {
    //     setPass(val);
    //     getPass(emptyPass);
    //     setOpenPass(false);
    // };

    const onClose = () => {
        setOpenPass(false);
        const entryPass = {
            pass: pass
        };
        getPass(entryPass);
    }

    const handleClickShowPassword = (e) => {
        e.stopPropagation();
        setShowPass({
            ...showPass,
            showPass: !showPass
        })
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <span className="inline-text">
            {openPass || !saved ? (
                <span>
                    <Grid container item md={12} wrap='wrap' justifyContent="flex-start" alignItems="flex-end">
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="standard-adornment-password"
                            type={showPass ? 'text' : 'password'}
                            value={pass || ''}
                            autoFocus
                            onFocus={() => {
                                setOpenPass(true);
                            }}
                            onBlur={() => onClose()}
                            name={'password'}
                            onChange={e => onPassSelectionChange(e, 'pass')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={e => handleClickShowPassword(e)}
                                        onMouseDown={handleMouseDownPassword}
                                        size="large">
                                        {showPass ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>

                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openPass ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <TextField
                        fullWidth
                        multiline
                        id="outlined-disabled-pass"
                        label={t(label)}
                        defaultValue={''}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </span>)}
        </span >
    );
};