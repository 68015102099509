import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';

import t from '../../helpers/languages';
import useStyles from './styles';

const TableHeader = (props) => {
  const { headCells, order, orderBy, handleSorting, sort=true } = props;

  const createSortHandler = (property) => {
    return (event) => {
      handleSorting(event, property);
    };
  };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={ index }
              padding={ headCell.disablePadding ? 'none' : 'normal' }
              sortDirection={ orderBy === headCell.id ? order : false }
              width={headCell.width}
              align={headCell.align}
            >
              {sort?(<TableSortLabel
                active={ orderBy === headCell.id }
                direction={ orderBy === headCell.id ? order : 'asc' }
                onClick={ createSortHandler(headCell.id) }
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={ classes.visuallyHidden }>
                    {'desc' === order ? t('SORTED_DESCENDING') : t('SORTED_ASCENDING')}
                  </span>
                ) : null}
              </TableSortLabel>)
              :(headCell.label)
              }
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
