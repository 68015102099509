import React from 'react';
import Button from '@mui/material/Button';
import TableContainer from "@mui/material/TableContainer";
import {
    TextField
} from "@mui/material";
import FilterPortCountries from "../../components/filterPortCountries";
import usePortMatchHook from './usePortMatchHook';
import './PortMatch.scss'
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import PortMatchTable from "./PortMatchTable";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { EditRow } from './EditRow';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import t from '../../helpers/languages';

const PortMatch = (props) => {
    const {
        unresolvedPorts,
        isConfirmDialogOpen,
        resolvedPorts,
        filters,
        selectedPorts,
        onFilterPortCountryChange,
        onPortClick,
        onMatchBtnClick,
        setConfirmDialogOpen,
        onSearchTextChange,
        onPortMatchConfirm,
        onIsPricelistChange,
        validator,
        confirmDialogDeleteOpen,
        onEditablePortChange,
        handleSavePort,
        handleChangePage,
        handleChangeRowsPerPage,
        handleDeletePort,
        ref,
        handleCloseEditPort,
        selectedPortCountry,
    } = usePortMatchHook();

    return (
        <div className={'port-match'}>
            <div className={'port-match__selection-wrapper'}>
                <Paper
                    className={'paper'}
                    elevation={4}
                >
                    <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'port-match__search-actions'}>
                                    <Typography variant="h4" >
                                        <div className={'port-match__title'}>
                                            {t('PORT_RESOLUTION')}
                                        </div>
                                    </Typography>
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        onChange={e => onSearchTextChange(e, 'unresolved')}
                                        value={filters.unresolved.search}
                                    />
                                    <FormControl variant="outlined">
                                        <FormControlLabel
                                            variant="outlined"
                                            control={
                                                <Switch
                                                    checked={filters.unresolved.isPricelist?filters.unresolved.isPricelist:false}
                                                    onChange={e => onIsPricelistChange(e, 'unresolved')}
                                                    name="isPricelist"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    color="primary"
                                                />
                                            }
                                            label="isPricelist"
                                        />
                                        </FormControl>
                                </div>
                                <PortMatchTable
                                    ports={unresolvedPorts}
                                    portsCategory={'unresolvedPort'}
                                    selectedPorts={selectedPorts}
                                    handleChangePage={handleChangePage}
                                    handleDeletePort={handleDeletePort}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onPortClick}
                                />
                            </TableContainer>
                        </Grid>
                        <Grid container item xs={2} direction="column" alignItems="center" justifyContent="center">
                            <div className={'port-match__btn-action-wrapper'}>
                                <Button variant="contained"
                                    color="primary"
                                    onClick={onMatchBtnClick}
                                    size="large"
                                    disabled={!selectedPorts.resolvedPort || !selectedPorts.unresolvedPort}
                                >
                                    {t('MATCH')}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'port-match__search-actions'}>
                                    <FilterPortCountries
                                        country={filters.resolved.countrya}
                                        getSelectedPortCountry={e => onFilterPortCountryChange(e, 'resolved')}
                                    />
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        id="port-match-resolved"
                                        fullWidth
                                        inputRef={ref}
                                        onChange={e => onSearchTextChange(e, 'resolved')}
                                        value={filters.resolved.search||''}
                                    />
                                </div>
                                <PortMatchTable
                                    ports={resolvedPorts}
                                    portsCategory={'resolvedPort'}
                                    selectedPorts={selectedPorts}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onPortClick}
                                />
                            </TableContainer>

                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={
                                    `Are you sure you want to match those Ports: 
                        [${selectedPorts?.unresolvedPort?.name}]  
                         [${selectedPorts?.resolvedPort?.name}]?`
                                }
                                dialogTitle={'Port Match'}
                                handleCancel={() => setConfirmDialogOpen(false)}
                                handleOk={onPortMatchConfirm}
                                open={isConfirmDialogOpen}
                            />
                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={confirmDialogDeleteOpen.message}
                                dialogTitle={confirmDialogDeleteOpen.title}
                                handleCancel={confirmDialogDeleteOpen.onCancel}
                                handleOk={confirmDialogDeleteOpen.onConfirm}
                                open={confirmDialogDeleteOpen.isOpen}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {unresolvedPorts.portEditIndex !== -1 && unresolvedPorts.portEditObject?.id && <EditRow
                                unresolvedPorts={unresolvedPorts}
                                validator={validator}
                                onEditablePortChange={onEditablePortChange}
                                handleSavePort={handleSavePort}
                                handleCloseEditPort={handleCloseEditPort}
                                selectedPortCountry={selectedPortCountry}
                            />}
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        </div>
    );
}

export default PortMatch;
