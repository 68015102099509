import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersProduct, newProduct } from '../../helpers/productsConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllProductsInfo, editProduct, deleteProduct, addProduct } from '../../services/productsService';
import { getAuthorizationMappings } from './../../services/authorizationsService';
import { ProductTableFilter } from '../../components/productTableFilter';
import FilterProductCategories from '../../components/filterProductCategories';
import FilterCodingSystems from "../../components/filterCodingSystems";
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import './_products.scss';
import t from '../../helpers/languages';

const Products = (props) => {

  const productInitObject = () => {
    return {
      id: '?',
      name: '',
      code: '',
      category: { name: null, id: null },
      coding: { name: null, id: null },
      isUnresolved: false,
      isDiscountable: false
    }
  };

  const classes = useStyles();

  const userState = props.userState && props.userState.user;

  const filters = localStoreGetObj('productFilter');

  const [productsState, setProductsState] = useState({
    products: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    productEditIndex: -1,
    productEditObject: productInitObject(),
    productCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...productsState.filters,
    coding: filters && filters.coding && filters.coding.id,
    category: filters && filters.category && filters.category.id,
    search: filters && filters.search,
    isUnresolved: filters && filters.isUnresolved,
    isDiscountable: filters && filters.isDiscountable,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllProductsInfo(filtersData).then(res => {
      const { results } = res.data;

      setProductsState({
        ...productsState,
        products: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/products', userState.userRole)
        }
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsState({
        ...productsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setProductsState({
      ...productsState,
      isLoading: true
    });
    const newCriteria = {
      ...productsState.filters,
      per_page: productsState.page.per_page,
      page: newPage + 1
    };
    getAllProductsInfo(newCriteria).then(res => {
      setProductsState({
        ...productsState,
        isLoading: false,
        products: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setProductsState({
      ...productsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...productsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllProductsInfo(newCriteria).then(res => {
      setProductsState({
        ...productsState,
        isLoading: false,
        products: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsState({
        ...productsState,
        isLoading: false,
      });
    });
  };

  const handleEditProduct = (event, productIndex) => {

    event.stopPropagation();

    const productEditObject = Object.assign({}, productsState.products[productIndex]);


    setProductsState({
      ...productsState,
      productEditIndex: productIndex,
      productEditObject: productEditObject
    });

  };

  const onEditableProductChange = (event, property) => {
    
    let newValue = '';
    if (property === "isDiscountable" || property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditProductItem = Object.assign({}, productsState.productEditObject);
    newEditProductItem[property] = newValue;

    setProductsState({
      ...productsState,
      productEditObject: newEditProductItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveProduct = (event, props) => {
    event.preventDefault();
    setProductsState({
      ...productsState,
      isLoading: true
    });
    const newEditProductItem = Object.assign({}, productsState.productEditObject);

    if (validator.current.allValid()) {
      editProduct(newEditProductItem.id, newEditProductItem)
        .then(re => {
          const editedProduct = re.data;
          setProductsState(oldProducts => {
            return {
              ...oldProducts,
              products: oldProducts.products.map(product => {
                return product.id === editedProduct.id
                  ? re.data : product
              }),
              productEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setProductsState({
            ...productsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setProductsState({
        ...productsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditProduct = (e, index) => {
    setProductsState({
      ...productsState,
      productEditIndex: -1
    })
  };

  const handleDeleteProduct = (event, index) => {

    const productForDeletion = productsState.products[index];
    setProductsState({
      ...productsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Product',
        onCancel: () => {
          setProductsState({
            ...productsState,
            confirmDialog: {
              ...productsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteProduct(productForDeletion.id).then(() => {
            const newProducts = [...productsState.products];
            newProducts.splice(index, 1);
            setProductsState({
              ...productsState,
              products: newProducts,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewProduct = (e) => {
    e.preventDefault();

    setProductsState({
      ...productsState,
      productCreateObject: newProduct()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewProduct = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newProduct = {};
    newProduct.name = productsState.productCreateObject.name;
    newProduct.code = productsState.productCreateObject.code;
    newProduct.category = productsState.productCreateObject.category;
    newProduct.coding = productsState.productCreateObject.coding;
    newProduct.isUnresolved = productsState.productCreateObject.isUnresolved;
    newProduct.isDiscountable = productsState.productCreateObject.isDiscountable;

    if (validator.current.allValid()) {
      addProduct(newProduct)
        .then(res => {
          setProductsState({
            ...productsState,
            products: [...productsState.products, res.data],
            productCreateObject: null,
            productEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setProductsState({
            ...productsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewProductObject = (e, property) => {
   
    let newValue='';
    if (property === "isDiscountable" || property === "isUnresolved") {
      newValue = e.target.checked ? true : false;
    }
    else {
      newValue = e.target.value;
    }

    const newEditProductItem = Object.assign({}, productsState.productCreateObject);
    newEditProductItem[property] = newValue;

    setProductsState({
      ...productsState,
      productCreateObject: newEditProductItem
    });
  };

  const filterData = (data) => {
    setProductsState({
      ...productsState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...productsState.filters,
      search: data && data.search,
      coding: data && data.coding && data.coding.id,
      category: data && data.category && data.category.id,
      isUnresolved: data && data.isUnresolved,
      isDiscountable: data && data.isDiscountable,
      per_page: criteria.per_page,
      page: 1 
    };

    getAllProductsInfo(filtersData).then(res => {
      const { results } = res.data;

      setProductsState({
        ...productsState,
        products: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/products', userState.userRole)
        }
      });
      localStoreSetObj('productFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setProductsState({
        ...productsState,
        isLoading: false,
      });
    });

  };

  const selectedCodingSystem = (data, action) => {

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? productsState.productEditObject
        : productsState.productCreateObject
    );

    newEditProductItem.coding = data.codingSystem;

    setProductsState({
      ...productsState,
      [
        action === 'edit'
          ? 'productEditObject'
          : 'productCreateObject'
      ]: newEditProductItem
    });
  };


  const selectedProductCategory = (data, action) => {

    const newEditProductItem = Object.assign({},
      action === 'edit'
        ? productsState.productEditObject
        : productsState.productCreateObject
    );

    newEditProductItem.category = data.category;

    setProductsState({
      ...productsState,
      [
        action === 'edit'
          ? 'productEditObject'
          : 'productCreateObject'
      ]: newEditProductItem
    });
  };


  const tableFilterComponent = () => {
    return <ProductTableFilter
      search={(filters && filters.search) ? filters.search : productsState.filters.search}
      codingSystem={(filters && filters.coding) ? filters.coding : productsState.filters.coding}
      catalogue={(filters && filters.category) ? filters.category : productsState.filters.category}
      enabledIsUnresolved={productsState.authorizedActions.create}
      isUnresolved={(filters && filters.isUnresolved) ? filters.isUnresolved : productsState.filters.isUnresolved}
      isDiscountable ={(filters && filters.isDiscountable ) ? filters.isDiscountable  : productsState.filters.isDiscountable }
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersProduct}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(productsState.products) && productsState.products.map((product, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (productsState.productEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{product.code}</TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.category.name}</TableCell>
                    <TableCell>{product.coding.name}</TableCell>
                    <TableCell>{product.isUnresolved && <CheckIcon />}</TableCell>
                    <TableCell>{product.isDiscountable && <CheckIcon />}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={productsState.productEditObject.code || ''}
                          onChange={e => onEditableProductChange(e, 'code')}
                          onBlur={() => validator.current.showMessageFor('code')}
                        />{validator.current.message('code', productsState.productEditObject.code, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={productsState.productEditObject.name || ''}
                          onChange={e => onEditableProductChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', productsState.productEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterProductCategories
                          category={productsState.productEditObject.category || null}
                          getSelectedProductCategory={(data) => selectedProductCategory(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCodingSystems
                          codingSystem={productsState.productEditObject.coding}
                          getSelectedCodingSystem={(data) => selectedCodingSystem(data, 'edit')}
                        />{validator.current.message('coding', productsState.productEditObject.coding, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={productsState.productEditObject.isUnresolved}
                              onChange={e => onEditableProductChange(e, 'isUnresolved')}
                              name="isUnresolved"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="IsUnresolved"
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={productsState.productEditObject.isDiscountable}
                              onChange={e => onEditableProductChange(e, 'isDiscountable')}
                              name="isDiscountable"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="ΙsDiscountable"
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: productsState.authorizedActions.update&&productsState.productEditIndex !== index,
                    callback: (e) => handleEditProduct(e, index)
                  },
                  delete: {
                    enabled: productsState.productEditIndex !== index,
                    callback: (e) => handleDeleteProduct(e, index)
                  },
                  close: {
                    enabled: productsState.productEditIndex === index,
                    callback: (e) => handleCloseEditProduct(e, index)
                  },
                  save: {
                    enabled: productsState.productEditIndex === index,
                    callback: (e) => handleSaveProduct(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          productsState.productCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={productsState.productCreateObject.code || ''}
                  onChange={e => onEditNewProductObject(e, 'code')}
                  onBlur={() => validator.current.showMessageFor('code')}
                />{validator.current.message('code', productsState.productCreateObject.code, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={productsState.productCreateObject.name || ''}
                  onChange={e => onEditNewProductObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', productsState.productCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterProductCategories
                  category={productsState.productCreateObject.category || null}
                  getSelectedProductCategory={(data) => selectedProductCategory(data, 'create')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCodingSystems
                  codingSystem={productsState.productCreateObject.coding}
                  getSelectedCodingSystem={(data) => selectedCodingSystem(data, 'create')}
                />{validator.current.message('coding', productsState.productCreateObject.coding&&productsState.productCreateObject.coding.id, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={productsState.productCreateObject.isUnresolved}
                        onChange={e => onEditNewProductObject(e, 'isUnresolved')}
                        name="isUnresolved"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="ΙsUnresolved"
                  />
                </FormControl>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={productsState.productCreateObject.isDiscountable}
                        onChange={e => onEditNewProductObject(e, 'isDiscountable')}
                        name="isDiscountable"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="ΙsDiscountable"
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setProductsState({ ...productsState, productCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewProduct()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PRODUCTS')}
        pagination={{
          total: productsState.page.total || 0,
          page: productsState.page.page - 1 || 0,
          perPage: productsState.page.per_page || 10,
          count: productsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={productsState.authorizedActions.create}
        onNewRowClick={handleAddNewProduct}
        newRowBtnEnabled={!productsState.productCreateObject}
        confirmDialog={productsState.confirmDialog.message.length > 0 ? productsState.confirmDialog : productsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={productsState.isLoading}
      />
    </>
  );
};


export default Products;
