

const tableHeadersCompanyDictionary = [
  { id: 'nameAlias', disablePadding: false, label: 'Name Alias' },
  { id: 'codeAlias', disablePadding: false, label: 'Code Alias' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

// Empty Company dictionary
const newCompanyDictionary  = () => {
  return {
    id: '?',
    company: null,
    nameAlias: null,
    codeAlias: null,
    original: null
  }
};

export { tableHeadersCompanyDictionary, newCompanyDictionary };
