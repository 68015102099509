import useStyles from "./styles";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ProcureDataTable = (props) => {
    const classes = useStyles();

    let rootClass = null;

    if (props.autonomousPage && !props.dashboard && props.noWidth === undefined) {
        rootClass = classes.root;

    }
    else if (props.dashboard !== undefined) {
        rootClass = classes.rootnopaddingtop;
    }
    else if (props.noWidth !== undefined) {
        rootClass = classes.rootnowidth;
    }
    else {
        rootClass = classes.rootsec;
    }

    return (
        <div className={classes.procureDataTableWrapper}>
            {props.dashboard !== undefined && props.dashboard()}
            <div className={rootClass}>
                {props.breadcrump !== undefined && props.breadcrump()}
                <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    direction="row"
                    spacing={1}>
                    <Paper
                        className={classes.paper}
                        elevation={props.autonomousPage ? 4 : 0}
                    >
                        {props.infoTab !== undefined && (<>
                            <Grid container
                                alignItems="center">
                                <Grid item xs={props.tablePrint !== undefined ? 7 : 9}>
                                    <Typography variant="h4" gutterBottom>
                                        {props.infoTabTitle}
                                    </Typography>
                                </Grid>
                                {props.tablePrint !== undefined && (
                                    <Grid item xs={5} className={classes.alignRight}>
                                        {props.uploadAction !== undefined && props.uploadAction && props.tableUpload()}
                                        {(props.newRowActionAvailable || props.importAction) && props.tableImport !== undefined && props.tableImport()}
                                        {props.tableExport !== undefined && props.tableExport()}
                                        {props.tablePrint()}
                                    </Grid>)}
                                    {props.tablePrint === undefined && props.uploadAction !== undefined && props.uploadAction && props.uploadAction !== undefined &&(
                                    <Grid item xs={3} className={classes.alignRight}>
                                        { props.tableUpload()}
                                    </Grid>)}
                                {/* {props.tableFilter !== undefined && (
                                    <Grid item xs={3}>
                                        {props.tableFilter()}
                                    </Grid>)} */}

                            </Grid>
                            <div className={classes.infoMargin}>
                                <Grid item xs={12}>
                                    {props.infoTab()}
                                </Grid>
                            </div>
                        </>)}
                        {props.attachments !== undefined && (<div className={classes.attachmentsTitle}>
                            <Grid item xs={12}>
                                {props.attachments()}
                            </Grid>
                        </div>)}
                        {props.tableFilter !== undefined && props.autonomousPage && (<Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                {props.toolbarTitle}
                            </Typography>
                        </Grid>)}
                        {props.infoTab === undefined && props.tableFilter !== undefined && (<Grid item xs={12}>
                                    <div className={classes.itemsTitle}>  {props.tableFilter()}</div>
                                </Grid>)}
                        <TableContainer>
                            <Grid container>
                                <Grid item xs={9}>
                                    <div className={classes.itemsTitle}>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {props.itemsTableTitle}
                                        </Typography> </div>
                                </Grid>
                                {props.infoTab !== undefined && props.tableFilter !== undefined && (<Grid item xs={3}>
                                    <div className={classes.itemsTitle}>  {props.tableFilter()}</div>
                                </Grid>)}
                            </Grid>
                            <Table className={classes.table} size="small" >
                                {props.tableHeader && props.tableHeader()}
                                {props.tableBody && props.tableBody()}
                            </Table>
                        </TableContainer>

                        <Grid item xs={12}>
                            <TablePagination
                                component='div'
                                disabled={props.pagination?.disabled}
                                SelectProps={{
                                    disabled: props.pagination?.disabled
                                }}
                                backIconButtonProps={
                                    props.pagination?.disabled
                                        ? {
                                            disabled: props.pagination?.disabled
                                        }
                                        : undefined
                                }
                                nextIconButtonProps={
                                    props.pagination?.disabled
                                        ? {
                                            disabled: props.pagination?.disabled
                                        }
                                        : undefined
                                }
                                count={props.pagination.total}
                                onPageChange={props.pagination.handleChangePage}
                                onRowsPerPageChange={props.pagination.handleChangeRowsPerPage}
                                page={props.pagination.page}
                                rowsPerPage={props.pagination.perPage}
                                rowsPerPageOptions={[5, 10, 20, 50]}
                            />

                            {
                                props.newRowActionAvailable &&
                                <Tooltip
                                    className={classes.rowActionBtn + " " + "add-table-row"}
                                    title={props.addNewRawBtnTitle}
                                    onClick={(e) => props.onNewRowClick(e)}
                                    disabled={!props.newRowBtnEnabled}
                                >
                                    <Fab

                                        color='primary'
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            }
                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={props.confirmDialog.message}
                                dialogTitle={props.confirmDialog.title}
                                handleCancel={props.confirmDialog.onCancel}
                                handleOk={props.confirmDialog.onConfirm}
                                open={props.confirmDialog.isOpen}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        </div>
    );
};

export { ProcureDataTable };