import React, { Fragment, useState } from 'react';
import 'date-fns';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import t from '../../helpers/languages';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Grid } from "@mui/material";
import dateToString from '../../helpers/dateToString';
import moment from 'moment';
import './_pricelistTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),

  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const PricelistTableFilter = ({ searchDate: initialSearchDate,  getFilterData }) => {
  const classes = useStyles();

  const [searchDate, setSearchDate] = useState(initialSearchDate ? moment.utc(initialSearchDate) : null);

  const onDateFromChange = (date) => {
    setSearchDate(date);

    if (moment(date).isValid() && !isNaN(date)) {
      const filterData = {
        searchDate: dateToString(date) + 'T00:00:00Z',
      
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        searchDate: date,
      };

      getFilterData(filterData);
    }


  };


  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={1}>
                <Fragment>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-from"
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      label={t('DATE')}
                      value={searchDate}
                      inputFormat="yyyy-MM-DD"
                      inputVariant="outlined"
                      onChange={onDateFromChange}
                      initialFocusedDate={searchDate}
                      clearable
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Fragment>
              </Grid>
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}