import React, { useCallback } from 'react';
import t from "../../helpers/languages";
import { Autocomplete } from '@mui/material';
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import { getAllTransactionsInfo } from "../../services/transactionsService";

export default function FilterTransactions({ transaction: initialTransaction, getSelectedTransaction }) {

  const [transaction, setTransaction] = React.useState(initialTransaction && initialTransaction.id !== null ? initialTransaction : 0);
  const [transactions, setTransactions] = React.useState( initialTransaction && initialTransaction.id !== null ? [initialTransaction] : []);
  const [search, setSearch] = React.useState("");
  const [openTransaction, setOpenTransaction] = React.useState(false);


  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "transaction" && value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    }
  };

  const handleDebounceFn = (val) => {
    transactionSearch({
      search: val,
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onTransactionSelectionChange = (event, values) => {
    setTransaction(values ? values : '');
    const selectedTransaction = {
      transaction: values ? values : null, // return null when the value is empty string
    };
    getSelectedTransaction(selectedTransaction);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const transactionSearch = (criteria) => {
    return getAllTransactionsInfo(criteria)
      .then((res) => {
        const results=res.data;
        setTransactions(results);
      })
      .catch((error) => { });
  };

  
  
  return (
    <Autocomplete
      id={"find-transaction" + randomNumber(0, 999)}
      fullWidth
      open={openTransaction}
      onOpen={() => { setOpenTransaction(true); }}
      onClose={() => { setOpenTransaction(false); }}
      onInputChange={(e) => handleInputChange(e, "transaction")}
      onChange={onTransactionSelectionChange}
      value={transactions.find((v) => v.id === transaction.id)|| null }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option.date!== null?option.vessel?.name + ' '+ option.port?.name + ' ' +option.category?.name + ' ' + option.date?.slice(0, 10): '')}
      options={transactions!== null ? transactions : null}
      filterOptions={(transactions) => transactions!== null ? transactions : null}
      // renderOption={(option) => (
      //   <React.Fragment>
      //     {option.vessel?.name + ' '+ option.port?.name + ' ' +option.category?.name + ' ' + option?.date.slice(0, 10)} 
      //   </React.Fragment>
      // )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("TRANSACTION")}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
