import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersCountryDictionary,tableHeadersCountryDictionaryGlobal, newCountryDictionary } from '../../helpers/countriesDictionaryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterCountries from "../../components/filterCountries";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import {
  getAllCountryDictionariesInfo,
  editCountryDictionary,
  deleteCountryDictionary,
  addCountryDictionary
} from '../../services/countriesDictionaryService';
import FilterCompanies from "../../components/filterCompanies";
import { DictionaryTableFilter } from '../../components/dictionaryTableFilter';
import './_CountryDictionaries.scss';
import t from '../../helpers/languages';

const CountryDictionaries = (props) => {

  const countryDictionaryInitObject = () => {
    return {
      id: '?',
      company: { name: '', id: '' },
      altCode: null,
      countryAlias: null,
      original: null
    }
  };

  const classes = useStyles();

  const filters = localStoreGetObj('countryDictionaryFilter');

  const [countriesDictionaryState, setcountryDictionariesState] = useState({
    countriesDictionary: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    countryDictionaryEditIndex: -1,
    countryDictionaryEditObject: countryDictionaryInitObject(),
    countryDictionaryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};


  if (props.autonomousPage) {
    filtersData = {
      ...countriesDictionaryState.filters,
      companyId: filters && filters.company && filters.company.id,
      search: filters && filters.country,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: countriesDictionaryState.page.order_type,
      order_by: countriesDictionaryState.page.order_by,
    };
  }
  else {
    filtersData = {
      ...countriesDictionaryState.filters,
      companyId: props.companyId,
      search: filters && filters.country,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: countriesDictionaryState.page.order_type,
      order_by: countriesDictionaryState.page.order_by,
    };
  }

  useEffect(function () {
    getAllCountryDictionariesInfo(filtersData).then(res => {
      
      const { results } = res.data;
      setcountryDictionariesState({
        ...countriesDictionaryState,
        countriesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === countriesDictionaryState.page.order_by && countriesDictionaryState.page.order_type === 'desc' ? 'asc' : 'desc';

    setcountryDictionariesState({
      ...countriesDictionaryState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllCountryDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setcountryDictionariesState({
        ...countriesDictionaryState,
        countriesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setcountryDictionariesState({
      ...countriesDictionaryState,
      isLoading: true
    });
    const newCriteria = {
      ...countriesDictionaryState.filters,
      per_page: countriesDictionaryState.page.per_page,
      page: newPage + 1
    };
    getAllCountryDictionariesInfo(newCriteria).then(res => {
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
        countriesDictionary: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to country id gia mba member
    setcountryDictionariesState({
      ...countriesDictionaryState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...countriesDictionaryState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllCountryDictionariesInfo(newCriteria).then(res => {
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
        countriesDictionary: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
      });
    });
  };

  const handleEditCountryDictionary = (event, countryDictionaryIndex) => {

    event.stopPropagation();

    const countryDictionaryEditObject = Object.assign({}, countriesDictionaryState.countriesDictionary[countryDictionaryIndex]);


    setcountryDictionariesState({
      ...countriesDictionaryState,
      countryDictionaryEditIndex: countryDictionaryIndex,
      countryDictionaryEditObject: countryDictionaryEditObject
    });

  };

  const onEditableCountryDictionaryChange = (event, property) => {

    const newValue = event.target.value;

    const newEditCountryDictionaryItem = Object.assign({}, countriesDictionaryState.countryDictionaryEditObject);
    newEditCountryDictionaryItem[property] = newValue;

    setcountryDictionariesState({
      ...countriesDictionaryState,
      countryDictionaryEditObject: newEditCountryDictionaryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveCountryDictionary = (event, props) => {
    event.preventDefault();
    setcountryDictionariesState({
      ...countriesDictionaryState,
      isLoading: true
    });
  
    const simpleCompanyUser = {
      id: props.companyId,
    }

    let createNull = {
      id: null,
    };
    if (countriesDictionaryState.countryDictionaryEditObject.company?.id !== ''&&countriesDictionaryState.countryDictionaryEditObject.company!=='') {
      createNull = countriesDictionaryState.countryDictionaryEditObject.company;
    }

    const newEditCountryDictionaryItem = Object.assign({}, countriesDictionaryState.countryDictionaryEditObject);
    
    props.autonomousPage ? newEditCountryDictionaryItem.company = createNull : newEditCountryDictionaryItem.company = simpleCompanyUser;
    if (validator.current.allValid()) {
      editCountryDictionary(newEditCountryDictionaryItem.id, newEditCountryDictionaryItem)
        .then(re => {
          const editedCountryDictionary = re.data;
          setcountryDictionariesState(oldcountryDictionaries => {
            return {
              ...oldcountryDictionaries,
              countriesDictionary: oldcountryDictionaries.countriesDictionary.map(countryDictionary => {
                return countryDictionary.id === editedCountryDictionary.id
                  ? re.data : countryDictionary
              }),
              countryDictionaryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setcountryDictionariesState({
            ...countriesDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditCountryDictionary = (e, index) => {
    setcountryDictionariesState({
      ...countriesDictionaryState,
      countryDictionaryEditIndex: -1
    })
  };

  const handleDeleteCountryDictionary = (event, index) => {

    const countryDictionaryForDeletion = countriesDictionaryState.countriesDictionary[index];
    setcountryDictionariesState({
      ...countriesDictionaryState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete CountryDictionary',
        onCancel: () => {
          setcountryDictionariesState({
            ...countriesDictionaryState,
            confirmDialog: {
              ...countriesDictionaryState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteCountryDictionary(countryDictionaryForDeletion.id).then(() => {
            const newcountryDictionaries = [...countriesDictionaryState.countriesDictionary];
            newcountryDictionaries.splice(index, 1);
            setcountryDictionariesState({
              ...countriesDictionaryState,
              countriesDictionary: newcountryDictionaries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewCountryDictionary = (e) => {
    e.preventDefault();

    const newCountryDict = newCountryDictionary();

    //newCountryDict.company={id:props.companyId};

    setcountryDictionariesState({
      ...countriesDictionaryState,
      countryDictionaryCreateObject: newCountryDict
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewCountryDictionary = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newCountryDictionary = {};

    const simpleCompanyUser = {
      id: props.companyId,
    }

    let createNull = null;
    if (countriesDictionaryState.countryDictionaryCreateObject.company|| !countriesDictionaryState.countryDictionaryCreateObject.company.length === 0) {
      createNull = countriesDictionaryState.countryDictionaryCreateObject.company;
    }

    props.autonomousPage ? newCountryDictionary.company = createNull : newCountryDictionary.company = simpleCompanyUser;
    newCountryDictionary.company = countriesDictionaryState.countryDictionaryCreateObject.company;
    newCountryDictionary.countryAlias = countriesDictionaryState.countryDictionaryCreateObject.countryAlias;
    newCountryDictionary.original = countriesDictionaryState.countryDictionaryCreateObject.original;

    if (validator.current.allValid()) {
      addCountryDictionary(newCountryDictionary)
        .then(res => {
          setcountryDictionariesState({
            ...countriesDictionaryState,
            countriesDictionary: [...countriesDictionaryState.countriesDictionary, res.data],
            countryDictionaryCreateObject: null,
            countryDictionaryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setcountryDictionariesState({
            ...countriesDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewCountryDictionaryObject = (e, property) => {
    const newValue = e.target.value;

    const newEditCountryDictionaryItem = Object.assign({}, countriesDictionaryState.countryDictionaryCreateObject);
    newEditCountryDictionaryItem[property] = newValue;

    setcountryDictionariesState({
      ...countriesDictionaryState,
      countryDictionaryCreateObject: newEditCountryDictionaryItem
    });
  };

  const filterData = (data) => {
    setcountryDictionariesState({
      ...countriesDictionaryState,
      isLoading: true
    });

    let filtersData = '';
    if (props.autonomousPage) {
      filtersData = {
        ...countriesDictionaryState.filters,
        search: data && data.searchText,
        companyId: data && data.company && data.company.id,
        per_page: criteria.per_page,
        page: 1,
      };
    } else {
      filtersData = {
        ...countriesDictionaryState.filters,
        search: data && data.searchText,
        companyId: props.companyId,
        per_page: criteria.per_page,
        page: 1,
      };
    }

    getAllCountryDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;

      setcountryDictionariesState({
        ...countriesDictionaryState,
        countriesDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('countryDictionaryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setcountryDictionariesState({
        ...countriesDictionaryState,
        isLoading: false,
      });
    });

  };


  const selectedOriginal = (data, action) => {

    const newEditCountryDictionaryItem = Object.assign({},
      action === 'edit'
        ? countriesDictionaryState.countryDictionaryEditObject
        : countriesDictionaryState.countryDictionaryCreateObject
    );

    newEditCountryDictionaryItem.original = data.country;


    setcountryDictionariesState({
      ...countriesDictionaryState,
      [
        action === 'edit'
          ? 'countryDictionaryEditObject'
          : 'countryDictionaryCreateObject'
      ]: newEditCountryDictionaryItem
    });
  };

  const selectedCompany = (data, action) => {

    const newEditCountryDictionaryItem = Object.assign({},
      action === 'edit'
        ? countriesDictionaryState.countryDictionaryEditObject
        : countriesDictionaryState.countryDictionaryCreateObject
    );


    newEditCountryDictionaryItem.company = data.company;


    setcountryDictionariesState({
      ...countriesDictionaryState,
      [
        action === 'edit'
          ? 'countryDictionaryEditObject'
          : 'countryDictionaryCreateObject'
      ]: newEditCountryDictionaryItem
    });
  };


  const tableFilterComponent = () => {
    return <DictionaryTableFilter
      searchText={(filters && filters.country) ? filters.country: countriesDictionaryState.filters.country}
      company={(filters && filters.company) ? filters.company : countriesDictionaryState.filters.company}
      autonomousPage={props.autonomousPage}
      getFilterData={filterData}
      filterLabel={'Country'}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.autonomousPage ? tableHeadersCountryDictionaryGlobal : tableHeadersCountryDictionary}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(countriesDictionaryState.countriesDictionary) && countriesDictionaryState.countriesDictionary.map((countryDictionary, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (countriesDictionaryState.countryDictionaryEditIndex !== index) && index !== '?'
                  ? <>
                    {props.autonomousPage && <TableCell>{countryDictionary.company?.name}</TableCell>}
                    <TableCell>{countryDictionary.countryAlias}</TableCell>
                    <TableCell>{countryDictionary.original?.name}</TableCell>
                  </> :
                  <>
                  {props.autonomousPage && (
                      <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={countriesDictionaryState.countryDictionaryEditObject.company || null}
                            getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                            buyer={true}
                            errorMessage={null}
                          />
                        </FormControl>
                      </TableCell>
                    )
                    }
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={countriesDictionaryState.countryDictionaryEditObject.countryAlias || ''}
                          onChange={e => onEditableCountryDictionaryChange(e, 'countryAlias')}
                          onBlur={() => validator.current.showMessageFor('countryAlias')}
                        />{validator.current.message('countryAlias', countriesDictionaryState.countryDictionaryEditObject.countryAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterCountries
                          country={countriesDictionaryState.countryDictionaryEditObject.original || null}
                          getSelectedCountry={(data) => selectedOriginal(data, 'edit')}
                          errorMessage={null}
                        />
                        {validator.current.message('original', countriesDictionaryState.countryDictionaryEditObject.original, 'required')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: countriesDictionaryState.countryDictionaryEditIndex !== index,
                    callback: (e) => handleEditCountryDictionary(e, index)
                  },
                  delete: {
                    enabled: countriesDictionaryState.countryDictionaryEditIndex !== index,
                    callback: (e) => handleDeleteCountryDictionary(e, index)
                  },
                  close: {
                    enabled: countriesDictionaryState.countryDictionaryEditIndex === index,
                    callback: (e) => handleCloseEditCountryDictionary(e, index)
                  },
                  save: {
                    enabled: countriesDictionaryState.countryDictionaryEditIndex === index,
                    callback: (e) => handleSaveCountryDictionary(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          countriesDictionaryState.countryDictionaryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
          {props.autonomousPage && (
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={countriesDictionaryState.countryDictionaryCreateObject.company || null}
                    getSelectedCompany={(data) => selectedCompany(data, 'create')}
                    buyer={true}
                    errorMessage={null}
                  />
                </FormControl>
              </TableCell>
            )}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={countriesDictionaryState.countryDictionaryCreateObject.countryAlias || ''}
                  onChange={e => onEditNewCountryDictionaryObject(e, 'countryAlias')}
                  onBlur={() => validator.current.showMessageFor('countryAlias')}
                />{validator.current.message('countryAlias', countriesDictionaryState.countryDictionaryCreateObject.countryAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterCountries
                  country={countriesDictionaryState.countryDictionaryCreateObject.original}
                  getSelectedCountry={(data) => selectedOriginal(data, 'create')}
                  errorMessage={null}
                />
                {validator.current.message('original', countriesDictionaryState.countryDictionaryCreateObject.original, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setcountryDictionariesState({ ...countriesDictionaryState, countryDictionaryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewCountryDictionary()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('COUNTRY_DICTIONARIES')}
        pagination={{
          total: countriesDictionaryState.page.total || 0,
          page: countriesDictionaryState.page.page - 1 || 0,
          perPage: countriesDictionaryState.page.per_page || 10,
          count: countriesDictionaryState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewCountryDictionary}
        newRowBtnEnabled={!countriesDictionaryState.countryDictionaryCreateObject}
        confirmDialog={countriesDictionaryState.confirmDialog.message.length > 0 ? countriesDictionaryState.confirmDialog : countriesDictionaryState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={countriesDictionaryState.isLoading}
      />
    </>
  );
};


export default CountryDictionaries;