import httpService from './httpService';

const getDeliveryItems = (criteria = {}) => {
  return httpService.FetchDeliveryItemList(criteria);
};

const add = (data) => {
  return httpService.CreateDeliveryItem({}, data);
};

const edit = (id, data) => {
  return httpService.UpdateDeliveryItem({}, data);
};

//DeleteDeliveryItem

const deleteDeliveryItem = (id, data) => {
  return httpService.DeleteDeliveryItem({}, data);
};

export { getDeliveryItems, add, edit, deleteDeliveryItem };
