import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeaderInfo from '../../components/tableHeaderInfo';
import TableRow from '@mui/material/TableRow';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/rfqsConfig';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import {StatusIndicator} from "../../common/StatusIndicator/StatusIndicator";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    blueHover: {
        padding: '0px',
        verticalAlign: 'left',
        justifyContent:  'left',
        alignItems: 'left',
        textAlign: 'left',
        position: 'relative',
        display: 'flex',
        color:'inherit',
        flexGrow: 1,
        width: 'auto',
        "&:hover": {
          color: '#036df4'
        }
      }
});

const RfqInfoTable = (props) => {

    const classes = useStyles();

    return <>
        <Typography variant="h5" gutterBottom>
            {props.toolbarTitle}
        </Typography>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="simple-table">
                <TableHeaderInfo headCells={props.role === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer} />
                    <TableBody>
                        {props.rfqs.map((rfq) => (
                            <TableRow key={rfq.id}>
                                <TableCell>
                                    <IconButton
                                        className='rfqs-quotes-orders-details'
                                        aria-label="expand row"
                                        style={{ padding: '0px' }}
                                        component={RouterLink}
                                        to={{ pathname: `/transactions/${props.transactionId}/rfqs/${rfq.id}` }}
                                    >
                                        <ManageSearchIcon></ManageSearchIcon>
                                    </IconButton>
                                </TableCell>
                             <TableCell className='rfqs-quotes-orders-details'>{rfq.reference_number?rfq.reference_number:'N/A'}</TableCell>
                            <TableCell>{(rfq.buyer && rfq.supplier) ? (props.role === 'SUPPLIER' ? rfq.buyer.name : rfq.supplier.name) : ''}</TableCell>
                            <TableCell>{rfq.vessel && rfq.vessel.name}</TableCell>
                            <TableCell>{rfq.category && rfq.category.name}</TableCell>
                            <TableCell>{rfq.items}</TableCell>
                            <TableCell>{rfq.submitted&&rfq.submitted.slice(0, 10)}</TableCell>
                            <TableCell>{rfq.due && rfq.due.slice(0, 10)}</TableCell>
                            <TableCell>{rfq.port && rfq.port.name}</TableCell>
                            <TableCell>
                                <StatusIndicator status={rfq.status}>
                                    {rfq.status} 
                                </StatusIndicator>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default RfqInfoTable;