import React from "react";
import Typography from '@mui/material/Typography';
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import useStyles from './style';
export default function ModeCommentIconWithNumber({ size = 14, count = 0 }) {
  const classes = useStyles();

  return (
    <div className={classes.root} 
    style={{ fontSize: size }}
    >
      <ModeCommentIcon  color="primary" className={classes.icon} />
      <Typography component="span" className={classes.count}>
        {count}
      </Typography>
    </div>
  );
}