const localStorageVersionHandler = () => {
    // Every time we have breaking changes to our application, we should increase
    // the MAJOR version by one.
    // Example:  1.2.4 ---> 2.0.0
    // ##########################################################################
    // For Minor changes, the localstorage state will not be affected
    // Example: 1.2.4 ---> 1.3.4 (# Same for bugFixes)

    const defaultVersion = '1.0.0';
    const localStorageApplicationVersion = localStorage.getItem('version') || defaultVersion;
    const applicationVersion = process.env.REACT_APP_VERSION || defaultVersion;

    const MajorVersionLocalStorage = Number(localStorageApplicationVersion.split('.')[0]);
    const MajorVersionApplication = Number(applicationVersion.split('.')[0]);

    if(MajorVersionLocalStorage < MajorVersionApplication) {
        localStorage.clear();
        localStorage.setItem('version', applicationVersion)
    }
}

export { localStorageVersionHandler }