import { useEffect, useState } from "react";
import { urlParams } from "../../helpers/urlParams";
import { getCompanyById, editCompany, importBuyerInvoices, importSupplierPriceLists,importSupplierInvoices } from "../../services/companiesService";
import { importProductsFile } from "../../services/productsService";
import { importEquivalentProductsFile } from "../../services/equivalentsProductService";
import { importUnitsFile } from "../../services/unitsService";
import dateToString from '../../helpers/dateToString';
import moment from 'moment';

const useCompanyHook = () => {

    const companyInitObject = () => {
        return {
            id: "?",
            name: "",
            admin: "",
            supplier: true,
            codingSystem: null,
            address: "",
            country: "",
            enabled: "",
            integrated:"",
            notes: "",
            port: "",
            email: null,
            category: "",
        };
    };

    const [companiesData, setCompanies] = useState({
        companies: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        filters: {
        },
        isLoading: true,
        companyEditObject: companyInitObject(),
        companyCreateObject: null,
        authorizedActions: {
            read: false,
            update: false,
            create: false,
            delete: false
        },
        error: null,
        isNameSaved: true,
        isAddressSaved: true,
        isPortSaved: true,
        isCountrySaved: true,
        isSupplierSaved: true,
        isIntegratedSaved: true,
        isEmailSaved: true,
        isCompanyCategorySaved: true,
        isCodingSystemSaved: true,
        //todo make it global with custom events
        confirmDialog: {
            message: '',
            title: '',
            onCancel: () => { },
            onConfirm: () => { },
            isLoading: false,
            isOpen: false
        }
    })

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const criteria = urlParams.getUrlParams();
    !criteria.per_page && (criteria.per_page = 10);
    !criteria.page && (criteria.page = 1);
    //urlParams.setUrlParams(criteria);

    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;

    m = regex.exec(pathName);

    const companyId = m[0];

    const filtersData = {
        id: companyId
    };

    useEffect(() => {
        setCompanies({
            ...companiesData,
            isLoading: true
        });
        getCompanyById(filtersData).then(res => {
            const results = res.data;

            setCompanies({
                ...companiesData,
                companyEditObject: results,
                page: res.data.page,
                filters: res.data.filters,
                isLoading: false
            });
        }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' ' + error,
            // }));
            setCompanies({
                ...companiesData,
                isLoading: false,
            });
        });

        // eslint-disable-next-line  
    }, []);


    const getSelectedCountry = (data) => {

        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);
        if (data.country !== undefined && data.country.id !== 1000000) {
            const dp = {
                id: data.country.id,
                name: data.country.name
            }
            newEditCompanyEditObject.country = dp;
        } else {
            const dp = {
                id: null,
                name: null
            }
            newEditCompanyEditObject.country = dp;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isCountrySaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isCountrySaved: false,
                    isLoading: false,
                });
            });
    };

    const getSelectedCompanyCategory = (data, action) => {

        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);
      
        if (data.companyCategory !== undefined && data.companyCategory.id !== 1000000) {
            const dp = {
                id: data.companyCategory.id,
                name: data.companyCategory.name
            }
            newEditCompanyEditObject.category = dp;
        } else {
            const dp = {
                id: null,
                name: null
            }
            newEditCompanyEditObject.category = dp;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isCompanyCategorySaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isCompanyCategorySaved: false,
                    isLoading: false,
                });
            });
    };

    const getSelectedCodingSystem = (data, action) => {
  
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);
      
        if (data.codingSystem !== undefined && data.codingSystem.id !== 1000000) {
            const dp = {
                id: data.codingSystem.id,
                name: data.codingSystem.name
            }
            newEditCompanyEditObject.codingSystem = dp;
        } else {
            const dp = {
                id: null,
                name: null
            }
            newEditCompanyEditObject.codingSystem = dp;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isCodingSystemSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isCodingSystemSaved: false,
                    isLoading: false,
                });
            });
        
    };


    const getCompanyName = (data) => {
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditCompanyEditObject.name = data.notes;
        } else {
            newEditCompanyEditObject.name = null;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isSupplierSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isSupplierSaved: false,
                    isLoading: false,
                });
            });
    };

    const getCompanyAddress = (data) => {
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditCompanyEditObject.address = data.notes;
        } else {
            newEditCompanyEditObject.address = null;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isAddressSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isAddressSaved: false,
                    isLoading: false,
                });
            });
    };

    const getCompanyEmail = (data) => {
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditCompanyEditObject.email = data.notes;
        } else {
            newEditCompanyEditObject.email = null;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isEmailSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isEmailSaved: false,
                    isLoading: false,
                });
            });
    };

    const getSupplierChecked = (data) => {
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);

        newEditCompanyEditObject.supplier = data.check;
        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isSupplierSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isSupplierSaved: false,
                    isLoading: false,
                });
            });
    };

    const getIntegratedChecked = (data) => {
        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);

        newEditCompanyEditObject.integrated = data.check;
        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isIntegratedSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isIntegratedSaved: false,
                    isLoading: false,
                });
            });
    };

    const getSelectedPort = (data) => {

        const newEditCompanyEditObject = Object.assign({}, companiesData.companyEditObject);
        if (data.port !== null && data.port.id !== 1000000) {
            const dp = {
                id: data.port.id,
                name: data.port.name
            }
            newEditCompanyEditObject.port = dp;
        } else {
            newEditCompanyEditObject.port = null;
        }

        editCompany(newEditCompanyEditObject.id, newEditCompanyEditObject)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    companyEditObject: re.data,
                    isPortSaved: true,
                    isLoading: false,
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isPortSaved: false,
                    isLoading: false,
                });
            });
    };


    const getUploadHistoricalData = (data) => {

        setCompanies({
            ...companiesData,
            isLoading: true
        });
        
        const path=companyId;

        if(companiesData.companyEditObject.supplier){
            importSupplierInvoices(path, data)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    isLoading: false
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isLoading: false
                });
            });
        }
        else {
            importBuyerInvoices(path, data)
            .then(re => {
                setCompanies({
                    ...companiesData,
                    isLoading: false
                });
            }).catch((error) => {
                setCompanies({
                    ...companiesData,
                    isLoading: false
                });
            });
        }

    };

	const getUploadPriceLists = (data) => {

		setCompanies({
			...companiesData,
			isLoading: true
		});

       const dFrom= dateToString(from) + 'T00:00:00Z';
       const dTo= dateToString(to) + 'T00:00:00Z';


       setFrom(null);
       setTo(null);
	   importSupplierPriceLists(dFrom,dTo, companyId, data)
		.then(re => {
			setCompanies({
				...companiesData,
				isLoading: false
			});
		}).catch((error) => {
			setCompanies({
				...companiesData,
				isLoading: false
			});
		});
	};


    const getUploadProductsFile = (data) => {

		setCompanies({
			...companiesData,
			isLoading: true
		});
		importProductsFile(data)
			.then(re => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			}).catch((error) => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			});
	};

    const getUploadUnitsFile = (data) => {

		setCompanies({
			...companiesData,
			isLoading: true
		});
		importUnitsFile(data)
			.then(re => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			}).catch((error) => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			});
	};

    const getUploadEquivalentProductsFile = (data) => {

		setCompanies({
			...companiesData,
			isLoading: true
		});
		importEquivalentProductsFile(data)
			.then(re => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			}).catch((error) => {
				setCompanies({
					...companiesData,
					isLoading: false
				});
			});
	};



    const getOnDateFromChange = (date) => {
        setFrom(date);

    };

    const getOnDateToChange = (date) => {
        setTo(date);
    };

    return {
        companiesData,
        from,
        to,
        getCompanyEmail,
        getSelectedCodingSystem,
        getSelectedCountry,
        getCompanyName,
        getCompanyAddress,
        getSelectedPort,
        getSelectedCompanyCategory,
        getSupplierChecked,
        getIntegratedChecked,
        getUploadHistoricalData,
		getUploadPriceLists,
        getUploadProductsFile,
        getUploadUnitsFile,
        getUploadEquivalentProductsFile,
        getOnDateFromChange,
        getOnDateToChange
    }
}

export default useCompanyHook;