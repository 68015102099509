
const tableHeadersCountry = [
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'code', disablePadding: false, label: 'Code' },
  { id: 'unresolved', disablePadding: false, label: 'Unresolved' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty Country
const newCountry = () => {
  return {
    id: '?',
    name: null,
    code: null,
    isUnresolved: false,
  }
};

export { tableHeadersCountry, newCountry };