import httpService from './httpService';

const getBuyerPreferences = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchBuyerPrefs(criteria);

};
const deleteBuyerPreference = (id) => {
  return httpService.DeleteBuyerPrefs(id);
};

const edit = (id, data) => {
   return httpService.UpdateBuyerPrefs({}, data);
};

const add = (data) => {
  return httpService.CreateBuyerPrefs({},data);
};

export { getBuyerPreferences, edit, add, deleteBuyerPreference };
