import { useCallback, useEffect, useState, useRef } from "react";
import { getAllProductsInfo, matchProducts, editProduct, deleteProduct } from "../../services/productsService";
import SimpleReactValidator from 'simple-react-validator';
import { debounce } from "lodash";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';

const useProductMatchHook = () => {

    const productInitObject = () => {
        return {
            id: '?',
            name: '',
            code: '',
            category: { name: null, id: null },
            coding: { name: null, id: null },
            isUnresolved: false,
        }
    };

    const [unresolvedProducts, setUnresolvedProducts] = useState({
        products: [],
        isLoading: true,
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        productEditIndex: -1,
        productEditObject: productInitObject(),
        error: null,
    })

    const [resolvedProducts, setResolvedProducts] = useState(
        {
            products: [],
            page: {
                page: 1,
                count: 0,
                per_page: 10,
                total: 0,
                order_type: 'asc',
                order_by: ''
            },
            isLoading: true,
            error: null,
        }
    )
    const filtersMatch = localStoreGetObj('productMatchFilter');
    const ref = useRef(null);
    const [filters, setFilters] = useState({
        unresolved: {
            category: null,
            coding: filtersMatch?.unresolved && filtersMatch.unresolved.codinga && filtersMatch.unresolved.codinga.id,
            codinga: filtersMatch?.unresolved && filtersMatch.unresolved.codinga,
            isUnresolved: true,
            search: filtersMatch?.unresolved.search,
            page: filtersMatch?.unresolved && filtersMatch.unresolved.page || 1,
        },
        resolved: {
            category: filtersMatch?.resolved && filtersMatch.resolved.categorya && filtersMatch.resolved.categorya.id,
            categorya: filtersMatch?.resolved && filtersMatch.resolved.categorya,
            coding: filtersMatch?.resolved && filtersMatch.resolved.codinga && filtersMatch.resolved.codinga.id,
            codinga: filtersMatch?.resolved && filtersMatch.resolved.codinga,
            isUnresolved: 'false',
            search: filtersMatch?.resolved.search,
            page: filtersMatch?.resolved && filtersMatch.resolved.page || 1,
        }
    })
    const [selectedProducts, setSelectedProducts] = useState({
        unresolvedProduct: null,
        resolvedProduct: null,
    })

    const [confirmDialogDeleteOpen, setConfirmDialogDeleteOpen] = useState({
        message: '',
        title: '',
        onCancel: () => { },
        onConfirm: () => { },
        isLoading: false,
        isOpen: false
    });

    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

    useEffect(() => {
        getAllProductsInfo(filters.unresolved).then(res => {
            const results = res.data.results;
            setUnresolvedProducts({
                ...unresolvedProducts,
                products: results,
                page: res.data.page,
            });
        });
        getAllProductsInfo(filters.resolved).then(res => {
            const results = res.data.results;
            setResolvedProducts({
                ...resolvedProducts,
                products: results,
                page: res.data.page,
            });
        });
    },
        // eslint-disable-next-line  
        []);

    const loadProductsByCategory = (category, filters, categorya) => {

        getAllProductsInfo(filters[category]).then(res => {
            const results = res.data.results;
            if (category === 'unresolved') {
                setUnresolvedProducts({
                    ...unresolvedProducts,
                    products: results,
                    page: res.data.page,
                    productEditIndex: -1
                })
                setSelectedProducts(prevSelectedProducts => {
                    return {
                        ...prevSelectedProducts,
                        ['unresolvedProduct']: null
                    }
                })
            } else {
                setResolvedProducts({
                    ...resolvedProducts,
                    products: results,
                    page: res.data.page
                })
                setSelectedProducts(prevSelectedProducts => {
                    return {
                        ...prevSelectedProducts,
                        ['resolvedProduct']: null
                    }
                })
            }
        });
        const cat = category === 'unresolved' ? 'unresolved' : 'resolved';

        if (categorya !== undefined) {
            const newFilters = {
                ...filters,
                [cat]: {
                    ...filters[cat],
                    categorya,


                }
            }
            localStoreSetObj('productMatchFilter', newFilters);
        } else {

            const newFilters = {
                ...filters,
                [cat]: {
                    ...filters[cat],



                }
            }
            localStoreSetObj('productMatchFilter', newFilters);
        }







    }

    const loadProductsByCodingSystemFilter = (coding, filters, codinga) => {


        getAllProductsInfo(filters[coding]).then(res => {
            const results = res.data.results;
            if (coding === 'unresolved') {
                setUnresolvedProducts({
                    ...unresolvedProducts,
                    products: results,
                    page: res.data.page,
                    productEditIndex: -1
                })
                setSelectedProducts(prevSelectedProducts => {
                    return {
                        ...prevSelectedProducts,
                        ['unresolvedProduct']: null
                    }
                })
            } else {
                setResolvedProducts({
                    ...resolvedProducts,
                    products: results,
                    page: res.data.page
                })
                setSelectedProducts(prevSelectedProducts => {
                    return {
                        ...prevSelectedProducts,
                        ['resolvedProduct']: null
                    }
                })

            }

        })
        const cat = coding === 'unresolved' ? 'unresolved' : 'resolved';

        const newFilters = {
            ...filters,
            [cat]: {
                ...filters[cat],
                codinga,
            }
        }

        localStoreSetObj('productMatchFilter', newFilters);
    }

    const onFilterCatalogueChange = (e, categoryFilter) => {
        const category = e.catalogue.id
        const newFilters = {
            ...filters,
            [categoryFilter]: {
                ...filters[categoryFilter],
                category,
                page: 1
            }
        }

        setFilters(newFilters);
        loadProductsByCategory(categoryFilter, newFilters, e.catalogue);
    }
    const onFilterCodingSystemChange = (e, codingSystemFilter) => {

        const coding = e.codingSystem?.id
        const newFilters = {
            ...filters,
            [codingSystemFilter]: {
                ...filters[codingSystemFilter],
                coding,
                page: 1
            }
        }
        setFilters(newFilters);
        loadProductsByCodingSystemFilter(codingSystemFilter, newFilters, e.codingSystem);
    }

    const onProductClick = (product, category, index) => {

        if (category === 'unresolvedProduct') {
            const productEditObject = Object.assign({}, unresolvedProducts.products[index]);
            setUnresolvedProducts({
                ...unresolvedProducts,
                productEditIndex: index,
                productEditObject: productEditObject
            });

            const input = ref.current;
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value").set
            nativeInputValueSetter.call(input, productEditObject.name.substring(0, 4));
            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
        }

        setSelectedProducts(prevSelectedProducts => {
            return {
                ...prevSelectedProducts,
                [category]: product
            }
        })
    }
    const debounceLoadData = useCallback(debounce(loadProductsByCategory, 500), []);
    const onSearchTextChange = (e, category) => {
        const newSearchValue = e.target.value;
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                search: newSearchValue,
                page: 1
            }
        }
        setFilters(newFilters);
        category !== 'resolved' && setUnresolvedProducts({
            ...unresolvedProducts,
            productEditIndex: -1
        });


        debounceLoadData(category, newFilters);
    }
    const onMatchBtnClick = () => {
        setConfirmDialogOpen(true);
    }

    const onProductMatchConfirm = () => {
        matchProducts({
            id: selectedProducts.unresolvedProduct.id,
            matchId: selectedProducts.resolvedProduct.id
        })


            .then(res => {

                const newFilters = {
                    unresolved: {
                        category: null,
                        coding: filters.unresolved.coding,
                        codinga: filters.unresolved.codinga,
                        isUnresolved: true,
                        search: filters.unresolved.search,
                        page: unresolvedProducts.page.page
                    },
                    resolved: {
                        category: filters.resolved.category,
                        categorya: filters.resolved.categorya,
                        coding: filters.resolved.coding,
                        codinga: filters.resolved.codinga,
                        isUnresolved: 'false',
                        search: filters.resolved.search,
                        page: resolvedProducts.page.page
                    }
                }
                setFilters(newFilters)
                loadProductsByCategory('unresolved', newFilters)
                loadProductsByCategory('resolved', newFilters)
                setConfirmDialogOpen(false);
            })
    }

    const [, forceUpdate] = useState();
    //todo
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));

    const onEditableProductChange = (event, property) => {
        let newValue = '';
        if (property === "isUnresolved" || property === "isDiscountable") {
            newValue = event.target.checked ? true : false;
        }
        else {
            newValue = event.target.value;
        }
        const newEditProductItem = Object.assign({}, unresolvedProducts.productEditObject);
        newEditProductItem[property] = newValue;

        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditObject: newEditProductItem
        });
    };
    const handleSaveProduct = (event, props) => {
        event.preventDefault();
        setUnresolvedProducts({
            ...unresolvedProducts,
            isLoading: true
        });
        const newEditProductItem = Object.assign({}, unresolvedProducts.productEditObject);

        if (validator.current.allValid()) {
            editProduct(newEditProductItem.id, newEditProductItem)
                .then(re => {
                    const editedProduct = re.data;
                    if (!re.data.isUnresolved) {
                        const newUnresolvedProducts = [...unresolvedProducts.products];
                        newUnresolvedProducts.splice(unresolvedProducts.productEditIndex, 1);
                        setUnresolvedProducts({
                            ...unresolvedProducts,
                            products: newUnresolvedProducts,
                            productEditIndex: -1
                        });
                    }
                    else {
                        setUnresolvedProducts(oldProducts => {
                            return {
                                ...oldProducts,
                                products: oldProducts.products.map(product => {
                                    return product.id === editedProduct.id
                                        ? re.data : product
                                }),
                                productEditIndex: -1
                            }
                        });
                    }
                }).catch((error) => {
                    setUnresolvedProducts({
                        ...unresolvedProducts,
                        isLoading: false,
                    });
                });
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
            setUnresolvedProducts({
                ...unresolvedProducts,
                isLoading: false,
            });
        }

    };

    const handleCloseEditProduct = (e, index) => {
        setSelectedProducts(prevSelectedProducts => {
            return {
                ...prevSelectedProducts,
                unresolvedProduct: null
            }
        })
        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditIndex: -1
        })
    };

    const selectedCodingSystem = (data) => {

        const newEditProductItem = Object.assign({}, unresolvedProducts.productEditObject
        );

        newEditProductItem.coding = data.codingSystem;

        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditObject: newEditProductItem
        });
    };


    const selectedProductCategory = (data) => {

        const newEditProductItem = Object.assign({}, unresolvedProducts.productEditObject);

        newEditProductItem.category = data.category;

        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditObject: newEditProductItem
        });
    };
    const handleDeleteProduct = (event, index) => {


        const productForDeletion = unresolvedProducts.products[index];

        setConfirmDialogDeleteOpen({
            ...confirmDialogDeleteOpen,
            message: 'Are you sure you want to delete?',
            title: 'Delete Product',
            onCancel: () => {
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                })
            },
            onConfirm: () => {
                deleteProduct(productForDeletion.id).then(() => {
                    const newProducts = [...unresolvedProducts.products];
                    newProducts.splice(index, 1);
                    setUnresolvedProducts({
                        ...unresolvedProducts,
                        products: newProducts,
                    });
                    setConfirmDialogDeleteOpen({
                        ...confirmDialogDeleteOpen,
                        isOpen: false
                    });
                })
                    .catch(err => {
                        alert('ERROR')
                    });
            },
            isOpen: true
        });

    };


    const handleChangePage = (e, newPage, category) => {
        let filterCategory = '';
        const np = newPage;
        if (category === 'unresolvedProduct') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: np + 1
            },
        }
        setFilters(newFilters);
        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    const handleChangeRowsPerPage = (e, category) => {


        let filterCategory = '';
        const np = e.target.value;
        if (category === 'unresolvedProduct') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: 1,
                per_page: np
            },
        }
        setFilters(newFilters);
        setUnresolvedProducts({
            ...unresolvedProducts,
            productEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };


    return {
        unresolvedProducts,
        setUnresolvedProducts,
        isConfirmDialogOpen,
        resolvedProducts,
        setResolvedProducts,
        filters,
        setFilters,
        selectedProducts,
        setSelectedProducts,
        loadProductsByCategory,
        onFilterCatalogueChange,
        onFilterCodingSystemChange,
        onMatchBtnClick,
        onProductClick,
        onSearchTextChange,
        setConfirmDialogOpen,
        onProductMatchConfirm,
        handleDeleteProduct,
        validator,
        confirmDialogDeleteOpen,
        onEditableProductChange,
        handleSaveProduct,
        handleCloseEditProduct,
        handleChangePage,
        handleChangeRowsPerPage,
        selectedCodingSystem,
        ref,
        selectedProductCategory
    }
}

export default useProductMatchHook