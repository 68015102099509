const tableHeadersBuyer = [
  { id: 'region', disablePadding: false, label: 'Region' },
  { id: 'supplier', disablePadding: false, label: 'Supplier' },
  { id: 'category', disablePadding: false, label: 'Company Category'},
  { id: 'port', disablePadding: false, label: 'Port'},
  { id: 'usd', disablePadding: false, label: 'USD' },
];

const tableHeadersSupplier = [
  { id: 'region', disablePadding: false, label: 'Region' },
  { id: 'buyer', disablePadding: false, label: 'Customer'  },
  { id: 'category', disablePadding: false, label: 'Company Category'},
  { id: 'port', disablePadding: false, label: 'Port'},
  { id: 'usd', disablePadding: false, label: 'USD' },
];

export { tableHeadersBuyer, tableHeadersSupplier };