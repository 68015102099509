import React, { useCallback } from "react";
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCompanies, getBuyerCompanies, getSupplierCompanies } from '../../services/companiesService';
import {
    getAllEquivalentProductSuppliers,
  } from '../../services/equivalentsProductService';

export default function FilterCompanies({
    company: initialCompany,
    supplier: initialSupplier,
    companySearchFor,
    getSelectedCompany,
    errorMessage
}) {

    const [company, setCompany] = React.useState(initialCompany && initialCompany.id !== null ? initialCompany : 0);
    const [openCompany, setOpenCompany] = React.useState(false);
    const [companies, setCompanies] = React.useState(initialCompany && initialCompany.id !== null ? [initialCompany] : []);
    const [supplier] = React.useState(initialSupplier);
    const [search, setSearch] = React.useState('');

    let labelFilter = t('COMPANY');

    if (companySearchFor === 'buyer') {
        labelFilter = t('BUYER');
    }
    else if (companySearchFor === 'supplier') {
        labelFilter = t('SUPPLIER');
    }
    //added to avoid confusion in the vessels page for the admin. sorts by buyer but the filter label says company.
    else if (companySearchFor === 'vesselsCompany') {
        labelFilter = t('COMPANY');
    }

    const handleInputChange = (e, fetchType) => {

        const value = e && e.target && e.target.value;
        if (value && value.length >= 3 && search !== value) {
            setSearch(value);
            debounceFn(value);
        };
    };

    const randomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const handleDebounceFn = (val) => {
        companySearch({
            search: val,
            is_supplier: supplier
        })
    };
    const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

    const onCompanySelectionChange = (event, values) => {
        setCompany(values ? values : '');
        const selectedCompany = {
            company: values ? values : '',
        };
        getSelectedCompany(selectedCompany);

    };

    const companySearch = (criteria) => {
        if (companySearchFor === 'buyer' || companySearchFor === 'vesselsCompany') {
            return getBuyerCompanies(criteria).then((res) => {
                setCompanies(res.data);
            }).catch((error) => {
            })
        }
        else if (companySearchFor === 'supplier') {
            return getSupplierCompanies(criteria).then((res) => {
                setCompanies(res.data);
            }).catch((error) => {
            })
        }
        else if (companySearchFor === 'equivalentProductSupplier') {
            return getAllEquivalentProductSuppliers(criteria).then((res) => {
                setCompanies(res.data);
            }).catch((error) => {
            })
        }
        else {
            return getAllCompanies(criteria).then((res) => {
                setCompanies(res.data);
            }).catch((error) => {
            })
        }
    }

    return (
        <div>
            <Autocomplete
                // set the width to the input length multiplied by the x height
                // it's not quite right but gets it close
                id={"find-company" + randomNumber(0, 999)}
                fullWidth
                open={openCompany}
                onOpen={() => {
                    setOpenCompany(true);
                }}
                onClose={() => {
                    setOpenCompany(false);
                }}
                openOnFocus={true}
                onInputChange={e => handleInputChange(e)}
                onChange={onCompanySelectionChange}
                value={companies.find(v => v.id === company.id) || null}
                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                getOptionLabel={(option) => (option.name !== null ? option.name : '')}
                options={companies !== null ? companies : null}
                renderInput={(params) => (
                    <TextField
                        fullWidth
                        {...params}
                        label={labelFilter}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                )}
            />
        </div>
    );
};
