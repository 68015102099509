import React from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import OutlineDisplay from '../../components/outlineDisplay';
import InlineEditNotes from '../../components/inlineEditNotes';
import InlineEditDate from '../../components/inlineEditDate';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { AddSupplierNotes } from '../../components/actions';

const OrderInfo = (props) => {

    const orderNotes = (data) => {
        props.getNotes(data);
    };

    const orderSupplierNotes = (data) => {
        props.getSupplierNotes(data);
    };

    const selectedDate = (data) => {
        props.getSelectedDate(data);
    };

    const orderReferenceNumber = (data) => {
        props.getReferenceNumber(data);
    };

    const orderSupplierReference = (data) => {
        props.getSupplierReference(data);
    };

    const action = (data) => {
        return props.submitSupplierNotes(data);
    }

    return (<>
        <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-end"
            direction="row"
            spacing={3}
        >
            {props.alert && <Grid item xs={12}>
                <Alert severity="error">
                    <Typography variant="subtitle1" gutterBottom>
                        {props.alert}
                    </Typography>
                </Alert>
            </Grid>}
            <Grid item xs={2}>
                <OutlineDisplay label={t('VESSEL')} value={props.vessel} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('PORT')} value={props.port} />
            </Grid>
            <Grid item xs={2}>
                {props.edit ? <InlineEditNotes notes={props.referenceNumber} saved={props.savedReferenceNumber} label={t('REFERENCE_NUMBER')} error={props.error}
                    getNotes={orderReferenceNumber} req={true} /> : <OutlineDisplay label={t('REFERENCE_NUMBER')} value={props.referenceNumber} />}
            </Grid>
            <Grid item xs={2}>
                {props.editSupplier ? <InlineEditNotes notes={props.supplierReference} saved={props.savedSupplierReference} label={t('SUPPLIER_REFERENCE')} error={props.error}
                    getNotes={orderSupplierReference} /> : <OutlineDisplay label={t('SUPPLIER_REFERENCE')} value={props.supplierReference} />}
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('SUBMITTED')} value={props.submitted} />
            </Grid>
            <Grid item xs={2}>
                {props.edit ? <InlineEditDate due={props.due} saved={props.savedDue} error={props.error}
                    getSelectedDate={selectedDate} /> :
                    <OutlineDisplay label={t('DUE')} value={props.periodDue} />}
            </Grid>
            <Grid item xs={6}>
                {props.userRole === "BUYER" ?
                    <OutlineDisplay label={t('SUPPLIER')} value={props.supplier} /> :
                    <OutlineDisplay label={t('BUYER')} value={props.buyer} />
                }
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('CATALOGUE')} value={props.catalogue} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('STATUS')} value={props.status} />
            </Grid>
            <Grid item xs={1}>
                <OutlineDisplay label={t('DISCOUNT')} value={props.discount ? (props.discount).toString() + '%' : '0%'} />
            </Grid>
            <Grid item xs={1}>
                <OutlineDisplay label={t('CURRENCY')} value={props.currency} />
            </Grid>
            <Grid item xs={10}>
                {props.edit ?
                    <InlineEditNotes notes={props.notes} saved={props.savedNotes} error={props.error}
                        getNotes={orderNotes} /> : <OutlineDisplay label={t('NOTES')} value={props.notes} />}
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('TOTAL_COST')} value={props.amount} />
            </Grid>
        </Grid>
        <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            spacing={3}
            style={{ marginTop: 14 }}
        >
            <Grid item xs={props.editSupplier?10:12}>
                {props.editSupplier ?
                    <InlineEditNotes notes={props.supplierNotes} saved={props.savedSupplierNotes} error={props.error} label={t('ORDER_STATUS_NOTES')}
                        getNotes={orderSupplierNotes} /> : <OutlineDisplay label={t('ORDER_STATUS_NOTES')} value={props.supplierNotes} />}
            </Grid>
            {props.editSupplier && <Grid item xs={2}>
                <AddSupplierNotes getAction={action} label={'UPDATE_ORDER_STATUS'}/>
            </Grid>}
        </Grid>
    </>
    )
}


export default OrderInfo;