import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import clsx from 'clsx';
import React, { createElement, useState } from 'react';
import t from '../../helpers/languages';
import { navigator } from '../../helpers/sidenavConfig';
import SidenavListItem from '../sidenav-list-item';
import useStyles from './styles';
import { EventBus } from "../../services/eventBus";
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


const Sidenav = (props) => {
  const [expanded,setExpanded] = useState(false);

  const logoutUser = () => {
    EventBus.publish('user-state-change', {
      detail: {
        ...props.userState,
        accessToken: null
      }
    })
  };


  const hasChildren = (item) => {
    const { items: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  }


  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    return (
        <SidenavListItem
          key={item.id}
          icon={item.icon}
          open={props.sideNavState.expanded}
          listitemtitle={item.name}
          url={item.routeLink}
        />
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;

    var groupopen = false;
    if (localStorage.getItem(item.id) === 'false') {
      groupopen = true
    }
    const [open, setOpen] = useState(groupopen);



    const handleClick = () => {
      setOpen((prev) => !prev);
      localStorage.setItem(item.id, open);
    };

    return (
      <div className={classes.sidebarLink}>
        <ListItem key={item.name}
          disablePadding
          sx={{ display: 'block' }}>
          <ListItem button onClick={handleClick}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {createElement(item.icon, { className: classes.listItemIcon })}
            </ListItemIcon>
            <ListItemText primary={item.name} sx={{ opacity: props.sideNavState.expanded ? 1 : 0 }} />
            {props.sideNavState.expanded && (open ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
        </ListItem>          
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </div>
    );
  };

  const classes = useStyles();
  return (
    (<Drawer
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: props.sideNavState.expanded,
          [classes.drawerClose]: !props.sideNavState.expanded,
        }),
      }}
      variant='permanent'
      // onClick={handleDrawer}
      // onMouseEnter={() => {
      //   props.sideNavState.toggle(true)
      //   setExpanded(true)
      // }}
      // onMouseLeave={() => {
      //   props.sideNavState.toggle(false)
      //   setExpanded(false)
      // }}
    >       
      <Toolbar />
      <div>
        <List className='side-menu'>

          {
            props.userState
            && props.userState.user
            && props.userState.user.userRole
            && navigator[props.userState.user.userRole]
            && navigator[props.userState.user.userRole].mainSection.map((listItem) => {
              if (
                (listItem.authorizationFlag === null) ||
                props.userState.authorizations[listItem.authorizationFlag].read.includes(props.userState.user.userRole)
              ) {
                return (
                    <SidenavListItem
                      key={listItem.id}
                      icon={listItem.icon}
                      listitemtitle={listItem.name}
                      open={props.sideNavState.expanded}
                      url={listItem.routeLink}
                    />
                );
              } else {
                return '';
              }
            })
          }

          {
            props.userState
            && props.userState.user
            && props.userState.user.userRole
            && navigator[props.userState.user.userRole].secondarySection.map((listItem) => {
              return (

                <MenuItem item={listItem} key={listItem.id} />
              )

            })
          }
          {
            props.userState
            && props.userState.user
            && props.userState.user.userRole
            && navigator[props.userState.user.userRole].thirdSection.map((listItem) => {
              return (

                <MenuItem item={listItem} key={listItem.id} />
              )

            })
          }
          {
            props.userState
            && props.userState.user
            && props.userState.user.userRole
            && navigator[props.userState.user.userRole].fourthSection?.map((listItem) => {
              return (

                <MenuItem item={listItem} key={listItem.id} />
              )

            })
          }
          <Divider className={classes.horizontalLine} light />
          {
            props.userState
            && props.userState.user
            && props.userState.user.userRole
            && navigator[props.userState.user.userRole].accountSettings.map((listItem) => {
              return (
                  <SidenavListItem
                  key={listItem.id}
                  icon={listItem.icon}
                  listitemtitle={listItem.name}
                  open={props.sideNavState.expanded}
                  url={listItem.routeLink}
                />
              );
            })
          }
          <Divider className={classes.horizontalLine} light />
          <ListItem button className={classes.logoutButton} onClick={logoutUser}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: props.sideNavState.expanded ? 1 : 0 }}>
              {t('LOGOUT')}
            </ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
    )
  );
};

export default Sidenav;
