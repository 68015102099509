import { PropaneSharp } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React, { createElement, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';

const SidenavListItem = forwardRef((props, ref) => {
  const classes = useStyles();

  const renderIcon = (iconComp) => {
    return createElement(iconComp, { className: classes.listItemIcon });
  };

  return (
    <div
      {...props}
      ref={ref}
    >
      <NavLink
        activeClassName={classes.activeListItem}
        className={classes.sidebarLink}
        exact
        to={props.url}
      >
        <ListItem key={props.ListItemText} disablePadding sx={{ display: 'block' }}>
          <ListItem button
            sx={{
              minHeight: 48,
              justifyContent: props.open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : 'auto',
                justifyContent: 'center',
              }}>
              {renderIcon(props.icon)}
            </ListItemIcon>
            <ListItemText sx={{ opacity: props.open ? 1 : 0 }}>
              {props.listitemtitle}
            </ListItemText>
          </ListItem> </ListItem>
      </NavLink>
    </div>
  );
});

SidenavListItem.propTypes = {
  url: PropTypes.string.isRequired,
  listitemtitle: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default SidenavListItem;
