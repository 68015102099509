import { useCallback, useEffect, useState, useRef } from "react";
import { getAllCountriesInfo, matchCountries, editCountry, deleteCountry } from "../../services/countriesService";
import SimpleReactValidator from 'simple-react-validator';
import { debounce } from "lodash";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';

const useCountryMatchHook = () => {

    const countryInitObject = () => {
        return {
            id: '?',
            name: null,
            code: null,
            isUnresolved: false
        }
      };

    const [unresolvedCountries, setUnresolvedCountries] = useState({
        countries: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        countryEditIndex: -1,
        countryEditObject: countryInitObject(),
        error: null,
    })
   
    const [resolvedCountries, setResolvedCountries] = useState({     
       countries: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        error: null,
    })
    const filtersMatch = localStoreGetObj('countryMatchFilter');
    const ref=useRef(null);
    const [filters, setFilters] = useState({
        unresolved: {
            country: null,
            isUnresolved: true,
            search: '',
            page: filtersMatch?.unresolved&&filtersMatch.unresolved.page||1,
        },
        resolved: {
            country: null,
            isUnresolved: 'false',
            search: filtersMatch?.resolved.search,
            page: filtersMatch?.resolved&&filtersMatch.resolved.page||1,
        }
    })
    const [selectedCountries, setSelectedCountries] = useState({
        unresolvedCountry: null,
        resolvedCountry: null,
    })

	const [confirmDialogDeleteOpen, setConfirmDialogDeleteOpen] = useState({
        message: '',
        title: '',
        onCancel: () => { },
        onConfirm: () => { },
        isLoading: false,
        isOpen: false
    });

    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

    useEffect(() => {
        getAllCountriesInfo(filters.unresolved).then(res => {
            const results = res.data.results;

            setUnresolvedCountries({
                ...unresolvedCountries,
                countries: results,
                page: res.data.page,
            });
        });
        getAllCountriesInfo(filters.resolved).then(res => {
            const results = res.data.results;
            setResolvedCountries({
                ...resolvedCountries,
                countries: results,
                page: res.data.page,
            });
        });
    },
        // eslint-disable-next-line  
        []);

    const loadCountriesByCategory = (category, filters) => {

        getAllCountriesInfo(filters[category]).then(res => {
            const results = res.data.results;

            if (category === 'unresolved') {
                setUnresolvedCountries({
                    ...unresolvedCountries,
                    countries: results,
                    page: res.data.page,
                    countryEditIndex: -1
                })
                setSelectedCountries(prevSelectedCountries => {
                    return {
                        ...prevSelectedCountries,
                        ['unresolvedCountry']: null
                    }
                })
            } else {
                setResolvedCountries({
                    ...resolvedCountries,
                    countries: results,
                    page: res.data.page,
                })
                setSelectedCountries(prevSelectedCountries => {
                    return {
                        ...prevSelectedCountries,
                        [' resolvedCountry']: null
                    }
                })
            }

                const cat = category === 'unresolved' ? 'unresolved' : 'resolved'

                const newFilters = {
                    ...filters,
                    [cat]: {
                        ...filters[cat],
    
    
    
                    }
                }
                localStoreSetObj('countryMatchFilter', newFilters);
        });
    }

    const onFilterCountryChange = (e, category) => {
        const country = e.country.id
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                country,
                page: 1
            }
        }
        setFilters(newFilters);
        loadCountriesByCategory(category, newFilters);
    }

    const onCountryClick = (country, category, index) => {
        
        if (category==='unresolvedCountry') {
            const countryEditObject = Object.assign({}, unresolvedCountries.countries[index]);
            setUnresolvedCountries({
                ...unresolvedCountries,
                countryEditIndex: index,
                countryEditObject:countryEditObject
            });
        
        const input =  ref.current;
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value").set
        nativeInputValueSetter.call(input, countryEditObject.name.substring(0, 4));
        const event = new Event('input', { bubbles: true });
        input.dispatchEvent(event);
        }
        setSelectedCountries(prevSelectedCountries => {
            return {
                ...prevSelectedCountries,
                [category]: country
            }
        })
    }
    const debounceLoadData = useCallback(debounce(loadCountriesByCategory, 500), []);
    const onSearchTextChange = (e, category) => {
        const newSearchValue = e.target.value;
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                search: newSearchValue,
                page: 1
            }
        }
        setFilters(newFilters);
        category!=='resolved'&&setUnresolvedCountries({
            ...unresolvedCountries,
            countryEditIndex: -1
        });
        debounceLoadData(category, newFilters);
    }
    const onMatchBtnClick = () => {
        setConfirmDialogOpen(true);
    }

    const onCountryMatchConfirm = () => {
        matchCountries({
            id: selectedCountries.unresolvedCountry.id,
            matchId: selectedCountries.resolvedCountry.id
        })
            .then(res => {
                const newFilters = {
                    unresolved: {
                        country: null,
                        isUnresolved: true,
                        search: filters.unresolved.search,
                        page: unresolvedCountries.page.page
                    },
                    resolved: {
                        country: null,
                        isUnresolved: 'false',
                        search: filters.resolved.search,
                        page: unresolvedCountries.page.page
                    }
                }
                setFilters(newFilters)
                loadCountriesByCategory('unresolved', newFilters)
                loadCountriesByCategory('resolved', newFilters)
                setConfirmDialogOpen(false);
            })
    }

    const [, forceUpdate] = useState();
    //todo
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));

    const onEditableCountryChange = (event, property) => {
        let newValue='';
        if (property === "isUnresolved") {
            newValue = event.target.checked?true:false;
        }
        else {
            newValue = event.target.value;
        }
        const newEditCountryItem = Object.assign({}, unresolvedCountries.countryEditObject);
        newEditCountryItem[property] = newValue;

        setUnresolvedCountries({
            ...unresolvedCountries,
           countryEditObject: newEditCountryItem
        });
    };
    const handleSaveCountry = (event, props) => {
        event.preventDefault();
        setUnresolvedCountries({
            ...unresolvedCountries,
            isLoading: true
        });
        const newEditCountryItem = Object.assign({}, unresolvedCountries.countryEditObject);

        if (validator.current.allValid()) {
            editCountry(newEditCountryItem.id, newEditCountryItem)
                .then(re => {
                    const editedCountry = re.data;
                    if (!re.data.isUnresolved) {
                        const newUnresolvedCountries = [...unresolvedCountries.countries];
                        newUnresolvedCountries.splice(unresolvedCountries.countryEditIndex, 1);
                        setUnresolvedCountries({
                            ...unresolvedCountries,
                            countries: newUnresolvedCountries,
                            countryEditIndex: -1
                        });
                    }
                    else {
                        setUnresolvedCountries(oldCountries => {
                            return {
                                ...oldCountries,
                                countries: oldCountries.countries.map(port => {
                                    return port.id === editedCountry.id
                                        ? re.data : port
                                }),
                                countryEditIndex: -1
                            }
                        });
                    }
                }).catch((error) => {
                    setUnresolvedCountries({
                        ...unresolvedCountries,
                        isLoading: false,
                    });
                });
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
            setUnresolvedCountries({
                ...unresolvedCountries,
                isLoading: false,
            });
        }

    };

    const handleCloseEditCountry = (e, index) => {
        setSelectedCountries(prevSelectedCountries => {
            return {
                ...prevSelectedCountries,
                unresolvedCountry: null
            }
        })
        setUnresolvedCountries({
            ...unresolvedCountries,
            countryEditIndex: -1
        })
    };

	const handleDeleteCountry = (event, index) => {
        const countryForDeletion = unresolvedCountries.countries[index];
        setConfirmDialogDeleteOpen({
            ...confirmDialogDeleteOpen,
            message: 'Are you sure you want to delete?',
            title: 'Delete Country',
            onCancel: () => {
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                })
            },
            onConfirm: () => {
              deleteCountry(countryForDeletion.id).then(() => {
                const newCountries = [...unresolvedCountries.countries];
                newCountries.splice(index, 1);
                setUnresolvedCountries({
                    ...unresolvedCountries,
                  countries: newCountries,
                });
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                });
              })
                .catch(err => {
                  alert('ERROR')
                });
            },
            isOpen: true
          });
      };
    
    const handleChangePage = (e, newPage, category) => {
        let filterCategory = '';
        const np = newPage;
        if (category === 'unresolvedCountry') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: np + 1
            },
        }
        setFilters(newFilters);
        setUnresolvedCountries({
            ...unresolvedCountries,
            unitEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    const handleChangeRowsPerPage = (e, category) => {


        let filterCategory = '';
        const np = e.target.value;
        if (category === 'unresolvedCountry') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: 1,
                per_page: np
            },
        }
        setFilters(newFilters);
        setUnresolvedCountries({
            ...unresolvedCountries,
            unitEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    return {
        unresolvedCountries,
        setUnresolvedCountries,
        isConfirmDialogOpen,
        resolvedCountries,
        setResolvedCountries,
        filters,
        setFilters,
        selectedCountries,
        setSelectedCountries,
        loadCountriesByCategory,
        onFilterCountryChange,
        onMatchBtnClick,
        onCountryClick,
        onSearchTextChange,
        setConfirmDialogOpen,
        onCountryMatchConfirm,
        validator,
		confirmDialogDeleteOpen,
        handleDeleteCountry,
        onEditableCountryChange,
        handleSaveCountry,
        handleChangePage,
        handleChangeRowsPerPage,
        ref,
        handleCloseEditCountry,
    }
}

export default useCountryMatchHook