import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    tableRow: {
      cursor: 'pointer',
    }
  }
});

export default useStyles;
