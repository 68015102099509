
const tableHeadersPort = [
  { id: 'name', disablePadding: false, label: 'Port Name' },
  { id: 'country', disablePadding: false, label: 'Country' },
  { id: 'latitude', disablePadding: false, label: 'Latitude' },
  { id: 'longitude', disablePadding: false, label: 'Longitude' },
  { id: 'timezone', disablePadding: false, label: 'Time Zone' },
  { id: 'loCode', disablePadding: false, label: 'loCode' },
  { id: 'isUnresolved', disablePadding: false, label: 'Unresolved' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty product
const newPort = () => {
  return {
    id: '?',
    name: '',
    country:  { name: '', id: '' },
    latitude: '',
    longitude: '',
    timezone: '',
    loCode: '',
    isUnresolved: false,
  }
};

export { tableHeadersPort, newPort };