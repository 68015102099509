import {useEffect, useRef, useState} from "react";
import {EventSourcePolyfill} from "event-source-polyfill";
import messageSound from "../assets/sounds/send_message.mp3";
import {getMessage, getUnreadMessages, setMessagesRead, createMessage} from "../services/chatConversationService";
import { handleTokenRefresh } from '../services/httpService';
import {EventBus} from "../services/eventBus";

const useChatService = (entityId, entityType) => {
    const [messages, setMessages] = useState([]);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    let sseInstance = useRef(null);

    const initSSE = () => {
        const { REACT_APP_CLUSTER_URL } = process.env;
        const sourceLink = REACT_APP_CLUSTER_URL + `/sse/chat/messages/${entityId}/stream?entity=${entityType}`
        const stateObj = JSON.parse(window.localStorage.getItem('userStore') || '{}');

        sseInstance.current = new EventSourcePolyfill(sourceLink,
            {
                headers: {
                    ...(
                        !!stateObj?.accessToken &&
                        {"Authorization": `Bearer ${stateObj?.accessToken}`}
                    ),
                    'Content-Type': 'text/event-stream',
                    'Cache-Control': 'no-cache',
                    'Connection': 'keep-alive',
                },
                heartbeatTimeout: 1800000,
            });                   

        sseInstance.current.onopen = (e) => {

        };

        sseInstance.current.addEventListener("chat-messages", (event) => {
            const { data } = event;
            const message = JSON.parse(data);
            setMessages(oldMessages => [...oldMessages, {...message}]);

            if(!message.isFrom) {
                const audio = new Audio(messageSound);
                audio.play();
            }
        });

        sseInstance.current.onerror = async (error) => {
            sseInstance.current.close();
            /**
             *  ReInitialize the SSE connection, only in case refreshToken has not been expired.
             *  In other case, SPA will force redirection to login, in order to Authenticate again.
             */
            if(error?.status === 406 && stateObj.accessToken && stateObj.refreshToken) {
                try {
                    await handleTokenRefresh();
                    setTimeout(initSSE, 1000);
                } catch (e) {
                    EventBus.publish('jwt-token-update', {
                        accessToken: null,
                        refreshToken: null
                    });
                }
            }
        };
    }

    const loadMessages = async () => {
        const { data: messages } = await getMessage(entityId, entityType);
        setMessages(messages);
    }

    const loadUnreadMessages = async () => {
        const { data: unreadMessages } = await getUnreadMessages(entityId, entityType);
        setUnreadMessagesCount(unreadMessages);
    }

    const makeMessagesRead = async () => {
        await setMessagesRead(entityId, entityType);
        setUnreadMessagesCount(0);
    }

    useEffect( () => {
        initSSE();
        loadUnreadMessages();

        return () => {
            sseInstance.current?.close();
        }
    }, []);

    return {
        messages,
        setMessages,
        loadMessages,
        unreadMessagesCount,
        setUnreadMessagesCount,
        makeMessagesRead,
        createMessage
    }
}

export { useChatService }