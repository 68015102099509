import httpService from './httpService';

const getAllEquivalentProductsInfo = (criteria = {}) => {
  return httpService.FetchEquivalentProductInfoList(criteria);
};

const getAllEquivalentProductSuppliers = (criteria = {}) => {
  return httpService.FetchEquivalentProductSupplierList(criteria);
};

const getAllEquivalentCategoriesList = (criteria = {}) => {
  return httpService.FetchEquivalentCategoryList(criteria);
};

const resolveEquivalentProduct = (id, data) => {
  return httpService.ResolveEquivalentProduct({}, data);
};

const importEquivalentProductsFile = (data) => {
  console.log(data);
  let fd = new FormData();

  fd.append('file', new Blob([data], {
    type: "text/tab-separated-values"
  }));

  return httpService.UploadEquivalentProductsFile({}, fd,
    {
      headers: {
        'Content-Type': 'text/tab-separated-values',
      },
    })
};

export {
  getAllEquivalentProductsInfo,
  getAllEquivalentProductSuppliers,
  getAllEquivalentCategoriesList,
  resolveEquivalentProduct,
  importEquivalentProductsFile
};