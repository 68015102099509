import httpService from './httpService';


const getAllCountryDictionariesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.GetCountryDicts(criteria);
};

const deleteCountryDictionary = (id) => {
  return httpService.DeleteCountryDict(id);
};

const editCountryDictionary = (id, data) => {
   return httpService.UpdateCountryDict({}, data);
};

const addCountryDictionary = (data) => {
  return httpService.CreateCountryDict({},data);
};

export { getAllCountryDictionariesInfo, deleteCountryDictionary, addCountryDictionary, editCountryDictionary };
