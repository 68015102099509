import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersCountry, newCountry } from '../../helpers/countriesConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllCountriesInfo, editCountry, deleteCountry, addCountry } from '../../services/countriesService';
import { CountryTableFilter } from '../../components/countryTableFilter';
import CheckIcon from '@mui/icons-material/Check';
import './_countries.scss';
import t from '../../helpers/languages';

const Countries = (props) => {

  const countryInitObject = () => {
    return {
      id: '?',
      name: null,
      code: null,
      isUnresolved: false,
    }
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('countryFilter');

  const [countriesState, setCountriesState] = useState({
    countries: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    countryEditIndex: -1,
    countryEditObject: countryInitObject(),
    countryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...countriesState.filters,
    search: filters && filters.search,
    isUnresolved: filters && filters.isUnresolved,
    per_page: criteria.per_page,
    page: criteria.page,
  };


  useEffect(function () {
    getAllCountriesInfo(filtersData).then(res => {
      const { results } = res.data;

      setCountriesState({
        ...countriesState,
        countries: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesState({
        ...countriesState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setCountriesState({
      ...countriesState,
      isLoading: true
    });
    const newCriteria = {
      ...countriesState.filters,
      per_page: countriesState.page.per_page,
      page: newPage + 1
    };
    getAllCountriesInfo(newCriteria).then(res => {
      setCountriesState({
        ...countriesState,
        isLoading: false,
        countries: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setCountriesState({
      ...countriesState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...countriesState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllCountriesInfo(newCriteria).then(res => {
      setCountriesState({
        ...countriesState,
        isLoading: false,
        countries: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesState({
        ...countriesState,
        isLoading: false,
      });
    });
  };

  const handleEditCountry = (event, countryIndex) => {

    event.stopPropagation();

    const countryEditObject = Object.assign({}, countriesState.countries[countryIndex]);


    setCountriesState({
      ...countriesState,
      countryEditIndex: countryIndex,
      countryEditObject: countryEditObject
    });

  };

  const onEditableCountryChange = (event, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = event.target.checked ? true : false;
    }
    else {
        newValue = event.target.value;
    }

    const newEditCountryItem = Object.assign({}, countriesState.countryEditObject);
    newEditCountryItem[property] = newValue;

    setCountriesState({
      ...countriesState,
      countryEditObject: newEditCountryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveCountry = (event, props) => {
    event.preventDefault();
    setCountriesState({
      ...countriesState,
      isLoading: true
    });
    const newEditCountryItem = Object.assign({}, countriesState.countryEditObject);

    if (validator.current.allValid()) {
      editCountry(newEditCountryItem.id, newEditCountryItem)
        .then(re => {
          const editedCountry = re.data;
          setCountriesState(oldCountries => {
            return {
              ...oldCountries,
              countries: oldCountries.countries.map(country => {
                return country.id === editedCountry.id
                  ? re.data : country
              }),
              countryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCountriesState({
            ...countriesState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setCountriesState({
        ...countriesState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditCountry = (e, index) => {
    setCountriesState({
      ...countriesState,
      countryEditIndex: -1
    })
  };

  const handleDeleteCountry = (event, index) => {

    const countryForDeletion = countriesState.countries[index];
    setCountriesState({
      ...countriesState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Country',
        onCancel: () => {
          setCountriesState({
            ...countriesState,
            confirmDialog: {
              ...countriesState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteCountry(countryForDeletion.id).then(() => {
            const newCountries = [...countriesState.countries];
            newCountries.splice(index, 1);
            setCountriesState({
              ...countriesState,
              countries: newCountries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewCountry = (e) => {
    e.preventDefault();

    setCountriesState({
      ...countriesState,
      countryCreateObject: newCountry()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewCountry = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newCountry = {};
    newCountry.name = countriesState.countryCreateObject.name;
    newCountry.code = countriesState.countryCreateObject.code;
    newCountry.isUnresolved = countriesState.countryCreateObject.isUnresolved;

    if (validator.current.allValid()) {
      addCountry(newCountry)
        .then(res => {
          setCountriesState({
            ...countriesState,
            countries: [...countriesState.countries, res.data],
            countryCreateObject: null,
            countryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setCountriesState({
            ...countriesState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewCountryObject = (e, property) => {

    let newValue = '';
    if (property === "isUnresolved") {
        newValue = e.target.checked ? true : false;
    }
    else {
        newValue = e.target.value;
    }

    const newEditCountryItem = Object.assign({}, countriesState.countryCreateObject);
    newEditCountryItem[property] = newValue;

    setCountriesState({
      ...countriesState,
      countryCreateObject: newEditCountryItem
    });
  };

  const filterData = (data) => {
    setCountriesState({
      ...countriesState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...countriesState.filters,
      search: data && data.search,
      isUnresolved: data && data.isUnresolved
    };

    getAllCountriesInfo(filtersData).then(res => {
      const { results } = res.data;

      setCountriesState({
        ...countriesState,
        countries: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('countryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setCountriesState({
        ...countriesState,
        isLoading: false,
      });
    });

  };


  const tableFilterComponent = () => {
    return <CountryTableFilter
      search={(filters && filters.search) ? filters.search : countriesState.filters.search}
      isUnresolved={(filters && filters.isUnresolved) ? filters.isUnresolved : countriesState.filters.isUnresolved}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      headCells={tableHeadersCountry}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(countriesState.countries) && countriesState.countries.map((country, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (countriesState.countryEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>{country.name}</TableCell>
                    <TableCell>{country.code}</TableCell>
                    <TableCell>{country.isUnresolved && <CheckIcon />}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={countriesState.countryEditObject.name || ''}
                          onChange={e => onEditableCountryChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', countriesState.countryEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={countriesState.countryEditObject.code || ''}
                          onChange={e => onEditableCountryChange(e, 'code')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          variant="outlined"
                          control={
                            <Switch
                              checked={countriesState.countryEditObject.isUnresolved}
                              onChange={e =>  onEditableCountryChange(e, 'isUnresolved')}
                              name="isUnresolved"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              color="primary"
                            />
                          }
                          label="isUnresolved"
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: countriesState.countryEditIndex !== index,
                    callback: (e) => handleEditCountry(e, index)
                  },
                  delete: {
                    enabled: countriesState.countryEditIndex !== index,
                    callback: (e) => handleDeleteCountry(e, index)
                  },
                  close: {
                    enabled: countriesState.countryEditIndex === index,
                    callback: (e) => handleCloseEditCountry(e, index)
                  },
                  save: {
                    enabled: countriesState.countryEditIndex === index,
                    callback: (e) => handleSaveCountry(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          countriesState.countryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={countriesState.countryCreateObject.name || ''}
                  onChange={e => onEditNewCountryObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', countriesState.countryCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={countriesState.countryCreateObject.code || ''}
                  onChange={e => onEditNewCountryObject(e, 'code')}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FormControlLabel
                    variant="outlined"
                    control={
                      <Switch
                        checked={countriesState.countryCreateObject.isUnresolved}
                        onChange={e => onEditNewCountryObject(e, 'isUnresolved')}
                        name="isUnresolved"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                      />
                    }
                    label="isUnresolved"
                  />
                </FormControl>
              </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setCountriesState({ ...countriesState, countryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewCountry()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('COUNTRIES')}
        pagination={{
          total: countriesState.page.total || 0,
          page: countriesState.page.page - 1 || 0,
          perPage: countriesState.page.per_page || 10,
          count: countriesState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewCountry}
        newRowBtnEnabled={!countriesState.countryCreateObject}
        confirmDialog={countriesState.confirmDialog.message.length > 0 ? countriesState.confirmDialog : countriesState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        isLoading={countriesState.isLoading}
      />
    </>
  );
};


export default Countries;
