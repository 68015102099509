import React, { useCallback } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCountries } from '../../services/countriesService';
import useStyles from './style';

export default function InlineEditAutocompleteCountries({ country: initialCountry, getSelectedCountry, saved, error }) {

  const empty = {
    id: null,
    name: null
  };

  const [country, setCountry] = React.useState(initialCountry && initialCountry.id !== null ? initialCountry : empty);
  const [countries, setCountries] = React.useState(initialCountry && initialCountry.id !== null ? [initialCountry] : [empty]);
  const [openCountry, setOpenCountry] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const classes = useStyles();


  const handleSpanClick = useCallback(() => setOpenCountry(true), [
    setOpenCountry
  ]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    if (fetchType === "country" && value && value.length >= 2 && search !== value) {
      setSearch(value);
      debounceFn(value)
    };
  };

  const handleDebounceFn = (val) => {
    countrySearch({
      search: val
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onCountrySelectionChange = (event, values) => {
    setCountry(values ? values : []);
    const selectedCountry = {
      country: values ? values : [],
    };
    getSelectedCountry(selectedCountry);
  };

  const countrySearch = (criteria) => {

    return getAllCountries(criteria).then((res) => {
      setCountries(res.data);
    }).catch((error) => {
    })
  }

  return (
    <span className="inline-text">
      {openCountry || !saved ? (
        <span>
          <Autocomplete
            id={"find-country" + randomNumber(0, 999)}
            fullWidth
            open={openCountry}
            onOpen={() => {
              setOpenCountry(true);
            }}
            onClose={() => {
              setOpenCountry(false);
            }}
            onInputChange={e => handleInputChange(e, 'country')}
            onChange={onCountrySelectionChange}
            value={countries.find(v => v.id === country.id) || null}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name !== null ? option.name : '')}
            options={countries !== null ? countries : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin='none'
                variant="outlined"
                error={error && !saved}
                helperText={error && !saved && "Value not saved."}
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openCountry ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            id="outlined-disabled"
            label={t('COUNTRY')}
            defaultValue={country && country.name}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </span>)}
    </span >
  );
}