import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/tableHeader';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import TextField from "@mui/material/TextField";
import TableActions from '../../components/TableActions/TableActions';
import FormControl from '@mui/material/FormControl';
import { getQuoteById, editQuote, deleteQuote, reviseQuote, submitQuote, syncQuote, createOrderFromQuote, printQuote, getQuoteAttachment, addQuoteAttachment, deleteQuoteAttachment, exportQuote, importQuote } from '../../services/quotesService';
import { getQuoteItems, bulkEditQuoteItems } from '../../services/quoteItemsService';
import { tableHeaders } from '../../helpers/quoteItemsConfig';
import QuoteInfo from '../../components/quoteInfo';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { getAuthorizationMappings } from '../../services/authorizationsService';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import DropzoneComponent from '../../components/dropzoneComponent/DropzoneComponent';
import PrintPdf from '../../components/printPdf';
import ExportExcel from '../../components/exportExcel';
import FileUploader from '../../components/fileUploader';
import { TableFilterItems } from '../../components/tableFilterItems';
import InputAdornment from '@mui/material/InputAdornment';
import OnlineAssistant from '../../components/onlineAssistant';
import MembersChat from '../../components/membersChat';
import { useKeyDown } from '../../hooks/useKeyDown';
import t from '../../helpers/languages';
import Sha256 from 'crypto-js/sha256';
import { ValidateField } from './Validator';
import './quoteItemsStyles.css'

const QuoteItems = (props) => {
  const quoteItemEditObjectRef = useRef([]);
  const [newFiles, setNewFiles] = useState([]);
  const [dropkey, setDropKey] = useState(1);
  const [, setQuoteFiles] = React.useState('');
  const [, setCurrency] = React.useState('');
  const [, setQuoteNotes] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [bulkEdit, setBulkEdit] = React.useState(false);
  const activeIndex = useRef(-1); // Track which cell to highlight
  const inputRefs = React.useRef([]);

  const quoteInitObject = () => {
    return {
      port: { name: '', id: '' },
      submitted: '',
      status: '',
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      due: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      quoted_items: '',
      isCompleted: ''
    }
  };

  const [quotesState, setQuotesState] = useState({
    quotes: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    filters: {},
    isLoading: true,
    quoteEditIndex: -1,
    quoteEditObject: quoteInitObject(),
    quoteCreateObject: null,
    authorizedActions: {
      read: false,
      update: false,
      create: false,
      delete: false
    },
    error: null,
    isQuoteSaved: false,
    isCurrencySaved: true,
    isNotesSaved: true,
    isDiscountSaved: true,
    isReferenceNumberSaved: true,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });


  const quoteItemInitObject = () => {
    return {
      category: { id: '', name: '', code: '' },
      leadTimeDays: null,
      notes: '',
      id: '',
      discount: '',
      product: { code: '', id: '', name: '' },
      productDescription: '',
      totalCost: '',
      quantity: '',
      quoteId: '',
      remote_id: '',
      quoteitem_id: '',
      unit: { id: '', name: '', categoryId: '', categoryName: '' },
      unitCost: '',
      net_price: '',
      unitDescription: '',
      add: true
    }
  };

  const [quoteItemsState, setQuoteItemsState] = useState({
    quoteItems: [],
    page: {
      page: 1,
      count: 0,
      per_page: 50,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    quoteItemEditIndex: -1,
    quoteItemEditObject: [],
    quoteItemCreateObject: null,
    error: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const pathName = window.location.pathname;
  const regex = /\d+$/;
  let m;

  m = regex.exec(pathName);

  const quoteId = m[0];

  const quoteCriteria = { id: quoteId };

  const quoteItemCriteria = urlParams.getUrlParams();
  !quoteItemCriteria.per_page && (quoteItemCriteria.per_page = 50)
  !quoteItemCriteria.page && (quoteItemCriteria.page = 1)
  quoteItemCriteria.id = quoteId;
  urlParams.setUrlParams(quoteItemCriteria);
  quoteItemCriteria.order_type = quoteItemsState.page.order_type;
  quoteItemCriteria.order_by = quoteItemsState.page.order_by;

  const loadQuotes = () => {
    getQuoteItems(quoteItemCriteria).then(res => {
      const { results } = res.data;

      setQuoteItemsState({
        ...quoteItemsState,
        quoteItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters,
        authorizedActions: {
          ...getAuthorizationMappings('/quoteitems', props.userState.user.userRole)
        }
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
      });
    });
  }


  useEffect(() => {
    setQuotesState({
      ...quotesState,
      isLoading: true
    });


    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true
    });
    loadQuotes();
    getQuoteById(quoteCriteria).then(res => {
      setQuotesState({
        ...quotesState,
        quotes: res.data,
        isLoading: false
      });
    }).catch((error) => {
      setQuotesState({
        ...quotesState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line
  }, []);


  const handleSorting = (event, property) => {
    event.preventDefault();


    const isAsc = property === quoteItemsState.page.order_by && quoteItemsState.page.order_type === 'desc' ? 'asc' : 'desc';
    let filtersData = {
      ...quoteItemsState.filters,
      per_page: quoteItemsState.page.per_page,
      page: quoteItemsState.page.page,
      order_type: isAsc,
      order_by: property,
      id: quoteId
    };
    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });


    getQuoteItems(filtersData).then(res => {

      setQuoteItemsState({
        ...quoteItemsState,
        quoteItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false,
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
      });
    });

  };


  const handleChangePage = (event, newPage) => {
    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true,
    });

    const newCriteria = {
      ...quoteItemsState.filters,
      per_page: quoteItemsState.page.per_page,
      page: newPage + 1,
      id: quoteId
    };

    getQuoteItems(newCriteria).then(res => {
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
        quoteItemEditIndex: -1,
        quoteItemsCreateObject: null,
        quoteItems: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...quoteItemsState.filters,
      page: 1,
      per_page: perPage,
      id: quoteId
    };

    getQuoteItems(newCriteria).then(res => {
      setQuoteItemsState({
        ...quoteItemsState,
        quoteItems: res.data.results,
        quoteItemEditIndex: -1,
        isLoading: false,
        quoteItemsCreateObject: null,
        page: res.data.page,
        filters: newCriteria
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
      });
    });
  };

  const handleEditQuoteItem = (event, quoteItemIndex) => {
    event.stopPropagation();
    setBulkEdit(true);
    const quoteItemEditObject = JSON.parse(JSON.stringify(quoteItemsState.quoteItems));
    quoteItemEditObjectRef.current = quoteItemEditObject;
  };

  const onEditableQuoteItemChange = (event, property, index) => {
    event.preventDefault();

    const newValue = event.target.value;
    quoteItemEditObjectRef.current[index][property] = newValue;
    const targetElement = event.target;
    targetElement.value = newValue;
  };

  const handleSaveQuoteItem = (event) => {
    event.preventDefault();
    let errors = {};
    let saveQuoteItems=true;
    quoteItemEditObjectRef.current.forEach((quote, index) => {
    
      errors[index] = {
          quantity:  ValidateField('quantity', quote.quantity),
          unitCost:  ValidateField('unitCost', quote.unitCost),
          discount:  ValidateField('discount', quote.discount),
          leadTimeDays:  ValidateField('leadTimeDays', quote.leadTimeDays),
        }
    });
    
  
    Object.keys(errors).forEach(index => {
      const tableRow = document.querySelector(`tr[data-table-row-index="${index}"]`);
      const quanityErrorEl = tableRow.querySelector(`div[data-attribute="quantity"]`).nextSibling;
      const unitCostErrorEl = tableRow.querySelector(`div[data-attribute="unitCost"]`).nextSibling;
      const discountErrorEl = tableRow.querySelector(`div[data-attribute="discount"]`).nextSibling;
      const leadTimeDaysErrorEl = tableRow.querySelector(`div[data-attribute="leadTimeDays"]`).nextSibling;
      quanityErrorEl.innerHTML = errors[index].quantity?.quantity || '';
      unitCostErrorEl.innerHTML = errors[index].unitCost?.unitCost || '';
      discountErrorEl.innerHTML = errors[index].discount?.discount || '';
      leadTimeDaysErrorEl.innerHTML = errors[index].leadTimeDays?.leadTimeDays || '';
      if(errors[index].quantity?.quantity ||errors[index].unitCost?.unitCost||errors[index].discount?.discount||errors[index].leadTimeDays?.leadTimeDays)
      {saveQuoteItems=false}
    });
    

    if (saveQuoteItems) {
      const unChangedQuoteItems = quoteItemsState.quoteItems;
      const originalQuoteItemsHashes = [...unChangedQuoteItems.map(item => Sha256(JSON.stringify(item)).toString())];
      const updatedQuoteItemsHashes = [...quoteItemEditObjectRef.current.map(item => Sha256(JSON.stringify(item)).toString())];
      const updatedQuoteItems = [];

      updatedQuoteItemsHashes.forEach((hash, index) => {
        if(originalQuoteItemsHashes[index] !== updatedQuoteItemsHashes[index]) {
          updatedQuoteItems.push(quoteItemEditObjectRef.current[index])
        }
      })

      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: true,
      });
      bulkEditQuoteItems(quoteId, updatedQuoteItems)
        .then(re => {
          const results = re.data;
          loadQuotes();
          setQuoteItemsState({
            ...quoteItemsState,
            quoteItems: results,
            quoteItemEditIndex: -1,
            isLoading: false,
          });

          setBulkEdit(false);
          setNewFiles(() => { return [] });
          setDropKey(dropkey + 1);
          getQuoteById(quoteCriteria).then(res => {

            setQuotesState({
              ...quotesState,
              quotes: res.data,
              isLoading: false
            });
          }).catch((error) => {
            setQuotesState({
              ...quotesState,
              isLoading: false,
            });
          });

        }).catch((error) => {
          setBulkEdit(true);
          setQuoteItemsState({
            ...quoteItemsState,
            isLoading: false,
          });
        });
    } else {
      console.log('show errors');
    }
  };

  const triggerBlurValidator = (event) => {
    const element = event.target;
    const rootEl = element.closest('[data-attribute]');
    const attributeName = rootEl.dataset.attribute;
    const value = element.value;

    const validationError = ValidateField(attributeName, value);
    const errorEl = rootEl.nextSibling;

    errorEl.innerHTML = (!!validationError && validationError[attributeName] )|| '';
  }

  const handleCloseEditQuoteItem = (e, index) => {
    setBulkEdit(false);
    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true,
    });

    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: false,
      quoteItemEditIndex: -1
    })
  };

  const shouldSetStateToDisabled = (quotesState.quotes
    && quotesState.quotes.currency
    && quotesState.quotes.currency.id !== null
    && quotesState.quotes.reference_number
    && quoteItemsState.quoteItems.length > 0
    && quoteItemsState.quoteItemEditIndex === -1
    && quoteItemsState.quoteItemCreateObject === null);

  useEffect(() => {
    if (shouldSetStateToDisabled) {
      setDisabled(
        false

      );
    } else {
      setDisabled(
        true
      )
    }
  }, [shouldSetStateToDisabled])

  const handleDeleteQuote = (event) => {

    const quoteForDeletion = quoteId;
    setQuotesState({
      ...quotesState,
      isLoading: true
    });
    setQuotesState({
      ...quotesState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Quote',
        onCancel: () => {
          setQuotesState({
            ...quotesState,
            confirmDialog: {
              ...quotesState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteQuote(quoteForDeletion).then(() => {
            window.location.replace('/transactions/' + quotesState.quotes.transaction.id);
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });

  };

  const handleReviseQuote = (e) => {
    const quote = {
      id: quotesState.quotes.id
    };
    setQuotesState({
      ...quotesState,
      isLoading: true
    });
    reviseQuote(quote)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false,
          isQuoteSaved: true
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isQuoteSaved: false
        });
      });
  };

  const handleSyncQuote = (e) => {

    const quote = {
      id: quotesState.quotes.id
    };
    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true
    });
    setQuotesState({
      ...quotesState,
      isLoading: true,
    });
    syncQuote(quote)
      .then(res => {

        getQuoteItems(quoteItemCriteria).then(res => {
          const { results } = res.data;

          setQuoteItemsState({
            ...quoteItemsState,
            quoteItems: results,
            isLoading: false,
            page: res.data.page,
            filters: res.data.filters,
          });
          urlParams.setUrlParams({
            page: res.data.page.page,
            per_page: res.data.page.per_page
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setQuoteItemsState({
            ...quoteItemsState,
            isLoading: false,
          });
        });

        getQuoteById(quoteCriteria).then(res => {

          setQuotesState({
            ...quotesState,
            quotes: res.data,
            isLoading: false
          });

        }).catch((error) => {

          setQuotesState({
            ...quotesState,
            isLoading: false,
          });
        });
      }).catch((error) => {

        setQuotesState({
          ...quotesState,
          isLoading: false,
          isQuoteSaved: false
        });
      });
  };

  const handleSubmitQuote = (e) => {

    const quote = {
      id: quotesState.quotes.id
    };
    setQuotesState({
      ...quotesState,
      isLoading: true
    });
    submitQuote(quote)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false,
          isQuoteSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isQuoteSaved: false
        });
      });
  };

  const handleEvaluateSupplier = (e) => {

    window.location.assign('/transactions/' + quotesState.quotes.transaction.id + '/suppliers/' + quotesState.quotes.supplier.id);

  };

  const handleEvaluateItems = (e) => {

    window.location.assign('/transactions/' + quotesState.quotes.transaction.id + '/rated-products/' + quotesState.quotes.supplier.id);

  };

  const selectedProduct = (data, action) => {

    const newEditQuoteItem = Object.assign({},
      action === 'edit'
        ? quoteItemsState.quoteItemEditObject
        : quoteItemsState.quoteItemCreateObject
    );

    if (data.product.id !== undefined) {
      newEditQuoteItem.product.id = data.product.id;
      newEditQuoteItem.product.name = data.product.name;
      newEditQuoteItem.product.code = data.product.code;
      //todo what is product description
      newEditQuoteItem.productDescription = data.product.name;

    } else {
      newEditQuoteItem.product.id = '';
      newEditQuoteItem.product.name = '';
      newEditQuoteItem.product.code = '';
      newEditQuoteItem.productDescription = '';
    }

    setQuoteItemsState({
      ...quoteItemsState,
      [
        action === 'edit'
          ? 'quoteItemEditObject'
          : 'quoteItemCreateObject'
      ]: newEditQuoteItem
    });

  };

  const selectedUnit = (data, action) => {

    const newEditQuoteItem = Object.assign({},
      action === 'edit'
        ? quoteItemsState.quoteItemEditObject
        : quoteItemsState.quoteItemCreateObject
    );

    if (data.unit.id !== undefined) {
      newEditQuoteItem.unit.id = data.unit.id;
      newEditQuoteItem.unit.name = data.unit.name;
      newEditQuoteItem.unit.categoryId = data.unit.categoryId;
      newEditQuoteItem.unit.categoryName = data.unit.categoryName;
      newEditQuoteItem.unit.id = data.unit.id;
      //todo what is product description
      newEditQuoteItem.unitDescription = data.unit.name;
    } else {
      newEditQuoteItem.unit.id = '';
      newEditQuoteItem.unit.name = '';
      newEditQuoteItem.unit.categoryId = '';
      newEditQuoteItem.unit.categoryName = '';
      newEditQuoteItem.unitDescription = '';
    }

    setQuoteItemsState({
      ...quoteItemsState,
      [
        action === 'edit'
          ? 'quoteItemEditObject'
          : 'quoteItemCreateObject'
      ]: newEditQuoteItem
    });

  };

  const quoteNotes = (data) => {
    setQuoteNotes(data);

    const quoteEditObject = Object.assign({}, quotesState.quotes);
    if (data !== undefined && data !== '-' && data !== null) {
      quoteEditObject.notes = data.notes;
    } else {
      quoteEditObject.notes = null;
    }

  

    editQuote({ id: quotesState.quotes.id }, quoteEditObject)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false,
          isNotesSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isNotesSaved: false
        });
      });
  };

  const quoteReferenceNumber = (data) => {

    const quoteEditObject = Object.assign({}, quotesState.quotes);

    if (data !== undefined && data !== '-' && data !== null) {
      quoteEditObject.reference_number = data.notes;
    } else {
      quoteEditObject.reference_number = '';
    }

  

    editQuote({ id: quotesState.quotes.id }, quoteEditObject)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false,
          isReferenceNumberSaved: true
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isReferenceNumberSaved: false
        });
      });
  };

  const quoteDiscount = (data) => {
    let isDiscountValid = true;
    const quoteEditObject = Object.assign({}, quotesState.quotes);

    if (data !== undefined && data.value !== null && data.value !== '' && data.value !== undefined) {
      quoteEditObject.discount = data.value;
      isDiscountValid = data.value && data.value < 100 && data.value >= 0;
    } else {
      quoteEditObject.discount = null;
    }


   

    isDiscountValid ? editQuote({ id: quotesState.quotes.id }, quoteEditObject)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false,
          isDiscountSaved: true
        });

        getQuoteItems(quoteItemCriteria).then(res => {

          setQuoteItemsState({
            ...quoteItemsState,
            quoteItems: res.data.results,
            page: res.data.page,
            filters: res.data.filters,
            isLoading: false,
          });

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setQuoteItemsState({
            ...quoteItemsState,
            isLoading: false,
          });
        });

        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isDiscountSaved: false
        });
      }) : setQuotesState({
        ...quotesState,
        isLoading: false,
        isDiscountSaved: false,
        error: 'Discount must be between 0 and 100.'
      });
  };

  const selectedCurrency = (data) => {
    setCurrency(data);
    const quoteEditObject = Object.assign({}, quotesState.quotes);
    if (data.currency !== null && data.currency.id !== 1000000) {
      const ds = {
        id: data.currency.id,
        name: data.currency.name
      }
      quoteEditObject.currency = ds;
    } else {
      quoteEditObject.currency = null;
    }

   

    editQuote({ id: quotesState.quotes.id }, quoteEditObject)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setQuotesState({
          ...quotesState,
          isLoading: false,
          isCurrencySaved: false
        });
      });

  };

  const handleOrder = (e) => {

    e.preventDefault();
    const order = {
      id: quotesState.quotes.id
    };

    createOrderFromQuote(order).then(res => {
      setQuotesState({
        ...quotesState,
        isLoading: true
      });

      window.location.assign('/transactions/' + quotesState.quotes.transaction.id + '/orders/' + res.data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuotesState({
        ...quotesState,
        isLoading: false
      });
    });
  }

  const handleSearchItems = (data) => {

    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true
    });


    let filtersData = {
      search: data.search,
      per_page: quoteItemCriteria.per_page,
      page: 1,
      id: quoteId
    };

    getQuoteItems(filtersData).then(res => {
      const { results } = res.data;

      setQuoteItemsState({
        ...quoteItemsState,
        quoteItems: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setQuoteItemsState({
        ...quoteItemsState,
        isLoading: false,
      });
    });
  };

  const classes = useStyles();

  const handlePrint = (e) => {

    const quote = {
      id: quotesState.quotes.id
    };

    printQuote(quote)
      .then(response => {
        const binaryString = window.atob(response.data.blob);

        const binaryLen = binaryString.length;

        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }


        const url = window.URL.createObjectURL(new Blob([bytes], { type: response.data.type }));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
      });
  };

  const handleExport = (e) => {

    const quote = {
      id: quotesState.quotes.id
    };

    exportQuote(quote)
      .then(response => {
        const binaryString = window.atob(response.data.blob);

        const binaryLen = binaryString.length;

        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }


        const url = window.URL.createObjectURL(new Blob([bytes], { type: response.data.type }));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.name);

        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
      });
  };

  const handleImport = (data) => {

    setQuotesState({
      ...quotesState,
      isLoading: true
    });

    setQuoteItemsState({
      ...quoteItemsState,
      isLoading: true,
    });


    importQuote(quotesState.quotes.id, data)
      .then(res => {
        setQuotesState({
          ...quotesState,
          quotes: res.data,
          isLoading: false
        });
        setNewFiles(() => { return [] });
        setDropKey(dropkey + 1);

        quoteItemCriteria.per_page = 10;
        quoteItemCriteria.page = 1;
        quoteItemCriteria.id = quoteId;

        urlParams.setUrlParams(quoteItemCriteria);
        quoteItemCriteria.order_type = quoteItemsState.page.order_type;
        quoteItemCriteria.order_by = quoteItemsState.page.order_by;


        let filtersData = {
          ...quoteItemsState.filters,
          per_page: 10,
          page: 1,
          id: quoteId
        };

        getQuoteItems(filtersData).then(res => {
          const { results } = res.data;

          setQuoteItemsState({
            ...quoteItemsState,
            quoteItems: results,
            isLoading: false,
            page: res.data.page,
            filters: res.data.filters
          });
          urlParams.setUrlParams({
            page: res.data.page.page,
          });

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setQuoteItemsState({
            ...quoteItemsState,
            isLoading: false,
          });
        });
      }).catch((error) => {
        console.log(error);
        setQuotesState({
          ...quotesState,
          isLoading: false
        });
      });
  };


  const quoteFiles = (data) => {
    setQuoteFiles(data);

    addQuoteAttachment(quotesState.quotes.id, data).then((response) => {
      const newFile = response.data;
      setNewFiles([...newFiles, newFile]);
      setQuotesState({
        ...quotesState,
        quotes: {
          ...quotesState.quotes,
          attachments: [
            ...quotesState.quotes.attachments,
            newFile
          ]
        }
      })

    }).catch((error) => {
      console.log(error);
    });
  };

  const quoteFileClicked = (data) => {
    const fileInfo = {
      id: quoteId,
      fileId: data.id
    }

    getQuoteAttachment(fileInfo).then((response) => {

      const binaryString = window.atob(response.data.blob);

      const binaryLen = binaryString.length;

      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }


      const url = window.URL.createObjectURL(new Blob([bytes], { type: response.data.type }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.name);


      document.body.appendChild(link);
      link.click();
    });
  };

  const quoteFileDeleted = (data) => {
    const fileInfo = {
      id: quoteId,
      fileStorageid: data.filestorageId
    }

    const atts = quotesState.quotes.attachments.filter(attachment => attachment.id !== data.id);
    deleteQuoteAttachment(fileInfo).then((response) => {
      if (response) {
        setQuotesState({
          ...quotesState,
          quotes: {
            ...quotesState.quotes,
            attachments: atts
          }
        })
      }
    });
  };


  const attachments = () => {
    return (<DropzoneComponent
      initialFiles={quotesState.quotes.attachments}
      title={'Attachments'}
      key={dropkey}
      enabled={quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("UPDATE")}
      newFiles={newFiles}
      getFiles={quoteFiles}
      getFileClicked={quoteFileClicked}
      getFileDeleted={quoteFileDeleted}
    />)
  };

  const onKeyDown = useCallback(() => {

    if (activeIndex.current + 5 < quoteItemsState.page.count * 5) {
      console.log(activeIndex.current)
      inputRefs.current[activeIndex.current + 5].focus();
    }
  }, [activeIndex.current,quoteItemsState.page.count]);

  useKeyDown(() => {
    onKeyDown()
  }, ["Enter"]);

  const tableFilterComponent = () => {
    return <TableFilterItems
      config={{
        search: {
          enabled: true,
          callback: (e) => handleSearchItems(e)
        },
        edit: {
          enabled: quotesState.quotes.actions?.includes("UPDATE") && !quotesState.quotes.isCompleted && !bulkEdit,
          callback: (e) => handleEditQuoteItem(e)
        },
        close: {
          enabled: bulkEdit,
          callback: (e) => handleCloseEditQuoteItem(e)
        },
        save: {
          enabled: bulkEdit,
          callback: (e) => handleSaveQuoteItem(e, props)
        }
      }}

    />;
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={tableHeaders}
      onSelectAllClick={() => { }}
      order={quoteItemsState.page.order_type}
      orderBy={quoteItemsState.page.order_by}
      rowCount={quoteItemsState.page.length} />;
  };

  const tablePrint = () => {
    return <PrintPdf title={t('PRINT')} handlePrint={handlePrint} />
  };

  const tableExport = () => {
    return <ExportExcel title={t('EXPORT')} handleExport={handleExport} />
  };

  const tableImport = () => {
    return <FileUploader
      title={t('IMPORT')}
      accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/vnd.ms-excel'}
      handleImport={handleImport}
    />
  };


  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        revise: {
          enabled: quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("REVISE"),
          callback: (e) => handleReviseQuote(e)
        },
        delete: {
          enabled: quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("DELETE"),
          callback: (e) => handleDeleteQuote(e)
        },
        sync: {
          enabled: quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("SYNCHRONIZE"),
          callback: (e) => handleSyncQuote(e)
        },
        submit: {
          enabled: quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("SUBMIT"),
          disabled: disabled,
          callback: (e) => handleSubmitQuote(e)
        },
        quote: {
          enabled: false,
        },
        order: {
          enabled: quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("ORDER"),
          callback: (e) => handleOrder(e)
        },
        evaluateItems: {
          enabled:  quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("EVALUATE_ITEMS"),
          callback: (e) => handleEvaluateItems(e)
        },
        evaluateSupplier: {
          enabled:  quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("EVALUATE_SUPPLIER"),
          callback: (e) => handleEvaluateSupplier(e)
        },
      }}

    />;
  };


  const infoTab = () => {

    return (
      !quotesState.isLoading && quotesState.quotes && quotesState.quotes.length !== 0 &&
      <QuoteInfo
        vessel={quotesState.quotes.vessel ? quotesState.quotes.vessel.name : "-"}
        supplier={quotesState.quotes.supplier ? quotesState.quotes.supplier.name : "-"}
        userRole={props.userState.user.userRole}
        buyer={quotesState.quotes.buyer ? quotesState.quotes.buyer.name : "-"}
        catalogue={quotesState.quotes.category ? quotesState.quotes.category.name : "-"}
        port={quotesState.quotes.port ? quotesState.quotes.port.name : "-"}
        periodDue={quotesState.quotes.periodDue ? quotesState.quotes.periodDue : "-"}
        submitted={quotesState.quotes.submitted ? quotesState.quotes.submitted.slice(0, 10) : "-"}
        currency={quotesState.quotes.currency ? quotesState.quotes.currency : '-'}
        discount={quotesState.quotes.discount}
        status={quotesState.quotes.status || "-"}
        referenceNumber={quotesState.quotes.reference_number}
        amount={quotesState.quotes.amount || "-"}
        alert={quotesState.quotes.alert}
        saved={quotesState.isQuoteSaved}
        savedCurrency={quotesState.isCurrencySaved}
        savedNotes={quotesState.isNotesSaved}
        savedDiscount={quotesState.isDiscountSaved}
        savedReferenceNumber={quotesState.isReferenceNumberSaved}
        notes={quotesState.quotes.notes}
        check={quotesState.quotes.isCompleted}
        error={quotesState.error}
        edit={quotesState.quotes.actions && quotesState.quotes.actions.includes("UPDATE")}
        getSelectedCurrency={selectedCurrency}
        getReferenceNumber={quoteReferenceNumber}
        getNotes={quoteNotes}
        getDiscount={quoteDiscount}
      />
    )
  };


  let count=0;
  const tableBodyComponent = () => {

    return (
      <TableBody className={'quote-items-body'}>
        {
              quotesState.quotes &&
              !quotesState.isLoading &&
              quoteItemsState.quoteItems.map((quoteItem, index) => (
                  <TableRow
                    style={{display: !bulkEdit ? 'table-row': 'none' }}
                    key={index}
                    className={classes.tableRow}
                >
                  <TableCell>{quoteItem.product.code}</TableCell>
                  <TableCell>{quoteItem.product.name}</TableCell>
                  <TableCell>{quoteItem.quantity}</TableCell>
                  <TableCell>{quoteItem.unit?.name}</TableCell>
                  <TableCell>{quoteItem.unitCost}</TableCell>
                  <TableCell>{quoteItem.discount ? (quoteItem.discount).toString() + '%' : '0%'}</TableCell>
                  <TableCell>{quoteItem.totalCost}</TableCell>
                  <TableCell>{quoteItem.leadTimeDays}</TableCell>
                  <TableCell>{quoteItem.notes}</TableCell>
                  <TableCell>
                    <TableActions config={{
                      edit: {
                        enabled: false,
                        callback: () => null
                      },
                      delete: {
                        enabled: false,
                        callback: () => null
                      },
                      close: {
                        enabled: false,
                        callback: () => null
                      },
                      save: {
                        enabled: false,
                        callback: () => null
                      }
                    }} />
                  </TableCell>
                </TableRow>
              ))
        }
        {
          !!quoteItemEditObjectRef.current &&
          !!quoteItemEditObjectRef.current.length &&
            quoteItemEditObjectRef.current.map((quoteItem, index) => (
              <TableRow
                  style={{display: bulkEdit ? 'table-row': 'none' }}
                  key={index}
                  className={classes.tableRow}
                  data-table-row-index={index}
              >
                <TableCell onClick={e => e.stopPropagation()}>
                  {quoteItem.product.code}
                </TableCell>
                <TableCell onClick={e => e.stopPropagation()}>
                  {quoteItem.product.name}
                </TableCell>
                <TableCell
                    onClick={e => e.stopPropagation()}
                >
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                        variant="outlined"
                        size='small'
                        inputRef={(el) => (inputRefs.current[index*5] = el)}
                        onFocus={
                          (event) => {
                            activeIndex.current = index * 5;
                            event.target.select();
                          }
                        }
                        defaultValue={quoteItem.quantity}
                        data-attribute={'quantity'}
                        onChange={e => onEditableQuoteItemChange(e, 'quantity', index)}
                        onBlur={e => triggerBlurValidator(e)}
                    />
                    <span className={'error'}></span>
                  </FormControl>
                </TableCell>
                <TableCell onClick={e => e.stopPropagation()}>
                  {quoteItem.unit.name}
                </TableCell>
                <TableCell
                    onClick={e => e.stopPropagation()}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                        size='small'
                        inputRef={(el) => (inputRefs.current[index*5+1] = el)}
                        // onKeyDown={e =>onKeyDown(e, index*5+1)}
                        onFocus={
                          (event) => {
                            activeIndex.current = (index * 5) + 1;
                            event.target.select();
                          }
                        }
                        variant="outlined"
                        defaultValue={quoteItem.unitCost}
                        data-attribute={'unitCost'}
                        // type="number"     
                        // inputProps={{
                        //   step: '0.01',
                        // }}
                        onChange={e => onEditableQuoteItemChange(e, 'unitCost', index)}
                        onBlur={e => triggerBlurValidator(e)}
                        />
                    <span className={'error'}></span>
                  </FormControl>
                </TableCell>
                <TableCell
                    onClick={e => e.stopPropagation()}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                        variant="outlined"
                        size='small'
                        inputRef={(el) => (inputRefs.current[index*5+2] = el)}
                        // onKeyDown={e =>onKeyDown(e, index*5+2)}
                        onFocus={
                          (event) => {
                            activeIndex.current = (index * 5) + 2;
                            event.target.select();
                          }
                        }
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        // type="number"     
                        // inputProps={{
                        //   step: '0.01',
                        // }}
                        defaultValue={quoteItem.discount}
                        data-attribute={'discount'}
                        onChange={e => onEditableQuoteItemChange(e, 'discount', index)}
                        onBlur={e => triggerBlurValidator(e)}
                    />
                    <span className={'error'}></span>
                  </FormControl>
                </TableCell>
                <TableCell onClick={e => e.stopPropagation()}>
                  {quoteItem.totalCost}
                </TableCell>
                <TableCell
                    onClick={e => e.stopPropagation()}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                        variant="outlined"
                        size='small'
                        inputRef={(el) => (inputRefs.current[index*5+3] = el)}
                        // onKeyDown={e =>onKeyDown(e, index*5+3)}
                        onFocus={
                          (event) => {
                            activeIndex.current = (index * 5) + 3;
                            event.target.select();
                          }
                        }
                        defaultValue={quoteItem.leadTimeDays}
                        data-attribute={'leadTimeDays'}
                        onChange={e => onEditableQuoteItemChange(e, 'leadTimeDays', index)}
                        onBlur={e => triggerBlurValidator(e)}
                    />
                    <span className={'error'}></span>
                  </FormControl>
                </TableCell>
                <TableCell
                    onClick={e => e.stopPropagation()}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                        variant="outlined"
                        size='small'
                        inputRef={(el) => (inputRefs.current[index*5+4] = el)}
                        onFocus={
                          (event) => {
                            activeIndex.current = (index * 5) + 4;
                            event.target.select();
                          }
                        }
                        defaultValue={quoteItem.notes}
                        onChange={e => onEditableQuoteItemChange(e, 'notes', index)}
                        fullWidth
                        multiline
                        maxRows={10}
                    />
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TableActions config={{
                    edit: {
                      enabled: false,
                      callback: () => null
                    },
                    delete: {
                      enabled: false,
                      callback: () => null
                    },
                    close: {
                      enabled: false,
                      callback: () => null
                    },
                    save: {
                      enabled: false,
                      callback: () => null
                    }
                  }} />
                </TableCell>
              </TableRow>
          ))
        }
      </TableBody>
    );
  };

  return (
    <>{quotesState.quotes && quotesState.quotes.transaction && <MembersChat
      entityId={quotesState.quotes.id}
      entityType={'quote'}
      relatedId={quotesState.quotes.transaction.id}
      ownerId={props.userState.user.userId}
      recipient={props.userState.user.userRole === "BUYER" ? quotesState.quotes.supplier : quotesState.quotes.buyer}
      supplierId={quotesState.quotes.supplier.id}
      user={props.userState}
    />}
      {quotesState.quotes && !quotesState.isLoading && quotesState.quotes.actions && <OnlineAssistant
        path={'quote'}
        user={props.userState.user.userRole}
        submit={quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("SUBMIT")}
      />}
      <ProcureDataTable
        toolbarTitle={''}
        infoTabTitle={t('QUOTATION')}
        pagination={{
          total: quoteItemsState.page.total || 0,
          page: quoteItemsState.page.page - 1 || 0,
          perPage: quoteItemsState.page.per_page || 50,
          count: quoteItemsState.page.count || 0,
          disabled:bulkEdit,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={''}
        newRowActionAvailable={''}
        newRowBtnEnabled={''}
        confirmDialog={quoteItemsState.confirmDialog.message.length > 0 ? quoteItemsState.confirmDialog : quotesState.confirmDialog}
        infoTab={infoTab}
        importAction={quotesState.quotes && quotesState.quotes.actions && quotesState.quotes.actions.includes("UPDATE")}
        breadcrump={breadcrump}
        tableHeader={tableHeaderComponent}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableBody={tableBodyComponent}
        tablePrint={tablePrint}
        tableExport={tableExport}
        tableImport={tableImport}
        attachments={attachments}
        isLoading={quoteItemsState.isLoading}
        itemsTableTitle={t('ITEMS')}
      />
    </>
  );
};

export default QuoteItems;
