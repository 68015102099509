import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { Grid, } from "@mui/material";
import FilterPorts from "../filterPorts";
import FilterVessels from "../filterVessels";
import FilterCatalogues from "../filterCatalogues";
import FilterCompanies from "../filterCompanies";
import './_buyerPreferencesTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const BuyerPreferencesTableFilter = ({
  supplier: initialSupplier,
  buyer: initialBuyer,
  vessel: initialVessel,
  productCategory: initialProductCategory,
  port: initialPort, userRole,
  getFilterData
}) => {
  const classes = useStyles();

  const [supplier, setSupplier] = useState(initialSupplier);
  // const [buyer, setBuyer] = useState(initialBuyer);
  const [vessel, setVessel] = useState(initialVessel);
  const [port, setPort] = useState(initialPort);
  const [productCategory, setProductCategory] = useState(initialProductCategory);


  const selectedPort = (data) => {
    setPort(data.port);
    const filterData = {
      port: data.port,
      vessel: vessel,
      category: productCategory,
      // buyer: buyer,
      supplier: supplier
    };

    getFilterData(filterData);
  }

  const selectedVessel = (data) => {
    setVessel(data.vessel);
    const filterData = {
      port: port,
      vessel: data.vessel,
      category: productCategory,
      // buyer: buyer,
      supplier: supplier
    };

    getFilterData(filterData);
  }

  const selectedCatalogue = (data) => {

    setProductCategory(data.catalogue);
    const filterData = {
      port: port,
      vessel: vessel,
      category: data.catalogue,
      // buyer: buyer,
      supplier: supplier
    };

    getFilterData(filterData);

  }

  const selectedSupplier = (data) => {

    setSupplier(data.company);
    const filterData = {
      port: port,
      vessel: vessel,
      category: productCategory,
      // buyer: buyer,
      supplier: data.company
    };

    getFilterData(filterData);
  }

  // const selectedBuyer = (data) => {

  //   setBuyer(data.company);
  //   const filterData = {
  //     port: port,
  //     vessel: vessel,
  //     category: productCategory,
  //     buyer: data.company,
  //     supplier: supplier
  //   };

  //   getFilterData(filterData);
  // }

  const companyObj={id:initialBuyer};

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterVessels
              vessel={vessel}
              company={companyObj}
              getSelectedVessel={selectedVessel} />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterPorts
              port={port}
              getSelectedPort={selectedPort} />
          </FormControl>
        </Grid>
        {/* {userRole === 'MBA' && (
          <Grid item xs={2} >
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterCompanies
                company={buyer}
                companySearchFor={'buyer'}
                getSelectedCompany={selectedBuyer}
                errorMessage={''}
              />
            </FormControl>
          </Grid>)} */}
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCompanies
              company={supplier}
              companySearchFor={'supplier'}
              getSelectedCompany={selectedSupplier}
              errorMessage={''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCatalogues
              catalogue={productCategory}
              getSelectedCatalogue={selectedCatalogue}
            />
          </FormControl>
        </Grid>
      </Grid >
    </form >
  );
}