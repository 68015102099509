import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import './style.scss';
// import TrendingUp from '@mui/icons-material/TrendingUp';
// import TrendingDown from '@mui/icons-material/TrendingDown';

const ChartWithNoAxesAndNumber = (props) => {

    useEffect(() => {

        const data = props.stats.map((stat) => (stat.total));
        const label = props.stats.map((stat) => (stat.monthAbr));
        const con = document.getElementById('myChartNoAxes');
        let c = new Chart(con, {
            type: 'bar',
            data: {
                labels: label,
                datasets: [{
                    data: data,
                    label: "Rfq's",
                    backgroundColor: [
                        'rgba(102, 255, 181, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    // borderColor: [
                    //     'rgba(255, 99, 132, 1)',
                    //     'rgba(54, 162, 235, 1)',
                    //     'rgba(255, 206, 86, 1)',
                    //     'rgba(75, 192, 192, 1)',
                    //     'rgba(153, 102, 255, 1)',
                    //     'rgba(255, 159, 64, 1)'
                    // ],
                    borderWidth: .5,
                    lineWidth: .3
                }]
            },
            options: {
                responsive: true,
                // maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales:
                {
                    x: {
                        display: true,
                        grid: {
                            display: false,

                        }, 
                        border: {
                            display: false
                        },
                        ticks: {
                            autoSkip: false
                        }
                    },
                    y: {
                        display: false,
                        beginAtZero: true,
                    }
                }
            }
        });

        return () => { c.destroy(); }

    }, [props.stats]);

    const themes = {
        red: {
            fontColor: '#ff4861',
            progressBarColor: 'linear-gradient(270deg,#ff7e9a,#ff4861)'
        },
        springGreen: {
            fontColor: '#00ff7f',
            progressBarColor: 'linear-gradient(270deg,#c7ffe3,#00ff7f)'
        },
        green: {
            fontColor: '#b8e986',
            progressBarColor: 'linear-gradient(270deg,#d8efc1,#b8e986)'
        },
        blue: {
            fontColor: '#48b5ff',
            progressBarColor: 'linear-gradient(270deg,#7edbff,#48b5ff)'
        },
    };

    return (
        <section
            className='no-axes-presenter'
        >
            <div className='column-no-axes'>
                <div
                    className='no-axes-presenter__total-amount'
                    style={{ color: themes[props.theme].fontColor }}
                >
                    <span>{props.amount}</span>
                    {/* {props.stats.length > 1 && props.stats[props.stats.length - 2].total > props.stats[props.stats.length - 1].total ?
                        <TrendingDown /> : <TrendingUp />} */}
                </div>
                <div className='no-axes-presenter__total-amount-label'>{props.label}</div>
            </div>
            {/* <hr></hr> */}
            <div className='column-no-axes'>
                <canvas
                    id="myChartNoAxes"
                    width={300}
                    height={'100%'}
                >
                </canvas>
            </div>
        </section >
    )
};

export default ChartWithNoAxesAndNumber;