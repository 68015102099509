import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const TableHeader = (props) => {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={index}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              width={headCell.width}
              align={headCell.align}
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
