
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InlineEditAutocompletePorts from "../../components/inlineEditAutocompletePorts";
import InlineEditAutocompleteCountries from "../../components/inlineEditAutocompleteCountries";
import InlineEditAutocompleteCompanyCategories from "../../components/inlineEditAutocompleteCompanyCategories";
import InlineEditAutocompleteCodingSystems from "../../components/inlineEditAutocompleteCodingSystems";
import InlineEditNotes from '../../components/inlineEditNotes';
import InlineEditSwitch from '../../components/inlineEditSwitch';
import { Grid } from "@mui/material";
import './_CompanyEditRow.scss';
import clsx from "clsx";

const CompanyEditRow = ({
    getSelectedCodingSystem,
    getSelectedCountry,
    companiesData,
    getCompanyName,
    getCompanyAddress,
    getSelectedPort,
    getCompanyEmail,
    getSelectedCompanyCategory,
    getSupplierChecked,
    getIntegratedChecked
}) => {

    const companyName = (data) => {
        getCompanyName(data);
    };

    const companyAddress = (data) => {
        getCompanyAddress(data);
    };

    const supplierChecked = (data) => {
        getSupplierChecked(data);
    };

    const integratedChecked = (data) => {
        getIntegratedChecked(data);
    };

    const selectedCountry = (data) => {
        getSelectedCountry(data);
    };

    const selectedPort = (data) => {
        getSelectedPort(data);
    };

    const companyEmail = (data) => {
        getCompanyEmail(data);
    };

    const selectedCompanyCategory = (data) => {
        getSelectedCompanyCategory(data);
    };

    const selectedCodingSystem = (data) => {
        getSelectedCodingSystem(data);
    };

    return companiesData.companyEditObject.id !== '?' && (

        <div className={'company-form'}>
            {<form className={clsx({
                'company-form__form': true,
            })}

            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditNotes notes={companiesData.companyEditObject.name} saved={companiesData.isNameSaved} label={'Name'} error={companiesData.error}
                                getNotes={companyName} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditAutocompleteCompanyCategories companyCategory={companiesData.companyEditObject.category}
                                saved={companiesData.isCompanyCategorySaved} error={companiesData.error}
                                getSelectedCompanyCategory={selectedCompanyCategory} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <InlineEditSwitch
                            check={companiesData.companyEditObject.supplier} label={'Supplier'} saved={companiesData.isSupplierSaved}
                            getSelectedCheck={supplierChecked}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditNotes notes={companiesData.companyEditObject.address} saved={companiesData.isAddressSaved} label={'Address'} error={companiesData.error}
                                getNotes={companyAddress} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <InlineEditSwitch
                            check={companiesData.companyEditObject.integrated} label={'Integrated'} saved={companiesData.isIntegratedSaved}
                            getSelectedCheck={integratedChecked}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditAutocompleteCountries country={companiesData.companyEditObject.country} saved={companiesData.isCountrySaved} error={companiesData.error}
                                getSelectedCountry={selectedCountry} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditAutocompletePorts port={companiesData.companyEditObject.port} saved={companiesData.isPortSaved} error={companiesData.error}
                                getSelectedPort={selectedPort} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditAutocompleteCodingSystems codingSystem={companiesData.companyEditObject.codingSystem}
                                getSelectedCodingSystem={selectedCodingSystem}
                                saved={companiesData.isCodingSystemSaved} error={companiesData.error}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InlineEditNotes notes={companiesData.companyEditObject.email} saved={companiesData.isEmailSaved} label={'Email'} error={companiesData.error}
                                getNotes={companyEmail} />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
            }
        </div >
    );

};


export default CompanyEditRow;