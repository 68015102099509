import React, { useCallback, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { getAllCompanyCategories } from '../../services/companyCategoriesService';

export default function FilterCompanyCategories({
    companyCategory: initialCompanyCategory,
    getSelectedCompanyCategory,
    errorMessage
}) {

    const [companyCategory, setCompanyCategory] = React.useState(0);
    const [openCompanyCategory, setOpenCompanyCategory] = React.useState(false);
    const [companyCategories, setCompanyCategories] = React.useState([]);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        setCompanyCategory(initialCompanyCategory && initialCompanyCategory.id !== null ? initialCompanyCategory : 0);
        setCompanyCategories(initialCompanyCategory && initialCompanyCategory.id !== null ? [initialCompanyCategory] : []);
        getAllCompanyCategories().then((res) => {
            setCompanyCategories(res.data);
        }).catch((error) => {
        })
    }, [initialCompanyCategory]);

    const handleInputChange = (e, fetchType) => {
        const value = e && e.target && e.target.value;
        setSearch(value);
    };

    const onCompanyCategorySelectionChange = (event, values) => {
        setCompanyCategory(values ? values : '');
        const selectedCompanyCategory = {
            companyCategory: values ? values : '',
        };
        getSelectedCompanyCategory(selectedCompanyCategory);

    };

    return (
        <div>
            <Autocomplete
                // set the width to the input length multiplied by the x height
                // it's not quite right but gets it close
                id="find-companyCategory"
                fullWidth
                open={openCompanyCategory}
                onOpen={() => {
                    setOpenCompanyCategory(true);
                }}
                onClose={() => {
                    setOpenCompanyCategory(false);
                }}
                openOnFocus={true}
                onInputChange={e => handleInputChange(e)}
                onChange={onCompanyCategorySelectionChange}
                value={companyCategories.find(v => v.id === companyCategory.id) || null}
                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                getOptionLabel={(option) => (option.name !== null ? option.name : '')}
                options={companyCategories !== null ? companyCategories : null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('CATEGORY')}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps
                        }}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                )}
            />
        </div>
    );
};
