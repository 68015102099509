import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { Link as RouterLink } from 'react-router-dom';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/deliveriesConfig';
import { TableDeliveriesFilter } from '../../components/tableDeliveriesFilter';
import useStyles from './styles';
import { urlParams } from "../../helpers/urlParams";
import { getDeliveriesList } from '../../services/deliveriesService';
import t from '../../helpers/languages';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const Deliveries = (props) => {
  const deliveriesInitObject = () => {
    return {
      port: { name: '', id: '' },
      category: { name: '', id: '' },
      vessel: { name: '', id: '' },
      id: '?',
      reference_number: '',
      supplier: { name: '', id: '' },
      buyer: { name: '', id: '' },
      deliveredItems: '',
    }
  };

  const filters = localStoreGetObj('deliveriesFilter');

  const [deliveriessState, setDeliveriesState] = useState({
    deliveries: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      deliveries_type: 'asc',
      deliveries_by: ''
    },
    filters: {},
    isLoading: true,
    deliveriesEditIndex: -1,
    deliveriesEditObject: deliveriesInitObject(),
    deliveriesCreateObject: null,
    error: null,
  });

  //const { userState } = props;
  const userState = props.userState && props.userState.user;


  const classes = useStyles();

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);


  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...deliveriessState.filters,
      port: filters && filters.port && filters.port.id,
      buyer: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...deliveriessState.filters,
      port: filters && filters.port && filters.port.id,
      supplier: filters && filters.company && filters.company.id,
      vessel: filters && filters.vessel && filters.vessel.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }

  useEffect(() => {
    getDeliveriesList(filtersData).then(res => {
      const { results } = res.data;
      setDeliveriesState({
        ...deliveriessState,
        deliveries: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {

      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
      });
    });
  // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    event.preventDefault();

    const isAsc = property===deliveriessState.page.deliveries_by&&deliveriessState.page.deliveries_type==='desc'?'asc':'desc';
   
    setDeliveriesState({
      ...deliveriessState,
      isLoading: true,
      deliveries_type: isAsc,
      deliveries_by: property,
    });

    filtersData.deliveries_type = isAsc;
    filtersData.deliveries_by = property;

    getDeliveriesList(filtersData).then(res => {
      const { results } = res.data;
      setDeliveriesState({
        ...deliveriessState,
        deliveries: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {

      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
      });
    })

  };


  const handleChangePage = (event, newPage) => {
    setDeliveriesState({
      ...deliveriessState,
      isLoading: true
    });
    const newCriteria = {
      ...deliveriessState.filters,
      per_page:deliveriessState.page.per_page,
      page: newPage + 1
    };

    getDeliveriesList(newCriteria).then(res => {
      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
        deliveries: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    }).catch((error) => {

      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setDeliveriesState({
      ...deliveriessState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...deliveriessState.filters,
      page: 1,
      per_page: perPage,
    };

    getDeliveriesList(newCriteria).then(res => {
      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
        deliveries: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {

      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
      });
    });
  };


  const filterData = (data) => {
    setDeliveriesState({
      ...deliveriessState,
      isLoading: true
    });

    let filtersData = '';

    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...deliveriessState.filters,
        buyer: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...deliveriessState.filters,
        supplier: data && data.company && data.company.id,
        port: data && data.port && data.port.id,
        vessel: data && data.vessel && data.vessel.id,
        category: data && data.category && data.category.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    }

    getDeliveriesList(filtersData).then(res => {
      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
        deliveries: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('deliveriesFilter', data);
    }).catch((error) => {

      setDeliveriesState({
        ...deliveriessState,
        isLoading: false,
      });
    });
  };

  const tableFilterComponent = () => {
    return <TableDeliveriesFilter
      companyField={true}
      searchByText={false}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"} // set buyer filter for supplier and vice versa.
      company={(filters && filters.company) ? filters.company : deliveriessState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : deliveriessState.filters.vessel}
      productCategory={(filters && filters.category) ? filters.category : deliveriessState.filters.category}
      port={(filters && filters.port) ? filters.port : deliveriessState.filters.port}
      from={(filters && filters.from) ? filters.from : deliveriessState.filters.from_date}
      to={(filters && filters.to) ? filters.to : deliveriessState.filters.to_date}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      deliveries={deliveriessState.page.deliveries_type}
      deliveriesBy={deliveriessState.page.deliveries_by}
      rowCount={deliveriessState.page.length} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {deliveriessState.deliveries && deliveriessState.deliveries.map((deliveries, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>
                <IconButton
                  className='table-more-info'
                  aria-label="expand row"
                  style={{ padding: '0px' }}
                  component={RouterLink}
                  to={{ pathname: `/transactions/${deliveries.transaction.id}/deliveries/${deliveries.id}` }}
                >
                  <ManageSearchIcon></ManageSearchIcon>
                </IconButton>
              </TableCell>
              <TableCell>{deliveries.reference_number?deliveries.reference_number:'N/A'}</TableCell>
              <TableCell>{(deliveries.buyer !== undefined && deliveries.supplier !== undefined) ? (userState.userRole === 'SUPPLIER' ? deliveries.buyer.name : deliveries.supplier.name) : ''}</TableCell>
              <TableCell>{deliveries.vessel.name}</TableCell>
              <TableCell>{deliveries.category&&deliveries.category.name}</TableCell>
              <TableCell>{deliveries.deliveredItems}</TableCell>
              <TableCell>{deliveries.port.name}</TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('DELIVERIES')}
        pagination={{
          total: deliveriessState.page.total || 0,
          page: deliveriessState.page.page - 1 || 0,
          perPage: deliveriessState.page.per_page || 10,
          count: deliveriessState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        onNewRowClick={() => { }}

        confirmDialog={{
          message: '',
          title: '',
          onCancel: () => { },
          onConfirm: () => { },
          isLoading: false,
          isOpen: false
        }}
        tableFilter={tableFilterComponent}
        autonomousPage={props.autonomousPage}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        isLoading={deliveriessState.isLoading}
      />
    </>
  );
};


export default Deliveries;