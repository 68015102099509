
const tableHeadersEquivalentProduct = [
  { id: 'code', disablePadding: false, label: 'Code' },
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'catalogue', disablePadding: false, label: 'Catalogue' },
  { id: 'company', disablePadding: false, label: 'Company' },
  { id: 'unresolved', disablePadding: false, label: 'Unresolved' },
  { id: 'vs', disablePadding: false, label: 'vs' },
  { id: 'dft', disablePadding: false, label: 'dft' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

export { tableHeadersEquivalentProduct };
