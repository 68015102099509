import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, Route } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import useStyles from './styles';
import { capitalize } from '@mui/material';
import BreadcrumbsActions from '../breadcrumbsActions/BreadcrumbsActions';

const LinkRouter = (props) => <Link {...props} component={RouterLink} underline="hover" />;

const SimpleBreadcrumbs = (props) => {
    const classes = useStyles();

    const handleDelete = (e) => {
        props.config.delete.callback(e);
    }

    const handleSync = (e) => {
        props.config.sync.callback(e);
    }

    const handleSubmit = (e) => {
        props.config.submit.callback(e);
    }

    const handleRevise = (e) => {
        props.config.revise.callback(e);
    }

    const handleQuote = (e) => {
        props.config.quote.callback(e);
    }

    const handleOrder = (e) => {
        props.config.order.callback(e);
    }

    const handleConfirm = (e) => {
        props.config.confirm.callback(e);
    }

    const handleAccept = (e) => {
        props.config.accept.callback(e);
    }

    const handleCancel = (e) => {
        props.config.cancel.callback(e);
    }

    const handleEvaluateItems = (e) => {
        props.config.evaluateItems.callback(e);
    }

    const handleEvaluateSupplier = (e) => {
        props.config.evaluateSupplier.callback(e);
    }

    const handleDecline = (e) => {
        props.config.decline.callback(e);
    }

    const handleCompare = (e) => {
        props.config.cοmpare.callback(e);
    }

    return (
        <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="row"
            spacing={3}
        >
            <Grid item xs={6}>
                <Route>
                    {({ location }) => {
                        const pathnames = location.pathname.split('/').filter(x => x);

                        return (
                            <Breadcrumbs className={classes.breadcrumbs +' '+'breadcrumb-info'} aria-label="Breadcrumb" separator={'>'}>
                                <LinkRouter color="inherit" to="/">
                                    Home
                                </LinkRouter>
                                {pathnames.map((value, index) => {
                                    const last = index === pathnames.length - 1;
                                    let to = `/${pathnames.slice(0, index + 1).join('/')}`;

                                    if (index === pathnames.length - 2&&!props.norfq) {
                                        to = `/${pathnames.slice(pathnames.length - 2, index + 1).join('/')}`;
                                    }
                                   
                                    return last ? (
                                        <Typography color="textPrimary" key={to}>
                                            {value}
                                        </Typography>
                                    ) : (
                                        <LinkRouter color="inherit" to={to} key={to}>
                                            {capitalize(value)}
                                        </LinkRouter>
                                    );
                                })}
                            </Breadcrumbs>
                        );
                    }}
                </Route>
            </Grid>
            {props.buttons &&
                <Grid item xs={6}
                    container
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <BreadcrumbsActions
                        config={{
                            revise: {
                                enabled: props.config.revise.enabled,
                                callback: (e) => handleRevise(e)
                            },
                            delete: {
                                enabled: props.config.delete.enabled,
                                callback: (e) => handleDelete(e)
                            },
                            sync: {
                                enabled: props.config.sync.enabled,
                                callback: (e) => handleSync(e)
                            },
                            submit: {
                                enabled: props.config.submit.enabled,
                                disabled: props.config.submit.disabled,
                                callback: (e) => handleSubmit(e)
                            },
                            quote: {
                                enabled: props.config.quote.enabled,
                                callback: (e) => handleQuote(e)
                            },
                            order: {
                                enabled: props.config.order?.enabled,
                                callback: (e) => handleOrder(e)
                            },
                            confirm: {
                                enabled: props.config.confirm?.enabled,
                                callback: (e) => handleConfirm(e)
                            },
                            accept: {
                                enabled: props.config.accept?.enabled,
                                callback: (e) => handleAccept(e)
                            },
                            cancel: {
                                enabled: props.config.cancel?.enabled,
                                callback: (e) => handleCancel(e)
                            },
                            evaluateItems: {
                                enabled: props.config.evaluateItems?.enabled,
                                callback: (e) => handleEvaluateItems(e)
                            },
                            evaluateSupplier: {
                                enabled: props.config.evaluateSupplier?.enabled,
                                callback: (e) => handleEvaluateSupplier(e)
                            },
                            decline: {
                                enabled: props.config.decline?.enabled,
                                callback: (e) => handleDecline(e)
                            },
                            compare: {
                                enabled: props.config.cοmpare?.enabled,
                                callback: (e) => handleCompare(e)
                            },
                        }}
                    ></BreadcrumbsActions>
                </Grid>
            }
        </Grid >
    )
}


export default SimpleBreadcrumbs;