import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import clsx from "clsx";
import Table from "@mui/material/Table";
import React from "react";
import TableActions from '../../components/TableActions/TableActions';

const PortMatchTable = ({ ports, onRowClick, selectedPorts, portsCategory, handleChangePage, handleChangeRowsPerPage,handleDeletePort }) => {

    const pcat = portsCategory;
   
    return (
        <>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="left">Country</TableCell>
                        {portsCategory === 'resolvedPort' ? <TableCell align="left">LoCode</TableCell> : <TableCell align="left">Action</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ports.ports.map((port, index) => {
                        return (
                            <TableRow key={port.id}
                                onClick={() => onRowClick(port, portsCategory, index)}
                                
                                className={clsx({
                                    'port-item': true,
                                    'port-item--selected': selectedPorts[portsCategory]?.id === port.id
                                })}
                            >
                                <TableCell>{port.name}</TableCell>
                                <TableCell>{port.country && port.country.name}</TableCell>
                                {portsCategory === 'resolvedPort' ? <TableCell>{port.loCode}</TableCell> : <TableCell><
                                    TableActions config={{
                                        edit: {
                                            enabled: false
                                        },
                                        delete: {
                                            enabled: true,
                                            callback: (e) => handleDeletePort(e, index)
                                        },
                                        close: {
                                            enabled: false
                                        },
                                        save: {
                                            enabled: false
                                        }
                                    }} /></TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={ports.page && ports.page.total}
                onPageChange={(event, newPage) => handleChangePage(event, newPage, pcat)}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, pcat)}
                page={ports.page && ports.page.page - 1}
                rowsPerPage={ports.page && ports.page.per_page}
                rowsPerPageOptions={[5, 10, 50]}
            />
        </>
    )
}

export default PortMatchTable