
const tableHeadersUnit = [
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'category', disablePadding: false, label: 'Category' },
  { id: 'quantity', disablePadding: false, label: 'Quantity' },
  { id: 'conversion', disablePadding: false, label: 'Conversion' },
  { id: 'subunit', disablePadding: false, label: 'SubUnit' },
  { id: 'notes', disablePadding: false, label: 'Notes' },
  { id: 'unresolved', disablePadding: false, label: 'Unresolved' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
];

// Empty product
const newUnit = () => {
  return {
    id: '?',
    name: '',
    category:  { name: '', id: '' },
    quantity: '',
    conversion: '',
    notes: '',
    subUnit: { name: '', id: '' },
    isUnresolved: false,
  }
};

export { tableHeadersUnit, newUnit };
