import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersPortDictionary, tableHeadersPortDictionaryGlobal, newPortDictionary } from '../../helpers/portsDictionaryConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterPorts from "../../components/filterPorts";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllPortDictionariesInfo, editPortDictionary, deletePortDictionary, addPortDictionary } from '../../services/portsDictionaryService';
import './_PortDictionaries.scss';
import { DictionaryTableFilter } from '../../components/dictionaryTableFilter';
import FilterCompanies from "../../components/filterCompanies";
import t from '../../helpers/languages';

const PortDictionaries = (props) => {

  const portDictionaryInitObject = () => {
    return {
      id: '?',
      company: { name: '', id: '' },
      altCode: null,
      portAlias: null,
      original: null
    }
  };

  const classes = useStyles();

  const filters = localStoreGetObj('portDictionaryFilter');

  const [portsDictionaryState, setportDictionariesState] = useState({
    portsDictionary: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    portDictionaryEditIndex: -1,
    portDictionaryEditObject: portDictionaryInitObject(),
    portDictionaryCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};


  if (props.autonomousPage) {
    filtersData = {
      ...portsDictionaryState.filters,
      companyId: filters && filters.company && filters.company.id,
      search: filters && filters.port,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: portsDictionaryState.page.order_type,
      order_by: portsDictionaryState.page.order_by,
    };
  }
  else {
    filtersData = {
      ...portsDictionaryState.filters,
      companyId: props.companyId,
      search: filters && filters.port,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: portsDictionaryState.page.order_type,
      order_by: portsDictionaryState.page.order_by,
    };
  }

  useEffect(function () {
    getAllPortDictionariesInfo(filtersData).then(res => {
      
      const { results } = res.data;
      setportDictionariesState({
        ...portsDictionaryState,
        portsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === portsDictionaryState.page.order_by && portsDictionaryState.page.order_type === 'desc' ? 'asc' : 'desc';

    setportDictionariesState({
      ...portsDictionaryState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllPortDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;
      setportDictionariesState({
        ...portsDictionaryState,
        portsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setportDictionariesState({
      ...portsDictionaryState,
      isLoading: true
    });
    const newCriteria = {
      ...portsDictionaryState.filters,
      per_page: portsDictionaryState.page.per_page,
      page: newPage + 1
    };
    getAllPortDictionariesInfo(newCriteria).then(res => {
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
        portsDictionary: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to port id gia mba member
    setportDictionariesState({
      ...portsDictionaryState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...portsDictionaryState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllPortDictionariesInfo(newCriteria).then(res => {
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
        portsDictionary: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
      });
    });
  };

  const handleEditPortDictionary = (event, portDictionaryIndex) => {

    event.stopPropagation();

    const portDictionaryEditObject = Object.assign({}, portsDictionaryState.portsDictionary[portDictionaryIndex]);


    setportDictionariesState({
      ...portsDictionaryState,
      portDictionaryEditIndex: portDictionaryIndex,
      portDictionaryEditObject: portDictionaryEditObject
    });

  };

  const onEditablePortDictionaryChange = (event, property) => {

    const newValue = event.target.value;

    const newEditPortDictionaryItem = Object.assign({}, portsDictionaryState.portDictionaryEditObject);
    newEditPortDictionaryItem[property] = newValue;

    setportDictionariesState({
      ...portsDictionaryState,
      portDictionaryEditObject: newEditPortDictionaryItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSavePortDictionary = (event, props) => {
    event.preventDefault();
    setportDictionariesState({
      ...portsDictionaryState,
      isLoading: true
    });

    const simpleCompanyUser = {
      id: props.companyId,
    }
    let createNull = {
      id: null,
    };
    if (portsDictionaryState.portDictionaryEditObject.company?.id !== ''&&portsDictionaryState.portDictionaryEditObject.company!=='') {
      createNull = portsDictionaryState.portDictionaryEditObject.company;
    }

    const newEditPortDictionaryItem = Object.assign({}, portsDictionaryState.portDictionaryEditObject);

    props.autonomousPage ? newEditPortDictionaryItem.company = createNull : newEditPortDictionaryItem.company = simpleCompanyUser;

    if (validator.current.allValid()) {
      editPortDictionary(newEditPortDictionaryItem.id, newEditPortDictionaryItem)
        .then(re => {
          const editedPortDictionary = re.data;
          setportDictionariesState(oldportDictionaries => {
            return {
              ...oldportDictionaries,
              portsDictionary: oldportDictionaries.portsDictionary.map(portDictionary => {
                return portDictionary.id === editedPortDictionary.id
                  ? re.data : portDictionary
              }),
              portDictionaryEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setportDictionariesState({
            ...portsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditPortDictionary = (e, index) => {
    setportDictionariesState({
      ...portsDictionaryState,
      portDictionaryEditIndex: -1
    })
  };

  const handleDeletePortDictionary = (event, index) => {

    const portDictionaryForDeletion = portsDictionaryState.portsDictionary[index];
    setportDictionariesState({
      ...portsDictionaryState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete PortDictionary',
        onCancel: () => {
          setportDictionariesState({
            ...portsDictionaryState,
            confirmDialog: {
              ...portsDictionaryState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deletePortDictionary(portDictionaryForDeletion.id).then(() => {
            const newportDictionaries = [...portsDictionaryState.portsDictionary];
            newportDictionaries.splice(index, 1);
            setportDictionariesState({
              ...portsDictionaryState,
              portsDictionary: newportDictionaries,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewPortDictionary = (e) => {
    e.preventDefault();

    const newPortDict = newPortDictionary();

    // newPortDict.company={id:props.companyId};

    setportDictionariesState({
      ...portsDictionaryState,
      portDictionaryCreateObject: newPortDict
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewPortDictionary = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newPortDictionary = {};

    const simpleCompanyUser = {
      id: props.companyId,
    }

    let createNull = null;
    if (portsDictionaryState.portDictionaryCreateObject.company|| !portsDictionaryState.portDictionaryCreateObject.company.length === 0 ) {
      createNull = portsDictionaryState.portDictionaryCreateObject.company;
    }

    props.autonomousPage ? newPortDictionary.company = createNull : newPortDictionary.company = simpleCompanyUser;
    
    newPortDictionary.altCode = portsDictionaryState.portDictionaryCreateObject.altCode;
    newPortDictionary.portAlias = portsDictionaryState.portDictionaryCreateObject.portAlias;
    newPortDictionary.original = portsDictionaryState.portDictionaryCreateObject.original;

    if (validator.current.allValid()) {
      addPortDictionary(newPortDictionary)
        .then(res => {
          setportDictionariesState({
            ...portsDictionaryState,
            portsDictionary: [...portsDictionaryState.portsDictionary, res.data],
            portDictionaryCreateObject: null,
            portDictionaryEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setportDictionariesState({
            ...portsDictionaryState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewPortDictionaryObject = (e, property) => {
    const newValue = e.target.value;

    const newEditPortDictionaryItem = Object.assign({}, portsDictionaryState.portDictionaryCreateObject);
    newEditPortDictionaryItem[property] = newValue;

    setportDictionariesState({
      ...portsDictionaryState,
      portDictionaryCreateObject: newEditPortDictionaryItem
    });
  };

  const filterData = (data) => {
    setportDictionariesState({
      ...portsDictionaryState,
      isLoading: true
    });

    let filtersData = '';
    if (props.autonomousPage) {
      filtersData = {
        ...portsDictionaryState.filters,
        search: data && data.searchText,
        companyId: data && data.company && data.company.id,
        per_page: criteria.per_page,
        page: 1,
      };
    } else {
      filtersData = {
        ...portsDictionaryState.filters,
        search: data && data.searchText,
        companyId: props.companyId,
        per_page: criteria.per_page,
        page: 1,
      };
    }

    getAllPortDictionariesInfo(filtersData).then(res => {
      const { results } = res.data;

      setportDictionariesState({
        ...portsDictionaryState,
        portsDictionary: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('portDictionaryFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setportDictionariesState({
        ...portsDictionaryState,
        isLoading: false,
      });
    });

  };

  const selectedCompany = (data, action) => {
    const newEditPortDictionaryItem = Object.assign({},
      action === 'edit'
        ? portsDictionaryState.portDictionaryEditObject
        : portsDictionaryState.portDictionaryCreateObject
    );
    newEditPortDictionaryItem.company = data.company;
    setportDictionariesState({
      ...portsDictionaryState,
      [
        action === 'edit'
          ? 'portDictionaryEditObject'
          : 'portDictionaryCreateObject'
      ]: newEditPortDictionaryItem
    });
  };

  const selectedOriginal = (data, action) => {

    const newEditPortDictionaryItem = Object.assign({},
      action === 'edit'
        ? portsDictionaryState.portDictionaryEditObject
        : portsDictionaryState.portDictionaryCreateObject
    );

    newEditPortDictionaryItem.original = data.port;


    setportDictionariesState({
      ...portsDictionaryState,
      [
        action === 'edit'
          ? 'portDictionaryEditObject'
          : 'portDictionaryCreateObject'
      ]: newEditPortDictionaryItem
    });
  };

  const tableFilterComponent = () => {
    return <DictionaryTableFilter
    searchText={(filters && filters.port) ? filters.port : portsDictionaryState.filters.port}
    company={(filters && filters.company) ? filters.company : portsDictionaryState.filters.company}
    autonomousPage={props.autonomousPage}
      getFilterData={filterData}
      filterLabel={'Port'}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.autonomousPage ? tableHeadersPortDictionaryGlobal : tableHeadersPortDictionary}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(portsDictionaryState.portsDictionary) && portsDictionaryState.portsDictionary.map((portDictionary, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (portsDictionaryState.portDictionaryEditIndex !== index) && index !== '?'
                  ? <>
                  {props.autonomousPage && <TableCell>{portDictionary.company?.name}</TableCell>}
                    <TableCell>{portDictionary.portAlias}</TableCell>
                    <TableCell>{portDictionary.altCode}</TableCell>
                    <TableCell>{portDictionary.original?.name}</TableCell>
                  </> :
                  <>
                  {props.autonomousPage && (
                      <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={portsDictionaryState.portDictionaryEditObject.company || null}
                            getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                            buyer={true}
                            errorMessage={null}
                          />
                        </FormControl>
                      </TableCell>
                    )
                    }
                  <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsDictionaryState.portDictionaryEditObject.portAlias || ''}
                          onChange={e => onEditablePortDictionaryChange(e, 'portAlias')}
                          onBlur={() => validator.current.showMessageFor('portAlias')}
                        />{validator.current.message('portAlias', portsDictionaryState.portDictionaryEditObject.portAlias, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={portsDictionaryState.portDictionaryEditObject.altCode || ''}
                          onChange={e => onEditablePortDictionaryChange(e, 'altCode')}
                          onBlur={() => validator.current.showMessageFor('altCode')}
                        />{validator.current.message('altCode', portsDictionaryState.portDictionaryEditObject.altCode, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterPorts
                          port={portsDictionaryState.portDictionaryEditObject.original || null}
                          getSelectedPort={(data) => selectedOriginal(data, 'edit')}
                          errorMessage={null}
                        />
                        {validator.current.message('original', portsDictionaryState.portDictionaryEditObject.original, 'required')}
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: portsDictionaryState.portDictionaryEditIndex !== index,
                    callback: (e) => handleEditPortDictionary(e, index)
                  },
                  delete: {
                    enabled: portsDictionaryState.portDictionaryEditIndex !== index,
                    callback: (e) => handleDeletePortDictionary(e, index)
                  },
                  close: {
                    enabled: portsDictionaryState.portDictionaryEditIndex === index,
                    callback: (e) => handleCloseEditPortDictionary(e, index)
                  },
                  save: {
                    enabled: portsDictionaryState.portDictionaryEditIndex === index,
                    callback: (e) => handleSavePortDictionary(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          portsDictionaryState.portDictionaryCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
          {props.autonomousPage && (
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={portsDictionaryState.portDictionaryCreateObject.company || null}
                    getSelectedCompany={(data) => selectedCompany(data, 'create')}
                    buyer={true}
                    errorMessage={null}
                  />
                </FormControl>
              </TableCell>
            )}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsDictionaryState.portDictionaryCreateObject.portAlias || ''}
                  onChange={e => onEditNewPortDictionaryObject(e, 'portAlias')}
                  onBlur={() => validator.current.showMessageFor('portAlias')}
                />{validator.current.message('portAlias', portsDictionaryState.portDictionaryCreateObject.portAlias, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={portsDictionaryState.portDictionaryCreateObject.altCode || ''}
                  onChange={e => onEditNewPortDictionaryObject(e, 'altCode')}
                  onBlur={() => validator.current.showMessageFor('altCode')}
                />{validator.current.message('altCode', portsDictionaryState.portDictionaryCreateObject.altCode, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <FilterPorts
                  port={portsDictionaryState.portDictionaryCreateObject.original}
                  getSelectedPort={(data) => selectedOriginal(data, 'create')}
                  errorMessage={null}
                />
                {validator.current.message('original', portsDictionaryState.portDictionaryCreateObject.original, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setportDictionariesState({ ...portsDictionaryState, portDictionaryCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewPortDictionary()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('PORT_DICTIONARIES')}
        pagination={{
          total: portsDictionaryState.page.total || 0,
          page: portsDictionaryState.page.page - 1 || 0,
          perPage: portsDictionaryState.page.per_page || 10,
          count: portsDictionaryState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewPortDictionary}
        newRowBtnEnabled={!portsDictionaryState.portDictionaryCreateObject}
        confirmDialog={portsDictionaryState.confirmDialog.message.length > 0 ? portsDictionaryState.confirmDialog : portsDictionaryState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={portsDictionaryState.isLoading}
      />
    </>
  );
};


export default PortDictionaries;
