import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

import t from '../../../helpers/languages';

const ConfirmationDialog = (props) => {
  const { open, handleCancel, handleOk, confirmationMessage, ariaLabelledBy, dialogTitle, confirm = 'OK', cancel = 'CANCEL' } = props;


  return (
    <Dialog
      aria-labelledby={ariaLabelledBy}
      onClick={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          // Set 'open' to false, however you would do that with your particular code.
          return false;
        }
      }
      }
      disableEscapeKeyDown
      maxWidth='sm'
      open={open}
    >
      <>
        <DialogTitle id={ariaLabelledBy}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {confirmationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleCancel}>
            {t(cancel)}
          </Button>
          <Button color='primary' onClick={handleOk}>
            {t(confirm)}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
};

export default ConfirmationDialog;
