import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';

// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Grid, TextField } from "@mui/material";
import './_filterByText.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const FilterByText = ({ searchText: initialSearchText, getFilterData }) => {
  const classes = useStyles();
 
  const [searchText, setSearchText] = useState(initialSearchText||'');

  const handleChange = (event, property ) => {

    setSearchText(event.target.value);

    const filterData = {
      searchText: event.target.value
    };

    getFilterData(filterData);
  };


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <form >
        <FormControl variant="outlined">
          <TextField
            style={{width:'200px'}}
            value={searchText}
            variant="outlined" className={classes.formControl}
            onChange={e => handleChange(e, 'search')}
            name="search"
          />
        </FormControl>
      
      </form>

    </Grid>
  );
}