import { useCallback, useEffect, useState, useRef } from "react";
import { getAllPortsInfo, matchPorts, editPort, deletePort } from "../../services/portsService";
import SimpleReactValidator from 'simple-react-validator';
import { debounce } from "lodash";
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import userService from "../../services/userService";

const usePortMatchHook = () => {

    const portInitObject = () => {
        return {
            id: '?',
            name: '',
            country: { name: '', id: '' },
            latitude: '',
            longitude: '',
            timezone: '',
            loCode: '',
            isUnresolved: false,
        }
    };

    const [unresolvedPorts, setUnresolvedPorts] = useState({
        ports: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        portEditIndex: -1,
        portEditObject: portInitObject(),
        error: null
    })

    const [resolvedPorts, setResolvedPorts] = useState({
        ports: [],
        page: {
            page: 1,
            count: 0,
            per_page: 10,
            total: 0,
            order_type: 'asc',
            order_by: ''
        },
        isLoading: true,
        error: null,
    })

    const filtersMatch = localStoreGetObj('portMatchFilter');
    const ref=useRef(null);
    const [filters, setFilters] = useState({
        unresolved: {
            country: filtersMatch?.unresolved&&filtersMatch.unresolved.countrya&&filtersMatch.unresolved.countrya.id,
            countrya:filtersMatch?.unresolved&&filtersMatch.unresolved.countrya,
            isUnresolved: true,
            isPricelist:filtersMatch?.unresolved.isPricelist,
            search: filtersMatch?.unresolved.search,
            page: filtersMatch?.unresolved&&filtersMatch.unresolved.page,
        },
        resolved: {
            country: filtersMatch?.resolved&&filtersMatch.resolved.countrya&&filtersMatch.resolved.countrya.id,
            countrya:filtersMatch?.resolved&&filtersMatch.resolved.countrya,
            isUnresolved: 'false',
            search: filtersMatch?.resolved.search,
            page: filtersMatch?.resolved&&filtersMatch.resolved.page,
        }
    })
    const [selectedPorts, setSelectedPorts] = useState({
        unresolvedPort: null,
        resolvedPort: null,
    })

    const [confirmDialogDeleteOpen, setConfirmDialogDeleteOpen] = useState({
        message: '',
        title: '',
        onCancel: () => { },
        onConfirm: () => { },
        isLoading: false,
        isOpen: false
    });

    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

    useEffect(() => {
        getAllPortsInfo(filters.unresolved).then(res => {
            const results = res.data.results;
            setUnresolvedPorts({
                ...unresolvedPorts,
                ports: results,
                page: res.data.page,
            });
        });
        getAllPortsInfo(filters.resolved).then(res => {
            const results = res.data.results;
            setResolvedPorts({
                ...resolvedPorts,
                ports: results,
                page: res.data.page,
            });
        });
    },
        // eslint-disable-next-line  
        []);

    const loadPortsByCategory = (category, filters, countrya) => {
 
        getAllPortsInfo(filters[category]).then(res => {
            const results = res.data.results;
            if (category === 'unresolved') {
                setUnresolvedPorts({
                    ...unresolvedPorts,
                    ports: results,
                    page: res.data.page,
                    portEditIndex: -1
                })
                setSelectedPorts(prevSelectedPorts => {
                    return {
                        ...prevSelectedPorts,
                        ['unresolvedPort']: null
                    }
                })
               
            } else {
                setResolvedPorts({
                    ...resolvedPorts,
                    ports: results,
                    page: res.data.page,
                })
                setSelectedPorts(prevSelectedPorts => {
                    return {
                        ...prevSelectedPorts,
                        ['resolvedPort']: null
                    }
                })
                
            }
        });     

        const cat = category === 'unresolved' ? 'unresolved' : 'resolved';

        const newFiltersCountry = {
            ...filters,
            [cat]: {
                ...filters[cat],
                countrya,
            }
        }

        localStoreSetObj('portMatchFilter', newFiltersCountry);
       
    }

    const onFilterPortCountryChange = (e, category) => {
        const country = e.country.id
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                country,
                page: 1
            }
        }
        setFilters(newFilters);
       
        loadPortsByCategory(category, newFilters,e.country);
    }

    const onPortClick = (port, category, index) => {

        if (category === 'unresolvedPort') {
            const portEditObject = Object.assign({}, unresolvedPorts.ports[index]);
            setUnresolvedPorts({
                ...unresolvedPorts,
                portEditIndex: index,
                portEditObject: portEditObject,
                
            });
    
            const input =  ref.current;
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value").set
            nativeInputValueSetter.call(input, portEditObject.name.substring(0, 4));
            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
            }
    setSelectedPorts(prevSelectedPorts => {
        return {
            ...prevSelectedPorts,
            [category]: port
        }
    })
    }
    const debounceLoadData = useCallback(debounce(loadPortsByCategory, 500), []);
    const onSearchTextChange = (e, category) => {
        const newSearchValue = e.target.value;
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                search: newSearchValue,
                page: 1
            }
        }
        setFilters(newFilters);

        category!=='resolved'&&setUnresolvedPorts({
            ...unresolvedPorts,
            portEditIndex: -1
        });

       
        debounceLoadData(category, newFilters);
    }
    const onMatchBtnClick = () => {
        setConfirmDialogOpen(true);
    }

    const onPortMatchConfirm = () => {
        matchPorts({
            id: selectedPorts.unresolvedPort.id,
            matchId: selectedPorts.resolvedPort.id
        })
            .then(res => {
                const newFilters = {
                    unresolved: {
                        country: filters.unresolved.country,
                        country: filters.unresolved.countrya,
                        isUnresolved: true,
                        search: filters.unresolved.search,
                        page:unresolvedPorts.page.page
                    },
                    resolved: {
                        country: filters.resolved.country,
                        country: filters.resolved.countrya,
                        isUnresolved: 'false',
                        search: filters.resolved.search,
                        page:resolvedPorts.page.page
                    }
                }
                setFilters(newFilters)
                loadPortsByCategory('unresolved', newFilters)
                loadPortsByCategory('resolved', newFilters)
                setConfirmDialogOpen(false);
            })
    }

    const [, forceUpdate] = useState();
    //todo
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));

    const onEditablePortChange = (event, property) => {
        let newValue = '';
        if (property === "isUnresolved") {
            newValue = event.target.checked ? true : false;
        }
        else {
            newValue = event.target.value;
        }
        const newEditPortItem = Object.assign({}, unresolvedPorts.portEditObject);
        newEditPortItem[property] = newValue;

        setUnresolvedPorts({
            ...unresolvedPorts,
            portEditObject: newEditPortItem
        });
    };
    const handleSavePort = (event, props) => {
        event.preventDefault();
        setUnresolvedPorts({
            ...unresolvedPorts,
            isLoading: true
        });
        const newEditPortItem = Object.assign({}, unresolvedPorts.portEditObject);

        if (validator.current.allValid()) {
            editPort(newEditPortItem.id, newEditPortItem)
                .then(re => {
                    const editedPort = re.data;
                    if (!re.data.isUnresolved) {
                        const newUnresolvedPorts = [...unresolvedPorts.ports];
                        newUnresolvedPorts.splice(unresolvedPorts.portEditIndex, 1);
                        setUnresolvedPorts({
                            ...unresolvedPorts,
                            ports: newUnresolvedPorts,
                            portEditIndex: -1
                        });
                    }
                    else {
                        setUnresolvedPorts(oldPorts => {
                            return {
                                ...oldPorts,
                                ports: oldPorts.ports.map(port => {
                                    return port.id === editedPort.id
                                        ? re.data : port
                                }),
                                portEditIndex: -1
                            }
                        });
                    }
                }).catch((error) => {
                    setUnresolvedPorts({
                        ...unresolvedPorts,
                        isLoading: false,
                    });
                });
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
            setUnresolvedPorts({
                ...unresolvedPorts,
                isLoading: false,
            });
        }

    };

    const handleCloseEditPort = (e, index) => {
        setSelectedPorts(prevSelectedPorts => {
            return {
                ...prevSelectedPorts,
                unresolvedPort: null
            }
        })
        setUnresolvedPorts({
            ...unresolvedPorts,
            portEditIndex: -1
        })
    };

    const selectedPortCountry = (data, action) => {

        const newEditPortItem = Object.assign({}, unresolvedPorts.portEditObject);
        newEditPortItem.country = data.country;

        setUnresolvedPorts({
            ...unresolvedPorts,
            portEditObject: newEditPortItem
        });
    };

    const handleDeletePort = (event, index) => {

  
        const portForDeletion = unresolvedPorts.ports[index];

        setConfirmDialogDeleteOpen({
            ...confirmDialogDeleteOpen,
            message: 'Are you sure you want to delete?',
            title: 'Delete Port',
            onCancel: () => {
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                })
            },
            onConfirm: () => {
              deletePort(portForDeletion.id).then(() => {
                const newPorts = [...unresolvedPorts.ports];
                newPorts.splice(index, 1);
                setUnresolvedPorts({
                    ...unresolvedPorts,
                  ports: newPorts,
                });
                setConfirmDialogDeleteOpen({
                    ...confirmDialogDeleteOpen,
                    isOpen: false
                });
              })
                .catch(err => {
                  alert('ERROR')
                });
            },
            isOpen: true
          });

      };

    const handleChangePage = (e, newPage, category) => {
        let filterCategory = '';
        const np = newPage;
        if (category === 'unresolvedPort') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: np + 1
            },
        }
        setFilters(newFilters);
        setUnresolvedPorts({
            ...unresolvedPorts,
            portEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    const handleChangeRowsPerPage = (e, category) => {


        let filterCategory = '';
        const np = e.target.value;
        if (category === 'unresolvedPort') {
            filterCategory = 'unresolved';
        } else {
            filterCategory = 'resolved';
        }

        const newFilters = {
            ...filters,
            [filterCategory]: {
                ...filters[filterCategory],
                page: 1,
                per_page: np
            },
        }
        setFilters(newFilters);
        setUnresolvedPorts({
            ...unresolvedPorts,
            portEditIndex: -1
        });
        debounceLoadData(filterCategory, newFilters);
    };

    const onIsPricelistChange = (e, category) => {
        const value = e.target.checked 
        const newFilters = {
            ...filters,
            [category]: {
                ...filters[category],
                isPricelist:value,
                page: 1
            }
        }
        setFilters(newFilters);
       
        loadPortsByCategory(category, newFilters);
    }

    return {
        unresolvedPorts,
        setUnresolvedPorts,
        isConfirmDialogOpen,
        resolvedPorts,
        setResolvedPorts,
        filters,
        setFilters,
        selectedPorts,
        setSelectedPorts,
        loadPortsByCategory,
        onFilterPortCountryChange,
        onMatchBtnClick,
        onPortClick,
        onSearchTextChange,
        onIsPricelistChange,
        setConfirmDialogOpen,
        onPortMatchConfirm,
        handleDeletePort,
        validator,
        confirmDialogDeleteOpen,
        onEditablePortChange,
        handleSavePort,
        handleChangePage,
        handleChangeRowsPerPage,
        handleCloseEditPort,
        ref,
        selectedPortCountry
    }
}

export default usePortMatchHook