import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import { tableHeadersCountry } from '../../helpers/countriesConfig';
import TableHeader from '../../components/tableHeader';
import TableActions from '../../components/TableActions/TableActions';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from './EditRowStyles';

const tableHeaderComponent = () => {
    return <TableHeader
        headCells={tableHeadersCountry}
        onSelectAllClick={() => { }}
        sort={false}
        order={''}
        orderBy={''}
        rowCount={''} />;
};

const EditRow = ({ validator,
    onEditableCountryChange,
    handleSaveCountry,
    handleCloseEditCountry,
    unresolvedCountries }) => {
    const classes = useStyles();
    return (
        <Table stickyHeader>
            {tableHeaderComponent()}
            <TableBody>
                <TableRow className={classes.tableRow}>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedCountries.countryEditObject.name || ''}
                                onChange={e => onEditableCountryChange(e, 'name')}
                                onBlur={() => validator.current.showMessageFor('name')}
                            />{validator.current.message('name', unresolvedCountries.countryEditObject.name, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={unresolvedCountries.countryEditObject.code || ''}
                                onChange={e => onEditableCountryChange(e, 'code')}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                variant="outlined"
                                control={
                                    <Switch
                                        checked={unresolvedCountries.countryEditObject.isUnresolved}
                                        onChange={e => onEditableCountryChange(e, 'isUnresolved')}
                                        name=" isUnresolved"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                }
                                label="IsUnresolved"
                            /></FormControl>
                    </TableCell>
                    <TableCell>
                        <TableActions config={{
                            edit: {
                                enabled: false,
                                callback: () => null
                            },
                            delete: {
                                enabled: false,
                                callback: () => null
                            },
                            close: {
                                enabled: true,
                                callback: (e) => handleCloseEditCountry(e, unresolvedCountries.countryEditIndex)
                            },
                            save: {
                                enabled: true,
                                callback: (e) => handleSaveCountry(e)
                            }
                        }} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

}

export { EditRow };