import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import { tableHeadersPort } from '../../helpers/portConfig';
import TableHeader from '../../components/tableHeader';
import TableActions from '../../components/TableActions/TableActions';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterPortCountries from '../../components/filterPortCountries';
import useStyles from './EditRowStyles';

const tableHeaderComponent = () => {
    return <TableHeader
        headCells={tableHeadersPort}
        onSelectAllClick={() => { }}
        sort={false}
        order={''}
        orderBy={''}
        rowCount={''} />;
};

const EditRow = ({ validator,
    onEditablePortChange,
    handleSavePort,
    handleCloseEditPort,
    unresolvedPorts,
    selectedPortCountry }) => {
    const classes = useStyles();
    return (
        <Table stickyHeader>
            {tableHeaderComponent()}
            <TableBody>
                <TableRow className={classes.tableRow}>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedPorts.portEditObject.name || ''}
                                onChange={e => onEditablePortChange(e, 'name')}
                                onBlur={() => validator.current.showMessageFor('name')}
                            />{validator.current.message('name', unresolvedPorts.portEditObject.name, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FilterPortCountries
                                country={unresolvedPorts.portEditObject.country}
                                getSelectedPortCountry={(data) => selectedPortCountry(data, 'edit')}
                            />{validator.current.message('country', unresolvedPorts.portEditObject.country, 'required')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedPorts.portEditObject.latitude || ''}
                                onChange={e => onEditablePortChange(e, 'latitude')}
                                onBlur={() => validator.current.showMessageFor('latitude')}
                            />{validator.current.message('latitude', unresolvedPorts.portEditObject.latitude, 'numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedPorts.portEditObject.longitude || ''}
                                onChange={e => onEditablePortChange(e, 'longitude')}
                                onBlur={() => validator.current.showMessageFor('longitude')}
                            />{validator.current.message('longitude', unresolvedPorts.portEditObject.longitude, 'numeric')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedPorts.portEditObject.timezone || ''}
                                onChange={e => onEditablePortChange(e, 'timezone')}
                                onBlur={() => validator.current.showMessageFor('timezone')}
                            />{validator.current.message('timezone', unresolvedPorts.portEditObject.timezone, 'max:10')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                variant="outlined"
                                value={unresolvedPorts.portEditObject.loCode || ''}
                                onChange={e => onEditablePortChange(e, 'loCode')}
                                onBlur={() => validator.current.showMessageFor('loCode')}
                            />{validator.current.message('loCode', unresolvedPorts.portEditObject.loCode, 'max:10')}
                        </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                variant="outlined"
                                control={
                                    <Switch
                                        checked={unresolvedPorts.portEditObject.isUnresolved}
                                        onChange={e => onEditablePortChange(e, 'isUnresolved')}
                                        name=" isUnresolved"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                }
                                label="IsUnresolved"
                            /></FormControl>
                    </TableCell>
                    <TableCell>
                        <TableActions config={{
                            edit: {
                                enabled: false,
                                callback: () => null
                            },
                            delete: {
                                enabled: false,
                                callback: () => null
                            },
                            close: {
                                enabled: true,
                                callback: (e) => handleCloseEditPort(e, unresolvedPorts.portEditIndex)
                            },
                            save: {
                                enabled: true,
                                callback: (e) => handleSavePort(e)
                            }
                        }} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

}

export { EditRow };