
import { useEffect, useState } from "react";
import userService from "../../services/userService";

const useAccountPreference = (filtersData) => {

    const prefInitObject = () => {
        return {
            id: null,
            company: null,
            value: null,
            preference: null,
        }
    };

    const [prefsState, setPrefsState] = useState({
        isLoading: false,
        items: [],
        prefEditObject: prefInitObject(),
        prefCreateObject: null,
        //todo make it global with custom events
        confirmDialog: {
            message: '',
            title: '',
            onCancel: () => { },
            onConfirm: () => { },
            isLoading: false,
            isOpen: false
        },
        error: null,
        isPreferenceSaved: { null: true }
    });
    
    useEffect(() => {

        userService.getUserPreferences(filtersData).then(res => {
            const results = res.data;

            setPrefsState({
                ...prefsState,
                isLoading: false,
                items: results
            });
        }).catch((error) => {
            setPrefsState({
                ...prefsState,
                isLoading: false,
            })

        });
        // eslint-disable-next-line  
    }, []);

    const getPrefVal = (data, pos) => {

        if (prefsState.items[pos].id) {
            const prefEditObject = Object.assign({}, prefsState.items[pos]);

            if (data !== undefined && data !== null && data.notes !== '') {
                prefEditObject.value = data.notes;
            } else {
                prefEditObject.value = null;
            }


            userService.editUserPreference(filtersData, prefEditObject)
                .then(res => {
                    const results = res.data;

                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        items: [...(prefsState.items[pos] = results)],
                        isNotesSaved: {
                            ...prefsState.isNotesSaved,
                            [pos]: true
                        },
                    });
                }).catch((error) => {
                    // dispatch(messagesActions.openSnackbar({
                    //   variant: 'error ',
                    //   message: ' '  error,
                    // }));
                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        isNotesSaved: {
                            ...prefsState.isNotesSaved,
                            [pos]: false
                        },
                    });
                });
        } else {
            const prefCreateObject = Object.assign({}, prefsState.items[pos]);
            if (data !== undefined && data !== null && data.notes !== '') {
                prefCreateObject.value = data.notes;
            } else {
                prefCreateObject.value = null;
            }

            const newPref = {};
            newPref.id = filtersData.id;
            newPref.preference = prefCreateObject;

            userService.addUserPreference(filtersData, prefCreateObject)
                .then(res => {
                    const results = res.data;

                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        items: [...(prefsState.items[pos] = results)],
                        isNotesSaved: {
                            ...prefsState.isNotesSaved,
                            [pos]: true
                        },
                    });

                }).catch((error) => {
                    // dispatch(messagesActions.openSnackbar({
                    //   variant: 'error ',
                    //   message: ' '  error,
                    // }));
                    setPrefsState({
                        ...prefsState,
                        isLoading: false,
                        isNotesSaved: {
                            ...prefsState.isNotesSaved,
                            [pos]: false
                        },
                    });
                });
        }
    };


    return {
        prefsState,
        getPrefVal
    }



}
export default useAccountPreference;


