import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import t from '../../helpers/languages';
import useStyles from './styles';
import { EventBus } from "../../services/eventBus";
import { getSystemVersion } from "../../services/systemVersionService";
import ConfirmationDialog from "../../components/modals/confirmation-dialog";

const StyledMenu = (props) => {
  const classes = useStyles();

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={classes.menu}
      // getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  );
};

const UserMenu = ({ id, anchorEl, open, close, ...props }) => {
  const classes = useStyles();

  //const dispatch = useDispatch();
  const [version, setVersion] = useState(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const about = () => {
    getSystemVersion().then(res => {
      setVersion(res.data);
      setConfirmDialogOpen(true);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
    }).finally(() => {

    });


  };
  const logoutUser = () => {
    EventBus.publish('user-state-change', {
      detail: {
        ...props.userState,
        accessToken: null
      }
    })
  };

  return (<>
    <StyledMenu
      anchorEl={anchorEl}
      id={id}
      keepMounted
      onClose={close}
      open={open}
    >
      <MenuItem onClick={logoutUser}>
        <ExitToAppIcon className={classes.logoutIcon} />
        <span className={classes.menuItem}>{t('LOGOUT')}</span>
      </MenuItem>
      <MenuItem onClick={about}>
        <InfoIcon className={classes.logoutIcon} />
        <span className={classes.menuItem}>{'About'}</span>
      </MenuItem>
    </StyledMenu>
    <ConfirmationDialog
      ariaLabelledBy={''}
      confirmationMessage={`Runtime Version: ${version}
          Powered by: Sea-Beyond
          Copyright 2022-2023`                             }
      dialogTitle={'About'}
      handleCancel={() => setConfirmDialogOpen(false)}
      handleOk={() => setConfirmDialogOpen(false)}
      open={isConfirmDialogOpen}
    /></>
  );
};

export default UserMenu;
