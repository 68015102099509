import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import React, { useState, useRef, useEffect } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { Link as RouterLink } from "react-router-dom";
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj } from '../../helpers/storage';
import { tableHeadersUser, newUser } from '../../helpers/usersConfig.js';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import userService from '../../services/userService';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SearchByTextTableFilter } from '../../components/searchByTextTableFilter';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import './_users.scss';
import t from '../../helpers/languages';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const Users = (props) => {

  const userInitObject = () => {
    return {
      id: '?',
      firstName: null,
      lastName: null,
      userName: null,
      email: null,
      companyId: null,
      password: null,
      joined: null,
      isActive: true,
      isSuperUser: false
    }
  };

  const classes = useStyles();

  //const filters = localStoreGetObj('userFilter');

  const [usersState, setUsersState] = useState({
    users: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: false,
    userEditIndex: -1,
    userEditObject: userInitObject(),
    userCreateObject: null,
    editShowPassword: false,
    createShowPassword: false,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const pathName = window.location.pathname;
  const regex = /(\d+)/gm;
  let m;

  m = regex.exec(pathName);


  const companyId = m[0];


  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)
  criteria.companyId = companyId;

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};


  filtersData = {
    ...usersState.filters,
    per_page: criteria.per_page,
    page: criteria.page,
  };

  useEffect(() => {

    setUsersState({
      ...usersState,
      isLoading: true
    });

    userService.getUsersByCompanyId(criteria).then(res => {
      const { results } = res.data;
      setUsersState({
        ...usersState,
        users: results,
        page: res.data.page,
        filters: res.data.filters,
        isLoading: false
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUsersState({
        ...usersState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === usersState.page.order_by && usersState.page.order_type === 'desc' ? 'asc' : 'desc';

    setUsersState({
      ...usersState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    userService.getUsersByCompanyId(filtersData).then(res => {
      const { results } = res.data;
      setUsersState({
        ...usersState,
        users: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUsersState({
        ...usersState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setUsersState({
      ...usersState,
      isLoading: true
    });
    const newCriteria = {
      ...usersState.filters,
      companyId: companyId,
      per_page: usersState.page.per_page,
      page: newPage + 1

    };


    userService.getUsersByCompanyId(newCriteria).then(res => {
      setUsersState({
        ...usersState,
        isLoading: false,
        users: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to user id gia mba member
    setUsersState({
      ...usersState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...usersState.filters,
      companyId: companyId,
      page: 1,
      per_page: perPage,
    };

    userService.getUsersByCompanyId(newCriteria).then(res => {
      setUsersState({
        ...usersState,
        isLoading: false,
        users: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUsersState({
        ...usersState,
        isLoading: false,
      });
    });
  };

  const handleEditUser = (event, userIndex) => {

    event.stopPropagation();
    const userEditObject = Object.assign({}, usersState.users[userIndex]);


    setUsersState({
      ...usersState,
      userEditIndex: userIndex,
      userEditObject: userEditObject
    });

  };

  const onEditableUserChange = (e, property) => {
    let newValue = '';
    if (property === 'isActive' || property === 'isSuperUser') {
      newValue = e.target.checked;
    } else {
      newValue = e.target.value;
    }


    const newEditUserItem = Object.assign({}, usersState.userEditObject);
    newEditUserItem[property] = newValue;

    setUsersState({
      ...usersState,
      userEditObject: newEditUserItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({
    validators: {
      customEmail: {  // name the rule
        message: 'The :attribute must be a valid IP address and must be :values.',
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) && params.indexOf(val) === -1;
        },
        messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
        required: true  // optional
      }
    },
    autoForceUpdate: { forceUpdate: forceUpdate },
    showMessages: true
  }));

  const filterData = (data) => {
    setUsersState({
      ...usersState,
      isLoading: true
    });

    let filtersData = '';

    filtersData = {
      ...usersState.filters,
      companyId: companyId,
      search: data && data.searchText
    };

    userService.getUsersByCompanyId(filtersData).then(res => {
      const { results } = res.data;

      setUsersState({
        ...usersState,
        users: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('userFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setUsersState({
        ...usersState,
        isLoading: false,
      });
    });

  };

  const handleSaveUser = (event, props) => {
    event.preventDefault();
    setUsersState({
      ...usersState,
      isLoading: true
    });
    const newEditUserItem = Object.assign({}, usersState.userEditObject);

    if (validator.current.allValid()) {
      userService.editUser(newEditUserItem.id, newEditUserItem)
        .then(re => {
          const editedUser = re.data;
          setUsersState(oldUsers => {
            return {
              ...oldUsers,
              users: oldUsers.users.map(user => {
                return user.id === editedUser.id
                  ? re.data : user
              }),
              userEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setUsersState({
            ...usersState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setUsersState({
        ...usersState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditUser = (e, index) => {
    setUsersState({
      ...usersState,
      userEditIndex: -1
    })
  };

  const handleDeleteUser = (event, index) => {

    const userForDeletion = usersState.users[index];
    setUsersState({
      ...usersState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete AccountSettings',
        onCancel: () => {
          setUsersState({
            ...usersState,
            confirmDialog: {
              ...usersState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          userService.deleteUser(userForDeletion.id).then(() => {
            const newUsers = [...usersState.users];
            newUsers.splice(index, 1);
            setUsersState({
              ...usersState,
              users: newUsers,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewUser = (e) => {
    e.preventDefault();

    setUsersState({
      ...usersState,
      userCreateObject: newUser()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewUser = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newUser = {};
    newUser.firstName = usersState.userCreateObject.firstName;
    newUser.lastName = usersState.userCreateObject.lastName;
    newUser.userName = usersState.userCreateObject.userName;
    newUser.companyId = companyId;
    newUser.email = usersState.userCreateObject.email;
    newUser.password = usersState.userCreateObject.password;
    newUser.joined = usersState.userCreateObject.joined;
    newUser.isActive = usersState.userCreateObject.isActive;
    newUser.isSuperUser = usersState.userCreateObject.isSuperUser;

    if (validator.current.allValid()) {
      userService.addUser(newUser)
        .then(res => {
          setUsersState({
            ...usersState,
            users: [...usersState.users, res.data],
            userCreateObject: null,
            userEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setUsersState({
            ...usersState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };



  const onEditNewUserObject = (e, property) => {
    let newValue = '';
    if (property === 'isActive' || property === 'isSuperUser') {
      newValue = e.target.checked;
    } else {
      newValue = e.target.value;
    }


    const newEditUserItem = Object.assign({}, usersState.userCreateObject);
    newEditUserItem[property] = newValue;

    setUsersState({
      ...usersState,
      userCreateObject: newEditUserItem
    });
  };


  const handleClickShowPassword = (e, action) => {
    e.stopPropagation();

    if (action === 'edit') {
      setUsersState({
        ...usersState,
        editShowPassword: !usersState.editShowPassword
      });
    } else {
      setUsersState({
        ...usersState,
        createShowPassword: !usersState.createShowPassword
      });
    }

  };

  const tableFilterComponent = () => {
    return <SearchByTextTableFilter
      searchText={usersState.filters.user}
      getFilterData={filterData}
    />
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      sort={false}
      headCells={tableHeadersUser}
      onSelectAllClick={() => { }}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={false}
    />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(usersState.users) && usersState.users.map((user, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (usersState.userEditIndex !== index) && index !== '?'
                  ? <> 
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        style={{ padding: '0px' }}
                        component={RouterLink}
                        to={{ pathname: `/account-settings/${user.id}` }}
                      >
                        <ManageSearchIcon></ManageSearchIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.joined}</TableCell>
                    <TableCell>{user.isActive && <CheckIcon />}</TableCell>
                    <TableCell>{user.isSuperUser && <CheckIcon />}</TableCell>

                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={usersState.userEditObject.userName || ''}
                          onChange={e => onEditableUserChange(e, 'userName')}
                          onBlur={() => validator.current.showMessageFor('userName')}
                        />{validator.current.message('userName', usersState.userEditObject.userName, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={usersState.userEditObject.firstName || ''}
                          onChange={e => onEditableUserChange(e, 'firstName')}
                          onBlur={() => validator.current.showMessageFor('firstName')}
                        />{validator.current.message('firstName', usersState.userEditObject.firstName, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={usersState.userEditObject.lastName || ''}
                          onChange={e => onEditableUserChange(e, 'lastName')}
                          onBlur={() => validator.current.showMessageFor('lastName')}
                        />{validator.current.message('lastName', usersState.userEditObject.lastName, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={usersState.userEditObject.email || ''}
                          onChange={e => onEditableUserChange(e, 'email')}
                        />{validator.current.message('email', usersState.userEditObject.email, 'required')}
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="standard-adornment-password"
                          type={usersState.editShowPassword ? 'text' : 'password'}
                          value={usersState.userEditObject.password || ''}
                          onChange={e => onEditableUserChange(e, 'password')}
                          label='password'
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => handleClickShowPassword(e, 'edit')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large">
                                {usersState.editShowPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <Checkbox
                          checked={usersState.userEditObject.isActive}
                          onChange={e => onEditableUserChange(e, 'isActive')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <Checkbox
                          checked={usersState.userEditObject.isSuperUser}
                          onChange={e => onEditableUserChange(e, 'isSuperUser')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </FormControl>
                    </TableCell>


                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: usersState.userEditIndex !== index,
                    callback: (e) => handleEditUser(e, index)
                  },
                  delete: {
                    enabled: usersState.userEditIndex !== index,
                    callback: (e) => handleDeleteUser(e, index)
                  },
                  close: {
                    enabled: usersState.userEditIndex === index,
                    callback: (e) => handleCloseEditUser(e, index)
                  },
                  save: {
                    enabled: usersState.userEditIndex === index,
                    callback: (e) => handleSaveUser(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          usersState.userCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={usersState.userCreateObject.userName || ''}
                  onChange={e => onEditNewUserObject(e, 'userName')}
                  onBlur={() => validator.current.showMessageFor('userName')}
                />{validator.current.message('userName', usersState.userCreateObject.userName, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={usersState.userCreateObject.firstName || ''}
                  onChange={e => onEditNewUserObject(e, 'firstName')}
                  onBlur={() => validator.current.showMessageFor('firstName')}
                />{validator.current.message('firstName', usersState.userCreateObject.firstName, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={usersState.userCreateObject.lastName || ''}
                  onChange={e => onEditNewUserObject(e, 'lastName')}
                  onBlur={() => validator.current.showMessageFor('lastName')}
                />{validator.current.message('lastName', usersState.userCreateObject.lastName, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={usersState.userCreateObject.email || ''}
                  onChange={e => onEditNewUserObject(e, 'email')}
                />{validator.current.message('email', usersState.userCreateObject.email, 'required')}
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  label='password'
                  type={usersState.createShowPassword ? 'text' : 'password'}
                  value={usersState.userCreateObject.password || ''}
                  onChange={e => onEditNewUserObject(e, 'password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => handleClickShowPassword(e, 'create')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {usersState.createShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{validator.current.message('password', usersState.userCreateObject.password, 'required')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <Checkbox
                  checked={usersState.userCreateObject.isActive}
                  onChange={e => onEditNewUserObject(e, 'isActive')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <Checkbox
                  checked={usersState.userCreateObject.isSuperUser}
                  onChange={e => onEditNewUserObject(e, 'isSuperUser')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </FormControl>
            </TableCell>

            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setUsersState({ ...usersState, userCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewUser()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('USERS')}
        pagination={{
          total: usersState.page.total || 0,
          page: usersState.page.page - 1 || 0,
          perPage: usersState.page.per_page || 10,
          count: usersState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewUser}
        newRowBtnEnabled={!usersState.userCreateObject}
        confirmDialog={usersState.confirmDialog.message.length > 0 ? usersState.confirmDialog : usersState.confirmDialog}
        tableHeader={tableHeaderComponent}
        breadcrump={props.autonomousPage ? breadcrump : undefined}
        tableBody={tableBodyComponent}
        tableFilter={props.pageFilter ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={usersState.isLoading}
      />
    </>
  );
};


export default Users;
