import React, { useCallback } from "react";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCompanies, getBuyerCompanies, getSupplierCompanies } from '../../services/companiesService';
import t from '../../helpers/languages';
import useStyles from './style';

export default function InlineEditAutocompleteCompanies({ company: initialCompany, getSelectedCompany, supplier, saved, companySearchFor, error, req=false }) {
  const classes = useStyles();

  const empty = {
    id: null,
    name: null
  };

  const [openCompany, setOpenCompany] = React.useState(false);
  const [company, setCompany] = React.useState(initialCompany !== '-' && initialCompany !== null && initialCompany.id !== null ? initialCompany : empty);
  const [search, setSearch] = React.useState('');
  const [companies, setCompanies] = React.useState([initialCompany !== '-' && initialCompany !== null && initialCompany.id !== null ? initialCompany : empty]);


  const handleSpanClick = useCallback(() => setOpenCompany(true), [
    setOpenCompany
  ]);

  const handleInputChange = (e, fetchType) => {

    const value = e && e.target && e.target.value;
    if (value && value.length >= 3 && search !== value) {
      setSearch(value);
      debounceFn(value);
    };
  };

  const handleDebounceFn = (val) => {
    companySearch({
      search: val,
      is_supplier: supplier
    })
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const onCompanySelectionChange = (event, values) => {


    setCompany(values ? values : empty);
    const selectedCompany = {
      company: values ? values : null,
    };
    getSelectedCompany(selectedCompany);

  };



  const companySearch = (criteria) => {
    if (companySearchFor === 'buyer') {
      return getBuyerCompanies(criteria).then((res) => {
        setCompanies(res.data);
      }).catch((error) => {
      })
    }
    else if (companySearchFor === 'supplier') {
      return getSupplierCompanies(criteria).then((res) => {
        setCompanies(res.data);
      }).catch((error) => {
      })
    }
    else {
      return getAllCompanies(criteria).then((res) => {
        setCompanies(res.data);
      }).catch((error) => {
      })
    }
  }


  let labelFilter = t('COMPANY');

  if (companySearchFor === 'buyer') {
    labelFilter = t('BUYER');
  }
  else if (companySearchFor === 'supplier') {
    labelFilter = t('SUPPLIER');
  }

  return (
    <span className="inline-text">
      {openCompany || !saved ? (
        <span>
          <Autocomplete
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            id="find-company"
            fullWidth
            onOpen={() => {
              setOpenCompany(true);
            }}
            onClose={() => {
              setOpenCompany(false);
            }}
            onInputChange={e => handleInputChange(e)}
            onChange={onCompanySelectionChange}
            value={companies.find(v => v.id === company.id) || null}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            getOptionLabel={(option) => (option.name !== null ? option.name : '')}
            options={companies !== null ? companies : null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin='none'
                label={labelFilter}
                variant="outlined"
                error={error && !saved}
                helperText={error && !saved && "Value not saved."}
                InputProps={req&&company&&company.id===null?{...params.InputProps,className:classes.input }:{...params.InputProps}}
              />
            )}
          />
        </span>) : (
        <span
          onClick={handleSpanClick}
          className={!openCompany ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
        >
          <TextField
            fullWidth
            id="outlined-disabled-company"
            label={labelFilter}
            defaultValue={company.name}
            variant="outlined"
            InputProps={req&&company&&company.id===null?{className:classes.input+' '+'required-fields', readOnly: true }:{readOnly: true}}
          />
        </span>)}
    </span >
  );
};
