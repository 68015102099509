import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import UserInitials from '../userInitials';
import UserMenu from '../userMenu';
import useStyles from './styles';

const TopBar = (props) => {
  const [ anchorEl, setAnchorEl ] = useState(null);

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <AppBar className={ classes.appBar } position='fixed'>
      <Toolbar className={ classes.topBar }>
        <div>
          <IconButton
            aria-label='menu'
            color='inherit'
            edge='start'
            onClick={ () => {
              //TODO custom update state implementation
              props.sideNavState.toggle(!props.sideNavState.expanded);
            } }
            size="large">
            <MenuIcon className={ classes.burgerIcon  +' '+'button-side-menu'} />
          </IconButton>
        </div>
        <Button
          aria-controls='user-menu'
          aria-haspopup='true'
          className={ classes.userMenu }
          onClick={ handleClick }
        >
          <UserInitials
            firstName={ props.userState && props.userState.user && props.userState.user.userName && props.userState.user.userName.split(' ')[0] }
            lastName={ props.userState && props.userState.user && props.userState.user.userName && props.userState.user.userName.split(' ')[1] }
          />
          <span className={ classes.firstName }>
            { props.userState && props.userState.user && props.userState.user.userName && props.userState.user.userName.split(' ')[0] }
          </span>
          <span className={ classes.lastName }>
            { props.userState && props.userState.user && props.userState.user.userName && props.userState.user.userName.split(' ')[1] }
          </span>
          <ExpandMoreIcon className={ classes.expandIcon } />
        </Button>
        <UserMenu
          props
          anchorEl={ anchorEl }
          close={ handleClose }
          id='user-menu'
          open={ Boolean(anchorEl) }
        />
      </Toolbar>
    </AppBar>
  </>;
};

export default TopBar;
