  
  const tableHeaders = [
    { id: 'product_code', disablePadding: false, label: 'Product Code', width: '25%'},
    { id: 'product_name', disablePadding: false, label: 'Product Name', width: '25%' },
    { id: 'category', disablePadding: false, label: 'Category', width: '25%' },
    { id: 'avg_rating', disablePadding: false, label: 'Average Rating', width: '25%' },
  ];



  export { tableHeaders };
