import React from 'react';
import Grid from '@mui/material/Grid';
import t from '../../helpers/languages';
import OutlineDisplay from '../outlineDisplay';
import InlineRating from '../inlineRating';
import Typography from '@mui/material/Typography';
import OutlineRating from "../outlineRating";

const EvaluationInfo = (props) => {

    const evalStars = (data) => {
        props.getStars(data);
    };

    return (
        <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-end"
            direction="row"
            spacing={3}
        >
            <Grid item xs={11}>
                <Typography variant="h4" gutterBottom>
                    {props.infoTabTitle}
                </Typography>
            </Grid>
            <Grid item xs={1} >
                {props.edit ? <InlineRating stars={props.stars} saved={props.savedStars} error={props.error}
                    getStars={evalStars} step={0.1} /> : <OutlineRating value={props.stars} />}

            </Grid>
            <Grid item xs={3}>
                <OutlineDisplay label={t('SUPPLIER')} value={props.supplier.name} />
            </Grid>
            <Grid item xs={3}>
                <OutlineDisplay label={t('PORT')} value={props.port.name} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('VESSEL')} value={props.vessel.name} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('STATUS')} value={props.status} />
            </Grid>
            <Grid item xs={2}>
                <OutlineDisplay label={t('DATE')} value={props.date} />
            </Grid>
        </Grid>
    );

}


export default EvaluationInfo;