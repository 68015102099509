import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeaderInfo from '../../components/tableHeaderInfo';
import TableRow from '@mui/material/TableRow';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/deliveriesConfig';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
// import {StatusIndicator} from "../../common/StatusIndicator/StatusIndicator";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    blueHover: {
        padding: '0px',
        verticalAlign: 'left',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        width: 'auto',
        color: 'inherit',
        "&:hover": {
            color: '#036df4'
        }
    }
});



const DeliveriesInfoTable = (props) => {
    const classes = useStyles();

    return <>
        <Typography variant="h5" gutterBottom>
            {props.toolbarTitle}
        </Typography>
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHeaderInfo
                    headCells={props.role === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
                />
                <TableBody>
                    {props.deliveries.map((deliveries) => (
                        <TableRow key={deliveries.id}>
                            <TableCell>
                                <IconButton
                                    className='rfqs-quotes-orders-details'
                                    aria-label="expand row"
                                    style={{ padding: '0px' }}
                                    component={RouterLink}
                                    to={{ pathname: `/transactions/${props.transactionId}/deliveries/${deliveries.id}` }}
                                >
                                    <ManageSearchIcon></ManageSearchIcon>
                                </IconButton>
                            </TableCell>
                            <TableCell>{deliveries.reference_number ? deliveries.reference_number : 'N/A'}</TableCell>
                            <TableCell>{(deliveries.buyer !== undefined && deliveries.supplier !== undefined) ? (props.role === 'SUPPLIER' ? deliveries.buyer.name : deliveries.supplier.name) : ''}</TableCell>
                            <TableCell>{deliveries.vessel.name}</TableCell>
                            <TableCell>{deliveries.category && deliveries.category.name}</TableCell>
                            <TableCell>{deliveries.deliveredItems}</TableCell>
                            <TableCell>{deliveries.port.name}</TableCell>

                            {/* <TableCell>
                                <StatusIndicator status={deliveries.transaction.status}>
                                    {deliveries.transaction.status} 
                                </StatusIndicator>
                            </TableCell> */}

                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default DeliveriesInfoTable;