import React, { useEffect } from 'react';
import t from '../../helpers/languages';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllVesselTypes } from '../../services/vesselsService';

export default function FilterVesselTypes({ vesselType: initialvesselType, getSelectedVesselType }) {

  const [vesselType, setvesselType] = React.useState(0);
  const [vesseltypes, setVesselTypes] = React.useState([]);
  const [openVessel, setOpenVessel] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setvesselType(initialvesselType && initialvesselType.id !== null ? initialvesselType : 0);
    setVesselTypes(initialvesselType && initialvesselType.id !== null ? [initialvesselType] : []);
    getAllVesselTypes().then((res) => {
      setVesselTypes(res.data);
    }).catch((error) => {
    })
  }, [initialvesselType]);

  const handleInputChange = (e, fetchType) => {
    const value = e && e.target && e.target.value;
    setSearch(value);
  };

  const randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const onvesselTypeSelectionChange = (event, values) => {
    setvesselType(values ? values : '');
    const selectedVesselType = {
      vesselType: values ? values :  null, // return null when the value is empty string
    };
    getSelectedVesselType(selectedVesselType);
  };


  return (
    <div>
      <Autocomplete
        id={"find-vesselType" + randomNumber(0, 999)}
        fullWidth
        open={openVessel}
        onOpen={() => {
          setOpenVessel(true);
        }}
        onClose={() => {
          setOpenVessel(false);
        }}
        onInputChange={e => handleInputChange(e, 'vesselType')}
        onChange={onvesselTypeSelectionChange}
        value={vesseltypes.find(v => v.id === vesselType.id) || null}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        getOptionLabel={(option) => (option.name !== null ? option.name : option.description)}
        options={vesseltypes !== null ? vesseltypes : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('VESSEL_TYPE')}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </div>
  );
}