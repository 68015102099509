import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { debounce, Grid, TextField } from "@mui/material";
import FilterCatalogues from "../filterCatalogues";
import './_ratedProductsTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const RatedProductsTableFilter = ({
  search: initialSearch,
  catalogue: initialCatalogue,
  getFilterData
}) => {

  const classes = useStyles();

  const [search, setSearch] = useState(initialSearch || '');
  const [catalogue, setCatalogue] = useState(initialCatalogue || null);

  const handleChange = (event, property) => {

      setSearch(event.target.value);
      const filterData = {
        catalogue: catalogue,
        search: event.target.value
      };

      debounceFn(filterData);
    
  };
  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const selectedCatalogue = (data) => {
    setCatalogue(data.catalogue);
    const filterData = {
      catalogue: data.catalogue,
      search: search
    };

    getFilterData(filterData);
  }

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCatalogues
              catalogue={catalogue}
              getSelectedCatalogue={selectedCatalogue}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}