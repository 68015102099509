import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableActions from '../../components/TableActions/TableActions';
import clsx from "clsx";
import Table from "@mui/material/Table";
import React from "react";

const ProductMatchTable = ({ products, onRowClick, selectedProducts, productsCategory, handleChangePage, handleChangeRowsPerPage, handleDeleteProduct }) => {
    const pcat = productsCategory;

    return (<>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Coding</TableCell>
                    {productsCategory === 'resolvedProduct' ? <TableCell align="left">Catalogue</TableCell> : <TableCell align="left">Action</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {products.products.map((product, index) => {
                    return (
                        <TableRow key={product.id}
                            onClick={() => onRowClick(product, productsCategory, index)}
                            className={clsx({
                                'product-item': true,
                                'product-item--selected': selectedProducts[productsCategory]?.id === product.id
                            })}
                        >
                            <TableCell>{product.code}</TableCell>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>{product.coding?.name}</TableCell>
                            {productsCategory === 'resolvedProduct' ?  <TableCell>{product?.category?.name}</TableCell> : <TableCell><
                                    TableActions config={{
                                        edit: {
                                            enabled: false
                                        },
                                        delete: {
                                            enabled: true,
                                            callback: (e) => handleDeleteProduct(e, index)
                                        },
                                        close: {
                                            enabled: false
                                        },
                                        save: {
                                            enabled: false
                                        }
                                    }} /></TableCell>}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
        <TablePagination
            component='div'
            count={products.page && products.page.total}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, pcat)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, pcat)}
            page={products.page && products.page.page - 1}
            rowsPerPage={products.page && products.page.per_page}
            rowsPerPageOptions={[5, 10, 50]}
        />
    </>
    )
}

export default ProductMatchTable