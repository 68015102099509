import React, { Fragment, useState } from 'react';
import 'date-fns';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import t from '../../helpers/languages';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import Button from '@mui/material/Button';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Grid } from "@mui/material";
import FilterPorts from "../filterPorts";
import FilterCompanyCategories from "../filterCompanyCategories";
import FilterCountries from "../filterCountries";
import FilterContinents from "../filterContinents";
import FilterCompanies from "../filterCompanies";
import FilterRegions from "../filterRegions";
import dateToString from '../../helpers/dateToString';
import moment from 'moment';
import Collapse from '@mui/material/Collapse';
import './_tableFilterRegionPurchases.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),

  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



export const TableFilterRegionPurchases = ({
  company: initialCompany,
  from: initialFrom,
  to: initialTo,
  companySearchFor,
  region: initialRegion,
  country: initialCountry,
  port: initialPort,
  continent: initialContinent,
  companyCategory: initialProductCategory,
  getFilterData
}) => {
  const classes = useStyles();
  
  const [company, setCompany] = useState(initialCompany);
  const [region, setRegion] = useState(initialRegion);
  const [country, setCountry] = useState(initialCountry);
  const [port, setPort] = useState(initialPort);
  const [continent, setContinent] = useState(initialContinent);
  const [companyCategory, setProductCategory] = useState(initialProductCategory);
  const [from, setFrom] = useState(initialFrom ? moment.utc(initialFrom) : null);
  const [to, setTo] = useState(initialTo ? moment.utc(initialTo) : null);
  const [expanded, setExpanded] = useState(initialCompany||initialRegion||initialCountry||initialPort||initialContinent?true:false);


  const handleExpandClick = () => {
    
    setExpanded(!expanded);
   
  };

  const selectedPort = (data) => {
    setPort(data.port);
    const filterData = {
      port: data.port,
      category: companyCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      region: region,
      country: country,
      continent: continent,
      company: company,
    };

    getFilterData(filterData);
  }

  const selectedCountry = (data) => {
    setCountry(data.country);
    const filterData = {
      country: data.country,
      port: port,
      category: companyCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      region: region,
      continent: continent,
      company: company,
    };

    getFilterData(filterData);
  }

  const selectedContinent = (data) => {
    setContinent(data.continent);
    const filterData = {
      continent: data.continent,
      port: port,
      category: companyCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      region: region,
      country: country,
      company: company,
    };

    getFilterData(filterData);
  }

  const selectedRegion = (data) => {
    setRegion(data.region);
    const filterData = {
      region: data.region,
      country: country,
      port: port,
      category: companyCategory,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      continent: continent,
      company: company,
    };

    getFilterData(filterData);
  }

  const selectedCompanyCategory = (data) => {
    setProductCategory(data.companyCategory);
    const filterData = {
      region: region,
      category: data.companyCategory,
      country: country,
      port: port,
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      continent: continent,
      company: company,
    };

    getFilterData(filterData);
  }

  const selectedCompany = (data) => {

    setCompany(data.company);
    const filterData = {
      from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
      to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
      company: data.company,
      region: region,
      country: country,
      continent: continent,
      category: companyCategory,
    };

    getFilterData(filterData);
  }


  const onDateFromChange = (date) => {
    setFrom(date);

    if (moment(date).isValid() && !isNaN(date)) {
      const filterData = {
        from: dateToString(date) + 'T00:00:00Z',
        to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
        company: company,
        region: region,
        country: country,
        continent: continent,
        category: companyCategory,
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        from: date,
        to: moment(to).isValid() ? dateToString(to) + 'T00:00:00Z' : to,
        company: company,
        region: region,
        country: country,
        continent: continent,
        category: companyCategory,
      };

      getFilterData(filterData);
    }


  };

  const onDateToChange = (date) => {
    setTo(date);

    if (moment(date).isValid() && !isNaN(date)) {
      const filterData = {
        from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
        to: dateToString(date) + 'T00:00:00Z',
        company: company,
        region: region,
        country: country,
        continent: continent,
        category: companyCategory,
      };

      getFilterData(filterData);
    } else if (date === null) {
      const filterData = {
        from: moment(from).isValid() ? dateToString(from) + 'T00:00:00Z' : from,
        to: date,
        company: company,
        region: region,
        country: country,
        continent: continent,
        category: companyCategory,
      };

      getFilterData(filterData);
    }

  };




  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={5}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCompanyCategories
              companyCategory={companyCategory}
              getSelectedCompanyCategory={selectedCompanyCategory}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={1}>
                <Fragment>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-from"
                      label={t('FROM')}
                      value={from}
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      inputFormat="yyyy-MM-DD"
                      inputVariant="outlined"
                      onChange={onDateFromChange}
                      initialFocusedDate={from}
                      clearable
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      id="date-picker-to"
                      label={t('TO')}
                      inputFormat="yyyy-MM-DD"
                      minDate={new Date(2015, 1, 1)}
                      maxDate={new Date(2030, 1, 1)}
                      value={to}
                      inputVariant="outlined"
                      onChange={onDateToChange}
                      clearable
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Fragment>
              </Grid>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>
      </Grid>
     
      <Collapse in={expanded} timeout="auto" unmountOnExit >
        <Grid
          style={{ paddingTop: '7px' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={6} >
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterCompanies
                company={company}
                companySearchFor={companySearchFor}
                getSelectedCompany={selectedCompany}
                errorMessage={''}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterPorts
                port={port}
                getSelectedPort={selectedPort} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterCountries
                country={country}
                getSelectedCountry={selectedCountry} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterContinents
                continent={continent}
                getSelectedContinent={selectedContinent} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <FilterRegions
                region={region}
                getSelectedRegion={selectedRegion} />
            </FormControl>
          </Grid>
        </Grid>
      </Collapse>
    </form>
  );
}