import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: "2.5em"
  },
  count: {
    position: "absolute",
    lineHeight: 1,
    color: "#fff",
    top: "0.5em",
    fontSize: "1em"
  }
});

export default useStyles;