import React from 'react';
import Button from '@mui/material/Button';
import TableContainer from "@mui/material/TableContainer";
import {
    TextField
} from "@mui/material";
import FilterCatalogues from "../../components/filterCatalogues";
import FilterCodingSystems from "../../components/filterCodingSystems";
import useProductMatchHook from './useProductMatchHook';
import './ProductMatch.scss'
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import ProductMatchTable from "./ProductMatchTable";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { EditRow } from './EditRow';
import Typography from '@mui/material/Typography';
import t from '../../helpers/languages';

const ProductMatch = (props) => {
    const {
        unresolvedProducts,
        isConfirmDialogOpen,
        resolvedProducts,
        filters,
        selectedProducts,
        onFilterCatalogueChange,
        onFilterCodingSystemChange,
        onProductClick,
        onMatchBtnClick,
        setConfirmDialogOpen,
        onSearchTextChange,
        onProductMatchConfirm,
        validator,
        confirmDialogDeleteOpen,
        onEditableProductChange,
        handleSaveProduct,
        handleCloseEditProduct,
        selectedCodingSystem,
        handleChangePage,
        handleChangeRowsPerPage,
        handleDeleteProduct,
        ref,
        selectedProductCategory
    } = useProductMatchHook();

    return (
        <div className={'product-match'}>
            <div className={'product-match__selection-wrapper'}>
                <Paper
                    className={'paper'}
                    elevation={4}
                >
                    <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
              
                                 <div className={'product-match__search-actions'}>
                                 <Typography variant="h4" >
                                    <div className={'product-match__title'}>
                                        {t('PRODUCT_RESOLUTION')}
                                    </div>
                                </Typography>
                                   {/* <FilterCatalogues
                                        getSelectedCatalogue={e => onFilterCatalogueChange(e, 'unresolved')}
                                    />*/}
                                     <FilterCodingSystems   
                                        codingSystem={filters.unresolved.codinga}     
                                        getSelectedCodingSystem={e =>onFilterCodingSystemChange(e, 'unresolved')}
                                    />
                                </div> 
                                <ProductMatchTable
                                    products={unresolvedProducts}
                                    productsCategory={'unresolvedProduct'}
                                    selectedProducts={selectedProducts}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleDeleteProduct={handleDeleteProduct}
                                    onRowClick={onProductClick}
                                />
                            </TableContainer>
                        </Grid>
                        <Grid container item xs={2} direction="column" alignItems="center" justifyContent="center">
                            <div className={'product-match__btn-action-wrapper'}>
                                <Button variant="contained"
                                    color="primary"
                                    onClick={onMatchBtnClick}
                                    size="large"
                                    disabled={!selectedProducts.resolvedProduct || !selectedProducts.unresolvedProduct}
                                >
                                    {t('MATCH')}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <TableContainer className={'table-wrapper'}>
                                <div className={'product-match__search-actions'}>
                                    <FilterCodingSystems
                                        codingSystem={filters.resolved.codinga}                    
                                        getSelectedCodingSystem={e =>onFilterCodingSystemChange(e, 'resolved')}
                                    />
                                    <FilterCatalogues
                                        catalogue={filters.resolved.categorya}   
                                        getSelectedCatalogue={e => onFilterCatalogueChange(e, 'resolved')}
                                    />
                                    <TextField variant="outlined"
                                        label={t('SEARCH')}
                                        fullWidth
                                        inputRef={ref}
                                        onChange={e => onSearchTextChange(e, 'resolved')}
                                        value={filters.resolved.search||''}
                                    />
                                </div>
                                <ProductMatchTable
                                    products={resolvedProducts}
                                    productsCategory={'resolvedProduct'}
                                    selectedProducts={selectedProducts}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    onRowClick={onProductClick}
                                />
                            </TableContainer>

                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={
                                    `Are you sure you want to match those Products: 
                        [${selectedProducts?.unresolvedProduct?.name}]  
                         [${selectedProducts?.resolvedProduct?.name}]?`
                                }
                                dialogTitle={'Product Match'}
                                handleCancel={() => setConfirmDialogOpen(false)}
                                handleOk={onProductMatchConfirm}
                                open={isConfirmDialogOpen}
                            />
                            <ConfirmationDialog
                                ariaLabelledBy={''}
                                confirmationMessage={confirmDialogDeleteOpen.message}
                                dialogTitle={confirmDialogDeleteOpen.title}
                                handleCancel={confirmDialogDeleteOpen.onCancel}
                                handleOk={confirmDialogDeleteOpen.onConfirm}
                                open={confirmDialogDeleteOpen.isOpen}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {unresolvedProducts.productEditIndex !== -1 && unresolvedProducts.productEditObject?.code && <EditRow
                                unresolvedProducts={unresolvedProducts}
                                validator={validator}
                                onEditableProductChange={onEditableProductChange}
                                handleSaveProduct={handleSaveProduct}
                                handleCloseEditProduct={handleCloseEditProduct}
                                selectedCodingSystem={selectedCodingSystem}
                                selectedProductCategory={selectedProductCategory}
                            />}
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        </div>
    );
}

export default ProductMatch;
