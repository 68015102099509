import React, { Fragment } from 'react';
import FileUploader from '../fileUploader';
import Grid from '@mui/material/Grid';
import 'date-fns';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';


const ImportFiles = (props) => {
    return (<Grid container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}>
        <Grid item >
            <FileUploader
                title={'Upload units file'}
                accept={'text/tab-separated-values'}
                handleImport={props.handleUploadUnitsFile} />
        </Grid>
        <Grid item>
            <FileUploader
                title={'Upload products file'}
                accept={'text/tab-separated-values'}
                handleImport={props.handleUploadProductsFile} />
        </Grid>
        <Grid item>
            <FileUploader
                title={'Upload Equivalent products file'}
                accept={'text/tab-separated-values'}
                handleImport={props.handleUploadEquivalentProductsFile} />
        </Grid>
        {!props.supplier && (
            <>
                <Grid item >
                    <FileUploader
                        title={'Upload old invoice/delivery notes'}
                        accept={'application/pdf'}
                        multiple={true}
                        handleImport={props.handleUploadHistoricalData} />
                </Grid>

            </>)}

        {props.supplier && (
            <>   <Grid item >
                <FileUploader
                    title={'Upload old invoice/delivery notes'}
                    accept={'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/x-zip-compressed, application/x-zip'}
                    multiple={true}
                    handleImport={props.handleUploadHistoricalData} />
            </Grid>
                <Grid item xs={12}> </Grid>
                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item >
                        <FormControl fullWidth variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container spacing={1}>
                                    <Fragment>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                id="date-picker-from-pricelist"

                                                label={t('FROM')}
                                                value={props.from}
                                                inputFormat="yyyy-MM-DD"
                                                minDate={new Date(2015, 1, 1)}
                                                maxDate={new Date(2030, 1, 1)}
                                                inputVariant="outlined"
                                                onChange={props.onDateFromChange}
                                                initialFocusedDate={props.from}
                                                clearable
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                id="date-picker-to-pricelist"
                                                minDate={new Date(2015, 1, 1)}
                                                maxDate={new Date(2030, 1, 1)}
                                                label={t('TO')}
                                                inputFormat="yyyy-MM-DD"
                                                value={props.to}
                                                inputVariant="outlined"
                                                onChange={props.onDateToChange}
                                                clearable
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Grid>
                                    </Fragment>
                                </Grid>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item >
                        <FileUploader
                            title={'Upload price lists'}
                            accept={'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/x-zip-compressed, application/x-zip'}
                            multiple={true}
                            disabled={!props.from || !props.to}
                            handleImport={props.handleUploadPriceLists} />
                    </Grid></Grid></>
        )}
    </Grid>
    );
}

export default ImportFiles;