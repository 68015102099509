import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px',
      width: '100%',
      minWidth: '1600px'
    },
    rootnopaddingtop: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '50px',
      width: '100%',
      minWidth: '1600px'
    },
    rootsec: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      minWidth: '1400px'
    },
    rootnowidth: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '29px',
      paddingLeft: '7px',
      paddingRight: '3px',
      // paddingBottom: '50px',
     
    },
    procureDataTableWrapper: {
      border: '2 px solid red'
    },
    createProductButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 20,
    },
    paper: {
      width: '100%',
      padding: '20px',
      minHeight: '500px',
      marginBottom: theme.spacing('2px'),
    },
    loadingScreen: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'not-allowed!important',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    tableRow: {
      cursor: 'pointer',
    },
    editButton: {
      padding: 3,
    },
    editIcon: {
      fontSize: 32,
    },
    rowActionBtn: {
      margin: '0 auto;',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      fontSize: '32px'
    },
    infoMargin: {
      marginTop: 10,
    },
    attachmentsTitle: {
      marginTop: 15,
    },
    itemsTitle: {
      marginTop: 15,
      // display: 'flex',
      // fontWeight: '450',
      // fontSize: "1.25em",
    },
    buttonMargin:{
      margin: 5,
      backgroundColor: 'white',
    },
    alignRight: {
      textAlign: 'right',
      alignItems: 'center',
    },
  };
});

export default useStyles;
