import httpService from './httpService';

const getAllPorts = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchPortList(criteria);
};

// old deletePort function
// const deletePort = (portId) => {
//   return httpService.delete(`/ui/Ports/ ${ portId }/`);
// };

const deletePort = (criteria = {}) => {
  return httpService.DeletePort(criteria);
};

const getAllPortsInfo = (criteria = {}) => {
  return httpService.FetchPortInfoList(criteria);
};

const editPort = (id, data) => {
  return httpService.UpdatePort({}, data);
};

const addPort = (data) => {
  return httpService.CreatePort({}, data);
};

const matchPorts = (data) => {
  return httpService.MatchPort(data);
}

export { getAllPorts, getAllPortsInfo, addPort, editPort, deletePort, matchPorts };
