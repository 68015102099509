import httpService from './httpService';


const getAllUnitDictionariesInfo = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }
 return httpService.GetUnitDicts(criteria);
};

const deleteUnitDictionary = (id) => {
  return httpService.DeleteUnitDict(id);
};

const editUnitDictionary = (id, data) => {
   return httpService.UpdateUnitDict({}, data);
};

const addUnitDictionary = (data) => {
  return httpService.CreateUnitDict({},data);
};

export { getAllUnitDictionariesInfo, deleteUnitDictionary, addUnitDictionary, editUnitDictionary };
