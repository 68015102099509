// /** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { DropzoneAreaBase } from 'mui-file-dropzone';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import useStyles from './styles';
import overrides from './overrides';
import Message from "../messages";
import uuid from "../../helpers/Uuid";
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';


const Div = styled.div`
.MuiSvgIcon-root {
      display:none;
    }
    .MuiChip-deleteIcon{
      display:block
    }
`;

function DropzoneComponent({ initialFiles, title, enabled, newFiles, getFiles, getFileClicked, getFileDeleted }) {

  const [files, setFiles] = useState([]);
  const [messages, setMessages] = useState([]);


  const maxFiles = 12;
  //console.log(initialFiles);
  const onMessageClose = uuid => {
    setMessages(oldMessages => oldMessages.filter(message => message.id !== uuid));
  }

  const onErrorMessage = message => {
    const newMessage = {
      id: uuid(),
      message
    };
    setMessages(oldMessages => [...oldMessages, newMessage])
  }

  const classes = useStyles();

  const handleAdd = (newFiles) => {

    const firstFile = [newFiles[0]];

    if (newFiles.length > 1) {
      onErrorMessage('Only one file is allowed, per upload!');
    }

    if (files.length + initialFiles.length === maxFiles) {
      onErrorMessage('Maximum allowed number of files exceeded. Only 12 allowed');
    } else {

      newFiles = firstFile.filter(file => !files.find(f => f.file.name === file.file.name) && !initialFiles.find(f => f.name === file.file.name));

      if (newFiles.length > 0) {
        getFiles([newFiles[0].file]);

        setFiles([...files, ...newFiles]);
      }
    }
  };
  // const handleDrop = (files) => {
  //   getFiles(files);
  // }

  const handleDelete = (deleted) => {

    const data = newFiles.find(x => x.name === deleted.file.name);

    getFileDeleted(data);

    setFiles(files.filter(f => f !== deleted));
  };

  const handleDeleteInitial = (data) => {
    getFileDeleted(data);
  };

  const handleClick = (data) => {

    getFileClicked(data);

  };

 

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={overrides} >
        <Message
          messages={messages}
          onMessageClose={onMessageClose}
        />
        {(initialFiles?.length !== 0 || enabled) && <Typography variant="h5" gutterBottom component="div">
          {title}
        </Typography>}
        <Div
        className='attachment-import'
        //       css={css`
        //       .MuiSvgIcon-root {
        //     display:none;
        //   }
        //   .MuiChip-deleteIcon{
        //     display:block
        //   }
        // `}
        >
          {enabled && <DropzoneAreaBase
            showPreviews={false}
            fileObjects={files}
            showAlerts={['error']} 
            initialFiles={initialFiles}
            showPreviewsInDropzone={false}
            dropzoneProps={{
              multiple: false
            }}
            alertSnackbarProps={{
              anchorOrigin:{ 
                vertical: 'top',
                horizontal: 'right',
              }
            }}
            filesLimit={maxFiles}
            maxFileSize={52428800}
            acceptedFiles={[
              'application/pdf',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/plain',
              'image/png',
              'image/jpeg',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'text/csv'

          ]}
            dropzoneClass={classes.dropZone}
            dropzoneParagraphClass={classes.dropZoneText}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            previewText="Selected files"
            onAdd={(newFiles) => handleAdd(newFiles)}
            onDelete={(deleted) => handleDelete(deleted)}
          //onDrop={(files) => handleDrop(files)}
          />}


          {initialFiles && initialFiles.map((initFile, index) => {

            return (enabled ? <Chip
              key={index}
              classes={{ root: classes.previewChipExisting }}
              variant="outlined"
              label={initFile.name}
              onClick={() => handleClick(initFile)}
              onDelete={() => handleDeleteInitial(initFile)}
            /> : <Chip
              key={index}
              classes={{ root: classes.previewChipExisting }}
              variant="outlined"
              label={initFile.name}
              onClick={() => handleClick(initFile)}
            />)
          })}

        </Div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default DropzoneComponent;