import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import Chip from '@mui/material/Chip';

const PrintPdf = ({ title, handlePrint }) => {

    const handleClick = (event) => {
        handlePrint(event);
    }

    return <Chip
            aria-label={`${title}`}
            style={{ color: '#000000', opacity: '55%', marginLeft: '2px', marginRight: '4px' }}
            variant="outlined"
            onClick={(event) => {
                return handleClick(event)
            }}
            label={title}
            size="medium"
            icon={<PrintIcon />}
        >
        </Chip>;
};

export default PrintPdf;