import { useEffect, useState } from "react";
import userService from "../../services/userService";

const useAccountSettingsForm = (filtersData) => {
    const [user, setUser] = useState({
        id: null,
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        notes: '',
        catalogues: '',
        isSuperUser: false,
        isStaff: false,
        isActive: false,
    });
    const [errors, setErrors] = useState({
        errors: null,
        isUserNameSaved: true,
        isFirstNameSaved: true,
        isLastNameSaved: true,
        isEmailSaved: true,
        isCatalogueSaved:true,
        isPassSaved: true,
        isNotesSaved: true,
    });
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        userService.getUserById(filtersData)
            .then(res => {
                const userResult = res.data;
                setUser(olduser => {
                    return {
                        ...olduser,
                        ...userResult
                    }
                });
                setLoading(loading => false);
            }).catch((error) => {
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    errors: error,
                });
            });
        // eslint-disable-next-line  
    }, []);

    const onDataChange = (e) => {
        e.persist();

        const newEditUser = Object.assign({}, user);

        if (e.target.value) {
            newEditUser[e.target.name] = e.target.value;
        } else {
            newEditUser[e.target.name] = false;
        }

        userService.editUser(user.id, newEditUser).then(res => {
            const userResult = res.data;
            setUser(olduser => {
                return {
                    ...olduser,
                    ...userResult,
                }
            });
            setLoading(loading => false);
            setErrors({
                ...errors
            });
        }).catch((error) => {
            setLoading(loading => false);
            setErrors({
                ...errors,
                errors: error
            });
        });
    }


    const validateFields = () => {
        let errors = {};

        if (user.userName.trim().length < 3 || user.userName.trim().length > 15) {
            errors.userName = "UserName should have 3 ~ 15 characters"
        }

        if (user.firstName.trim().length < 5 || user.firstName.trim().length > 15) {
            errors.firstName = "FirstName should have 5 ~ 15 characters"
        }

        if (user.lastName.trim().length < 5 || user.lastName.trim().length > 15) {
            errors.lastName = "Lastname should have 5 ~ 15 characters"
        }

        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(user.email).toLowerCase())) {
            errors.email = "Please enter a valid Email";
        }

        setErrors(errors);

        return errors;
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (Object.keys(validateFields(errors)).length === 0) {
            setLoading(true)
            userService
                .editUser(user.id, user)
                .then(re => {
                    setLoading(false);
                })
        }
    }


    const getSelectedCatalogue = (data, action) => {

        const newEditUser = Object.assign({}, user);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditUser.catalogues = data.catalogue;
        } else {
            newEditUser.catalogues = null;
        }

        userService.editUser(user.id, newEditUser).then(res => {
                const userResult = res.data;
                setUser(olduser => {
                    return {
                        ...olduser,
                        ...userResult,
                    }
                });
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    isCatalogueSaved: true
                });
            }).catch((error) => {
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    errors: error,
                    isCatalogueSaved: false
                });
            });

    };

    const getUserName = (data, action) => {

      const newEditUser = Object.assign({}, user);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditUser.userName = data.notes;
        } else {
            newEditUser.userName = null;
        }

        userService.editUser(user.id, newEditUser).then(res => {
                const userResult = res.data;
                setUser(olduser => {
                    return {
                        ...olduser,
                        ...userResult,
                    }
                });
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    isUserNameSaved: true
                });
            }).catch((error) => {
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    errors: error,
                    isUserNameSaved: false
                });
            });
    };

    const getFirstName = (data, action) => {

      const newEditUser = Object.assign({}, user);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditUser.firstName = data.notes;
        } else {
            newEditUser.firstName = null;
        }

        userService.editUser(user.id, newEditUser).then(res => {
                const userResult = res.data;
                setUser(olduser => {
                    return {
                        ...olduser,
                        ...userResult,
                    }
                });
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    isFirstNameSaved: true
                });
            }).catch((error) => {
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    errors: error,
                    isFirstNameSaved: false
                });
            });
    };

    const getLastName = (data, action) => {

      const newEditUser = Object.assign({}, user);

        if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
            newEditUser.lastName = data.notes;
        } else {
            newEditUser.lastName = null;
        }

        userService.editUser(user.id, newEditUser).then(res => {
                const userResult = res.data;
                setUser(olduser => {
                    return {
                        ...olduser,
                        ...userResult,
                    }
                });
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    isLastNameSaved: true
                });
            }).catch((error) => {
                setLoading(loading => false);
                setErrors({
                    ...errors,
                    errors: error,
                    isLastNameSaved: false
                });
            });
    };

    const getEmail = (data, action) => {

        const newEditUser = Object.assign({}, user);
  
          if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
              newEditUser.email = data.notes;
          } else {
              newEditUser.email= null;
          }
  
          userService.editUser(user.id, newEditUser).then(res => {
                  const userResult = res.data;
                  setUser(olduser => {
                      return {
                          ...olduser,
                          ...userResult,
                      }
                  });
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      isEmailSaved: true
                  });
              }).catch((error) => {
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      errors: error,
                      isEmailSaved: false
                  });
              });
      };

      const getPass = (data, action) => {

        const newEditUser = Object.assign({}, user);
  
          if (data !== undefined && data !== '-' && data !== null && data.pass !== '') {
              newEditUser.password = data.pass;
          } else {
              newEditUser.password= null;
          }
  
          userService.editUser(user.id, newEditUser).then(res => {
                  const userResult = res.data;
                  setUser(olduser => {
                      return {
                          ...olduser,
                          ...userResult,
                      }
                  });
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      isPassSaved: true
                  });
              }).catch((error) => {
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      errors: error,
                      isPassSaved: false
                  });
              });
      };

      const getNotes = (data, action) => {

        const newEditUser = Object.assign({}, user);
  
          if (data !== undefined && data !== '-' && data !== null && data.notes !== '') {
              newEditUser.notes = data.notes;
          } else {
              newEditUser.notes = null;
          }
  
          userService.editUser(user.id, newEditUser).then(res => {
                  const userResult = res.data;
                  setUser(olduser => {
                      return {
                          ...olduser,
                          ...userResult,
                      }
                  });
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      isNotesSaved: true
                  });
              }).catch((error) => {
                  setLoading(loading => false);
                  setErrors({
                      ...errors,
                      errors: error,
                      isNotesSaved: false
                  });
              });
      };

    return {
        user,
        isLoading,
        errors,
        onDataChange,
        onFormSubmit,
        getUserName,
        getFirstName,
        getLastName,
        getEmail,
        getPass,
        getNotes,
        getSelectedCatalogue,
    }
}

export default useAccountSettingsForm;