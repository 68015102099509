import React, { useCallback } from "react";
import useStyles from './style';
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import t from '../../helpers/languages';

export default function InlineEditDiscount({value: initialValue, getValue, saved, error, label='VALUE' }) {
    const classes = useStyles();
    const emptyValue = '0%';
    const [openValue, setOpenValue] = React.useState(false);
    const [value, setValue] = React.useState(initialValue?initialValue:undefined);

    const handleSpanClick = useCallback(() => setOpenValue(true), [
        setOpenValue
    ]);
  
  
    const onValueSelectionChange = (event, values) => {
  
        setValue(event.target.value);
    };

    // const handleValue = (val) => {
    //     setValue(val);
    //     getValue(emptyValue);
    //     setOpenValue(false);
    // };

    const onClose=()=>{
          setOpenValue(false);
          const entryValue={
              value:value
          };
          getValue(entryValue);
    }

    return (
        <span className="inline-text">
            {openValue || !saved ? (
                <span>
                    <Grid container item md={12} wrap='wrap' justifyContent="flex-start" alignItems="flex-end">
                        <TextField
                            value={value||''}
                            autoFocus
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            margin='none'
                            variant="outlined"
                            onChange={e => onValueSelectionChange(e, 'value')}
                            error={error && !saved}
                            placeholder={label}
                            label={t(label)}
                            onFocus={() => {
                                setOpenValue(true);
                            }}
                            fullWidth

                          
                            onBlur={() =>onClose()}
                            helperText={error && !saved && error }
                         />

                        {/* <IconButton
                            edge="end"
                            size="small"
                            disabled={!value}
                            onClick={() => handleValue('')}
                        >
                            <ClearIcon />
                        </IconButton> */}
                    </Grid>

                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openValue ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                        <TextField
                            fullWidth

                            id="outlined-disabled-value"
                            label={t(label)}
                            defaultValue={value ? value.toString()+'%' : emptyValue}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                </span>)}
        </span >
    );
};