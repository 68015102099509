import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    companyInfoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px',
      width: '100%',
      minWidth: '1750px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      width: '100%',
      minWidth: 'auto'
      // height: 224,

    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: '165px'
    },
    // companyInfoWrapper: {
    //   border: '2 px solid red'
    // },
    panelDetails: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    paper: {
      width: '100%',
      padding: '20px',
      marginBottom: theme.spacing('2px'),
    },
  };
});

export default useStyles;
