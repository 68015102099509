import React, { useCallback } from "react";
import useStyles from './style';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function InlineEditSwitch({ check: initialCheck, getSelectedCheck, saved, disabled, label }) {
    const classes = useStyles();
    const [openCheck, setOpenCheck] = React.useState(false);
    const [check, setCheck] = React.useState(initialCheck ? true : false);

    const handleSpanClick = useCallback(() => setOpenCheck(true), [
        setOpenCheck
    ]);

    const handleChange = (event) => {
        setCheck(event.target.checked);
        const selectedCheck = {
            check: event.target.checked,
        };
        getSelectedCheck(selectedCheck);
        setOpenCheck(false);
    };
    return (
        <span className="inline-text">
            {openCheck || !saved ? (
                <span>
                    <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                            variant="outlined"
                            control={
                                <Switch
                                    checked={check}
                                    disabled={disabled}
                                    onChange={(e) =>
                                        handleChange(e, label)
                                    }
                                    name={label}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    color="primary"
                                />
                            }
                            label={label}
                        />
                    </FormControl>
                </span>) : (
                <span
                    onClick={handleSpanClick}
                    className={!openCheck ? classes.inline_text_copy_active : classes.inline_text_copy_hidden}
                >
                    <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                            variant="outlined"
                            control={
                                <Switch
                                    checked={check}
                                
                                    name={label}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    color="primary"
                                />
                            }
                            label={label}
                        />
                    </FormControl>
                </span>)}
        </span >
    );
};
