import React, { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeader from '../../components/tableHeader';
import { urlParams } from "../../helpers/urlParams";
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import useStyles from './styles';
import { tableHeadersBuyer, tableHeadersSupplier } from '../../helpers/vesselPurchasesConfig';
import { getVesselReport } from '../../services/reportsService';
import { TableFilterVesselPurchases } from '../../components/tableFilterVesselPurchases';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import ChartWithGrid from "../../components/statistics/chartWithGrid";
import DoughnutMultiChartWithGrid from "../../components/statistics/doughnutMultiChartWithGrid";
import MiniCard from '../../components/miniCard';
import t from '../../helpers/languages';
import './_vesselPurchases.scss';


const vesselPurchases = (props) => {

  const classes = useStyles();


  const [vesselPurchasesState, setVesselPurchasesState] = useState({
    isLoading: false,
    filters: {},
    page: {
      page: 1,
      count: 0,
      per_page: 20,
      total: 0,
      order_type: 'asc',
      order_by: ''
    },
    vesselTypeData: [],
    dwtStatData: [],
    ageStatData: [],
    reportData: [],
    totalAmount: null,
    totalAmountUSD: null,
  });

  const filters = localStoreGetObj('vesselPurchasesFilter');

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 20)
  !criteria.page && (criteria.page = 1)

  urlParams.setUrlParams(criteria);

  const userState = props.userState && props.userState.user;

  let filtersData = '';

  if (userState.userRole === 'SUPPLIER') {
    filtersData = {
      ...vesselPurchasesState.filters,
      dwt: filters && filters.dwt && filters.dwt.id,
      age: filters && filters.age && filters.age.id,
      vesselType: filters && filters.vesselType && filters.vesselType.id,
      buyer: filters && filters.company && filters.company.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  } else {
    filtersData = {
      ...vesselPurchasesState.filters,
      dwt: filters && filters.dwt && filters.dwt.id,
      age: filters && filters.age && filters.age.id,
      vesselType: filters && filters.vesselType && filters.vesselType.id,
      supplier: filters && filters.company && filters.company.id,
      category: filters && filters.category && filters.category.id,
      from_date: filters && filters.from,
      to_date: filters && filters.to,
      per_page: criteria.per_page,
      page: criteria.page
    };
  }

  useEffect(() => {

    setVesselPurchasesState({
      ...vesselPurchasesState,
      isLoading: true,
    });

    getVesselReport(filtersData).then(res => {
      const results = res.data;
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
        vesselTypeData: results.vesselTypeData,
        ageStatData: results.ageStatData,
        dwtStatData: results.dwtStatData,
        reportData: results.reportData,
        page: res.data.page,
        totalAmount: res.data.totalAmount,
        totalAmountUSD: res.data.totalAmountUSD,
        filters: res.data.filters
      });
      urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });
    }).catch((error) => {
      console.log(error);
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleChangePage = (event, newPage) => {
    setVesselPurchasesState({
      ...vesselPurchasesState,
      isLoading: true
    });

    const newCriteria = {
      ...vesselPurchasesState.filters,
      per_page: vesselPurchasesState.page.per_page,
      page: newPage + 1
    };

    getVesselReport(newCriteria).then(res => {
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
        reportData: res.data.reportData,
        page: res.data.page,
        filters: res.data.filters
      });
      urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setVesselPurchasesState({
      ...vesselPurchasesState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...vesselPurchasesState.filters,
      page: 1,
      per_page: perPage,
    };

    getVesselReport(newCriteria).then(res => {
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
        reportData: res.data.reportData,
        page: res.data.page,
        filters: res.data.filters
      })
      urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
      });
    });
  };

  const filterData = (data) => {
    setVesselPurchasesState({
      ...vesselPurchasesState,
      isLoading: true,
    });

    let filtersData = '';

    if (userState.userRole === 'SUPPLIER') {
      filtersData = {
        ...vesselPurchasesState.filters,
        port: data && data.port && data.port.id,
        buyer: data && data.company && data.company.id,
        category: data && data.category && data.category.id,
        dwt: data && data.dwt && data.dwt.id,
        age: data && data.age && data.age.id,
        vesselType: data && data.vesselType && data.vesselType.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    } else {
      filtersData = {
        ...vesselPurchasesState.filters,
        port: data && data.port && data.port.id,
        supplier: data && data.company && data.company.id,
        category: data && data.category && data.category.id,
        dwt: data && data.dwt && data.dwt.id,
        age: data && data.age && data.age.id,
        vesselType: data && data.vesselType && data.vesselType.id,
        from_date: data && data.from,
        to_date: data && data.to,
        per_page: criteria.per_page,
        page: 1
      };
    }

    getVesselReport(filtersData).then(res => {
      const results = res.data;
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
        vesselTypeData: results.vesselTypeData,
        ageStatData: results.ageStatData,
        dwtStatData: results.dwtStatData,
        reportData: results.reportData,
        page: res.data.page,
        totalAmount: res.data.totalAmount,
        totalAmountUSD: res.data.totalAmountUSD,
        filters: res.data.filters
      });
      localStoreSetObj('vesselPurchasesFilter', data);
    }).catch((error) => {
      console.log(error);
      setVesselPurchasesState({
        ...vesselPurchasesState,
        isLoading: false,
      });
    });
  };


  const tableFilterComponent = () => {
    return <TableFilterVesselPurchases
      companyField={true}
      companySearchFor={userState.userRole === 'SUPPLIER' ? "buyer" : "supplier"} // set buyer filter for supplier and vice versa.
      company={(filters && filters.company) ? filters.company : vesselPurchasesState.filters.company}
      companyCategory={(filters && filters.category) ? filters.category : vesselPurchasesState.filters.category}
      vesselType={(filters && filters.vesselType) ? filters.vesselType : vesselPurchasesState.filters.vesselType}
      dwt={(filters && filters.dwt) ? filters.dwt : vesselPurchasesState.filters.dwt}
      age={(filters && filters.age) ? filters.age : vesselPurchasesState.filters.age}
      from={(filters && filters.from) ? filters.from : vesselPurchasesState.filters.from_date}
      to={(filters && filters.to) ? filters.to : vesselPurchasesState.filters.to_date}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={() => { }}
      headCells={userState.userRole === 'SUPPLIER' ? tableHeadersSupplier : tableHeadersBuyer}
      onSelectAllClick={() => { }}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };

  const tableBodyComponent = () => {
    return (
      <TableBody>
        {!vesselPurchasesState.isLoading && vesselPurchasesState.reportData && vesselPurchasesState.reportData.map((data, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >
              <TableCell>{data.vessel && data.vessel.name}</TableCell>
              <TableCell>{data.vesselType}</TableCell>
              <TableCell>{data.age}</TableCell>
              <TableCell>{data.dwt}</TableCell>
              <TableCell>{(data.buyer !== undefined && data.supplier !== undefined) ? (userState.userRole === 'SUPPLIER' ? data.buyer.name : data.supplier.name) : ''}</TableCell>
              <TableCell>{data.totalAmountUSD}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <main className='dashboard'>
      <div className='dashboard__total-amount-container'>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={3}
        >
          <Grid item md={8}>
            <ProcureDataTable
              toolbarTitle={t('VESSEL_PURCHASES')}
              pagination={{
                total: vesselPurchasesState.page.total || 0,
                page: vesselPurchasesState.page.page - 1 || 0,
                perPage: vesselPurchasesState.page.per_page || 10,
                count: vesselPurchasesState.page.count || 0,
                handleChangePage: handleChangePage,
                handleChangeRowsPerPage: handleChangeRowsPerPage,
              }}
              addNewRawBtnTitle={''}
              onNewRowClick={() => { }}

              confirmDialog={{
                message: '',
                title: '',
                onCancel: () => { },
                onConfirm: () => { },
                isLoading: false,
                isOpen: false
              }}
              tableFilter={tableFilterComponent}
              autonomousPage={props.autonomousPage}
              tableHeader={tableHeaderComponent}
              tableBody={tableBodyComponent}
              noWidth={true}
              isLoading={vesselPurchasesState.isLoading}
            />
          </Grid>
          <Grid item md={4}>
            <Grid item xs={12}>
              <Paper
                elevation={4}
              >
                <MiniCard
                  title={'Total Amount Spent '}
                  number={vesselPurchasesState.totalAmountUSD}>
                </MiniCard>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={4}
              >
                {!vesselPurchasesState.isLoading && <ChartWithGrid
                  theme={'red'}
                  data={vesselPurchasesState.vesselTypeData?.map((stat) => (stat.totalAmount))}
                  labels={vesselPurchasesState.vesselTypeData?.map((stat) => (stat.vesselType))}
                  amount={''}
                  title={'Top spending vessel types'}
                />}
              </Paper>
            </Grid>

              <Grid item xs={12}>
                <Paper
                  elevation={4}
                  sx={{ marginBottom: '10px' }}
                >
                  {!vesselPurchasesState.isLoading && <DoughnutMultiChartWithGrid
                    theme={'green'}
                    id={'dwtStatData'}
                    doughnutWidth={'650px'}
                    data1={vesselPurchasesState.dwtStatData?.map((stat) => (stat.percent))}
                    labels1={vesselPurchasesState.dwtStatData?.map((stat) => (stat.dwt))}
                    data2={vesselPurchasesState.ageStatData?.map((stat) => (stat.percent))}
                    labels2={vesselPurchasesState.ageStatData?.map((stat) => (stat.age))}
                    title={'Spending by DWT and Age'}
                    subtitleToolbarTitle={''}
                  />}
                </Paper>
              </Grid>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default vesselPurchases;