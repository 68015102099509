
import React, { useEffect, useState } from "react";
import useStyles from './styles';
import HalfRatingWithNotes from '../../components/halfRatingWithNotes';
import Typography from '@mui/material/Typography';
import {
  getSupplierEvaluation, addSupplierEvaluation, deleteSupplierEvaluation,
  editSupplierEvaluation, submitSupplierEvaluation, editEvaluationFactor
} from "../../services/evaluationsService";
import EvaluationInfo from "../../components/evaluationInfo";
import Paper from '@mui/material/Paper';
import './_evaluations.scss';
import _ from 'lodash';
import t from '../../helpers/languages';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ConfirmationDialog from "../../components/modals/confirmation-dialog";
import InlineEditNotes from '../../components/inlineEditNotes';
import SimpleBreadcrumbs from '../../components/breadcrumbs';
import OutlineDisplay from "../../components/outlineDisplay";
import OutlineRating from "../../components/outlineRating";

const Evaluations = (props) => {

  const [disabled, setDisabled] = React.useState(true);

  const classes = useStyles();

  const evalInitObject = () => {
    return {
      date: null,
      id: null,
      isFinal: false,
      items: [],
      notes: null,
      rating: null,
      objectId: null,
      port: null,
      supplier: null,
      type: null,
      vessel: null,
      actions: null,
    }
  };

  const [evalsState, setEvalsState] = useState({
    date: null,
    id: null,
    isFinal: false,
    isLoading: false,
    isGlobalRateLoading: false,
    items: [],
    notes: null,
    rating: null,
    objectId: null,
    port: null,
    supplier: null,
    type: null,
    vessel: null,
    actions: null,
    evalEditObject: evalInitObject(),
    evalCreateObject: null,
    //todo make it global with custom events
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    },
    error: null,
    isNotesSaved: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
      9: true
    },
    isStarsSaved: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
      9: true
    },
  });


  const pathName = window.location.pathname;
  const regexTransaction = /(\d+)/gm;
  let m;

  m = regexTransaction.exec(pathName);


  const transactionId = m[0];
  const regexSupplier = /\d+$/;
  m = regexSupplier.exec(pathName);
  const supplierId = m[0];

  const evaluateCriteria = {
    id: transactionId,
    supplierId: supplierId
  };



  useEffect(() => {

    getSupplierEvaluation(evaluateCriteria).then(res => {
      const results = res.data;
      setEvalsState({
        ...evalsState,
        isLoading: false,
        date: results.date,
        id: results.id,
        isFinal: results.isFinal,
        items: results.items,
        notes: results.notes,
        rating: results.rating,
        objectId: results.objectId,
        port: results.port,
        supplier: results.supplier,
        type: results.type,
        vessel: results.vessel,
        actions: results.actions,
      });
    }).catch((error) => {
      setEvalsState({
        ...evalsState,
        isLoading: false,
      })

    });
    // eslint-disable-next-line  
  }, []);


  const evalNotes = (data, pos) => {

    if (evalsState.id) {
      const evalEditObject = pos !== 9 ? Object.assign({}, evalsState.items[pos]) : Object.assign({}, evalsState);
      if (data !== undefined && data !== null && data.notes !== '') {
        if (data.notes !== null) {
          evalEditObject.notes = data.notes;
        } else {
          evalEditObject.notes = null;
        }
      }


      if (pos !== 9) {

        editEvaluationFactor({ id: evalsState.id }, evalEditObject)
          .then(res => {
            const results = res.data;
            setEvalsState({
              ...evalsState,
              isLoading: false,
              date: results.date,
              id: results.id,
              isFinal: results.isFinal,
              items: results.items,
              notes: results.notes,
              rating: results.rating,
              objectId: results.objectId,
              port: results.port,
              supplier: results.supplier,
              type: results.type,
              vessel: results.vessel,
              actions: results.actions,
              isNotesSaved: {
                ...evalsState.isNotesSaved,
                [pos]: true
              },
            });
          }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' ' + error,
            // }));
            setEvalsState({
              ...evalsState,
              isLoading: false,
              isNotesSaved: {
                ...evalsState.isNotesSaved,
                [pos]: false
              },
            });
          });
      }
      else {
        editSupplierEvaluation({ id: evalsState.id }, evalEditObject)
          .then(res => {
            const results = res.data;
            setEvalsState({
              ...evalsState,
              isLoading: false,
              date: results.date,
              id: results.id,
              isFinal: results.isFinal,
              items: results.items,
              notes: results.notes,
              rating: results.rating,
              objectId: results.objectId,
              port: results.port,
              supplier: results.supplier,
              type: results.type,
              vessel: results.vessel,
              actions: results.actions,
              isNotesSaved: {
                ...evalsState.isNotesSaved,
                [pos]: true
              },
            });
          }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' ' + error,
            // }));
            setEvalsState({
              ...evalsState,
              isLoading: false,
              isNotesSaved: {
                ...evalsState.isNotesSaved,
                [pos]: false
              },
            });
          });
      }
    }
    else {

      const evalCreateObject = Object.assign({}, evalsState);
      if (data !== undefined && data !== null && data.notes !== '') {
        if (data.notes !== null) {
          if (pos !== 9) {
            evalCreateObject.items[pos].notes = data.notes;
          } else {
            evalCreateObject.notes = data.notes;
          }
        } else {
          if (pos !== 9) {
            evalCreateObject.items[pos].notes = null;
          } else {
            evalCreateObject.notes = null;
          }
        }
      }

      const newEvaluation = {};
      newEvaluation.date = evalCreateObject.date;
      newEvaluation.id = evalCreateObject.id;
      newEvaluation.isFinal = evalCreateObject.isFinal;
      newEvaluation.objectId = evalCreateObject.objectId;
      newEvaluation.items = evalCreateObject.items;
      newEvaluation.supplier = evalCreateObject.supplier;
      newEvaluation.type = evalCreateObject.type;
      newEvaluation.vessel = evalCreateObject.vessel;
      newEvaluation.port = evalCreateObject.port;
      newEvaluation.notes = evalCreateObject.notes;
      newEvaluation.rating = evalCreateObject.rating;

      addSupplierEvaluation(newEvaluation)
        .then(res => {
          const results = res.data;
          setEvalsState({
            ...evalsState,
            isLoading: false,
            date: results.date,
            id: results.id,
            isFinal: results.isFinal,
            items: results.items,
            notes: results.notes,
            rating: results.rating,
            objectId: results.objectId,
            port: results.port,
            supplier: results.supplier,
            type: results.type,
            vessel: results.vessel,
            actions: results.actions,
            isNotesSaved: {
              ...evalsState.isNotesSaved,
              [pos]: true
            },
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setEvalsState({
            ...evalsState,
            isLoading: false,
            isNotesSaved: {
              ...evalsState.isNotesSaved,
              [pos]: false
            },
          });
        });
    }
  };

  const evalStars = (data, pos) => {

    if (evalsState.id) {
      const evalEditObject = pos !== 9 ? Object.assign({}, evalsState.items[pos]) : Object.assign({}, evalsState);
      if (data !== undefined && data !== null && data.stars !== '') {
        if (data.stars !== null) {
          if (pos !== 9) {
            evalEditObject.rating = data.stars;
            setEvalsState({
              ...evalsState,
              isGlobalRateLoading: true,
            });
          } else {
            setEvalsState({
              ...evalsState,
              isLoading: true
            });
            evalEditObject.rating = data.stars;
            evalEditObject.items[0].rating = data.stars;
            evalEditObject.items[1].rating = data.stars;
            evalEditObject.items[2].rating = data.stars;
            evalEditObject.items[3].rating = data.stars;
            evalEditObject.items[4].rating = data.stars;
            evalEditObject.items[5].rating = data.stars;
            evalEditObject.items[6].rating = data.stars;
            evalEditObject.items[7].rating = data.stars;
            evalEditObject.items[8].rating = data.stars;
          }
        } else {
          if (pos !== 9) {
            evalEditObject.rating = null;
            setEvalsState({
              ...evalsState,
              isGlobalRateLoading: true,
            });
          } else {
            setEvalsState({
              ...evalsState,
              isLoading: true
            });
            evalEditObject.rating = null;
            evalEditObject.items[0].rating = null;
            evalEditObject.items[1].rating = null;
            evalEditObject.items[2].rating = null;
            evalEditObject.items[3].rating = null;
            evalEditObject.items[4].rating = null;
            evalEditObject.items[5].rating = null;
            evalEditObject.items[6].rating = null;
            evalEditObject.items[7].rating = null;
            evalEditObject.items[8].rating = null;
          }
        }
      }
      if (pos !== 9) {
        editEvaluationFactor({ id: evalsState.id }, evalEditObject)
          .then(res => {
            const results = res.data;
            setEvalsState({
              ...evalsState,
              isLoading: false,
              date: results.date,
              id: results.id,
              isFinal: results.isFinal,
              items: results.items,
              notes: results.notes,
              objectId: results.objectId,
              port: results.port,
              supplier: results.supplier,
              type: results.type,
              vessel: results.vessel,
              rating: results.rating,
              isGlobalRateLoading: false,
              actions: results.actions,
              isStarsSaved: {
                ...evalsState.isStarsSaved,
                [pos]: true
              },
            });
          }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' ' + error,
            // }));
            setEvalsState({
              ...evalsState,
              isLoading: false,
              isGlobalRateLoading: false,
              isStarsSaved: {
                ...evalsState.isStarsSaved,
                [pos]: false
              },
            });
          });
      } else {
        editSupplierEvaluation({ id: evalsState.id }, evalEditObject)
          .then(res => {
            const results = res.data;
            setEvalsState({
              ...evalsState,
              isLoading: false,
              date: results.date,
              id: results.id,
              isFinal: results.isFinal,
              items: results.items,
              notes: results.notes,
              objectId: results.objectId,
              port: results.port,
              supplier: results.supplier,
              type: results.type,
              vessel: results.vessel,
              rating: results.rating,
              isGlobalRateLoading: false,
              actions: results.actions,
              isStarsSaved: {
                ...evalsState.isStarsSaved,
                [pos]: true
              },
            });
          }).catch((error) => {
            // dispatch(messagesActions.openSnackbar({
            //   variant: 'error ',
            //   message: ' ' + error,
            // }));
            setEvalsState({
              ...evalsState,
              isLoading: false,
              isGlobalRateLoading: false,
              isStarsSaved: {
                ...evalsState.isStarsSaved,
                [pos]: false
              },
            });
          });
      }
    }
    else {
      const evalCreateObject = Object.assign({}, evalsState);
      if (data !== undefined && data !== null && data.stars !== '') {
        if (data.stars !== null) {
          if (pos !== 9) {
            evalCreateObject.items[pos].rating = data.stars;
            setEvalsState({
              ...evalsState,
              isGlobalRateLoading: true,
            });
          } else {
            setEvalsState({
              ...evalsState,
              isLoading: true
            });
            evalCreateObject.rating = data.stars;
            evalCreateObject.items[0].rating = data.stars;
            evalCreateObject.items[1].rating = data.stars;
            evalCreateObject.items[2].rating = data.stars;
            evalCreateObject.items[3].rating = data.stars;
            evalCreateObject.items[4].rating = data.stars;
            evalCreateObject.items[5].rating = data.stars;
            evalCreateObject.items[6].rating = data.stars;
            evalCreateObject.items[7].rating = data.stars;
            evalCreateObject.items[8].rating = data.stars;
          }
        } else {
          if (pos !== 9) {
            evalCreateObject.items[pos].rating = null;
            setEvalsState({
              ...evalsState,
              isGlobalRateLoading: true,
            });
          } else {
            setEvalsState({
              ...evalsState,
              isLoading: true
            });
            evalCreateObject.rating = null;
            evalCreateObject.items[0].rating = null;
            evalCreateObject.items[1].rating = null;
            evalCreateObject.items[2].rating = null;
            evalCreateObject.items[3].rating = null;
            evalCreateObject.items[4].rating = null;
            evalCreateObject.items[5].rating = null;
            evalCreateObject.items[6].rating = null;
            evalCreateObject.items[7].rating = null;
            evalCreateObject.items[8].rating = null;
          }
        }
      }

      const newEvaluation = {};
      newEvaluation.date = evalCreateObject.date;
      newEvaluation.id = evalCreateObject.id;
      newEvaluation.isFinal = evalCreateObject.isFinal;
      newEvaluation.objectId = evalCreateObject.objectId;
      newEvaluation.items = evalCreateObject.items;
      newEvaluation.supplier = evalCreateObject.supplier;
      newEvaluation.type = evalCreateObject.type;
      newEvaluation.vessel = evalCreateObject.vessel;
      newEvaluation.port = evalCreateObject.port;
      newEvaluation.notes = evalCreateObject.notes;
      newEvaluation.rating = evalCreateObject.rating;

      addSupplierEvaluation(newEvaluation)
        .then(res => {
          const results = res.data;
          setEvalsState({
            ...evalsState,
            isLoading: false,
            date: results.date,
            id: results.id,
            isFinal: results.isFinal,
            items: results.items,
            notes: results.notes,
            rating: results.rating,
            objectId: results.objectId,
            port: results.port,
            supplier: results.supplier,
            type: results.type,
            vessel: results.vessel,
            actions: results.actions,
            isGlobalRateLoading: false,
            isStarsSaved: {
              ...evalsState.isStarsSaved,
              [pos]: true
            },
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setEvalsState({
            ...evalsState,
            isLoading: false,
            isGlobalRateLoading: false,
            isStarsSaved: {
              ...evalsState.isStarsSaved,
              [pos]: false
            },
          });
        });
    }
  };

  const deleteEvaluateItem = (e, pos) => {
    const evalEditObject = Object.assign({}, evalsState.items[pos]);

    evalEditObject.rating = null;
    evalEditObject.notes = null;

    editEvaluationFactor({ id: evalsState.id }, evalEditObject)
      .then(res => {
        const results = res.data;
        setEvalsState({
          ...evalsState,
          isLoading: false,
          date: results.date,
          id: results.id,
          isFinal: results.isFinal,
          items: results.items,
          notes: results.notes,
          objectId: results.objectId,
          port: results.port,
          supplier: results.supplier,
          type: results.type,
          vessel: results.vessel,
          rating: results.rating,
          isGlobalRateLoading: false,
          actions: results.actions,
          isStarsSaved: {
            ...evalsState.isStarsSaved,
            [pos]: true
          },
        });
      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setEvalsState({
          ...evalsState,
          isLoading: false,
          isGlobalRateLoading: false,
          isStarsSaved: {
            ...evalsState.isStarsSaved,
            [pos]: false
          },
        });
      });
  }

  const handleDeleteEvaluation = (event) => {

    const evaluationForDeletion = evalsState.id;
    setEvalsState({
      ...evalsState,
      isLoading: true
    });

    setEvalsState({
      ...evalsState,
      confirmDialog: {
        message: 'Are you sure you want to delete this evaluation?',
        title: 'Delete Evaluation',
        onCancel: () => {
          setEvalsState({
            ...evalsState,
            confirmDialog: {
              ...evalsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          setEvalsState({
            ...evalsState,
            isLoading: true,
          })
          deleteSupplierEvaluation(evaluationForDeletion).then(res => {
            getSupplierEvaluation(evaluateCriteria).then(res => {
              const results = res.data;

              setEvalsState({
                ...evalsState,
                isLoading: false,
                date: results.date,
                id: results.id,
                isFinal: results.isFinal,
                items: results.items,
                notes: results.notes,
                objectId: results.objectId,
                rating: results.rating,
                port: results.port,
                supplier: results.supplier,
                type: results.type,
                vessel: results.vessel,
                actions: results.actions,
              });
            }).catch((error) => {
              setEvalsState({
                ...evalsState,
                isLoading: false,
              })
            });
          }).catch((error) => {
            setEvalsState({
              ...evalsState,
              isLoading: false,
            });
          });
        },
        isLoading: false,
        isOpen: true
      }
    });
  }
  const shouldSetStateToDisabled = (evalsState.id
    && (evalsState.items[0].rating ||
      evalsState.items[1].rating ||
      evalsState.items[2].rating ||
      evalsState.items[3].rating ||
      evalsState.items[4].rating ||
      evalsState.items[5].rating ||
      evalsState.items[6].rating ||
      evalsState.items[7].rating ||
      evalsState.items[8].rating ||
      evalsState.rating
    )
  );

  useEffect(() => {
    if (shouldSetStateToDisabled) {
      setDisabled(
        false
      );
    } else {
      setDisabled(
        true
      )
    }
  }, [shouldSetStateToDisabled])

  const handleSubmitEvaluation = (event) => {

    setEvalsState({
      ...evalsState,
      isLoading: true
    });


    const evaluation = evalsState.id;

    submitSupplierEvaluation(evaluation)
      .then(res => {

        const results = res.data;

        setEvalsState({
          ...evalsState,
          isLoading: false,
          date: results.date,
          id: results.id,
          isFinal: results.isFinal,
          items: results.items,
          notes: results.notes,
          rating: results.rating,
          objectId: results.objectId,
          port: results.port,
          supplier: results.supplier,
          type: results.type,
          vessel: results.vessel,
          actions: results.actions,
        });

      }).catch((error) => {
        // dispatch(messagesActions.openSnackbar({
        //   variant: 'error ',
        //   message: ' ' + error,
        // }));
        setEvalsState({
          ...evalsState,
          isLoading: true
        });
      });
  }


  const trasactionTab = () => {
    return (
      !evalsState.isLoading && evalsState.actions && <EvaluationInfo
        infoTabTitle={t('EVALUATION')}
        edit={evalsState.actions.includes("UPDATE")}
        stars={evalsState.rating}
        savedStars={evalsState.isStarsSaved[9]}
        getStars={(data) => evalStars(data, 9)}
        vessel={evalsState.vessel || "-"}
        buyer={evalsState.buyer || "-"}
        supplier={evalsState.supplier || "-"}
        port={evalsState.port || "-"}
        date={evalsState.date ? evalsState.date.slice(0, 10) : "-"}
        status={evalsState.status !== 'RFQ' ? _.capitalize(evalsState.status) : evalsState.status || "-"}
      />
    )
  };

  const breadcrump = () => {
    return <SimpleBreadcrumbs
      buttons={true}
      config={{
        revise: {
          enabled: false,

        },
        sync: {
          enabled: false,
        },
        quote: {
          enabled: false,
        },
        evaluate: {
          enabled: false,
        },
        delete: {
          enabled: evalsState.objectId && evalsState.actions && evalsState.actions.includes("DELETE"),
          callback: (e) => handleDeleteEvaluation(e)
        },
        submit: {
          enabled: evalsState.objectId && evalsState.actions && evalsState.actions.includes("FINALIZE"),
          disabled: disabled,
          callback: (e) => handleSubmitEvaluation(e)
        }
      }}
    />;
  };

  return (
    <div className={classes.evalutaionsWrapper}>
      <div className={classes.root}>
        {breadcrump()}
        <Paper
          className={classes.paper}
          elevation={4}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            spacing={3}>
            <Grid item xs={12}>
              {evalsState.objectId && trasactionTab()}
            </Grid>
            <Divider />
            {evalsState.objectId && !evalsState.isLoading && evalsState.actions && evalsState.actions.includes("UPDATE") && <><Grid item xs={12}>
              <Grid item xs={12}>
                <InlineEditNotes
                  key={'rating-9'}
                  notes={evalsState.notes} saved={evalsState.isNotesSaved[9]} error={props.error}
                  getNotes={(data) => evalNotes(data, 9)}>
                </InlineEditNotes>
              </Grid>
              <Typography variant="h5" style={{ marginTop: 15 }} gutterBottom>
                {t('PRODUCTS')}
              </Typography>
            </Grid>
              {evalsState.items.map((evalItem, index) => {
                return (
                  <Grid item xs={12} key={'rating-row' + index}>
                    <HalfRatingWithNotes
                      key={'rating-item' + index}
                      title={evalItem.factor.name}
                      stars={evalItem.rating}
                      notes={evalItem.notes}
                      deleteEvaluateItem={(e)=>deleteEvaluateItem(e,index)}
                      savedNotes={evalsState.isNotesSaved[index]}
                      savedStars={evalsState.isStarsSaved[index]}
                      getStars={(data) => evalStars(data, index)}
                      getNotes={(data) => evalNotes(data, index)}
                    />
                    {index === 4 &&
                      <Typography variant="h5" key={'rating-title-row-detail' + index} gutterBottom style={{ marginTop: 15 }}>
                        {t('SERVICES')}
                      </Typography>
                    }
                  </Grid>
                )
              })
              }
            </>
            }
            {evalsState.objectId && !evalsState.isLoading && evalsState.actions && !evalsState.actions.includes("UPDATE") && <><Grid item xs={12}>
              <Grid item xs={12}>
                <OutlineDisplay value={evalsState.notes} label={t('NOTES')} />
              </Grid>
              <Typography variant="h5" style={{ marginTop: 15 }} gutterBottom>
                {t('PRODUCTS')}
              </Typography>
            </Grid>
              {evalsState.items.map((evalItem, index) => {
                return (<Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  key={'rating-row-span' + index}
                >
                  <Grid item xs={3} key={'rating-row' + index}>
                    <Typography component="legend">
                      {evalItem.factor.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <OutlineRating value={evalItem.rating} />
                  </Grid>
                  <Grid item xs={6}>
                    <OutlineDisplay value={evalItem.notes} label={t('NOTES')} />
                  </Grid>

                  {index === 4 &&
                    <Grid item xs={12}>
                      <Typography variant="h5" key={'rating-title-row-detail' + index} gutterBottom style={{ marginTop: 15 }}>
                        {t('SERVICES')}
                      </Typography>
                    </Grid>
                  }

                </Grid>
                )
              })
              }
            </>
            }

            <ConfirmationDialog
              ariaLabelledBy={''}
              confirmationMessage={evalsState.confirmDialog.message}
              dialogTitle={evalsState.confirmDialog.title}
              handleCancel={evalsState.confirmDialog.onCancel}
              handleOk={evalsState.confirmDialog.onConfirm}
              open={evalsState.confirmDialog.isOpen}
            />
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
export default Evaluations;