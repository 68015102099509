import httpService from './httpService';

const getOrderItems = (criteria = {}) => {
  return httpService.FetchOrderItemList(criteria);
};


const add = (data) => {
  return httpService.CreateOrderItem({}, data);
};

const edit = (id, data) => {
  return httpService.UpdateOrderItem({}, data);
};

const deleteOrderItem = (id) => {
  return httpService.DeleteOrderItem(id);
};

const bulkEditOrderItems = (id, data) => {
  return httpService.UpdateListOfOrderItems({id}, data);
};

export { getOrderItems, edit, add, deleteOrderItem, bulkEditOrderItems };
