

const tableHeadersCountryDictionary = [
  { id: 'countryAlias', disablePadding: false, label: 'Country Alias' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];

const tableHeadersCountryDictionaryGlobal = [
  { id: 'company', disablePadding: false, label: 'Company' },
  { id: 'countryAlias', disablePadding: false, label: 'Country Alias' },
  { id: 'originalId', disablePadding: false, label: 'Original' },
  { id: 'row-actions', disablePadding: false },
];


// Empty Country dictionary
const newCountryDictionary  = () => {
  return {
    id: '?',
    company: { name: '', id: '' },
    countryAlias: null,
    original: null
  }
};

export { tableHeadersCountryDictionary, tableHeadersCountryDictionaryGlobal, newCountryDictionary };
