import httpService from './httpService';

const getAllUnitCategories = (criteria = {}) => {
  if (!criteria.search) {
    delete criteria.search;
  }

  return httpService.FetchUnitCategories(criteria);
};

export { getAllUnitCategories };
