import httpService from './httpService';

const getAllOriginalRegions = (criteria = {}) => {
  return httpService.FetchOriginalRegions(criteria);
};

const getAllContractRegionsInfo = (criteria = {}) => {
  return httpService.FetchContractRegions(criteria);
};

const deleteOriginalRegion = (id) => {
  return httpService.DeleteOriginalRegion(id);
};

const editOriginalRegion = (id, data) => {
  return httpService.UpdateOriginalRegion({id}, data);
};

const addOriginalRegion = (data) => {
  return httpService.CreateOriginalRegion({}, data);
};

const cleanOriginalRegion = (data) => {
  return httpService.CleanOriginalRegion(data);
}

const addOriginalRegionData = (id,data) => {

  return httpService.AddOriginalRegionData({id}, data);
};

const addOriginalRegionCountry = (id,data) => {

  return httpService.AddOriginalRegionCountry({id}, data);
};

const addOriginalRegionPort = (id,data) => {

  return httpService.AddOriginalRegionPort({id}, data);
};

const addOriginalRegionCRegion = (id,data) => {

  return httpService.AddOriginalRegionCRegion({id}, data);
};

const deleteOriginalRegionData = (id,data={}) => {
  return httpService.RemoveOriginalRegionData({id},data);
};

const getAllRegionPorts = (id, criteria = {}) => {
  
  const search = {
    id: id,
    search: criteria.search
  }
  return httpService.FetchOriginalRegionPorts(search);
};

const getAllRegionCountries = (id, criteria = {}) => {
  const search = {
    id: id,
    search: criteria.search
  }
  return httpService.FetchOriginalRegionCountries(search);
};

const getAllRegionContractRegions = (id, criteria = {}) => {
  const search = {
    id: id,
    search: criteria.search
  }
  return httpService.FetchOriginalRegionContractRegions(search);
};

const getAllContractRegions = (criteria = {}) => {
  return httpService.FetchContractRegionList(criteria);
};

export { 
  getAllOriginalRegions, 
  deleteOriginalRegion , 
  editOriginalRegion,
  addOriginalRegion,
  cleanOriginalRegion,
  getAllRegionPorts,
  getAllRegionCountries,
  getAllRegionContractRegions,
  addOriginalRegionData,
  deleteOriginalRegionData,
  addOriginalRegionCountry,
  addOriginalRegionPort,
  addOriginalRegionCRegion,
  getAllContractRegions,
  getAllContractRegionsInfo
  };
