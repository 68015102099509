import httpService from './httpService';

const getAllProducts = (criteria = {}) => {
  return httpService.FetchProductList(criteria);
};

const getAllProductsInfo = (criteria = {}) => {
  return httpService.FetchProductInfoList(criteria);
};

const getProduct = (productId) => {
  return httpService.get(`api/Products/ ${ productId }`);
};

const deleteProduct = (id) => {
  return httpService.DeleteProduct(id);
};

const editProduct = (id, data) => {
  return httpService.UpdateProduct({}, data);
};

const addProduct = (data) => {
  return httpService.CreateProduct({}, data);
};

const matchProducts = (data) => {
  return httpService.MatchProduct(data);
}

const importProductsFile = (data) => {
  
  let fd = new FormData();

  fd.append('file', new Blob([data], {
    type: "text/tab-separated-values"
  }));

  return httpService.UploadProductsFile({},fd,
    {
      headers: {
        'Content-Type': 'text/tab-separated-values',
      },
    })
};


export { getAllProducts, editProduct, deleteProduct, getAllProductsInfo, addProduct, getProduct, matchProducts, importProductsFile };
